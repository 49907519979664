import { AsyncTypeahead } from 'react-bootstrap-typeahead'

interface IProps {
  allowNew: boolean
  newLabel: string
  id: string
  onSearch: (s: string) => Promise<any[]>
  onChange: () => void
  labelKey: () => void
  options: string[]
  isLoading: boolean
  placeholder: string
  defaultSelected: boolean
}

const Autocomplete = (props: IProps) => {
  return (
    <AsyncTypeahead
      allowNew={props.allowNew}
      minLength={3}
      newSelectionPrefix={props.newLabel}
      filterBy={() => true}
      id={props.id}
      onSearch={props.onSearch}
      onChange={props.onChange}
      labelKey={props.labelKey ? props.labelKey : '..'}
      options={props.options}
      placeholder={props.placeholder}
      isLoading={props.isLoading}
      defaultSelected={props.defaultSelected}
      onInputChange={(text, event) => {
        event.preventDefault()
        event.stopPropagation()
      }}
    />
  )
}

export default Autocomplete

const suffixes = [
  { name: 'None', value: '' },
  { name: 'Jr.', value: 'Jr.' },
  { name: 'Sr.', value: 'Sr.' },
  { name: 'I', value: 'I' },
  { name: 'II', value: 'II' },
  { name: 'III', value: 'III' },
  { name: 'IV', value: 'IV' },
  { name: 'V', value: 'V' },
  { name: 'VI', value: 'VI' },
  { name: 'VII', value: 'VII' },
  { name: 'VIII', value: 'VIII' },
  { name: 'IX', value: 'IX' },
  { name: 'X', value: 'X' },
]

export default suffixes

/** @jsxImportSource @emotion/react */
import { Physician, PhysicianAddress } from 'store/types'
import * as generalHelper from 'helpers/generalHelper'
import Button from 'components/atoms/Button'
import ButtonModal from 'components/atoms/ButtonModal'
import { formatPhoneNumber } from 'helpers/format'

interface IProps {
  physician: Physician
  index: number
  setPhysician: (a: Physician, location: PhysicianAddress) => void
  openPhysicianModal: (a: Physician, indexLocation: number | null, preValidate?: boolean) => void
  onSelect: () => void
}

const SearchResultItem = (props: IProps) => {
  const { physician, index, setPhysician, openPhysicianModal, onSelect } = props
  const physicianLocations =
    physician?.addresses?.filter((address) => address.addressPurpose !== 'MAILING') || []
  const verified = physician?.addresses?.some((a) => a.status === 'approved')
  const imported = physician?.id

  return (
    <div key={index} className="list-group text-secondary">
      <div className="list-group-item list-group-item-action flex-column align-items-start mb-2 pr-2">
        <div className="d-flex w-100 row">
          <div className="col-sm-1 pt-2">
            {verified ? (
              <i className="text-success fa fa-user-check" />
            ) : imported ? (
              <i className="text-success fa fa-user-md" />
            ) : (
              <i className="text-default fa fa-user-md" />
            )}
          </div>
          <div className="col-sm-2 pt-2">{physician.npi}</div>
          <div className="col-sm-2 pt-2">{`${physician.firstName} ${physician.lastName}`}</div>
          <div className="col-sm-7">
            {Array.isArray(physician?.addresses) &&
              physician.addresses.map((physicianLocation, indexLocation) => {
                if (physicianLocation.addressPurpose === 'MAILING') return null

                const selectable =
                  physicianLocation.addressStreetLine1 &&
                  physicianLocation.addressCity &&
                  physicianLocation.addressState &&
                  physicianLocation.telephone &&
                  physicianLocation.fax

                const isLastAddress = indexLocation === physicianLocations.length - 1

                return (
                  <div
                    key={indexLocation}
                    className={`row ${physicianLocations?.length === 1 ? ' pb-2' : ''}${
                      physicianLocations?.length > 1 && !isLastAddress ? ' border-bottom pb-2' : ''
                    }`}>
                    <div className={`col-8 pt-1`}>
                      <div>
                        {physicianLocation.addressStreetLine1}
                        {', '}
                        {physicianLocation.addressCity}
                        {', '}
                        {physicianLocation.addressState}
                      </div>
                      <div>
                        {physicianLocation.telephone && <strong>{generalHelper.t('Tel')}: </strong>}
                        {formatPhoneNumber(physicianLocation.telephone)}{' '}
                        {physicianLocation.fax ? (
                          <>
                            <strong className="pl-2">{generalHelper.t('Fax')}: </strong>
                            {formatPhoneNumber(physicianLocation.fax)}{' '}
                          </>
                        ) : (
                          <span className="ml-2 text-danger">(missing fax #)</span>
                        )}
                      </div>
                      {physicianLocation.comment && (
                        <div className="text-gray" css={{ fontStyle: 'italic' }}>
                          {physicianLocation.comment}
                        </div>
                      )}
                    </div>
                    <div className="col-4  d-flex align-items-center justify-content-end">
                      {!selectable ? (
                        <ButtonModal
                          label="Select"
                          className="btn btn-sm btn-primary"
                          data_toggle="modal"
                          data_target="#modify_physician_modal"
                          onClick={() => {
                            onSelect && onSelect()
                            openPhysicianModal(physician, indexLocation, true)
                          }}
                        />
                      ) : (
                        <Button
                          className="btn btn-sm btn-primary"
                          label="Select"
                          type="button"
                          onClick={() => {
                            setPhysician(physician, physician.addresses![indexLocation])
                          }}
                        />
                      )}
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SearchResultItem

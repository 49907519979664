import { SyntheticEvent, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

import Toast from 'components/atoms/Toast'
import Input from 'components/atoms/Input'
import { useMoveDeviceDataMutation } from 'store/services/labOrder'
import { Order } from 'store/types'

interface IProps {
  show?: boolean
  onClose: () => void
  markOrderAsBilled: (reason: string) => Promise<Order>
}

export const MarkBilledDialog = ({ show, onClose, markOrderAsBilled }: IProps) => {
  const [reason, setReason] = useState('')
  const [error, setError] = useState('')
  const [moveDeviceData] = useMoveDeviceDataMutation()

  return (
    <Modal centered show={!!show} onHide={onClose}>
      <Modal.Header>
        <Modal.Title>Mark Order as Billed?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to mark this report as billed?
        <br />
        <br />
        <Input
          handleChange={(e: SyntheticEvent) => setReason(e.target.value)}
          name="reason"
          label="Reason (optional)"
          value={reason}
          className="form-control w-100"
        />
        {error && <div className="text-danger">{error}</div>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          autoFocus
          variant="primary"
          onClick={async () => {
            setError('')
            try {
              await markOrderAsBilled(reason)
              onClose()
              setReason('')
              Toast({ type: 'success', label: `Order marked as billed.` })
            } catch (e) {
              if (e.data?.message?.statusCode === 404) return setError('Order not found')
              setError(e.data.message)
            }
          }}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

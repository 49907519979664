/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'

import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { getLogout } from 'store/slice/loginSlice'
import SidebarSection, { Item } from 'components/atoms/SidebarSection'
import { useGetPhysicianGroupPhysiciansQuery } from '../../../store/services/physician'

const StyledLink = styled(Link)`
  border-bottom: none !important;
`

const Menu = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [menuData, setMenuData] = useState<Item[]>([])
  const physicianGroup = useAppSelector((state) => state.init.physicianGroup)
  const { data: physicians, isLoading } = useGetPhysicianGroupPhysiciansQuery(
    { id: Number(physicianGroup) },
    {
      pollingInterval: 60000,
      refetchOnFocus: true,
    },
  )

  useEffect(() => {
    setMenuData([
      ...(physicians?.map(({ name, npi }) => ({
        icon: 'fas fa-user',
        to: `/physicians/reports/${npi}`,
        label: name,
        showIcon: true,
        rightArrow: true,
        open: false,
      })) || []),
    ])
  }, [physicians])

  const handleLogout = async () => {
    dispatch(getLogout())
    navigate('/login')
  }

  return (
    <aside
      className="main-sidebar sidebar-dark-primary elevation-4 vh-100 vw-50"
      style={{ width: 230, backgroundColor: '#1D242B' }}>
      <StyledLink to="/" className="brand-link text-center py-4">
        <span className="brand-text">
          <img src="/img/logo.png" width="160px" />
        </span>
      </StyledLink>
      <a
        href="#"
        role="button"
        onClick={handleLogout}
        css={{ position: 'absolute', left: 205, top: 32 }}>
        <i className="fas fa-sign-out-alt text-white" />
      </a>
      <div className="sidebar overflow-y-auto overflow-x-hidden vh-80 pb-10">
        <nav>
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false">
            <SidebarSection
              data={[
                {
                  type: 'button',
                  icon: 'fal fa-tachometer-alt',
                  label: 'Dashboard / Statistics',
                  to: `/physicians`,
                  extraStyle: { backgroundColor: 'unset', border: 'none' },
                },
                {
                  to: `/physicians/reports/all`,
                  label: 'All Reports',
                  rightArrow: true,
                  open: false,
                },
                {
                  to: `/physicians/orders/open`,
                  label: 'Open Orders',
                  rightArrow: true,
                  open: false,
                },
                {
                  to: `/physicians/orders/cancelled`,
                  label: 'Cancelled Orders',
                  rightArrow: true,
                  open: false,
                },
                {
                  type: 'hr',
                  className: 'mt-1 mb-2 border-light',
                },
                ...menuData,
              ] as Item[]}
              setMenuData={setMenuData}
              badgeData={[]}
            />
          </ul>
        </nav>
      </div>
    </aside>
  )
}

export default Menu

import { Formik } from 'formik'
import moment from 'moment'
import * as Yup from 'yup'
import Button from '../../atoms/Button'
import TitleLead from '../../atoms/TitleLead'
import Toast from '../../atoms/Toast'
import PatientForm from './PatientForm'
import * as generalHelper from 'helpers/generalHelper'
import { updateUser, insertUser } from 'store/slice/userSlice'
import { Patient } from 'store/types'
import { FormValues } from './PatientForm'
import { requiredPhoneValidation } from 'helpers/validate'

const actions = {
  update: updateUser,
  insert: insertUser,
}

const userValidation = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  dob: Yup.date().required('Required'),
  homePhone: requiredPhoneValidation,
  primaryAddressStreetLine1: Yup.string().required('Required'),
})

const noop = () => {}

interface IProps {
  onCancel: () => void
  onSaved: (v: FormValues) => void
  patient: Patient
}

const PatientFormContainer = ({ onCancel = noop, onSaved = noop, patient }: IProps) => {
  const initialValues = {
    firstName: patient?.firstName ?? '',
    lastName: patient?.lastName ?? '',
    middleName: patient?.middleName ?? '',
    suffix: patient?.suffix ?? '',
    dob: moment(patient?.dob).format('MM/DD/YYYY') ?? '',
    gender: patient?.gender ?? '',
    ssn: patient?.ssn ?? '',
    homePhone: patient?.homePhone?.replace(/^\+1/, '') ?? '',
    mobilePhone: patient?.mobilePhone?.replace(/^\+1/, '') ?? '',
    email: patient?.email ?? '',
    primaryAddressStreetLine1: patient?.primaryAddressStreetLine1 ?? '',
    primaryAddressStreetLine2: patient?.primaryAddressStreetLine2 ?? '',
    primaryAddressCity: patient?.primaryAddressCity ?? '',
    primaryAddressState: patient?.primaryAddressState ?? '',
    primaryAddressZip: patient?.primaryAddressZip ?? '',
    hasMail: patient?.deliveryAddressStreetLine1 || patient?.deliveryAddressStreetLine2,
    deliveryAddressStreetLine1: patient?.deliveryAddressStreetLine1 ?? '',
    deliveryAddressStreetLine2: patient?.deliveryAddressStreetLine2 ?? '',
    deliveryAddressCity: patient?.deliveryAddressCity ?? '',
    deliveryAddressState: patient?.deliveryAddressState ?? '',
    deliveryAddressZip: patient?.deliveryAddressZip ?? '',
  }

  const onSubmit = async (values: FormValues) => {
    try {
      console.log(values)
      onSaved(values)
      Toast({ type: 'success', label: `Patient updated!` })
    } catch (error) {
      Toast({
        type: 'Error',
        label: `Error Updating Patient: ${error.message}`,
      })
    }
  }

  return (
    <Formik
      enableReinitialize
      validationSchema={userValidation}
      initialValues={initialValues}
      onSubmit={onSubmit}>
      {({
        values,
        dirty,
        submitCount,
        isValid,
        errors,
        touched,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit} className="row border bg-white m-2 mb-3 pb-3">
          <div className="col-sm-12 d-flex flex-column px-3">
            <div className="d-flex justify-content-between align-items-center">
              <TitleLead className="ml-2 my-3" label={generalHelper.t('PATIENT INFORMATION')} />

              <div className="d-flex">
                <Button
                  onClick={onCancel}
                  className="btn btn-default mr-2"
                  label="Cancel"
                  type="button"
                />
                <Button
                  type="submit"
                  className="btn btn-primary"
                  disabled={!dirty || !isValid}
                  label={generalHelper.t('Save Changes')}
                />
              </div>
            </div>
            <hr className="w-100 mt-0" />
          </div>

          <div className="col-sm-12 ml-1">
            {submitCount > 0 && !isValid && (
              <div className="row mx-2">
                <div className="alert alert-warning col-sm-12" role="alert">
                  Patient Information is missing. Please review the information below
                </div>
              </div>
            )}

            <div className="row mx-2">
              <PatientForm
                disabledValues={{
                  firstName: true,
                  lastName: true,
                  middleName: true,
                  suffix: true,
                  dob: true,
                  gender: true,
                }}
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
              />
            </div>
          </div>
        </form>
      )}
    </Formik>
  )
}

export default PatientFormContainer

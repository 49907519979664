import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import DashboardContent from '../atoms/DashboardContent'
import { getUser } from '../../../store/slice/userSlice'
import { getDeviceCategories } from '../../../store/slice/deviceCategorySlice'
import AddOrderUpsert from './AddOrderUpsert'
import Moment from 'moment'
import Loading from '../../atoms/Loading'
import * as generalHelper from '../../../helpers/generalHelper'

export default function AddOder() {
  const dispatch = useDispatch()
  const { patient_id } = useParams()
  const patient = useSelector((state) => state.user.row)
  const deviceCategories = useSelector((state) => state.deviceCategory.rows)
  const [redirect, setRedirect] = useState(false)
  const isFetchingPatient = patient_id ? useSelector((state) => state.user.pending) : false
  const isFetchingDeviceCategories = useSelector((state) => state.deviceCategory.pending)
  const isFetching = isFetchingPatient || isFetchingDeviceCategories

  const title =
    patient_id > 0 && patient ? `${patient.first_name} ${patient.last_name}` : 'New Order'
  const subtitle =
    patient_id > 0 && patient && patient.dob
      ? `${generalHelper.t('Date Of Birth')}: ${Moment(patient.dob).format('MM/DD/YYYY')}`
      : null

  useEffect(() => {
    if (patient_id) {
      dispatch(
        getUser({
          id: patient_id,
          add_join: 'user_role.role',
        }),
      )
    }
    dispatch(getDeviceCategories({ add_join: 'device_type' }))
  }, [])

  return !isFetching ? (
    <div>
      {redirect && <Navigate to={redirect} />}
      <DashboardContent
        title={title}
        subtitle={subtitle}
        icon="fal fa-folder-plus"
        content={
          <AddOrderUpsert
            patient={patient_id && patient}
            patientId={patient_id}
            deviceCategories={deviceCategories}
          />
        }
        breadcrumb={[{ label: 'New Order' }]}
      />
    </div>
  ) : (
    <Loading />
  )
}

import * as generalHelper from '../../helpers/generalHelper'
import { toast, ToastOptions, TypeOptions } from 'react-toastify'

interface IProps {
  label: string
  type: TypeOptions
  extra?: string
  options?: ToastOptions
}

const ToastComponent = (props: IProps) => {
  const label = props.label ?? ''
  const type = props.type ?? 'info'
  const extra = props.extra ?? ''
  const options = props.options ?? {}

  if (toast?.[type]) {
    return toast[type](`${generalHelper.t(label)} ${extra}`, options)
  } else {
    return () => {}
  }
}

export default ToastComponent

/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import BsButton from 'react-bootstrap/Button'
import { useParams } from 'react-router-dom'
import { useGetPhysicianReportsQuery } from '../../../store/services/physician'
import DataTable from '../../atoms/DataTable'
import Dropdown from '../../atoms/Dropdown'
import Input from '../../atoms/Input'
import { TableWrapper } from '../../atoms/TableWrapper'
import ToggleBtnGroup from '../../atoms/ToggleBtnGroup'
import DashboardContent from '../../dme/atoms/DashboardContent'

const Wrap = styled.div`
  background: white;
  border-top: 3px solid #2c83bd;
  border-radius: 3px;
  padding: 10px;
  box-shadow: 0 1px 3px 1px #e8e5e5;
  margin-bottom: 10px;
`

const StyledTableWrapper = styled(TableWrapper)`
  margin-top: 20px;

  th {
    padding: 0 0.2rem 0.4rem 0.1rem;
  }

  td[role='cell'] {
    padding: 0.6rem 0.2rem;
    height: 40px;
  }

  td {
    vertical-align: middle;
    padding: 0.6rem;
    div {
      span {
        font-size: 0.9rem;
      }
    }
  }
`

interface IProps {}

const PhysicianReportListing = (props: IProps) => {
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(12)
  const [testType, setTestType] = useState('POX')
  const [sortOptions, setSortOptions] = useState<{ id: string; desc: boolean }>()

  const [search, setSearch] = useState('')
  const { npi } = useParams()

  const {
    data: reports,
    refetch,
    isLoading,
  } = useGetPhysicianReportsQuery(
    {
      search,
      npi,
      testType,
      page: currentPage,
      size: pageSize,
      sortField: sortOptions?.id,
      sortOrder: sortOptions?.desc ? 'desc' : 'asc',
    },
    {
      pollingInterval: 60000,
      refetchOnFocus: true,
    },
  )

  useEffect(() => {
    setCurrentPage(0)
    setSearch('')
    setTestType('POX')
  }, [npi])

  useEffect(() => {
    refetch()
  }, [sortOptions, search, currentPage, pageSize, npi])

  const header = [
    {
      accessor: 'reportId',
      Header: 'Report',
      Cell: ({ row }) => (
        <div css={{ textWeight: 300 }}>
          <div
            css={{
              fontSize: 16,
            }}>
            Report #{row.original.reportId}
          </div>
          <div>Order #{row.original.orderId}</div>
        </div>
      ),
    },
    {
      accessor: 'patientName',
      Header: 'Patient',
      Cell: ({ row }) => (
        <div>
          <div
            css={{
              fontSize: 16,
            }}>
            {row.original.patientName}
          </div>
          <div>DOB: {row.original.patientDob}</div>
        </div>
      ),
    },
    {
      accessor: 'qualification',
      Header: 'Result',
      Cell: ({ row }) => (
        <div>
          {['POX', 'COX'].includes(testType) ? (
            <>
              {row.original.qualification !== 0 && row.original.qualification !== 4 ? (
                <span css={{ color: '#4ea45f' }}>
                  <i className="fas fa-solid fa-check" /> Qualified
                </span>
              ) : (
                <span css={{ color: '#d42020' }}>
                  <span css={{ fontWeight: 600 }}>✕</span> Not Qualified
                </span>
              )}
            </>
          ) : (
            !!row.original.ahi && <span>AHI: {row.original.ahi}</span>
          )}
        </div>
      ),
    },
    {
      accessor: 'createdAt',
      Header: 'Date',
      Cell: ({ row }) => {
        function formatDate(date: Date) {
          const day = String(date.getDate()).padStart(2, '0')
          const month = String(date.getMonth() + 1).padStart(2, '0') // Months are 0-indexed
          const year = date.getFullYear()
          return `${month}/${day}/${year}`
        }

        return <div>{formatDate(new Date(row.original.createdAt))}</div>
      },
    },
    {
      accessor: 'physicianName',
      Header: 'Physician',
      Cell: ({ row }) => <div>{row.original.physicianName}</div>,
    },
    {
      accessor: 'officeName',
      Header: 'DME',
      Cell: ({ row }) => <div>{row.original.officeName}</div>,
    },
    {
      accessor: 'viewUrl',
      Header: 'Actions',
      Cell: ({ row }) => (
        <div>
          <BsButton
            variant={'outline-secondary'}
            className={'mr-2'}
            onClick={() => window.open(row.original.viewUrl, '_blank')}>
            <i className={'fas fa-file mr-2'} /> View
          </BsButton>
          <BsButton
            variant={'primary'}
            onClick={() => window.open(row.original.printUrl, '_blank')}>
            <i className={'fas fa-print mr-2'} /> Print
          </BsButton>
        </div>
      ),
    },
  ]

  return (
    <DashboardContent
      title="Physician Reports"
      content={
        <div className={'p-3'}>
          <Wrap>
            <div
              css={{
                marginTop: 5,
                marginBottom: 15,
                paddingBottom: 15,
                fontSize: 18,
                borderBottom: '1px solid #ced4da',
              }}>
              Reports
            </div>
            <div className={'d-flex justify-content-between'}>
              <div>
                <Dropdown
                  onClick={(v) => {
                    setCurrentPage(0)
                    setPageSize(Number(v))
                  }}
                  buttonTypeClass="btn-outline-primary"
                  options={[
                    { label: '12' },
                    {
                      label: '20',
                    },
                    {
                      label: '50',
                    },
                    {
                      label: '100',
                    },
                  ]}
                  additionalText="Records"
                />
              </div>
              <ToggleBtnGroup
                options={[
                  {
                    key: 'POX',
                    name: 'Pulse-Oximetry',
                  },
                  {
                    key: 'COX',
                    name: 'Capnography',
                  },
                  {
                    key: 'HST',
                    name: 'Home Sleep Test',
                  },
                  {
                    key: 'PSG',
                    name: 'In-Lab Sleep Test',
                  },
                ]}
                value={testType}
                onChange={(testType) => setTestType(testType)}
              />
              <div
                className={'d-flex align-items-center'}
                css={{
                  width: 320,
                }}>
                <p
                  css={{
                    margin: 0,
                    marginRight: 10,
                    fontWeight: 600,
                  }}>
                  Search
                </p>
                <Input
                  value={search || ''}
                  label="Search"
                  placeholder="Search..."
                  name="search"
                  parentDiv={null}
                  handleChange={(e) => setSearch(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      refetch()
                    }
                  }}
                />
              </div>
            </div>
            <StyledTableWrapper>
              <DataTable
                data={reports}
                status={isLoading}
                tableColumns={header}
                pageSize={pageSize}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                setSortOptions={setSortOptions}
                useCustomSortHandler={true}
                entityName="reports"
              />
            </StyledTableWrapper>
          </Wrap>
        </div>
      }
      breadcrumb={[
        { label: 'Home' },
        { label: npi ? `Physician Reports NPI ${npi}` : `All Physician Reports` },
      ]}
    />
  )
}

export default PhysicianReportListing

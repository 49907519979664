/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled'
import { useMemo } from 'react'
import { skipToken } from '@reduxjs/toolkit/query'
import { Formik, Form, Field } from 'formik'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import ReactSelect from 'react-select'
import * as Yup from 'yup'

import { TextInput } from 'components/atoms/Input'
import { CheckboxInput } from 'components/atoms/Checkbox'
import { SelectInput } from 'components/atoms/Select'
import { TextAreaInput } from 'components/atoms/Textarea'
import ButtonCancel from 'components/atoms/ButtonCancel'
import ButtonSave from 'components/atoms/ButtonSave'
import {
  useLazyGetLabDevicesQuery,
  useGetDeviceBrandsQuery,
  useGetLabDeviceQuery,
} from 'store/services/devices'
import { useGetLabOfficesQuery } from 'store/services/office'
import { statusOptions } from './constants'
import { DeviceFormValues } from './DeviceUpsert'

interface IProps {
  id: number | undefined
  onSubmit: (val: any) => void
  allowedDevices?: number[]
  onCancel?: () => void
  submitting?: boolean
}

const TimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;

  .react-datepicker-wrapper {
    flex: 1;

    input {
      width: 100%;
      padding: 7px 8px;
    }
    svg {
      top: 9px !important;
      right: 7px !important;
    }
    button {
      margin-right: 20px;
    }
  }
`

const CreateDeviceForm = ({ id, allowedDevices, onSubmit, onCancel, submitting }: IProps) => {
  const { data: offices } = useGetLabOfficesQuery({})
  const { data: brands } = useGetDeviceBrandsQuery({ userType: 'LAB' })
  const { data: device } = useGetLabDeviceQuery(id ? Number(id) : skipToken)
  const navigate = useNavigate()
  const [getDevices] = useLazyGetLabDevicesQuery()

  const officesOptions = useMemo(
    () =>
      offices?.map((o) => ({
        value: o.id,
        label: `${o.officeName} (${o.addressCity}, ${o.addressState})`,
      })),
    [offices],
  )

  const isTsDevice = (val) => {
    const brand = brands?.items?.find((b) => b.id === Number(val))
    if (
      brand?.name?.toLowerCase()?.includes('testsmarter') ||
      brand?.name?.toLowerCase()?.includes('viatom') ||
      brand?.name?.toLowerCase()?.includes('wesper') ||
      brand?.name?.toLowerCase()?.includes('philips')
    )
      return true
  }

  const formValidation = useMemo(
    () =>
      Yup.object().shape({
        brandId: Yup.string().required('Required'),
        label: Yup.string()
          .required('Required')
          .test('unique-label', 'Label already exists', async function (value) {
            if (this.parent.labelOverride) return true
            const { data } = await getDevices({ label: value })
            const items = data?.items?.find((i) => i.label === value && i.id !== Number(id))
            return !items
          }),
        serial: Yup.string().when('brandId', {
          is: isTsDevice,
          then: Yup.string().required('Required'),
        }),
        mac: Yup.string().when('brandId', {
          is: isTsDevice,
          then: Yup.string().required('Required'),
        }),
        bluetoothId: Yup.string().when('brandId', {
          is: isTsDevice,
          then: Yup.string().required('Required'),
        }),
      }),
    [brands],
  )

  const typeOptions = useMemo(
    () =>
      brands?.items
        ?.map(({ model, id, name }) => ({
          value: name,
          key: id,
        }))
        .filter(({ key }) => !allowedDevices?.length || allowedDevices.includes(key)) || [],
    [brands],
  )

  const initialValues: DeviceFormValues = useMemo(
    () =>
      id === undefined
        ? {
            brandId: undefined,
            label: '',
            serial: '',
            mac: '',
            bluetoothId: '',
            state: '',
            statusUpdatedAt: '',
            officeId: '',
            enabled: false,
            purchaseCost: null,
            salePrice: null,
            notes: '',
            labelOverride: false,
            availability: 'AVALABLE',
          }
        : {
            ...device,
            statusUpdatedAt:
              device?.statusUpdatedAt && device.statusUpdatedAt.toLowerCase() !== 'invalid date'
                ? moment(device?.statusUpdatedAt).toDate()
                : undefined,
          },
    [id, device],
  )

  return (
    <Formik
      enableReinitialize={true}
      validationSchema={formValidation}
      initialValues={initialValues}
      onSubmit={onSubmit}>
      {({ values, setFieldValue, errors }) => (
        <Form>
          <div className="col-sm-6 col-md-4 form-group">
            <label htmlFor="brandId" className="textGray">
              Type
            </label>
            <Field
              component={SelectInput}
              name="brandId"
              options={typeOptions}
              value={values.brandId}
            />
          </div>
          <div className="col-sm-6 col-md-4 form-group">
            <label htmlFor="label" className="textGray">
              Label
            </label>
            <Field component={TextInput} name="label" value={values.label} />
            {!!id && errors?.label === 'Label already exists' && (
              <Field
                component={CheckboxInput}
                name="labelOverride"
                label="Override"
                className="mt-1"
                value={values.labelOverride}
              />
            )}
          </div>
          <div className="col-sm-6 col-md-4 form-group">
            <label htmlFor="serial" className="textGray">
              Serial No
            </label>
            <Field component={TextInput} name="serial" value={values.serial} />
          </div>
          <div className="col-sm-6 col-md-4 form-group">
            <label htmlFor="mac" className="textGray">
              MAC Addr.
            </label>
            <Field name="mac" component={TextInput} value={values.mac} />
          </div>

          <div className="col-sm-6 col-md-4 form-group">
            <label htmlFor="bluetoothId" className="textGray">
              BT ID
            </label>
            <Field component={TextInput} name="bluetoothId" value={values.bluetoothId} />
          </div>
          <div className="col-sm-6 col-md-4 form-group">
            <Field
              component={SelectInput}
              label="Status"
              name="state"
              options={statusOptions.map((o) => ({ value: o.key, key: o.value }))}
              value={values.state}
            />
          </div>
          <div className="col-sm-6 col-md-4 form-group">
            <Field
              component={SelectInput}
              label="Availability"
              name="availability"
              options={['AVAILABLE', 'UNAVAILABLE'].map((o) => ({ value: o, key: o }))}
              value={values.availability}
            />
          </div>
          <div className="col-sm-6 col-md-4 form-group">
            <TimeWrapper>
              <label htmlFor="bluetoothId" className="textGray">
                Status Date
              </label>

              <div>
                {values.statusUpdatedAt ? (
                  <div className="d-flex align-items-center">
                    {moment(values.statusUpdatedAt).format('MMMM D, YYYY')}
                  </div>
                ) : (
                  <div className="d-flex align-items-center">
                    <span className="textGray">No Date</span>
                  </div>
                )}
              </div>
              {/* <DateTimePicker
                          value={values.statusUpdatedAt}
                          handleChange={(date) => setFieldValue('statusUpdatedAt', date)}
                          showTimeSelect={false}
                          isClearable
                          dateFormat="MMMM d, yyyy"
                        /> */}
            </TimeWrapper>
          </div>
          <div className="col-sm-6 col-md-4 input-group mb-3">
            <label className="textGray">Assigned To</label>
            <ReactSelect
              className="basic-single w-100"
              css={{ '& .select__control': { height: 34, minHeight: 'unset' } }}
              classNamePrefix="select"
              isClearable
              isSearchable
              placeholder="Select DME Office"
              name="officeId"
              onChange={(item) => setFieldValue('officeId', item?.value)}
              options={officesOptions}
              value={officesOptions?.find((o) => o.value == values.officeId) ?? null}
            />
          </div>
          <div className="col-sm-6 col-md-4 form-group">
            <Field
              component={TextInput}
              label="Purchase Cost"
              name="purchaseCost"
              type="number"
              value={values.purchaseCost}
            />
          </div>
          <div className="col-sm-6 col-md-4 form-group">
            <Field
              component={TextInput}
              label="Sale Price"
              name="salePrice"
              type="number"
              value={values.salePrice}
            />
          </div>
          <div className="col-sm-6 col-md-4">
            <Field
              component={TextAreaInput}
              name="notes"
              label="Notes"
              value={values.notes}
              rows={3}
              placeholder="Notes..."
            />
          </div>
          <div className="card-footer text-right">
            <ButtonCancel
              className="mr-2"
              onClick={
                onCancel ||
                (() => {
                  navigate(-1)
                })
              }
            />{' '}
            <ButtonSave type="submit" disabled={submitting} />
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default CreateDeviceForm

import { Field, FieldProps } from 'formik'
import { SyntheticEvent } from 'react'
import MaskedInput from 'react-text-mask'

interface IProps {
  value: string
  name: string
  mask: (string | RegExp)[]
  onChange: (e: SyntheticEvent) => void
  placeholder?: string
  autoComplete?: string
  guide?: boolean
  disabled?: boolean
}

const InputMask = ({ value, name, mask, guide, onChange, placeholder, autoComplete, disabled }: IProps) => (
  <Field name={name}>
    {({ field }: FieldProps) => (
      <MaskedInput
        value={value}
        disabled={disabled}
        type="text"
        {...field}
        mask={mask}
        guide={guide}
        className="form-control"
        onChange={onChange}
        placeholder={placeholder}
        autoComplete={autoComplete}
      />
    )}
  </Field>
)

export default InputMask

import { forwardRef, ReactNode, useCallback } from 'react'
import * as generalHelper from '../../helpers/generalHelper'
import { useDropzone } from 'react-dropzone'

interface IProps {
  label?: string
  icon?: string
  className?: string
  handleUpload: (files: File[], props: IProps) => void
  invisible?: boolean
  accept?: { [mimeType: string]: string[] | [] }
  children?: ReactNode
  disabled?: boolean
}

const UploadComponent = forwardRef<HTMLInputElement, IProps>((props: IProps, ref) => {
  const onDrop = useCallback((acceptedFiles: File[], props: IProps) => {
    props.handleUpload(acceptedFiles, props)
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (droppedFiles) => onDrop(droppedFiles, props),
    accept: props.accept || {
      'image/*': ['.jpeg', '.png', '.jpg'],
      'application/pdf': ['.pdf'],
    },
  })
  const label = props.label
    ? generalHelper.t(props.label)
    : generalHelper.t('Click here to select and upload files')
  const icon = props.icon ?? null
  const className = props.className ?? 'btn btn-secondary'
  const children = props.children ?? null

  return (
    <span {...getRootProps()} className={props.invisible ? 'd-none' : ''}>
      <input {...getInputProps()} {...(ref && { ref })} />
      {isDragActive ? (
        <p>Drop files here ...</p>
      ) : (
        <button
          className={className}
          disabled={props.disabled}
          onClick={(e) => {
            e.preventDefault()
          }}>
          {icon && <i className={icon}></i>} {children} {label}
        </button>
      )}
    </span>
  )
})

export default UploadComponent

/** @jsxImportSource @emotion/react */
import { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Markdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

import { useDmeUpdateBulletinMutation } from 'store/services/bulletins'
import { BulletinDto } from 'ts-back-end/src/business/bulletin/dto/bulletin.dto'
import CheckboxComponent from 'components/atoms/Checkbox'

interface IProps {
  open: boolean
  onClose: () => void
  bulletins: BulletinDto[]
}

const BulletinModal = ({ open, onClose, bulletins = [] }: IProps) => {
  const [checked, setChecked] = useState([])
  const [updateBulletin] = useDmeUpdateBulletinMutation()

  const handleSubmit = async () => {
    await Promise.all(
      Object.entries(checked).map(([key, value]) => {
        if (value) {
          return updateBulletin({ id: Number(key), isDismissed: true })
        }
      }),
    )

    onClose()
  }

  return (
    <Modal centered show={open} onHide={onClose}>
      <Modal.Header>
        <Modal.Title>Bulletins</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {bulletins?.length && (
          <div css={{ display: 'flex', justifyContent: 'flex-end', fontWeight: '600' }}>
            Dismiss
          </div>
        )}
        {[...(bulletins ?? [])]
          ?.sort((a, b) => a.priority - b.priority)
          ?.map((bulletin, idx) => (
            <div
              className="d-flex pt-2"
              key={bulletin.id}
              css={{
                flex: 1,
                borderBottom: idx < bulletins?.length - 1 ? '1px solid #ccc' : undefined,
              }}>
              <div className="pr-3">
                <h4>{bulletin.title}</h4>
                <Markdown rehypePlugins={[rehypeRaw]}>{bulletin.message}</Markdown>
              </div>
              <div
                className="ml-auto"
                css={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CheckboxComponent
                  variant="primary"
                  handleChange={() => setChecked((p) => ({ ...p, [bulletin.id]: !p[bulletin.id] }))}
                />
              </div>
            </div>
          ))}
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={false} onClick={handleSubmit}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default BulletinModal

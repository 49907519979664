/** @jsxImportSource @emotion/react */
import { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import HstDeviceTab, { DeviceType } from 'components/dme/orderEdit/views/HstDevice.tab'
import { Order } from 'store/types'

interface IProps {
  order: Order
  open: boolean
  onClose: () => void
  isLab: boolean
}

export const HstDeviceModal = ({ order, open, onClose, isLab }: IProps) => {
  const [deviceSelected, setDeviceSelected] = useState<DeviceType>()

  return (
    <Modal centered show={open} onHide={onClose} size="lg">
      <Modal.Header>
        <Modal.Title>Device Selection</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <HstDeviceTab
          isLab={isLab}
          order={order}
          inModal
          setDeviceSelected={setDeviceSelected}
          submitCallback={onClose}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button type="submit" form={deviceSelected}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

import { SyntheticEvent, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

import TextArea from 'components/atoms/Textarea'
import { VerificationSection } from 'store/types'
import { cannedMessages } from './cannedFailureMsgs'

interface IProps {
  show?: VerificationSection
  onClose: () => void
  onSubmit: (message: string) => void
}

export const FailValidationDialog = ({ show, onClose, onSubmit }: IProps) => {
  const [message, setMessage] = useState('')

  return (
    <Modal centered show={!!show} onHide={onClose}>
      <Modal.Header>
        <Modal.Title>
          FAIL {show?.replace('_', ' ')?.replace('PAYER', 'PAYOR')} VALIDATION
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span className="text-bold text-gray">Attention</span>
        <br />
        <br />
        Are you sure you want to fail validation for this order segment?
        <br />
        <br />
        Please enter a short summary of the reason below that will be used to send a ticket to the
        DME Office. <br />
        <br />
        <div className="form-group">
          <select
            value=""
            className="form-control"
            name="cannedMsg"
            onChange={(e) => setMessage(e.target.value)}>
            <option value="">Select Message Template (optional)</option>
            {cannedMessages.map((s, i) => (
              <option key={i} value={s.content}>
                {s.title}
              </option>
            ))}
          </select>
        </div>
        <TextArea
          handleChange={(e: SyntheticEvent) => setMessage(e.target.value)}
          name="failureReason"
          label="Message Notes"
          value={message}
          rows={5}
          labelClassName="text-gray"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          autoFocus
          variant="primary"
          onClick={() => {
            setMessage('')
            onSubmit(message)
          }}>
          FAIL VALIDATION
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

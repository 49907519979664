export const cannedMessages = [
  {
    title: "AOB Failed - Incorrect Patient",
    content: "The incorrect patient AOB was uploaded to this order. Please upload the correct AOB and resubmit the order for verification",
  },
  {
    title: "AOB Failed - Unsigned",
    content: "An unsigned AOB was uploaded. Please remove, upload a signed AOB, and resubmit the order for verification",
  },
  {
    title: "Insurance Failed - Unable to Verify",
    content: "We are unable to verify the insurance with the information provided. Please update and resubmit the order for verification",
  },
  {
    title: "Insurance Failed - Inactive",
    content: "The insurance information provided is inactive. Please update and resubmit the order for verification",
  }, 
  {
    title: "Rx Failed - Incorrect Document",
    content: "An incorrect document was uploaded under the Rx section. Please remove, upload patients Rx, and resubmit the order for verification",
  },
  {
    title: "Rx Failed - Unsigned",
    content: "Unsigned Rx. Please remove, upload an Rx with the ordering physician&#39;s electronic or physical signature, and resubmit the order for verification",
  }
]
   
import { Box, Grid, Typography } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'

/** @jsxImportSource @emotion/react */
interface IStep {
  label: string
  id: number
}
interface HorizontalStepperProps {
  steps: IStep[]
  activeStep: number
  onStepClick?: (id: number) => void
}
type StepProps = IStep & {
  status: 'completed' | 'active' | 'pending'
  onStepClick?: (id: number) => void
}
const Step = ({ label, id, onStepClick, status }: StepProps) => {
  const stepBackground = {
    completed: '#31AB4C',
    active: '#607D8B',
    pending: '#90A4AE',
  }
  return (
    <Grid
      onClick={() => {
        onStepClick && onStepClick(id)
      }}
      item
      container
      width="100%"
      direction="column"
      rowGap={'5px'}
      sx={{
        padding: '11px 0',
        cursor: 'pointer',
      }}>
      <Box
        sx={{
          width: '100%',
          height: '4px',
          background: stepBackground[status],
          borderRadius: '100px',
        }}></Box>
      <Grid container flexWrap="nowrap" columnGap={'3px'}>
        {status === 'completed' ? (
          <CheckCircleIcon htmlColor={stepBackground[status]} />
        ) : status === 'active' ? (
          <RadioButtonCheckedIcon htmlColor={stepBackground[status]} />
        ) : (
          <RadioButtonUncheckedIcon htmlColor={stepBackground[status]} />
        )}
        <Typography
          key={label}
          sx={{
            width: '100%',
            fontFamily: 'Source Sans Pro',
            color: '#616161',
            fontWeight: status === 'pending' ? 'normal' : '600',
          }}>
          {label}
        </Typography>
      </Grid>
    </Grid>
  )
}
export const HorizontalStepper = ({ steps, activeStep, onStepClick }: HorizontalStepperProps) => {
  const getStepStatus = (index: number) => {
    if (activeStep > index) return 'completed'
    if (activeStep === index) return 'active'
    return 'pending'
  }

  return (
    <Grid container flexWrap="nowrap" columnGap="10px" padding="0 26px">
      {steps.map(({ label, id }, index) => (
        <Step id={id} label={label} status={getStepStatus(id)} onStepClick={onStepClick} />
      ))}
    </Grid>
  )
}

import { ReactNode } from 'react'

interface IProps {
  id: string
  modalSize?: string
  title: string
  content?: ReactNode
  children?: ReactNode
}

const Modal = (props: IProps) => {
  const id = props.id ?? 'modal_default'

  return (
    <div className="modal fade tsModal" id={id}>
      <div
        className={`modal-dialog ${props.modalSize ?? 'modal-md'}`}
        style={{ margin: 'calc(50vh - 250px) auto', zIndex: 1100 }}>
        <div className="modal-content" style={{ border: 'none' }}>
          <div className="modal-header tsModal_header">
            <h4 className="modal-title">{props.title}</h4>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          {props.content}
          {props.children}
        </div>
      </div>
    </div>
  )
}

export default Modal

import { ChangeEvent } from 'react'
import { FieldInputProps } from 'formik'
import Moment from 'moment'

import * as generalHelper from '../../helpers/generalHelper'

interface Option {
  key?: string
  id?: string
  value?: string
  name?: string
  patient?: any
}

interface IProps {
  name: string
  label?: string
  value: string | number | undefined
  handleChange: (e: ChangeEvent<HTMLSelectElement>) => void
  options: Option[]
  custom?: string
  className?: string
  wrapperClassName?: string
  defaultLabel?: string
  labelSeparator?: string
  labelClassName?: string
  disabled?: boolean
  labelKeys?: string[]
  noDefault?: boolean
  error?: string
}

const SelectComponent = (props: IProps) => {
  const options = props && props.options ? props.options : []
  const customValue = props && props.custom ? props.custom : null
  const wrapperClassName = `form-group ${props.wrapperClassName ? props.wrapperClassName : ''}`
  const className = props && props.className ? props.className : 'form-control'
  const defaultLabel = props && props.defaultLabel ? props.defaultLabel : 'Select'
  const labelKeys = props.labelKeys ? props.labelKeys : null
  const labelSeparator = props.labelSeparator ? props.labelSeparator : ' '
  const labelClassName = props.labelClassName ? props.labelClassName : ''
  const disabled = props.disabled ? true : false
  const error = props?.error ?? ''

  return (
    <div className={wrapperClassName}>
      {props.label && (
        <label className={labelClassName + ' text-gray'} htmlFor="last_name">
          {props.label}
        </label>
      )}
      <select
        className={className}
        name={props.name}
        value={props.value ? props.value : ''}
        onChange={(e) => {
          props.handleChange(e)
        }}
        disabled={disabled}>
        {!props.noDefault && <option value=""> {generalHelper.t(defaultLabel)} </option>}
        {Array.isArray(options) &&
          options.map((row, index) => {
            let key,
              value = ''
            if (customValue === 'order') {
              if (row.key) {
                key = row.key
              }
              if (row.id) {
                key = row.id
              }
              if (!key && row.value) {
                key = row.value
              }
              if (row.value) {
                value = row.value
              }
              if (row.name) {
                value = row.name
              }
              let dob = row?.patient?.dob ? `- DOB: ${Moment(row?.patient?.dob).format('l')}` : ''
              value = `${row?.patient?.first_name} ${row?.patient?.last_name} ${dob}`
              return (
                <option key={index} value={key}>
                  {value}
                </option>
              )
            } else if (row) {
              row.key ? (key = row.key) : false
              row.id ? (key = row.id) : false
              !key && row.value ? (key = row.value) : false
              row.value ? (value = row.value) : false
              row.name ? (value = row.name) : false
              if (labelKeys) {
                let labelByKeyArray: string[] = []
                labelKeys.forEach((label_row) => {
                  row[label_row] ? labelByKeyArray.push(row[label_row]) : false
                })
                value = labelByKeyArray.join(labelSeparator)
              }

              return (
                <option key={index} value={key}>
                  {value}
                </option>
              )
            }
          })}
      </select>
      {error && <div className="text-danger">{error}</div>}
    </div>
  )
}

export const SelectInput = ({
  field,
  form,
  ...props
}: {
  form: any
  field: FieldInputProps<string | number>
  label: string
}) => <SelectComponent {...field} {...props} handleChange={field.onChange} error={form.errors[field.name]}/>

export default SelectComponent

import { useEffect } from 'react'
import DashboardContent from '../../atoms/DashboardContent'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getOffice } from '../../../../store/slice/officeSlice'
import { getUsers } from '../../../../store/slice/userSlice'
import * as generalHelper from '../../../../helpers/generalHelper'
import ContentBox from '../../../atoms/ContentBox'

const OfficeDetailView = () => {
  const dispatch = useDispatch()
  const office = useSelector(state => state.office.row)
  const employees = useSelector(state => state.user.rows)
  let { office_id } = useParams()

  const getOfficeParams = {
    id: office_id,
  }

  const getEmployeesParams = {
    office_id: office_id,
  }

  useEffect(() => {
    dispatch(getOffice(getOfficeParams))
    dispatch(getUsers(getEmployeesParams))
  }, [])

  const OfficeComponent = () => {
    return (
      <>
        <div className="row mb-3">
          <div className="col">
            <label className="text-gray">Address</label>
            <br />
            <span>{office.address}</span>
            <br />
            <span>
              {office &&
                office.city &&
                office.state &&
                office.zip &&
                `${office.city}, ${office.state}, ${office.zip}`}
            </span>
          </div>
          <div className="col">
            <label className="text-gray">Region</label>
            <br />
            {
              <span>
                {office.region_id ? office.region_id : 'Region not found'}
              </span>
            }
          </div>
          <div className="col">
            <label className="text-gray">Telephone</label>
            <br />
            <span>
              {office.work_phone
                ? generalHelper.formatPhoneNumber(office.work_phone)
                : 'Phone number not found'}
            </span>
          </div>
          <div className="col">
            <label className="text-gray">FAX</label>
            <br />
            <span>
              {' '}
              {office.fax
                ? generalHelper.formatPhoneNumber(office.fax)
                : 'Fax number not found'}
            </span>
          </div>
        </div>
      </>
    )
  }

  const EmployeesComponent = () => {
    return (
      <div className="table-responsive mb-3">
        <table className="table table-sm">
          <thead>
            <tr>
              <th width="18%" className="table-col">
                {generalHelper.t('Employee ID')}
              </th>
              <th width="18%" className="table-col">
                {generalHelper.t('Employee')}
              </th>
              <th width="18%" className="table-col">
                {generalHelper.t('Role')}
              </th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(employees) &&
              employees.map((row, index) => {
                return (
                  <tr key={index}>
                    <td>{row.id}</td>
                    <td>{`${row.first_name} ${row.last_name}`}</td>
                    <td>
                      <div>Sales Representative</div>
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>
      </div>
    )
  }

  return (
    <>
      <DashboardContent
        title={generalHelper.t('Company Directory')}
        subtitle={office.name}
        content={
          <section className="row">
            <div className="col-sm-8">
              <ContentBox
                title={generalHelper.t('Office Information')}
                content={OfficeComponent()}
              />
              <ContentBox
                title={generalHelper.t('Employees')}
                content={EmployeesComponent()}
              />
            </div>
          </section>
        }
      />
    </>
  )
}

export default OfficeDetailView

const orderStatus = {
  open: 1,
  driverWillDeliver: 2,
  patientWillPickUp: 3,
  patientTesting: 4,
  driverWillPickup: 5,
  patientReturn: 6,
  completed: 7,
  archived: 8,
  cancelled: 9,
  internalUse: 10,
  stock: 11,
  renewedStock: 12,
  consigned: 13,
  sold: 14,
  returnInTransit: 15,
  returnReceived: 16,
  inRepair: 17,
  defective: 18,
  damaged: 19,
  active: 20,
  inactive: 21,
}

export const COMPOUND_BUCKETS = ['ACTIVE_ORDERS', 'ALL_ORDERS']
export const ACTIVE_ORDERS = [
  'OPEN',
  'DRIVER_DELIVERY',
  'PATIENT_PICKUP',
  'PATIENT_TESTING',
  'DRIVER_PICKUP',
  'PATIENT_DROP_OFF',
  'FINALIZE_ORDER',
  'PROCESSING',
  'VERIFICATION',
]
export const COMPOUND_BUCKET_MAP = {
  ACTIVE_ORDERS,
  ALL_ORDERS: [...ACTIVE_ORDERS, 'COMPLETED', 'CANCELLED', 'ARCHIVED'],
}

export default orderStatus

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import Modal from "../../atoms/Modal";
import ButtonSave from "../../atoms/ButtonSave";
import { setModal } from "../../../store/slice/modalSlice";
import * as generalHelper from "../../../helpers/generalHelper";
import { handleChange, handleSubmit } from "../../atoms/Upsert";
import Textarea from "../../atoms/Textarea";
import ButtonCancelModal from "../../atoms/ButtonCancelModal";

const PhysicianNotesModal = (props) => {
    const [values, setValues] = useState({});
    const [redirect, setRedirect] = React.useState(false);
    const dispatch = useDispatch();
    const modal = useSelector((state) => state.modal.row);

    return (
        <>
            <Modal
                {...props}
                content={
                    <div className="container">
                        <div className="modal-body">
                            <div className="col-sm-12">
                                <strong className="textGray">
                                    {generalHelper.t("Attention")}
                                </strong>
                                <br />
                                <p>
                                    You have modified this physicians information
                                </p>

                                <p>
                                    A verification request will be sent to the Lab to approve these changes.
                                </p>
                            </div>
                            <div className="col-12 form-group textGray">
                                <Textarea
                                    label="Add Any Notes or Comments Below"
                                    name="message"
                                    rows="6"
                                    value={values.message}
                                    handleChange={(event) => {
                                        handleChange(event, values, setValues);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <ButtonCancelModal
                                type="button"
                                data_dismiss="modal"
                                onClick={(e) => {
                                    dispatch(setModal({}));
                                }}
                            />
                            <ButtonSave
                                type="button"
                                label="Confirm"
                                data-dismiss="modal"
                                onClick={(event) => {
                                    dispatch(setModal({}));
                                   // handleSubmit(event, unassignDevice())
                                }}
                            />
                        </div>
                    </div>
                }
            />
        </>
    )
}

export default PhysicianNotesModal;
import * as generalHelper from '../../helpers/generalHelper'
import {
  createSlice,
  createAsyncThunk,
  createSelector,
  PayloadAction,
} from '@reduxjs/toolkit'
import { CreatePatient } from '../types'
import { RootState } from 'store/configureStore'

// ------------------------------------------------------------
export const getPatients = createAsyncThunk(
  'getPatients',
  async (params, { rejectWithValue }) => {
    try {
      const data = await generalHelper.apiGet('/api/user', params)
      if (!data) {
        return rejectWithValue([])
      }
      return data
    } catch (error) {
      console.log('error', error)
    }
  }
)

// ------------------------------------------------------------
export const getPatient = createAsyncThunk(
  'getPatient',
  async (params, { rejectWithValue }) => {
    try {
      params.return_key = 'one'
      const data = await generalHelper.apiGet('/api/user', params)
      if (!data) {
        return rejectWithValue([])
      }
      return data
    } catch (error) {
      console.log('error', error)
    }
  }
)

// ------------------------------------------------------------
export const updatePatient = createAsyncThunk(
  'updatePatient',
  async ({ id, params }, { rejectWithValue }) => {
    try {
      const data = await generalHelper.apiPut('/api/user', id, params)
      if (!data) {
        return rejectWithValue([])
      }
      return data
    } catch (error) {
      console.log('error', error)
    }
  }
)

// ------------------------------------------------------------
export const insertPatient = createAsyncThunk(
  'insertPatient',
  async (params, { rejectWithValue }) => {
    try {
      const data = await generalHelper.apiPost('/api/user', params)
      if (!data) {
        return rejectWithValue([])
      }
      return data
    } catch (error) {
      console.log('error', error)
    }
  }
)

// ------------------------------------------------------------
export const deletePatient = createAsyncThunk(
  'deletePatient',
  async ({ id, params }, { rejectWithValue }) => {
    try {
      const data = await generalHelper.apiDelete('/api/user', id, params)
      if (!data) {
        return rejectWithValue([])
      }
      return data
    } catch (error) {
      console.log('error', error)
    }
  }
)

// ------------------------------------------------------------
export const resetPatient = createAsyncThunk(
  'resetPatient',
  async (params, { rejectWithValue }) => {
    return params
  }
)

// ------------------------------------------------------------
interface PatientsState {
  pending: boolean
  rows: any[]
  row: any
  update: any
  insert: any
  delete: any
  rejected: boolean
  patientNewHistory: { patient?: CreatePatient }
}

const initialState: PatientsState = {
  pending: false,
  rows: [],
  row: {},
  update: {},
  insert: {},
  delete: {},
  rejected: false,
  patientNewHistory: {
    patient: undefined,
  },
}

// ------------------------------------------------------------
const localSlice = createSlice({
  name: 'patient',
  initialState,
  reducers: {
    patientNewSaved: (state, action: PayloadAction<CreatePatient>) => {
      state.patientNewHistory = state.patientNewHistory || { patient: null }
      state.patientNewHistory = { patient: action.payload }
    },
    patientHistoryReset: state => {
      state.patientNewHistory.patient = undefined
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPatients.rejected, (state, action) => {
        state.pending = false
        state.rejected = true
        state.data = null
    })
    .addCase(getPatients.pending, (state, action) => {
      state.pending = true
      state.rejected = false
      state.update = null
    })
    .addCase(getPatients.fulfilled, (state, action) => {
      state.pending = false
      state.rejected = false
      state.rows = action.payload
    })
    .addCase(getPatient.rejected, (state, action) => {
      state.pending = false
      state.rejected = true
      state.data = null
    })
    .addCase(getPatient.pending, (state, action) => {
      state.pending = true
      state.rejected = false
      state.update = null
    })
    .addCase(getPatient.fulfilled, (state, action) => {
      state.pending = false
      state.rejected = false
      state.row = action.payload
    })
    .addCase(updatePatient.rejected, (state, action) => {
      state.pending = false
      state.rejected = true
      state.data = null
    })
    .addCase(updatePatient.pending, (state, action) => {
      state.pending = true
      state.rejected = false
      state.update = null
    })
    .addCase(updatePatient.fulfilled, (state, action) => {
      state.pending = false
      state.rejected = false
      state.update = action.payload
    })
    .addCase(insertPatient.rejected, (state, action) => {
      state.pending = false
      state.rejected = true
      state.data = null
    })
    .addCase(insertPatient.pending, (state, action) => {
      state.pending = true
      state.rejected = false
      state.update = null
    })
    .addCase(insertPatient.fulfilled, (state, action) => {
      state.pending = false
      state.rejected = false
      state.insert = action.payload
    })
    .addCase(deletePatient.rejected, (state, action) => {
      state.pending = false
      state.rejected = true
      state.data = null
    })
    .addCase(deletePatient.pending, (state, action) => {
      state.pending = true
      state.rejected = false
      state.update = null
    })
    .addCase(deletePatient.fulfilled, (state, action) => {
      state.pending = false
      state.rejected = false
      state.delete = action.payload
    })
    .addCase(resetPatient.fulfilled, (state, action) => {
      state.pending = false
      state.rejected = false
      action.payload.rows ? (state.rows = action.payload.rows) : false
      action.payload.row ? (state.row = action.payload.row) : false
      action.payload.update ? (state.update = action.payload.update) : false
      action.payload.insert ? (state.insert = action.payload.insert) : false
      action.payload.delete ? (state.delete = action.payload.delete) : false
    })
  },
})

export default localSlice.reducer

export const { patientNewSaved, patientHistoryReset } = localSlice.actions

// -- Selectors
export const newPatientInHistorySelector = createSelector(
  (state: RootState) => state.patient.patientNewHistory,
  patientNewHistory => patientNewHistory?.patient
)

/** @jsxImportSource @emotion/react */
import { useState, useMemo } from 'react'
import moment from 'moment'

import Link from 'components/atoms/Link'
import { useGetTicketsQuery } from 'store/services/tickets'
import { useAppSelector } from 'hooks/redux'
import DataTable from 'components/atoms/DataTable'
import DashboardContent from '../atoms/DashboardContent'
import ButtonNew from 'components/atoms/ButtonNew'
import routes from '../constants/routes'
import Select from 'components/atoms/Select'

const ROWS_PER_PAGE = 20

const STATUSES = [
  { key: '2', value: 'Open' },
  { key: '3', value: 'Pending' },
  { key: '4', value: 'Resolved' },
  { key: '5', value: 'Closed' },
]

const lookupStatus = (status: string) =>
  STATUSES.reduce((agg, curr) => ({ ...agg, [curr.key]: curr.value }), {})[status]

const lookupPriority = (priority: string) =>
  ({
    1: 'Low',
    2: 'Medium',
    3: 'High',
    4: 'Urgent',
  })[priority]

const Ticket = () => {
  const officeId = useAppSelector((state) => state.init.currentOfficeId)
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(ROWS_PER_PAGE)
  const [status, setShowStatus] = useState('2')
  const { data: rawTickets, isLoading } = useGetTicketsQuery({ officeId, status })

  const tickets = useMemo(() => {
    if (rawTickets) {
      return {
        items: rawTickets.results.map((row) => ({
          id: row.id,
          priority: row.priority,
          status: row.status,
          type: row.type,
          subject: row.subject,
          desciption: row.description,
          descriptionText: row.description_text,
          createdAt: row.created_at,
          updatedAt: row.updated_at,
        })),
        total: rawTickets.total,
        totalPages: 1,
      }
    }
    return null
  }, [rawTickets])

  const header = [
    {
      Header: 'ID',
      className: '',
      accessor: 'id',
      Cell: ({ row }) => (
        <Link
          className="border border-primary rounded p-2 mr-1 d-flex align-items-center"
          css={{ width: '48px' }}
          to={`${routes.index}${routes.ticket.root}/${routes.upsert}${row.original.id}`}
          label={`#${row.original.id}`}
        />
      ),
    },
    { accessor: 'type', Header: 'Ticket Type' },
    { accessor: 'subject', Header: 'Subject' },
    {
      accessor: 'status',
      Header: 'Status',
      Cell: ({ row }) => <span>{lookupStatus(row.original.status)}</span>,
    },
    {
      accessor: 'priority',
      Header: 'Priority',
      Cell: ({ row }) => <span>{lookupPriority(row.original.priority)}</span>,
    },
    {
      accessor: 'createdAt',
      Header: 'Created',
      Cell: ({ row }) => <span>{moment(row.original.createdAt).format('lll')}</span>,
    },
  ]

  return (
    <DashboardContent
      title="Tickets"
      icon="fas fa-headset"
      content={
        <div className="card card-primary">
          <form>
            <div className="card-header">
              <h3 className="card-title">
                <div className="d-flex align-items-center">
                  Status
                  <Select
                    wrapperClassName="m-0 ml-2"
                    value={status}
                    handleChange={(e) => setShowStatus(e.target.value)}
                    options={STATUSES}
                  />
                </div>
              </h3>
              <div className="card-tools">
                <Link
                  to={`${routes.index}${routes.ticket.root}/${routes.upsert}`}
                  className="btn btn-primary"
                  label="Contact Support"
                />
              </div>
            </div>
            <div className="card-body row">
              <DataTable
                status={isLoading}
                tableColumns={header}
                data={tickets}
                setCurrentPage={setCurrentPage}
                setPageSize={setPageSize}
                pageSize={pageSize}
                setSortOptions={() => {}}
                entityName="tickets"
              />
            </div>
          </form>
        </div>
      }
      breadcrumb={[{ label: 'Home', to: `${routes.index}` }, { label: 'Tickets' }]}
    />
  )
}

export default Ticket

/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled'
import { Button } from 'react-bootstrap'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import Toast from 'components/atoms/Toast'

import { confirm } from 'components/molecules/Confirmation'
import { phoneNumberMask } from 'helpers/masks'
import MaskInput from 'components/atoms/MaskInput'
import { CompanyCollections } from './CompanyUpsert'
import { KeyPerson } from 'ts-back-end/src/business/company/dto/company.dto'
import { phoneValidation, requiredPhoneValidation } from 'helpers/validate'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const validation = Yup.object().shape({
  fullName: Yup.string().required('Required'),
  title: Yup.string().required('Required'),
  telephone: requiredPhoneValidation,
  directTelephone: phoneValidation,
})

interface IProps {
  keyPeople: KeyPerson[] | null
  onUpdateCompanyCollection: (data: Partial<CompanyCollections>) => void
}

const initialValues = {
  fullName: '',
  title: '',
  telephone: '',
  directTelephone: '',
}

export const KeyPeople = ({ keyPeople, onUpdateCompanyCollection }: IProps) => {
  return (
    <Container>
      <div className="d-flex w-100">
        <Formik
          enableReinitialize={true}
          validationSchema={validation}
          initialValues={initialValues}
          onSubmit={async (values, { resetForm }) => {
            try {
              await onUpdateCompanyCollection({
                keyPersons: [
                  ...(keyPeople || []),
                  {
                    fullName: values.fullName,
                    title: values.title,
                    telephone: values.telephone,
                    directTelephone: values.directTelephone,
                  },
                ],
              })
              resetForm()
              Toast({ type: 'success', label: `People updated!` })
            } catch (err) {
              Toast({ type: 'error', label: err.data.message })
            }
          }}>
          {({ values, errors, touched, handleChange }) => (
            <Form id="keyPeople" className="w-100">
              <div className={`container bg-white p-0`}>
                <div className={`container pt-3 pb-2`}>
                  <div className="row">
                    <div className="col-5 form-group textGray">
                      <label htmlFor="name">
                        Full Name
                        <span className="text-danger">*</span>
                      </label>
                      <Field
                        type="text"
                        name="fullName"
                        className="form-control"
                        placeholder="Full Name"
                        value={values.fullName}
                        onChange={handleChange}
                      />
                      {touched.fullName && errors.fullName && (
                        <div className="text-danger">{errors.fullName}</div>
                      )}
                    </div>
                    <div className="col-5 form-group textGray">
                      <label htmlFor="title">
                        Title <span className="text-danger">*</span>
                      </label>
                      <Field
                        type="text"
                        name="title"
                        className="form-control"
                        placeholder="Title"
                        value={values.title}
                        onChange={handleChange}
                      />
                      {touched.title && errors.title && (
                        <div className="text-danger">{errors.title}</div>
                      )}
                    </div>
                  </div>
                  <div className="row" css={{ alignItems: 'flex-end' }}>
                    <div className="col-5 form-group textGray">
                      <label htmlFor="telephone">
                        Telephone <span className="text-danger">*</span>
                      </label>
                      <MaskInput
                        value={values.telephone}
                        onChange={handleChange}
                        name="telephone"
                        mask={phoneNumberMask}
                        placeholder="(###) ###-####"
                        autoComplete="off"
                      />
                      {touched.telephone && errors.telephone && (
                        <div className="text-danger">{errors.telephone}</div>
                      )}
                    </div>
                    <div className="col-5 form-group textGray">
                      <label htmlFor="lastName">Direct Telephone</label>
                      <MaskInput
                        value={values.directTelephone}
                        onChange={handleChange}
                        name="directTelephone"
                        mask={phoneNumberMask}
                        placeholder="(###) ###-####"
                        autoComplete="off"
                      />
                      {touched.directTelephone && errors.directTelephone && (
                        <div className="text-danger">{errors.directTelephone}</div>
                      )}
                    </div>
                    <div className="col-2 form-group textGray">
                      <Button variant="primary" type="submit" form="keyPeople">
                        Add
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {keyPeople?.length ? (
        <>
          <div
            className="row flex-row flex-nowrap justify-content-between align-items-center p-2 my-2"
            style={{
              color: '#6D757E',
              borderBottom: '1px solid #ced4da',
            }}>
            <div style={{ width: '100%', minWidth: 160 }}>Full Name</div>
            <div style={{ width: '100%' }}>Title</div>
            <div style={{ width: '100%' }}>Telephone</div>
            <div style={{ width: '100%' }}>Direct Telephone</div>
            <div className="flex-shrink-0 pr-3" style={{ width: 100, textAlign: 'end' }}>
              Action
            </div>
          </div>
          {keyPeople.map((person) => (
            <div className="row my-1" key={person.fullName}>
              <div className="col-sm-12 p-0">
                <div className="list-group text-secondary">
                  <div className="list-group-item list-group-item-action flex-column align-items-start">
                    <div className="d-flex py-1 justify-content-between align-items-center">
                      <span css={{ minWidth: 100 }}>{person.fullName}</span>
                      <span css={{ minWidth: 40, maxWidth: 50 }}>{person.title}</span>
                      <span css={{ width: 100 }}>{person.telephone}</span>
                      <span css={{ width: 100 }}>{person.directTelephone}</span>
                      <Button
                        variant="primary"
                        size="sm"
                        onClick={() =>
                          confirm('contact')
                            .then(() =>
                              onUpdateCompanyCollection({
                                keyPersons: keyPeople.filter(
                                  (p) =>
                                    !(p.fullName === person.fullName && p.title === person.title),
                                ),
                              }),
                            )
                            .catch(() => {})
                        }>
                        Remove
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      ) : (
        <div className="row my-1 mt-3">
          <div className="col-sm-12 p-0">
            <div className="list-group text-secondary">
              <div className="list-group-item list-group-item-action flex-column align-items-center">
                <div className="d-flex py-1 justify-content-center align-items-center">
                  <span>No contacts provided</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Container>
  )
}

import Moment from 'moment'
import { debounce, snakeCase } from 'lodash'
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query'

import axiosApiInstance from './axios'
import config from 'config/app'
import { KeyVal } from 'store/types'
import { getLogout } from 'store/slice/loginSlice'

const host = config?.api
const newHost = config?.newApi

const baseQuery = fetchBaseQuery({
  baseUrl: newHost,
  prepareHeaders: (headers) => {
    const token = localStorage.getItem('access_token')
    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    }
    return headers
  },
})

export const baseQueryWithLogout: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions)
  if (result?.error && result.error.status === 401) {
    api.dispatch(getLogout())
  }
  return result
}

/**
 * Updates an existing Model in the API
 *
 * @param {*} url
 * @param {*} id
 * @param {*} params
 * @returns
 */
export const apiPut = async (url: string, id: string | number, params: any) => {
  try {
    const returnValue = await axiosApiInstance.put(`${newHost}${url}/${id}`, params, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
    return returnValue && returnValue.data && returnValue.data.data ? returnValue.data.data : null
  } catch (error) {
    console.log('error', error)
  }
}

export const apiDelete = async (url: string, id: string | number, params: any) => {
  try {
    const returnValue = await axiosApiInstance.delete(`${newHost}${url}/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      data: params,
    })
    return returnValue && returnValue.data && returnValue.data.data ? returnValue.data.data : null
  } catch (error) {
    console.log('error', error)
  }
}

export const apiPost = async (url: string, params: any) => {
  try {
    const returnValue = await axiosApiInstance.post(`${newHost}${url}`, params, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
    return returnValue && returnValue.data && returnValue.data.data
      ? returnValue.data.data
      : returnValue.data
  } catch (error) {
    console.log('error', error)
  }
}

export const axiosRequest = async (params: any) => {
  const returnValue = await axiosApiInstance.request({
    ...params,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      ...params.headers,
    },
  })
  return returnValue
}

export const apiGet = async (url: string, params: any) => {
  try {
    const returnValue = await axiosApiInstance.get(`${newHost}${url}`, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
    return returnValue.data
  } catch (error) {
    console.log('error', error)
  }
}

export const getPT = (url: string, params: any) => {
  let urlParameters = Object.entries(params)
    .map((e) => e.join('='))
    .join('&')
  return `${host}/api/pt/${url}?_token=${localStorage.getItem('access_token')}&${urlParameters}`
}

export function getUserRole(data) {
  let returnArray: string[] = []

  if (Array.isArray(data)) {
    data.forEach((row) => {
      row && row.role && row.role.name ? returnArray.push(row.role.name) : false
    })
    returnArray = [...new Set(returnArray)]
  }
  return returnArray
}
export function t(key: string) {
  let returnValue = key
  try {
    const language = localStorage.getItem('language')
    if (language) {
      let Lang = require(`../language/${language}.json`)
      Lang && key && Lang[key] ? (returnValue = Lang[key]) : null
    }
  } catch (err) {}
  return returnValue
}

/**
 *
 * @param {object} data raw row data from user table
 * @param {string} key key to extract fromn payload
 */
export function getUserPreference(data, pkey = 'report_options') {
  let _data = []
  data.preference?.forEach((element) => {
    // Get stored preferences for report_options
    if (element.data.hasOwnProperty(pkey)) {
      _data.push(element.data[pkey])
    }
  })
  return _data.length ? _data : false
}

export function arrayFromKey(array = [], key = '', unique = false) {
  let returnValue = []
  try {
    if (array && Array.isArray(array)) {
      array.forEach((row) => {
        returnValue.indexOf(key) < 0 ? returnValue.push(row[key]) : false
      })
      if (unique) {
        returnValue = arrayUniqueBy(returnValue, 'id')
      }
    }
  } catch (error) {}
  return returnValue
}

/**
 * Set values for userPreference Pbject keys
 * @param {*} key
 * @param {*} value
 */
export async function saveUserPreferences(id: number, rootKey: string, key: string, value: string) {
  //Update existing preferences.
  await apiPut(
    'api/user-preferences',
    id,
    {
      data: {
        [rootKey]: {
          [key]: value,
        },
      },
    },
    true,
  )
}

export function arrayUniqueBy(array, key) {
  return [...new Map(array.map((item) => [item[key], item])).values()]
}

export function dateDiff(from_date, to_date, return_type = 'day') {
  let returnValue = ''
  if (from_date && to_date) {
    from_date = from_date == 'today' ? new Date() : new Date(from_date.replace(/-/g, '/'))
    to_date = to_date == 'today' ? new Date() : new Date(to_date.replace(/-/g, '/'))
    let diff = Math.abs(from_date - to_date)
    return_type == 'day' ? (returnValue = Math.round(diff / 1000 / 60 / 60 / 24)) : false
    return_type == 'hour' ? (returnValue = diff / 1000 / 60 / 60) : false
  }
  return returnValue
}

export function getLabelValue(array: any[], value_key: string, label_key: string): any[] {
  let returnValue: any[] = []
  if (array && Array.isArray(array)) {
    array.forEach((row) => {
      let label = ';'
      if (label_key === 'full_name') {
        let dob = row.dob ? `- DOB: ${Moment(row.dob).format('l')}` : ''
        label = `${row.first_name} ${row.last_name} ${dob}`
      } else if (label_key === 'patient') {
        let dob = row[label_key]?.dob ? `- DOB: ${Moment(row[label_key]?.dob).format('l')}` : ''
        label = `${row[label_key]?.first_name} ${row[label_key]?.last_name} ${dob}`
      } else {
        label = row[label_key]
      }
      let value = row[value_key]

      returnValue.push({ label, value })
    })
  }
  return returnValue
}

export function stringTo(string, separator = '_') {
  string = string.replace(/ /g, separator).toLowerCase()
  return string
}

export function getStatusById(array, id) {
  let returnValue = {}
  if (Array.isArray(array)) {
    returnValue = searchObject(array, 'id', id, null, null, null, null, 'one')
  }
  return returnValue
}

export function getStatus(array, type, name) {
  let returnValue = {}
  if (Array.isArray(array)) {
    returnValue = searchObject(
      array,
      'type',
      type,
      name ? 'name' : null,
      name,
      null,
      null,
      name ? 'one' : null,
    )
  }
  return returnValue
}

export function searchObject(object, field, value, field2, value2, field3, value3, return_key) {
  var returnValue = []

  if (typeof object == 'object') {
    if (object.length > 0 && Array.isArray(object)) {
      object.forEach(function (row) {
        if (row && row[field] && row[field] == value && row[field2] && row[field2] == value3) {
          returnValue.push(row)
        }
        if (row && row[field] && row[field] == value && row[field2] && row[field2] == value2) {
          returnValue.push(row)
        } else if (row && row[field] && row[field] == value && !field2) {
          returnValue.push(row)
        }
      })

      if (return_key && return_key == 'one') {
        if (returnValue && returnValue.length == 1) {
          returnValue = returnValue[0]
        } else {
          if (returnValue[1] && returnValue[1].total) {
            returnValue.total = returnValue[0].total + returnValue[1].total
          }
        }
      }
    }
  }
  return returnValue
}

export function spellNumber(num: number): string {
  const names = [
    '0',
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine',
    'ten',
    '...',
  ]
  return names[num]
}

export function maybePlural(word: string, quantifier: number): string {
  return quantifier === 1 ? word : `${word}s`
}

export function closeOneModal(modalId: string) {
  // get modal
  const modal = document.getElementById(modalId)

  // change state like in hidden modal
  if (modal) {
    modal.classList.remove('show')
    modal.setAttribute('aria-hidden', 'true')
    modal.setAttribute('style', 'display: none')
  }

  // get modal backdrop
  const modalBackdrops = document.getElementsByClassName('modal-backdrop')

  // remove opened modal backdrop
  document.body.removeChild(modalBackdrops[0])
}

export function groupBy(array: any[], key: string) {
  return array.reduce(function (rv, x) {
    ;(rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})
}

export function debounceSearch(search_value, setFunction, timeout) {
  return debounce(
    () => {
      setFunction(search_value)
    },
    search_value?.length > 0 ? (timeout ? timeout : 800) : 0,
  )
}

export function formatPhoneNumber(number: string) {
  if (number.length !== 10) {
    return 'Invalid number'
  }
  let cleaned = ('' + number).replace(/\D/g, '')
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  }
  return null
}

export function formatName(
  first_name: string,
  middle_name: string,
  last_name: string,
  suffix: string,
): string {
  return `${last_name}${suffix && suffix !== 'Suffix' ? ` ${suffix}` : ''}, ${first_name}${
    middle_name ? ` ${middle_name}` : ''
  }`
}

export function formatTestTypes(testType: string): string | undefined {
  if (testType.indexOf('Pulse') >= 0) {
    return 'Pulse-Oximetry [POX]'
  }
  if (testType.indexOf('Cap') >= 0) {
    return 'Capnography [CAPOX]'
  }
  if (
    testType === 'HST' ||
    testType === 'Home Sleep Testing' ||
    testType.indexOf('Home') >= 0 ||
    testType.indexOf('HST') >= 0
  ) {
    return 'Home Sleep Testing [HST]'
  }
  if (
    testType.indexOf('PSG') >= 0
  ) {
    return 'In-Lab Sleep Testing [PSG]'
  }
}

export const cleanObj = (obj: KeyVal | null | undefined, removeFalse = false): KeyVal => {
  if (!obj) return {}
  return Object.entries(obj).reduce((agg: KeyVal, [key, val]) => {
    if (val !== undefined && val !== '' && val !== null && (!removeFalse || val !== false)) {
      agg[key] = val
    }
    return agg
  }, {})
}

export const toSnakeCase = (obj: KeyVal = {}) =>
  Object.keys(obj).reduce((res: KeyVal, key: string) => {
    const newKey = snakeCase(key)
    res[newKey] = obj[key]
    return res
  }, {})

export const formatZip = (zip: string) => {
  if (zip?.length > 5) {
    return zip.slice(0, 5) + '-' + zip.slice(5)
  }
  return zip
}

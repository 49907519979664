import React from 'react'
import { createRoot } from 'react-dom/client'
import * as Sentry from '@sentry/react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { Provider as ReaderProvider, createReader, config } from './components/reader'
import store from './store/configureStore'
import reportWebVitals from './reportWebVitals'
import App from './App'
import env from './config/app'

const reader = createReader(config.url, {
  autoConnect: false,
  minVersion: config.minVersion,
})

Sentry.init({
  dsn: env?.sentryDsn,
  integrations: [
    // new Sentry.BrowserTracing({
    //   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    //   tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

const persistor = persistStore(store)

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ReaderProvider reader={reader}>
          <ToastContainer position="bottom-left" autoClose={10000} />
          <App />
        </ReaderProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

console.log(
  `=================================================${''}\n
    Node Environment: ${process.env.NODE_ENV}\n
    App Name: ${process.env.REACT_APP_NAME}\n
    App Environment: ${process.env.REACT_APP_ENV}\n
    App Version: ${process.env.REACT_APP_VERSION}\n
    App API: ${process.env.REACT_APP_API_URL}\n
  =================================================`,
)

/** @jsxImportSource @emotion/react */

const PaperworkComplete = () => {
  return (
    <div className="payment-success">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="payment-success__content text-center">
              <div className="d-flex align-items-center justify-content-center">
                <i className="fa fa-check text-success mr-3" css={{ fontSize: 30 }} />
                <h1>Patient Paperwork is Complete</h1>
              </div>
              <div>
                <p css={{ fontSize: 18 }}>
                  Thank you, we have received your questionnaire and payment. We will be in touch
                  shortly with further instructions.
                </p>
              </div>
              <div className="payment-success__content__button">
                <button className="btn btn-primary" onClick={() => window.close()}>
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PaperworkComplete

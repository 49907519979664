/** @jsxImportSource @emotion/react */
import { Device, devices } from 'components/reader/devices'

interface IProps {
  data?: Device[]
  onChange: (device: Device) => void
}

const DeviceSelector = ({ data = devices, onChange }: IProps) => {
  return (
    <ul className="row justify-content-around align-items-center">
      {data.map((device, i) => (
        <li
          role="button"
          key={`device-${device.type}-${i}`}
          className="py-6 mx-2 px-2 col-auto justify-content-center align-items-center"
          onClick={() => onChange(device)}>
          <figure className="text-center">
            <img
              src={device.image}
              className="mb-4"
              css={{
                width: 150,
                transition: 'transform .3s ease',
                ':hover': { transform: 'scale(1.2)' },
              }}
              alt=""
            />
            <figcaption>{device.name}</figcaption>
          </figure>
        </li>
      ))}
    </ul>
  )
}

export default DeviceSelector

import { baseApi } from './baseApi'
import { TestTypesList } from '../types'

export const testTypesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getTestTypes: builder.query<TestTypesList, { officeId: string }>({
      query: ({ officeId }) => ({ url: `/dme/offices/${officeId}/test-types` }),
    }),
  }),
})

export const { useGetTestTypesQuery, useLazyGetTestTypesQuery } = testTypesApi

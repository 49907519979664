import { forwardRef } from 'react'
import * as generalHelper from '../../helpers/generalHelper'

const ButtonCancelModalComponent = forwardRef((props, ref) => {
  const className = props.className ?? 'btn btn-default'
  const icon = props.icon ?? 'fa fa-times'
  const type = props.type ?? 'submit'
  const onClick = props.onClick ? props.onClick : () => {}
  const data_dismiss = props.data_dismiss ?? ''
  const label = props.label ?? 'Cancel'

  return (
    <button
      ref={ref}
      type={type}
      onClick={onClick}
      className={className}
      data-dismiss={data_dismiss}
    >
      {generalHelper.t(label)}
    </button>
  )
})

export default ButtonCancelModalComponent

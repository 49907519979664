import { notificationTypes, DEFAULT_NOTIFICATION_PREFS } from './notificationsData'
import { confirm } from 'components/molecules/Confirmation'

type FormValues = {
  [key: string]: boolean
}

type NotificationPrefs = {
  [key: string]: {
    email: boolean
    sms: boolean
  }
}

export const getUserNotificationPrefs = (
  formValues: FormValues,
): [NotificationPrefs, FormValues] => {
  const notificationPrefs: NotificationPrefs = {}
  const otherFormVals: FormValues = formValues

  Object.keys(notificationTypes).forEach((key) => {
    notificationPrefs[key] = {
      email: formValues[`EMAIL_${key}`] || false,
      sms: formValues[`SMS_${key}`] || false,
    }

    delete otherFormVals[`EMAIL_${key}`]
    delete otherFormVals[`SMS_${key}`]
  })

  return [notificationPrefs, otherFormVals]
}

export const getInitialFormValues = (notificationPrefs: NotificationPrefs): FormValues => {
  const formValues: FormValues = {}

  Object.keys(notificationTypes).forEach((key) => {
    formValues[`EMAIL_${key}`] = notificationPrefs?.[key]?.email || false
    formValues[`SMS_${key}`] = notificationPrefs?.[key]?.sms || false
  })

  return formValues
}

export const handleRoleChange = (values, setValues) => (event) => {
  const role = event.target.value
  const { role: currentRole, ...rest } = values
  const prefs = {}

  confirm('', {
    title: 'Change Notification Preferences?',
    description: `Do you want to update this user's notification preferences to match this role?`,
    yesAction: 'Yes',
    noAction: 'No',
  })
    .then(() => {
      Object.keys(notificationTypes).forEach((key) => {
        prefs[`EMAIL_${key}`] = DEFAULT_NOTIFICATION_PREFS[role][`EMAIL_${key}`] ?? false
        prefs[`SMS_${key}`] = DEFAULT_NOTIFICATION_PREFS[role][`SMS_${key}`] ?? false
      })
      setValues({ ...rest, ...prefs, role })
    })
    .catch(() => setValues({ ...rest, role }))
}

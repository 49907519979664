/** @jsxImportSource @emotion/react */
import Button from 'react-bootstrap/Button'

import { useAppSelector } from 'hooks/redux'
import * as generalHelper from 'helpers/generalHelper'
import DashboardContent from 'components/dme/atoms/DashboardContent'
import CalloutComponent from 'components/atoms/Callout'
import { forms } from './formData'

const formObj = forms.reduce((agg, { type, ...data }) => {
  if (Array.isArray(agg[type])) {
    agg[type].push(data)
  } else {
    agg[type] = [data]
  }
  return agg
}, {})

const PrintableForms = () => {
  const officeId = useAppSelector((state) => state.init.currentOfficeId)

  const downloadDocument = (documentKey: string, officeInfoType: string) => async () => {
    const data = await generalHelper.apiGet(`/dme/office/${officeId}/documents`, {
      documentKey,
      officeInfoType,
    })
    window.open(data, '_blank')
  }

  return (
    <DashboardContent
      title="Printable Forms"
      icon="fa fa-cloud-download-alt"
      content={
        <div className="col-12">
          <div className="card card-default">
            <div className="card-body">
              {Object.entries(formObj).map(([type, forms]) => (
                <div>
                  <CalloutComponent title={type} className="info pt-2 pb-1" content={<div></div>} />
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="table-responsive">
                        <table className="table table-hover table-sm" css={{ maxWidth: 800 }}>
                          <thead>
                            <tr>
                              <th>Form Type</th>
                              <th>English</th>
                              <th>Spanish</th>
                            </tr>
                          </thead>
                          <tbody>
                            {forms.map((form, index) => (
                              <tr key={index}>
                                <td css={{ verticalAlign: 'middle !important', minWidth: 350 }}>
                                  {form?.name}
                                </td>
                                <td>
                                  <Button
                                    as="a"
                                    size="sm"
                                    onClick={downloadDocument(form.keyEn, form.officeInfoType)}>
                                    <i className="fa fa-download mr-2" />
                                    Download English
                                  </Button>
                                </td>
                                <td>
                                  {form.keyEs && (
                                    <Button
                                      as="a"
                                      size="sm"
                                      onClick={downloadDocument(form.keyEs, form.officeInfoType)}>
                                      <i className="fa fa-download mr-2" />
                                      Download Spanish
                                    </Button>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      }
      breadcrumb={[{ label: 'Downloads' }]}
    />
  )
}

export default PrintableForms

/** @jsxImportSource @emotion/react */
import { HST_STEPS, PSG_STEPS } from 'helpers/orderStateHelpers'
import { Order } from 'store/types'
import { CaptureConsent } from './CaptureConsent'
import { DeviceShipping } from './DeviceShipping'
import { FollowUpSection } from './FollowUpSection'
import { PatientScheduling } from './PatientScheduling'
import { PatientTesting } from './PatientTesting'
import { PreAuthorization } from './PreAuthorization'
import { PsgPatientScheduling } from './PsgPatientScheduling'

interface IProps {
  orderState: { hstStep: HST_STEPS }
  order: Order
}

export const HstWorkflow = ({ orderState, order }: IProps) => {
  const { hstStep } = orderState

  if (hstStep === HST_STEPS.PATIENT_CONSENT) {
    return <CaptureConsent order={order} />
  }
  if (hstStep === HST_STEPS.PRE_AUTHORIZATION) {
    return <PreAuthorization order={order} />
  }
  if (hstStep === HST_STEPS.PATIENT_SCHEDULING) {
    return <PatientScheduling order={order} />
  }

  return (
    <>
      <div css={{ padding: 20 }}>
        <FollowUpSection order={order} />
      </div>
      <DeviceShipping order={order} orderState={orderState} />
      <PatientTesting order={order} />
    </>
  )
}

interface IPropsPsg {
  step: PSG_STEPS
  order: Order
}

export const PsgWorkflow = ({ step, order }: IPropsPsg) => {
  if (step === PSG_STEPS.PATIENT_CONSENT) {
    return <CaptureConsent order={order} />
  }
  if (step === PSG_STEPS.PRE_AUTHORIZATION) {
    return <PreAuthorization order={order} />
  }

  return (
    <>
      <div css={{ padding: 20 }}>
        <FollowUpSection order={order} />
      </div>
      {order?.bucket !== 'COMPLETED' && <PsgPatientScheduling order={order} />}
    </>
  )
}

import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import * as generalHelper from 'helpers/generalHelper'

interface IProps {
  onlabel?: string
  offlabel?: string
  positionlabel?: string
  label?: string
  width?: number
  size?: 'xs' | 'sm' | 'md' | 'lg'
  checked?: boolean
  onChange?: (checked: boolean) => void
}

const SwitchComponent = (props: IProps) => {
  const positionlabel = props.positionlabel ? props.positionlabel : 'none'
  const onlabel = props.onlabel ?? 'Yes'
  const offlabel = props.offlabel ?? 'No'
  const label = props.label ?? ''
  const width = props.width ?? 60
  const size = props.size ?? 'sm'
  const checked = props.checked ?? false
  const onChange = props.onChange ?? (() => {})

  return (
    <div>
      {positionlabel === 'before' && <label>{generalHelper.t(label)}</label>}
      {positionlabel === 'before' && <br />}
      <BootstrapSwitchButton
        width={width}
        size={size === 'md' ? undefined : size}
        checked={checked}
        onlabel={generalHelper.t(onlabel)}
        offlabel={generalHelper.t(offlabel)}
        onChange={onChange}
      />
      {positionlabel === 'after' && <span> {generalHelper.t(label)}</span>}
    </div>
  )
}

export default SwitchComponent

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import { Order } from 'store/types'

interface IProps {
  order: Order
  open: boolean
  onClose: () => void
  onSubmit: () => Promise<void>
}

export const AddDocumentModal = ({ order, open, onClose }: IProps) => {
  return (
    <Modal centered show={open} onHide={onClose} size="md">
      <Modal.Header>
        <Modal.Title>Add Document</Modal.Title>
      </Modal.Header>
      <Modal.Body></Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

/** @jsxImportSource @emotion/react */
import { useEffect, useMemo, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { css } from '@emotion/react'
import { toast } from 'react-toastify'
import { useDebounce } from 'use-debounce'
import { Document, Page, pdfjs } from 'react-pdf'

import Input from 'components/atoms/Input'
import { UploadType } from 'store/types'
import { useSearchOrdersQuery } from 'store/services/labOrder'
import Checkbox from "components/atoms/Checkbox";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`

const allBuckets = [
  'PRE_OPEN',
  'OPEN',
  'DRIVER_DELIVERY',
  'PATIENT_PICKUP',
  'PATIENT_TESTING',
  'DRIVER_PICKUP',
  'PATIENT_DROP_OFF',
  'FINALIZE_ORDER',
  'COMPLETED',
  'CANCELLED',
  'ARCHIVED',
  'UNKNOWN',
]

interface IProps {
  show?: string
  onClose: () => void
  onSubmit: (orderId: number, faxUuid: string, uploadTypes: UploadType[]) => Promise<void>
  faxMediaUrl?: string
}

const orderTitle = {
  HST: 'Home Sleep Test',
  PSG: 'In-Lab Sleep Test',
  POX: 'Pulse Oximetry',
  COX: 'Capnography',
}

export const AssignDialog = ({ show, onClose, onSubmit, faxMediaUrl }: IProps) => {
  const [numPages, setNumPages] = useState<number | null>(null)
  const [patient, setPatient] = useState('')
  const [page, setPage] = useState(0)
  const [documentTypes, setDocumentTypes] = useState<UploadType[] | undefined>(['RX'])
  const [selected, setSelected] = useState('')
  const [debouncedPatient] = useDebounce(patient, 1000)
  const patientSearch =
    patient?.length >= 2
      ? { patient: debouncedPatient, buckets: allBuckets }
      : { patient: '!', buckets: [] }
  const { data: orders, isLoading } = useSearchOrdersQuery({
    ...patientSearch,
    size: 10,
    page,
  })
  const docUrl = useMemo(() => faxMediaUrl, [show])

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages)
  }

  const getDocNeeded = (order = {}) => {
    const { progress } = order
    const { AOB, RX } = progress
    const needs = []
    if (['PENDING', 'PROMISED'].includes(RX)) needs.push('RX')
    if (['PENDING', 'PROMISED'].includes(AOB)) needs.push('AOB')
    if (!needs.length) return ''
    return `(needs ${needs.join(', ')})`
  }

  useEffect(() => {
    setPatient('')
    setDocumentTypes(undefined)
    setSelected('')
    setPage(1)
  }, [show])

  useEffect(() => {
    setPage(0)
  }, [debouncedPatient])

  const assign = async () => {
    try {
      if (documentTypes?.length) {
        const types = documentTypes.map((x) => x.toUpperCase().replace(/\s/g, '_'))
        await onSubmit(Number(selected), show!, types as UploadType[]).unwrap()
        onClose()
      }
    } catch (err) {
      toast.error('Unable to assign document')
    }
  }

  return (
    <Modal centered show={!!show} onHide={onClose} size="xl">
      <Modal.Header>
        <Modal.Title>Assign To Order</Modal.Title>
      </Modal.Header>
      <Modal.Body
        css={css`
          display: flex;
        `}>
        <div
          css={css`
            flex: 1;
            padding-right: 10px;
            border-right: 1px solid lightgray;
          `}>
          <Input
            value={patient}
            placeholder="Enter patient name or order # to search"
            label="Search"
            name="patientSearch"
            handleChange={(e) => setPatient(e.target.value)}
          />
          {isLoading && <>Loading...</>}
          {orders?.totalItems > 0 && (
            <div
              className="mt-3"
              css={css`
                overflow-y: auto;
                cursor: pointer;
              `}>
              <div
                css={css`
                  display: flex;
                  justify-content: space-between;
                `}>
                <span>{orders.totalItems} Search Results:</span>
                <div
                  css={css`
                    display: flex;
                    justify-content: space-between;
                  `}>
                  <Button variant="link" onClick={() => setPage(page - 1)} disabled={page === 0}>
                    Previous
                  </Button>
                  <Button
                    variant="link"
                    onClick={() => setPage(page + 1)}
                    disabled={page === orders.totalPages - 1}>
                    Next
                  </Button>
                </div>
              </div>
              <div className="list-group text-secondary mt-1 mb-2">
                {orders?.items.map((order) => (
                  <div
                    className="list-group-item list-group-item-action flex-column align-items-start"
                    css={css`
                      line-height: 1.2rem;
                      background: ${order.id === selected ? '#2c83bd80 !important' : 'unset'};
                    `}
                    onClick={() => setSelected(order.id)}>
                    <strong>
                      {order.patient.firstName} {order.patient.lastName}
                    </strong>{' '}
                    {getDocNeeded(order)}
                    <br />
                    {orderTitle[order?.testType || 'POX']} Order #{order.id}, {order.officeName}
                  </div>
                ))}
              </div>
              {orders?.totalPages > 1 && <span>Please refine search to see other matches</span>}
            </div>
          )}
          <div className="mt-3">
            <label className="text-gray">Document Type:</label>
            <br />
            <div className="d-flex">
              {['RX', 'AOB', 'Office Notes', 'Billing Doc', 'Other'].map((type) => (
                <Checkbox
                  name="documentType"
                  value={documentTypes?.includes(type)}
                  className="form-check-input"
                  parentClassName="col ml-4 align-items-center"
                  checked={documentTypes?.includes(type)}
                  handleChange={(e) => {
                    if(e.target?.checked) {
                      setDocumentTypes((x) => x ? [...x, type] : [type])
                    } else {
                      setDocumentTypes((x) => x?.filter((t) => t !== type))
                    }
                  }}
                  label={type}
                />
              ))}
            </div>
          </div>
        </div>
        <div
          css={css`
            max-height: 80vh;
            overflow-y: auto;
          `}>
          <Document
            loading={'Loading PDF ...'}
            file={docUrl}
            onLoadSuccess={onDocumentLoadSuccess}
            externalLinkTarget="_blank">
            {Array.from({ length: numPages }, (v, i) => i + 1).map((page) => (
              <Page loading="" pageNumber={page} scale={1} key={page} />
            ))}
          </Document>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button autoFocus variant="primary" onClick={assign} disabled={!documentTypes?.length || !selected}>
          Assign
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

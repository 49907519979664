export const BILLING_LOCATION_CODES = [
  { key: '9', value: 'Sleep Solutions HST' },
  { key: '10', value: 'Sleep Solutions PO.' },
  { key: '7', value: 'TestSmarter HST – FL' },
  { key: '8', value: 'TestSmarter HST – NE' },
  { key: '3', value: 'TestSmarter - FL' },
  { key: '4', value: 'TestSmarter - NE' },
]

export const billingLocationCodeLookup = BILLING_LOCATION_CODES.reduce((acc, val) => {
  acc[val.key] = val.value
  return acc
}, {})

/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import { useAppSelector } from '../../../hooks/redux'
import {
  useGetOfficeOrderCountsQuery,
  useGetOfficeOrderStatsQuery,
} from '../../../store/services/dmeOrder'

const StyledLink = styled.span`
  margin-bottom: -3px !important;
  padding: 6px 0px 3px 0px !important;
  min-width: 70px;
  font-weight: 400 !important;
  height: 30px;
  &.active {
    border-color: #cbcbcb !important;
    &:hover {
      border-bottom: 1px solid white !important;
      margin-bottom: -2px !important;
    }
  }
`

interface IProps {
  testType: string
}

const OrderStatsFooter = ({ testType }: IProps) => {
  const { data: officeOrderCounts } = useGetOfficeOrderCountsQuery({
    testType,
  })
  const [sideTab, setSideTab] = useState('this-month')
  const [dateRange, setDateRange] = useState<{
    dateStart: string | undefined
    dateEnd: string | undefined
  }>({ dateStart: undefined, dateEnd: undefined })
  const { data: officeOrderStats } = useGetOfficeOrderStatsQuery({
    testType,
    ...dateRange,
  })
  const userOffices = useAppSelector((state) => state.init.offices)

  const getQuarter = (month: number) => {
    return Math.floor(month / 3) + 1
  }

  const getOptions = () => {
    const now = new Date()
    const thisMonth = now.getMonth()
    const thisYear = now.getFullYear()

    const options = ['this-month', 'last-month']

    const currentQuarter = getQuarter(thisMonth)
    options.push(`q${currentQuarter}-${thisYear}`)

    for (let i = 1; i <= 3; i++) {
      let quarterYear = thisYear
      let quarter = currentQuarter - i
      if (quarter < 1) {
        quarter += 4
        quarterYear--
      }
      options.push(`q${quarter}-${quarterYear}`)
    }

    return options
  }

  const getLabelCounts = (count: number) =>
    `(${count} completed)`

  const getOptionLabel = (option: string, i: number) => {
    switch (option) {
      case 'this-month':
        return `This Month ${getLabelCounts(officeOrderCounts.thisMonth)}`
      case 'last-month':
        return `Last Month ${getLabelCounts(officeOrderCounts.lastMonth)}`
      default: {
        const index = i - 1
        const [q, year] = option.split('-')

        return `${q.toUpperCase()} ${year} ${getLabelCounts(officeOrderCounts[`q${index}`])}`
      }
    }
  }

  const setTimeRange = () => {
    const today = new Date()

    if (sideTab === 'this-month') {
      const firstDayOfThisMonth = new Date(today.getFullYear(), today.getMonth(), 1)

      return setDateRange({
        dateStart: firstDayOfThisMonth.toISOString(),
        dateEnd: new Date().toISOString(),
      })
    }

    if (sideTab === 'last-month') {
      const firstDayOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1)
      const lastDayOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0)

      return setDateRange({
        dateStart: firstDayOfLastMonth.toISOString(),
        dateEnd: lastDayOfLastMonth.toISOString(),
      })
    }

    const [q, year] = sideTab.split('-')
    const quarter = parseInt(q.substring(1), 10)
    const yearNum = parseInt(year, 10)

    let dateStart, dateEnd

    switch (quarter) {
      case 1:
        dateStart = new Date(yearNum, 0, 1)
        dateEnd = new Date(yearNum, 2, 31)
        break
      case 2:
        dateStart = new Date(yearNum, 3, 1)
        dateEnd = new Date(yearNum, 5, 30)
        break
      case 3:
        dateStart = new Date(yearNum, 6, 1)
        dateEnd = new Date(yearNum, 8, 30)
        break
      case 4:
        dateStart = new Date(yearNum, 9, 1)
        dateEnd = new Date(yearNum, 11, 31)
        break
      default:
    }

    return setDateRange({ dateStart: dateStart?.toISOString(), dateEnd: dateEnd?.toISOString() })
  }

  useEffect(() => {
    setTimeRange()
  }, [sideTab])

  const calculatePercentage = (part: number, whole: number) => ((part / whole) * 100).toFixed(1)

  const options = getOptions()

  return officeOrderCounts ? (
    <div className="tabs">
      <ul className="nav nav-tabs nav-fill">
        {options.map((option, i) => (
          <li
            className="nav-item"
            onClick={() => setSideTab(option)}
            css={{ cursor: 'pointer', height: 28 }}>
            <StyledLink
              className={`py-3 font-weight-bold nav-link tabs-header ${
                sideTab === option ? 'textDark active nav-tabs-no-bckg' : ''
              }`}>
              {getOptionLabel(option, i)}
            </StyledLink>
          </li>
        ))}
      </ul>
      <div
        className="tab-content tabs_container bg-white"
        css={{
          borderColor: '#cbcbcb !important',
          borderTop: '3px solid #2c83bd !important',
          borderRadius: `0 0 3px 3px`,
          zIndex: 3,
          padding: 10,
          position: 'relative',
        }}>
        <div className={`tab-pane fade show active`}>
          <p
            css={{
              fontSize: '18px',
              margin: 0,
              marginBottom: 5,
            }}>
            {testType === 'POX'
              ? 'Pulse-Oximetry'
              : testType === 'COX'
                ? 'Capnography'
                : testType === 'PSG'
                  ? 'In-Lab Sleep Test'
                  : 'Home Sleep Test'}{' '}
            Order Statistics for{' '}
            {getOptionLabel(
              sideTab,
              options.findIndex((x) => x === sideTab),
            )}
          </p>
          <p>
            Date Range:{' '}
            {`${new Date(dateRange.dateStart!).toLocaleDateString()} - ${new Date(
              dateRange.dateEnd!,
            ).toLocaleDateString()} - averages calculated using only weekdays (Mon - Fri)`}
          </p>
          {officeOrderStats ? (
            <div>
              <div
                className={'row'}
                css={{
                  fontWeight: 600,
                  paddingBottom: 5,
                  borderBottom: '1px solid #cbcbcb',
                }}>
                <div className={'col-sm'}>Location</div>
                {/*<div className={'col-sm'}>Total</div>*/}
                <div className={'col-sm'}>Completed</div>
                <div className={'col-sm'}>Group 1</div>
                <div className={'col-sm'}>Group 2</div>
                <div className={'col-sm'}>Non-Qualified</div>
                {/* <div className={'col-sm'}>Room Air</div>
                <div className={'col-sm'}>PAP</div>
                <div className={'col-sm'}>Oxygen</div>
                <div className={'col-sm'}>Surgical Implant</div>
                <div className={'col-sm'}>Dental Appliance</div>
                <div className={'col-sm'}>NIV</div> */}
              </div>
              <div css={{
                height: 300,
                overflow: 'scroll'
              }}>
                {userOffices.map((userOffice) => {
                  const stats = officeOrderStats.find(({ officeId }) => officeId === userOffice.id)

                  return (
                    <div className={'row'} css={{
                      padding: '6px 0',
                      borderBottom: '1px solid #ececec',
                    }}>
                      <div className={'col-sm'}>{userOffice!.officeName}</div>
                      {/*<div className={'col-sm'}>*/}
                      {/*  {stats?.total || 0}{' '}*/}
                      {/*  /!* {getLabelCounts(officeOrderStats.total, sideTab.startsWith('q') ? 90 : 30)} *!/*/}
                      {/*</div>*/}
                      <div className={'col-sm'}>
                        {stats?.completed || 0}{' '} {stats?.completed ? `(${calculatePercentage(stats.completed, stats.total)}%)` : ''}
                        {/* {getLabelCounts(officeOrderStats.completed, sideTab.startsWith('q') ? 90 : 30)} */}
                      </div>
                      <div className={'col-sm'}>
                        {stats?.g1 || 0}{' '} {stats?.g1 ? `(${calculatePercentage(stats.g1, stats.total)}%)` : ''}
                        {/* {getLabelCounts(officeOrderStats.g1, sideTab.startsWith('q') ? 90 : 30)} */}
                      </div>
                      <div className={'col-sm'}>
                        {stats?.g2 || 0}{' '} {stats?.g2 ? `(${calculatePercentage(stats.g2, stats.total)}%)` : ''}
                        {/* {getLabelCounts(officeOrderStats.g2, sideTab.startsWith('q') ? 90 : 30)} */}
                      </div>
                      <div className={'col-sm'}>
                        {stats?.g3 || 0}{' '} {stats?.g3 ? `(${calculatePercentage(stats.g3, stats.total)}%)` : ''}
                        {/* {getLabelCounts(officeOrderStats.g3, sideTab.startsWith('q') ? 90 : 30)} */}
                      </div>
                      {/* <div className={'col-sm'}>{officeOrderStats.roomAir} {getLabelCounts(officeOrderStats.roomAir, sideTab.startsWith('q') ? 90 : 30)}</div>
                <div className={'col-sm'}>{officeOrderStats.pap} {getLabelCounts(officeOrderStats.pap, sideTab.startsWith('q') ? 90 : 30)}</div>
                <div className={'col-sm'}>{officeOrderStats.oxygen} {getLabelCounts(officeOrderStats.oxygen, sideTab.startsWith('q') ? 90 : 30)}</div>
                <div className={'col-sm'}>{officeOrderStats.implant} {getLabelCounts(officeOrderStats.implant, sideTab.startsWith('q') ? 90 : 30)}</div>
                <div className={'col-sm'}>{officeOrderStats.dental} {getLabelCounts(officeOrderStats.dental, sideTab.startsWith('q') ? 90 : 30)}</div>
                <div className={'col-sm'}>{officeOrderStats.ventilator} {getLabelCounts(officeOrderStats.ventilator, sideTab.startsWith('q') ? 90 : 30)}</div> */}
                    </div>
                  )
                })}
              </div>
            </div>
          ) : (
            <p css={{ color: '#b9b9b9' }}>Loading...</p>
          )}
        </div>
      </div>
    </div>
  ) : (
    <p css={{ color: '#b9b9b9' }}>Loading...</p>
  )
}

export default OrderStatsFooter

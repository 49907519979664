import { useEffect, useState, SyntheticEvent } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Toast from 'components/atoms/Toast'

import { PasswordField } from 'components/molecules/PasswordField'
import { getLoginWithNonce, getLogout } from 'store/slice/loginSlice'
import { useUpdatePasswordMutation } from 'store/services/users'
import { useLazyGetDmeOfficeTestsQuery } from 'store/services/office'
import { useAppDispatch, useAppSelector } from 'hooks/redux'

const PasswordReset = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const [password, setPassword] = useState('')
  const [password2, setPassword2] = useState('')
  const [error, setError] = useState('')
  const login = useAppSelector((state) => state.login)
  const init = useAppSelector((state) => state.init)
  const [updatePassword] = useUpdatePasswordMutation()
  const [getDmeOffice] = useLazyGetDmeOfficeTestsQuery()
  const nonce = searchParams.get('nonce')

  useEffect(() => {
    if (nonce) {
      dispatch(getLoginWithNonce({ nonce }))
    }
  }, [nonce])

  // useEffect(() => {
  //   if (init?.userType === 'LAB') {
  //     navigate('/lab')
  //   } else if (init?.userType === 'DME') {
  //     navigate('/dme')
  //   }
  // }, [init])

  const handleSubmit = async (e: SyntheticEvent) => {
    setError('')
    e.preventDefault()
    if (password !== password2) {
      return setError('Passwords do not match')
    }
    try {
      await updatePassword({ password }).unwrap()

      dispatch(getLogout())
      navigate('/login')

      // const initData = await dispatch(getInit())
      // getDmeOffice({ id: Number(initData?.payload?.primaryOffice) })
      //   .unwrap()
      //   .then((tests) => dispatch(setCurrentOfficeTests(tests)))
      Toast({ type: 'success', label: `Password reset successful.  Please login to continue.` })
    } catch (err) {
      setError(err?.response?.message || 'Error updating password')
    }
  }

  return (
    <Container>
      <Row> &nbsp; </Row>
      <Row className="justify-content-md-center">
        <Col sm={8}>
          <form method="post" onSubmit={handleSubmit}>
            <div className="text-center">
              <h3>
                <FontAwesomeIcon icon={faLock} /> Reset Password
              </h3>
            </div>

            <PasswordField setPassword={setPassword} />
            <PasswordField
              setPassword={setPassword2}
              label="Confirm Password"
              placeholder="Confirm Password"
            />

            <div className="form-group">
              <div className="custom-control custom-checkbox">
                <input type="checkbox" className="custom-control-input" id="customCheck1" />
              </div>
            </div>

            <button type="submit" className="btn btn-primary btn-block">
              Submit
            </button>
            <br />
          </form>
          {(error || login.error) && (
            <div className="alert alert-danger" role="alert">
              {error || login.error}
            </div>
          )}
        </Col>
      </Row>
    </Container>
  )
}

export default PasswordReset

import { Field } from 'formik'
import months from 'components/dme/constants/months'
import days from 'components/dme/constants/days'
import { SyntheticEvent } from 'react'

const DobForm = ({ values, errors, touched, setFieldValue }) => {
  const years = Array.from(Array(new Date().getFullYear() - 1910), (_, i) => (i + 1911).toString())
  years.unshift('')

  return (
    <>
      {errors.dob ? (
        <div className="row">
          <div className="col text-danger">{errors.dob}</div>
        </div>
      ) : null}
      <div className="row">
        <div className="col-5 form-group">
          <label htmlFor="month">Month</label>
          <Field
            className="form-control"
            as="select"
            name="month"
            onChange={(event) => {
              const value = event.target.value
              setFieldValue('month', value)
              setFieldValue('dob', `${value}/${values.day}/${values.year}`)
            }}>
            {months &&
              months.map((m, i) => (
                <option key={i} value={m.value}>
                  {m.name}
                </option>
              ))}
          </Field>
          {touched.month && errors.month ? <div className="text-danger">{errors.month}</div> : null}
        </div>
        <div className="col-3 form-group">
          <label htmlFor="day">Day</label>
          <Field
            className="form-control"
            as="select"
            name="day"
            onChange={(event) => {
              const value = event.target.value
              setFieldValue('day', value)
              setFieldValue('dob', `${values.month}/${value}/${values.year}`)
            }}>
            {days &&
              days.map((d, i) => (
                <option key={i} value={d}>
                  {d}
                </option>
              ))}
          </Field>
          {touched.day && errors.day ? <div className="text-danger">{errors.day}</div> : null}
        </div>
        <div className="col-4 form-group">
          <label htmlFor="year">Year</label>
          <Field
            className="form-control"
            as="select"
            name="year"
            onChange={(event: SyntheticEvent) => {
              const value = event.target.value
              setFieldValue('year', value)
              setFieldValue('dob', `${values.month}/${values.day}/${value}`)
            }}>
            {years?.map((y, i) => (
              <option key={i} value={y}>
                {y}
              </option>
            ))}
          </Field>
          {touched.year && errors.year ? <div className="text-danger">{errors.year}</div> : null}
        </div>
      </div>
    </>
  )
}

export default DobForm

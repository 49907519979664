import { baseApi } from './baseApi'
import { Totals, ReportFilter, OrderInfo } from 'ts-back-end/src/reporting/types/one-ship-report'

interface OneShipResult {
  totals: Totals
  filters: ReportFilter
  orders: OrderInfo[]
}

interface OneShipQuery {
  billedFrom?: string
  billedTo?: string
  createdFrom?: string
  createdTo?: string
  companyIds?: string[]
  officeIds?: string[]
  shippedBackFrom?: string
  shippedBackTo?: string
  shippedFrom?: string
  shippedTo?: string
}

interface DailyCountsQuery {
  from: string
  to: string
  companyIds?: string[]
  dateMode: string
  groupBy: string
}

export const ticketsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getOneShipCounts: builder.query<OneShipResult, OneShipQuery>({
      query: (params) => ({ url: `/management-reporting/one-ship`, params }),
      providesTags: ['OneShipCounts'],
    }),
    getDailyCounts: builder.query<any, DailyCountsQuery>({
      query: (params) => ({ url: `/management-reporting/daily`, params }),
      providesTags: ['DailyCounts'],
    }),
    getQuickSightEmbedUrl: builder.query<
      { url: string },
      { dashboardId: string; userType: 'LAB' | 'DME' }
    >({
      query: ({ dashboardId, userType }) => ({
        url: `/${userType === 'LAB' ? 'management-reporting' : 'dme'}/analytics/dashboard/${dashboardId}/embedding-url`,
      }),
    }),
  }),
})

export const {
  useGetOneShipCountsQuery,
  useLazyGetOneShipCountsQuery,
  useGetDailyCountsQuery,
  useLazyGetDailyCountsQuery,
  useGetQuickSightEmbedUrlQuery,
  useLazyGetQuickSightEmbedUrlQuery,
} = ticketsApi

/** @jsxImportSource @emotion/react */
import { useState } from 'react'
import Button from 'react-bootstrap/Button'
import styled from '@emotion/styled'

import Upload from 'components/atoms/Upload'

const Aside = styled.aside`
  margin-top: 15px;
  background: #2a6ca5;
  padding: 15px;
  color: #fff !important;
  Code {
    background: none !important;
    color: #fff !important;
    padding: 0;
  }
  a {
    color: white;
    text-decoration: underline;
  }
`

const Code = styled.code`
  background: #d0d2d4;
  font-family: monospace;
  font-size: 1rem;
  font-weight: 600;
  padding: 4px 8px;
  color: #2a6ca5;
`

const List = styled.ul`
  box-sizing: border-box;
  display: block;
  margin: 0;
  padding: 0 0 0 15px;
  list-style: none;
  marker-offset: 0;
  padding: 0;

  li {
    box-sizing: border-box;
    display: block;
    padding: 0;
    margin: 0 0 0 15px;
    i {
      font-style: normal;
      font-weight: bold;
    }
  }
  li + li {
    margin-top: 15px;
  }
`

interface IProps {
  upload: (files: File[]) => void
  uploadProgress: number
  error?: string
}

export const Cap35Upload = ({ upload, uploadProgress, error: uploadError }: IProps) => {
  const [showHelp, setShowHelp] = useState(false)
  const [selected, setSelected] = useState<File[]>([])
  const [error, setError] = useState('')

  const handleFileSelection = (files: File[]) => {
    setError('')
    const validFiles: File[] = []
    if (files.length) {
      const ul = document.createElement('ul')
      ul.className = 'list'
      files.forEach((file) => {
        if (file.name.match(/^TTR_.+\.(csv|txt)$/)) {
          validFiles.push(file)
        }
      })
      setSelected(validFiles)

      if (!validFiles.length) {
        setError('No valid Capnostream 35 data file(s) selected.')
      }
    }
  }

  if (showHelp) {
    return (
      <div className="instructions hidden" id="instructions">
        <h1>Capnostream 35 Instructions</h1>
        <Aside css={{ marginBottom: 10 }}>
          &#x1f4a1; If you prefer to watch a quick instructional video,{' '}
          <a href="/dme/videos/" target="_blank">
            click here
          </a>
          !
        </Aside>
        <p>
          <strong>How to download data from a Capnostream 35 to an external USB drive:</strong>
        </p>
        <List>
          <li>
            <i>1</i>. Insert your USB drive into the side of the Capnostream 35
          </li>
          <li>
            <i>2</i>. Power on the device
          </li>
          <li>
            <i>3</i>. Press <Code>Menu</Code> button and wait for the <Code>Setup</Code> menu to
            appear
          </li>
          <li>
            <i>4</i>. Press the <Code>left</Code> directional button to choose from the menus
          </li>
          <li>
            <i>5</i>. Press the <Code>down</Code> (bottom) directional button 3 times until you
            reach the <Code>Report</Code> menu
          </li>
          <li>
            <i>6</i>. Press the <Code>right</Code> directional button to enter the{' '}
            <Code>Report</Code> menu
          </li>
          <li>
            <i>7</i>. Press the <Code>down</Code> (bottom) directional button once to highlight{' '}
            <Code>Store Report</Code>
          </li>
          <li>
            <i>8</i>. Press the <Code>center</Code> directional button to select{' '}
            <Code>Store Report</Code>
          </li>
          <li>
            <i>9</i>. Press the <Code>right</Code> directional button to enter the{' '}
            <Code>Stored Report Selection</Code> menu
          </li>
          <li>
            <i>10</i>. Press the <Code>down</Code> (bottom) directional button 2 times until you
            reach the <Code>Tabular Trend</Code> button
          </li>
          <li>
            <i>11</i>. Press the <Code>center</Code> directional button to select{' '}
            <Code>Tabular Trend</Code>
          </li>
          <li>
            <i>12</i>. Press the <Code>center</Code> directional button to select <Code>Text</Code>
          </li>
          <li>
            <i>13</i>. Wait until the <Code>Stored Report Selection</Code> menu appears with{' '}
            <Code>Tabular Trend</Code> highlighted
          </li>
          <li>
            <i>14</i>. Press the <Code>left</Code> directional button to highlight{' '}
            <Code>Choose Device</Code>
          </li>
          <li>
            <i>15</i>. Press the <Code>center</Code> directional button to select{' '}
            <Code>Choose Device</Code>
          </li>
          <li>
            <i>16</i>. Press the <Code>center</Code> directional button to select <Code>USB 1</Code>
          </li>
          <li>
            <i>17</i>. Press the <Code>left</Code> directional button to highlight{' '}
            <Code>Save Report</Code>
          </li>
          <li>
            <i>18</i>. Press the <Code>center</Code> directional button to select{' '}
            <Code>Save Report</Code>
          </li>
          <li>
            <i>19</i>. Wait until you see the pop-up screen which says{' '}
            <Code>Tabular Trend Report exported to USB 1</Code>
          </li>
          <li>
            <i>20</i>. Press the <Code>center</Code> directional button to select <Code>OK</Code>
          </li>
          <li>
            <i>21</i>. Power off the device and wait until the screen and all lights are off
          </li>
          <li>
            <i>22</i>. Remove the USB drive from the device
          </li>
        </List>
        <Button variant="primary" className="mr-2 mt-3" onClick={() => setShowHelp(false)}>
          Back to Upload
        </Button>
      </div>
    )
  }
  return (
    <div className="form" id="upload">
      <div className="form-header">
        <Button
          variant="primary"
          className="mr-2"
          css={{ float: 'right' }}
          onClick={() => setShowHelp(true)}>
          Need Help?
        </Button>
        <h1 css={{ fontSize: '1.25em', fontWeight: 'bold' }}>Capnostream 35 Upload</h1>
      </div>
      <p>
        <strong>Checklist</strong>
      </p>
      <ul className="list">
        <li>
          <strong>1</strong>. Have you downloaded the data from the Capnostream 35 onto an external
          USB drive?
        </li>
        <li>
          <strong>2</strong>. Do you now have that external USB drive plugged into your computer?
        </li>
      </ul>
      <p>
        <strong>Instructions</strong>
      </p>
      <ul className="list">
        <li>
          <strong>1</strong>. Tap or click the <strong>Choose Files</strong> button below
        </li>
        <li>
          <strong>2</strong>. Navigate to the external USB drive folder
        </li>
        <li>
          <strong>3</strong>. Locate and select all Capnostream 35 data files
        </li>
      </ul>
      <form
        enctype="multipart/form-data"
        onsubmit="return!1"
        css={{
          background: '#e0e2e4',
          padding: 15,
          marginTop: '30px',
        }}>
        <div className="inputs">
          <p>
            Note: you can press and hold the <Code>Ctrl</Code> button while clicking to select
            multiple files
          </p>
          <p>
            <Upload
              label="Choose Files"
              accept={{
                'text/plain': ['.txt', '.csv'],
                'application/vnd.ms-excel': ['.txt', '.csv'],
              }}
              handleUpload={handleFileSelection}
            />
          </p>
        </div>
        {selected.length > 0 && (
          <div className="files">
            <p>
              <strong>Valid Selected Files</strong>
            </p>
            <ul css={{ paddingInlineStart: '10px' }}>
              {selected.map((file) => (
                <li key={file.name}>
                  <i className="fas fa-check text-success mr-2" /> {file.name}
                </li>
              ))}
            </ul>
          </div>
        )}
        {error && (
          <p className="error">
            <i className="fas fa-exclamation-triangle text-warning mr-2" />
            {error}
          </p>
        )}
        {uploadError && (
          <p className="error">
            <i className="fas fa-exclamation-triangle text-warning mr-2" />
            {uploadError}
          </p>
        )}
        <div className="buttons">
          <Button
            variant="primary"
            className="mr-2"
            disabled={selected?.length < 1}
            onClick={() => upload(selected)}>
            Upload
          </Button>
        </div>
        {uploadProgress > 0 && (
          <div className="progress" css={{ height: 35, marginTop: 10 }}>
            <div
              className="progress-bar progress-bar-striped progress-bar-animated"
              role="progressbar"
              css={{ width: `${uploadProgress}%` }}>
              {uploadProgress}%
            </div>
          </div>
        )}
      </form>
      <Aside>
        <p>
          <strong>&#x1f4a1; Helpful Hint</strong>
        </p>
        <p>Capnostream 35 data files should be named something like:</p>
        <ul>
          <li>
            <Code>TTR_ADULT_200000_00000_20190801110247_SF00000000.csv</Code>
          </li>
          <li>
            <Code>TTR_ADULT_200000_00000_20190801110247_SF00000000.txt</Code>
          </li>
        </ul>
      </Aside>
    </div>
  )
}

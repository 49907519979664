import { useEffect } from 'react'
import { useAppSelector, useAppDispatch } from 'hooks/redux'
import { useParams, Routes, Route } from 'react-router-dom'
import { getOrder } from '../../../store/slice/orderSlice'
import { getOrderTests } from '../../../store/slice/orderTestSlice'
import { getDeviceCategories } from '../../../store/slice/deviceCategorySlice'
import DashboardContent from '../atoms/DashboardContent'
import TestConditionSummary from './TestConditionSummary'
import TestConditionUpsert from './TestConditionUpsert'
import routes from '../constants/routes'
import Loading from '../../atoms/Loading'

const TestCondition = () => {
  const { order_id } = useParams()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getOrder({ id: order_id, add_join: 'patient' }))
    dispatch(getDeviceCategories({ add_join: 'device_type' }))
    dispatch(getOrderTests({ order_id }))
  }, [])

  const order = useAppSelector(state => state.order.row)
  const orderTests = useAppSelector(state => state.orderTest.rows)
  const deviceCategories = useAppSelector(state => state.deviceCategory.rows)

  const isFetchingOrder = useAppSelector(state => state.order.pending)
  const isFetchingOrderTests = useAppSelector(state => state.orderTest.pending)
  const isFetchingDeviceCategories = useAppSelector(
    state => state.deviceCategory.pending
  )
  const isFetching =
    isFetchingOrder || isFetchingOrderTests || isFetchingDeviceCategories

  if (!isFetching) {
    const patient = order?.patient
    const title =
      order && patient
        ? `${patient?.first_name} ${patient?.last_name} (Order#${order?.id})`
        : `(Order#${order?.id})`

    return (
      <DashboardContent
        title={title}
        // icon="fal fa-folder-open"
        content={
          <div className="d-flex justify-content-center">
            <div className="col-11">
              <Routes>
                <Route
                  path="/"
                  children={
                    <TestConditionSummary
                      order={order}
                      orderTests={orderTests}
                    />
                  }
                />
                <Route
                  path={`/${routes.upsert}`}
                  children={
                    <TestConditionUpsert
                      order={order}
                      orderTests={orderTests}
                      deviceCategories={deviceCategories}
                    />
                  }
                />
                <Route
                  path={`/${routes.upsert}:orderTest_id`}
                  children={
                    <TestConditionUpsert
                      order={order}
                      orderTests={orderTests}
                      deviceCategories={deviceCategories}
                    />
                  }
                />
              </Routes>
            </div>
          </div>
        }
      ></DashboardContent>
    )
  } else {
    return <Loading />
  }
}

export default TestCondition

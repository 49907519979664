import * as generalHelper from "../../helpers/generalHelper";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ------------------------------------------------------------
export const getFiles = createAsyncThunk(
    "getFiles",
    async (params, { rejectWithValue }) => {
        try {
            const data = await generalHelper.apiGet("/api/file", params);
            if (!data) {
                return rejectWithValue([]);
            }
            return data;
        } catch (error) {
            console.log("error", error);
        }
    }
);

// ------------------------------------------------------------
export const getFile = createAsyncThunk(
    "getFile",
    async (params, { rejectWithValue }) => {
        try {
            params.return_key = "one";
            const data = await generalHelper.apiGet("/api/file", params);
            if (!data) {
                return rejectWithValue([]);
            }
            return data;
        } catch (error) {
            console.log("error", error);
        }
    }
);

// ------------------------------------------------------------
export const updateFile = createAsyncThunk(
    "updateFile",
    async ({ id, params }, { rejectWithValue }) => {
        try {
            const data = await generalHelper.apiPut("/api/file", id, params);
            if (!data) {
                return rejectWithValue([]);
            }
            return data;
        } catch (error) {
            console.log("error", error);
        }
    }
);

// ------------------------------------------------------------
export const insertFile = createAsyncThunk(
    "insertFile",
    async (params, { rejectWithValue }) => {
        try {
            const data = await generalHelper.apiPost("/api/file", params);
            if (!data) {
                return rejectWithValue([]);
            }
            return data;
        } catch (error) {
            console.log("error", error);
        }
    }
);

// ------------------------------------------------------------
export const deleteFile = createAsyncThunk(
    "deleteFile",
    async ({ id, params }, { rejectWithValue }) => {
        try {
            const data = await generalHelper.apiDelete("/api/file", id, params);
            if (!data) {
                return rejectWithValue([]);
            }
            return data;
        } catch (error) {
            console.log("error", error);
        }
    }
);

// ------------------------------------------------------------
const initialState = {
    pending: false,
    rows: [],
    row: {},
    update: {},
    insert: {},
    delete: {},
    rejected: false,
};

// ------------------------------------------------------------
const localSlice = createSlice({
    name: "file",
    initialState,
    extraReducers: {
        [getFiles.rejected]: (state, action) => {
            state.pending = false;
            state.rejected = true;
            state.rows = null;
        },
        [getFiles.pending]: (state, action) => {
            state.pending = true;
            state.rejected = false;
            state.rows = null;
        },
        [getFiles.fulfilled]: (state, action) => {
            state.pending = false;
            state.rejected = false;
            state.rows = action.payload;
        },
        [getFile.rejected]: (state, action) => {
            state.pending = false;
            state.rejected = true;
            state.rows = null;
        },
        [getFile.pending]: (state, action) => {
            state.pending = true;
            state.rejected = false;
            state.rows = null;
        },
        [getFile.fulfilled]: (state, action) => {
            state.pending = false;
            state.rejected = false;
            state.row = action.payload;
        },
        [updateFile.rejected]: (state, action) => {
            state.pending = false;
            state.rejected = true;
            state.rows = null;
        },
        [updateFile.pending]: (state, action) => {
            state.pending = true;
            state.rejected = false;
            state.rows = null;
        },
        [updateFile.fulfilled]: (state, action) => {
            state.pending = false;
            state.rejected = false;
            state.update = action.payload;
        },
        [insertFile.rejected]: (state, action) => {
            state.pending = false;
            state.rejected = true;
            state.rows = null;
        },
        [insertFile.pending]: (state, action) => {
            state.pending = true;
            state.rejected = false;
            state.rows = null;
        },
        [insertFile.fulfilled]: (state, action) => {
            state.pending = false;
            state.rejected = false;
            state.insert = action.payload;
        },
        [deleteFile.rejected]: (state, action) => {
            state.pending = false;
            state.rejected = true;
            state.rows = null;
        },
        [deleteFile.pending]: (state, action) => {
            state.pending = true;
            state.rejected = false;
            state.rows = null;
        },
        [deleteFile.fulfilled]: (state, action) => {
            state.pending = false;
            state.rejected = false;
            state.delete = action.payload;
        },
    },
});

export default localSlice.reducer;

/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'

import ButtonSave from 'components/atoms/ButtonSave'
import { HstDeviceKind, Order } from 'store/types'
import TabHeader from 'components/atoms/TabHeader'
import NightOwlIcon from 'assets/img/nightowl-icon.svg'
import { ButtonGroup } from 'react-bootstrap'
import { NightOwlSelection } from './oneShip/NightOwlSelection'
import { useUpdateHstDeviceMutation } from 'store/services/dmeOrder'
import { useUpdateLabHstDeviceMutation } from 'store/services/labOrder'
import { TraditionalDeviceForm } from './traditional/TraditionalDeviceForm'

const nightOwlFocusStyle = {
  backgroundColor: '#20b2aa59 !important',
  borderColor: '#20b2aa79 !important',
  color: '#1f2d3d !important',
}

interface IProps {
  order: Order
  inModal?: boolean
  setDeviceSelected?: (device: DeviceType) => void
  submitCallback?: () => void
  isLab?: boolean
}

export type DeviceType = 'NightOwl' | 'HST' | 'dreamclear'

const HstDeviceTab = ({ order, inModal, setDeviceSelected, submitCallback, isLab }: IProps) => {
  const isNightOwlPatient = order?.patient?.nightOwls?.length > 0
  const [updateDevice] = useUpdateHstDeviceMutation()
  const [updateLabDevice] = useUpdateLabHstDeviceMutation()
  const [formDirty, setFormDirty] = useState(false)
  const [deviceType, setDeviceType] = useState<DeviceType | null>(
    order?.thirdPartyDevice?.deviceType === HstDeviceKind.NIGHT_OWL
      ? 'NightOwl'
      : order?.thirdPartyDevice?.deviceType === HstDeviceKind.HST
      ? 'HST'
      : order?.thirdPartyDevice?.deviceType === HstDeviceKind.DREAM_CLEAR
      ? 'dreamclear'
      : isNightOwlPatient
      ? 'NightOwl'
      : 'HST',
  )

  useEffect(() => {
    if (deviceType && setDeviceSelected) {
      setDeviceSelected(deviceType)
    }
  }, [setDeviceSelected])

  const deviceTypeUnchanged =
    (deviceType === 'NightOwl' &&
      order?.thirdPartyDevice?.deviceType === HstDeviceKind.NIGHT_OWL) ||
    (deviceType === 'HST' &&
      order?.thirdPartyDevice?.deviceType === HstDeviceKind.HST) ||
    (deviceType === 'dreamclear' &&
      order?.thirdPartyDevice?.deviceType === HstDeviceKind.DREAM_CLEAR)

  return (
    <div className="container ">
      <div className="row px-4">
        {!inModal && (
          <TabHeader
            actionButtons={[
              <ButtonSave
                key={1}
                form={deviceType}
                id="btn-ts"
                type="submit"
                label="Save Changes"
                disabled={!deviceType || (!formDirty && deviceTypeUnchanged)}
              />,
            ]}
          />
        )}
        {(isNightOwlPatient || isLab) && (
          <>
            {!isLab && (
              <span css={{ marginBottom: 10 }}>
                We see the patient may have a NightOwl device from a previous test. If the ordering
                physician wishes to proceed with NightOwl select NightOwl otherwise select
                Traditional HST. <br />
                <br />
                Please Note: The NightOwl Device does not allow for the diagnosis of central apnea
                at this time
              </span>
            )}
            <ButtonGroup className="mb-3">
              <Button
                active={deviceType === 'NightOwl'}
                onClick={() => {
                  setDeviceType('NightOwl')
                  setDeviceSelected && setDeviceSelected('NightOwl')
                }}
                size="lg"
                css={{
                  backgroundColor: '#20b2aa20',
                  borderColor: '#20b2aa40',
                  color: '#1f2d3d',
                  '&.active': {
                    ...nightOwlFocusStyle,
                    borderColor: '#ffc107 !important',
                    borderWidth: '2px',
                  },
                  '&:hover': nightOwlFocusStyle,
                  '&:active': nightOwlFocusStyle,
                  '&:focus': nightOwlFocusStyle,
                }}>
                <img src={NightOwlIcon} alt="nightowl" className="mr-2" />
                NightOwl
              </Button>
              <Button
                active={deviceType === 'dreamclear'}
                onClick={() => {
                  setDeviceType('dreamclear')
                  setDeviceSelected && setDeviceSelected('dreamclear')
                }}
                css={{
                  marginLeft: '20px !important',
                  '&.active': {
                    borderColor: '#ffc107 !important',
                    borderWidth: '2px',
                  },
                }}
                variant="light"
                size="lg">
                Traditional HST
              </Button>
              <Button
                active={deviceType === 'HST'}
                onClick={() => {
                  setDeviceType('HST')
                  setDeviceSelected && setDeviceSelected('HST')
                }}
                css={{
                  marginLeft: '20px !important',
                  '&.active': {
                    borderColor: '#ffc107 !important',
                    borderWidth: '2px',
                  },
                }}
                variant="light"
                size="lg">
                HST
              </Button>
            </ButtonGroup>
          </>
        )}
      </div>
      <div className="row px-4">
        <div className="col-12">
          {deviceType === 'NightOwl' && (
            <NightOwlSelection
              order={order}
              updateDeviceNightOwl={isLab ? updateLabDevice : updateDevice}
              onClose={submitCallback}
              setFormDirty={setFormDirty}
            />
          )}

          {(deviceType === 'HST' || deviceType === 'dreamclear') && (
            <TraditionalDeviceForm
              order={order}
              updateDevice={isLab ? updateLabDevice : updateDevice}
              onClose={submitCallback}
              setFormDirty={setFormDirty}
              deviceType={deviceType}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default HstDeviceTab

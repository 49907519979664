import TitleLead from '../../../atoms/TitleLead'
import Input from '../../../atoms/Input'
import Radio from '../../../atoms/Radio'
import { useOrderSettings } from './useOrderSettings'
import * as generalHelper from 'helpers/generalHelper'
import {
	inLabProcedures,
} from 'components/dme/constants/testDeviceParams'

const InLabTestCondition = () => {
	const { state: settings, dispatch: dispatchSetting } = useOrderSettings()
	const night = '1'
	const conditionSettings = settings.tests[night]

	return (
		<div className="col-sm-4">
			<div className="d-flex flex-column px-2">
				<TitleLead
					className="ml-2 my-3"
					label={generalHelper.t(`In-Lab Test Conditions`)}
				/>
				<hr className="w-100 mt-0" />
				<div className="col-sm-12 mb-2 font-weight-bold textGray">
					Conditions
					<span className="text-danger">*</span>
				</div>

				{/* Begin conditions */}
				<div className="mb-2">
					<div className={`row d-flex flex-nowrap align-items-center ml-1`}>
						<div className={`pl-1`}>
							<label className="ml-1 font-weight-normal d-flex flex-nowrap align-items-center">
								<Radio
									name={`oxygen-${night}`}
									value="0"
									checked={conditionSettings?.oxygen === 0}
									onChange={() => {
										dispatchSetting({
											type: 'condition:room-air',
											payload: { night },
										})
									}}
									className="mr-2"
								/>
								Room Air
							</label>
						</div>
					</div>

					<div className={`row d-flex flex-nowrap align-items-center ml-1`}>
						<div className={`pl-1`}>
							<label className="ml-1 mb-0 d-flex flex-nowrap align-items-center font-weight-normal">
								<Radio
									name={`oxygen-${night}`}
									value="1"
									checked={conditionSettings?.oxygen === 1}
									onChange={() => {
										dispatchSetting({
											type: 'condition:oxygen',
											payload: { night },
										})
									}}
									className="mr-2"
								/>
								Oxygen
							</label>
						</div>

						{conditionSettings?.oxygen === 1 ? (
							<div className="d-flex align-items-center ml-2">
								<div>@</div>
								<div className="col-sm-4">
									<Input
										minValue="0"
										type="number"
										name={`oxygen_lpm_${night}`}
										value={conditionSettings?.oxygenLpm ?? ''}
										handleChange={(event) => {
											dispatchSetting({
												type: 'set-condition-value',
												payload: {
													key: 'oxygenLpm',
													value: event.target.value,
													night,
												},
											})
										}}
									/>
								</div>
								<div>
									<p className="m-0" style={{ lineHeight: '12px' }}>
										LPM
										<br />
										<small>(Liters Per Minute)</small>
									</p>
								</div>
							</div>
						) : null}
					</div>
				</div>
				{/* End conditions */}

				{/* Begin Device Type */}
				<div className="col-sm-12 mb-2 font-weight-bold textGray">
					In-Lab Procedure
					<span className="text-danger">*</span>
				</div>
				<div className="row mb-3 align-items-center">
					{inLabProcedures.map((row, index) => {
						return (
							<div key={index} className="pl-3">
								<label className="form-check-label ml-1 d-flex align-items-center">
									<Radio
										name={`procedures-${night}`}
										value={row.value}
										checked={conditionSettings?.procedure === row.value}
										onChange={(event) => {
											dispatchSetting({
												type: 'procedure',
												payload: { value: event.target.value, night },
											})
										}}
										className="mr-2"
									/>
									{row.name}
								</label>
							</div>
						)
					})}
				</div>
				{/* End Device Type */}
			</div>
		</div>
	)
}

export default InLabTestCondition

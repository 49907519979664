import { ChangeEvent } from 'react'
import styled from '@emotion/styled'

const RadioLabel = styled.label`
  margin-top: 0.23rem;
  margin-bottom: 1px;
  display: flex;
  align-items: center;
`
interface IProps {
  value: string
  label?: string
  name: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  id?: string
  checked?: boolean
  className?: string
  wrapperClassName?: string
  key?: string
  disabled?: boolean
}

const RadioComponent = (props: IProps) => {
  const value = props.value ?? ''
  const name = props.name ?? ''
  const label = props.label ?? ''
  const wrapperClassName = props.wrapperClassName ?? ''
  const onChange = props.onChange ?? (() => {})
  const id = props.id ?? props.value

  return (
    <div className={`d-flex ${wrapperClassName}`} key={props.key}>
      <input
        type="radio"
        id={`radio-${id}`}
        name={name}
        value={value}
        onChange={onChange}
        defaultChecked={props.checked ? true : undefined}
        checked={props.checked}
        className={props.className}
      />
      {label && (
        <RadioLabel htmlFor={`radio-${id}`} className="form-check-label ml-2">
          {label}
        </RadioLabel>
      )}
    </div>
  )
}

export default RadioComponent

import { baseApi } from './baseApi'
import { CreatePhysicianUserDto, CreateUserDto, UsersListQuery, UserType } from 'store/types'

export const userApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getLabUsers: builder.query<any[], UsersListQuery>({
      query: (query) => ({ url: `/lab/users/list`, params: { ...query, type: UserType.LAB } }),
      providesTags: ['User'],
    }),
    getPhysicianGroupUsers: builder.query<any[], UsersListQuery>({
      query: (query) => ({ url: `/lab/users/list`, params: { ...query, type: UserType.PHYSICIAN_GROUP } }),
      providesTags: ['User'],
    }),
    getLabDmeUsers: builder.query<any[], UsersListQuery>({
      query: (query) => ({ url: `/lab/users/list`, params: { ...query, type: UserType.DME } }),
      providesTags: ['User'],
    }),
    getDmeUsers: builder.query<any[], UsersListQuery>({
      query: (params) => ({ url: `/dme/users/list`, params }),
      providesTags: ['User'],
    }),
    getAllUsers: builder.query<any, UsersListQuery>({
      query: (params) => ({ url: `/dme/users/all`, params }),
      providesTags: ['User'],
    }),
    getLabUser: builder.query<any, { id: number }>({
      query: ({ id }) => ({ url: `/lab/users/${id}` }),
      providesTags: (result, error, { id }) => [{ type: 'User', id }],
    }),
    getDmeUser: builder.query<any, { id: number }>({
      query: ({ id }) => ({ url: `/dme/users/${id}` }),
      providesTags: (result, error, { id }) => [{ type: 'User', id }],
    }),
    createLabUser: builder.mutation<any, CreateUserDto>({
      query: (data) => ({
        method: `POST`,
        url: `/lab/users`,
        body: data,
      }),
      invalidatesTags: ['User'],
    }),
    createDmeUser: builder.mutation<any, CreateUserDto>({
      query: (data) => ({
        method: `POST`,
        url: `/dme/users`,
        body: data,
      }),
      invalidatesTags: ['User'],
    }),
    createPhysicianGroupUser: builder.mutation<any, CreatePhysicianUserDto>({
      query: (data) => ({
        method: `POST`,
        url: `/lab/physicians/user`,
        body: data,
      }),
      invalidatesTags: ['User'],
    }),
    updateLabUser: builder.mutation<any, any>({
      query: (data) => ({
        method: `PUT`,
        url: `/lab/users/${data.id}`,
        body: data,
      }),
      invalidatesTags: ['User'],
    }),
    updateDmeUser: builder.mutation<any, any>({
      query: (data) => ({
        method: `PUT`,
        url: `/dme/users/${data.id}`,
        body: data,
      }),
      invalidatesTags: ['User'],
    }),
    deleteUser: builder.mutation<any, { id: number }>({
      query: ({ id }) => ({
        method: `DELETE`,
        url: `/lab/users/${id}`,
      }),
      invalidatesTags: ['User'],
    }),
    dmeDeleteUser: builder.mutation<any, { id: number }>({
      query: ({ id }) => ({
        method: `DELETE`,
        url: `/dme/users/${id}`,
      }),
      invalidatesTags: ['User'],
    }),
    sendResetPasswordEmail: builder.mutation<void, { email: string }>({
      query: ({ email }) => ({
        method: `POST`,
        url: `/lab/users/password-reset`,
        body: { email },
      }),
    }),
    sendDmeResetPasswordEmail: builder.mutation<void, { email: string }>({
      query: ({ email }) => ({
        method: `POST`,
        url: `/dme/users/password-reset`,
        body: { email },
      }),
    }),
    exchangeNonce: builder.mutation<any, { nonce: string }>({
      query: ({ nonce }) => ({
        method: `POST`,
        url: `/lab/users/password-reset/auth`,
        body: { nonce },
      }),
    }),
    updatePassword: builder.mutation<any, { password: string }>({
      query: ({ password }) => ({
        method: `PUT`,
        url: `/lab/users/new-password`,
        body: { password, repeatPassword: password },
      }),
    }),
    sendPasswordResetLink: builder.mutation<void, { email: string }>({
      query: ({ email }) => ({
        method: `POST`,
        url: `/password-reset`,
        body: { email },
      }),
    }),
    logout: builder.mutation<void, void>({
      query: () => ({ url: `/auth/logout`, method: 'POST' }),
    }),
  }),
})

export const {
  useGetLabUsersQuery,
  useGetPhysicianGroupUsersQuery,
  useGetLabUserQuery,
  useGetDmeUserQuery,
  useGetLabDmeUsersQuery,
  useLazyGetLabDmeUsersQuery,
  useGetDmeUsersQuery,
  useGetAllUsersQuery,
  useLazyGetDmeUsersQuery,
  useLazyGetLabUsersQuery,
  useCreateLabUserMutation,
  useCreateDmeUserMutation,
  useCreatePhysicianGroupUserMutation,
  useUpdateLabUserMutation,
  useUpdateDmeUserMutation,
  useDeleteUserMutation,
  useDmeDeleteUserMutation,
  useSendResetPasswordEmailMutation,
  useSendDmeResetPasswordEmailMutation,
  useExchangeNonceMutation,
  useUpdatePasswordMutation,
  useSendPasswordResetLinkMutation,
  useLogoutMutation,
} = userApi

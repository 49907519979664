export const cannedMessages = [
  {
    title: "Christy: ELIGIBILITY REQUEST-",
    content: `Please fax or email a copy of the insurance verification. Thank you!
Fax #s: (386) 382-4201/ (386) 313-0721
Email: Billing@testsmarter.net`
  },
  {
    title: "Christy: NEW INSURANCE REQUEST-",
    content: `Please provide insurance phone number and claims mailing address. Thank you!`,
  }, 
  {
    title: "Christy: DUPLICATE RX-",
    content: `The uploaded Rx is a duplicate to patients other completed order #______. Please provide an updated signed Rx. Thank you!`
  }, 
  {
    title: "Christy: DUPLICATE ORDER-",
    content: `This order is a duplicate to patient's order #______. Please let us know which order will be used to complete testing and which order you would like us to cancel. Thank you!`
  }, 
  {
    title: "Amber: INCORRECT TEST DATES - UPDATED",
    content: `The testing date & time has been updated and the revised report has been faxed to the physician. Please be sure to complete the reset process on the device to avoid future, incorrect time stamps. Thank you!`
  }, 
  {
    title: "Amber: INCORRECT TEST CONDITIONS - UPDATED",
    content: `The testing conditions have been updated and the revision has been sent to the ordering physician.`
  }, 
  {
    title: "Amber: RESPONSE - SUPPORT TEAM",
    content: `I have sent the request to the support team. They will get in touch with you if they have any questions. Thank you`
  }, 
  {
    title: "Amber: RESPONSE - BILLING TEAM",
    content: `The information provided has been sent to the billing dept. They will follow up if they have any further questions`
  }, 
  {
    title: "Amber: RESONSE - NIGHTOWL TEAM",
    content: `The information provided has been sent to the NightOwl team. They will follow up if they have any further questions`
  }, 
  {
    title: "Amber: NIGHTOWL - UNABLE TO CONTACT",
    content: `We have attempted to contact the patient multiple times with no success. Please advise on how you would like to proceed.`
  }, 
  {
    title: "Amber: AOB MISSING - REPORT ATTACHED",
    content: `Please fax or upload the patient signed AOB to release the report. Thank you.`
  }, 
  {
    title: "Amber: UPDATED: INSURANCE",
    content: `Insurance information has been updated and verified. Thank you!`
  }, 
  {
    title: "Amber: UPDATED: PATIENT",
    content: `The patient's information has been updated.`
  }, 
  {
    title: "Amber: UPDATED: PHYSICIAN",
    content: `The physician's information has been updated.`
  },
]
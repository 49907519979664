/** @jsxImportSource @emotion/react */
import { Button } from 'react-bootstrap'
import { DeviceDisplay } from './920mPlus'

export const Nine20m = ({ onClose }: { onClose: () => void }) => {
  return (
    <div>
      <h4>
        <i className="fas fa-info-circle mr-3" />
        Instructions for clearing the device memory
      </h4>
      <ol
        css={{
          listStyleType: 'decimal',
          li: { fontSize: 16, lineHeight: '22px' },
        }}>
        <li>
          Press the ┃ and ⬆ buttons to enter SETUP mode. The display should now read{' '}
          <DeviceDisplay>Clr no</DeviceDisplay>
        </li>
        <li>
          Press the ⬆ button to set the value to <DeviceDisplay>YES</DeviceDisplay>
        </li>
        <li>
          Press the ┃ button to advance to the next setting. The display should now read{' '}
          <DeviceDisplay>dEL no</DeviceDisplay>
        </li>
        <li>
          Press the ⬆ button to set the value to <DeviceDisplay>YES</DeviceDisplay>
        </li>
        <li>
          Press the ┃ button to clear the memory. The display should now read{' '}
          <DeviceDisplay>— —</DeviceDisplay>
        </li>
        <li>Press and hold the ┃ button to turn the device off</li>
      </ol>

      <h4>
        <i className="fas fa-info-circle mr-3" />
        Instructions for setting the date and time
      </h4>
      <ol
        css={{
          listStyleType: 'decimal',
          li: { fontSize: 16, lineHeight: '22px' },
        }}>
        <li>
          Press the ┃ and ⬆ buttons to enter SETUP mode. The display should now read{' '}
          <DeviceDisplay>Clr no</DeviceDisplay>
        </li>
        <li>
          IF NOT, press the ⬆ button to set the value to
          <DeviceDisplay>no</DeviceDisplay>
        </li>
        <li>
          Press the ┃ button to advance to the next setting. The display should now read{' '}
          <DeviceDisplay>y</DeviceDisplay>. <br />
          IF NOT, press the ┃ button to advance to the next setting until it does
        </li>
        <li>
          Press the ⬆ button to set the current year: <DeviceDisplay>{`{{ YEAR }}`}</DeviceDisplay>
        </li>
        <li>
          Press ┃ the button to advance to the next setting. The display should now read{' '}
          <DeviceDisplay>nn</DeviceDisplay>
        </li>
        <li>
          Press the ⬆ button to set the current month:{' '}
          <DeviceDisplay>{`{{ MONTH }}`}</DeviceDisplay>
        </li>
        <li>Press the ┃ button to advance to the next setting. The display should now read d</li>
        <li>
          Press the <i className="fa fa-signal mr-1" /> button to set the current day:{' '}
          <DeviceDisplay>{`{{ DATE }}`}</DeviceDisplay>
        </li>
        <li>Press the ┃ button to advance to the next setting. The display should now read h</li>
        <li>
          Press the <i className="fa fa-signal mr-1" /> button to set the current hour:{' '}
          <DeviceDisplay>{`{{ HOUR }}`}</DeviceDisplay>
        </li>
        <li>Press the button to advance to the next setting. The display should now read nn</li>
        <li>
          Press the <i className="fa fa-signal mr-1" /> button to set the current minute:{' '}
          <DeviceDisplay>{`{{ MINUTE }}`}</DeviceDisplay>
        </li>
        <li>
          Press the ┃ button to advance to the next setting. The display should now read{' '}
          <DeviceDisplay>— —</DeviceDisplay>
        </li>
        <li>Press and hold the ┃ button to turn the device off</li>
      </ol>
      <Button variant="secondary mt-3" onClick={onClose}>
        <i className="fas fa-undo mr-2" /> Reset Another Device
      </Button>
    </div>
  )
}

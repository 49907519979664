/** @jsxImportSource @emotion/react */
import { Formik } from 'formik'
import { useState } from 'react'
import Button from 'react-bootstrap/Button'
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn'

import { Order } from 'store/types'
import { useGetLabDevicesQuery } from 'store/services/devices'
import {
  useUpdateHstStatusMutation,
  useReturnThirdPartyDeviceMutation,
} from 'store/services/labOrder'
import { useGetLabPatientQuery } from 'store/services/patient'
import DeviceSelection from '../TestSmarterFulfillment/DeviceSelection'
import SendWelcome from '../TestSmarterFulfillment/SendWelcome'
import ShippingSection from '../TestSmarterFulfillment/ShippingSection'
import { confirm } from 'components/molecules/Confirmation'
import Toast from 'components/atoms/Toast'

interface IProps {
  order: Order
  orderState: any
}

export const hstStatusOptions = [
  // { value: 'S001', label: 'Order Created' },
  { value: 'S002', label: 'Device Shipped' },
  { value: 'S003', label: 'Device Delivered' },
  { value: 'S004', label: 'Device Shipped to TestSmarter' },
  // { value: 'S004', label: 'Test Data uploaded for Night - 1' },
  // { value: 'S005', label: 'Test Data uploaded for Night - 2' },
  // { value: 'S006', label: 'Test Not Completed for Night - 1' },
  // { value: 'S007', label: 'Test Not Completed for Night - 2' },
  // { value: 'S008', label: 'Data uploaded to Ensodata for Night - 1' },
  // { value: 'S009', label: 'Data uploaded to Ensodata for Night - 2' },
  // { value: 'S010', label: 'Scoring Completed for Night - 1' },
  // { value: 'S011', label: 'Scoring Completed for Night - 2' },
  // { value: 'S012', label: 'Scoring Completed but could not score one of the nights' },
  // { value: 'S013', label: 'Reports Downloaded from Ensodata' },
  // { value: 'S014', label: 'Interpretation Signed' },
  // { value: 'S015', label: 'Signed Report Transferred' },
]

export const DeviceShipping = ({ order, orderState }: IProps) => {
  const [hstStatus, setHstStatus] = useState({ value: 'S002', label: 'Device Shipped' })
  const [updateStatus] = useUpdateHstStatusMutation()
  const [returnThirdPartyDevice] = useReturnThirdPartyDeviceMutation()

  const { data: patient } = useGetLabPatientQuery({
    id: order.patientId,
  })

  const hasNoSmartPhone =
    !!order?.thirdPartyDevice?.hasNoSmartPhone ||
    !(order?.patient?.email || order?.patient?.mobilePhone)

  const initialValues = {
    sendEmail: true,
    sendSms: false,
    email: patient?.email || '',
    mobilePhone: patient?.mobilePhone?.replace(/^\+1/, '') ?? '',
    searchSerialNumber: '',
    searchMacAddress: '',
    searchLabel: '',
    deviceSerial: null,
    deviceBrandId: -1,
  }

  const welcomeMessage = Object.values(order?.mobileInvites || {})
    ?.sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1))
    ?.pop()

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={undefined}
      onSubmit={() => {}}>
      {({ values, errors, touched, handleChange, setFieldValue, setFieldTouched, setValues }) => {
        const allowedDevices = hasNoSmartPhone ? [15, 13] : [15, 14, 13]

        const { data: devices } = useGetLabDevicesQuery({
          serial: order?.thirdPartyDevice?.serial || values.searchSerialNumber,
          mac: values.searchMacAddress,
          label: values.searchLabel,
          brandId: allowedDevices?.join(','),
          page: 0,
          size: 100,
        })

        return (
          <div
            css={{
              width: '100%',
              boxSizing: 'border-box',
              padding: '0 8px',
            }}>
            <div
              css={{
                background: '#fff',
                border: '1px solid #ccc',
                borderRadius: 5,
              }}
              className={'p-4'}>
              <p className={'text-lg'}>OneShip</p>
              <DeviceSelection
                testType={order.testType}
                thirdPartyDevice={order?.thirdPartyDevice}
                values={values}
                devices={devices}
                setValues={setValues}
                startShipping={hasNoSmartPhone || welcomeMessage || values.deviceBrandId !== 13} // viatom
                allowedDevices={
                  hasNoSmartPhone ? (order?.testType === 'HST' ? [15, 13] : [15]) : [15, 14, 13]
                }
              />
              {order?.thirdPartyDevice?.serial &&
                order?.device?.model === 'viatom' &&
                !hasNoSmartPhone && (
                  <SendWelcome
                    welcomeMessage={welcomeMessage}
                    testType={order?.testType}
                    values={values}
                    order={order}
                  />
                )}
              {(welcomeMessage || hasNoSmartPhone || order?.device?.model !== 'viatom') &&
                order?.thirdPartyDevice?.serial && <ShippingSection order={order} />}
              <Button
                variant={'success'}
                className="mt-3 d-flex align-items-center"
                onClick={() =>
                  confirm('', {
                    title: 'Are you sure?',
                    description: 'This will mark the device as returned. Proceed?',
                    yesAction: 'Yes, proceed',
                    noAction: 'No, cancel',
                  })
                    .then(async () => {
                      await returnThirdPartyDevice({ orderId: Number(order.id) }).unwrap()
                      Toast({
                        type: 'success',
                        label: 'Device marked as returned',
                      })
                    })
                    .catch(() => {})
                }>
                <KeyboardReturnIcon className={'text-normal mr-2'} /> Return Device
              </Button>
            </div>
          </div>
        )
      }}
    </Formik>
  )
}

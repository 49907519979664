import * as generalHelper from "../../helpers/generalHelper";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ------------------------------------------------------------
export const getBrands = createAsyncThunk(
    "getBrands",
    async (params, { rejectWithValue }) => {
        try {
            const data = await generalHelper.apiGet("/api/brand", params);
            if (!data) {
                return rejectWithValue([]);
            }
            return data;
        } catch (error) {
            console.log("error", error);
        }
    }
);

// ------------------------------------------------------------
export const getBrand = createAsyncThunk(
    "getBrand",
    async (params, { rejectWithValue }) => {
        try {
            params.return_key = "one";
            const data = await generalHelper.apiGet("/api/brand", params);
            if (!data) {
                return rejectWithValue([]);
            }
            return data;
        } catch (error) {
            console.log("error", error);
        }
    }
);

// ------------------------------------------------------------
export const updateBrand = createAsyncThunk(
    "updateBrand",
    async ({ id, params }, { rejectWithValue }) => {
        try {
            const data = await generalHelper.apiPut("/api/brand", id, params);
            if (!data) {
                return rejectWithValue([]);
            }
            return data;
        } catch (error) {
            console.log("error", error);
        }
    }
);

// ------------------------------------------------------------
export const insertBrand = createAsyncThunk(
    "insertBrand",
    async (params, { rejectWithValue }) => {
        try {
            const data = await generalHelper.apiPost("/api/brand", params);
            if (!data) {
                return rejectWithValue([]);
            }
            return data;
        } catch (error) {
            console.log("error", error);
        }
    }
);

// ------------------------------------------------------------
export const deleteBrand = createAsyncThunk(
    "deleteBrand",
    async ({ id, params }, { rejectWithValue }) => {
        try {
            const data = await generalHelper.apiDelete(
                "/api/brand",
                id,
                params
            );
            if (!data) {
                return rejectWithValue([]);
            }
            return data;
        } catch (error) {
            console.log("error", error);
        }
    }
);

// ------------------------------------------------------------
export const resetBrand = createAsyncThunk(
    "resetBrand",
    async (params, { rejectWithValue }) => {
        return params;
    }
);
// ------------------------------------------------------------
const initialState = {
    pending: false,
    rows: [],
    row: {},
    update: {},
    insert: {},
    delete: {},
    rejected: false,
};

// ------------------------------------------------------------
const localSlice = createSlice({
    name: "brand",
    initialState,
    extraReducers: {
        [getBrands.rejected]: (state, action) => {
            state.pending = false;
            state.rejected = true;
            state.rows = null;
        },
        [getBrands.pending]: (state, action) => {
            state.pending = true;
            state.rejected = false;
            state.rows = null;
        },
        [getBrands.fulfilled]: (state, action) => {
            state.pending = false;
            state.rejected = false;
            state.rows = action.payload;
        },
        [getBrand.rejected]: (state, action) => {
            state.pending = false;
            state.rejected = true;
            state.rows = null;
        },
        [getBrand.pending]: (state, action) => {
            state.pending = true;
            state.rejected = false;
            state.rows = null;
        },
        [getBrand.fulfilled]: (state, action) => {
            state.pending = false;
            state.rejected = false;
            state.row = action.payload;
        },
        [updateBrand.rejected]: (state, action) => {
            state.pending = false;
            state.rejected = true;
            state.update = null;
        },
        [updateBrand.pending]: (state, action) => {
            state.pending = true;
            state.rejected = false;
            state.update = null;
        },
        [updateBrand.fulfilled]: (state, action) => {
            state.pending = false;
            state.rejected = false;
            state.update = action.payload;
        },
        [insertBrand.rejected]: (state, action) => {
            state.pending = false;
            state.rejected = true;
            state.update = null;
        },
        [insertBrand.pending]: (state, action) => {
            state.pending = true;
            state.rejected = false;
            state.update = null;
        },
        [insertBrand.fulfilled]: (state, action) => {
            state.pending = false;
            state.rejected = false;
            state.update = action.payload;
        },
        [deleteBrand.rejected]: (state, action) => {
            state.pending = false;
            state.rejected = true;
            state.update = null;
        },
        [deleteBrand.pending]: (state, action) => {
            state.pending = true;
            state.rejected = false;
            state.update = null;
        },
        [deleteBrand.fulfilled]: (state, action) => {
            state.pending = false;
            state.rejected = false;
            state.update = action.payload;
        },
        [resetBrand.fulfilled]: (state, action) => {
            state.pending = false;
            state.rejected = false;
            action.payload.rows ? (state.rows = action.payload.rows) : false;
            action.payload.row ? (state.row = action.payload.row) : false;
            action.payload.update
                ? (state.update = action.payload.update)
                : false;
            action.payload.insert
                ? (state.insert = action.payload.insert)
                : false;
            action.payload.delete
                ? (state.delete = action.payload.delete)
                : false;
        },
    },
});

export default localSlice.reducer;

import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import Table from '../../atoms/Table'
import DashboardContent from '../atoms/DashboardContent'
import { getRoles } from '../../../store/slice/roleSlice'
import ButtonNew from '../../atoms/ButtonNew'
import routes from '../constants/routes'

interface IProps {
  handleSubmit: () => void
}

const ManagerRole = (props: IProps) => {
  const rows = useAppSelector(state => state.role.rows)
  const office_id = useAppSelector(state => state.init.office_id)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getRoles({ no_cache: 1 }))
  }, [office_id])

  const header = [
    {
      key: 'id',
      to: `${routes.index}${routes.manager.role}/${routes.upsert}`,
      label: 'Id',
    },
    { key: 'role_type_id', label: 'Type Id' },
    { key: 'role_type', key2: 'name', label: 'Type' },
    { key: 'name', label: 'Name' },
  ]

  return (
    <DashboardContent
      title="Manage Roles"
      // icon="fas fa-user-tag"
      content={
        <div className="card card-primary">
          <form type="post" onSubmit={props.handleSubmit}>
            <div className="card-header">
              <h3 className="card-title"></h3>
              <div className="card-tools">
                <ButtonNew
                  to={`${routes.index}${routes.manager.role}${routes.upsert}`}
                />
              </div>
            </div>
            <div className="card-body row">
              <Table header={header} rows={rows} />
            </div>
          </form>
        </div>
      }
      breadcrumb={[
        { label: 'Home', to: `${routes.index}` },
        { label: 'Manage Roles' },
      ]}
    />
  )
}
export default ManagerRole

import * as generalHelper from '../../helpers/generalHelper'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { IdAndParam, KeyVal, Office } from '../types'

// ------------------------------------------------------------
export const getOffices = createAsyncThunk(
  'getOffices',
  async (params: KeyVal, { rejectWithValue }) => {
    try {
      const data = await generalHelper.apiGet('/api/office', params)
      if (!data) {
        return rejectWithValue([])
      }
      return data
    } catch (error) {
      console.log('error', error)
    }
  }
)

// ------------------------------------------------------------
export const getOffice = createAsyncThunk(
  'getOffice',
  async (params: KeyVal, { rejectWithValue }) => {
    try {
      params.return_key = 'one'
      const data = await generalHelper.apiGet('/api/office', params)
      if (!data) {
        return rejectWithValue([])
      }
      return data
    } catch (error) {
      console.log('error', error)
    }
  }
)

// ------------------------------------------------------------
export const updateOffice = createAsyncThunk(
  'updateOffice',
  async (
    { id, params }: { id: number; params: Office },
    { rejectWithValue }
  ) => {
    try {
      const data = await generalHelper.apiPut('/api/office', id, params)
      if (!data) {
        return rejectWithValue([])
      }
      return data
    } catch (error) {
      console.log('error', error)
    }
  }
)

// ------------------------------------------------------------
export const insertOffice = createAsyncThunk(
  'insertOffice',
  async (params: Office, { rejectWithValue }) => {
    try {
      const data = await generalHelper.apiPost('/api/office', params)
      if (!data) {
        return rejectWithValue([])
      }
      return data
    } catch (error) {
      console.log('error', error)
    }
  }
)

// ------------------------------------------------------------
export const deleteOffice = createAsyncThunk(
  'deleteOffice',
  async ({ id, params }: IdAndParam, { rejectWithValue }) => {
    try {
      const data = await generalHelper.apiDelete('/api/office', id, params)
      if (!data) {
        return rejectWithValue([])
      }
      return data
    } catch (error) {
      console.log('error', error)
    }
  }
)

// ------------------------------------------------------------
interface OfficeState {
  pending: boolean
  rows: Office[] | null
  row: Office
  update: any
  insert: any
  delete: any
  rejected: boolean
}

const initialState: OfficeState = {
  pending: false,
  rows: [],
  row: {},
  update: {},
  insert: {},
  delete: {},
  rejected: false,
}

// ------------------------------------------------------------
const localSlice = createSlice({
  name: 'office',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getOffices.rejected, (state, action) => {
        state.pending = false
        state.rejected = true
        state.rows = null
      })
      .addCase(getOffices.pending, (state, action) => {
        state.pending = true
        state.rejected = false
        state.rows = null
      })
      .addCase(getOffices.fulfilled, (state, action) => {
        state.pending = false
        state.rejected = false
        state.rows = action.payload
      })
      .addCase(getOffice.rejected, (state, action) => {
        state.pending = false
        state.rejected = true
        state.rows = null
      })
      .addCase(getOffice.pending, (state, action) => {
        state.pending = true
        state.rejected = false
        state.rows = null
      })
      .addCase(getOffice.fulfilled, (state, action) => {
        state.pending = false
        state.rejected = false
        state.row = action.payload
      })
      .addCase(updateOffice.rejected, (state, action) => {
        state.pending = false
        state.rejected = true
        state.rows = null
      })
      .addCase(updateOffice.pending, (state, action) => {
        state.pending = true
        state.rejected = false
        state.rows = null
      })
      .addCase(updateOffice.fulfilled, (state, action) => {
        state.pending = false
        state.rejected = false
        state.update = action.payload
      })
      .addCase(insertOffice.rejected, (state, action) => {
        state.pending = false
        state.rejected = true
        state.rows = null
      })
      .addCase(insertOffice.pending, (state, action) => {
        state.pending = true
        state.rejected = false
        state.rows = null
      })
      .addCase(insertOffice.fulfilled, (state, action) => {
        state.pending = false
        state.rejected = false
        state.insert = action.payload
      })
      .addCase(deleteOffice.rejected, (state, action) => {
        state.pending = false
        state.rejected = true
        state.rows = null
      })
      .addCase(deleteOffice.pending, (state, action) => {
        state.pending = true
        state.rejected = false
        state.rows = null
      })
      .addCase(deleteOffice.fulfilled, (state, action) => {
        state.pending = false
        state.rejected = false
        state.delete = action.payload
      })
  },
})

export default localSlice.reducer

import { useContext, useReducer, createContext, ReactNode, useEffect } from 'react'

interface TestConditions {
  oxygen: number
  oxygenLpm: number
  deviceType: string
  procedure?: string

  papType: string
  apapMax: number
  apapMin: number
  bipapE: number
  bipapI: number
  cpap: number

  ventilatorType: string
}

interface ContextState {
  isDirty: boolean
  testType: string
  duration: number
  tests: {
    '1'?: TestConditions
    '2'?: TestConditions
  }
}

interface ReducerAction {
  type: string
  payload: {
    testType?: string
    duration?: string
    night?: '1' | '2'
    key?: string
    value?: number | string
  }
}

interface TestErrors {
  papType?: string
  apapMax?: string
  bipapE?: string
  cpap?: string
  deviceType?: string
  ventilatorType?: string
}

interface Errors {
  duration?: string
  testType?: string
  tests?: {
    '1'?: TestErrors
    '2'?: TestErrors
  }
}

interface ContextInterface {
  state: ContextState
  dispatch: (v: ReducerAction) => void
}

const conditionTestSettings: TestConditions = {
  oxygen: 0,
  oxygenLpm: 0,
  deviceType: '',

  papType: '',
  apapMax: 0,
  apapMin: 0,
  bipapE: 0,
  bipapI: 0,
  cpap: 0,

  ventilatorType: '',
}

const initialState: ContextState = {
  isDirty: false,
  testType: 'POX',
  duration: 1,
  tests: {
    '1': conditionTestSettings,
  },
}

type NightIndex = keyof ContextState['tests']

const reducer = (state: ContextState = initialState, action: ReducerAction) => {
  const night: NightIndex = action.payload.night || '1'
  const tests = state.tests[night]

  const mergeSettings = (settings: Partial<TestConditions>) => {
    return {
      ...state,
      tests: {
        ...state.tests,
        [night]: {
          ...tests,
          ...settings,
        },
      },
    }
  }

  switch (action.type) {
    case 'is-dirty': {
      return {
        ...state,
        isDirty: action.payload,
      }
    }
    case 'test-type': {
      return {
        ...state,
        testType: action.payload.testType ?? '',
      }
    }

    case 'duration': {
      const duration = parseInt(action.payload.duration ?? '0') || 0
      const nextTests: ContextState['tests'] = {}
      const nextState: ContextState = {
        ...state,
        duration,
      }

      Array.from(Array(duration)).forEach((s, index) => {
        const night = (index + 1).toString() as '1' | '2'
        if (state.tests[night]) {
          nextTests[night] = state.tests[night]
        } else {
          nextTests[night] = conditionTestSettings
        }
      })

      nextState.tests = nextTests

      return nextState
    }

    case 'condition:room-air': {
      return mergeSettings({
        oxygen: 0,
        oxygenLpm: 0,
        deviceType: '',
        papType: '',
        apapMax: 0,
        apapMin: 0,
        bipapE: 0,
        bipapI: 0,
        cpap: 0,
        ventilatorType: '',
      })
    }

    case 'condition:oxygen': {
      return mergeSettings({
        oxygen: 1,
      })
    }

    case 'device-type': {
      return mergeSettings({
        deviceType: action.payload.value?.toString(),
        papType: '',
        apapMax: 0,
        apapMin: 0,
        bipapE: 0,
        bipapI: 0,
        cpap: 0,
        ventilatorType: '',
      })
    }

    case 'procedure': {
      return mergeSettings({
        procedure: action.payload.value?.toString(),
        papType: '',
        apapMax: 0,
        apapMin: 0,
        bipapE: 0,
        bipapI: 0,
        cpap: 0,
        ventilatorType: '',
      })
    }

    case 'pap-type': {
      return mergeSettings({
        papType: action.payload.value?.toString(),
        apapMax: 0,
        apapMin: 0,
        bipapE: 0,
        bipapI: 0,
        cpap: 0,
        ventilatorType: '',
      })
    }

    case 'set-condition-value': {
      if (action.payload.key) {
        return mergeSettings({
          [action.payload.key]: action.payload.value,
        })
      }
      return state
    }

    default:
      return state
  }
}

const OrderSettingsContext = createContext<ContextInterface>({
  state: initialState,
  dispatch: () => {},
})

export const OrderSettingsProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const dispatchWrapper = (...args) => {
    dispatch({
      type: 'is-dirty',
      payload: true,
    })
    dispatch(...args)
  }

  return (
    <OrderSettingsContext.Provider value={{ state, dispatch: dispatchWrapper }}>
      {children}
    </OrderSettingsContext.Provider>
  )
}

export const useOrderSettings = (disabledTests?: string[]) => {
  const { state, dispatch } = useContext(OrderSettingsContext)

  useEffect(() => {
    if (Array.isArray(disabledTests) && disabledTests.includes(state.testType)) {
      dispatch({
        type: 'test-type',
        payload: {
          testType: '',
        },
      })
    }
  }, [state])

  // Validate
  const errors: Errors = {}
  if (Object.keys(state.tests).length === 0) {
    errors.duration = 'Missing Duration'
  }

  if (!state.testType) {
    errors.testType = 'Missing Test Type'
  }

  ;(Object.keys(state.tests) as NightIndex[]).forEach((night) => {
    // Validate PAP
    if (state.tests[night]?.deviceType === 'PAP' && !state.tests[night]?.papType) {
      errors.tests = errors.tests ?? {}
      errors.tests[night] = errors.tests[night] ?? {}
      errors.tests[night].papType = 'Missing PAP type'
    }
    // else if (
    //   state.tests[night]?.papType === 'APAP' &&
    //   state.tests[night]?.apapMax <= state.tests[night]?.apapMin
    // ) {
    //   errors.tests = errors.tests ?? {}
    //   errors.tests[night] = errors.tests[night] ?? {}
    //   errors.tests[night].apapMax = 'Max and Min has incorrect values'
    // } else if (
    //   state.tests[night]?.papType === 'BiPAP' &&
    //   (state.tests[night]?.bipapE <= 0 || state.tests[night]?.bipapI <= 0)
    // ) {
    //   errors.tests = errors.tests ?? {}
    //   errors.tests[night] = errors.tests[night] ?? {}
    //   errors.tests[night].bipapE = 'Invalid BiPAP values'
    // } else if (state.tests[night]?.papType === 'CPAP' && state.tests[night]?.cpap <= 0) {
    //   errors.tests = errors.tests ?? {}
    //   errors.tests[night] = errors.tests[night] ?? {}
    //   errors.tests[night].cpap = 'Invalid CPAP value'
    // }

    // Validate Ventilator
    if (state.tests[night]?.deviceType === 'Ventilator' && !state.tests[night]?.ventilatorType) {
      errors.tests = errors.tests ?? {}
      errors.tests[night] = errors.tests[night] ?? {}
      errors.tests[night].ventilatorType = 'Invalid Ventilator type'
    }
  })

  const stateWithErrors = {
    ...state,
    errors,
    isValid: Object.keys(errors).length === 0,
  }

  return { state: stateWithErrors, dispatch }
}

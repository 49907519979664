import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import ReactSelect from 'react-select'
import { toast } from 'react-toastify'
import BsButton from 'react-bootstrap/Button'

import ButtonModal from 'components/atoms/ButtonModal'
import Button from 'components/atoms/Button'
import Textarea from 'components/atoms/Textarea'
import { handleChange } from 'components/atoms/Upsert'
import {
  useAssignDeviceMutation,
  useGetAvailableDevicesQuery,
  useRemoveDeviceMutation,
} from 'store/services/dmeOrder'
import { useSendDmeNoteMutation } from 'store/services/notes'
import { DeviceDto } from 'ts-back-end/src/business/device/dto/device.dto'
import { Order } from 'store/types'
import routes from 'components/dme/constants/routes'
import { confirm } from 'components/molecules/Confirmation'
import getOrderState from 'helpers/orderStateHelpers'
import { useDeviceForms } from 'hooks/useDeviceForms'

interface IProps {
  open: boolean
  onClose: (action?: () => void) => void
  order: Order
}

type Value = {
  value: string
  label: string
}
type FormValue = {
  brandName: Value
  deviceId: Value
  message: string
}
const initialFormValues: FormValue = {
  brandName: { value: '', label: '' },
  deviceId: { value: '', label: '' },
  message: '',
}

const UNAVAILABLE_DEVICE_STATES = ['RETURN_RECEIVED', 'IN_REPAIR', 'RETURN_IN_TRANSIT']

const DeviceModal = ({ open, onClose, order }: IProps) => {
  let { orderId } = useParams()
  const navigate = useNavigate()
  const [values, setValues] = useState(initialFormValues)
  const [showDeviceInstructions, setShowDeviceInstructions] = useState(false)
  const [assignDevice] = useAssignDeviceMutation()
  const [sendDmeNote] = useSendDmeNoteMutation()
  const [removeDevice] = useRemoveDeviceMutation()
  const { data: devices, refetch } = useGetAvailableDevicesQuery(Number(orderId))
  const orderState = getOrderState(order)
  const { downloadForms, downloadDocument } = useDeviceForms(order)

  const getPatientName = (device: DeviceDto): string => {
    return `${device.patient?.firstName} ${device.patient?.lastName}`
  }

  const brands = useMemo(
    () =>
      devices
        ?.reduce((agg: string[], curr: DeviceDto) => {
          if (
            !UNAVAILABLE_DEVICE_STATES.includes(curr.state) &&
            !agg.includes(curr.brand?.name || '')
          ) {
            agg.push(curr.brand?.name || '')
          }
          return agg
        }, [])
        .map((b) => ({ value: b, label: b })),
    [devices],
  )

  const getLabel = (d: DeviceDto) => {
    let label = ''
    if (d.label) {
      label = `Label: ${d.label.trim().toUpperCase()}`
    }
    if (d.serial) {
      label += `${d.label ? ' /' : ''} Serial #: ${d.serial}`
    }
    if (d.orderId) {
      label += ` (assigned to: ${getPatientName(d)}), order: ${d.orderId}`
    }
    return label
  }

  const filteredDevices = useMemo(() => {
    return devices
      ?.filter(
        (d) =>
          !UNAVAILABLE_DEVICE_STATES.includes(d.state) && d.brand?.name === values.brandName.value,
      )
      ?.sort((a, b) => (a.orderId && !b.orderId ? 1 : -1))
      ?.map((d) => ({
        value: String(d.id),
        label: getLabel(d),
      }))
  }, [values.brandName, devices])

  useEffect(() => {
    if (open) {
      refetch()
      if (order?.device?.id) {
        return setValues({
          brandName: { value: order.device.brand, label: order.device.brand },
          deviceId: { value: order.device.id, label: getLabel(order.device) },
          message: '',
        })
      }
      setValues(initialFormValues)
    }
  }, [open, order])

  const handleValidation = () => !(Number(values?.deviceId.value) > 0)

  const handleSubmit = async () => {
    const { deviceId, message } = values
    if (!deviceId.value) return
    if (deviceId.value === order?.device?.id) return onClose()
    if (order?.device?.id) {
      await confirm('', {
        title: 'Are You Sure?',
        description: `This will remove the currently assigned device ${order.device.serial} and assign the new one.`,
        yesAction: 'Yes, Proceed',
      })
    }
    const device = devices?.find((d) => Number(d.id) === Number(deviceId.value))
    if (device?.orderId) {
      await confirm('', {
        title: 'Are You Sure?',
        description: `This device is already assigned to the order for ${getPatientName(
          device,
        )}. Are you sure you want to assign it to this order?`,
        yesAction: 'Yes, Proceed',
      })
      await removeDevice({ orderId: Number(device?.orderId) })
    }

    try {
      await assignDevice({ orderId: Number(orderId), deviceId: Number(deviceId.value) }).unwrap()
      if (message) {
        sendDmeNote({ orderId: Number(orderId), text: message })
      }
      toast.success('Device Assigned')
      setShowDeviceInstructions(true)
    } catch (err) {
      const { data } = err as { data: { message: string } }
      toast.error(data?.message)
    }
  }

  return (
    <Modal centered show={open} onHide={onClose} size="lg">
      <Modal.Header>
        <Modal.Title>Device for Order #{orderId}</Modal.Title>
      </Modal.Header>
      {showDeviceInstructions ? (
        <>
          <Modal.Body>
            {order?.device?.brand} #{order?.device?.label} can now be handed out to the patient.{' '}
            <br />
            <br />
            <strong>Print Patient Instructions Handout:</strong>
            <br />
            <br />
            <BsButton size="sm" className="mr-2" onClick={downloadDocument(downloadForms?.keyEn!)}>
              <i className="fa fa-download mr-2" />
              Download English
            </BsButton>
            {downloadForms?.keyEs && (
              <BsButton size="sm" onClick={downloadDocument(downloadForms?.keyEs)}>
                <i className="fa fa-download mr-2" />
                Download Spanish
              </BsButton>
            )}
          </Modal.Body>
          <Modal.Footer>
            <ButtonModal
              label="Close"
              className="btn-default"
              type="button"
              data_dismiss="modal"
              onClick={() => {
                const closeAction = !orderState.isAobUploaded
                  ? () => navigate(`${routes.index}${routes.order.edit}${orderId}/AOBForm`)
                  : undefined
                onClose(closeAction)
                setShowDeviceInstructions(false)
              }}
            />
          </Modal.Footer>
        </>
      ) : (
        <>
          <Modal.Body>
            {order?.device?.id ? (
              <div className="col-sm-12 col-md-12 mb-3">
                The following device is already selected for this order:
              </div>
            ) : (
              <></>
            )}
            <div className="col-sm-12 col-md-12">
              <label className="text-gray mb-1" htmlFor="brandName">
                Select the Device Model
              </label>
              <ReactSelect
                className="basic-single w-100 mr-3 mb-2"
                classNamePrefix="select"
                isSearchable
                placeholder="Select the Brand"
                name="brandName"
                onChange={(item) => setValues((p) => ({ ...p, brandName: item }) as FormValue)}
                options={brands ?? []}
                value={values.brandName ?? null}
              />
            </div>
            <div className="col-sm-12 col-md-12">
              <label className="text-gray mb-1" htmlFor="brandName">
                Select the Device
              </label>
              <ReactSelect
                className="basic-single w-100 mr-3 mb-3"
                classNamePrefix="select"
                isSearchable
                placeholder="Select the Device"
                name="brandName"
                onChange={(item) => setValues((p) => ({ ...p, deviceId: item }) as FormValue)}
                options={filteredDevices ?? []}
                value={values.deviceId ?? null}
              />
            </div>
            <div className="col-sm-12 col-md-12 form-group">
              <Textarea
                label="Add Any Notes or Comments Below"
                name="message"
                rows={6}
                value={values.message}
                handleChange={(event) => {
                  handleChange(event as ChangeEvent<HTMLInputElement>, values, setValues)
                }}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <ButtonModal
              label="Cancel"
              className="btn-default"
              type="button"
              data_dismiss="modal"
              onClick={onClose}
            />
            <Button
              label="Save"
              type="button"
              icon="fa fa-save"
              id="btn-ts"
              className="ml-2"
              data-dismiss="modal"
              disabled={handleValidation()}
              onClick={() => {
                void handleSubmit()
              }}
            />
          </Modal.Footer>
        </>
      )}
    </Modal>
  )
}

export default DeviceModal

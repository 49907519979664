import TitleLead from '../../../atoms/TitleLead'
import Input from '../../../atoms/Input'
import Radio from '../../../atoms/Radio'
import Select from '../../../atoms/Select'
import { useOrderSettings } from './useOrderSettings'
import * as generalHelper from 'helpers/generalHelper'
import {
  deviceTypeArray,
  papTypeArray,
  ventilatorTypeArray,
} from 'components/dme/constants/testDeviceParams'
import { pressureOptions, bipapPressureOptions } from 'components/dme/constants/testDeviceParams'

interface IProps {
  night: '1' | '2'
}

const TestCondition = ({ night = '1' }: IProps) => {
  const { state: settings, dispatch: dispatchSetting } = useOrderSettings()
  const conditionSettings = settings.tests[night]

  return (
    <div className="col-sm-4">
      <div className="d-flex flex-column px-2">
        <TitleLead
          className="ml-2 my-3"
          label={generalHelper.t(`Night ${night} Test Conditions`)}
        />
        <hr className="w-100 mt-0" />
        <div className="col-sm-12 mb-2 font-weight-bold textGray">
          Conditions
          <span className="text-danger">*</span>
        </div>

        {/* Begin conditions */}
        <div className="mb-2">
          <div className={`row d-flex flex-nowrap align-items-center ml-1`}>
            <div className={`pl-1`}>
              <label className="ml-1 font-weight-normal d-flex flex-nowrap align-items-center">
                <Radio
                  name={`oxygen-${night}`}
                  value="0"
                  checked={conditionSettings?.oxygen === 0}
                  onChange={() => {
                    dispatchSetting({
                      type: 'condition:room-air',
                      payload: { night },
                    })
                  }}
                  className="mr-2"
                />
                Room Air
              </label>
            </div>
          </div>

          <div className={`row d-flex flex-nowrap align-items-center ml-1`}>
            <div className={`pl-1`}>
              <label className="ml-1 mb-0 d-flex flex-nowrap align-items-center font-weight-normal">
                <Radio
                  name={`oxygen-${night}`}
                  value="1"
                  checked={conditionSettings?.oxygen === 1}
                  onChange={() => {
                    dispatchSetting({
                      type: 'condition:oxygen',
                      payload: { night },
                    })
                  }}
                  className="mr-2"
                />
                Oxygen
              </label>
            </div>

            {conditionSettings?.oxygen === 1 ? (
              <div className="d-flex align-items-center ml-2">
                <div>@</div>
                <div className="col-sm-4">
                  <Input
                    minValue="0"
                    type="number"
                    name={`oxygen_lpm_${night}`}
                    value={conditionSettings?.oxygenLpm ?? ''}
                    handleChange={(event) => {
                      dispatchSetting({
                        type: 'set-condition-value',
                        payload: {
                          key: 'oxygenLpm',
                          value: event.target.value,
                          night,
                        },
                      })
                    }}
                  />
                </div>
                <div>
                  <p className="m-0" style={{ lineHeight: '12px' }}>
                    LPM
                    <br />
                    <small>(Liters Per Minute)</small>
                  </p>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        {/* End conditions */}

        {/* Begin Device Type */}
        <div className="col-sm-12 mb-2 font-weight-bold textGray">
          Device Type
          {conditionSettings?.oxygen !== 0 && <span className="text-danger">*</span>}
        </div>
        <div className="row mb-3 align-items-center">
          {deviceTypeArray.map((row, index) => {
            return (
              <div key={index} className="pl-3">
                <label className="form-check-label ml-1 d-flex align-items-center">
                  <Radio
                    name={`devicetype-${night}`}
                    value={row.value}
                    checked={conditionSettings?.deviceType === row.value}
                    onChange={(event) => {
                      dispatchSetting({
                        type: 'device-type',
                        payload: { value: event.target.value, night },
                      })
                    }}
                    className="mr-2"
                  />
                  {row.name}
                </label>
              </div>
            )
          })}
        </div>
        {/* End Device Type */}

        {/* Begin PAP type */}
        {conditionSettings?.deviceType === 'PAP' && (
          <div className="container">
            <div className="row">
              <div className="col-sm-6 mb-2 font-weight-bold textGray">
                PAP Type
                <span className="text-danger">*</span>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="btn-group" role="group">
                  {papTypeArray.map((row, index) => {
                    return (
                      <button
                        onClick={() => {
                          dispatchSetting({
                            type: 'pap-type',
                            payload: { value: row.value, night },
                          })
                        }}
                        key={index}
                        type="button"
                        name={`pap_type_${night}`}
                        className={`btn btn-outline-primary ${
                          conditionSettings?.papType === row.value ? 'active' : ''
                        }`}>
                        {row.value}
                      </button>
                    )
                  })}
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-sm-12 mb-2 font-weight-bold textGray">
                {conditionSettings?.papType === 'APAP'
                  ? 'Min - Max Pressure (cmH20)'
                  : conditionSettings?.papType === 'BiPAP'
                    ? 'Inhalation - Exhalation Pressure (cmH20)'
                    : conditionSettings?.papType === 'CPAP'
                      ? 'Pressure (cmH20)'
                      : ''}
              </div>
            </div>
            <div className="row">
              {conditionSettings?.papType === 'APAP' && (
                <>
                  <div className="col-sm-3">
                    <Select
                      name={`apap_min_${night}`}
                      value={conditionSettings?.apapMin}
                      options={pressureOptions}
                      handleChange={(event) => {
                        dispatchSetting({
                          type: 'set-condition-value',
                          payload: {
                            key: 'apapMin',
                            value: parseInt(event.target.value) || 0,
                            night,
                          },
                        })
                      }}
                    />
                  </div>
                  <div className="col-sm-3">
                    <Select
                      name={`apap_max_${night}`}
                      value={conditionSettings?.apapMax}
                      options={pressureOptions}
                      handleChange={(event) => {
                        dispatchSetting({
                          type: 'set-condition-value',
                          payload: {
                            key: 'apapMax',
                            value: parseInt(event.target.value) || 0,
                            night,
                          },
                        })
                      }}
                    />
                  </div>
                </>
              )}
              {conditionSettings?.papType === 'BiPAP' && (
                <>
                  <div className="col-sm-3">
                    <Select
                      name={`bipap_i_${night}`}
                      value={conditionSettings?.bipapI}
                      options={bipapPressureOptions}
                      handleChange={(event) => {
                        dispatchSetting({
                          type: 'set-condition-value',
                          payload: {
                            key: 'bipapI',
                            value: parseInt(event.target.value) || 0,
                            night,
                          },
                        })
                      }}
                    />
                  </div>
                  <div className="col-sm-3">
                    <Select
                      name={`bipap_e_${night}`}
                      value={conditionSettings?.bipapE}
                      options={bipapPressureOptions}
                      handleChange={(event) => {
                        dispatchSetting({
                          type: 'set-condition-value',
                          payload: {
                            key: 'bipapE',
                            value: parseInt(event.target.value) || 0,
                            night,
                          },
                        })
                      }}
                    />
                  </div>
                </>
              )}
              {conditionSettings?.papType === 'CPAP' && (
                <>
                  <div className="col-sm-3">
                    <Select
                      name={`cpap_${night}`}
                      value={conditionSettings?.cpap}
                      options={pressureOptions}
                      handleChange={(event) => {
                        dispatchSetting({
                          type: 'set-condition-value',
                          payload: {
                            key: 'cpap',
                            value: parseInt(event.target.value) || 0,
                            night,
                          },
                        })
                      }}
                    />
                  </div>
                </>
              )}
              {conditionSettings?.papType === 'OTHER' && (
                <>
                  <div className="col-sm-12 mb-2">
                    <Input
                      label="Comments"
                      name={`otherPapComments`}
                      value={conditionSettings?.otherPapComments ?? ''}
                      handleChange={(event) => {
                        dispatchSetting({
                          type: 'set-condition-value',
                          payload: {
                            key: 'otherPapComments',
                            value: event.target.value,
                            night,
                          },
                        })
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        {/* End PAP type */}

        {/* Begin Ventilator type */}
        {conditionSettings?.deviceType === 'Ventilator' && (
          <div className="container mb-2">
            <div className="row">
              <div className="col-sm-6 mb-2 font-weight-bold textGray">
                Ventilator Type
                <span className="text-danger">*</span>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-sm-12">
                <div className="btn-group" role="group"></div>
                {ventilatorTypeArray.map((row, index) => {
                  return (
                    <button
                      key={`ventilator-option-${night}-${index}`}
                      onClick={() => {
                        dispatchSetting({
                          type: 'set-condition-value',
                          payload: {
                            key: 'ventilatorType',
                            value: row.value,
                            night,
                          },
                        })
                      }}
                      type="button"
                      className={`btn btn-outline-primary mr-2 ${
                        conditionSettings?.ventilatorType === row.value ? 'active' : ''
                      }`}>
                      {row.value}
                    </button>
                  )
                })}
              </div>
            </div>
          </div>
        )}
        {/* End Ventilator type */}
      </div>
    </div>
  )
}

export default TestCondition

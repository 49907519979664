import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'
import Toast from "../../atoms/Toast";
import DashboardContent from '../atoms/DashboardContent'
import { useCreateLabDeviceMutation, useUpdateLabDeviceMutation } from 'store/services/devices'
import routes from '../constants/routes'
import CreateDeviceForm from './CreateDeviceForm'

export interface DeviceFormValues {
  brandId: number | null
  label: string
  labelOverride: boolean
  serial: string
  mac: string
  bluetoothId: string
  state: string
  statusUpdatedAt: Date
  officeId: string
  enabled: boolean
  purchaseCost: number | null
  salePrice: number | null
  notes: string
}

const DeviceUpsert = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [createDevice] = useCreateLabDeviceMutation()
  const [updateDevice] = useUpdateLabDeviceMutation()

  return (
    <div>
      <DashboardContent
        title={id ? 'Edit Device' : 'Create Device'}
        icon="fa fa-hdd"
        content={
          <div className="card card-primary p-4">
            <CreateDeviceForm
              id={id ? Number(id) : undefined}
              onSubmit={async (values) => {
                const submitValues = {
                  ...values,
                  ...(!values.officeId && { officeId: null }),
                  statusUpdatedAt: values.statusUpdatedAt
                    ? moment(values.statusUpdatedAt).format('YYYY-MM-DD')
                    : undefined,
                }

                try {
                  if (id) {
                    await updateDevice({ id: Number(id), ...submitValues }).unwrap()
                    Toast({ type: 'success', label: `Device updated!` })
                  } else {
                    await createDevice(submitValues).unwrap()
                    Toast({ type: 'success', label: `Device created!` })
                  }

                  navigate(`${routes.index}${routes.device.root}`)
                } catch (e) {
                  Toast({ type: 'error', label: e.data.message })
                }
              }}
            />
          </div>
        }
        breadcrumb={[
          {
            label: 'Devices',
            to: `${routes.index}${routes.device.root}`,
          },
          { label: id ? 'Edit' : 'New' },
        ]}
      />
    </div>
  )
}

export default DeviceUpsert

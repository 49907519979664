export const COMPOUND_BUCKETS = ['ACTIVE_ORDERS', 'ALL_ORDERS']
export const ACTIVE_ORDERS = [
  'OPEN',
  'DRIVER_DELIVERY',
  'PATIENT_PICKUP',
  'PATIENT_TESTING',
  'DRIVER_PICKUP',
  'PATIENT_DROP_OFF',
  'FINALIZE_ORDER',
  'PENDING_RETURN',
  'NO_SHIPPED_BACK'
]
export const COMPOUND_BUCKET_MAP = {
  ACTIVE_ORDERS,
  ALL_ORDERS: [...ACTIVE_ORDERS, 'COMPLETED', 'CANCELLED', 'ARCHIVED'],
}

export const PAYER_STATUS_OPTIONS = [
  { value: 'Verified', key: 'DONE' },
  { value: 'Not Started', key: 'PENDING' },
  { value: 'Failed', key: 'WARNING' },
]

export const NIGHT_OWL_BUCKETS = [
  'NO_NEW',
  'NO_FOLLOW_UP',
  'NO_NEED_TO_SHIP',
  'NO_READY_TO_SHIP',
  'NO_SHIPPED',
  'NO_DELIVERED',
  'PENDING_RETURN',
  'NO_SHIPPED_BACK'
]

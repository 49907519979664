/** @jsxImportSource @emotion/react */
import { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { css } from '@emotion/react'

interface IProps {
  className?: string
  children: ReactNode
  onClick?: () => void
  to?: string
}

export const Pill = ({ className, children, onClick, to }: IProps) => {
  return (
    <Link to={to ?? '#'}>
      <div
        className={`text-bold d-flex justify-content-center align-items-center mt-1 btn btn-sm w-30 ${className}`}
        css={css`
          max-height: 22px;
          min-width: 48px;
          pointer-events: none;
          white-space: nowrap;
          cursor: ${onClick ? 'pointer' : 'default'};
        `}>
        <small>{children}</small>
      </div>
    </Link>
  )
}

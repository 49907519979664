import ReactPlayer from 'react-player'

import DashboardContent from '../atoms/DashboardContent'
import CalloutComponent from 'components/atoms/Callout'

const HelpVideos = () => {
  return (
    <DashboardContent
      title="Help Videos"
      content={
        <>
          <div className="col-12">
            <div className="card card-default">
              <div className="card-body">
                <CalloutComponent
                  title="TestSmarter Reader Installation Guide"
                  content={
                    <div>
                      <ReactPlayer
                        url={
                          'https://ts-platform-web-assets.s3.amazonaws.com/videos/2022-11-24-TestSmarterReaderSetup.mp4'
                        }
                        controls={true}
                      />
                    </div>
                  }
                />
              </div>

              <div className="card-body">
                <CalloutComponent
                  title="Uploading a Metronic Capnostream 35"
                  content={
                    <div>
                      <ReactPlayer
                        url={
                          'https://ts-platform-web-assets.s3.amazonaws.com/videos/TS-Learn-Medtronic-Capnostream-35-Upload.mp4'
                        }
                        controls={true}
                      />
                    </div>
                  }
                />
              </div>
            </div>
          </div>
        </>
      }
      breadcrumb={[{ label: 'Support' }]}
    />
  )
}

export default HelpVideos

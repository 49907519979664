import moment from 'moment'
import Button from 'components/atoms/Button'
import { Order } from 'store/types'

interface IProps {
  order: Order
  setOrderVerified: (v: boolean) => void
}

const OrderInfo = ({ order, setOrderVerified }: IProps) => {
  const { patient, office, physician, physicianAddress, state } = order

  if (state?.AOB === 'DONE') {
    return (
      <div className="row mt-4">
        <div className="col">
          <h4>Assignment of Benefits Forms Already Signed</h4>
          <p>We have already received your signed Assignment of Benefits form.</p>
          <a href="https://www.testsmarter.net/" className="btn btn-primary">
            Close this and go to our website
          </a>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="row">
        <div className="col d-flex mb-3">
          <h3>Order Information</h3>
          <Button
            id="btn-ts"
            label="Continue"
            type="button"
            className="btn-sm ml-auto"
            onClick={() => setOrderVerified(true)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <p className="patientInfo">
            Please confirm you are the patient listed below and have requested oxygen from{' '}
            {office.officeName}
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <p>
            <span className="d-block text-bold">{`${patient.firstName} ${patient.lastName}`}</span>
            <span className="d-block">{`Date of Birth: ${moment(patient.dob).format(
              'MM/DD/YYYY',
            )}`}</span>
            <span className="d-block">{`Order Number: ${order.id}`}</span>
            <span className="d-block">{`Date of Order: ${moment(order.confirmedAt).format(
              'MM/DD/YYYY',
            )}`}</span>
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col d-flex">
          <p className="mr-5">
            <span className="d-block text-bold">Ordering Physician</span>
            <span className="d-block">{`${physician?.firstName} ${physician?.lastName}`}</span>
            <span className="d-block">{`${physicianAddress?.addressStreetLine1}`}</span>
            <span className="d-block">{`${physicianAddress?.addressStreetLine2 ?? ''}`}</span>
            <span className="d-block">
              {`${physicianAddress?.addressCity}, ${physicianAddress?.addressState} ` +
                `${physicianAddress?.addressZip}`}
            </span>
          </p>
          <p>
            <span className="d-block text-bold">Oxygen Supplier</span>
            <span className="d-block">{office.officeName}</span>
            <span className="d-block">{office.addressStreetLine1}</span>
            <span className="d-block">
              {office.addressCity}, {office.addressState} {office.addressZip?.slice(0, 5)}
            </span>
            <span className="d-block">Tel: {office.telephone}</span>
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h4>PATIENT ACKNOWLEDGEMENT</h4>
          <p>
            I understand that my physician ordered this self-administered overnight diagnostic procedure for the purpose of evaluating my pulmonary and/or respiratory condition. I certify with my signature below that I am the patient or a duly qualified legal representative authorized to act on behalf of the patient, and that TestSmarter has provided me with clear instructions for performing this self-administered procedure. I further certify that neither I nor any other person tampered with the testing equipment or the data collected while in my possession. I authorize TestSmarter to communicate with me via SMS text messaging for all matters pertaining to this order. I understand that I am responsible for the entire cost of this procedure, either the billable amount allowed by my insurance plan, or the total amount of $34 if I am uninsured or if this procedure is not covered by my plan, as well as any deductible or co-pay. I understand that TestSmarter will bill me directly for any amount due, and I agree to promptly remit payment in full directly to TestSmarter upon receipt of any such bill.
          </p>
          <h4>ASSIGNMENT OF BENEFIT</h4>
          <p>
            I hereby authorize TestSmarter, an approved and credentialed Independent Diagnostic Testing Facility (IDTF), to file a claim and bill my insurance directly on my behalf for any costs associated with this procedure. I understand that I may be responsible for any amount not covered by my insurance, including any deductible or co-pay, and up the full amount due for this procedure if  it is not covered by my insurance or if my claim is denied for any other reason.
          </p>
          <h4>RELEASE OF MEDICAL RECORDS</h4>
          <p>
            By signing this form, I hereby authorize TestSmarter to release the results of this test, as well as any related documents, to my physician who ordered this procedure, and to the company listed above as "Home Medical Equipment Supplier." Further, I also authorize my physician and the "Home Medical Equipment Supplier" to discuss those results and coordinate any follow-up treatment or services as may be required. Consistent with Medicare policy, I understand that the "Home Medical Equipment Supplier" is not involved in any aspect of this procedure beyond acting only as a courier of the testing equipment.
          </p>
          <h4>NOTICE OF PRIVACY PRACTICES</h4>
          <p>
            You have the right to read our Privacy Policy below prior to signing this document. The notice provides a description of the protection of your personal health information under HIPAA laws governing the handling of said information. Please read the policies in their entirety prior to signing this document.
          </p>
          <h4>PRIVACY POLICY</h4>
          <p>
            TestSmarter Inc. is required to follow the Health Insurance Portability and Accountability Act (HIPAA) privacy rules to ensure the confidentiality of your protected health information (PHI). TestSmarter respects your privacy and is committed to protecting that information. We will not disclose your information except as authorized by you or required by law. HIPAA protects the privacy of the PHI we create and obtain in providing our care and services to you. Your PHI includes our test results and any diagnosis, treatment, or other health information obtained from other providers, as well as billing and payment information related to the services we provide you. Federal and state laws authorize us to use and disclose your PHI where necessary for the purpose of carrying out treatment and health care operations. State law requires us to obtain your authorization to disclose this information for payment purposes. For our complete privacy policy, please visit www.testsmarter.net/privacy.
          </p>
        </div>
      </div>
    </>
  )
}

export default OrderInfo

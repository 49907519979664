/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { useAuth0 } from '@auth0/auth0-react'

import { useAppDispatch } from 'hooks/redux'
import InputComponent from 'components/atoms/Input'
import { getLogout } from 'store/slice/loginSlice'
import SidebarSection from 'components/atoms/SidebarSection'
import { MenuData } from 'components/lab/constants/menu'
import { useGetLabOrderCountsQuery } from 'store/services/labOrder'
import { useGetLabNewTicketCountQuery } from 'store/services/tickets'

const StyledLink = styled(Link)`
  border-bottom: none !important;
`

const Menu = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [orderId, setOrderId] = useState('')
  const { logout } = useAuth0()
  const cachedMenuData = JSON.parse(sessionStorage.getItem('ts-lab-menu') ?? '[]')
  const [menuData, setMenuData] = useState(cachedMenuData?.length ? cachedMenuData : MenuData)
  const { data: rawOrderCounts, isLoading } = useGetLabOrderCountsQuery(
    { officeId: 1 },
    {
      pollingInterval: 60000,
      refetchOnFocus: true,
    },
  )
  const { data: ticketCounts } = useGetLabNewTicketCountQuery(null, {
    pollingInterval: 60000,
    refetchOnFocus: true,
  })

  const handleLogout = async () => {
    // await logout()
    dispatch(getLogout())
    navigate('/login')
  }
  const orderCounts = [
    ...(rawOrderCounts?.map(({ ordersType, ...i }) => ({ ...i, testType: ordersType })) || []),
    {
      bucket: 'SUPPORT_TICKETS',
      count: ticketCounts?.open ?? 0,
      testType: 'TICKETS',
    },
  ]

  const compoundCounts = orderCounts.reduce(
    (agg, curr) => {
      if (agg[curr.bucket] !== undefined) {
        agg[curr.bucket] = agg[curr.bucket] + curr.count
      }
      return agg
    },
    { AUTHORIZATION: 0, BILLING_REVIEW: 0, BILLING_HELD: 0, BILLING_QUEUED: 0 },
  )

  Object.entries(compoundCounts).forEach(([bucket, count]) =>
    orderCounts.push({
      bucket,
      count,
      testType: 'POX_COX_HST',
    }),
  )

  useEffect(() => {
    sessionStorage.setItem('ts-lab-menu', JSON.stringify(menuData))
  }, [menuData])

  return (
    <aside
      className="main-sidebar lab-sidebar sidebar-dark-primary elevation-4 vh-100 vw-50"
      style={{ width: 230 }}>
      <StyledLink to="/" className="brand-link text-center py-4">
        <span className="brand-text">
          <img src="/img/logo.png" width="160px" />
        </span>
      </StyledLink>
      <a
        href="#"
        role="button"
        onClick={handleLogout}
        css={{ position: 'absolute', left: 205, top: 32 }}>
        <i className="fas fa-sign-out-alt text-white" />
      </a>
      <div className="sidebar overflow-y-auto overflow-x-hidden vh-80 pb-10">
        <div className="mb-3 mx-2 mt-2 d-flex align-items-center">
          <InputComponent
            type="number"
            placeholder="Open Order ID"
            className="form-control ml-2"
            css={{ borderRadius: 0 }}
            value={orderId}
            handleChange={(e) => setOrderId(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                location.href = `/lab/orderEdit/${orderId}`
                setOrderId('')
              }
            }}
          />
          <div className="ml-2">
            <button
              className="btn btn-primary btn-block"
              onClick={() => {
                location.href = `/lab/orderEdit/${orderId}`
                setOrderId('')
              }}>
              Go
            </button>
          </div>
        </div>
        <nav>
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false">
            <SidebarSection data={menuData} setMenuData={setMenuData} badgeData={orderCounts} />
          </ul>
        </nav>
      </div>
    </aside>
  )
}

export default Menu

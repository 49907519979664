import Link from '../../atoms/Link'
import * as generalHelper from '../../../helpers/generalHelper'

interface IProps {
  label: string
  icon: string
  to: string
  toLabel: string
  toIcon: string
  toClassName: string
}

const CardHeaderComponent = (props: IProps) => {
  const label = props.label ? props.label : null
  const icon = props.icon ? props.icon : undefined
  const to = props.to ? props.to : null
  const toLabel = props.toLabel ? props.toLabel : ''
  const toIcon = props.toIcon ? props.toIcon : 'fa fa-arrow-right'
  const toClassName = props.toClassName
    ? props.toClassName
    : 'btn btn-default rounded-circle'

  return (
    <div className="card-header bg-light">
      <div className="row">
        <div className="col-sm-9">
          <i className={icon}></i>
          {generalHelper.t(label)}
        </div>
        <div className="col-sm-3 text-right">
          {to && (
            <Link
              to={to}
              label={toLabel}
              icon={toIcon}
              className={toClassName}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default CardHeaderComponent

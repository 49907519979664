import { ReactNode } from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

export const TooltipWrapper = ({
  orderState,
  children,
}: {
  orderState: any
  children: ReactNode
}): ReactNode =>
  !orderState?.isUnderVerification ? (
    <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip>Cannot verify an order that's not under review</Tooltip>}>
      {children}
    </OverlayTrigger>
  ) : (
    children
  )

import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

export const TooltipWrapper = ({
  text,
  children,
  show,
}: {
  text: string | (() => JSX.Element)
  children: JSX.Element
  show?: boolean
}): JSX.Element => {
  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      {typeof text === 'string' ? text : typeof text === 'function' ? text() : null}
    </Tooltip>
  )

  if (!text || !show) return children

  return (
    <OverlayTrigger placement="bottom" overlay={renderTooltip}>
      {children}
    </OverlayTrigger>
  )
}

import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import storage from 'redux-persist/lib/storage'
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import login from './slice/loginSlice'
import init from './slice/initSlice'
import loader from './slice/loaderSlice'
import modal from './slice/modalSlice'
import patient from './slice/patientSlice'
import { baseApi } from './services/baseApi'

import { combineReducers } from 'redux'

const rootReducer = combineReducers({
  loader,
  modal,
  login,
  init,
  patient,
  [baseApi.reducerPath]: baseApi.reducer,
})

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['modal'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }).concat(baseApi.middleware),
})

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store

/** @jsxImportSource @emotion/react */
import { useEffect, useState, useMemo } from 'react'
import moment from 'moment'
import { Link, useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import ReactSelect from 'react-select'
import { keyBy } from 'lodash'

import { useAppDispatch } from 'hooks/redux'
import Input from 'components/atoms/Input'
import Checkbox from 'components/atoms/Checkbox'
import TitleLead from 'components/atoms/TitleLead'
import { handleChange } from 'components/atoms/Upsert'
import DashboardContent from '../atoms/DashboardContent'
import { useGetLabUsersQuery } from 'store/services/users'
import routes from '../constants/routes'
import Button from 'components/atoms/Button'
import Select from 'components/atoms/Select'
import _ from 'lodash'
import { Order } from 'store/types'
import DataTable from 'components/atoms/DataTable'
import { TableWrapper } from 'components/atoms/TableWrapper'

const StyledTableWrapper = styled(TableWrapper)`
  th {
    padding: 0 0.2rem 0.4rem 0.1rem;
  }
  td {
    vertical-align: middle;
    padding: 0.2rem;
    div {
      span {
        font-size: 0.9rem;
      }
    }
  }
`

const CellWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 40px;
  justify-content: center;

  > div {
    white-space: nowrap;
    display: flex;
  }
`

interface SearchForm {
  search: string
}

const initialSearchVals = {
  search: '',
}

const PAGE_SIZE = 15

const UserDirectory = () => {
  const navigate = useNavigate()
  const [values, setValues] = useState<SearchForm>(initialSearchVals)
  const [currentPage, setCurrentPage] = useState(0)
  const [sortOptions, setSortOptions] = useState<{ id: string; desc: boolean }>()
  const { data: patients, isLoading } = useGetLabUsersQuery({
    ...(values.search && { search: values.search }),
    sortField: sortOptions?.id,
    sortOrder: sortOptions?.desc ? 'desc' : 'asc',
    page: currentPage,
    size: PAGE_SIZE,
  })

  useEffect(() => {
    setCurrentPage(0)
  }, [values])

  const headers = [
    {
      Header: 'User ID',
      accessor: 'userId',
      Cell: ({ row }) => row.original.id,
    },
    {
      Header: 'User Name',
      accessor: 'lastName',
      columnSize: 3,
      Cell: ({ row }) => (
        <>
          <Link to={`${routes.index}${routes.labUsers.root}${routes.upsert}${row.original.id}`}>
            {`${_.capitalize(row.original.lastName)}${
              row.original.suffix ? ' ' + row.original.suffix : ''
            }${!!row.original.lastName ? ',' : ''} ${_.capitalize(row.original.firstName)} ${
              row.original.middleName ? _.capitalize(row.original.middleName) : ''
            }`}
          </Link>
        </>
      ),
    },
    {
      Header: 'Email',
      accessor: 'email',
      columnSize: 3,
      Cell: ({ row }) => (
        <CellWrapper>
          <div>{row.original.email}</div>
        </CellWrapper>
      ),
    },
    {
      Header: 'Active',
      accessor: 'active',
      columnSize: 2,
      Cell: ({ row }) => (
        <CellWrapper>
          <div>
            {row.original.active ? (
              <i className="fa fa-circle text-success" />
            ) : (
              <i className="fa fa-circle text-danger" />
            )}
          </div>
        </CellWrapper>
      ),
    },
  ]

  return (
    <DashboardContent
      title="Users"
      subtitle={
        <>
          Lab User Directory
          <Button
            label="Add User"
            className="btn btn-primary ml-3 position-absolute"
            css={{ top: '15px', right: '15px' }}
            onClick={() => navigate(`${routes.index}${routes.labUsers.root}${routes.upsert}`)}
          />
        </>
      }
      content={
        <div className="card card-primary">
          <div className="card-body">
            <div className="row px-4 mb-3">
              <div
                className="form-group"
                css={{
                  margin: '0 !important',
                  '& input': { minWidth: '300px', margin: '0 !important' },
                }}>
                <label>Search Users</label>
                <Input
                  value={values.search}
                  label=""
                  placeholder="Search Users"
                  name="search"
                  autoFocus={true}
                  handleChange={(event) => {
                    handleChange(event, values, setValues)
                  }}
                />
              </div>
              <div css={{ display: 'flex', alignItems: 'flex-end' }}>
                <Button
                  label="Clear"
                  className="btn btn-default mx-2"
                  onClick={() => setValues(initialSearchVals)}
                />
                <Button
                  label="Search"
                  className="btn btn-primary mr-3"
                  onClick={() => {
                    setCurrentPage(0)
                    // onSearch()
                  }}
                />
              </div>
            </div>
            <div className="container-fluid">
              <StyledTableWrapper
                className="card-body row"
                style={{
                  overflowY:
                    !patients?.items?.length || patients?.items?.length < 10 ? 'unset' : 'auto',
                }}>
                <DataTable
                  data={patients}
                  status={isLoading}
                  tableColumns={headers}
                  pageSize={PAGE_SIZE}
                  setCurrentPage={setCurrentPage}
                  useCustomSortHandler={true}
                  setSortOptions={setSortOptions}
                  entityName="patients"
                  onRowClick={(row) =>
                    navigate(
                      `${routes.index}${routes.labUsers.root}${routes.upsert}${row.original.id}`,
                    )
                  }
                />
              </StyledTableWrapper>
            </div>
          </div>
        </div>
      }
      breadcrumb={[
        {
          label: 'User Directory',
          to: `${routes.index}${routes.patient.root}`,
        },
      ]}
    />
  )
}

export default UserDirectory

import { useMemo, useRef } from 'react'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'

import { useAppSelector, useAppDispatch } from 'hooks/redux'
import Modal from 'components/atoms/Modal'
import { TextInput } from 'components/atoms/Input'
import { SelectInput } from 'components/atoms/Select'
import { CheckboxInput } from 'components/atoms/Checkbox'
import ButtonCancelModal from 'components/atoms/ButtonCancelModal'
import ButtonSave from 'components/atoms/ButtonSave'
import {
  useGetDeviceBrandsQuery,
  useCreateDmeDeviceMutation,
  useUpdateDmeDeviceMutation,
} from 'store/services/devices'
import { setModal } from 'store/slice/modalSlice'

interface IProps {
  id: string
  title: string
}

const AddDeviceModal = (props: IProps) => {
  const modal = useAppSelector((state) => state.modal.row)
  const { currentOfficeId, userType } = useAppSelector((state) => state.init)
  const dispatch = useAppDispatch()
  const { data: brands } = useGetDeviceBrandsQuery({ userType })
  const [createDevice] = useCreateDmeDeviceMutation()
  const [updateDevice] = useUpdateDmeDeviceMutation()
  const officeId = useAppSelector((state) => state.init.currentOfficeId)
  const closeRef = useRef<HTMLButtonElement>(null)

  const typeOptions = useMemo(
    () =>
      brands?.items
        ?.filter(
          ({ name }) =>
            name.toLowerCase().includes('contec') ||
            name.toLowerCase().includes('nonin 2500') ||
            name.toLowerCase().includes('wristox') ||
            name.toLowerCase().includes('capnostream') ||
            name.toLowerCase().includes('nonin 7500'),
        )
        ?.map(({ model, id, name }) => ({
          value: name,
          key: id,
        })) || [],
    [brands],
  )

  const capnographyCapableDevices = useMemo(
    () =>
      brands?.items
        ?.filter(({ name }) => name.toLowerCase().includes('masimo'))
        .map(({ id }) => id) || [],
    [brands],
  )

  const formValidation = useMemo(
    () =>
      Yup.object().shape({
        serial: Yup.string().required('Required'),
        brandId: Yup.number().required('Required'),
      }),
    [],
  )

  const initialValues = {
    brandId: modal?.brandId ?? undefined,
    serial: modal?.serial ?? '',
    label: modal?.label ?? '',
  }

  return (
    <Modal
      {...props}
      content={
        <div className="container">
          <Formik
            enableReinitialize={true}
            validationSchema={formValidation}
            initialValues={initialValues}
            onSubmit={(values) => {
              if (modal?.id) {
                updateDevice({
                  id: Number(modal.id),
                  officeId,
                  ...values,
                  label: values.serial,
                })
              } else {
                createDevice({
                  ...values,
                  state: 'SOLD',
                  officeId: Number(currentOfficeId),
                  label: values.serial,
                })
              }
              closeRef.current?.click()
            }}>
            {({ values, errors, resetForm }) => (
              <Form className="modal-body">
                <Field component={SelectInput} name="brandId" options={typeOptions} />
                {capnographyCapableDevices.includes(Number(values.brandId)) && (
                  <div className="col-6 form-check ml-2 mb-2">
                    <Field
                      id="capnography"
                      name="capnography"
                      component={CheckboxInput}
                      label="Supports Capnography"
                    />
                  </div>
                )}

                {/* <div className="form-group">
                  <label htmlFor="label" className="textGray">
                    Label
                  </label>
                  <Field component={TextInput} name="label" />
                  {errors?.label === 'Label already exists' && (
                    <Field
                      component={CheckboxInput}
                      name="labelOverride"
                      label="Override"
                      className="mt-1"
                    />
                  )}
                </div> */}
                <div className="form-group">
                  <label htmlFor="serial" className="textGray">
                    Serial No
                  </label>
                  <Field component={TextInput} name="serial" />
                </div>
                <div className="modal-footer">
                  <ButtonCancelModal
                    ref={closeRef}
                    type="button"
                    data_dismiss="modal"
                    onClick={(e) => {
                      dispatch(setModal({}))
                      resetForm()
                    }}
                  />
                  <ButtonSave type="submit" label={`${modal?.id ? 'Update' : 'Add'} Device`} />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      }
    />
  )
}

export default AddDeviceModal

import { Fragment, ReactNode } from 'react'
import TitleLead from '../atoms/TitleLead'
import LinkBtn from '../atoms/LinkBtn'
import * as generalHelper from '../../helpers/generalHelper'

interface IProps {
  title: string
  back?: string
  next?: string
  actionButtons?: ReactNode
  className?: string
}

const TabHeader = (props: IProps) => {
  const back = props.back ?? ''
  const title = props.title ?? ''
  const next = props.next ?? ''
  const actionButtons = props.actionButtons ?? []
  const className = props.className ?? ''

  return (
    <>
      <div
        className="row align-items-center justify-content-between"
        style={{ minHeight: '40px', width: '100%' }}>
        <div className="col-sm-5">
          <TitleLead className={className} label={generalHelper.t(title)} />
        </div>
        {actionButtons ? (
          <div className="row text-right">
            {actionButtons && Array.isArray(actionButtons)
              ? actionButtons.map((button, index) => <Fragment key={index}>{button}</Fragment>)
              : ''}
          </div>
        ) : (
          ''
        )}

        {/* Left here incase tab back & next nav buttons are re-implemented then this code under here does that! */}
        {/* {back || actionButtons || next ? (
          <div className="col-sm-6 text-right">
            {back && (
              <LinkBtn to={back} className="mr-2" id="btn-ts" label={generalHelper.t('Back')} />
            )}
            {actionButtons && Array.isArray(actionButtons)
              ? actionButtons.map((button, index) => <Fragment key={index}>{button}</Fragment>)
              : ''}
            {next && (
              <LinkBtn to={next} className="ml-2" id="btn-ts" label={generalHelper.t('Next')} />
            )}
          </div>
        ) : (
          ''
        )} */}
      </div>
    </>
  )
}

export default TabHeader

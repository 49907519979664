/** @jsxImportSource @emotion/react */
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import moment from 'moment'
import { Button } from 'react-bootstrap'

import DataTable from 'components/atoms/DataTable'
import { confirm } from 'components/molecules/Confirmation'
import { TableWrapper } from 'components/atoms/TableWrapper'
import { useGetBulletinsQuery, useDeleteBulletinMutation } from 'store/services/bulletins'
import DashboardContent from '../atoms/DashboardContent'
import ButtonNew from '../../atoms/ButtonNew'
import Input from '../../atoms/Input'
import routes from '../constants/routes'

const StyledTableWrapper = styled(TableWrapper)`
  max-height: calc(100vh - 230px);
  th {
    padding: 0 0.2rem 0.4rem 0.1rem;
  }
  td {
    vertical-align: middle;
    padding: 0.6rem;
    div {
      span {
        font-size: 0.9rem;
      }
    }
  }
`

const PAGE_SIZE = 10

const Bulletins = () => {
  const navigate = useNavigate()
  const [search, setSearch] = useState('')
  // const [currentPage, setCurrentPage] = useState(0)
  const { data: bulletins, isLoading } = useGetBulletinsQuery()
  const [deleteBulletin] = useDeleteBulletinMutation()

  const bulletinData = {
    items:
      bulletins?.filter(
        (bulletin) => !search || bulletin?.title?.toLowerCase().includes(search.toLowerCase()),
      ) || [],
  }

  const header = [
    {
      accessor: 'id',
      Header: 'Id',
    },
    { accessor: 'title', Header: 'Title' },
    {
      accessor: 'showAfter',
      Header: 'Start Date',
      Cell: ({ row }) => (
        <div className="d-flex flex-column">
          <span>{moment(row.original.showAfter).format('MM/DD/YYYY')}</span>
        </div>
      ),
    },
    {
      accessor: 'showUntil',
      Header: 'End Date',
      Cell: ({ row }) => (
        <div className="d-flex flex-column">
          <span>{moment(row.original.showUntil).format('MM/DD/YYYY')}</span>
        </div>
      ),
    },
    {
      accessor: 'createdAt',
      Header: 'Created At',
      Cell: ({ row }) => (
        <div className="d-flex flex-column">
          <span>{moment(row.original.createdAt).format('MM/DD/YYYY h:MM a')}</span>
        </div>
      ),
    },
    {
      accessor: 'createdBy',
      Header: 'Created By',
      Cell: ({ row }) => (
        <div className="d-flex flex-column">
          <span>{row.original.createdBy.email}</span>
        </div>
      ),
    },
    {
      accessor: 'action',
      Header: 'Action',
      Cell: ({ row }) => (
        <div className="d-flex flex-column">
          <Button
            className="cursor-pointer btn-sm btn-danger"
            onClick={(e) => {
              e.stopPropagation()
              confirm('', {
                title: 'Delete Bulletin',
                description: 'Are you sure you want to delete this bulletin?',
              })
                .then(() => {
                  deleteBulletin(row.original.id)
                })
                .catch(() => {})
            }}>
            Delete
          </Button>
        </div>
      ),
    },
  ]

  const onOpenBulletin = (id: number) => {
    navigate(`${routes.index}${routes.bulletins.root}${routes.upsert}${id}`)
  }

  return (
    <DashboardContent
      title="Bulletins"
      icon="fas fa-file"
      content={
        <div className="card card-primary">
          <form>
            <div className="card-header row">
              <div className="col-10 input-group mb-3">
                <Input
                  autoFocus={true}
                  value={search}
                  label={false}
                  placeholder="Search..."
                  name="search"
                  parentDiv={null}
                  handleChange={(event) => setSearch(event.target.value)}
                />

                <div className="input-group-append">
                  <span className="input-group-text">
                    <i className="fas fa-search" />
                  </span>
                </div>
              </div>

              <div className="col-2 text-right">
                <ButtonNew to={`${routes.index}${routes.bulletins.root}${routes.upsert}`} />
              </div>
            </div>
          </form>
          <div className="card-body align-items-center">
            <StyledTableWrapper className="card-body row">
              <DataTable
                data={bulletinData}
                status={isLoading}
                tableColumns={header}
                pageSize={PAGE_SIZE}
                currentPage={0}
                setCurrentPage={() => {}}
                setSortOptions={() => {}}
                entityName="bulletins"
                onRowClick={(row) => onOpenBulletin(row.original.id)}
              />
            </StyledTableWrapper>
          </div>
        </div>
      }
      breadcrumb={[
        {
          label: 'Bulletins',
        },
      ]}
    />
  )
}

export default Bulletins

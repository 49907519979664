import { baseApi } from './baseApi'
import { IncomingFaxesEntry } from 'store/types'

export const faxApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getIncomingFaxes: builder.query<IncomingFaxesEntry[], void>({
      query: () => ({ url: `/lab/incoming-faxes` }),
      providesTags: ['IncomingFax'],
    }),
    deleteIncomingFax: builder.mutation<void, { uuid: string }>({
      query: ({ uuid }) => ({
        method: `DELETE`,
        url: `/lab/incoming-faxes/${uuid}`,
      }),
      invalidatesTags: (result, error, { id }) => ['IncomingFax', 'OrderCounts'],
    }),
  }),
})

export const { useGetIncomingFaxesQuery, useDeleteIncomingFaxMutation } = faxApi

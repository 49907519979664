/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled'
import Button from 'components/atoms/Button'
import ButtonSave from 'components/atoms/ButtonSave'
import Dropdown from 'components/atoms/Dropdown'
import Input from 'components/atoms/Input'
import Pagination from 'components/atoms/Pagination'

import * as generalHelper from 'helpers/generalHelper'
import { useEffect, useState } from 'react'
import { useSortBy, useTable } from 'react-table'
import { KeyVal } from 'store/types'

const StyledRow = styled.tr`
  :hover {
    background: rgba(99, 99, 99, 0.15);
  }
`

interface IProps {
  data: any[]
  status: boolean
  tableColumns: any[]
  customTableLayout?: any
  searchFilter: string
  setSearchFilter: (v: string) => void
  setCurrentPage: (v: number) => void
  setPageSize: (v: number) => void
  setSortOptions: (v: KeyVal) => void
  pageSize: number
  onExport?: () => void
  onRowClick?: (row: any) => void
  select?: boolean
  useCustomSortHandler?: boolean
  entityName?: string
}

/**
 * Renders ReactTable's child elements and sets proper event handling
 *
 * @param Object props
 * @returns JSX.Element
 */
const DataTable = ({
  data,
  status,
  tableColumns,
  customTableLayout,
  setSearchFilter,
  searchFilter,
  setCurrentPage,
  setPageSize,
  pageSize,
  setSortOptions,
  useCustomSortHandler,
  onExport,
  onRowClick = () => {},
  highlightRowId,
  select,
  entityName = 'orders',
}: IProps) => {
  const { items, currentPage, totalItems } = data || {}
  const [tableState, setTableState] = useState({})

  const ReactTable = useTable(
    {
      columns: tableColumns,
      data: items || [],
      manualSortBy: true,
    },
    useSortBy,
  )

  // Set listener for sortBy Events from Our Table
  useEffect(() => {
    if (tableState.sortBy) {
      setSortOptions(tableState.sortBy)
    } else if (tableState.sortBy === undefined) {
      setSortOptions()
    }
  }, [tableState])

  const setMergedTableState = (val) => setTableState((prevState) => ({ ...prevState, ...val }))

  if (customTableLayout) {
    return customTableLayout
  } else {
    return (
      <TableLayout
        ReactTable={ReactTable}
        tableState={setMergedTableState}
        filterState={setSearchFilter}
        filterValue={searchFilter}
        tableLayout={customTableLayout}
        tableDataState={status}
        tableTotalRows={totalItems || 1}
        tableColumns={tableColumns}
        currentPage={Number(currentPage)}
        pageSize={pageSize}
        setCurrentPage={setCurrentPage}
        setPageSize={setPageSize}
        onRowClick={onRowClick}
        select={select}
        entityName={entityName}
        highlightRowId={highlightRowId}
        onExport={onExport}
        customSortHandler={useCustomSortHandler ? setSortOptions : undefined}
      />
    )
  }
}

const TableLayout = (props) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state } =
    props.ReactTable
  const {
    pageSize,
    filterState,
    currentPage,
    setCurrentPage,
    setPageSize,
    onRowClick,
    highlightRowId,
    customSortHandler,
    onExport,
  } = props
  const [search, setSearch] = useState('')

  useEffect(() => {
    if (customSortHandler) {
      customSortHandler(state.sortBy[0])
    } else {
      props.tableState({ sortBy: state.sortBy[0] })
    }
  }, [state])

  useEffect(() => {
    props.tableState({
      skip: currentPage == 1 ? 0 : (currentPage - 1) * pageSize,
      limit: pageSize,
    })
  }, [currentPage])

  if (props.tableLayout) {
    return props.tableLayout
  } else {
    return (
      <>
        <table {...getTableProps({ className: 'table' })} id="data-table">
          {!!filterState && (
            <thead className="table_filters">
              <tr>
                <td colSpan="100%" style={{ border: 'none' }}>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="row p1-2">
                        <div className="col-sm-3">
                          <Input
                            value={search || ''}
                            label="Search"
                            placeholder="First Name, Last Name, Order ID, DOB"
                            name="search"
                            parentDiv={null}
                            handleChange={(e) => setSearch(e.target.value)}
                            onKeyPress={(e) => {
                              if (e.key === 'Enter') {
                                filterState(search)
                              }
                            }}
                          />
                        </div>
                        <div className="col-sm-4 col-md-4">
                          <ButtonSave
                            className="btn btn-primary mr-3"
                            type="button"
                            label="Search"
                            onClick={(event) => {
                              filterState(search)
                            }}
                          />
                          <Button
                            label="Clear"
                            className="btn btn btn-default"
                            onClick={() => {
                              filterState('')
                              setSearch('')
                            }}
                          />
                        </div>
                        <div className="d-flex justify-content-end" style={{ flex: 1 }}>
                          {onExport && (
                            <ButtonSave
                              className="btn btn-primary mr-3"
                              type="button"
                              label="Export CSV"
                              onClick={onExport}
                            />
                          )}
                          <Dropdown
                            onClick={(v) => {
                              setCurrentPage(0)
                              setPageSize(Number(v))
                            }}
                            buttonTypeClass="btn-outline-primary"
                            options={[
                              { label: '12' },
                              {
                                label: '20',
                              },
                              {
                                label: '50',
                              },
                              {
                                label: '100',
                              },
                              {
                                label: '200',
                              },
                            ]}
                            additionalText="Records"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </thead>
          )}
          <thead>
            {
              // Loop over the header rows
              headerGroups.map((headerGroup) => (
                // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column) => (
                      // Apply the header cell props
                      <th
                        {...column.getHeaderProps({
                          ...column.getSortByToggleProps(),
                          className: `table-col ${column.className}`,
                        })}>
                        {
                          // Render the header
                          column.render('Header')
                        }
                        <span>{column.isSorted ? (column.isSortedDesc ? ' ⇣' : ' ⇡') : ''}</span>
                      </th>
                    ))
                  }
                </tr>
              ))
            }
          </thead>
          {props.tableDataState && (
            <tbody {...getTableBodyProps()}>
              <tr>
                <td colSpan="100%">
                  <div className="row border rounded m-2 py-3 pl-2 justify-content-center font-italic textGray font-weight-bold">
                    {generalHelper.t('Loading Data...')}
                  </div>
                </td>
              </tr>
            </tbody>
          )}
          {!props.tableDataState && rows.length > 0 ? (
            // Loop over the table rows
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                // Prepare the row for display
                prepareRow(row)
                return (
                  // Apply the row props
                  <StyledRow
                    {...row.getRowProps()}
                    onClick={() => !props.select && onRowClick(row)}
                    css={{
                      // cursor: props.select ? 'default' : 'pointer',
                      backgroundColor:
                        highlightRowId && highlightRowId == row.original.id ? '#f0f0f0' : 'white',
                    }}>
                    {
                      // Loop over the rows cells
                      row.cells.map((cell) => {
                        // Apply the cell props
                        return (
                          <td {...cell.getCellProps()}>
                            <div className="container pl-0">
                              {
                                // Render the cell contents
                                cell.render('Cell')
                              }
                            </div>
                          </td>
                        )
                      })
                    }
                  </StyledRow>
                )
              })}
            </tbody>
          ) : (
            <tbody {...getTableBodyProps()}>
              <tr>
                <td colSpan="100%">
                  {props.tableDataState === false && rows.length == 0 && (
                    <>
                      <div className="row border rounded m-2 py-3 pl-2 justify-content-center font-italic textGray font-weight-bold">
                        {generalHelper.t(`No ${props.entityName} found.`)}
                      </div>
                    </>
                  )}
                  {props.tableDataState === 'error' && rows.length == 0 && (
                    <>
                      <div className="row border rounded m-2 py-3 pl-2 justify-content-center font-italic textGray font-weight-bold">
                        {generalHelper.t(`Error getting ${props.entityName}.`)}
                      </div>
                    </>
                  )}
                </td>
              </tr>
            </tbody>
          )}

          {!props.tableDataState && (
            <tfoot>
              <tr>
                <th colSpan="100%">
                  <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={props.tableTotalRows}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    onPageChange={setCurrentPage}
                  />
                </th>
              </tr>
            </tfoot>
          )}
        </table>
      </>
    )
  }
}

export default DataTable

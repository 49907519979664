/** @jsxImportSource @emotion/react */
import { Field, Form, Formik } from 'formik'
import Toast from 'components/atoms/Toast'
import Button from 'react-bootstrap/Button'
import * as Yup from 'yup'

import { phoneNumberMask, zipMask } from 'helpers/masks'
import states from 'constants/states'
import MaskInput from 'components/atoms/MaskInput'
import GoogleAutocomplete from 'components/atoms/GoogleAutocomplete'
import { getAddressFields2 } from 'components/dme/forms/utils/userFormUtils'
import * as generalHelper from 'helpers/generalHelper'
import { requiredPhoneValidation } from 'helpers/validate'

const companyValidation = Yup.object().shape({
  companyName: Yup.string().required('Required'),
  telephone: requiredPhoneValidation,
  fax: requiredPhoneValidation,
  npi: Yup.string()
    .optional()
    .test('len', 'Must be exactly 10 characters', (val?: string) => !val || val?.length === 10),
  addressStreetLine1: Yup.string().required('Required'),
  addressCity: Yup.string().required('Required'),
  addressState: Yup.string().required('Required'),
  addressZip: Yup.string().required('Required'),
})

export interface CompanyFormValues {
  companyName: string
  telephone: string
  fax: string
  npi: string
  addressStreetLine1: string
  addressStreetLine2: string
  addressCity: string
  addressState: string
  addressZip: string
  btSiteNickname?: string[]
  nickname?: string
}

interface IProps {
  onCreateCompany: (values: CompanyFormValues) => void
  onUpdateCompany: (values: CompanyFormValues) => void
  initialValues: CompanyFormValues & { id?: number }
}

const defaultValues: CompanyFormValues = {
  companyName: '',
  telephone: '',
  fax: '',
  npi: '',
  addressStreetLine1: '',
  addressStreetLine2: '',
  addressCity: '',
  addressState: '',
  addressZip: '',
  btSiteNickname: [],
  nickname: '',
}

export const CompanyForm = ({
  onCreateCompany,
  onUpdateCompany,
  initialValues = defaultValues,
}: IProps) => {
  return (
    <Formik
      enableReinitialize={true}
      validationSchema={companyValidation}
      initialValues={{
        ...initialValues,
        address: initialValues.addressStreetLine1,
      }}
      onSubmit={async (values) => {
        const { nickname, regions, address, ...vals } = values
        try {
          if (initialValues.id) {
            await onUpdateCompany(vals)
          } else {
            await onCreateCompany(vals)
          }
          Toast({ type: 'success', label: `Company updated!` })
        } catch (err) {
          Toast({ type: 'error', label: err.data.message })
        }
      }}>
      {({ values, errors, touched, handleChange, setFieldValue }) => (
        <Form id="companyUpsert">
          <div className={`container bg-white mb-3 px-3 p-0`}>
            <div className={`container pt-3 pb-2`}>
              <div className="row">
                <div className="col-4 form-group textGray">
                  <label htmlFor="companyName">
                    Company Name
                    <span className="text-danger">*</span>
                  </label>
                  <Field
                    type="text"
                    name="companyName"
                    className="form-control"
                    placeholder="Company Name"
                    value={values.companyName}
                    onChange={handleChange}
                  />
                  {touched.companyName && errors.companyName && (
                    <div className="text-danger">{errors.companyName}</div>
                  )}
                </div>
                <div className="col-4 form-group textGray">
                  <label htmlFor="telephone">
                    Telephone <span className="text-danger">*</span>
                  </label>
                  <MaskInput
                    value={values.telephone}
                    onChange={handleChange}
                    name="telephone"
                    mask={phoneNumberMask}
                    placeholder="(###) ###-####"
                    autoComplete="off"
                  />
                </div>
                <div className="col-4 form-group textGray">
                  <label htmlFor="lastName">
                    Fax
                    <span className="text-danger">*</span>
                  </label>
                  <MaskInput
                    value={values.fax}
                    onChange={handleChange}
                    name="fax"
                    mask={phoneNumberMask}
                    placeholder="(###) ###-####"
                    autoComplete="off"
                  />
                  {touched.fax && errors.fax && <div className="text-danger">{errors.fax}</div>}
                </div>
              </div>
              <div className="row">
                <div className="col-4 form-group textGray">
                  <label htmlFor="npi">NPI</label>
                  <Field
                    type="text"
                    name="npi"
                    maxlength="10"
                    className="form-control"
                    placeholder="NPI"
                    value={values.npi}
                    onChange={handleChange}
                  />
                  {touched.npi && errors.npi && <div className="text-danger">{errors.npi}</div>}
                </div>
                <div className="col-8">
                  <div
                    className="row"
                    css={{ border: '1px solid #ccc', borderRadius: '5px', padding: '5px 10px' }}>
                    <div className="col-6 form-group textGray">
                      <label htmlFor="npi">Brightree Site Nicknames</label>
                      <div className="d-flex">
                        <Field
                          type="text"
                          name="nickname"
                          className="form-control"
                          placeholder="Site Nickname"
                          value={values.nickname}
                          onChange={handleChange}
                        />
                        <i
                          onClick={() => {
                            if (values.nickname) {
                              setFieldValue('btSiteNickname', [
                                values.nickname,
                                ...(values.btSiteNickname || []),
                              ])
                              setFieldValue('nickname', '')
                            }
                          }}
                          className="fa fa-plus-circle ml-2 mt-2"
                          aria-hidden="true"
                          css={{ cursor: 'pointer', color: values.nickname ? '#2c83bd' : '#ccc' }}
                        />
                      </div>
                    </div>
                    <div className="col-6" css={{ overflowY: 'auto', maxHeight: 150 }}>
                      {!!values.btSiteNickname?.length &&
                        values.btSiteNickname.map((n) => (
                          <div className="d-flex justify-content-between mt-2">
                            <span>{n}</span>
                            <i
                              onClick={() =>
                                setFieldValue(
                                  'btSiteNickname',
                                  values.btSiteNickname?.filter((b) => b !== n),
                                )
                              }
                              className="fa fa-times-circle ml-2"
                              aria-hidden="true"
                              css={{ cursor: 'pointer', color: '#2c83bd' }}
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={`container py-2 px-3`}>
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div className="col-12 form-group textGray">
                      <label htmlFor="addressStreetLine1">
                        {generalHelper.t('Address')}
                        <span className="text-danger">*</span>
                      </label>
                      <GoogleAutocomplete
                        id="address"
                        name="address"
                        onInputChanged={(v) => setFieldValue('addressStreetLine1', v)}
                        onChange={(place) => {
                          const { address, city, state, zip } = getAddressFields2(place)
                          console.log(address, city, state, zip)
                          document.getElementById('address').value = address
                          setFieldValue('addressStreetLine1', address)
                          setFieldValue('addressCity', city)
                          setFieldValue('addressState', state)
                          setFieldValue('addressZip', zip)
                        }}
                      />
                      {touched.addressStreetLine1 && errors.addressStreetLine1 ? (
                        <div className="text-danger">{errors.addressStreetLine1}</div>
                      ) : null}
                    </div>
                    <div className="col-12 form-group textGray">
                      <Field
                        type="text"
                        name="addressStreetLine2"
                        className="form-control"
                        placeholder="Suite / Apt"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4 form-group textGray">
                      <label htmlFor="addressCity">
                        City
                        <span className="text-danger">*</span>
                      </label>
                      <Field
                        type="text"
                        name="addressCity"
                        className="form-control"
                        placeholder="City"
                      />
                      {touched.addressCity && errors.addressCity ? (
                        <div className="text-danger">{errors.addressCity}</div>
                      ) : null}
                    </div>
                    <div className="col-4 form-group textGray">
                      <label htmlFor="addressState">
                        State
                        <span className="text-danger">*</span>
                      </label>
                      <Field
                        className="form-control"
                        as="select"
                        name="addressState"
                        placeholder="State">
                        {states &&
                          states.map((s, i) => (
                            <option key={i} value={s.id}>
                              {s.name}
                            </option>
                          ))}
                      </Field>
                      {touched.addressState && errors.addressState ? (
                        <div className="text-danger">{errors.addressState}</div>
                      ) : null}
                    </div>
                    <div className="col-4 form-group textGray">
                      <label htmlFor="addressZip">
                        Zip Code
                        <span className="text-danger">*</span>
                      </label>
                      <MaskInput
                        value={values.addressZip}
                        onChange={handleChange}
                        name="addressZip"
                        mask={zipMask}
                        guide={false}
                      />

                      {touched.addressZip && errors.addressZip && (
                        <div className="text-danger">{errors.addressZip}</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 pt-3 pr-0 border-top">
              <div className="col-sm-12 d-flex justify-content-end">
                <Button variant="primary" type="submit">
                  Save Changes
                </Button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashboardContent from "../atoms/DashboardContent";
import * as generalHelper from "../../../helpers/generalHelper";
import { setLoader } from "../../../store/slice/loaderSlice";
import Loader from "../../atoms/Loader";
import Input from "../../atoms/Input";
import ButtonCancel from "../../atoms/ButtonCancel";
import Upload from "../../atoms/Upload";
import { handleChange } from "../../atoms/Upsert";
import routes from "../constants/routes";

export default function (props) {
    const dispatch = useDispatch();
    const [values, setValues] = useState({});

    const handleUpload = async (acceptedFiles, extraProps) => {
        dispatch(setLoader(true));

        // ----------------
        const formData = new FormData();
        if (extraProps && extraProps.to) {
            formData.append("to", extraProps.to);
        }

        formData.append("_callback", "uploadFax");
        acceptedFiles.forEach((file) => {
            formData.append("file[]", file);
        });

        // ----------------
        await generalHelper.apiPost("/api/upload", formData);
        dispatch(setLoader(false));
    };

    function Content(props) {
        return (
            <div className="card card-primary">
                <form type="post">
                    <div className="card-body row">
                        <div className="col-6">
                            <Input
                                label={generalHelper.t("To")}
                                name="to"
                                value={values.to}
                                handleChange={(event) => {
                                    handleChange(event, values, setValues);
                                }}
                            />
                        </div>
                        <div className="col-12">
                            <Upload
                                label="Upload &amp; Fax"
                                className="btn btn-primary"
                                icon="fas fa-file-upload"
                                handleUpload={handleUpload}
                                to={values.to}
                            />
                        </div>
                    </div>
                    <div className="card-footer text-right">
                        <ButtonCancel />
                    </div>
                </form>
            </div>
        );
    }

    return (
        <DashboardContent
            title="Send a Fax"
            icon="fa fa-paper-plane"
            content={Content(props)}
            breadcrumb={[
                {
                    label: "Faxes",
                    to: `${routes.index}${routes.fax.root}`,
                },
                {
                    label: "Send a Fax",
                },
            ]}
        />
    );
}

/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled'
import { Grid } from '@mui/material'
import { CancelDialog } from 'components/molecules/CancelDialog'
import { confirm } from 'components/molecules/Confirmation'
import ModifyPhysicianModal from 'components/organisms/physician/ModifyPhysicianModal'
import orderStateHelper from 'helpers/orderStateHelpers'
import { useAppDispatch } from 'hooks/redux'
import { ReactNode, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import { useCancelDmeOrderMutation, useRestoreDmeOrderMutation } from 'store/services/dmeOrder'
import { useSendDmeMessageMutation } from 'store/services/messages'
import { Order } from 'store/types'
import * as generalHelper from '../../../helpers/generalHelper'
import { setModal } from '../../../store/slice/modalSlice'
import Button from '../../atoms/Button'
import ButtonModal from '../../atoms/ButtonModal'
import Link from '../../atoms/Link'
import AddDeviceModal from '../device/views/AddDeviceModal'
import EditOrderModal from '../order/EditOrderModal'
import PhysicianNotesModal from '../order/PhysicianNotesModal'
import AddPatientModal from '../patient/views/AddPatientModal'

const ContentContainer = styled.div`
  .fluid {
    max-width: unset;
    width: unset;
    @media (min-width: 1400px) {
      width: 100%;
    }
  }
`
interface IProps {
  title: string
  subtitle?: string
  subtitleExtra?: string
  content: ReactNode
  breadcrumb: { label: string; to?: string }[]
  extra?: ReactNode
  getOrderParams?: any
  order?: Order
  leftHeaderAction?: ReactNode
  titleActionButton?: ReactNode
  customHeaderColumn?: string
  deviceDropdown?: string
  employeeDropdown?: string
  employee?: string
  device?: string
  className?: string
  fluidWidth?: boolean
  orderInfo?: ReactNode[]
}

const DashboardContent = (props: IProps) => {
  const {
    title,
    subtitle,
    subtitleExtra,
    leftHeaderAction,
    content,
    breadcrumb,
    extra,
    order,
    titleActionButton,
    customHeaderColumn,
    deviceDropdown,
    employeeDropdown,
    employee,
    device,
    fluidWidth,
    orderInfo,
  } = props

  const { orderId, tab } = useParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [cancelDmeOrder] = useCancelDmeOrderMutation()
  const [restoreDmeOrder] = useRestoreDmeOrderMutation()
  const [showCancelDialog, setShowCancelDialog] = useState(false)
  const [sendDmeMessage] = useSendDmeMessageMutation()

  const orderState = orderStateHelper(order)

  const canCancelOrder =
    (['POX', 'COX'].includes(order?.testType) ||
      ((order?.testType === 'HST' || order?.testType === 'PSG') &&
        !orderState.isUnderVerification)) &&
    !order?.reportRenderRequests?.length

  useEffect(() => {
    dispatch(setModal({}))
  }, [])

  return (
    <div className="dashboard">
      <CancelDialog
        context="DME"
        cannotCancelReason={
          order?.testType === 'POX' &&
          (order?.thirdPartyDevice?.shippedAt || order?.thirdPartyDevice?.returnedAt)
            ? 'TestSmarter has already shipped a device to the patient.'
            : undefined
        }
        show={showCancelDialog}
        onClose={() => setShowCancelDialog(false)}
        onSubmit={async (message: string) => {
          if (showCancelDialog) {
            await cancelDmeOrder({ orderId: Number(orderId) }).unwrap()
            await sendDmeMessage({ orderId: Number(orderId), text: message, context: '' })
            navigate(-1)
            setShowCancelDialog(false)
          }
        }}
      />
      <AddDeviceModal id="new_device_modal" title={generalHelper.t('MANAGE DEVICE')} />
      <AddPatientModal id="new_patient_modal" title={generalHelper.t('CREATE NEW PATIENT')} />
      {/* <AssignDeviceModal id="assign_device_modal" title={generalHelper.t('ASSIGN DEVICE')} /> */}
      {/* <UnassignDeviceModal id="unassign_device_modal" title={generalHelper.t('UNASSIGN DEVICE')} /> */}
      {/* <RetireDeviceModal id="retire_device_modal" /> */}
      {/* <InsuranceModal id="edit_insurance_modal" modalSize="modal-lg" /> */}
      <EditOrderModal id="edit_order_modal" modalSize="modal-lg" />
      <ModifyPhysicianModal id="modify_physician_modal" modalSize="modal-md" />
      <PhysicianNotesModal id="physician_notes" title="PHYSICIAN INFORMATION CHANGED" />
      <div className="dashboard_header">
        <div className={`dashboard_header_title ${order?.cancelled && 'bg-danger'}`}>
          <h2 className="my-1">{generalHelper.t(title).toUpperCase()}</h2>
          <div className="dashboard_header_breadcrumb">
            {Array.isArray(breadcrumb)
              ? breadcrumb.map((x, i) => (
                  <Link
                    key={i}
                    label={i === 0 ? `${x.label}` : ` / ${x.label}`}
                    to={x.to ? x.to : ''}
                    className={`text-white ${breadcrumb[i].to ? 'text-bold' : 'disabled'}`}
                  />
                ))
              : ''}
          </div>
        </div>
        {subtitle ? (
          <Grid container columnGap="10px" flexWrap="nowrap" sx={{ mt: 1 }}>
            {leftHeaderAction ?? null}
            {customHeaderColumn && (
              <Grid
                container
                direction="column"
                sx={{ background: 'white', padding: '5px 10px', position: 'relative' }}
                rowGap="5px">
                <div className="col-sm-2 col-md-2 d-flex justify-content-start p-0">
                  {customHeaderColumn}
                </div>
              </Grid>
            )}
            <Grid
              container
              direction="column"
              sx={{
                background: 'white',
                padding: '5px 10px',
                position: 'relative',
                width: orderInfo ? 'unset' : '100%',
                ml: 1,
              }}
              rowGap="5px">
              <div className="dashboard_header_subtitle pl-3" style={{ minHeight: '65px' }}>
                <div className="holder">
                  <h3
                    className={`mb-0 ${subtitleExtra ? 'subTitle' : ''}`}
                    style={{ fontSize: '1.5rem', paddingBottom: 5 }}>
                    {subtitle}
                  </h3>
                  {subtitleExtra ? <p className="subtitle-extra m-0">{subtitleExtra}</p> : null}
                </div>
              </div>
            </Grid>
            {orderInfo && orderInfo.map((i) => <div className="col pt-2 bg-white">{i}</div>)}
            {titleActionButton && (
              <div className="col d-flex justify-content-end my-auto pr-3">{titleActionButton}</div>
            )}

            {order ? (
              <>
                {canCancelOrder && (
                  <div className="d-flex justify-content-end my-auto pr-2" css={{ minWidth: 50 }}>
                    <div className="dropdown">
                      <button
                        className="btn btn-outline-secondary"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false">
                        <i className="fas fa-ellipsis-v"></i>
                      </button>
                      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <Button
                          label={order?.cancelled ? 'Re-open Order' : 'Cancel Order'}
                          className="dropdown-item"
                          href="#"
                          onClick={() => {
                            if (order?.cancelled) {
                              return confirm('', {
                                title: 'Re-open Order',
                                description: 'Are you sure you want to re-open this order?',
                                yesAction: 'Yes',
                              })
                                .then(() => restoreDmeOrder({ orderId: Number(orderId) }).unwrap())
                                .catch()
                            }
                            setShowCancelDialog(true)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              ''
            )}
            {deviceDropdown && (
              <>
                <div className="col d-flex justify-content-end my-auto pr-2">
                  <div className="dropdown">
                    <button
                      className="btn btn-outline-secondary"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false">
                      <i className="fas fa-ellipsis-v"></i>
                    </button>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      {device?.status_id !== status_damaged?.id &&
                        device?.status_id !== status_retired?.id && (
                          <>
                            <ButtonModal
                              label="Device Damaged"
                              className="dropdown-item"
                              data_toggle="modal"
                              href="#"
                              data_target="#retire_device_modal"
                              onClick={() => {
                                dispatch(
                                  setModal({
                                    open: true,
                                    device: device,
                                    title: 'DEVICE DAMAGED',
                                  }),
                                )
                              }}
                            />
                            <ButtonModal
                              label="Retire Device"
                              className="dropdown-item"
                              data_toggle="modal"
                              href="#"
                              data_target="#retire_device_modal"
                              onClick={() => {
                                dispatch(
                                  setModal({
                                    open: true,
                                    device: device,
                                    title: 'RETIRE DEVICE',
                                    isRetireDevice: true,
                                  }),
                                )
                              }}
                            />
                          </>
                        )}
                      {(device?.status_id === status_damaged?.id ||
                        device?.status_id === status_retired?.id) && (
                        <ButtonModal
                          label="Reactive Device"
                          className="dropdown-item"
                          data_toggle="modal"
                          href="#"
                          data_target="#retire_device_modal"
                          onClick={() => {
                            dispatch(
                              setModal({
                                open: true,
                                device: device,
                                title: 'REACTIVE DEVICE',
                                isActiveDevice: true,
                              }),
                            )
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
            {employeeDropdown && (
              <>
                <div className="col d-flex justify-content-end my-auto pr-2">
                  <div className="dropdown">
                    <button
                      className="btn btn-outline-secondary"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false">
                      <i className="fas fa-ellipsis-v"></i>
                    </button>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <>
                        <ButtonModal
                          label="Deactivate Account"
                          className="dropdown-item"
                          data_toggle="modal"
                          href="#"
                          data_target="#deactivate_employee_modal"
                          onClick={() => {
                            dispatch(
                              setModal({
                                open: true,
                                employee,
                                title: 'DEACTIVATE ACCOUNT',
                              }),
                            )
                          }}
                        />
                      </>
                    </div>
                  </div>
                </div>
              </>
            )}
          </Grid>
        ) : (
          ''
        )}
      </div>
      <div className="content">
        <div className="container-fluid">
          <ContentContainer className="row">
            <div className={`${extra ? 'col-lg-9' : 'col-lg-12'} ${fluidWidth && 'fluid'} px-0`}>
              {content}
            </div>
            {extra ? (
              <div className="col-lg-3 pl-0">
                <div>
                  <div>{extra}</div>
                </div>
              </div>
            ) : null}
          </ContentContainer>
        </div>
      </div>
    </div>
  )
}

export default DashboardContent

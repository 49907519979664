import moment from 'moment'

export const formatUserPayload = (values) => {
  const newValues = { ...values }
  newValues.dob = moment(values.dob, 'YYYY-MM-DD', true)
    ? moment(values.dob).format('YYYY-MM-DD')
    : ''
  newValues.homePhone = values.homePhone?.replace(/\D/g, '')
  newValues.mobilePhone = values.mobilePhone?.replace(/\D/g, '') || ''
  newValues.ssn = values.ssn?.replace(/-/g, '') || ''
  newValues.primaryAddressZip = values.primaryAddressZip?.replace(/\D/g, '') || ''

  delete newValues.address
  delete newValues.mail_address

  return newValues
}

type AddressFields = {
  address?: string
  city?: string
  state?: string
  zip?: string
}

export const getAddressFields = (
  place: google.maps.places.PlaceResult,
  id = 'google-autocomplete',
): AddressFields => {
  console.log(place)
  const fields = place?.formatted_address?.split(',')
  if (!fields?.length) return {}

  const address = {
    address: fields[0]?.trim(),
    city: fields[1]?.trim(),
    state: fields[2]?.split(' ')[1]?.trim(),
    zip: fields[2]?.split(' ')[2]?.trim(),
  }
  document.getElementById(id).value = address.address
  return address
}

export const getAddressFields2 = (place: google.maps.places.PlaceResult): AddressFields => {
  if (!place.address_components?.length) return {}

  const streetNum =
    place.address_components.find((a) => a.types.includes('street_number'))?.long_name ?? ''
  const street = place.address_components.find((a) => a.types.includes('route'))?.long_name ?? ''
  const city = place.address_components.find((a) => a.types.includes('locality'))?.long_name ?? ''
  const state =
    place.address_components.find((a) => a.types.includes('administrative_area_level_1'))
      ?.short_name ?? ''
  const zip = place.address_components.find((a) => a.types.includes('postal_code'))?.long_name ?? ''

  const address = {
    address: `${streetNum ?? ''} ${street ?? ''}`.trim(),
    city,
    state,
    zip,
  }
  return address
}

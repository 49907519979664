import { Field, FormikTouched, FormikErrors } from 'formik'
import MaskInput from 'components/atoms/MaskInput'
import GoogleAutocomplete from 'components/atoms/GoogleAutocomplete'
import { getAddressFields } from '../forms/utils/userFormUtils'
import InputMask from '../../atoms/InputMask'
import suffixes from '../constants/suffixes'
import states from 'constants/states'
import { CheckboxInput } from 'components/atoms/Checkbox'
import { phoneNumberMask, zipMask } from 'helpers/masks'
import { ChangeEvent, SyntheticEvent } from 'react'

export interface FormValues {
  firstName: string
  lastName: string
  middleName: string
  suffix: string
  dob: string
  gender: string
  language: string
  ssn: string
  homePhone: string
  mobilePhone: string
  email: string
  primaryAddressStreetLine1: string
  primaryAddressStreetLine2: string
  primaryAddressCity: string
  primaryAddressState: string
  primaryAddressZip: string
  hasMail: boolean
  deliveryAddressStreetLine1: string
  deliveryAddressStreetLine2: string
  deliveryAddressCity: string
  deliveryAddressState: string
  deliveryAddressZip: string
}

interface IProps {
  values: FormValues
  handleChange: (e: SyntheticEvent<Element, Event>) => void
  touched: FormikTouched<FormValues>
  errors: FormikErrors<FormValues>
  setFieldValue: (k: string, v: string | boolean) => void
  disabledValues: FormikTouched<FormValues>
}

const PatientForm = ({ values, disabledValues = {}, handleChange, setFieldValue }: IProps) => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-3 form-group textGray">
          <label htmlFor="firstName">
            First Name
            <span className="text-danger">*</span>
          </label>
          <Field
            disabled={disabledValues.firstName}
            type="text"
            name="firstName"
            className="form-control"
            placeholder="First Name"
            autoComplete="one-time-code"
          />
        </div>
        <div className="col-3 form-group textGray">
          <label htmlFor="middleName">Middle Name</label>
          <Field
            disabled={disabledValues.middleName}
            type="text"
            name="middleName"
            className="form-control"
            placeholder="Middle Name"
            autoComplete="one-time-code"
          />
        </div>
        <div className="col-3 form-group textGray">
          <label htmlFor="lastName">
            Last Name
            <span className="text-danger">*</span>
          </label>
          <Field
            disabled={disabledValues.lastName}
            type="text"
            name="lastName"
            className="form-control"
            placeholder="Last Name"
            autoComplete="one-time-code"
          />
        </div>
        <div className="col-3 form-group textGray">
          <label htmlFor="suffix">Suffix</label>
          <Field
            disabled={disabledValues.suffix}
            className="form-control"
            as="select"
            name="suffix">
            {suffixes &&
              suffixes.map((s, i) => (
                <option key={i} value={s.name}>
                  {s.name}
                </option>
              ))}
          </Field>
        </div>
      </div>
      <div className="row">
        <div className="col-3 form-group textGray">
          <label htmlFor="dob">
            Date Of Birth
            <span className="text-danger">*</span>
          </label>
          <InputMask
            disabled={disabledValues.dob}
            value={values.dob ?? ' '}
            className="form-control"
            name="dob"
            onChange={handleChange}
            mask="99/99/9999"
          />
        </div>
        <div className="col-3 form-group textGray">
          <label htmlFor="gender">Gender</label>
          <Field
            disabled={disabledValues.gender}
            className="form-control"
            as="select"
            name="gender"
            placeholder="Select Gender">
            <option>Select a Gender</option>
            <option value="F">Female</option>
            <option value="M">Male</option>
          </Field>
        </div>
      </div>
      <div className="row">
        <div className="col-3 form-group textGray">
          <label htmlFor="homePhone">
            Home Number
            <span className="text-danger">*</span>
          </label>
          <MaskInput
            value={values.homePhone}
            onChange={handleChange}
            name="homePhone"
            mask={phoneNumberMask}
            placeholder="(###) ###-####"
            autoComplete="off"
          />
        </div>
        <div className="col-3 form-group textGray">
          <label htmlFor="mobile">Mobile Number</label>
          <MaskInput
            value={values.mobilePhone}
            onChange={handleChange}
            name="mobilePhone"
            mask={phoneNumberMask}
            placeholder="(###) ###-####"
            autoComplete="off"
          />
        </div>

        <div className="col-4 textGray">
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <Field
              type="text"
              name="email"
              className="form-control"
              placeholder="Email"
              autoComplete="one-time-code"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-6 textGray">
          <div className="form-group">
            <label htmlFor="address">
              Primary Address
              <span className="text-danger">*</span>
            </label>
            <GoogleAutocomplete
              id="primaryAddressStreetLine1"
              name="primaryAddressStreetLine1"
              onInputChanged={(v) => setFieldValue('primaryAddressStreetLine1', v)}
              onChange={(place) => {
                const { address, city, state, zip } = getAddressFields(
                  place,
                  'primaryAddressStreetLine1',
                )
                setFieldValue('primaryAddressStreetLine1', address)
                setFieldValue('primaryAddressCity', city)
                setFieldValue('primaryAddressState', state)
                setFieldValue('primaryAddressZip', zip)
              }}
            />
          </div>

          <div className="form-group">
            <Field type="text" name="address2" className="form-control" placeholder="Suite / Apt" />
          </div>

          <div className="row">
            <div className="col-4 form-group textGray">
              <label htmlFor="city">City</label>
              <Field
                type="text"
                name="primaryAddressCity"
                className="form-control"
                placeholder="City"
              />
            </div>
            <div className="col-4 form-group textGray">
              <label htmlFor="state">State</label>
              <Field
                className="form-control"
                as="select"
                name="primaryAddressState"
                placeholder="State">
                {states.map((s, i) => (
                  <option key={i} value={s.id}>
                    {s.name}
                  </option>
                ))}
              </Field>
            </div>
            <div className="col-4 form-group textGray">
              <label htmlFor="zip">Zip Code</label>
              <MaskInput
                value={values.primaryAddressZip}
                onChange={handleChange}
                name="primaryAddressZip"
                mask={zipMask}
                placeholder="#####-####"
              />
            </div>
          </div>
        </div>

        <div className="col-6 textGray">
          <div className="d-flex flex-row">
            <div className="textGray mr-4">
              <label htmlFor="has_email">Device Delivery Address</label>
            </div>
            <Field
              id="deliverySameAsPrimary"
              name="deliverySameAsPrimary"
              component={CheckboxInput}
              label="Same as Primary Address"
            />
          </div>

          {!values.hasMail ? (
            <>
              <div className="row">
                <div className="col-12 form-group textGray">
                  <GoogleAutocomplete
                    id="deliveryAddressStreetLine1"
                    name="deliveryAddressStreetLine1"
                    onInputChanged={(v) => setFieldValue('deliveryAddressStreetLine1', v)}
                    onChange={(place) => {
                      const { address, city, state, zip } = getAddressFields(
                        place,
                        'deliveryAddressStreetLine1',
                      )
                      setFieldValue('deliveryAddressStreetLine1', address)
                      setFieldValue('deliveryAddressCity', city)
                      setFieldValue('deliveryAddressState', state)
                      setFieldValue('deliveryAddressZip', zip)
                    }}
                  />
                </div>
                <div className="col-12 form-group textGray">
                  <Field
                    type="text"
                    name="deliveryAddressStreetLine2"
                    className="form-control"
                    placeholder="Suite / Apt"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-4 form-group textGray">
                  <label htmlFor="mail_city">City</label>
                  <Field
                    type="text"
                    name="deliveryAddressCity"
                    className="form-control"
                    placeholder="City"
                  />
                </div>
                <div className="col-4 form-group textGray">
                  <label htmlFor="mail_state">State</label>
                  <Field
                    className="form-control"
                    as="select"
                    name="deliveryAddressState"
                    placeholder="State">
                    {states.map((s, i) => (
                      <option key={i} value={s.id}>
                        {s.name}
                      </option>
                    ))}
                  </Field>
                </div>
                <div className="col-4 form-group textGray">
                  <label htmlFor="mail_zip">Zip Code</label>
                  <MaskInput
                    value={values.deliveryAddressZip}
                    onChange={handleChange}
                    name="deliveryAddressZip"
                    mask={zipMask}
                    placeholder="#####-####"
                  />
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default PatientForm

/** @jsxImportSource @emotion/react */
import { useState, SyntheticEvent } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Alert from 'react-bootstrap/Alert'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faEnvelope, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

import Logo from 'assets/img/logo2.png'
import { useSendPasswordResetLinkMutation } from 'store/services/users'

const Login = () => {
  const navigate = useNavigate()
  const [sendDmeResetPasswordEmail] = useSendPasswordResetLinkMutation()
  const [email, setEmail] = useState('')
  const [result, setResult] = useState({ success: '', error: '' })

  const handleSubmit = async (e: SyntheticEvent) => {
    setResult({ success: '', error: '' })
    e.preventDefault()
    try {
      await sendDmeResetPasswordEmail({ email }).unwrap
      setResult({
        error: '',
        success:
          'If the email you entered is associated with an account, you will receive an email with a link to reset your password.',
      })
    } catch (err) {
      console.log(err)
      setResult({
        success: '',
        error:
          'There was an issue sending the password reset email. Please try again or contact support for assistance.',
      })
    }
  }

  return (
    <Container>
      <Row> &nbsp; </Row>
      <Row className="justify-content-md-center">
        <Col sm={8}>
          <div className="text-center mb-5 mt-2">
            <img src={Logo} alt="logo" />
          </div>
        </Col>
        <Col sm={8}>
          <form method="post" onSubmit={handleSubmit} css={{ marginBottom: '20px' }}>
            <div className="text-center">
              <h3>Forgot Password</h3>
            </div>

            <div className="form-group">
              <label>
                {' '}
                <FontAwesomeIcon icon={faEnvelope} /> Email address
              </label>
              <input
                type="email"
                className="form-control"
                autoComplete="username"
                placeholder="Enter email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <button type="submit" className="btn btn-primary btn-block">
              Send Password Reset Link
            </button>
            <br />
            <Link to="/login">{'< '} Back to Login</Link>
          </form>
          {result.success && (
            <Alert variant="success">
              <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" /> {result.success}
            </Alert>
          )}
          {result.error && (
            <Alert variant="danger">
              <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" /> {result.error}
            </Alert>
          )}
        </Col>
      </Row>
    </Container>
  )
}

export default Login

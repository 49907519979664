/** @jsxImportSource @emotion/react */
import moment from 'moment'

import { useAppSelector } from 'hooks/redux'
import { confirm } from 'components/molecules/Confirmation'
import { Note } from 'store/types'

interface IProps {
  userContext: 'LAB' | 'DME'
  note: Note
  pinNote: (param: { uuid: string; pinned: boolean }) => Promise<void>
  deleteNote: (param: { uuid: string }) => Promise<void>
}

const Box = ({ userContext, note, pinNote, deleteNote }: IProps) => {
  const profile = useAppSelector((state) => state.init.profile)
  const name = `${note.userFirstName} ${note.userLastName}`
  const timestamp = moment(note.createdAt).format('MM/DD/YYYY h:mm a')
  const pinClass = note.pinned ? 'fas fa-star' : 'far fa-star'
  const isMyNote = profile?.email === note.userEmail

  const pin = () => pinNote({ uuid: note.uuid, pinned: !note.pinned })
  const schedulingNoteFromLab = note.type === 'scheduling' && userContext === 'DME'

  return (
    <div className="note_box">
      <div className="note_box_meta">
        <div className="note_box_meta-user mb-1" style={{ color: '#343a40' }}>
          {name}
        </div>
        <div className="note_box_meta-date">
          <span>{timestamp}</span>
        </div>
      </div>
      <div
        className={`note_box_holder rounded ${note.pinned ? 'note-pinned' : ''}`}
        css={{
          ...(schedulingNoteFromLab && { background: '#c6e3fa', border: 'none !important' }),
        }}>
        <p className="note_box_text pt-1 pl-1 font-italic" css={{ whiteSpace: 'pre-wrap' }}>
          {note.text}
        </p>
        <div className="actions">
          <span className={`pl-1 pb-1 note-star ${pinClass}`} onClick={pin}>
            <span className="note-star_text">{note.pinned ? 'Pinned' : 'Pin Note'}</span>
          </span>
          {userContext === 'LAB' && isMyNote && (
            <span
              className="note-trash fas fa-trash pr-1 pb-1"
              onClick={() =>
                confirm('note')
                  .then(() => deleteNote({ uuid: note.uuid }))
                  .catch(() => {})
              }
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default Box

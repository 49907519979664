/** @jsxImportSource @emotion/react */
import { ReactNode, useRef, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import { useAppSelector } from 'hooks/redux'
import TabHeader from 'components/atoms/TabHeader'
import ButtonSave from 'components/atoms/ButtonSave'
import routes from 'components/dme/constants/routes'
import { useUpdatePayerMutation } from 'store/services/dmeOrder'
import { Order } from 'store/types'
import { PayerSelectionForm } from './payer/PayerSelection'
import NavigateWarning from 'components/atoms/NavigateWarning'
import { useCallbackPrompt } from 'hooks/useCallbackPrompt'

interface IProps {
  order: Order
  actionButtons: ReactNode
  newOrderValues: { [k: string]: string }
  setNewOrdersValues: () => void
  modalView?: boolean
}

const InsuranceTab = ({
  order,
  actionButtons,
  newOrderValues,
  setNewOrdersValues,
  modalView,
}: IProps) => {
  const navigate = useNavigate()
  const { orderId } = useParams()
  const [dirty, setFormDirty] = useState(false)
  const [valid, setFormValid] = useState(false)
  const submitRef = useRef<() => void>()
  const [updatePayer] = useUpdatePayerMutation()
  const { payers } = order || {}
  const currentOfficeId = useAppSelector((state) => state.init.currentOfficeId)
  const inTabView = location.pathname.indexOf('orderEdit') > -1
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(dirty)

  const submitForm = async (payload) => {
    await updatePayer({
      orderId,
      payers: payload,
    })
    setFormDirty(false)
    await new Promise((resolve) => setTimeout(resolve, 400))

    if (window.location.href.indexOf('orderEdit') > 0 && !modalView) {
      navigate(`${routes.index}${routes.order.edit}${orderId}/${routes.order.rx}`)
    }
    return true
  }

  return (
    <>
      <NavigateWarning
        showDialog={showPrompt}
        content={
          <div css={{ lineHeight: '1.3rem' }}>
            {valid
              ? 'You have unsaved changes. Do you want to save before navigating away?'
              : 'Insurance form is incomplete.  Complete the form before navigating away?'}
          </div>
        }
        cancelNavigation={confirmNavigation}
        confirmNavigation={() => {
          if (!valid) {
            return cancelNavigation()
          }

          submitRef.current && submitRef.current()
          confirmNavigation()
        }}
        alternateConfirmLabel={valid ? 'Save' : 'Complete Form'}
      />
      <div className="container">
        {!modalView && (
          <TabHeader
            title="Patient Insurance"
            back={`${routes.index}${routes.order.edit}${orderId}/physician`}
            next={`${routes.index}${routes.order.edit}${orderId}/RxForm`}
            actionButtons={
              inTabView
                ? [
                    <ButtonSave
                      key={1}
                      id="btn-ts"
                      type="submit"
                      label="Save Changes"
                      onClick={() => {
                        submitRef?.current && submitRef.current()
                      }}
                    />,
                  ]
                : actionButtons
            }
          />
        )}
      </div>
      {!inTabView && <hr className="mt-0" />}
      <PayerSelectionForm
        officeId={currentOfficeId}
        payers={payers}
        submitForm={submitForm}
        submitRef={submitRef}
        setNewOrdersValues={setNewOrdersValues}
        setFormDirty={setFormDirty}
        setFormValid={setFormValid}
      />
    </>
  )
}

export default InsuranceTab

import { TicketWithConversations } from "ts-back-end/dist/ts-back-end/src/business/ticket/dto/create-ticket.dto";
import { baseApi } from './baseApi'
import { CreateTicketDto, TicketDto, FreshDeskTicketsList } from 'store/types'

export const ticketsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    createTicket: builder.mutation<TicketDto, CreateTicketDto>({
      query: (data) => ({
        method: `POST`,
        url: `/dme/${data.officeId}/tickets`,
        body: data,
      }),
      invalidatesTags: ['Tickets'],
    }),
    updateTicket: builder.mutation<TicketDto, TicketDto>({
      query: (data) => ({
        method: `PUT`,
        url: `/dme/${data.officeId}/tickets/${data.id}`,
        body: data,
      }),
      invalidatesTags: ['Tickets'],
    }),
    getTickets: builder.query<FreshDeskTicketsList, { officeId: string; status: string }>({
      query: ({ officeId, status }) => ({ url: `/dme/${officeId}/tickets`, params: { status } }),
      providesTags: ['Tickets'],
    }),
    getTicket: builder.query<TicketWithConversations, { officeId: string; id: string }>({
      query: ({ officeId, id }) => ({ url: `/dme/${officeId}/tickets/${id}` }),
      providesTags: (result, error, { id }) => [{ type: 'Tickets', id }],
    }),
    getLabNewTicketCount: builder.query<number, null>({
      query: () => ({ url: `/lab/tickets/counts` }),
    }),
  }),
})

export const {
  useCreateTicketMutation,
  useUpdateTicketMutation,
  useGetTicketsQuery,
  useLazyGetTicketsQuery,
  useGetTicketQuery,
  useGetLabNewTicketCountQuery,
} = ticketsApi

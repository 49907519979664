import createEmitter from './emitter'
import { isObject } from './utils'
import { DeviceListOptions } from './devices'

export const createDevice = (config: DeviceListOptions, reader) => {
  const emitter = createEmitter()

  emitter.connected = false

  const onTimeout = (event) => {
    const error = new Error('Device timeout')
    error.type = 'DeviceTimeoutError'
    emitter.emit('timeout', event)
    emitter.emit('error', error)
  }

  const onConnected = (event) => {
    emitter.connected = true
    emitter.emit('connected', event)
  }

  const onDisconnected = (event) => {
    emitter.connected = false
    emitter.emit('disconnected', event)
  }

  const onRead = (event) => {
    emitter.emit('read', event)
  }

  const onReading = (event) => {
    emitter.emit('reading', { progress: event.data })
  }

  const onResetted = (event) => {
    emitter.emit('resetted', event)
  }

  const onResetting = (event) => {
    emitter.emit('resetting', { progress: event.data })
  }

  const onUploaded = (event) => {
    emitter._lastServerResponse = emitter._lastServerResponse || {}

    emitter.emit('uploaded', {
      ...event,
      data: { ...emitter._lastServerResponse, size: event.data },
    })

    delete emitter._lastServerResponse
  }

  const onServerResponse = (event) => {
    emitter._lastServerResponse = event?.data?.data
  }

  const onUploading = (event) => {
    emitter.emit('uploading', { progress: event.data })
  }

  const onError = (event) => {
    if (event instanceof Error) {
      event.error = event
    }

    const error = event?.error
      ? isObject(event.error)
        ? event.error
        : new Error(event.error)
      : new Error('Unknown upload error')

    error.type = 'UploadError'

    emitter.emit('error', error)
  }

  const read = (data: { token: string; orderId: string; deviceType: string }) => {
    const { connectionPath, connectionType } = config || {}
    const { token, orderId, deviceType } = data
    reader.send('device:read', {
      deviceType,
      connectionPath,
      connectionType,
      authToken: token,
      orderId,
    })
  }

  const reset = (deviceType: string) => {
    const { connectionType, connectionPath } = config
    reader.send('device:reset', {
      deviceType,
      connectionPath,
      connectionType,
    })
  }

  const destroy = () => {
    reader.off('deviceTimeout', onTimeout)
    reader.off('serialConnected', onConnected)
    reader.off('serialDisconnected', onDisconnected)
    reader.off('deviceReadProgress', onReading)
    reader.off('deviceReadCompleted', onRead)
    reader.off('deviceResetProgress', onResetting)
    reader.off('deviceResetCompleted', onResetted)
    reader.off('uploadProgress', onUploading)
    reader.off('uploadCompleted', onUploaded)
    reader.off('serverResponse', onServerResponse)
    reader.off('uploadError', onError)
  }

  reader.on('deviceTimeout', onTimeout)
  reader.on('serialConnected', onConnected)
  reader.on('serialDisconnected', onDisconnected)
  reader.on('deviceReadProgress', onReading)
  reader.on('deviceReadCompleted', onRead)
  reader.on('deviceResetProgress', onResetting)
  reader.on('deviceResetCompleted', onResetted)
  reader.on('uploadProgress', onUploading)
  reader.on('uploadCompleted', onUploaded)
  reader.on('serverResponse', onServerResponse)
  reader.on('resetError', onError)
  reader.on('uploadError', onError)

  return Object.assign(emitter, { ...config, read, reset, destroy })
}

export const DME_ROLES = [
  'CORPORATE_EXECUTIVE',
  'OFFICE_MANAGER',
  'OFFICE_TEAM_LEAD',
  'REGIONAL_VP',
  'DIRECTOR_OF_SALES',
  'CSR',
  'SALES_REP',
  'DRIVER',
  'OTHER',
]

export const LAB_ROLES = ['LAB_ADMIN', 'Lab Manager']

import { Field } from 'formik'

import states from 'constants/states'
import { TextInput } from 'components/atoms/Input'
import { SelectInput } from 'components/atoms/Select'
import { MaskedTextInput } from 'components/atoms/InputMask'

export interface PhysicianFormFields {
  firstName: string
  lastName: string
  credential?: string
  addressStreetLine1?: string
  addressStreetLine2?: string
  addressCity?: string
  addressState?: string
  addressZip?: string
  telephone?: string
  fax: string
  comment?: string
}
interface IProps {
  includeName?: boolean
}

export const PhysicianForm = ({ includeName }: IProps) => {
  return (
    <>
      {includeName && (
        <div className="row">
          <div className="col-4 form-group">
            <label className="textGray">First Name</label>
            <Field
              component={TextInput}
              label={false}
              placeholder="First Name"
              name="firstName"
              autoComplete="one-time-code"
            />
          </div>
          <div className="col-4 form-group">
            <label className="textGray">Last Name</label>
            <Field
              component={TextInput}
              label={false}
              placeholder="Last Name"
              name="lastName"
              autoComplete="one-time-code"
            />
          </div>
          <div className="col-4 form-group">
            <label className="textGray">Suffix</label>
            <Field
              disabled
              component={TextInput}
              label={false}
              placeholder="Suffix"
              name="credential"
            />
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-12 form-group">
          <label className="textGray">Address</label>
          <Field
            component={TextInput}
            label={false}
            placeholder="Street Address"
            name="addressStreetLine1"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 form-group">
          <Field
            component={TextInput}
            label={false}
            placeholder="Suite/Apt"
            name="addressStreetLine2"
          />
        </div>
        <div className="col-12"></div>
      </div>
      <div className="row">
        <div className="col-4 form-group">
          <label className="textGray">City</label>
          <Field component={TextInput} label={false} placeholder="City" name="addressCity" />
        </div>
        <div className="col-4 form-group">
          <Field
            component={SelectInput}
            options={states || []}
            defaultLabel="State"
            labelClassName="textGray"
            name="addressState"
            label="State"
          />
        </div>
        <div className="col-4 form-group">
          <label className="textGray">Zip Code</label>
          <Field component={TextInput} label={false} placeholder="Zip Code" name="addressZip" />
        </div>
      </div>
      <div className="row">
        <div className="col-6 form-group">
          <label className="textGray">Telephone Number *</label>
          <Field component={MaskedTextInput} name="telephone" mask="(999) 999-9999" />
        </div>
        <div className="col-6 form-group">
          <label className="textGray">Fax Number *</label>
          <Field component={MaskedTextInput} name="fax" mask="(999) 999-9999" />
        </div>
      </div>
      <div className="row">
        <div className="col-12 form-group">
          <label className="textGray">Address Comment (optional)</label>
          <Field component={TextInput} label={false} placeholder="Address Comment" name="comment" />
        </div>
      </div>
    </>
  )
}

/** @jsxImportSource @emotion/react */
import { useEffect, useState, useMemo } from 'react'
import moment from 'moment'
import { Link, useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import ReactSelect from 'react-select'
import { keyBy } from 'lodash'

import Input from 'components/atoms/Input'
import Checkbox from 'components/atoms/Checkbox'
import { handleChange } from 'components/atoms/Upsert'
import DashboardContent from '../atoms/DashboardContent'
import * as generalHelper from 'helpers/generalHelper'
import { useLazyGetLabPatientListQuery } from 'store/services/patient'
import { useGetLabOfficesQuery } from 'store/services/office'
import statesList from 'constants/states'
import routes from '../constants/routes'
import Button from 'components/atoms/Button'
import Select from 'components/atoms/Select'
import _ from 'lodash'
import { Order } from 'store/types'
import DataTable from 'components/atoms/DataTable'
import { TableWrapper } from 'components/atoms/TableWrapper'
import InputMask from 'components/atoms/InputMask'

const StyledTableWrapper = styled(TableWrapper)`
  th {
    padding: 0 0.2rem 0.4rem 0.1rem;
  }
  td {
    vertical-align: middle;
    padding: 0.2rem;
    div {
      span {
        font-size: 0.9rem;
      }
    }
  }
`

const states = statesList.map((s) => ({ key: s.id, name: s.name }))

const CellWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    white-space: nowrap;
    display: flex;
  }
`

interface SearchForm {
  firstName: string
  lastName: string
  dob: string
  state: string
  officeId: string
  openOrder: boolean
}

const initialSearchVals = {
  firstName: '',
  lastName: '',
  dob: '',
  state: '',
  openOrder: false,
  officeId: '',
}

const PAGE_SIZE = 15

const PatientDirectory = () => {
  const navigate = useNavigate()
  const [values, setValues] = useState<SearchForm>(initialSearchVals)
  const [currentPage, setCurrentPage] = useState(0)
  const { data: offices } = useGetLabOfficesQuery({})
  const [getPatients, { currentData: patients, isFetching }] = useLazyGetLabPatientListQuery()

  const officesOptions = useMemo(
    () =>
      offices?.map((o) => ({
        value: o.id,
        label: `${o.officeName} (${o.addressCity}, ${o.addressState})`,
      })),
    [offices],
  )

  const officeLookup = useMemo(() => keyBy(offices, 'id'), [offices])

  useEffect(() => {
    setTimeout(onSearch, 100)
  }, [currentPage])

  const onSearch = () => {
    getPatients(
      {
        ...(values.firstName && { firstName: values.firstName }),
        ...(values.lastName && { lastName: values.lastName }),
        ...(values.dob.length > 7 && {
          dob: moment(values.dob).format('YYYY-MM-DD'),
        }),
        ...(values.state && { state: values.state }),
        ...(values.officeId && { officeId: values.officeId }),
        ...(values.openOrder && { only_open_orders: 'true' }),
        size: PAGE_SIZE,
        page: currentPage,
      },
      false,
    )
  }

  const capitalizeWords = (string: string) => {
    const words = string?.split(' ')
    words?.forEach((element, index: number) => {
      words[index] = words[index]?.toLowerCase()
      words[index] = words[index]?.[0]?.toUpperCase() + words[index].substr(1)
    })
    return words?.join(' ')
  }

  const headers = [
    {
      Header: 'Patient Name',
      accessor: 'patientName',
      columnSize: 3,
      Cell: ({ row }) => (
        <>
          <Link to={`${routes.index}${routes.patient.root}${row.original.id}`}>
            {`${_.capitalize(row.original.lastName)}${
              row.original.suffix ? ' ' + row.original.suffix : ''
            }, ${_.capitalize(row.original.firstName)} ${
              row.original.middleName ? _.capitalize(row.original.middleName) : ''
            }`}
          </Link>
        </>
      ),
    },
    {
      Header: 'Date of Birth',
      accessor: 'dob',
      Cell: ({ row }) => (
        <>
          {moment(moment(row.original.dob).format('MM/DD/YYYY')).isValid()
            ? moment(row.original.dob).format('MM/DD/YYYY')
            : 'N/A'}
        </>
      ),
    },
    {
      Header: 'Address',
      accessor: 'address',
      columnSize: 3,
      Cell: ({ row }) => (
        <CellWrapper>
          <div>{row.original.primaryAddressStreetLine1}</div>
          <div>
            {row.original.primaryAddressState && (
              <span>{`${capitalizeWords(
                row.original.primaryAddressCity,
              )}, ${row.original.primaryAddressState.toUpperCase()}, ${
                row.original.primaryAddressZip
              }`}</span>
            )}
          </div>
        </CellWrapper>
      ),
    },
    {
      Header: 'DME',
      accessor: 'dme',
      Cell: ({ row }) => officeLookup[row.original.officeId]?.officeName || null,
    },
    {
      Header: 'Open Order',
      accessor: 'openOrder',
      columnSize: 3,
      Cell: ({ row }) => (
        <CellWrapper onClick={(e) => e.stopPropagation()}>
          {[...row.original.orders]
            ?.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
            ?.map((order: Order) => (
              <div css={{ fontWeight: order.bucket === 'COMPLETED' ? '400' : '600' }}>
                <Link to={`${routes.index}${routes.order.edit}${order.id}`} className=" mr-3">
                  {`#${order.id ?? ''}`}
                </Link>
                {order.testType} - {_.startCase(order.bucket?.replace(/_/g, ' ')?.toLowerCase())} -{' '}
                {moment(order.createdAt).format('MM/DD/YYYY')}
              </div>
            ))}
        </CellWrapper>
      ),
    },
    {
      Header: 'Action',
      accessor: 'action',
      columnSize: 2,
      Cell: ({ row }) => (
        <>
          <Link
            className="btn btn-primary py-0"
            type="button"
            to={`${routes.index}${routes.order.root}${routes.order.new}patient/${
              row.original?.id
            }/orderDetails?officeId=${row.original?.officeId ?? '1'}`}
            state={{ activeOrders: row.original.orders?.filter((i) => i.bucket !== 'COMPLETED') }}
            onClick={(e) => e.stopPropagation()}>
            {generalHelper.t('New Order')}
          </Link>
        </>
      ),
    },
  ]

  return (
    <DashboardContent
      title="Patients"
      subtitle="Patient Directory"
      content={
        <div className="card card-primary">
          <div className="card-body">
            <div className="row">
              <div className="col-sm-10">
                <div className="row pl-2">
                  <div className="col-sm-2">
                    <div className="form-group">
                      <label>{generalHelper.t('First Name')}</label>

                      <Input
                        value={values.firstName}
                        label=""
                        placeholder="First Name"
                        name="firstName"
                        autoFocus={true}
                        handleChange={(event) => {
                          handleChange(event, values, setValues)
                        }}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            onSearch()
                          }
                        }}
                        autoComplete="one-time-code"
                      />
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="form-group">
                      <label>{generalHelper.t('Last Name')}</label>
                      <Input
                        value={values.lastName}
                        label=""
                        placeholder="Last Name"
                        name="lastName"
                        handleChange={(event) => {
                          handleChange(event, values, setValues)
                        }}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            onSearch()
                          }
                        }}
                        autoComplete="one-time-code"
                      />
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="form-group">
                      <label htmlFor="dob">{generalHelper.t('Date Of Birth (MM/DD/YYYY)')}</label>
                      <InputMask
                        value={values.dob ?? ' '}
                        className="form-control"
                        name="dob"
                        onChange={(event) => {
                          handleChange(event, values, setValues)
                        }}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            onSearch()
                          }
                        }}
                        mask="99/99/9999"
                      />
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div
                      className="form-group"
                      css={{ '& .text-gray': { color: 'black !important' } }}>
                      <Select
                        options={states}
                        value={values.state}
                        defaultLabel="All States"
                        name="state"
                        label="State"
                        handleChange={(event) => {
                          handleChange(event, values, setValues)
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="col-sm-2"
                    css={{ '& .select__control': { height: '34px', minHeight: '34px' } }}>
                    <label className="text-black" htmlFor="officeId">
                      DME Office
                    </label>
                    <ReactSelect
                      className="basic-single w-100 mr-3"
                      classNamePrefix="select"
                      isClearable
                      isSearchable
                      placeholder="Select DME"
                      name="officeId"
                      onChange={(item) =>
                        setValues({
                          ...values,
                          officeId: item?.value,
                        })
                      }
                      options={officesOptions}
                      value={
                        values.officeId
                          ? officesOptions.find((option) => option.value === values.officeId)
                          : null
                      }
                    />
                  </div>
                  <div className="col-sm-2 col-md-2 pt-4 pb-3 m-auto">
                    <div className="form-check">
                      <Checkbox
                        value={values.openOrder}
                        label="Only Open Orders"
                        name="openOrder"
                        handleChange={(e) => {
                          handleChange(e, values, setValues)
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-2  pt-4">
                <Button
                  label="Search"
                  className="btn btn-primary mr-3"
                  onClick={() => {
                    setCurrentPage(0)
                    onSearch()
                  }}
                />
                <Button
                  label="Clear"
                  className="btn btn-default"
                  onClick={() => {
                    setValues(initialSearchVals)
                    getPatients({
                      size: PAGE_SIZE,
                      page: 0,
                    })
                  }}
                />
              </div>
            </div>
            <div className="container-fluid">
              <StyledTableWrapper
                className="card-body row"
                style={{
                  overflowY:
                    !patients?.items?.length || patients?.items?.length < 10 ? 'unset' : 'auto',
                }}>
                <DataTable
                  data={patients}
                  status={isFetching}
                  tableColumns={headers}
                  pageSize={PAGE_SIZE}
                  setCurrentPage={setCurrentPage}
                  setSortOptions={() => {}}
                  entityName="patients"
                  onRowClick={(row) =>
                    navigate(`${routes.index}${routes.patient.root}${row.original.id}`)
                  }
                />
              </StyledTableWrapper>
            </div>
          </div>
        </div>
      }
      breadcrumb={[
        {
          label: 'Patient Directory',
          to: `${routes.index}${routes.patient.root}`,
        },
      ]}
    />
  )
}

export default PatientDirectory

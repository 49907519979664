/** @jsxImportSource @emotion/react */
import { useEffect, useMemo, useState } from 'react'
import { useParams, Link, useNavigate, useSearchParams } from 'react-router-dom'
import { capitalize, pick, startCase } from 'lodash'
import Button from 'react-bootstrap/Button'
import { Formik, Form, Field } from 'formik'
import Toast from 'components/atoms/Toast'
import * as Yup from 'yup'
import Select from 'react-select'
import { v4 as uuidv4 } from 'uuid'

import SwitchButton from 'components/atoms/SwitchButton'
import MaskInput from 'components/atoms/MaskInput'
import { phoneNumberMask } from 'helpers/masks'
import { confirm } from 'components/molecules/Confirmation'
import {
  useCreateDmeUserMutation,
  useUpdateDmeUserMutation,
  useDeleteUserMutation,
  useSendResetPasswordEmailMutation,
} from 'store/services/users'
import {
  useGetCompaniesQuery,
  useGetLabOfficesQuery,
  useGetCompanyQuery,
} from 'store/services/office'
import { UserType } from 'store/types'
import DashboardContent from '../atoms/DashboardContent'
import { useGetLabUserQuery } from 'store/services/users'
import routes from '../constants/routes'
import { skipToken } from '@reduxjs/toolkit/query/react'
import { NotificationPreferences } from 'components/organisms/user/NotificationPreferences'
import {
  getUserNotificationPrefs,
  getInitialFormValues,
  handleRoleChange,
} from 'components/organisms/user/notificationHelpers'
import { DME_ROLES } from 'constants/roles'
import { userValidation } from 'helpers/validate'

interface FormValues {
  firstName: string
  lastName: string
  officePhone: string
  directPhone: string
  email: string
  role: string
  title: string
}

const initialValues = {
  firstName: '',
  lastName: '',
  primaryOfficeId: '',
  officePhone: '',
  directPhone: '',
  email: '',
  role: '',
  title: '',
  active: true,
  primaryOfficeAdmin: false,
  assignedOfficesAdmin: false,
  companyAdmin: false,
}

const UserUpsert = () => {
  const navigate = useNavigate()
  const { userId } = useParams()
  const [searchParams] = useSearchParams()
  const [createUser] = useCreateDmeUserMutation()
  const [updateUser] = useUpdateDmeUserMutation()
  const [deleteUser] = useDeleteUserMutation()
  const [companySearch, setCompanySearch] = useState('')
  const [sendResetPasswordEmail] = useSendResetPasswordEmailMutation()
  const [officeValues, setOfficeValues] = useState<any>({
    companyId: searchParams.get('companyId'),
    primaryOfficeId: searchParams.get('officeId'),
    addlOfficeId: '',
  })
  const [addlOffices, setAddlOffices] = useState([])
  const { data: companies } = useGetCompaniesQuery({
    name: companySearch || undefined,
  })
  const { data: offices } = useGetLabOfficesQuery(
    officeValues.companyId ? { companyId: officeValues.companyId } : skipToken,
  )
  const { data: user } = useGetLabUserQuery(userId ? { id: Number(userId) } : skipToken)
  const { data: companyDetails } = useGetCompanyQuery(
    officeValues.companyId || user?.company
      ? { id: officeValues.companyId || user?.company }
      : skipToken,
  )
  const validation = useMemo(
    () => userValidation(companyDetails?.companyDomains ?? []),
    [companyDetails],
  )

  const companyOptions = companies?.map((company) => ({
    label: company.companyName,
    value: company.id,
  }))
  const officesOptions =
    useMemo(
      () =>
        offices?.map((office) => ({
          label: `${office.officeName} (${office.addressCity}, ${office.addressState})`,
          value: Number(office.id),
        })),
      [offices],
    ) || []

  useEffect(() => {
    if (companyDetails) {
      setCompanySearch(companyDetails.companyName)
    }
  }, [companyDetails])

  useEffect(() => {
    if (user) setOfficeValues({ companyId: user.company })
  }, [user])

  useEffect(() => {
    if (user && officesOptions?.length) {
      const addlOffices = officesOptions.filter(
        (o) =>
          (user.offices.includes(o.value) || user.offices.includes(o.value.toString())) &&
          o.value !== user.primaryOffice,
      )
      setAddlOffices(addlOffices ?? [])
    }
  }, [user, officesOptions])

  useEffect(() => {
    if (user && !officeValues.primaryOfficeId) {
      setOfficeValues((p) => ({ ...p, primaryOfficeId: user.primaryOffice }))
    }
  }, [user, officesOptions])

  const formInit = useMemo(
    () => ({
      ...initialValues,
      ...(user && {
        ...pick(user, ['email', 'firstName', 'lastName', 'officePhone', 'directPhone']),
        role: user?.roles?.[0],
        active: user?.active ?? false,
        primaryOfficeId: user?.primaryOffice,
        primaryOfficeAdmin: user?.adminOf === 'PRIMARY_OFFICE',
        assignedOfficesAdmin: user?.adminOf === 'ASSIGNED_OFFICES',
        companyAdmin: user?.adminOf === 'COMPANY',
        ...getInitialFormValues(user?.notificationPreferences),
      }),
      ...(!user &&
        officeValues.primaryOfficeId && { primaryOfficeId: officeValues.primaryOfficeId }),
    }),
    [user, officeValues.primaryOfficeId],
  )

  return (
    <div>
      <DashboardContent
        icon="fas fa-people"
        title={userId ? 'Edit User' : 'Create User'}
        subtitle={
          <>
            <Link to={`${routes.index}${routes.dmeUsers.root}${routes.dmeUsers.directory}`}>
              {'< Back to User List'}
            </Link>
            <div css={{ position: 'absolute', top: '15px', right: '15px', display: 'flex' }}>
              {userId && (
                <>
                  <Button
                    variant="outline-danger"
                    css={{ marginRight: 10 }}
                    onClick={() =>
                      confirm('user')
                        .then(async () => {
                          await deleteUser({ id: Number(userId) }).unwrap()
                          navigate(
                            `${routes.index}${routes.dmeUsers.root}${routes.labUsers.directory}`,
                          )
                        })
                        .catch(() => {})
                    }>
                    Delete User
                  </Button>
                  {!user?.isSsoEnabled && (
                    <Button
                      variant="outline-danger mr-5"
                      css={{}}
                      onClick={() => {
                        confirm('', {
                          title: 'Are you sure?',
                          description: 'This will send a password reset email to this user.',
                          yesAction: 'Yes, proceed',
                          noAction: 'Cancel',
                        })
                          .then(async () => {
                            try {
                              await sendResetPasswordEmail({ email: user?.email }).unwrap()
                              Toast({ type: 'success', label: `Password reset email sent.` })
                            } catch (err) {
                              Toast({ type: 'error', label: `Error sending password reset email.` })
                            }
                          })
                          .catch(() => {})
                      }}>
                      Reset Password
                    </Button>
                  )}
                </>
              )}
              <Button type="submit" form="userUpsert" variant="primary">
                Save Changes
              </Button>
            </div>
          </>
        }
        breadcrumb={[
          {
            label: 'Users',
            to: `${routes.index}${routes.company.root}`,
          },
          { label: userId ? 'Edit' : 'New' },
        ]}
        content={
          <Formik
            enableReinitialize
            validationSchema={validation}
            initialValues={formInit}
            onSubmit={async (values) => {
              const {
                role,
                roles,
                primaryOfficeAdmin,
                companyAdmin,
                assignedOfficesAdmin,
                nickName,
                ...vals
              } = values
              vals.roles = role ? [role] : ['CSR']
              const offices = [officeValues.primaryOfficeId, ...addlOffices.map((o) => o.value)]
              const adminOf = primaryOfficeAdmin
                ? 'PRIMARY_OFFICE'
                : assignedOfficesAdmin
                  ? 'ASSIGNED_OFFICES'
                  : companyAdmin
                    ? 'COMPANY'
                    : null

              const [notificationPreferences, rest] = getUserNotificationPrefs(vals)
              delete rest.primaryOfficeId
              const common = {
                userType: UserType.DME,
                offices,
                primaryOffice: officeValues.primaryOfficeId,
                company: officeValues.companyId,
                permissions: [],
                adminOf,
                ...rest,
                notificationPreferences,
              }
              try {
                if (userId) {
                  await updateUser({
                    id: Number(userId),
                    ...common,
                  }).unwrap()
                  Toast({ type: 'success', label: `User updated!` })
                } else {
                  await createUser({
                    ...common,
                    password: uuidv4(),
                  }).unwrap()
                  Toast({ type: 'success', label: `User created!` })
                }
                navigate(`${routes.index}${routes.dmeUsers.root}${routes.dmeUsers.directory}`)
              } catch (err) {
                Toast({ type: 'error', label: err.data.message })
              }
            }}>
            {({ values, errors, touched, handleChange, setFieldValue, setValues }) => (
              <Form id="userUpsert">
                <div className="card-body row">
                  <div className="col-md-9">
                    <div className="card card-primary p-2 mb-0">
                      <div
                        className="card-header d-flex"
                        css={{
                          color: 'black !important',
                          backgroundColor: 'transparent !important',
                          justifyContent: 'space-between',
                          '&::after': {
                            display: 'none',
                          },
                        }}>
                        <h3 className="card-title">DME USER INFORMATION</h3>
                        <SwitchButton
                          onlabel="Active"
                          offlabel="Inactive"
                          width={80}
                          size="md"
                          checked={values.active}
                          onChange={(checked) => setFieldValue('active', checked)}
                        />
                      </div>
                      <div className={`container bg-white mb-3 px-1 p-0`}>
                        <div className={`container pt-3 pb-2`}>
                          <div className="row mb-3">
                            <div className="col-6">
                              <label className="text-gray">
                                Company <span className="text-danger">*</span>
                              </label>
                              <Select
                                isClearable
                                name="companyId"
                                value={companyOptions?.find(
                                  (c) => c?.value == officeValues.companyId,
                                )}
                                options={companyOptions}
                                onInputChange={(v) => v && setCompanySearch(v)}
                                onChange={(event) => {
                                  console.log(event)
                                  setOfficeValues((p) => ({
                                    ...p,
                                    primaryOfficeId: '',
                                    addlOfficeId: '',
                                    companyId: event?.value,
                                  }))
                                }}
                              />
                            </div>
                            <div className="col-6">
                              <label className="text-gray">
                                Primary Office <span className="text-danger">*</span>
                              </label>
                              <Select
                                isClearable
                                name="primaryOfficeId"
                                value={
                                  officesOptions.find(
                                    (c) => c?.value == officeValues.primaryOfficeId,
                                  ) ?? null
                                }
                                options={officesOptions}
                                onChange={(event) => {
                                  const val = event?.value
                                  setOfficeValues((p) => ({ ...p, primaryOfficeId: val }))
                                  setFieldValue('primaryOfficeId', val)
                                }}
                              />
                              {touched.primaryOfficeId && errors.primaryOfficeId && (
                                <div className="text-danger">Required</div>
                              )}
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-6 form-group textGray">
                              <label htmlFor="firstName">
                                First Name
                                <span className="text-danger">*</span>
                              </label>
                              <Field
                                type="text"
                                name="firstName"
                                className="form-control"
                                placeholder="First Name"
                                value={values.firstName}
                                onChange={handleChange}
                                autoComplete="one-time-code"
                              />
                              {touched.firstName && errors.firstName && (
                                <div className="text-danger">{errors.firstName}</div>
                              )}
                            </div>
                            <div className="col-6 form-group textGray">
                              <label htmlFor="lastName">
                                Last Name
                                <span className="text-danger">*</span>
                              </label>
                              <Field
                                type="text"
                                name="lastName"
                                className="form-control"
                                placeholder="Last Name"
                                value={values.lastName}
                                onChange={handleChange}
                                autoComplete="one-time-code"
                              />
                              {touched.lastName && errors.lastName && (
                                <div className="text-danger">{errors.lastName}</div>
                              )}
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-6 form-group textGray">
                              <label htmlFor="officePhone">Office Number</label>
                              <MaskInput
                                value={values.officePhone}
                                onChange={handleChange}
                                name="officePhone"
                                mask={phoneNumberMask}
                                placeholder="(###) ###-####"
                                autoComplete="off"
                              />
                              {touched.officePhone && errors.officePhone && (
                                <div className="text-danger">{errors.officePhone}</div>
                              )}
                            </div>
                            <div className="col-6 form-group textGray">
                              <label htmlFor="directPhone">Mobile Number</label>
                              <MaskInput
                                value={values.directPhone}
                                onChange={handleChange}
                                name="directPhone"
                                mask={phoneNumberMask}
                                placeholder="(###) ###-####"
                                autoComplete="off"
                              />

                              {touched.directPhone && errors.directPhone && (
                                <div className="text-danger">{errors.directPhone}</div>
                              )}
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-6 form-group textGray">
                              <label htmlFor="email">Email</label>
                              <span className="text-danger">*</span>
                              <Field
                                type="text"
                                name="email"
                                className="form-control"
                                placeholder="Email"
                                value={values.email}
                                onChange={handleChange}
                              />
                              {touched.email && errors.email && (
                                <div className="text-danger">{errors.email}</div>
                              )}
                            </div>
                            <div className="col-6 form-group textGray">
                              <label htmlFor="role">Role</label>
                              <Field
                                className="form-control"
                                as="select"
                                name="role"
                                placeholder="Role"
                                onChange={handleRoleChange(values, setValues)}>
                                <option value="">Select Role</option>
                                {DME_ROLES.map((s) => (
                                  <option key={s} value={s}>
                                    {startCase(s.replace(/_/g, ' ').toLowerCase())}
                                  </option>
                                ))}
                              </Field>
                            </div>
                          </div>
                        </div>
                        {/* <div className="col-sm-12 pt-3 pr-0 border-top">
                          <div className="col-sm-12 d-flex justify-content-end">
                            <Button variant="primary">Save Changes</Button>
                          </div>
                        </div>*/}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 mb-3">
                    <div className="card card-primary p-2">
                      <div
                        className="card-header"
                        css={{
                          color: 'black !important',
                          backgroundColor: 'transparent !important',
                        }}>
                        <h3 className="card-title">ADMIN PRIVILEGES</h3>
                      </div>
                      <div className="card-body">
                        <div className="pb-2 mt-1">Primary Office</div>
                        <SwitchButton
                          onlabel="ON"
                          offlabel="OFF"
                          width={80}
                          size="md"
                          checked={values.primaryOfficeAdmin}
                          onChange={(checked) => {
                            setFieldValue('primaryOfficeAdmin', checked)
                            if (checked) {
                              setFieldValue('assignedOfficesAdmin', false)
                              setFieldValue('companyAdmin', false)
                            }
                          }}
                        />

                        <div className="pb-2 mt-3">All Assigned Offices</div>
                        <SwitchButton
                          onlabel="ON"
                          offlabel="OFF"
                          width={80}
                          size="md"
                          checked={values.assignedOfficesAdmin}
                          onChange={(checked) => {
                            setFieldValue('assignedOfficesAdmin', checked)
                            if (checked) {
                              setFieldValue('primaryOfficeAdmin', false)
                              setFieldValue('companyAdmin', false)
                            }
                          }}
                        />

                        <div className="pb-2 mt-3">Company</div>
                        <SwitchButton
                          onlabel="ON"
                          offlabel="OFF"
                          width={80}
                          size="md"
                          checked={values.companyAdmin}
                          onChange={(checked) => {
                            setFieldValue('companyAdmin', checked)
                            if (checked) {
                              setFieldValue('primaryOfficeAdmin', false)
                              setFieldValue('assignedOfficesAdmin', false)
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body row">
                  <div className="col-md-9 mb-3">
                    <div className="card card-primary p-2">
                      <div
                        className="card-header"
                        css={{
                          color: 'black !important',
                          backgroundColor: 'transparent !important',
                        }}>
                        <h3 className="card-title">ADDITIONAL OFFICES</h3>
                      </div>
                      <div className="card-body">
                        <div className="row mb-3">
                          <div className="col-6">
                            <div className="d-flex align-items-end">
                              <div css={{ flex: 1, marginRight: 8 }}>
                                <label className="text-gray">Additional Offices</label>
                                <Select
                                  isClearable
                                  name="addlOfficeId"
                                  value={
                                    officesOptions.find(
                                      (c) => c?.value == officeValues.addlOfficeId,
                                    ) ?? null
                                  }
                                  options={officesOptions.filter(
                                    (o) =>
                                      o.value !== officeValues.primaryOfficeId &&
                                      !addlOffices.find((a) => a.value == o.value),
                                  )}
                                  onChange={(event) => {
                                    setOfficeValues((p) => ({ ...p, addlOfficeId: event?.value }))
                                  }}
                                />
                              </div>
                              <Button
                                variant="primary"
                                disabled={!officeValues.addlOfficeId}
                                onClick={() => {
                                  setAddlOffices((p) => [
                                    ...p,
                                    officesOptions?.find(
                                      (o) => o.value == officeValues.addlOfficeId,
                                    ),
                                  ])
                                  setOfficeValues((p) => ({ ...p, addlOfficeId: null }))
                                }}>
                                Add Office
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div
                          className="row flex-row flex-nowrap justify-content-between align-items-center p-2 my-2"
                          style={{
                            color: '#6D757E',
                            borderBottom: '1px solid #ced4da',
                          }}>
                          <div style={{ width: '100px' }}>Office ID</div>
                          <div style={{ width: '100%' }}>Office</div>
                          <div
                            className="flex-shrink-0 pr-3"
                            style={{ width: 100, textAlign: 'end' }}>
                            Action
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-12">
                            {!addlOffices?.length && (
                              <div className="row my-1">
                                <div className="col-sm-12 p-0">
                                  <div className="list-group text-secondary">
                                    <div className="list-group-item list-group-item-action flex-column align-items-center">
                                      <div className="d-flex py-1 justify-content-center align-items-center">
                                        <span>No Additional Offices Selected</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            {addlOffices?.map((office) => (
                              <div className="row my-1" key={office?.value}>
                                <div className="col-sm-12 p-0">
                                  <div className="list-group text-secondary">
                                    <div className="list-group-item list-group-item-action flex-column align-items-start">
                                      <div className="d-flex py-1 justify-content-between align-items-center">
                                        <span css={{ width: '70px' }}>{office?.value}</span>
                                        <span css={{ flex: 1 }}>{office?.label}</span>
                                        <Button
                                          variant="primary"
                                          className="ml-2"
                                          css={{ minWidth: '60px' }}
                                          size="sm"
                                          onClick={() =>
                                            confirm('office')
                                              .then(() => {
                                                setAddlOffices((p) =>
                                                  p.filter((o) => o.value !== office.value),
                                                )
                                              })
                                              .catch(() => {})
                                          }>
                                          Remove
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <NotificationPreferences />
              </Form>
            )}
          </Formik>
        }
      />
    </div>
  )
}

export default UserUpsert

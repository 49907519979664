/** @jsxImportSource @emotion/react */
import { useEffect, useState, useRef } from 'react'
import { createEmbeddingContext, EmbeddingContext } from 'amazon-quicksight-embedding-sdk'

import { useLazyGetQuickSightEmbedUrlQuery } from 'store/services/managementReports'
import { Button } from 'react-bootstrap'

export const Analytics = () => {
  const [dashboardId, setDashboardId] = useState<string>('ce7df084-4a25-492e-be29-d09d717d06de')
  const ecRef = useRef<EmbeddingContext>()
  const [getEmbedUrl] = useLazyGetQuickSightEmbedUrlQuery()

  useEffect(() => {
    const handleDashboardChange = async (id: string) => {
      document.querySelectorAll('iframe').forEach((iframe) => iframe.remove())
      const { url } = await getEmbedUrl({ dashboardId: id, userType: 'LAB' }).unwrap()

      if (!ecRef.current) {
        ecRef.current = await createEmbeddingContext()
      }
      const container = document.getElementById('quicksight')
      if (!container) return
      console.log('embedding dashboard', url)
      ecRef.current.embedDashboard({
        url,
        container,
        height: '100%',
        width: '100%',
      })
    }

    handleDashboardChange(dashboardId)
  }, [dashboardId])

  return (
    <div css={{ height: '100vh' }}>
      <div className="d-flex justify-content-center align-items-center">
        <Button
          className="mr-2"
          onClick={() => setDashboardId('ce7df084-4a25-492e-be29-d09d717d06de')}>
          Office Orders Analysis
        </Button>
        <Button
          className="mr-2"
          onClick={() => setDashboardId('9ea960bf-2320-482a-a7e0-f132c22df35a')}>
          Site Overview Dashboard
        </Button>
        <Button onClick={() => setDashboardId('9d39526b-c17c-4099-859d-e341ca320f68')}>
          Referring Physician Analysis
        </Button>
      </div>

      <div id="quicksight" style={{ height: 'calc(100vh - 50px' }} />
    </div>
  )
}

export default Analytics

/** @jsxImportSource @emotion/react */
import Button from 'react-bootstrap/Button'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { CircularProgress } from '@mui/material'

import { SignaturePad } from './SignaturePad'
import { HstPatientPaperworkDto, Order } from 'store/types'
import { useMemo } from 'react'
import { tsOfficePhone } from 'constants/misc'

interface IProps {
  order: Order
  finishPaperwork: (data: any) => void
  savePatientPaperwork: (data: Partial<HstPatientPaperworkDto>) => Promise<void>
}

const formValidation = Yup.object().shape({
  signature: Yup.string().required('Required'),
})

const SignatureRequests = ({ finishPaperwork, order, savePatientPaperwork }: IProps) => {
  const initialValues = useMemo(() => ({ signature: '' }), [order])

  return (
    <div className="container" style={{ maxWidth: 720 }}>
      <div className="mb-3 mt-3">
        <h2>No Test Data Policy</h2>
        <p>
          In the situation where we have facilitated the service of providing testing equipment to
          you and after receiving the equipment, you choose not to test, you have therefore delayed
          testing for other ready and willing Patients. To reiterate, please test consecutively for
          a total of two nights beginning the night you receive the testing equipment. When you have
          finished your second night of testing, please return it to us. Once the testing equipment
          has been returned to TestSmarter and it is determined the equipment was unused and test
          data isn’t present, then you will be responsible for a $100.00 No Test Data Fee.
        </p>
        <h2>Late Fee Policy</h2>
        <p>
          To reiterate, please test consecutively for a total of two nights beginning the night you
          receive the testing equipment. When you have finished your second night of testing, please
          return it to us. Testing equipment should not be kept past one week after receiving it,
          especially without notifying our office staff of the delay in testing. If after one week
          the equipment has not been returned and multiple attempts to contact you have been
          unsuccessful, then you will be responsible for a $25.00 weekly late fee, which will be
          billed to your account on file. The $25.00 weekly late fee will continue to be billed
          until the equipment has been returned to TestSmarter.
        </p>
        <h2>Text Message Consent</h2>
        <p>
          As a patient of TestSmarter, you consent to receive text messages regarding, appointment
          scheduling, appointment reminders, documentation requests, statement and billing delivery,
          and follow-up. You can opt out of receiving our text messages at any time by making the
          appropriate reply to those text messages. If a mobile number is not available, then text
          messages will not be sent. Message and data fees may apply.
        </p>
        <h2>Equipment Responsibility Policy</h2>
        <p>
          <strong>
            The 2021 Florida Statutes: Title XLVI, Chapter 812, Section 812.155: Subsection 3:
            FAILURE TO RETURN HIRED OR LEASED PERSONAL PROPERTY.—{' '}
          </strong>
          Whoever, after hiring or leasing personal property or equipment under an agreement to
          return the personal property to the person letting the personal property or equipment or
          his or her agent at the termination of the period for which it was let, shall, without the
          consent of the person or persons knowingly abandon or refuse to return the personal
          property or equipment as agreed, commits a misdemeanor of the second degree, punishable as
          provided in s. 775.082 or s. 775.083, unless the value of the personal property or
          equipment is of a value of $300 or more; in that case the person commits a felony of the
          third degree, punishable as provided in s. 775.082, s. 775.083, or s. 775.084.{' '}
          <strong>Subsection 4, Part B & C: EVIDENCE.—</strong> In a prosecution under subsection
          (3), failure to redeliver the property or equipment within 5 days after receiving the
          demand for return from a courier service with tracking capability or by certified mail,
          return receipt requested, or within 5 days after delivery by the courier service or return
          receipt from the certified mailing of the demand for return, is prima facie evidence of
          abandonment or refusal to redeliver the property or equipment. Notice mailed by certified
          mail, return receipt requested, or delivery by courier with tracking capability to the
          address given by the renter at the time of rental is sufficient and equivalent to notice
          having been received by the renter, should the notice be returned undelivered. In a
          prosecution under subsection (3), failure to pay any amount due which is incurred as the
          result of the failure to redeliver property or equipment after the rental period expires,
          and after the demand for return is made, is prima facie evidence of abandonment or refusal
          to redeliver the property or equipment. Amounts due include unpaid rental for the time
          period during which the property or equipment was not returned and include the lesser of
          the cost of repairing or replacing the property or equipment if it has been damaged.
          <strong> Subsection 5: DEMAND FOR RETURN.—</strong> Demand for return of overdue property
          or equipment and for payment of amounts due may be made in person, by hand delivery, by
          certified mail, return receipt requested, or by courier service with tracking capability,
          addressed to the lessee's address shown in the rental contract. Failure to return rental
          property or equipment upon expiration of the rental period and failure to pay all amounts
          due (including costs for damage to the property or equipment) are evidence of abandonment
          or refusal to redeliver the property, punishable in accordance with section 812.155,
          Florida Statutes.
        </p>
        <h2>Account on File Authorization and Agreement</h2>
        <p>
          <strong>
            Effective May 1, 2022, we now require a credit card to be on file with our office for
            every patient, even if you do not owe a copay.{' '}
          </strong>
          I agree to allow TestSmarter to keep my account on file and to charge my account for any
          outstanding balance on my patient account. This includes co-pays, deductibles,
          co-insurance, non-covered services, late fees, unreturned equipment, no test data fees,
          and cancellation fees. I acknowledge that:
          <br />
          My account will be charged 30 days after the first statement is sent following review of
          the final explanation of benefits from each applicable insurance company for services
          provided while this agreement is in effect. If my account is declined, TestSmarter will
          bill me directly for any outstanding balance. I acknowledge that I will not be able to
          schedule any future appointments with TestSmarter until the balance has been paid in full.
          If the balance is not paid, I understand that my account may be sent to a collection’s
          agency and that I and my family members may be discharged from the practice. I am
          responsible for informing TestSmarter of any updates regarding my insurance and account
          information. I may request a receipt detailing the amount charged. I may cancel this
          agreement at any time by contacting TestSmarter; any unpaid amounts relating to service
          provided while this agreement is in effect will then be billed to me directly.
        </p>
        <p>
          <strong>
            If you are more comfortable providing this information verbally, please call our office
            phone number {tsOfficePhone}. This Authorization Agreement still must be signed.
          </strong>
        </p>
        <Formik
          enableReinitialize={true}
          validationSchema={formValidation}
          initialValues={initialValues}
          onSubmit={async (values) => {
            try {
              await savePatientPaperwork({
                orderId: Number(order.id),
                meta: { completedStep: 'signatureRequests' },
                data: {},
              })
              await finishPaperwork({
                orderId: Number(order.id),
                signature: values.signature,
              })
              window.location.href = order?.hst?.paymentSession?.url
            } catch (err) {}
          }}>
          {({ isSubmitting, errors, touched, setFieldValue }) => (
            <Form id="signature">
              <SignaturePad touched={touched} setFieldValue={setFieldValue} errors={errors} />
              <div css={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={isSubmitting}
                  css={{ minWidth: 65 }}>
                  {isSubmitting ? <CircularProgress size={24} sx={{ color: 'white' }} /> : 'Submit'}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default SignatureRequests

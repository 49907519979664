import { useEffect, useState, useMemo, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { startCase } from 'lodash'
import styled from '@emotion/styled'
import { useAppSelector } from 'hooks/redux'

import DashboardContent from '../atoms/DashboardContent'
import Link from '../../atoms/Link'
import routes from '../constants/routes'
import * as generalHelper from '../../../helpers/generalHelper'
import DataTable from 'components/atoms/DataTable'
import OrderTableColumns from './OrderTableColumns'
import { useGetOrdersQuery } from 'store/services/dmeOrder'
import { COMPOUND_BUCKETS, COMPOUND_BUCKET_MAP } from '../constants/orderStatus'

const TableWrapper = styled.div`
  overflow-y: auto;
  width: 100%;
  max-height: calc(100vh - 240px);
  padding: 0 10px 10px 10px;

  Table {
    thead:not(.table_filters) {
      background: white;
      position: sticky;
      top: 0px;
      margin: 0 0 0 0;
      z-index: 1;
    }
    td {
      vertical-align: middle;
    }
  }
`
const ROWS_PER_PAGE = 12

const pageTitle = {
  HST: 'Home Sleep Test',
  PSG: 'In-Lab Sleep Test',
  POX: 'Pulse Oximetry',
  COX: 'Capnography',
}

const Order = () => {
  const queryString = {}
  const [search, setSearch] = useSearchParams()
  search.forEach((v: string, key: string) => (queryString[key] = v))
  const { testType, bucket, sort, page } = queryString
  const [searchFilter, setSearchFilter] = useState('')
  const [currentPage, setCurrentPage] = useState(page || 0)
  const [pageSize, setPageSize] = useState(ROWS_PER_PAGE)
  const [sortOptions, setSortOptions] = useState(
    sort ? { field: sort.split('_')[0], order: sort.split('_')[1] } : {},
  )
  const officeId = useAppSelector((state) => state.init.currentOfficeId)
  const effectCount = useRef(0)
  const bucketName = startCase(bucket?.replace(/_/g, ' ')?.toLowerCase())
  // console.log('>>>>>>>>>', queryString, testType, bucket)

  const hiddenCols = ['testType']
  const showStatus: boolean = useMemo(() => COMPOUND_BUCKETS.includes(bucket), [bucket])

  if (!showStatus) {
    hiddenCols.push('status')
  }
  if (bucket !== 'PROCESSING') {
    hiddenCols.push('labProgress')
  }
  if (bucket === 'COMPLETED') {
    hiddenCols.push('dateOrdered')
  }
  if (bucket === 'OPEN') {
    hiddenCols.push('deviceAssignedAt')
  }
  if (testType === 'HST') {
    hiddenCols.push('tests')
    hiddenCols.push('deviceAssignedAt')
  }
  if (testType !== 'PSG') {
    hiddenCols.push('procedure')
  }

  const bucketFilter = COMPOUND_BUCKETS.includes(bucket) ? COMPOUND_BUCKET_MAP[bucket] : bucket

  const {
    data: orders,
    isLoading,
    refetch: refetchOrder,
  } = useGetOrdersQuery(
    {
      officeId: Number(officeId),
      type: testType,
      buckets: bucketFilter,
      ...(searchFilter?.length && { search: searchFilter }),
      page: currentPage,
      size: pageSize,
      sortField: queryString?.field,
      sortOrder:
        queryString?.field === 'deviceAssignedAt'
          ? queryString?.order === 'asc'
            ? 'desc'
            : 'asc'
          : queryString?.order,
    },
    {
      pollingInterval: 60000,
      refetchOnFocus: true,
    },
  )

  useEffect(() => {
    setCurrentPage(0)
    refetchOrder()
  }, [searchFilter])

  useEffect(() => {
    if (sortOptions?.id) {
      const newOptions = {
        field: sortOptions.id,
        order: sortOptions?.desc ? 'desc' : 'asc',
      }
      setSearch({ ...queryString, ...newOptions })
    } else {
      if (queryString.field || queryString.order) {
        delete queryString.field
        delete queryString.order
        setSearch({ ...queryString })
      }
    }
  }, [sortOptions])

  useEffect(() => {
    if (effectCount.current > 0) {
      setSearchFilter('')
      setCurrentPage(0)
    }
    effectCount.current++
  }, [bucket])

  useEffect(() => {
    search.set('page', currentPage.toString())
    setSearch(search)
  }, [currentPage, search, setSearch])

  const titleActionAddNewOrder = (
    <Link
      className="btn btn-primary text-nowrap"
      type="button"
      to={`${routes.index}${routes.order.newOrder}`}>
      {generalHelper.t('Add Order')}
    </Link>
  )

  return (
    <div>
      <DashboardContent
        title={pageTitle[testType] ?? ''}
        icon="fas fa-folder-open"
        titleActionButton={titleActionAddNewOrder}
        fluidWidth
        content={
          <div className="card card-primary">
            <div className="card-body row">
              <TableWrapper>
                <DataTable
                  data={orders}
                  status={isLoading}
                  tableColumns={OrderTableColumns(hiddenCols, testType)}
                  setSearchFilter={setSearchFilter}
                  searchFilter={searchFilter}
                  setCurrentPage={setCurrentPage}
                  setPageSize={setPageSize}
                  pageSize={pageSize}
                  setSortOptions={setSortOptions}
                />
              </TableWrapper>
            </div>
          </div>
        }
        breadcrumb={[
          { label: 'Home', to: `${routes.index}` },
          {
            label: `${testType ? `${testType + ' /'}` : ''} ${bucketName}`,
          },
        ]}
        subtitle={bucketName}
      />
    </div>
  )
}
export default Order

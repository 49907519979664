import { baseApi } from './baseApi'
import { Patient } from 'store/types'
import {
  PatientsList,
  PatientsListQuery,
} from 'ts-back-end/src/business/patient/dto/patient-list.dto'

export const patientApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getPatients: builder.query<PatientsList, { [key: string]: string | number }>({
      query: (query) => ({ url: `/dme/patients`, params: query }),
    }),
    getPatient: builder.query<Patient, { id: number }>({
      query: ({ id }) => ({ url: `/dme/patients/${id}` }),
    }),
    updatePatient: builder.mutation<Patient, Partial<Patient>>({
      query: (data) => ({
        method: `PATCH`,
        url: `/dme/patients/${data.id}`,
        body: data,
      }),
      invalidatesTags: (result, error, { id }) => ['Order', 'Patient'],
    }),
    getLabPatientList: builder.query<PatientsList, PatientsListQuery>({
      query: (query) => ({ url: `/lab/patients/list`, params: query }),
      providesTags: ['Patient'],
    }),
    getLabPatient: builder.query<Patient, { id: number }>({
      query: ({ id }) => ({ url: `/lab/patients/${id}` }),
      providesTags: (result, error, { id }) => [{ type: 'Patient', id }],
    }),
  }),
})

export const {
  useGetPatientsQuery,
  useUpdatePatientMutation,
  useGetPatientQuery,
  useGetLabPatientListQuery,
  useLazyGetLabPatientListQuery,
  useGetLabPatientQuery,
} = patientApi

const Nonin7500 = () => {
  return (
    <ul className="text-left">
      <li>
        <span>1.</span> Be sure the Nonin 7500 cable is connected securely to
        your computer.
      </li>
      <li className="highlight">
        <span>2.</span> <small>IMPORTANT:</small> Press the button below to
        begin download <strong>before</strong> powering on the device.
      </li>
      <li>
        <span>3.</span> Press <strong>both</strong> the{' '}
        <i className="fa fa-power-off"></i> and <i className="fa fa-plus"></i>{' '}
        buttons on the oximeter at the same time until the unit powers on.
      </li>
    </ul>
  )
}

export default Nonin7500

import { hasKey } from 'helpers/typescript'

const env = process.env.REACT_APP_ENV ?? 'development'

const config = {
  // Local
  local: {
    api: process.env.REACT_APP_API_URL ?? 'http://localhost:8000',
    auth0: {
      authRedirectUri: process.env.REACT_APP_AUTH0_AUTH_REDIRECT_URI ?? 'http://localhost:5001/login/callback',
      clientId: process.env.REACT_APP_AUTH0_CLIENT_ID ?? '290aJGGivqugc8biewl9tAqJYuunPdSz',
      domain: process.env.REACT_APP_AUTH0_DOMAIN ?? 'auth-dev.testsmarter.net',
    },
    newApi: process.env.REACT_APP_NEW_API_URL ?? 'http://localhost:3001',
    sentryDsn:
      process.env.REACT_APP_SENTRY_DSN ??
      'https://3abef1a34ce6d69bb7e9a6f96f65211b@o473585.ingest.sentry.io/4506380507414528',
    ws: 'ws://localhost:1112',
    googleApiKey: process.env.REACT_APP_GOOGLE_API ?? 'AIzaSyArQqFGNER9G_D13S5croNiKemM6F8v3OE',
    testSmarterFaxNumber: process.env.REACT_APP_TS_FAX_NUMBER ?? '(386) 382-4201',
    stripeCustomerUrl:
      process.env.REACT_APP_STRIPE_CUSTOMER_URL ?? 'https://dashboard.stripe.com/test/customers/',
  },

  // Development
  development: {
    api: 'https://dev.tsmrtr.pro',
    auth0: {
      authRedirectUri: process.env.REACT_APP_AUTH0_AUTH_REDIRECT_URI ?? 'https://develop.tsmrtr.pro/login/callback',
      clientId: process.env.REACT_APP_AUTH0_CLIENT_ID ?? '290aJGGivqugc8biewl9tAqJYuunPdSz',
      domain: process.env.REACT_APP_AUTH0_DOMAIN ?? 'auth-dev.testsmarter.net',
    },
    newApi: process.env.REACT_APP_NEW_API_URL ?? 'https://api.tsmrtr.pro',
    sentryDsn:
      process.env.REACT_APP_SENTRY_DSN ??
      'https://3abef1a34ce6d69bb7e9a6f96f65211b@o473585.ingest.sentry.io/4506380507414528',
    ws: 'ws://localhost:1112',
    googleApiKey: process.env.REACT_APP_GOOGLE_API ?? 'AIzaSyArQqFGNER9G_D13S5croNiKemM6F8v3OE',
    testSmarterFaxNumber: process.env.REACT_APP_TS_FAX_NUMBER ?? '(386) 382-4201',
    stripeCustomerUrl:
      process.env.REACT_APP_STRIPE_CUSTOMER_URL ?? 'https://dashboard.stripe.com/test/customers/',
  },

  // Testing
  staging: {
    api: 'https://test.tsmrtr.pro',
    auth0: {
      authRedirectUri: process.env.REACT_APP_AUTH0_AUTH_REDIRECT_URI ?? 'https://staging.testsmarter.net/login/callback',
      clientId: process.env.REACT_APP_AUTH0_CLIENT_ID ?? 'mR2lDGGqVtFKFUlMFQugDlgZKzLEVfjz',
      domain: process.env.REACT_APP_AUTH0_DOMAIN ?? 'auth-staging.testsmarter.net',
    },
    newApi: process.env.REACT_APP_NEW_API_URL ?? 'https://api-staging.testsmarter.net',
    sentryDsn:
      process.env.REACT_APP_SENTRY_DSN ??
      'https://3abef1a34ce6d69bb7e9a6f96f65211b@o473585.ingest.sentry.io/4506380507414528',
    ws: 'ws://localhost:1112',
    googleApiKey: process.env.REACT_APP_GOOGLE_API ?? 'AIzaSyArQqFGNER9G_D13S5croNiKemM6F8v3OE',
    testSmarterFaxNumber: process.env.REACT_APP_TS_FAX_NUMBER ?? '(386) 382-4201',
    stripeCustomerUrl:
      process.env.REACT_APP_STRIPE_CUSTOMER_URL ?? 'https://dashboard.stripe.com/test/customers/',
  },

  // Production
  production: {
    api: 'https://www.tsmrtr.pro',
    auth0: {
      authRedirectUri: process.env.REACT_APP_AUTH0_AUTH_REDIRECT_URI ?? 'https://app.testsmarter.net/login/callback',
      clientId: process.env.REACT_APP_AUTH0_CLIENT_ID ?? 'sDgDQuyW9BKkpC7JgRTjOSZ6KS1r2o7C',
      domain: process.env.REACT_APP_AUTH0_DOMAIN ?? 'auth.testsmarter.net',
    },
    newApi: process.env.REACT_APP_NEW_API_URL ?? 'https://api-prod.testsmarter.net',
    sentryDsn:
      process.env.REACT_APP_SENTRY_DSN ??
      'https://96e107afbaaa3c1542a3c998c05672b6@o473585.ingest.sentry.io/4506378692263936',
    ws: 'ws://localhost:1112',
    googleApiKey: process.env.REACT_APP_GOOGLE_API ?? 'AIzaSyArQqFGNER9G_D13S5croNiKemM6F8v3OE',
    testSmarterFaxNumber: process.env.REACT_APP_TS_FAX_NUMBER ?? '(386) 382-4201',
    stripeCustomerUrl:
      process.env.REACT_APP_STRIPE_CUSTOMER_URL ?? 'https://dashboard.stripe.com/test/customers/',
  },
}

export default (() => {
  if (hasKey(config, env)) {
    return config[env]
  }
})()

import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DashboardContent from '../atoms/DashboardContent'
import TitleLead from '../../atoms/TitleLead'
import routes from '../constants/routes'
import { handleChange } from '../../atoms/Upsert'
import Button from '../../atoms/Button'
import ButtonModal from '../../atoms/ButtonModal'
import * as generalHelper from '../../../helpers/generalHelper'
import { Navigate, useParams } from 'react-router-dom'
import { getDevice } from '../../../store/slice/deviceSlice'
import MsgsNotes from '../orderEdit/views/MsgsNotes'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { setModal } from '../../../store/slice/modalSlice'

const DeviceDetail = props => {
  let { device_id } = useParams()
  const dispatch = useDispatch()
  const device = useSelector(state => state.device.row)
  const [redirect, setRedirect] = useState(false)
  const [sideTab, setSideTab] = useState('msgs')
  const status = useSelector(state => state.init.status)

  const getDeviceParams = {
    id: device_id,
    no_cache: 1,
    add_join: 'order.patient|device_log.order.patient|brand',
  }

  const tickets = useSelector(state => state.ticket.rows)
  const notes = useSelector(state => state.orderNote.data)
  const status_retired = generalHelper.getStatus(status, 'Device', 'In Repair')
  const status_damaged = generalHelper.getStatus(
    status,
    'Device',
    'Damaged (Bill to DME)'
  )

  useEffect(() => {
    dispatch(getDevice(getDeviceParams))
  }, [])

  const dayClass = (order, isDeviceAt) => {
    let diff = generalHelper.dateDiff(
      isDeviceAt ? order?.device_at : order?.created_at,
      'today'
    )
    if (diff > 10) {
      return '-danger'
    } else if (diff > 4) {
      return '-warning'
    } else {
      return '-success'
    }
  }

  const getTimeStamp = timestamp => {
    return (
      <>
        <div>{moment(timestamp).format('hh:mm:ss a')}</div>
        <div>{moment(timestamp).format('MMMM Do, YYYY')}</div>
      </>
    )
  }

  const getDeviceStatus = status_id => {
    let status = ''
    switch (status_id) {
      case 25:
        status = 'Assigned'
        break
      case 26:
        status = 'Unassigned'
        break
      default:
        status = 'Device Note'
        break
    }

    return status
  }

  const getBreadCrumb = () => {
    let breadcrumbTo = `${routes.index}${routes.device.deviceDirectory}1`
    if (
      device?.status_id === status_retired?.id ||
      device?.status_id === status_damaged?.id
    ) {
      breadcrumbTo = `${routes.index}${routes.device.deviceDirectory}2`
    }
    return breadcrumbTo
  }

  const handleActiveOrderTitle = () => {
    let title = 'Device Assignment'
    if (
      device.order &&
      device.order?.length > 0 &&
      device?.status_id !== status_retired?.id &&
      device?.status_id !== status_damaged?.id
    ) {
      title = 'Active Order'
    }

    return title
  }

  const deviceHeaderStatus = () => {
    if (device.status_id === status_retired?.id) {
      return (
        <div className="w-100 h-100 d-flex bg-dark justify-content-center align-items-center text-bold ">
          <span>{generalHelper.t('Retired').toUpperCase()}</span>
        </div>
      )
    } else if (device.status_id === status_damaged?.id) {
      return (
        <div className="w-100 h-100 d-flex bg-warning justify-content-center align-items-center text-bold ">
          <span>{generalHelper.t('Damaged').toUpperCase()}</span>
        </div>
      )
    } else if (device.order && device.order?.length > 0) {
      return (
        <div
          className={`w-100 h-100 d-flex flex-column justify-content-center align-items-center text-bold bg${dayClass(
            device.order[0],
            true
          )}`}
        >
          <div
            className={` w-75 text-center border border${dayClass(
              device.order[0],
              true
            )} bg-white`}
          >
            <span>{generalHelper.t('Assigned').toUpperCase()}</span>
          </div>
          <div className={`bg${dayClass(device.order[0], true)}`}>
            {generalHelper.dateDiff(device.order[0]?.device_at, 'today')}{' '}
            {generalHelper.t('Days')}
          </div>
        </div>
      )
    } else {
      return (
        <div className="w-100 h-100 d-flex bg-success justify-content-center align-items-center text-bold">
          <span>{generalHelper.t('Available').toUpperCase()}</span>
        </div>
      )
    }
  }

  const orderAging = order => {
    return (
      <div className="d-flex">
        <Link
          className="border border-primary rounded p-2 mr-1"
          to={`${routes.index}${routes.order.edit}${order?.id}`}
        >
          {`#${order?.id}`}
        </Link>
        <div className="d-flex flex-column ml-1">
          <div className="text-bold">
            {order?.patient?.last_name}, {order?.patient?.first_name}
          </div>
          {order?.patient?.dob && (
            <div>{moment(order?.patient?.dob).format('MM/DD/YYYY')}</div>
          )}
        </div>
        <div className="d-flex flex-column ml-1">
          {
            <div className={`btn btn-sm btn${dayClass(order)}`}>
              {generalHelper.dateDiff(order?.created_at, 'today')}{' '}
              {generalHelper.t('Days')}
            </div>
          }
        </div>
      </div>
    )
  }

  const deviceHistory = () => {
    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="table-responsive">
                <table className="table table-sm">
                  <thead>
                    <tr>
                      <th className="table-col font-weight-bold">
                        {generalHelper.t('Timestamp')}
                      </th>
                      <th className="table-col font-weight-bold">
                        {generalHelper.t('Order')}
                      </th>
                      <th className="table-col font-weight-bold">
                        {generalHelper.t('Notes')}
                      </th>
                      <th className="table-col font-weight-bold">
                        {generalHelper.t('Status')}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {device?.device_log &&
                      device.device_log?.length > 0 &&
                      device?.device_log.map((row, index) => {
                        return (
                          <tr key={index}>
                            <td>{getTimeStamp(row.created_at)}</td>
                            <td>
                              {row?.order &&
                                row?.order?.id &&
                                orderAging(row?.order)}
                            </td>
                            <td>{row.note}</td>
                            <td className="font-weight-bold">
                              {getDeviceStatus(row.status_id)}
                            </td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  const deviceActiveOrder = () => {
    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="table-responsive">
                <table className="table table-sm">
                  <thead>
                    <tr>
                      <th width="70%" className="table-col font-weight-bold">
                        {generalHelper.t('Order & Order Aging')}
                      </th>
                      <th
                        width="30%"
                        className="table-col text-right font-weight-bold"
                      >
                        {generalHelper.t('Remove From Order')}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{orderAging(device.order[0])}</td>
                      <td className="text-right">
                        <ButtonModal
                          label="Unassign"
                          className="btn btn-primary"
                          type="button"
                          data_toggle="modal"
                          data_target="#unassign_device_modal"
                          onClick={() => {
                            dispatch(
                              setModal({
                                open: true,
                                device: device,
                              })
                            )
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  const deviceViewContent = () => {
    return (
      <>
        {device?.consignment_id === 33 &&
          device?.status_id === status_damaged?.id && (
            <div className="container-fluid">
              <div className="card card-primary">
                <div className="card-body row">
                  <div className="col-sm-12 my-2">
                    <TitleLead
                      label={generalHelper
                        .t('Device Return Required')
                        .toUpperCase()}
                      className="lead"
                    />
                    <hr />
                  </div>
                  <div className="col-sm-12 my-2">
                    <div className="d-flex justify-content-center align-items-baseline text-bold font-italic">
                      <span className="textGray">
                        {generalHelper.t(
                          'This is a consigned testing device. Please fill out the following form and send to Testsmarter.'
                        )}
                      </span>
                      <a
                        className="btn btn-primary ml-2"
                        target="_blank"
                        href="https://testsmarter.s3.amazonaws.com/pdf/TS-Device-Return.pdf"
                        rel="noreferrer"
                      >
                        {generalHelper.t('Download Form')}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        {device?.status_id !== status_retired?.id &&
          device?.status_id !== status_damaged?.id && (
            <div className="container-fluid">
              <div className="card card-primary">
                <div className="card-body row">
                  <div className="col-sm-12 my-2">
                    <TitleLead
                      label={generalHelper
                        .t(handleActiveOrderTitle())
                        .toUpperCase()}
                      className="lead"
                    />
                    <hr />
                  </div>
                  {device.order &&
                    device.order?.length > 0 &&
                    device?.status_id !== status_retired?.id &&
                    device?.status_id !== status_damaged?.id &&
                    deviceActiveOrder()}
                  {device.order &&
                    device.order?.length === 0 &&
                    device?.status_id !== status_retired?.id &&
                    device?.status_id !== status_damaged?.id && (
                      <div className="col-sm-12 my-2">
                        <div className="d-flex justify-content-center align-items-baseline text-bold font-italic">
                          <span className="textGray">
                            {generalHelper.t('No Active Order')}
                          </span>

                          <ButtonModal
                            label="Assign Device"
                            className="btn btn-primary ml-2"
                            type="button"
                            data_toggle="modal"
                            data_target="#assign_device_modal"
                            onClick={() => {
                              dispatch(
                                setModal({
                                  open: true,
                                  device: device,
                                })
                              )
                            }}
                          />
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
          )}
        <div className="container-fluid">
          <div className="card card-primary">
            <div className="card-body row">
              <div className="col-sm-12 my-2">
                <TitleLead
                  label={generalHelper.t('Device History').toUpperCase()}
                  className="lead"
                />
                <hr />
              </div>
              {device?.device_log?.length > 0 && deviceHistory()}
              {device.device_log?.length === 0 && (
                <div className="col-sm-12 my-2">
                  <div className="d-flex justify-content-center align-items-baseline text-bold font-italic">
                    <span className="textGray">
                      {generalHelper.t('No Device History')}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    )
  }

  const deviceTitle = () => {
    return (
      <>
        <div className="d-flex align-items-center">
          <span>{`${device?.serial}`}</span>
          {device?.consignment_id === 33 && (
            <h6 className="mb-0">
              <span className="badge badge-secondary ml-2">
                {generalHelper.t('consigned').toUpperCase()}
              </span>
            </h6>
          )}
        </div>
      </>
    )
  }
  const deviceSubTitle = device?.brand?.name
    ? device?.brand?.name
    : 'Device Model'

  return (
    <div>
      {redirect && <Navigate to={redirect} />}
      <DashboardContent
        className="bg-primary"
        title={generalHelper.t('Device Management')}
        subtitle={deviceTitle()}
        subtitleExtra={deviceSubTitle}
        customHeaderColumn={deviceHeaderStatus()}
        deviceDropdown={true}
        extra={
          <MsgsNotes
            tickets={tickets}
            notes={notes}
            sideTab={sideTab}
            setSideTab={setSideTab}
            getOrderParams={getDeviceParams}
          />
        }
        content={deviceViewContent()}
        device={device}
        breadcrumb={[
          { label: 'Home', to: `${routes.index}` },
          {
            label: `${generalHelper.t('Device Management')}`,
          },
          {
            label: `${
              device?.status_id === 10
                ? 'Active Devices'
                : 'Retired & Damaged Devices'
            }`,
            to: `${routes.index}${routes.device.deviceDirectory}${
              device?.status_id === 10 ? '1' : '2'
            }`,
          },
          {
            label: `Device #${device_id}`,
            to: `${routes.index}${routes.device.deviceDetail}${device_id}`,
          },
        ]}
      />
    </div>
  )
}

export default DeviceDetail

import { useEffect, useState } from 'react'

interface IProps {
  seconds: number
}

export const CountDown = ({ seconds }: IProps) => {
  const [count, setCount] = useState(seconds)
  const queuedMins = Math.floor(count / 60)
  const queuedSecs = count % 60

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((c) => (c >= 1 ? c - 1 : 0))
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  return (
    <>
      Faxing report in {queuedMins}m {queuedSecs}s
    </>
  )
}

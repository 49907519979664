import { Order, HstDeviceKind } from 'store/types'

const reviewStates = ['PENDING_REVIEW', 'ON_REVIEW', 'WARNING', 'PROMISED', 'PROVIDED']

export const isInReview = (progress: string, helm: string) =>
  reviewStates.includes(progress) && ['DME_TO_LAB', 'LAB'].includes(helm)

export const isPatientValid = (order: Order) =>
  order?.state?.ORDER === 'READY_FOR_REVIEW' || order?.progress?.PATIENT === 'PROVIDED'

export const isPhysicianValid = (order: Order) =>
  order?.state?.ORDER === 'READY_FOR_REVIEW' || order?.progress?.PHYSICIAN === 'PROVIDED'

export const isPayerValid = (order: Order) =>
  order?.state?.ORDER === 'READY_FOR_REVIEW' || order?.progress?.PAYER === 'PROVIDED'

export const isRxProvidedOrPromised = (order: Order) =>
  order?.state?.ORDER === 'READY_FOR_REVIEW' ||
  ['PROVIDED', 'PROMISED', 'DONE'].includes(order?.state?.RX)

export const isOfficeNotesProvidedOrPromised = (order: Order) =>
  order?.state?.ORDER === 'READY_FOR_REVIEW' ||
  ['PROVIDED', 'PROMISED', 'DONE', 'DONE_RX'].includes(order?.state?.OFFICE_NOTES)

export const isRxPromised = (order: Order) => !!order?.promisedFiles?.RX
export const isOfficeNotesPromised = (order: Order) => !!order?.promisedFiles?.OFFICE_NOTES
export const isAobPromised = (order: Order) => !!order?.promisedFiles?.AOB

export const canOrderReviewStart = (order: Order) =>
  ['PATIENT', 'PHYSICIAN', 'PAYER', 'RX'].every((section) =>
    ['PROVIDED', 'PENDING_REVIEW', 'ON_REVIEW', 'DONE', 'WARNING'].includes(
      order?.progress?.[section],
    ),
  )

export const isAobUploaded = (order: Order) =>
  Object.values(order?.files || {}).some((file) => file.uploadType === 'AOB')

export const isAobVerified = (order: Order) => order?.state?.VERIFICATION?.RESULT?.AOB === 'DONE'
export const isAobFailed = (order: Order) => order?.state?.VERIFICATION?.RESULT?.AOB === 'WARNING'

export const isOnSecondTabGroup = (order: Order) =>
  order?.state?.ORDER !== 'READY_FOR_REVIEW' &&
  order?.state?.ORDER !== 'PRE_OPEN' &&
  !order?.state?.ORDER?.COLLECT_DATA

export const isReadyForReview = (order: Order) => order?.state?.ORDER === 'READY_FOR_REVIEW'

export const isAwaitingVerification = (order: Order) => order?.state?.VERIFICATION === 'PENDING'

export const isUnderVerification = (order: Order) =>
  order?.state?.VERIFICATION?.RESULT && order.state.VERIFICATION_HELM === 'LAB'

export const hasItemsToVerify = (order: Order) =>
  order?.state?.VERIFICATION?.RESULT &&
  Object.values(order?.state?.VERIFICATION?.RESULT).some(
    (i) => !['NOT_ELIGIBLE', 'DONE', 'NOT_REQUIRED'].includes(i),
  )

export const hasVerificationStarted = (order: Order) =>
  !!order?.state?.VERIFICATION?.RESULT?.PATIENT

export const isVerificationDone = (order: Order) => order?.state?.VERIFICATION === 'DONE'

export const isModifiable = (
  order: Order,
  section: 'PATIENT' | 'PHYSICIAN' | 'PAYER' | 'RX' | 'OFFICE_NOTES',
) =>
  (!order?.state?.VERIFICATION?.RESULT && !isVerificationDone(order)) ||
  (['LAB_TO_DME', 'DME', 'DME_TO_LAB'].includes(order.state.VERIFICATION_HELM) &&
    (section === 'PAYER'
      ? ['WARNING', 'FIXED', 'PENDING'].includes(order?.state?.VERIFICATION?.RESULT?.PAYER) ||
        ['WARNING', 'FIXED', 'PENDING'].includes(order?.state?.VERIFICATION?.RESULT?.PAYER_2) ||
        ['WARNING', 'FIXED', 'PENDING'].includes(order?.state?.VERIFICATION?.RESULT?.PAYER_3)
      : ['WARNING', 'FIXED', 'PENDING'].includes(order?.state?.VERIFICATION?.RESULT?.[section])))

export const hasFailedVerification = (order: Order) => {
  if (order?.state?.VERIFICATION?.RESULT) {
    return Object.values(order?.state?.VERIFICATION?.RESULT).some((i) => i === 'WARNING')
  }
}
export const isDmeMakingCorrections = (order: Order) =>
  ['DME', 'LAB_TO_DME'].includes(order?.state?.VERIFICATION_HELM)

export const canDmeResubmitForVerification = (order: Order) =>
  order?.state?.VERIFICATION?.RESULT?.PATIENT &&
  Object.values(order?.state?.VERIFICATION?.RESULT).some((i) => ['FIXED'].includes(i)) &&
  ['LAB_TO_DME', 'DME'].includes(order.state.VERIFICATION_HELM)

export const hasPatientFailedVerification = (order: Order) =>
  order?.state?.VERIFICATION?.RESULT?.PATIENT === 'WARNING'
export const hasPhysicianFailedVerification = (order: Order) =>
  order?.state?.VERIFICATION?.RESULT?.PHYSICIAN === 'WARNING'

export const hasPayer1FailedVerification = (order: Order) =>
  order?.state?.VERIFICATION?.RESULT?.PAYER === 'WARNING'
export const hasPayer2FailedVerification = (order: Order) =>
  order?.state?.VERIFICATION?.RESULT?.PAYER_2 === 'WARNING'
export const hasPayer3FailedVerification = (order: Order) =>
  order?.state?.VERIFICATION?.RESULT?.PAYER_3 === 'WARNING'

export const hasPayerFailedVerification = (order: Order) =>
  order?.state?.VERIFICATION?.RESULT?.PAYER === 'WARNING' ||
  order?.state?.VERIFICATION?.RESULT?.PAYER_2 === 'WARNING' ||
  order?.state?.VERIFICATION?.RESULT?.PAYER_3 === 'WARNING'
export const hasRxFailedVerification = (order: Order) =>
  order?.state?.VERIFICATION?.RESULT?.RX === 'WARNING'
export const hasOfficeNotesFailedVerification = (order: Order) =>
  order?.state?.VERIFICATION?.RESULT?.OFFICE_NOTES === 'WARNING'

export const isPayer2VerificationRequired = (order: Order) =>
  order?.payers?.length > 1 && order?.state?.VERIFICATION?.RESULT?.PAYER_2 !== 'NOT_REQUIRED'

export const isPayer3VerificationRequired = (order: Order) =>
  order?.payers?.length > 2 && order?.state?.VERIFICATION?.RESULT?.PAYER_3 !== 'NOT_REQUIRED'

export const isTestSmarterNightOwlFulfillment = (order: Order) =>
  order?.state?.DEVICE?.NIGHT_OWL?.TS_DELIVERY ||
  order?.thirdPartyDevice?.fulfillment === 'TESTSMARTER'

export const isHstDeviceValid = (order: Order) =>
  order?.thirdPartyDevice?.deviceType === HstDeviceKind.NIGHT_OWL ||
  order?.thirdPartyDevice?.deviceType === HstDeviceKind.DREAM_CLEAR

export const showHstLogistics = (order: Order) =>
  !showVerification(order) &&
  ((order?.state?.ORDER?.HST && order?.state?.ORDER?.HST !== 'AWAITING_VERIFICATION') ||
    (order.testType === 'HST' &&
      (order?.bucket !== 'COMPLETED' || !order?.thirdPartyDevice?.returnedAt) &&
      (['SETUP_DEVICE', 'PATIENT_TESTING'].includes(order?.state?.ORDER) ||
        order?.state?.ORDER?.FINALIZE_ORDER === 'PENDING' ||
        order?.state?.ORDER?.GENERATE_REPORT === 'PENDING' ||
        order?.state?.ORDER === 'DONE')))

export const showVerification = (order: Order) => {
  if(isUnderVerification(order)) {
    return true
  }

  const { RX, OFFICE_NOTES, PATIENT, PHYSICIAN, PAYER, PAYER_2, PAYER_3 } =
    order?.state?.VERIFICATION?.RESULT || {}

  const isHstVerificationDone =
    RX === 'DONE' &&
    (OFFICE_NOTES === 'DONE' || OFFICE_NOTES === 'NOT_REQUIRED') &&
    PATIENT === 'DONE' &&
    PHYSICIAN === 'DONE' &&
    PAYER === 'DONE' &&
    (PAYER_2 === 'DONE' || !isPayer2VerificationRequired(order)) &&
    (PAYER_3 === 'DONE' || !isPayer3VerificationRequired(order))

  return (
    order?.state?.ORDER?.HST === 'AWAITING_VERIFICATION' ||
    order?.state?.ORDER?.PSG === 'AWAITING_VERIFICATION' ||
    (order?.state?.VERIFICATION !== 'DONE' && !isHstVerificationDone)
  )
}

export const showPatientConsent = (order: Order) =>
  order?.state?.HST?.REQUIRED?.RESULT?.PATIENT_CONSENT === 'PENDING' ||
  order?.state?.PSG?.REQUIRED?.RESULT?.PATIENT_CONSENT === 'PENDING'

export const showPreAuthorization = (order: Order) =>
  order?.state?.HST?.REQUIRED?.RESULT?.AUTHORIZATION === 'PENDING' ||
  order?.state?.PSG?.REQUIRED?.RESULT?.AUTHORIZATION === 'PENDING'

export const showPatientScheduling = (order: Order) =>
  order?.state?.PSG?.REQUIRED?.RESULT?.SCHEDULING === 'READY' ||
  order?.state?.HST?.REQUIRED?.RESULT?.SCHEDULING?.PAYMENT === 'PENDING' ||
  order?.state?.HST?.REQUIRED?.RESULT?.SCHEDULING?.QUESTIONNAIRE === 'PENDING' ||
  order?.state?.VERIFICATION !== 'DONE'

export const showDeviceShipping = (order: Order) =>
  order?.state?.HST?.REQUIRED?.RESULT?.DEVICE === 'PENDING' &&
  order?.state?.ORDER !== 'PATIENT_TESTING' &&
  order?.state?.VERIFICATION === 'DONE'

export const showPatientTesting = (order: Order) =>
  order?.state?.HST?.REQUIRED?.RESULT?.PATIENT_TESTING === 'PENDING' ||
  order?.state?.ORDER?.FINALIZE_ORDER === 'PENDING' ||
  order?.state?.ORDER?.GENERATE_REPORT === 'PENDING'

export enum HST_STEPS {
  VERIFICATION = 0,
  PATIENT_CONSENT = 1,
  PRE_AUTHORIZATION = 2,
  PATIENT_SCHEDULING = 3,
  DEVICE_SHIPPING = 4,
  PATIENT_TESTING = 5,
  REPORT = 6,
}

export const getHstStep = (order: Order): HST_STEPS => {
  if (showVerification(order)) return HST_STEPS.VERIFICATION
  if (showPatientConsent(order)) return HST_STEPS.PATIENT_CONSENT
  if (showPreAuthorization(order)) return HST_STEPS.PRE_AUTHORIZATION
  if (showPatientScheduling(order)) return HST_STEPS.PATIENT_SCHEDULING
  if (showDeviceShipping(order)) return HST_STEPS.DEVICE_SHIPPING
  if (showPatientTesting(order)) return HST_STEPS.PATIENT_TESTING

  return HST_STEPS.REPORT
}

export enum PSG_STEPS {
  VERIFICATION = 0,
  PATIENT_CONSENT = 1,
  PRE_AUTHORIZATION = 2,
  PATIENT_SCHEDULING = 3,
}

export const getPsgStep = (order: Order): PSG_STEPS => {
  if (showVerification(order)) return PSG_STEPS.VERIFICATION
  if (showPatientConsent(order)) return PSG_STEPS.PATIENT_CONSENT
  if (showPreAuthorization(order)) return PSG_STEPS.PRE_AUTHORIZATION
  if (showPatientScheduling(order)) return PSG_STEPS.PATIENT_SCHEDULING

  return PSG_STEPS.PATIENT_SCHEDULING
}

export const getPrerequisitesForReport = (order: Order) => {
  return {
    isPatientValid: ['PROVIDED', 'PENDING_REVIEW', 'ON_REVIEW', 'DONE'].includes(
      order?.progress?.PATIENT,
    ),
    isPhysicianValid: ['PROVIDED', 'PENDING_REVIEW', 'ON_REVIEW', 'DONE'].includes(
      order?.progress?.PHYSICIAN,
    ),
    isPayerValid: ['PROVIDED', 'PENDING_REVIEW', 'ON_REVIEW', 'DONE'].includes(
      order?.progress?.PAYER,
    ),
    diagnosisCodesProvided: !!order.diagnosis?.length,
    isRxProvidedOrPromised: isRxProvidedOrPromised(order),
    isAobProvidedOrPromised: isAobPromised(order) || isAobUploaded(order),
    isVerificationDone: isVerificationDone(order),
    pendingDeviceUpload: order?.progress?.UPLOAD === 'PENDING',
    pendingSessionsSubmission:
      order?.progress?.UPLOAD === 'WAITING' && !order?.selectedSessions?.length,
    pendingTasks: !!order?.tasks?.filter((task) => !task.resolved && task.blocking).length,
  }
}

export const isReportDone = (order: Order) =>
  order?.progress?.UPLOAD !== 'PENDING' && order?.progress?.UPLOAD !== 'WAITING'

const getOrderState = (order?: Order) => {
  if (!order) return {}
  return {
    isPatientValid: isPatientValid(order),
    isPhysicianValid: isPhysicianValid(order),
    isPayerValid: isPayerValid(order),
    isRxProvidedOrPromised: isRxProvidedOrPromised(order),
    isOfficeNotesProvidedOrPromised: isOfficeNotesProvidedOrPromised(order),
    isRxPromised: isRxPromised(order),
    isOfficeNotesPromised: isOfficeNotesPromised(order),
    isAobPromised: isAobPromised(order),
    isAobUploaded: isAobUploaded(order),
    isAobFailed: isAobFailed(order),
    isAobVerified: isAobVerified(order),
    isOnSecondTabGroup: isOnSecondTabGroup(order),
    isReadyForReview: isReadyForReview(order),
    isAwaitingVerification: isAwaitingVerification(order),
    isUnderVerification: isUnderVerification(order),
    hasItemsToVerify: hasItemsToVerify(order),
    hasVerificationStarted: hasVerificationStarted(order),
    isVerificationDone: isVerificationDone(order),
    isDmeMakingCorrections: isDmeMakingCorrections(order),
    canDmeResubmitForVerification: canDmeResubmitForVerification(order),
    isPatientModifiable: isModifiable(order, 'PATIENT'),
    isPhysicianModifiable: isModifiable(order, 'PHYSICIAN'),
    isPayerModifiable: isModifiable(order, 'PAYER'),
    isRxModifiable: isModifiable(order, 'RX'),
    isOfficeNotesModifiable: isModifiable(order, 'OFFICE_NOTES'),
    hasFailedVerification: hasFailedVerification(order),
    hasPatientFailedVerification: hasPatientFailedVerification(order),
    hasPhysicianFailedVerification: hasPhysicianFailedVerification(order),
    hasPayer1FailedVerification: hasPayer1FailedVerification(order),
    hasPayer2FailedVerification: hasPayer2FailedVerification(order),
    hasPayer3FailedVerification: hasPayer3FailedVerification(order),
    hasPayerFailedVerification: hasPayerFailedVerification(order),
    hasRxFailedVerification: hasRxFailedVerification(order),
    hasOfficeNotesFailedVerification: hasOfficeNotesFailedVerification(order),
    isPayer2VerificationRequired: isPayer2VerificationRequired(order),
    isPayer3VerificationRequired: isPayer3VerificationRequired(order),
    isTestSmarterNightOwlFulfillment: isTestSmarterNightOwlFulfillment(order),
    canOrderReviewStart: canOrderReviewStart(order),
    isHstDeviceValid: isHstDeviceValid(order),
    showHstLogistics: showHstLogistics(order),
    showPatientConsent: showPatientConsent(order),
    showPreAuthorization: showPreAuthorization(order),
    showPatientScheduling: showPatientScheduling(order),
    hstStep: getHstStep(order),
    psgStep: getPsgStep(order),
    prerequisitesForReport: getPrerequisitesForReport(order),
    billingSucceeded: order?.state?.BILLING === 'DONE',
    isReportDone: isReportDone(order),
  }
}

export default getOrderState

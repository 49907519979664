import { useRef } from 'react'
import _ from 'lodash'

import Button from '../../atoms/Button'
import TitleLead from '../../atoms/TitleLead'
import * as generalHelper from '../../../helpers/generalHelper'
import { PayerSelectionForm } from 'components/dme/orderEdit/views/payer/PayerSelection'
import { Payer } from 'store/types'

interface IProps {
  onCancel: () => void
  onSave: (p: Payer[]) => void
  orderPayers: Payer[]
  officeId: string
}

const PayerFormContainer = ({ onCancel, onSave, orderPayers, officeId }: IProps) => {
  const submitRef = useRef<() => void>()

  const onSavePayers = (payers: Payer[]) => {
    onSave(payers)
  }

  return (
    <div className="row border bg-white m-2 mb-3 pb-3">
      <div className="col-sm-12 d-flex flex-column px-3">
        <div className="d-flex justify-content-between align-items-center">
          <TitleLead className="ml-2 my-3" label={generalHelper.t('INSURANCE INFORMATION')} />

          <div className="d-flex">
            <Button
              onClick={onCancel}
              className="btn btn-default mr-2"
              label="Cancel"
              type="button"
            />
            <Button
              onClick={() => {
                submitRef.current && submitRef.current()
              }}
              className="btn btn-primary"
              label={generalHelper.t('Save Changes')}
            />
          </div>
        </div>
        <hr className="w-100 mt-0" />
      </div>

      <div className="col-sm-12 ml-1">
        <div className="row mx-2">
          <PayerSelectionForm
            payers={orderPayers}
            submitForm={onSavePayers}
            submitRef={submitRef}
            officeId={officeId}
          />
        </div>
      </div>
    </div>
  )
}

export default PayerFormContainer

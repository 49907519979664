/** @jsxImportSource @emotion/react */
import { ReactNode } from 'react'
import { confirmable, createConfirmation } from 'react-confirm'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { css } from '@emotion/react'

const DEFAULT_OPTIONS = {
  description: `Are you sure you want to remove this`,
  noAction: `No`,
  title: `Confirm Delete`,
  yesAction: `Yes, delete`,
}

interface Options {
  description?: string | (() => ReactNode)
  noAction?: string
  title?: string
  yesAction?: string
  yesColor?: string
  noColor?: string
  size?: 'sm' | 'lg' | 'xl' | undefined
}

interface IProps {
  cancel: () => void
  confirmation: string
  options: Options
  proceed: () => void
  show: boolean
}

const getOption = (options: Options, optionKey: string): string | (() => void) =>
  ({ ...DEFAULT_OPTIONS, ...options })[optionKey]

const ConfirmModal = ({ cancel, confirmation, options, proceed, show }: IProps) => (
  <Modal centered show={show} onHide={cancel} size={options.size} style={{ zIndex: 100000 }}>
    <Modal.Header>
      <Modal.Title>{getOption(options, `title`)}</Modal.Title>
    </Modal.Header>
    <Modal.Body
      css={css`
        line-height: 1.3rem;
      `}>
      {typeof getOption(options, `description`) === `function`
        ? getOption(options, `description`)()
        : `${getOption(options, `description`)} ${confirmation} ${!!confirmation ? `?` : ``}`}
    </Modal.Body>
    <Modal.Footer>
      {!!getOption(options, `noAction`) && (
        <Button variant={options.noColor || 'outline-secondary'} onClick={cancel}>
          {getOption(options, `noAction`)}
        </Button>
      )}
      <Button autoFocus variant={options.yesColor || 'primary'} onClick={proceed}>
        {getOption(options, `yesAction`)} {confirmation}
      </Button>
    </Modal.Footer>
  </Modal>
)

const ConfirmDialog = confirmable(ConfirmModal)
const triggerConfirmation = createConfirmation(ConfirmDialog)

export const confirm = (value: string, options: Options = {}) =>
  triggerConfirmation({ confirmation: value, options })

export default ConfirmDialog

import { useAppSelector, useAppDispatch } from 'hooks/redux'
import { setModal } from '../../../store/slice/modalSlice'
import Modal from '../../atoms/Modal'
import PhysicianTab from '../orderEdit/views/Physician.tab'
import InsuranceTab from '../orderEdit/views/Insurance.tab'
import PatientUpsert from '../orderEdit/views/forms/PatientUpsert'
import ButtonSave from '../../atoms/ButtonSave'
import ButtonCancelModal from '../../atoms/ButtonCancelModal'

const EditOrderModal = props => {
  const modal = useAppSelector(state => state.modal.row)
  const dispatch = useAppDispatch()

  const modalFooter = () => {
    return (
      <>
        <div className="modal-footer">
          <ButtonCancelModal
            type="button"
            data_dismiss="modal"
            onClick={e => {
              dispatch(setModal({}))
            }}
          />
          <ButtonCancelModal
            className="btn btn-primary"
            type="button"
            label="Save"
            data_dismiss="modal"
            onClick={() => {
              dispatch(setModal({}))
            }}
          />
        </div>
      </>
    )
  }

  const handleModalContent = () => {
    let modalContent = <></>
    if (modal && modal?.content && modal?.content === 1) {
      modalContent = (
        <PatientUpsert patient={modal?.patient} isNewOrder={true} />
      )
    } else if (modal && modal?.content && modal?.content === 2) {
      modalContent = (
        <>
          <div className="pl-3 pr-3">
            <PhysicianTab patient={modal?.patient} isNewOrder={true} />
          </div>
          {modalFooter()}
        </>
      )
    } else if (modal && modal?.content && modal?.content === 3) {
      modalContent = <InsuranceTab patient={modal?.patient} />
    }
    return modalContent
  }

  return (
    <>
      <Modal
        {...props}
        title={modal?.title ? modal?.title : 'EDIT INFORMATION'}
        content={handleModalContent()}
      />
    </>
  )
}

export default EditOrderModal

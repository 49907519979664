import {
  AttachFaxDto,
  BucketValue,
  CreateOrder,
  FollowUpNamespace,
  HoldNightOwlOrderDto,
  LabAssignThirdPartyDeviceDto,
  LabManualShippingDto,
  LabRequestDeliveryDto,
  Order,
  PatientRelationship,
  Payer,
  ProvidePaymentAuthorizationDto,
  RefundShippoTransactionDto,
  RevertNightOwlOrder,
  SendNightOwlWelcomeDto,
  SubmitToNightOwlDto,
  TestCode,
  VerificationResult,
  VerificationSection,
} from 'store/types'
import { ChangeFulfillmentType } from 'ts-back-end/src/business/device/dto/device.dto'
import {
  TestConditions,
  TimestampOverride,
} from 'ts-back-end/src/business/order/dto/create-order.dto'
import { baseApi } from './baseApi'
import { AobRequestPayload } from './dmeOrder'

type PhysicianAssignment = {
  physicianId: number
  orderId: number
  addressUuid: string
}

type PayerAssignment = {
  orderId: string
  payers: Payer[]
}

export type SignAobRequest = {
  orderId: number
  signature: string
  relationship: PatientRelationship
  signatureDateTime: string
  signedBy?: string
}

type OrderListRequest = {
  officeIds?: number[]
  types: TestCode
  buckets: BucketValue
  page: number
  size: number
}

export const orderApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getLabOrder: builder.query<Order, number>({
      query: (id) => ({ url: `/lab/orders/${id}` }),
      providesTags: (result, error, id) => [{ type: 'Order', id }],
      keepUnusedDataFor: 5,
    }),
    getLabOrders: builder.query<Order[], OrderListRequest>({
      query: (query) => ({ url: `/lab/orders`, params: query }),
      providesTags: ['OrderList'],
    }),
    getRecentLabOrders: builder.query<Order[], OrderListRequest>({
      query: (query) => ({ url: `/lab/orders/recent`, params: query }),
      providesTags: ['OrderList'],
    }),
    startOrderVerification: builder.mutation<Order, { orderId: string }>({
      query: ({ orderId }) => ({
        method: `PUT`,
        url: `/lab/orders/${orderId}/verification`,
      }),
      invalidatesTags: (result, error, { orderId: id }) => [{ type: 'Order', id }],
    }),
    finishOrderVerification: builder.mutation<Order, { orderId: string }>({
      query: ({ orderId }) => ({
        method: `PATCH`,
        url: `/lab/orders/${orderId}/verification`,
      }),
      invalidatesTags: (result, error, { orderId: id }) => [{ type: 'Order', id }],
    }),
    addOrderDiagnosisCode: builder.mutation<Order, { orderId: string; code: string }>({
      query: ({ orderId, code }) => ({
        method: `POST`,
        url: `/lab/orders/${orderId}/diagnosis-codes`,
        body: { orderId, code },
      }),
      invalidatesTags: (result, error, { orderId: id }) => [{ type: 'Order', id }],
    }),
    removeOrderDiagnosisCode: builder.mutation<Order, { orderId: string; code: string }>({
      query: ({ orderId, code }) => ({
        method: `DELETE`,
        url: `/lab/orders/${orderId}/diagnosis-codes/${code}`,
      }),
      invalidatesTags: (result, error, { orderId: id }) => [{ type: 'Order', id }],
    }),
    updateLabOrderPayer: builder.mutation<Payer, PayerAssignment>({
      query: (params) => ({
        method: `PUT`,
        url: `/lab/orders/${params.orderId}/payers`,
        body: params,
      }),
      invalidatesTags: (result, error, { orderId: id }) => [
        { type: 'Order', id },
        { type: 'OrderList' },
      ],
    }),
    setLabOrderPayerInfo: builder.mutation<Payer, ProvidePaymentAuthorizationDto>({
      query: ({ orderId, ...payerInfo }) => ({
        method: `PUT`,
        url: `/lab/orders/${orderId}/authorization`,
        body: { orderId, ...payerInfo },
      }),
      invalidatesTags: (result, error, { orderId: id }) => [
        { type: 'Order', id },
        { type: 'OrderList' },
        { type: 'OrderCounts' },
      ],
    }),
    addLabFile: builder.mutation<Order, { orderId: number; uploadType: string; fileKey: string }>({
      query: (params) => ({
        method: `PUT`,
        url: `/lab/orders/${params.orderId}/files`,
        body: params,
      }),
      invalidatesTags: (result, error, { orderId: id }) => [{ type: 'Order', id }],
    }),
    removeLabFile: builder.mutation<Order, { orderId: number; fileKey: string }>({
      query: (params) => ({
        method: `DELETE`,
        url: `/lab/orders/${params.orderId}/files`,
        body: params,
      }),
      invalidatesTags: (result, error, { orderId: id }) => [{ type: 'Order', id }],
    }),
    attachFaxDocument: builder.mutation<void, AttachFaxDto>({
      query: (params) => ({
        method: `POST`,
        url: `/lab/orders/${params.orderId}/files`,
        body: params,
      }),
      invalidatesTags: (result, error, { orderId: id }) => [
        { type: 'Order', id },
        { type: 'IncomingFax' },
      ],
    }),
    changeLabFileCategory: builder.mutation<
      Order,
      { orderId: number; fileKey: string; toUploadType: string }
    >({
      query: (params) => ({
        method: `POST`,
        url: `/lab/orders/${params.orderId}/files/change-category`,
        body: params,
      }),
      invalidatesTags: (result, error, { orderId: id }) => [{ type: 'Order', id }],
    }),
    verifyOrder: builder.mutation<Order, VerificationResult>({
      query: (params) => ({
        method: `PUT`,
        url: `/lab/orders/${params.orderId}/verify`,
        body: params,
      }),
      invalidatesTags: (result, error, { orderId: id }) => [
        { type: 'Order', id },
        { type: 'OrderCounts' },
        { type: 'OrderList' },
      ],
    }),
    unverifyOrder: builder.mutation<Order, { orderId: number; section: VerificationSection }>({
      query: ({ orderId, section }) => ({
        method: `DELETE`,
        url: `/lab/orders/${orderId}/verify/${section}`,
      }),
      invalidatesTags: (result, error, { orderId: id }) => [
        { type: 'Order', id },
        { type: 'OrderCounts' },
        { type: 'OrderList' },
      ],
    }),
    revokeVerification: builder.mutation<Order, { orderId: number }>({
      query: ({ orderId }) => ({
        method: `DELETE`,
        url: `/lab/orders/${orderId}/verification`,
      }),
      invalidatesTags: (result, error, { orderId: id }) => [
        { type: 'Order', id },
        { type: 'OrderCounts' },
        { type: 'OrderList' },
      ],
    }),
    assignLabPhysician: builder.mutation<Order, PhysicianAssignment>({
      query: (params) => ({
        method: `PUT`,
        url: `/lab/orders/${params.orderId}/physician`,
        body: params,
      }),
      invalidatesTags: (result, error, { orderId: id }) => [{ type: 'Order', id }],
    }),
    removeLabPhysician: builder.mutation<Order, { orderId: number }>({
      query: ({ orderId }) => ({
        method: `DELETE`,
        url: `/lab/orders/${orderId}/physician`,
      }),
      invalidatesTags: (result, error, { orderId: id }) => [
        { type: 'Order', id },
        { type: 'OrderList' },
      ],
    }),
    getLabOrderCounts: builder.query<any[], {}>({
      query: (query) => ({ url: `/lab/orders/counts` }),
      providesTags: ['OrderCounts'],
    }),
    searchOrders: builder.query<
      Order[],
      { buckets: BucketValue[]; patient: string; page: number; size: number }
    >({
      query: (params) => ({
        url: `/lab/orders/search`,
        params: {
          ...params,
          buckets: params?.buckets?.join(','),
        },
      }),
    }),
    putNightOwlOnHold: builder.mutation<Order, HoldNightOwlOrderDto>({
      query: (params) => ({
        method: `PUT`,
        url: `/lab/orders/${params.orderId}/night-owl/hold`,
        body: params,
      }),
      invalidatesTags: (result, error, { orderId: id }) => [
        { type: 'Order', id },
        { type: 'OrderCounts' },
        { type: 'OrderList' },
      ],
    }),
    removeNightOwlHold: builder.mutation<Order, { orderId: number }>({
      query: (params) => ({
        method: `DELETE`,
        url: `/lab/orders/${params.orderId}/night-owl/hold`,
        body: params,
      }),
      invalidatesTags: (result, error, { orderId: id }) => [
        { type: 'Order', id },
        { type: 'OrderCounts' },
        { type: 'OrderList' },
      ],
    }),
    submitToNightOwl: builder.mutation<Order, SubmitToNightOwlDto>({
      query: (params) => ({
        method: `POST`,
        url: `/lab/orders/${params.orderId}/night-owl/submit`,
        body: params,
      }),
      invalidatesTags: (result, error, { orderId: id }) => [{ type: 'Order', id }],
    }),
    sendNightOwlWelcome: builder.mutation<Order, SendNightOwlWelcomeDto>({
      query: (params) => ({
        method: `POST`,
        url: `/lab/orders/${params.orderId}/night-owl/welcome`,
        body: params,
      }),
      invalidatesTags: (result, error, { orderId: id }) => [
        { type: 'Order', id },
        { type: 'OrderCounts' },
        { type: 'OrderList' },
      ],
    }),
    assignNightOwlDevice: builder.mutation<Order, LabAssignThirdPartyDeviceDto>({
      query: (params) => ({
        method: `POST`,
        url: `/lab/orders/${params.orderId}/night-owl/assign-device`,
        body: params,
      }),
      invalidatesTags: (result, error, { orderId: id }) => [
        { type: 'Order', id },
        { type: 'OrderCounts' },
        { type: 'OrderList' },
      ],
    }),
    initiateTestSmarterFulfillment: builder.mutation<Order, { orderId: number }>({
      query: (params) => ({
        method: `POST`,
        url: `/lab/orders/${params.orderId}/initiate-ts-fulfillment`,
      }),
      invalidatesTags: (result, error, { orderId: id }) => [
        { type: 'Order', id },
        { type: 'OrderCounts' },
        { type: 'OrderList' },
      ],
    }),
    getShippingRates: builder.mutation<Order, { orderId: number; max_price?: number }>({
      query: ({ orderId, ...query }) => ({
        method: `GET`,
        url: `/lab/orders/${orderId}/night-owl/rates`,
        params: query,
      }),
    }),
    validateShippingAddress: builder.mutation<Order, { orderId: number }>({
      query: ({ orderId, ...query }) => ({
        method: `GET`,
        url: `/lab/orders/${orderId}/tpd/validate-address`,
        params: query,
      }),
    }),
    requestDelivery: builder.mutation<Order, LabRequestDeliveryDto>({
      query: (params) => ({
        method: `POST`,
        url: `/lab/orders/${params.orderId}/night-owl/request-delivery`,
        body: params,
      }),
      invalidatesTags: (result, error, { orderId: id }) => [
        { type: 'Order', id },
        { type: 'OrderCounts' },
        { type: 'OrderList' },
      ],
    }),
    initiateManualShipping: builder.mutation<Order, LabManualShippingDto>({
      query: (params) => ({
        method: `POST`,
        url: `/lab/orders/${params.orderId}/night-owl/manual-shipping`,
        body: params,
      }),
      invalidatesTags: (result, error, { orderId: id }) => [
        { type: 'Order', id },
        { type: 'OrderCounts' },
        { type: 'OrderList' },
      ],
    }),
    localPickup: builder.mutation<Order, { orderId: number }>({
      query: (params) => ({
        method: `POST`,
        url: `/lab/orders/${params.orderId}/night-owl/local-pickup`,
        body: params,
      }),
      invalidatesTags: (result, error, { orderId: id }) => [
        { type: 'Order', id },
        { type: 'OrderCounts' },
        { type: 'OrderList' },
      ],
    }),
    refundNightOwlShipment: builder.mutation<Order, RefundShippoTransactionDto>({
      query: (params) => ({
        method: `POST`,
        url: `/lab/orders/${params.orderId}/night-owl/refund`,
        body: params,
      }),
      invalidatesTags: (result, error, { orderId: id }) => [
        { type: 'Order', id },
        { type: 'OrderCounts' },
        { type: 'OrderList' },
      ],
    }),
    revertNightOwl: builder.mutation<Order, RevertNightOwlOrder>({
      query: (params) => ({
        method: `POST`,
        url: `/lab/orders/${params.orderId}/night-owl/revert`,
        body: params,
      }),
      invalidatesTags: (result, error, { orderId: id }) => [
        { type: 'Order', id },
        { type: 'OrderCounts' },
        { type: 'OrderList' },
      ],
    }),
    returnThirdPartyDevice: builder.mutation<Order, RevertNightOwlOrder>({
      query: (params) => ({
        method: `POST`,
        url: `/lab/orders/${params.orderId}/tpd/mark-as-returned`,
        body: params,
      }),
      invalidatesTags: (result, error, { orderId: id }) => [
        { type: 'Order', id },
        { type: 'OrderCounts' },
        { type: 'OrderList' },
      ],
    }),
    changeNightOwlFulfillment: builder.mutation<Order, ChangeFulfillmentType>({
      query: (params) => ({
        method: `PATCH`,
        url: `/lab/orders/${params.orderId}/night-owl/fulfillment`,
        body: params,
      }),
      invalidatesTags: (result, error, { orderId: id }) => [
        { type: 'Order', id },
        { type: 'OrderList' },
      ],
    }),
    markNightOwlDelivered: builder.mutation<Order, { orderId: number }>({
      query: (params) => ({
        method: `PUT`,
        url: `/lab/orders/${params.orderId}/night-owl/delivered`,
        body: params,
      }),
      invalidatesTags: (result, error, { orderId: id }) => [
        { type: 'Order', id },
        { type: 'OrderCounts' },
        { type: 'OrderList' },
      ],
    }),
    getNightOwlShippingHistory: builder.query<Order, { orderId: number }>({
      query: ({ orderId }) => ({
        url: `/lab/orders/${orderId}/shippo/tracking?fakeResponse=false&debug=false`,
      }),
    }),
    labAobSignRequest: builder.mutation<Order, AobRequestPayload>({
      query: (params) => ({
        method: `POST`,
        url: `/lab/orders/${params.orderId}/aob-request`,
        body: params,
      }),
      invalidatesTags: (result, error, { orderId: id }) => [{ type: 'Order', id }],
    }),
    addToBillingQueue: builder.mutation<Order, { orders: number[] }>({
      query: (params) => ({
        method: `PUT`,
        url: `/lab/orders/billing/clear`,
        body: params,
      }),
      invalidatesTags: (result, error, { orders }) => [
        ...orders.map((id) => ({ type: 'Order', id })),
        { type: 'OrderCounts' },
        { type: 'OrderList' },
      ],
    }),
    removeFromBillingQueue: builder.mutation<Order, { orders: number[] }>({
      query: (params) => ({
        method: `DELETE`,
        url: `/lab/orders/billing/clear`,
        body: params,
      }),
      invalidatesTags: (result, error, { orders }) => [
        ...orders.map((id) => ({ type: 'Order', id })),
        { type: 'OrderCounts' },
        { type: 'OrderList' },
      ],
    }),
    createLabOrder: builder.mutation<Order, CreateOrder>({
      query: (order) => ({
        method: `POST`,
        url: `/lab/orders`,
        body: order,
      }),
      invalidatesTags: ['Order', 'OrderList', 'OrderCounts'],
    }),
    cancelReportFax: builder.mutation<Order, { orderId: number }>({
      query: (params) => ({
        method: `DELETE`,
        url: `/lab/orders/${params.orderId}/fax-schedule`,
      }),
      invalidatesTags: ['Order', 'OrderList', 'OrderCounts'],
    }),
    scheduleReportFax: builder.mutation<Order, { orderId: number; in: number }>({
      query: (params) => ({
        method: `POST`,
        url: `/lab/orders/${params.orderId}/fax-schedule`,
        body: params,
      }),
      invalidatesTags: ['Order', 'OrderList', 'OrderCounts'],
    }),
    capturePatientConsent: builder.mutation<Order, { orderId: number; result: string }>({
      query: (params) => ({
        method: `POST`,
        url: `/lab/orders/${params.orderId}/check-patient-end`,
        body: params,
      }),
      invalidatesTags: ['Order', 'OrderList', 'OrderCounts'],
    }),
    sendPatientPaperworkEmail: builder.mutation<
      Order,
      { orderId: number; email: string; method: 'email' | 'sms'; test?: boolean }
    >({
      query: (params) => ({
        method: `POST`,
        url: `/lab/orders/${params.orderId}/send-paperwork-link`,
        body: params,
      }),
      invalidatesTags: (result, error, { orderId: id }) => [{ type: 'Order', id }],
    }),
    bypassStripePayment: builder.mutation<Order, { orderId: number }>({
      query: (params) => ({
        method: `POST`,
        url: `/lab/orders/${params.orderId}/bypass-payment`,
        body: params,
      }),
      invalidatesTags: (result, error, { orderId: id }) => [{ type: 'Order', id }],
    }),
    initiateManualQuestionnaire: builder.mutation<Order, { orderId: number }>({
      query: (params) => ({
        method: `PUT`,
        url: `/lab/orders/${params.orderId}/manual-questionnaire`,
        body: params,
      }),
      invalidatesTags: (result, error, { orderId: id }) => [{ type: 'Order', id }],
    }),
    setCopayAmount: builder.mutation<Order, { orderId: number; value: number }>({
      query: (params) => ({
        method: `PUT`,
        url: `/lab/orders/${params.orderId}/copay`,
        body: params,
      }),
      invalidatesTags: (result, error, { orderId: id }) => [{ type: 'Order', id }],
    }),
    createCheckout: builder.mutation<Order, { orderId: number; amount: number }>({
      query: (params) => ({
        method: `POST`,
        url: `/lab/orders/${params.orderId}/checkout`,
        body: params,
      }),
      invalidatesTags: (result, error, { orderId: id }) => [{ type: 'Order', id }],
    }),
    setOrderFollowUpDate: builder.mutation<
      Order,
      { orderId: number; date: string; namespace: FollowUpNamespace; comment: string }
    >({
      query: (params) => ({
        method: `POST`,
        url: `/lab/orders/${params.orderId}/follow-ups`,
        body: params,
      }),
      invalidatesTags: (result, error, { orderId: id }) => [
        { type: 'Order', id },
        { type: 'OrderList' },
      ],
    }),
    updateLabHstDevice: builder.mutation<
      Order,
      { orderId: number; deviceType: string; email: string; mobilePhone: string }
    >({
      query: (params) => ({
        method: `PUT`,
        url: `/lab/orders/${params.orderId}/hst-device`,
        body: params,
      }),
      invalidatesTags: ['Order', 'OrderList', 'OrderCounts'],
    }),
    updateHstStatus: builder.mutation<Order, { orderId: number; status: string }>({
      query: (params) => ({
        method: `PUT`,
        url: `/lab/orders/${params.orderId}/update-status`,
        body: params,
      }),
      invalidatesTags: ['Order', 'OrderList', 'OrderCounts'],
    }),
    cancelOrder: builder.mutation<Order, { orderId: number }>({
      query: (params) => ({
        method: `PUT`,
        url: `/lab/orders/${params.orderId}/cancel`,
      }),
      invalidatesTags: (result, error, { orderId: id }) => [
        { type: 'Order', id },
        { type: 'OrderList' },
        { type: 'OrderCounts' },
      ],
    }),
    restoreOrder: builder.mutation<Order, { orderId: number }>({
      query: (params) => ({
        method: `PUT`,
        url: `/lab/orders/${params.orderId}/restore`,
      }),
      invalidatesTags: (result, error, { orderId: id }) => [
        { type: 'Order', id },
        { type: 'OrderList' },
        { type: 'OrderCounts' },
      ],
    }),
    updateTask: builder.mutation<
      Order,
      {
        orderId: number
        id: string
        resolved: boolean
        timestampOverrides: TimestampOverride[]
      }
    >({
      query: (params) => ({
        method: `POST`,
        url: `/lab/orders/${params.orderId}/tasks/${params.id}`,
        body: params,
      }),
      invalidatesTags: ['Order', 'OrderList', 'OrderCounts'],
    }),
    updateReportRender: builder.mutation<
      Order,
      {
        orderId: number
        timestampOverrides?: TimestampOverride[]
        sessions?: number[]
        tests?: TestConditions[]
      }
    >({
      query: (params) => ({
        method: `POST`,
        url: `/lab/orders/${params.orderId}/request-new-render`,
        body: params,
      }),
      invalidatesTags: (result, error, { orderId: id }) => [{ type: 'Order', id }],
    }),
    reRenderReport: builder.mutation<Order, { orderId: number; reportId: number }>({
      query: (params) => ({
        method: `POST`,
        url: `/lab/orders/${params.orderId}/reports/${params.reportId}/re-render`,
      }),
      invalidatesTags: ['Order', 'OrderList', 'OrderCounts'],
    }),
    removeDeviceReportData: builder.mutation<Order, { orderId: number }>({
      query: (params) => ({
        method: `POST`,
        url: `/lab/orders/${params.orderId}/wipe`,
      }),
      invalidatesTags: ['Order', 'OrderList', 'OrderCounts'],
    }),
    getOrderHistoryLog: builder.query<any[], { orderId: number; context: 'LAB' | 'DME' }>({
      query: ({ orderId, context }) => ({
        url: `/${context.toLowerCase()}/orders/${orderId}/history`,
      }),
    }),
    moveDeviceData: builder.mutation<Order, { orderId: number; targetOrderId: number }>({
      query: (params) => ({
        method: `POST`,
        url: `/lab/orders/${params.orderId}/move`,
        body: params,
      }),
      invalidatesTags: ['Order', 'OrderList', 'OrderCounts'],
    }),
    markOrderReportAsSent: builder.mutation<Order, { orderId: number; reports: string[] }>({
      query: (params) => ({
        method: `PUT`,
        url: `/lab/orders/${params.orderId}/mark-as-sent`,
        body: params,
      }),
      invalidatesTags: ['Order', 'OrderList', 'OrderCounts'],
    }),
    refaxOrderReport: builder.mutation<Order, { orderId: number; reports: string[] }>({
      query: (params) => ({
        method: `POST`,
        url: `/lab/orders/${params.orderId}/re-fax`,
        body: params,
      }),
      invalidatesTags: ['Order', 'OrderList', 'OrderCounts'],
    }),
    cancelOrderFax: builder.mutation<Order, { orderId: number; reports: string[] }>({
      query: (params) => ({
        method: `POST`,
        url: `/lab/orders/${params.orderId}/cancel-faxing`,
        body: params,
      }),
      invalidatesTags: ['Order', 'OrderList', 'OrderCounts'],
    }),
    disregardOrderFax: builder.mutation<
      Order,
      { orderId: number; reports: number[]; sendFax: boolean; toFax: string }
    >({
      query: (params) => ({
        method: `POST`,
        url: `/lab/orders/${params.orderId}/disregard-reports`,
        body: params,
      }),
      invalidatesTags: ['Order', 'OrderList', 'OrderCounts'],
    }),
    submitBilling: builder.mutation<Order, { orders: number[] }>({
      query: (params) => ({
        method: `POST`,
        url: `/lab/run-billing-queue`,
        body: params,
      }),
      invalidatesTags: (result, error, { orders }) => [
        ...orders.map((id) => ({ type: 'Order', id })),
        { type: 'OrderCounts' },
        { type: 'OrderList' },
      ],
    }),
    createMobileInvite: builder.mutation<
      Order,
      { orderId: number; method: string; email: string; phone?: string }
    >({
      query: (params) => ({
        method: `POST`,
        url: `/patient-mobile-app/invite`,
        body: params,
      }),
      invalidatesTags: ['Order', 'OrderList', 'OrderCounts'],
    }),
    markOrderAsBilled: builder.mutation<Order, { orderId: number; reason: string }>({
      query: (params) => ({
        method: `PUT`,
        url: `/lab/orders/${params.orderId}/mark-billed`,
        body: params,
      }),
      invalidatesTags: ['Order', 'OrderList', 'OrderCounts'],
    }),
    uploadHstReport: builder.mutation<Order, { orderId: number; formData: FormData }>({
      query: ({ orderId, formData }) => ({
        method: `POST`,
        url: `/lab/orders/${orderId}/upload-report`,
        body: formData,
        prepareHeaders: (headers: any) => {
          headers.set('Content-Type', 'multipart/form-data')
          return headers
        },
      }),
      invalidatesTags: ['Order', 'OrderList', 'OrderCounts'],
    }),
    completeHstOrder: builder.mutation<Order, { orderId: number }>({
      query: ({ orderId }) => ({
        method: `POST`,
        url: `/lab/orders/${orderId}/complete-hst`,
      }),
      invalidatesTags: ['Order', 'OrderList', 'OrderCounts'],
    }),
    reopenHstPsgOrder: builder.mutation<Order, { orderId: number }>({
      query: ({ orderId }) => ({
        method: `POST`,
        url: `/lab/orders/${orderId}/reopen-hst-psg`,
      }),
      invalidatesTags: ['Order', 'OrderList', 'OrderCounts'],
    }),
    scheduleInLabTesting: builder.mutation<Order, { orderId: number; date: string | undefined }>({
      query: (params) => ({
        method: `PUT`,
        url: `/lab/psg/${params.orderId}/schedule-testing`,
        body: params,
      }),
      invalidatesTags: ['Order', 'OrderList', 'OrderCounts'],
    }),
    changeInLabStep: builder.mutation<Order, { orderId: number; type: 'prev' | 'next' }>({
      query: (params) => ({
        method: `PUT`,
        url: `/lab/psg/${params.orderId}/state-step`,
        body: params,
      }),
      invalidatesTags: ['Order', 'OrderList', 'OrderCounts'],
    }),
    sendPatientDataToCureMd: builder.mutation<Order, { orderId: number }>({
      query: (params) => ({
        method: `POST`,
        url: `/lab/psg/${params.orderId}/send-patient-cure-md`,
      }),
      invalidatesTags: ['Order', 'OrderList', 'OrderCounts'],
    }),
  }),
})

export const {
  useGetLabOrdersQuery,
  useGetLabOrderQuery,
  useGetRecentLabOrdersQuery,
  useStartOrderVerificationMutation,
  useFinishOrderVerificationMutation,
  useAddOrderDiagnosisCodeMutation,
  useRemoveOrderDiagnosisCodeMutation,
  useGetLabOrderCountsQuery,
  useUpdateLabOrderPayerMutation,
  useSetLabOrderPayerInfoMutation,
  useAddLabFileMutation,
  useRemoveLabFileMutation,
  useAttachFaxDocumentMutation,
  useChangeLabFileCategoryMutation,
  useVerifyOrderMutation,
  useUnverifyOrderMutation,
  useRevokeVerificationMutation,
  useAssignLabPhysicianMutation,
  useRemoveLabPhysicianMutation,
  useSearchOrdersQuery,
  usePutNightOwlOnHoldMutation,
  useRemoveNightOwlHoldMutation,
  useSubmitToNightOwlMutation,
  useSendNightOwlWelcomeMutation,
  useAssignNightOwlDeviceMutation,
  useInitiateTestSmarterFulfillmentMutation,
  useGetShippingRatesMutation,
  useValidateShippingAddressMutation,
  useRequestDeliveryMutation,
  useInitiateManualShippingMutation,
  useLocalPickupMutation,
  useRefundNightOwlShipmentMutation,
  useRevertNightOwlMutation,
  useReturnThirdPartyDeviceMutation,
  useMarkNightOwlDeliveredMutation,
  useLazyGetNightOwlShippingHistoryQuery,
  useLabAobSignRequestMutation,
  useChangeNightOwlFulfillmentMutation,
  useAddToBillingQueueMutation,
  useRemoveFromBillingQueueMutation,
  useCreateLabOrderMutation,
  useCancelReportFaxMutation,
  useScheduleReportFaxMutation,
  useCapturePatientConsentMutation,
  useSendPatientPaperworkEmailMutation,
  useBypassStripePaymentMutation,
  useInitiateManualQuestionnaireMutation,
  useSetCopayAmountMutation,
  useCreateCheckoutMutation,
  useSetOrderFollowUpDateMutation,
  useUpdateLabHstDeviceMutation,
  useUpdateHstStatusMutation,
  useCancelOrderMutation,
  useRestoreOrderMutation,
  useUpdateTaskMutation,
  useUpdateReportRenderMutation,
  useReRenderReportMutation,
  useRemoveDeviceReportDataMutation,
  useGetOrderHistoryLogQuery,
  useLazyGetOrderHistoryLogQuery,
  useMoveDeviceDataMutation,
  useMarkOrderReportAsSentMutation,
  useRefaxOrderReportMutation,
  useCancelOrderFaxMutation,
  useDisregardOrderFaxMutation,
  useSubmitBillingMutation,
  useCreateMobileInviteMutation,
  useMarkOrderAsBilledMutation,
  useUploadHstReportMutation,
  useCompleteHstOrderMutation,
  useReopenHstPsgOrderMutation,
  useScheduleInLabTestingMutation,
  useChangeInLabStepMutation,
  useSendPatientDataToCureMdMutation,
} = orderApi

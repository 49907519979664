import { DismissBulletinDto } from 'ts-back-end/src/business/bulletin/dto/bulletin.dto'
import { baseApi } from './baseApi'
import { CreateBulletinDto, BulletinDto, UpdateBulletinDto } from 'store/types'

export const bulletingApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    createBulletin: builder.mutation<{ message: string }, CreateBulletinDto>({
      query: (body) => ({ url: `/lab/bulletins`, method: 'POST', body }),
      invalidatesTags: ['Bulletin'],
    }),
    getBulletin: builder.query<BulletinDto, number>({
      query: (id) => ({ url: `/lab/bulletins/${id}` }),
      providesTags: ['Bulletin'],
    }),
    getBulletins: builder.query<BulletinDto[], undefined>({
      query: () => ({ url: `/lab/bulletins` }),
      providesTags: ['Bulletin'],
    }),
    deleteBulletin: builder.mutation<{ message: string }, number>({
      query: (id) => ({ method: `DELETE`, url: `/lab/bulletins/${id}` }),
      invalidatesTags: ['Bulletin'],
    }),
    updateBulletin: builder.mutation<
      { message: string },
      Partial<UpdateBulletinDto> & { id: number }
    >({
      query: ({ id, ...data }: Partial<UpdateBulletinDto> & { id: number }) => ({
        method: `PATCH`,
        url: `/lab/bulletins/${id}`,
        body: data,
      }),
      invalidatesTags: ['Bulletin'],
    }),
    dmeUpdateBulletin: builder.mutation<{ message: string }, DismissBulletinDto & { id: number }>({
      query: ({ id, ...data }: DismissBulletinDto & { id: number }) => ({
        method: `PATCH`,
        url: `/dme/bulletins/${id}`,
        body: data,
      }),
      invalidatesTags: ['Bulletin'],
    }),
  }),
})

export const {
  useCreateBulletinMutation,
  useGetBulletinQuery,
  useGetBulletinsQuery,
  useDeleteBulletinMutation,
  useUpdateBulletinMutation,
  useDmeUpdateBulletinMutation,
} = bulletingApi

import {
  Children,
  createContext,
  isValidElement,
  cloneElement,
  Fragment,
  useEffect,
  useContext,
  useState,
  useMemo,
  memo,
  ReactNode,
} from 'react'

import { isFunction } from 'lodash'
import clsx from 'clsx'

export const Context = createContext({})

export const useSteps = () => {
  return useContext(Context)
}

export const Step = memo(({ step = 0, index, children, ...props }) => {
  return step === index ? <div {...props}>{children}</div> : null
})

interface IProps {
  step: number
  children: ReactNode
  stepProps?: any
  className?: string
}

export const Stepper = memo(
  ({ step: activeStep, children, stepProps, ...props }: IProps) => {
    const [step, updateStep] = useState(activeStep)
    const steps = Children.toArray(children)

    const setStep = (newStep: number | ((i: number) => number)) => {
      updateStep(step => {
        if (isFunction(newStep)) {
          newStep = newStep(step)
        }

        return newStep < 0 || newStep >= steps.length ? step : newStep
      })
    }

    useEffect(() => {
      setStep(activeStep)
    }, [activeStep])

    const next = () => {
      setStep(step => {
        const newStep = step + 1
        return newStep >= steps.length ? step : newStep
      })
    }

    const prev = () => {
      setStep(step => {
        const newStep = step - 1
        return newStep >= 0 ? newStep : 0
      })
    }

    const renderStep = (child: ReactNode, index: number) =>
      isValidElement(child) ? (
        <Fragment key={'step-' + index}>
          {cloneElement(child, {
            ...stepProps,
            ...child.props,
            className: clsx(stepProps?.className, child.props?.className),
            index,
            step,
          })}
        </Fragment>
      ) : null

    const value = useMemo(
      () => ({ next, prev, step, setStep }),
      [step, setStep, next, prev]
    )

    return (
      <Context.Provider value={value}>
        <div {...props}>{steps.map(renderStep)}</div>
      </Context.Provider>
    )
  }
)

/** @jsxImportSource @emotion/react */
import { useMemo, ReactNode } from 'react'
import Button from 'react-bootstrap/Button'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'

import { HstPatientPaperworkDto, Order } from 'store/types'

interface IProps {
  nextStep: () => void
  order: Order
  savePatientPaperwork: (data: Partial<HstPatientPaperworkDto>) => Promise<void>
}

const formValidation = Yup.object().shape({
  heightFt: Yup.string().required('Required'),
  heightIn: Yup.string().required('Required'),
  weight: Yup.string().required('Required'),
  sittingReading: Yup.string().required('Required'),
  watchingTv: Yup.string().required('Required'),
  sittingInactive: Yup.string().required('Required'),
  passengerInCar: Yup.string().required('Required'),
  lyingDown: Yup.string().required('Required'),
  sittingAndTalking: Yup.string().required('Required'),
  sittingQuietly: Yup.string().required('Required'),
  carStopped: Yup.string().required('Required'),
})

const RowRadios = ({ name }: { name: string }): ReactNode[] => {
  return [0, 1, 2, 3].map((val) => (
    <td key={val} css={{ textAlign: 'center' }}>
      <div className="form-check">
        <Field type="radio" name={name} className="form-check-input" value={val.toString()} />
      </div>
    </td>
  ))
}

const calcSleepynessTotal = (values): number => {
  const { heightFt, heightIn, weight, ...sleepyness } = values
  const total = Object.values(sleepyness).reduce(
    (acc: number, val: string) => acc + Number(val),
    0,
  ) as number
  return total
}

const SleepinessScale = ({ nextStep, order, savePatientPaperwork }: IProps) => {
  const initialValues = useMemo(
    () => ({
      heightFt:
        order?.hst?.paperwork?.data?.sleepinessScale?.heightFt ?? order.patient.heightFt ?? '',
      heightIn:
        order?.hst?.paperwork?.data?.sleepinessScale?.heightIn ?? order.patient.heightIn ?? '',
      weight: order?.hst?.paperwork?.data?.sleepinessScale?.weight ?? order.patient.weight ?? '',
      sittingReading: order?.hst?.paperwork?.data?.sleepinessScale?.sittingAndReading ?? '',
      watchingTv: order?.hst?.paperwork?.data?.sleepinessScale?.watchingTv ?? '',
      sittingInactive: order?.hst?.paperwork?.data?.sleepinessScale?.sittingInactive ?? '',
      passengerInCar: order?.hst?.paperwork?.data?.sleepinessScale?.passengerInCar ?? '',
      lyingDown: order?.hst?.paperwork?.data?.sleepinessScale?.lyingDown ?? '',
      sittingAndTalking: order?.hst?.paperwork?.data?.sleepinessScale?.sittingAndTalking ?? '',
      sittingQuietly: order?.hst?.paperwork?.data?.sleepinessScale?.sittingQuietly ?? '',
      carStopped: order?.hst?.paperwork?.data?.sleepinessScale?.inACarStoppedForMinutes ?? '',
    }),
    [order],
  )

  return (
    <div className="container" style={{ maxWidth: 720 }}>
      <div className="mb-3">
        <h2>The Epworth Sleepiness Scale</h2>
        <div className="mt-5 mb-3 pt-3 border-top" />
        <Formik
          enableReinitialize={true}
          validationSchema={formValidation}
          initialValues={initialValues}
          onSubmit={async (values) => {
            const { heightFt, heightIn, weight, ...sleepyness } = values
            try {
              await savePatientPaperwork({
                orderId: Number(order.id),
                meta: { completedStep: 'sleepinessScale' },
                data: {
                  sleepinessScale: {
                    heightFt,
                    heightIn,
                    weight,
                    sittingAndReading: sleepyness.sittingReading,
                    watchingTv: sleepyness.watchingTv,
                    sittingInactive: sleepyness.sittingInactive,
                    passengerInCar: sleepyness.passengerInCar,
                    lyingDown: sleepyness.lyingDown,
                    sittingAndTalking: sleepyness.sittingAndTalking,
                    sittingQuietly: sleepyness.sittingQuietly,
                    inACarStoppedForMinutes: sleepyness.carStopped,
                    total: calcSleepynessTotal(values),
                  },
                },
              })
              nextStep()
            } catch (err) {}
          }}>
          {({ values, errors, touched, handleChange }) => (
            <Form id="patientUpsert">
              <div className={`container`}>
                <div className="row">
                  <div className="col-sm-12 col-lg-4 form-group textGray">
                    <label htmlFor="height">My height is</label>
                    <span className="text-danger">*</span>
                    <div className="d-flex">
                      <div className="input-group mr-2">
                        <Field
                          type="text"
                          name="heightFt"
                          className="form-control"
                          placeholder="Feet"
                          value={values.heightFt}
                          onChange={handleChange}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">ft</span>
                        </div>
                      </div>
                      <div className="input-group">
                        <Field
                          type="text"
                          name="heightIn"
                          className="form-control"
                          placeholder="Inches"
                          value={values.heightIn}
                          onChange={handleChange}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">in</span>
                        </div>
                      </div>
                    </div>
                    {(touched.heightFt && errors.heightFt) ||
                    (touched.heightIn && errors.heightIn) ? (
                      <div className="text-danger">{errors.heightFt || errors.heightIn}</div>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="col-sm-4 col-lg-4 form-group textGray">
                    <label htmlFor="weight">Weight</label>
                    <span className="text-danger">*</span>
                    <div className="input-group">
                      <Field
                        type="text"
                        name="weight"
                        className="form-control"
                        placeholder="Weight"
                        value={values.weight}
                        autoComplete="off"
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">lbs</span>
                      </div>
                    </div>
                    {touched.weight && errors.weight ? (
                      <div className="text-danger">{errors.weight}</div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
              <p>
                How likely are you to doze off or fall asleep in the following situations, in
                contrast to feeling just tired? This refers to your usual way of life in recent
                times. Even if you haven’t done some of these things recently try to work out how
                they would have affected you.
                <br />
                <br />
                <strong>
                  Use the following scale to choose the most appropriate number for each situation:
                  <ul className="pl-3">
                    <li>0 = would never doze</li>
                    <li>1 = slight chance of dozing</li>
                    <li>2 = moderate chance of dozing</li>
                    <li>3 = high chance of dozing</li>
                  </ul>
                </strong>
              </p>
              <p css={{ textDecoration: 'underline' }}>
                It is important that you answer each question as best you can. Please only check one
                answer in each row.
              </p>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr css={{ textAlign: 'center' }}>
                      <th scope="col" />
                      <th scope="col">0 = Never Doze Off</th>
                      <th scope="col">1 = Slight Chance of Dozing</th>
                      <th scope="col">2 = Moderate Chance of Dozing</th>
                      <th scope="col">3 = High Chance of Dozing</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Sitting and reading</td>
                      <RowRadios name="sittingReading" />
                      {touched.sittingReading && errors.sittingReading ? (
                        <div className="text-danger">{errors.sittingReading}</div>
                      ) : (
                        ''
                      )}
                    </tr>
                    <tr>
                      <td>Watching TV</td>
                      <RowRadios name="watchingTv" />
                      {touched.watchingTv && errors.watchingTv && (
                        <div className="text-danger mt-3">{errors.watchingTv}</div>
                      )}
                    </tr>
                    <tr>
                      <td>Sitting inactive in a public place</td>
                      <RowRadios name="sittingInactive" />
                      {touched.sittingInactive && errors.sittingInactive && (
                        <div className="text-danger mt-3">{errors.sittingInactive}</div>
                      )}
                    </tr>
                    <tr>
                      <td>As a passenger in a car for an hour without a break</td>
                      <RowRadios name="passengerInCar" />
                      {touched.passengerInCar && errors.passengerInCar && (
                        <div className="text-danger mt-3">{errors.passengerInCar}</div>
                      )}
                    </tr>
                    <tr>
                      <td>Lying down to rest in the afternoon when circumstances permit</td>
                      <RowRadios name="lyingDown" />
                      {touched.lyingDown && errors.lyingDown && (
                        <div className="text-danger mt-3">{errors.lyingDown}</div>
                      )}
                    </tr>
                    <tr>
                      <td>Sitting and talking to someone</td>
                      <RowRadios name="sittingAndTalking" />
                      {touched.sittingAndTalking && errors.sittingAndTalking && (
                        <div className="text-danger mt-3">{errors.sittingAndTalking}</div>
                      )}
                    </tr>
                    <tr>
                      <td>Sitting quietly after lunch without alcohol</td>
                      <RowRadios name="sittingQuietly" />
                      {touched.sittingQuietly && errors.sittingQuietly && (
                        <div className="text-danger mt-3">{errors.sittingQuietly}</div>
                      )}
                    </tr>
                    <tr>
                      <td>In a car stopped for a few minutes</td>
                      <RowRadios name="carStopped" />
                      {touched.carStopped && errors.carStopped && (
                        <div className="text-danger mt-3">{errors.carStopped}</div>
                      )}
                    </tr>
                    <tr css={{ paddingTop: 10 }}>
                      <td>
                        <strong>Total Score</strong>
                      </td>
                      <td css={{ textAlign: 'center' }}>
                        <strong>{calcSleepynessTotal(values)}</strong>
                        <br />
                      </td>
                      <td colSpan={3} />
                    </tr>
                  </tbody>
                </table>
              </div>
              <div css={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default SleepinessScale

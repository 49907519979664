import AsyncCreatableSelect from 'react-select/async'
import { StylesConfig } from 'react-select'

import * as generalHelper from 'helpers/generalHelper'

interface IProps {
  handleChange: (v: any) => void
  handleNew?: () => void
  loadOptions: (v: string) => Promise<{ label: string; value: string }[]>
  value: number | string
  placeholder?: string
  autoFocus?: boolean
  isClearable?: boolean
  createLabel?: string
  isValidNewOption?: (l: string) => string
  noOptionsMessage?: string
  defaultInputValue?: string
  loadingMessage?: any
  onDelete?: () => void
  styles?: StylesConfig
  cacheOptions?: any
  disabled?: boolean
  onInputChange?: (v: string) => void
}

const AsyncCreatableSelectComponent = (props: IProps) => {
  const handleChange = props.handleChange ?? (() => {})
  const handleNew = props.handleNew ?? (() => {})
  const onDelete = props.onDelete ?? (() => {})
  const loadOptions = props.loadOptions
    ? async (input: string) => {
        const options = await props.loadOptions(input)
        return [
          ...(options || []),
          ...(props.handleNew ? [{ value: '__new__', label: 'Add New Payor' }] : []),
        ]
      }
    : () => {}
  const value = props.value ?? {}
  const placeholder = props.placeholder ?? 'Search'
  const autoFocus = props.autoFocus ?? false
  const isClearable = props.isClearable ?? true
  const createLabel = props.createLabel ?? 'Create'
  const isValidNewOption = props.isValidNewOption ?? ((label: string) => label)
  const noOptionsMessage = props.noOptionsMessage ?? 'Nothing found'
  const defaultInputValue = props.defaultInputValue
  const loadingMessage = props.loadingMessage
    ? (input: string) => props.loadingMessage
    : (input: string) => generalHelper.t('Loading data...')

  return (
    <AsyncCreatableSelect
      key={props.cacheOptions}
      cacheOptions={props.cacheOptions}
      defaultOptions
      isDisabled={props.disabled ?? false}
      value={value}
      loadOptions={loadOptions}
      isClearable={isClearable}
      onChange={(selection) => {
        if (selection?.value === '__new__') {
          return handleNew()
        }
        handleChange(selection)
      }}
      cacheOptions={false}
      isValidNewOption={isValidNewOption}
      loadingMessage={loadingMessage}
      menuPortalTarget={document.body}
      allowCreateWhileLoading={false}
      styles={{
        menuPortal: (base) => ({
          ...base,
          zIndex: 9999,
        }),
        ...props.styles,
      }}
      noOptionsMessage={() => generalHelper.t(noOptionsMessage)}
      removeSelected={false}
      autoFocus={autoFocus}
      defaultInputValue={defaultInputValue}
      placeholder={`${generalHelper.t(placeholder)}...`}
      formatCreateLabel={(userInput) => `${generalHelper.t(createLabel)} ${userInput}`}
      onInputChange={props.onInputChange}
    />
  )
}

export default AsyncCreatableSelectComponent

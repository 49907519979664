import { useState, SyntheticEvent } from 'react'
import { Formik } from 'formik'

import Button from '../../atoms/Button'
import TitleLead from '../../atoms/TitleLead'
import PhysicianSelectedBox from './PhysicianSelectedBox'
import { PhysicianSearch, SearchFormValues } from 'components/organisms/physician/PhysicianSearch'
import PhysicianForm from './PhysicianForm'
import * as generalHelper from '../../../helpers/generalHelper'
import { Order, Physician, PhysicianAddress } from 'store/types'

interface IProps {
  lastPatientOrder?: Order
  physician?: Physician
  physicianLocation?: PhysicianAddress
  onCancel: () => void
  onSaved: () => void
}

export interface PhysicianFormValues {
  npi: string
  firstName: string
  lastName: string
  credential: string
  telephone: string
  fax: string
  addressStreetLine1: string
  addressStreetLine2: string
  addressCity: string
  addressState: string
  addressZip: string
  status: string
}

interface SelectedPhysician {
  physician: Physician | null
  physicianLocation: PhysicianAddress | null
  locationIndex: number
}

const PhysicianInfoContainer = ({
  lastPatientOrder,
  physician: physicianProp,
  physicianLocation: physicianLocationProp,
  onSaved,
  onCancel,
}: IProps) => {
  const [searchValues, setSearchValues] = useState<SearchFormValues>({})
  const [resultsLimit, setResultsLimit] = useState(4)
  const [selectedLatestPhy, setSelectedLatestPhy] = useState('')
  const [isEditMode, setIsEditMode] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState<SelectedPhysician>({
    physician: physicianProp,
    physicianLocation: physicianLocationProp,
    locationIndex: 0,
  })

  const handleClear = (event?: SyntheticEvent) => {
    if (event) {
      event.preventDefault()
    }
    setSearchValues({})
    setSelectedLatestPhy('')
    setResultsLimit(0)
  }

  const reset = () => {
    setSelectedOptions({ physician: null, physicianLocation: null, locationIndex: 0 })
    setIsEditMode(false)
  }

  const setPhysician = async (physician: Physician, physicianLocation: PhysicianAddress) => {
    setSelectedOptions({ physician, physicianLocation, locationIndex: 0 })
  }

  const { physician, physicianLocation } = selectedOptions

  const getPhysicianInitialValues = (
    physician?: Physician,
    physicianLocation?: PhysicianAddress,
  ): PhysicianFormValues => ({
    npi: physician?.npi || '',
    firstName: physician?.firstName || '',
    lastName: physician?.lastName || '',
    credential: physician?.credential || '',
    telephone: physicianLocation?.telephone || '',
    fax: physicianLocation?.fax || '',
    addressStreetLine1: physicianLocation?.addressStreetLine1 || '',
    addressStreetLine2: physicianLocation?.addressStreetLine2 || '',
    addressCity: physicianLocation?.addressCity || '',
    addressState: physicianLocation?.addressState || '',
    addressZip: physicianLocation?.addressZip || '',
    status: physicianLocation?.status || '',
  })

  return (
    <Formik
      initialValues={getPhysicianInitialValues(physicianProp, physicianLocationProp)}
      onSubmit={(values) => {
        const locationValue = {
          telephone: values.telephone,
          fax: values.fax,
          addressStreetLine1: values.addressStreetLine1,
          addressStreetLine2: values.addressStreetLine2,
          addressCity: values.addressCity,
          addressState: values.addressState,
          addressZip: values.addressZip,
        }
        const physicianValue = {
          npi: values.npi,
          firstName: values.firstName,
          lastName: values.lastName,
          credential: values.credential,
          status: values.status,
          ...locationValue,
        }

        // append `id` for existing entities
        if (physician?.id) physicianValue.id = physician.id

        onSaved({
          physician: {
            ...physicianValue,
          },
          physicianLocation: {
            ...locationValue,
          },
        })
      }}>
      {(formik) => (
        <div>
          <div className="row border bg-white m-2 mb-3 pb-3">
            <div className="col-sm-12 d-flex flex-column px-3">
              <div className="d-flex justify-content-between align-items-center">
                <TitleLead className="ml-2 my-3" label={generalHelper.t('UPDATING PHYSICIAN')} />

                <div className="d-flex">
                  <Button
                    onClick={onCancel}
                    className="btn btn-default mr-2"
                    label="Cancel"
                    type="button"
                  />
                  <Button
                    disabled={!physician}
                    type="button"
                    onClick={() => formik.handleSubmit()}
                    className="btn btn-primary"
                    label="Save Changes"
                  />
                </div>
              </div>
              <hr className="w-100 mt-0" />
            </div>

            <div className="col-sm-12 px-3 mb-4">
              {physician ? (
                <PhysicianSelectedBox
                  isEditMode={isEditMode}
                  onEdit={setIsEditMode}
                  onRemove={() => {
                    formik.setValues({})
                    reset()
                  }}
                  physician={physician}
                  physicianLocation={physicianLocation}
                />
              ) : (
                <div className="card">
                  <div className="card-body text-center font-italic text-muted">
                    {generalHelper.t('No Physician is currently assigned to this order')}.
                  </div>
                </div>
              )}
            </div>

            {!physician && (
              <div className="col-sm-12 px-3">
                <PhysicianSearch
                  order={lastPatientOrder}
                  currentOfficeId={lastPatientOrder?.officeId}
                  searchValues={searchValues}
                  setSearchValues={setSearchValues}
                  resultsLimit={resultsLimit}
                  setPhysician={(physician, physicianAddress) => {
                    setPhysician(physician, physicianAddress)
                    formik.setValues(getPhysicianInitialValues(physician, physicianAddress))
                  }}
                  setResultsLimit={setResultsLimit}
                  handleClear={handleClear}
                  selectedLatestPhy={selectedLatestPhy}
                  setSelectedLatestPhy={setSelectedLatestPhy}
                />
              </div>
            )}

            {isEditMode && physician ? (
              <div className="col-sm-12 px-3">
                <PhysicianForm {...formik} />
              </div>
            ) : null}
          </div>
        </div>
      )}
    </Formik>
  )
}

export default PhysicianInfoContainer

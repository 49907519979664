import createEmitter from './emitter'
import { isString } from './utils'

const Socket = (url, options = {}) => {
  const { autoConnect = true, timeout = 2000, attempts = Infinity } = options

  // queue messages if socket is not ready
  const queue = []

  let socket = null
  let timer = null
  let attempt = 0
  let online = false
  let closed = false

  const emitter = createEmitter()

  const encode = (data) => {
    try {
      return JSON.stringify(data)
    } catch (error) {
      console.error(error)
      return ''
    }
  }

  const decode = (data) => {
    try {
      console.log('DATA', data)
      return JSON.parse(data)
    } catch (error) {
      console.error(error)
      return {}
    }
  }

  const open = () => {
    if (online) return

    try {
      socket = new WebSocket(url)
      socket.onopen = onOpen
      socket.onerror = onError
      socket.onclose = onClose
      socket.onmessage = onMessage
    } catch (error) {
      error.type = 'ServiceError'
      emitter.emit('error', error)
    }
  }

  const send = (message) => {
    if (!socket && !closed) {
      // queued messages
      queue.push(message)
      return false
    }

    if (!socket || socket.readyState !== WebSocket.OPEN) {
      const error = new Error('Socket is not open')
      error.type = 'ServiceError'
      emitter.emit('error', error)
      console.log(socket, socket?.readyState)
      return false
    }

    if (!isString(message)) {
      message = encode(message)
    }

    try {
      socket.send(message)
    } catch (e) {
      const error = new Error(e.message || 'Internal service error')
      error.type = 'ServiceError'
      emitter.emit('error', error)
      return false
    }

    return true
  }

  const reconnect = () => {
    if (online || closed) return

    if (attempt++ > attempts) {
      return onTimeout()
    }

    setTimeout(() => open(emitter.emit('reconnect', { attempt })), timeout)
  }

  const close = (isReconnect) => {
    online = false

    if (!isReconnect) {
      closed = true
    }

    if (socket) {
      socket.close()
      socket = null
    }
  }

  const onOpen = (event) => {
    online = true
    closed = false
    attempt = 0
    emitter.emit('open', event)

    // execute queued messages
    while (queue.length) {
      send(queue.pop())
    }
  }

  const onTimeout = () => {
    emitter.emit('timeout')
    close()
  }

  const onMessage = (event) => {
    let message = 'data' in event ? event.data : null

    if (!message || !message.length) {
      return send('')
    }

    message = decode(message)

    // if ('data' in event && !message.event && event.event) {
    //   message.event = event.event
    // }

    if (message.message) {
      return emitter.emit('message', message)
    }

    emitter.emit('event', message)
  }

  const onError = (e) => {
    // console.error(e)
    const error = new Error(e.message || 'Internal service error')
    error.type = 'ServiceError'
    emitter.emit('error', error)
  }

  const onClose = (event) => {
    online = false
    clearTimeout(timer)
    emitter.emit('close', event)
    return reconnect()
  }

  // auto connect if autoConnect flag is enabled
  if (autoConnect) {
    open()
  }

  return Object.assign(emitter, { send, open, close })
}

export default Socket

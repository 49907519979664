import versionConfig from 'config/version.json'

const Footer = () => {
  return (
    <footer className="main-footer">
      <div className="float-right d-none d-sm-inline-block">v.{versionConfig.version}</div>
    </footer>
  )
}

export default Footer

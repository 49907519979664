import { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import styled from '@emotion/styled'
import { Document, Page, pdfjs } from 'react-pdf'

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`

interface IProps {
  closeDialog: () => void
  reportUrl: string
}

const StyledModal = styled(Modal)`
  .modal-content {
    min-height: calc(100vh - 3rem);
  }
`

const StyledModalBody = styled(Modal.Body)`
  .react-pdf__Page {
    border-bottom: 1px solid #aaa;
  }
  canvas {
    width: 100% !important;
    height: unset !important;
  }
`

export const ReportViewerDialog = ({ closeDialog, reportUrl }: IProps) => {
  const [numPages, setNumPages] = useState<number | null>(null)
  const [scale, setScale] = useState(2)

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages)
  }

  const zoomIn = () => {
    if (scale <= 2) {
      setScale(scale + 0.2)
    }
  }

  const zoomOut = () => {
    if (scale >= 0.5) {
      setScale(scale - 0.2)
    }
  }

  useEffect(() => {
    setNumPages(null)
    setScale(1.0)
    setNumPages(null)
  }, [reportUrl])

  return (
    <StyledModal centered show={!!reportUrl} onHide={closeDialog} size="lg">
      <Modal.Header>
        <Modal.Title>Report</Modal.Title>
      </Modal.Header>
      <StyledModalBody>
        {reportUrl && (
          <Document
            loading={'Loading PDF ...'}
            file={reportUrl}
            onLoadSuccess={onDocumentLoadSuccess}
            externalLinkTarget="_blank">
            {Array.from({ length: numPages }, (v, i) => i + 1).map((page) => (
              <Page loading="" pageNumber={page} scale={scale} key={page} />
            ))}
          </Document>
        )}
      </StyledModalBody>
    </StyledModal>
  )
}

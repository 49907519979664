const DisabledMask = () => (
  <div
    style={{
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 100,
      background: 'white',
      opacity: 0.66,
      position: 'absolute',
    }}
  />
)

export default DisabledMask

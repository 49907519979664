import { forms } from 'components/dme/printableForms/formData'
import { useMemo } from 'react'

import * as generalHelper from 'helpers/generalHelper'
import { formatPhoneNumber } from 'helpers/format'
import { Order } from 'store/types'

export const useDeviceForms = (order: Order) => {
  const downloadForms = useMemo(() => {
    if (!order?.device?.model) return

    const deviceForms = forms.filter((f) => f.model === order.device.model)
    if (deviceForms?.length === 0) return
    if (deviceForms?.length > 1) {
      return deviceForms.find((f) => f.subModel === order?.testType)
    }
    return deviceForms[0]
  }, [order])

  const downloadDocument = (documentKey: string) => async () => {
    const orderInfo = `${order?.patient?.firstName} ${
      order?.patient?.lastName
    }, ${formatPhoneNumber(
      order?.patient.homePhone,
    )}, Order #${order?.id}, Device ${order?.device?.label} `

    const conditions =
      order?.tests?.map((test) => {
        let description = ''
        if (!test.oxygen) {
          description = 'On Room Air'
        } else {
          description = `On Oxygen @ ${test.oxygenLpm || 'current'} LPM`
        }
        if (!test.deviceType) return description

        if (['Implant', 'Dental'].includes(test.deviceType))
          return `${description} with ${test.deviceType} Device`

        if (test.deviceType === 'Ventilator')
          return `${description} with ${test.ventilatorType} ${test.deviceType}`

        if (test.deviceType === 'PAP')
          return `${description} with ${test.papType} ${
            test.papType === 'APAP'
              ? `@ ${test.apapMin}/${test.apapMax}`
              : test.papType === 'BiPAP'
                ? `@ ${test.bipapI}/${test.bipapE}`
                : `@ ${test.cpap}`
          }`

        return description
      }) || []
    const data = await generalHelper.apiPost(
      `/dme/office/${order?.officeId}/documents?documentKey=${encodeURIComponent(documentKey)}`,
      [
        {
          x: 280,
          y: 715,
          size: 10,
          overlay: orderInfo,
        },
        {
          x: 30,
          y: 430,
          size: 12,
          overlay:
            'Your physician would like you to test overnight with the following test conditions:',
          bold: true,
        },
        ...conditions.map((c, i) => ({
          x: 30,
          y: 430 - (i + 1) * 20,
          size: 12,
          overlay: `Night ${i + 1}: ${c}`,
        })),
      ],
    )
    window.open(data, '_blank')
  }

  return { downloadForms, downloadDocument }
}

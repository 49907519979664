/** @jsxImportSource @emotion/react */
import { useState } from 'react'
import styled from '@emotion/styled'
import { Button } from 'react-bootstrap'

import {
  useAddCompanyRegionMutation,
  useUpdateCompanyRegionMutation,
  useDeleteCompanyRegionMutation,
} from 'store/services/office'
import { confirm } from 'components/molecules/Confirmation'
import Input from 'components/atoms/Input'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

interface IProps {
  regions: { regionName: string; uuid: string }[]
  companyId: string
  disabled?: boolean
}

export const Regions = ({ regions, disabled, companyId }: IProps) => {
  const [newEntry, setNewEntry] = useState('')
  const [editEntry, setEditEntry] = useState('')
  const [editRegion, setEditRegion] = useState<string | null>(null)
  const [addRegion] = useAddCompanyRegionMutation()
  const [updateRegion] = useUpdateCompanyRegionMutation()
  const [deleteRegion] = useDeleteCompanyRegionMutation()

  return (
    <Container>
      <span style={{ color: '#6D757E' }} className="mb-1">
        Add Region
      </span>
      <div className="d-flex w-100">
        <Input
          parentDiv={null}
          name="newEntry"
          disabled={disabled}
          className="form-control mr-3"
          placeholder="Add Email Domain"
          value={newEntry}
          handleChange={(e) => setNewEntry(e.target.value)}
        />
        <Button
          disabled={!newEntry || disabled}
          variant="primary"
          style={{ minWidth: 100 }}
          onClick={async () => {
            if (newEntry) {
              await addRegion({ id: companyId, regionName: newEntry }).unwrap()
              setNewEntry('')
            }
          }}>
          Add Region
        </Button>
      </div>
      {Array.isArray(regions) && (
        <>
          <div
            className="row flex-row flex-nowrap justify-content-between align-items-center p-2 my-2"
            style={{
              color: '#6D757E',
              borderBottom: '1px solid #ced4da',
            }}>
            <div style={{ width: '100%' }}>Region Name</div>
            <div className="flex-shrink-0 pr-3" style={{ width: 100, textAlign: 'end' }}>
              Action
            </div>
          </div>
          {!regions?.length && (
            <div className="row my-1">
              <div className="col-sm-12 p-0">
                <div className="list-group text-secondary">
                  <div className="list-group-item list-group-item-action flex-column align-items-center">
                    <div className="d-flex py-1 justify-content-center align-items-center">
                      <span>No Regions Entered</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {regions.map((region) => (
            <div className="row my-1" key={region.uuid}>
              <div className="col-sm-12 p-0">
                <div className="list-group text-secondary">
                  <div className="list-group-item list-group-item-action flex-column align-items-start">
                    <div className="d-flex py-1 justify-content-between align-items-center">
                      {editRegion !== region.uuid ? (
                        <>
                          <span css={{ flex: 1 }}>{region.regionName}</span>
                          <Button
                            variant="primary"
                            size="sm"
                            css={{ minWidth: '60px' }}
                            onClick={() => {
                              setEditRegion(region.uuid)
                              setEditEntry(region.regionName)
                            }}
                            disabled={disabled}>
                            Edit
                          </Button>
                          <Button
                            variant="primary"
                            className="ml-2"
                            css={{ minWidth: '60px' }}
                            size="sm"
                            onClick={() =>
                              confirm('region')
                                .then(() => deleteRegion({ id: companyId, uuid: region.uuid }))
                                .catch(() => {})
                            }
                            disabled={disabled}>
                            Remove
                          </Button>
                        </>
                      ) : (
                        <>
                          <Input
                            parentDiv={null}
                            name="editEntry"
                            className="form-control mr-3"
                            css={{ width: '100%', marginRight: '20px' }}
                            placeholder="Edit Region Name"
                            value={editEntry}
                            handleChange={(e) => setEditEntry(e.target.value)}
                          />
                          <Button
                            variant="primary"
                            size="sm"
                            css={{ minWidth: '60px' }}
                            onClick={() =>
                              updateRegion({
                                id: companyId,
                                data: { uuid: region.uuid, regionName: editEntry },
                              })
                                .unwrap()
                                .then(() => {
                                  setEditRegion(null)
                                  setEditEntry('')
                                })
                            }
                            disabled={!editEntry}>
                            Update
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </Container>
  )
}

import { MouseEvent } from 'react'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import styled from '@emotion/styled'

import { VerifyType } from './OrderEdit'

const StyledButtonGroup = styled(ButtonGroup)`
  .btn-secondary {
    background-color: #c2c8cf;
    border-color: #c2c8cf;

    :hover {
      background-color: #5a6268;
      border-color: #5a6268;
    }
  }

  .btn-primary {
    background-color: #2286f2;
    border-color: #2286f2;
  }
`

interface IProps {
  value: VerifyType
  onChange: (v: VerifyType) => void
  disabled?: boolean
  verifyButtonDisabled?: boolean
}

export const VerifyStatusButton = ({ value, onChange, disabled, verifyButtonDisabled }: IProps) => {
  const handleChange = (type: VerifyType) => (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    onChange(type)
  }

  return (
    <StyledButtonGroup size="sm">
      <Button
        disabled={disabled}
        variant={value === 'failed' ? 'danger' : 'secondary'}
        onClick={handleChange('failed')}>
        FAILED
      </Button>

      <Button
        disabled={disabled}
        variant={value === 'pending' ? 'primary' : 'secondary'}
        onClick={handleChange('pending')}>
        PENDING
      </Button>
      <Button
        disabled={disabled || verifyButtonDisabled}
        variant={value === 'verified' ? 'success' : 'secondary'}
        onClick={handleChange('verified')}>
        VERIFIED
      </Button>
    </StyledButtonGroup>
  )
}

/** @jsxImportSource @emotion/react */
import { useRef, useState, useLayoutEffect } from 'react'
import { Field } from 'formik'
import Button from 'react-bootstrap/Button'
import SignatureCanvas from 'react-signature-canvas'

interface IProps {
  touched: any
  errors: any
  setFieldValue: (name: string, value: string) => void
}

export const SignaturePad = ({ touched, errors, setFieldValue }: IProps) => {
  const sigRef = useRef<any>()
  const sigParent = useRef<HTMLDivElement>()
  const [sigWidth, setSigWidth] = useState<number>(0)

  useLayoutEffect(() => {
    setSigWidth(sigParent.current?.offsetWidth)
  }, [])

  return (
    <div className="col form-group">
      <label htmlFor="signature">Sign the box below</label>
      <div className="sigHolder" css={{ display: 'block !important' }}>
        <div className="divSize" ref={sigParent}>
          <Field name="signature">
            {() => (
              <SignatureCanvas
                penColor="black"
                canvasProps={{
                  width: Math.min(sigWidth, 500),
                  height: 200,
                  className: 'sigCanvas',
                }}
                ref={sigRef}
                onEnd={() => {
                  const imageData = sigRef.current.toDataURL('image/png')
                  setFieldValue('signature', imageData)
                }}
              />
            )}
          </Field>
          {touched.signature && errors.signature ? (
            <div className="text-danger">{errors.signature}</div>
          ) : null}
        </div>
        <div className="">
          <Button
            onClick={() => {
              sigRef.current.clear()
              setFieldValue('signature', '')
            }}
            className="btn-default">
            Clear Signature
          </Button>
        </div>
      </div>
    </div>
  )
}

import * as Yup from "yup";

const verifyValidation = Yup.object().shape({
  email: Yup.string().email("Invalid Email").required("Required"),
  relationship: Yup.string().required("Required"),
  signature: Yup.string().required("Required"),
  signedBy: Yup.string().test(
    'match',
    'Required',
    function (signedBy) {
      return this.parent.relationship === 'self' || signedBy
    }
  )
});

export default verifyValidation;

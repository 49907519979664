const C50I = () => {
  return (
    <ul className="text-left">
      <li>
        <span>1.</span> Be sure the C50i USB cable is connected securely to your
        computer.
      </li>
      <li>
        <span>2.</span> Press the power button on the side of the oximeter
        opposite the USB connector until it powers on.
      </li>
      <li>
        <span>3.</span> <small>IMPORTANT:</small> Be sure the display reads
        "Finger Out" before you begin download.
      </li>
    </ul>
  )
}

export default C50I

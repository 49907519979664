import { Link } from 'react-router-dom'
import routes from '../constants/routes'
import * as generalHelper from '../../../helpers/generalHelper'

export default function () {
  return (
    <div>
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">{generalHelper.t('Lab Dashboard')}</h1>
            </div>
            {/* /.col */}
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item active">{generalHelper.t('Home')}</li>
              </ol>
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </div>
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header border-0">
                  <div className="d-flex justify-content-between">
                    <h3 className="card-title">{generalHelper.t('Dashboard')}</h3>
                  </div>
                </div>
                <div className="card-body row">
                  <div className="col-lg-4 col-6">
                    <div className="small-box bg-primary">
                      <div className="inner">
                        <h3>Offices</h3>

                        <p>
                          <i className="fa fa-search"></i> {generalHelper.t('Search')}
                        </p>
                      </div>
                      <div className="icon">
                        <i className="ion ion-bag"></i>
                      </div>
                      <Link
                        to={`${routes.index}${routes.office.root}`}
                        className="small-box-footer stretched-link">
                        <i className="fas fa-search"></i> {generalHelper.t('Search Office')}{' '}
                      </Link>
                    </div>
                  </div>
                  {/* <div className="col-12">
                                        <h3 className="text-success">
                                            Version Log{" "}
                                            <LinkLocal
                                                to={`${routes.index}${routes.gitLog.root}`}
                                                className="btn btn-info"
                                                label="Edit"
                                            />
                                        </h3>
                                        <div className="container-fluid">
                                            <div className="row">
                                                {gitLogGroup &&
                                                    Object.keys(gitLogGroup)
                                                        .sort(function (a, b) {
                                                            return b - a;
                                                        })
                                                        .map(
                                                            (
                                                                version,
                                                                version_index
                                                            ) => {
                                                                return (
                                                                    <div
                                                                        key={
                                                                            version_index
                                                                        }
                                                                        className="col-sm-2 border-bottom"
                                                                    >
                                                                        <p
                                                                            className={
                                                                                version_index ==
                                                                                0
                                                                                    ? "text-success text-bold"
                                                                                    : ""
                                                                            }
                                                                        >
                                                                            v.
                                                                            {
                                                                                version
                                                                            }{" "}
                                                                            {version_index ==
                                                                                0 && (
                                                                                <ExternalLink
                                                                                    to="https://documenter.getpostman.com/view/64508/Tz5jeLZV#intro"
                                                                                    label=""
                                                                                    target="_blank"
                                                                                    alt="Api Documentation"
                                                                                    icon="fa fa-file-code"
                                                                                    iconTitle="Open API Documentation"
                                                                                />
                                                                            )}
                                                                        </p>
                                                                        <ul>
                                                                            {Array.isArray(
                                                                                gitLogGroup[
                                                                                    version
                                                                                ]
                                                                            ) &&
                                                                                gitLogGroup[
                                                                                    version
                                                                                ].map(
                                                                                    (
                                                                                        row,
                                                                                        row_index
                                                                                    ) => {
                                                                                        return (
                                                                                            <li
                                                                                                key={
                                                                                                    row_index
                                                                                                }
                                                                                            >
                                                                                                <ExternalLink
                                                                                                    label={
                                                                                                        row.issue
                                                                                                    }
                                                                                                    target="_blank"
                                                                                                    to={`https://github.com/minorsolis/TestsmarterV3/issues/${row.issue}`}
                                                                                                />
                                                                                            </li>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                        </ul>
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                            </div>
                                        </div>
                                    </div> */}
                </div>
              </div>
              {/* /.card */}
            </div>
            {/* /.col-md-6 */}
            {/* /.col-md-6 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </div>
    </div>
  )
}

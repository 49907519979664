import { useMemo } from 'react'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'

import Box from './Box'
import Button from 'components/atoms/Button'
import NoData from 'components/atoms/NoData'
import { Note } from 'store/types'
import { cannedNotes } from './cannedNotes'

const validation = Yup.object().shape({
  newNote: Yup.string().required('Required'),
})

interface IProps {
  userContext: 'LAB' | 'DME'
  type?: 'scheduling' | 'labOnly'
  notes?: Note[]
  addNote: (n: string, type?: string) => Promise<void>
  pinNote?: (param: { uuid: string; pinned: boolean }) => Promise<void>
  deleteNote?: (param: { uuid: string }) => Promise<void>
}

const Panel = ({ userContext, type, notes, addNote, pinNote, deleteNote }: IProps) => {
  const keyboardAction = (event, cb) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      cb()
    }
  }

  const sortedNotes = useMemo(() => {
    if (!notes) return []
    return [...notes]
      ?.filter(
        (note) =>
          userContext === 'DME' ||
          (type === 'scheduling' ? note.type === 'scheduling' : note.type !== 'scheduling'),
      )
      .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
      .sort((a, b) => (a.pinned && !b.pinned ? -1 : 1))
  }, [notes, type])

  return (
    <>
      <div className="row mb-2">
        <div className="col">
          <div className="note_panel">
            {notes?.length ? (
              sortedNotes.map((note, i) => (
                <Box
                  pinNote={pinNote}
                  deleteNote={deleteNote}
                  userContext={userContext}
                  note={note}
                  key={i}
                />
              ))
            ) : (
              <NoData
                text={`No ${type === 'scheduling' ? 'Scheduling' : ''} Notes`}
                className="msg_noData font-italic py-3 my-2"
              />
            )}
          </div>
        </div>
      </div>
      <Formik
        initialValues={{ newNote: '' }}
        validationSchema={validation}
        enableReinitialize={true}
        onSubmit={async ({ newNote }, { resetForm }) => {
          await addNote(newNote, type)
          resetForm()
        }}>
        {({ touched, errors, isSubmitting, handleSubmit, setFieldValue }) => (
          <Form>
            {userContext === 'LAB' && (
              <div className="row ml-1 mr-1">
                <div className="col form-group">
                  <Field
                    className="form-control"
                    as="select"
                    name="cannedMsg"
                    onChange={(e) => {
                      setFieldValue('newNote', e.target.value)
                      setFieldValue('cannedMsg', '')
                    }}>
                    <option value="">Select Note Template</option>
                    {cannedNotes.map((s, i) => (
                      <option key={i} value={s.content}>
                        {s.title}
                      </option>
                    ))}
                  </Field>
                </div>
              </div>
            )}
            <div className="row mb-2 mr-1 ml-1">
              <div className="col form-group pt-1">
                <label htmlFor="note">Add a {type === 'scheduling' ? 'scheduling' : ''} note</label>
                <Field
                  component="textarea"
                  className="form-control"
                  name="newNote"
                  id="newNote"
                  rows="4"
                  placeholder="Enter your note..."
                />
                {touched.newNote && errors.newNote && (
                  <div className="text-danger">{errors.newNote}</div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col text-right mr-2">
                <Button id="btn-ts" label="Save Note" type="submit" disabled={isSubmitting} />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default Panel

import * as generalHelper from "../../helpers/generalHelper";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ------------------------------------------------------------
export const getStatuses = createAsyncThunk(
    "getStatuses",
    async (params, { rejectWithValue }) => {
        try {
            const data = await generalHelper.apiGet("/api/status", params);
            if (!data) {
                return rejectWithValue([]);
            }
            return data;
        } catch (error) {
            console.log("error", error);
        }
    }
);

// ------------------------------------------------------------
export const getStatus = createAsyncThunk(
    "getStatus",
    async (params, { rejectWithValue }) => {
        try {
            params.return_key = "one";
            const data = await generalHelper.apiGet("/api/status", params);
            if (!data) {
                return rejectWithValue([]);
            }
            return data;
        } catch (error) {
            console.log("error", error);
        }
    }
);

// ------------------------------------------------------------
export const updateStatus = createAsyncThunk(
    "updateStatus",
    async ({ id, params }, { rejectWithValue }) => {
        try {
            const data = await generalHelper.apiPut("/api/status", id, params);
            if (!data) {
                return rejectWithValue([]);
            }
            return data;
        } catch (error) {
            console.log("error", error);
        }
    }
);

// ------------------------------------------------------------
export const insertStatus = createAsyncThunk(
    "insertStatus",
    async (params, { rejectWithValue }) => {
        try {
            const data = await generalHelper.apiPost("/api/status", params);
            if (!data) {
                return rejectWithValue([]);
            }
            return data;
        } catch (error) {
            console.log("error", error);
        }
    }
);

// ------------------------------------------------------------
export const deleteStatus = createAsyncThunk(
    "deleteStatus",
    async ({ id, params }, { rejectWithValue }) => {
        try {
            const data = await generalHelper.apiDelete(
                "/api/status",
                id,
                params
            );
            if (!data) {
                return rejectWithValue([]);
            }
            return data;
        } catch (error) {
            console.log("error", error);
        }
    }
);

// ------------------------------------------------------------
const initialState = {
    pending: false,
    rows: [],
    row: {},
    update: {},
    insert: {},
    delete: {},
    rejected: false,
};

// ------------------------------------------------------------
const localSlice = createSlice({
    name: "status",
    initialState,
    extraReducers: {
        [getStatuses.rejected]: (state, action) => {
            state.pending = false;
            state.rejected = true;
            state.data = null;
        },
        [getStatuses.pending]: (state, action) => {
            state.pending = true;
            state.rejected = false;
            state.update = null;
        },
        [getStatuses.fulfilled]: (state, action) => {
            state.pending = false;
            state.rejected = false;
            state.rows = action.payload;
        },
        [getStatus.rejected]: (state, action) => {
            state.pending = false;
            state.rejected = true;
            state.data = null;
        },
        [getStatus.pending]: (state, action) => {
            state.pending = true;
            state.rejected = false;
            state.update = null;
        },
        [getStatus.fulfilled]: (state, action) => {
            state.pending = false;
            state.rejected = false;
            state.row = action.payload;
        },
        [updateStatus.rejected]: (state, action) => {
            state.pending = false;
            state.rejected = true;
            state.data = null;
        },
        [updateStatus.pending]: (state, action) => {
            state.pending = true;
            state.rejected = false;
            state.update = null;
        },
        [updateStatus.fulfilled]: (state, action) => {
            state.pending = false;
            state.rejected = false;
            state.update = action.payload;
        },
        [insertStatus.rejected]: (state, action) => {
            state.pending = false;
            state.rejected = true;
            state.data = null;
        },
        [insertStatus.pending]: (state, action) => {
            state.pending = true;
            state.rejected = false;
            state.update = null;
        },
        [insertStatus.fulfilled]: (state, action) => {
            state.pending = false;
            state.rejected = false;
            state.insert = action.payload;
        },
        [deleteStatus.rejected]: (state, action) => {
            state.pending = false;
            state.rejected = true;
            state.data = null;
        },
        [deleteStatus.pending]: (state, action) => {
            state.pending = true;
            state.rejected = false;
            state.update = null;
        },
        [deleteStatus.fulfilled]: (state, action) => {
            state.pending = false;
            state.rejected = false;
            state.delete = action.payload;
        },
    },
});

export default localSlice.reducer;

/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import { useAppSelector } from '../../../hooks/redux'
import { useGetPhysiciansStatsQuery } from '../../../store/services/physician'
import { PhysicianOrderCounts } from '../../../store/types'
import DataTable from '../../atoms/DataTable'
import Dropdown from '../../atoms/Dropdown'
import Input from '../../atoms/Input'
import { TableWrapper } from '../../atoms/TableWrapper'

const [yellow, green, blue, gray, red] = ['#e69c37', '#4ea45f', '#2c83bd', '#eeeeee', '#d42020']

const Wrap = styled.div`
  background: white;
  border-top: 3px solid ${blue};
  border-radius: 3px;
  padding: 10px;
  box-shadow: 0 1px 3px 1px #e8e5e5;
  margin-bottom: 10px;
`

const StyledTableWrapper = styled(TableWrapper)`
  margin-top: 20px;

  th {
    padding: 0 0.2rem 0.4rem 0.1rem;
  }

  td[role='cell'] {
    padding: 0.6rem 0.2rem;
    height: 40px;
  }

  td {
    vertical-align: middle;
    padding: 0.6rem;
    div {
      span {
        font-size: 0.9rem;
      }
    }
  }
`

const TableCount = styled.div`
  border-radius: 4px;
  background-color: ${gray};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  padding: 4px;
`

const PhysicianBlock = (props: {
  label: string | number
  timeRange: string
  count: number
  fullName: string
  officeName: string
  color: string
}) => (
  <div
    css={{
      borderRadius: 3,
      overflow: 'hidden',
      width: '100%',
      boxShadow: '3px 3px 10px 1px #f2f2f2',
    }}>
    <div
      css={{
        backgroundColor: 'white',
        borderTop: `3px solid ${props.color}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: 10,
      }}>
      <p
        className={'m-0'}
        css={{
          fontWeight: 700,
          fontSize: 12,
        }}>
        {props.label}
      </p>
      <p
        className={'m-0'}
        css={{
          fontSize: 12,
        }}>
        ({props.timeRange})
      </p>
      <h2 className={'m-0 p-0'}>{props.count}</h2>
      <p
        className={'m-0'}
        css={{
          fontWeight: 700,
          fontSize: 12,
        }}>
        {props.fullName}
      </p>
      <p
        className={'m-0'}
        css={{
          fontSize: 12,
        }}>
        {props.officeName}
      </p>
    </div>
  </div>
)

interface IProps {
  physicianOrderCounts: PhysicianOrderCounts | undefined
}

const PhysicianStats = (props: IProps) => {
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(12)
  const [sortOptions, setSortOptions] = useState<{ id: string; desc: boolean }>()

  const [search, setSearch] = useState('')
  const userOffice = useAppSelector((state) =>
    state.init.offices.find((x) => Number(x.id) === Number(state.init.currentOfficeId)),
  )
  const officeId = useAppSelector((state) => state.init.currentOfficeId)
  const {
    data: physicianStats,
    refetch,
    isLoading,
  } = useGetPhysiciansStatsQuery({
    officeId: Number(officeId),
    search,
    page: currentPage,
    size: pageSize,
    sortField: sortOptions?.id,
    sortOrder: sortOptions?.desc ? 'desc' : 'asc',
  })

  useEffect(() => {
    refetch()
  }, [sortOptions, currentPage, pageSize])

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const header = [
    { accessor: 'fullName', Header: 'Name' },
    { accessor: 'telephone', Header: 'Phone' },
    { accessor: 'addressStreetLine1', Header: 'Address' },
    { accessor: 'addressCity', Header: 'City' },
    { accessor: 'addressState', Header: 'State' },
    { accessor: 'addressZip', Header: 'Zip' },
    {
      accessor: 'total',
      Header: 'Total',
      Cell: ({ row }) => <TableCount>{row.original.total}</TableCount>,
    },
    {
      accessor: 'month',
      Header: months[new Date().getMonth()],
      Cell: ({ row }) => <TableCount>{row.original.month}</TableCount>,
    },
    {
      accessor: 'year',
      Header: new Date().getFullYear(),
      Cell: ({ row }) => <TableCount>{row.original.year}</TableCount>,
    },
    {
      accessor: 'qualified',
      Header: <i className="fas fa-solid fa-check" css={{ color: green }} />,
      Cell: ({ row }) => <TableCount>{row.original.qualified}</TableCount>,
    },
    {
      accessor: 'nonQualified',
      Header: <span css={{ color: red, fontWeight: 600 }}>✕</span>,
      Cell: ({ row }) => <TableCount>{row.original.nonQualified}</TableCount>,
    },
    {
      accessor: 'completed',
      Header: 'Completed',
      Cell: ({ row }) => <TableCount>{row.original.completed}</TableCount>,
    },
  ]

  return (
    <>
      <Wrap>
        <p
          css={{
            fontSize: 16,
          }}>
          <i
            className="fas fa-star"
            css={{
              marginRight: 5,
              color: '#f9d949',
            }}
          />
          Top Referrers
        </p>
        <div
          className={'d-flex'}
          css={{
            gap: 15,
          }}>
          <PhysicianBlock
            label={'# OF ORDERS'}
            timeRange={'ALL TIME TOTAL'}
            count={props.physicianOrderCounts?.total?.amount || 0}
            fullName={props.physicianOrderCounts?.total?.fullName || '--'}
            officeName={userOffice?.officeName || '--'}
            color={red}
          />
          <PhysicianBlock
            label={months[new Date().getMonth()].toUpperCase()}
            timeRange={`${new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              1,
            ).toLocaleDateString()} - ${new Date().toLocaleDateString()}`}
            count={props.physicianOrderCounts?.month?.amount || 0}
            fullName={props.physicianOrderCounts?.month?.fullName || '--'}
            officeName={userOffice?.officeName || '--'}
            color={yellow}
          />
          <PhysicianBlock
            label={new Date().getFullYear()}
            timeRange={`${new Date(
              new Date().getFullYear(),
              0,
              1,
            ).toLocaleDateString()} - ${new Date().toLocaleDateString()}`}
            count={props.physicianOrderCounts?.year?.amount || 0}
            fullName={props.physicianOrderCounts?.year?.fullName || '--'}
            officeName={userOffice?.officeName || '--'}
            color={blue}
          />
          <PhysicianBlock
            label={'COMPLETED'}
            timeRange={'ALL TIME TOTAL'}
            count={props.physicianOrderCounts?.completed?.amount || 0}
            fullName={props.physicianOrderCounts?.completed?.fullName || '--'}
            officeName={userOffice?.officeName || '--'}
            color={green}
          />
        </div>
      </Wrap>
      <Wrap>
        <div className={'d-flex justify-content-between'}>
          <div>
            <Dropdown
              onClick={(v) => {
                setCurrentPage(0)
                setPageSize(Number(v))
              }}
              buttonTypeClass="btn-outline-primary"
              options={[
                { label: '12' },
                {
                  label: '20',
                },
                {
                  label: '50',
                },
                {
                  label: '100',
                },
                {
                  label: '200',
                },
              ]}
              additionalText="Records"
            />
          </div>
          <div
            className={'d-flex align-items-center'}
            css={{
              width: 320,
            }}>
            <p
              css={{
                margin: 0,
                marginRight: 10,
                fontWeight: 600,
              }}>
              Search
            </p>
            <Input
              value={search || ''}
              label="Search"
              placeholder="Name, Phone, Address, City, Zip"
              name="search"
              parentDiv={null}
              handleChange={(e) => setSearch(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  refetch()
                }
              }}
            />
          </div>
        </div>
        <StyledTableWrapper>
          <DataTable
            data={physicianStats}
            status={isLoading}
            tableColumns={header}
            pageSize={pageSize}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            setSortOptions={setSortOptions}
            useCustomSortHandler={true}
            entityName="physicians"
          />
        </StyledTableWrapper>
      </Wrap>
    </>
  )
}

export default PhysicianStats

import { useState } from 'react'

import { useAppSelector } from 'hooks/redux'
import Table from '../../atoms/Table'
import Input from '../../atoms/Input'
import { handleChange } from '../../atoms/Upsert'
import DashboardContent from '../atoms/DashboardContent'
import ReportPreferenceOptions from '../../molecules/ReportPreferenceOptions'
import * as generalHelper from 'helpers/generalHelper'
import { formatPhoneNumber } from 'helpers/format'
import {
  useGetOfficePhysiciansQuery,
  useUpdatePhysicianSettingsMutation,
} from 'store/services/physician'
import routes from '../constants/routes'
import Button from '../../atoms/Button'

import _ from 'lodash'

const PhysicianSearch = () => {
  const [values, setValues] = useState({})
  const [searchValues, setSearchValues] = useState({})
  const officeId = useAppSelector((state) => state.init.currentOfficeId)
  const { data, refetch } = useGetOfficePhysiciansQuery(
    generalHelper.cleanObj({ ...searchValues, officeId }),
  )
  const [updatePhysicianSettings] = useUpdatePhysicianSettingsMutation()

  let physicians =
    [
      ...(data?.items.map((i) => ({
        ...i,
        name: `${i.lastName} ${i.credential || ''}, ${i.firstName}`,
      })) || []),
    ].sort((a, b) => (a.lastName > b.lastName ? 1 : -1)) ?? []

  const columns = [
    {
      key: 'npi',
      label: 'NPI',
    },
    {
      key: 'name',
      label: generalHelper.t('Physician Name'),
    },
    {
      label: generalHelper.t('Physician Office'),
      raw: {
        type: 'template',
        template: ({ addresses }) => (
          <>
            {addresses?.[0] ? (
              <ul className="physician__office_info p-0">
                <li>
                  {addresses[0].addressStreetLine1} | {addresses[0].addressCity},{' '}
                  {addresses[0].addressState}
                </li>
                <li>
                  <div>
                    <strong>Tel: </strong>
                    {formatPhoneNumber(addresses[0].telephone)}
                  </div>
                  <div>
                    <strong>Fax: </strong>
                    {formatPhoneNumber(addresses[0].fax)}
                  </div>
                </li>
              </ul>
            ) : (
              <div className="text-gray">No Office Information</div>
            )}
          </>
        ),
      },
    },
    {
      label: 'Physician Report Preferences',
      raw: {
        type: 'template',
        template: (physician) => {
          const preference = physician.settings

          return (
            <ReportPreferenceOptions
              value={preference}
              onChange={(settings) =>
                updatePhysicianSettings({
                  officeId,
                  id: physician.id,
                  ...settings,
                })
              }
            />
          )
        },
      },
    },
  ]

  return (
    <DashboardContent
      title="Physician Management"
      subtitle="Available Physicians"
      icon="fa fa-users"
      content={
        <div className="card card-primary">
          <div className="card-body row">
            <div className="col-sm-12">
              <div className="row">
                <div className="col-sm-2">
                  <div className="form-group">
                    <label>{generalHelper.t('First Name')}</label>
                    <Input
                      value={values.firstName}
                      autoFocus={true}
                      placeholder={generalHelper.t('First Name')}
                      name="firstName"
                      handleChange={(event) => handleChange(event, values, setValues)}
                      autoComplete="one-time-code"
                    />
                  </div>
                </div>
                <div className="col-sm-2">
                  <div className="form-group">
                    <label>{generalHelper.t('Last Name')}</label>
                    <Input
                      value={values.lastName}
                      placeholder={generalHelper.t('Last Name')}
                      name="lastName"
                      handleChange={(event) => handleChange(event, values, setValues)}
                      autoComplete="one-time-code"
                    />
                  </div>
                </div>
                <div className="col-sm-2">
                  <div className="form-group">
                    <label>{generalHelper.t('NPI')}</label>
                    <Input
                      value={values.npi}
                      placeholder="0000000000"
                      name="npi"
                      handleChange={(event) => handleChange(event, values, setValues)}
                      autoComplete="one-time-code"
                    />
                  </div>
                </div>
                <div className="col-sm-2 col-md-3 pt-4">
                  <Button
                    label="Search"
                    className="btn btn-primary mr-1"
                    onClick={() => {
                      setSearchValues(values)
                      refetch()
                    }}
                  />
                  <Button
                    label="Clear"
                    className="btn btn-default"
                    onClick={() => {
                      setValues({})
                      setSearchValues({})
                    }}
                  />
                </div>
              </div>
            </div>
            <Table
              header={columns}
              rows={physicians}
              emptymessage={generalHelper.t('No Physicians Found')}
            />
          </div>
        </div>
      }
      breadcrumb={[
        { label: 'Home', to: `${routes.index}` },
        {
          label: 'Physicians',
          to: `${routes.index}${routes.physician.root}`,
        },
      ]}
    />
  )
}

export default PhysicianSearch

import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DashboardContent from '../atoms/DashboardContent'
import * as generalHelper from '../../../helpers/generalHelper'
import { useParams } from 'react-router-dom'
import { getBrands } from '../../../store/slice/brandSlice'
import { getDeviceCategories } from '../../../store/slice/deviceCategorySlice'
import { Navigate } from 'react-router-dom'
import { handleChange, handleSubmit } from '../../atoms/Upsert'
import Input from '../../atoms/Input'
import ButtonCancel from '../../atoms/ButtonCancel'
import ButtonSave from '../../atoms/ButtonSave'
import Select from '../../atoms/Select'

import {
  getDevice,
  insertDevice,
  updateDevice,
} from '../../../store/slice/deviceSlice'
import routes from '../constants/routes'

const DeviceUpsert = props => {
  const dispatch = useDispatch()
  const brands = useSelector(state => state.brand.rows)
  const deviceCategories = useSelector(state => state.deviceCategory.rows)
  const [values, setValues] = useState({})
  const [redirect, setRedirect] = useState(false)
  const { id } = useParams()
  let deviceId = id ? id : null

  useEffect(() => {
    dispatch(getBrands())
    dispatch(getDeviceCategories())
    if (deviceId) {
      dispatch(getDevice({ id: deviceId })).then(response => {
        setValues(response.payload)
      })
    }
  }, [])

  function Content(props) {
    return (
      <div className="card card-primary">
        <form
          type="post"
          onSubmit={event => {
            handleSubmit(event, {
              id: deviceId,
              values: values,
              functions: {
                insert: insertDevice,
                update: updateDevice,
              },
              callback: {
                function: setRedirect,
                params: `${routes.index}${routes.device.root}`,
              },
              dispatch: dispatch,
            })
          }}
        >
          <div className="card-body row">
            <div className="col-4">
              <Select
                label="Category"
                name="device_category_id"
                options={deviceCategories}
                value={values.device_category_id}
                handleChange={event => {
                  handleChange(event, values, setValues)
                }}
              />
            </div>
            <div className="col-4">
              <Select
                label="Brand"
                name="brand_id"
                options={brands}
                value={values.brand_id}
                handleChange={event => {
                  handleChange(event, values, setValues)
                }}
              />
            </div>
            <div className="col-4">
              <Input
                label="Name"
                name="name"
                value={values.name}
                handleChange={event => {
                  handleChange(event, values, setValues)
                }}
              />
            </div>
            <div className="col-4">
              <Input
                label="Code"
                name="code"
                value={values.code}
                handleChange={event => {
                  handleChange(event, values, setValues)
                }}
              />
            </div>
            <div className="col-4">
              <Input
                label="Serial"
                name="serial"
                value={values.serial}
                handleChange={event => {
                  handleChange(event, values, setValues)
                }}
              />
            </div>
            <div className="col-4">
              <Input
                label="Mac"
                name="mac"
                value={values.mac}
                handleChange={event => {
                  handleChange(event, values, setValues)
                }}
              />
            </div>
          </div>
          <div className="card-footer text-right">
            <ButtonCancel to="/device" /> <ButtonSave />
          </div>
        </form>
      </div>
    )
  }

  return (
    <div>
      {redirect && <Navigate to={redirect} />}
      <DashboardContent
        title={deviceId ? 'Edit Device' : 'Create Device'}
        icon="fas fa-hdd"
        content={Content(props)}
        breadcrumb={[
          { label: 'Home', to: `${routes.index}` },
          {
            label: 'Devices',
            to: `${routes.index}${routes.device.root}`,
          },
          { label: deviceId ? 'Edit' : 'New' },
        ]}
      />
    </div>
  )
}

export default DeviceUpsert

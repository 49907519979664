import * as Yup from 'yup'

const testConditionValidation = Yup.object().shape({
  select_patient: Yup.object().when('order', {
    is: order => order,
    then: Yup.object().required('Required').nullable(),
  }),
  device_category_id: Yup.string().required('Required'),
  oxygen: Yup.boolean().required('Required'),
  oxygen_lpm: Yup.string().when('oxygen', {
    is: (oxygen: boolean) => oxygen,
    then: Yup.string().required('Required'),
  }),
  pap: Yup.boolean().required('Required'),
  pap_type: Yup.string().when('pap', {
    is: (pap: boolean) => pap,
    then: Yup.string().required('Required'),
  }),
  apap_min: Yup.string().when('pap_type', {
    is: (pap_type: string) => pap_type === 'APAP',
    then: Yup.string().required('Required'),
  }),
  apap_max: Yup.string().when('pap_type', {
    is: (pap_type: string) => pap_type === 'APAP',
    then: Yup.string().required('Required'),
  }),
  bipap_i: Yup.string().when('pap_type', {
    is: (pap_type: string) => pap_type === 'BiPAP',
    then: Yup.string().required('Required'),
  }),
  bipap_e: Yup.string().when('pap_type', {
    is: (pap_type: string) => pap_type === 'BiPAP',
    then: Yup.string().required('Required'),
  }),
  cpap: Yup.string().when('pap_type', {
    is: (pap_type: string) => pap_type === 'CPAP',
    then: Yup.string().required('Required'),
  }),
})

export default testConditionValidation

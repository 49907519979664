/** @jsxImportSource @emotion/react */
import { ChangeEvent, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { Button } from 'react-bootstrap'

import DashboardContent from '../atoms/DashboardContent'
import * as generalHelper from 'helpers/generalHelper'
import DeviceSelector from 'components/dme/atoms/DeviceSelector'
import routes from '../constants/routes'
import { Device, DeviceListOptions, useDevice, useReader } from 'components/reader'
import { ResetDeviceModal } from './ResetDeviceModal'
import CalloutComponent from 'components/atoms/Callout'
import { confirm } from 'components/molecules/Confirmation'
import Select from 'components/atoms/Select'
import { Nine20mPlus } from './920mPlus'
import { Nine20m } from './920m'
import { DeviceConnectError } from '../errors/DeviceConnectError'

const InputWrapper = styled.div`
  flex: 1;
  max-width: 300px;
  input,
  select {
    height: 38px;
  }
`

const DeviceReset = () => {
  const token = localStorage.getItem('access_token')
  const [deviceType, setDeviceType] = useState<Device>()
  const [devices, setDevices] = useState<DeviceListOptions[] | null>(null)
  const [selectedDevice, setSelectedDevice] = useState<DeviceListOptions>()
  const [lastError, setLastError] = useState<{ type: string; message: string } | null>(null)
  const [resetResult, setResetResult] = useState<string | null>(null)
  const [show920M, setShow920M] = useState<string>()
  const device = useDevice({
    selectedDevice,
    deviceType: deviceType?.type,
    token,
  })
  const { error, reader } = useReader()

  const deviceOptions = devices?.map((d) => ({ value: d.optionLabel, key: d.connectionId }))

  const connect = () => {
    setLastError(null)
    reader.disconnect()
    reader.connect()

    reader.on('connect', (e: Event) => {
      console.log('Reader connected.')
      reader.getConnectionList()
    })
    reader.on('deviceList', (deviceList: DeviceListOptions[]) => {
      console.log('Device List: ', deviceList)
      setDevices(deviceList)
    })
    reader.on('socketError', (error: string) => {
      console.log('SOCKET ERROR in upload tab', error)
      setLastError({ type: 'SocketError', message: error })
    })
    reader.on('deviceResetCompleted', () => {
      console.log('Device reset completed.')
      setLastError(null)
      setResetResult(`Device reset completed.`)
    })
    reader.on('resetError', (error: string) => {
      console.log('Device reset error.')
      if (error.includes('no-data')) {
        return setResetResult(`Device reset completed. There was no data on the device.`)
      }
      setResetResult(`Device reset failed. ${error}`)
    })
    reader.on('error', (error: string) => {
      console.log('ERROR in reset', error)
      setLastError({
        type: 'DeviceReadError',
        message: `Device read error: Please make sure the device is connected and the reader app is running.`,
      })
    })
  }

  useEffect(() => {
    connect()
  }, [])

  return (
    <DashboardContent
      title={generalHelper.t('Device Reset')}
      subtitle={
        show920M
          ? '920M Instructions'
          : lastError && !devices?.length
          ? 'Error'
          : deviceType
          ? 'Select Device to Clear'
          : 'Select Device Type to Clear'
      }
      content={
        !!show920M ? (
          <CalloutComponent
            title="Reset Oximeter"
            className="info mt-4"
            content={
              show920M === '920M+' ? (
                <Nine20mPlus onClose={() => setShow920M(undefined)} />
              ) : (
                <Nine20m onClose={() => setShow920M(undefined)} />
              )
            }
          />
        ) : (
          <div className="container-fluid">
            {/* <ResetDeviceModal
            open={!!devices?.length || !!lastError}
            onClose={() => {
              setDevices(null)
              setLastError(null)
            }}
            devices={devices}
            setSelectedDevice={setSelectedDevice}
            device={device}
            lastError={lastError?.message.toString()}
          /> */}
            {lastError && !devices?.length && (
              <CalloutComponent
                title={
                  <>
                    <i className="fas fa-exclamation-triangle text-danger" /> Something went wrong
                  </>
                }
                className="info mt-4"
                content={
                  lastError.type === 'SocketError' ? (
                    <div css={{ fontSize: 16, lineHeight: '22px' }}>
                      <span className="text-bold" css={{ fontSize: 16, lineHeight: '22px' }}>
                        Device upload / reset in progress.
                      </span>
                      <br />
                      Close any other device upload / reset tabs, then refresh this page to continue
                    </div>
                  ) : (
                    <DeviceConnectError onReturn={connect} />
                  )
                }
              />
            )}
            {!lastError && !deviceType && (
              <div className="card card-primary">
                <div className="card-body row pt-4">
                  <DeviceSelector
                    onChange={(type) => {
                      if (type.name.includes('920M')) {
                        if (type.name.includes('Plus')) {
                          return setShow920M('920M+')
                        }
                        return setShow920M('920M')
                      }
                      if (!devices?.length) {
                        return confirm('', {
                          title: 'No devices detected',
                          description: `It doesn't look like you have any device(s) connected.\nPlease double check the instructions and try again.`,
                          yesAction: 'OK',
                          noAction: '',
                        })
                          .then()
                          .catch()
                      }
                      setDeviceType(type)
                      const device = devices?.find((d) => d.connectionType === 'usb-hid')
                      if (device) {
                        console.log('found HID device', device)
                        return setSelectedDevice(device)
                      }
                      console.log('Didnt find HID device, selected: ', devices[0])
                      setSelectedDevice(devices[0])
                    }}
                  />
                </div>
              </div>
            )}
            {!lastError && deviceType && !resetResult && (
              <div className="card card-primary p-3">
                <h4>Connect to device</h4>
                <br />
                <span className="text-bold" css={{ fontSize: 16, lineHeight: '22px' }}>
                  We found the following devices connected to your computer:
                </span>
                <br />
                <InputWrapper className="col">
                  <Select
                    options={deviceOptions}
                    value={selectedDevice?.connectionId}
                    name="deviceSelect"
                    handleChange={(event: ChangeEvent) => {
                      const device = devices?.find((d) => d.connectionId === event.target.value)
                      setSelectedDevice(device)
                    }}
                  />
                </InputWrapper>

                <div css={{ fontSize: 16, lineHeight: '22px' }}>
                  <span className="text-bold" css={{ fontSize: 16, lineHeight: '22px' }}>
                    Before you continue:
                  </span>
                  <br />
                  Instructions for connecting to your device
                  <ol css={{ listStyleType: 'decimal', li: { fontSize: 16, lineHeight: '22px' } }}>
                    <li>Be sure the USB cable is connected securely to your computer</li>
                    <li>Press the power button on the face of the oximeter until it powers on.</li>
                    <li>
                      IMPORTANT: Be sure the display reads "Finger Out" before resetting the
                      oximeter.
                    </li>
                  </ol>
                  {device?.status === 'resetting' ? (
                    <div className="progress" css={{ height: 35, maxWidth: 500 }}>
                      <div
                        className="progress-bar progress-bar-striped progress-bar-animated"
                        role="progressbar"
                        ariaValuenow="75"
                        ariaValuemin="0"
                        ariaValuemax="100"
                        css={{ width: `${device?.progress}%` }}>
                        resetting - {device?.progress}%
                      </div>
                    </div>
                  ) : (
                    <Button
                      onClick={() =>
                        confirm('Device Data from Device')
                          .then(() => device.reset())
                          .catch()
                      }>
                      <i className="fas fa-bolt mr-2" /> Reset Device
                    </Button>
                  )}
                </div>
              </div>
            )}
            {resetResult && (
              <CalloutComponent
                title="Reset Complete"
                className="info mt-4"
                content={
                  <div css={{ fontSize: 16, lineHeight: '22px' }}>
                    {resetResult}
                    <br />
                    <Button
                      className="mt-4"
                      onClick={() => {
                        setDeviceType(undefined)
                        setDevices(null)
                        setLastError(null)
                        setResetResult(null)
                        connect()
                      }}>
                      <i className="fas fa-undo mr-2" /> Start Over
                    </Button>
                  </div>
                }
              />
            )}
          </div>
        )
      }
      breadcrumb={[{ label: 'Devices', to: `${routes.device}` }, { label: 'Reset' }]}
    />
  )
}

export default DeviceReset

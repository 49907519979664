import { useEffect, useState } from 'react'
import { Field, FieldProps } from 'formik'
import Autocomplete from 'react-google-autocomplete'
import config from 'config/app'

const googleAPI = config?.googleApiKey

interface IProps {
  id?: string
  name: string
  onChange: (data: google.maps.places.PlaceResult) => void
  onInputChanged?: (v: string) => void
}

const GoogleAutocomplete = ({ name, onChange, onInputChanged, id }: IProps) => {
  const [load, setLoad] = useState(false)

  useEffect(() => {
    setLoad(true)
  }, [])

  return load ? (
    <Field name={name}>
      {({ field }: FieldProps) => (
        <Autocomplete
          id={id || 'google-autocomplete'}
          options={{
            types: ['geocode'],
            componentRestrictions: { country: 'us' },
          }}
          apiKey={googleAPI}
          defaultValue={field.value}
          className="form-control"
          onPlaceSelected={onChange}
          onChange={(e) => onInputChanged && onInputChanged(e.target.value)}
        />
      )}
    </Field>
  ) : (
    <></>
  )
}

export default GoogleAutocomplete

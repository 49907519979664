/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import AsyncCreatableSelect from 'components/atoms/AsyncCreatableSelect'
import * as generalHelper from 'helpers/generalHelper'
import * as api from 'helpers/api'

interface IProps {
  open: boolean
  onClose: () => void
  dmePayorName?: string
  labPayorId?: string
  onSubmit: (labPayerId: number) => void
  onNewLabPayer: () => void
}

export const LinkLabPayerModal = ({
  open,
  onClose,
  onSubmit,
  dmePayorName,
  labPayorId,
  onNewLabPayer,
}: IProps) => {
  const [labPayer, setLabPayer] = useState<{ value: number; label: string } | null>(null)

  useEffect(() => {
    if (!open) {
      setLabPayer(null)
    }
  }, [open])

  const loadPayers = async (inputValue: string) => {
    const data = await api.get(`/lab/payers?name=${inputValue}&active=true`)
    const currPayer = labPayorId ? await api.get(`/lab/payers/${labPayorId}`) : null

    const options = !currPayer ? data : [...data, { ...currPayer, payerId: currPayer.id }]

    const currLabPayer = options.find((d: any) => d.payerId === Number(labPayorId))

    if (!labPayer?.value) {
      setLabPayer({ value: currLabPayer?.payerId, label: currLabPayer?.name })
    }
    return generalHelper.getLabelValue(options, 'payerId', 'name')
  }

  return (
    <Modal centered show={open} onHide={onClose}>
      <Modal.Header>
        <Modal.Title>PAYOR LINK</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <label className="text-gray">DME Payor Name</label>
        <div css={{ fontWeight: 600, color: 'black', marginBottom: 15 }}>{dmePayorName}</div>
        <label className="text-gray">Lab Payor</label>
        <AsyncCreatableSelect
          loadOptions={loadPayers}
          handleChange={setLabPayer}
          value={labPayer}
        />
        {onNewLabPayer && (
          <div className="d-flex justify-content-end">
            <Button variant="link" onClick={onNewLabPayer} css={{ paddingRight: 0 }}>
              New Payor
            </Button>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          disabled={!labPayer?.value}
          variant="primary"
          onClick={() => {
            if (labPayer?.value) {
              onSubmit(labPayer.value)
            }
          }}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

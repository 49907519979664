import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Navigate, useParams } from 'react-router-dom'
import ContentBox from '../../atoms/ContentBox'
import DashboardContent from '../atoms/DashboardContent'
import ButtonSave from '../../atoms/ButtonSave'
import * as generalHelper from '../../../helpers/generalHelper'
import { getDeviceCategories } from '../../../store/slice/deviceCategorySlice'
import { getPatient } from '../../../store/slice/patientSlice'
import OrderTest from '../orderTest/OrderTest'
import Radio from '../../atoms/Radio'
import { handleChange, handleSubmit } from '../../atoms/Upsert'
import ButtonModal from '../../atoms/ButtonModal'
import { setModal } from '../../../store/slice/modalSlice'
import moment from 'moment'
import { getOrder, insertOrder } from '../../../store/slice/orderSlice'
import routes from '../constants/routes'
import { getUser } from '../../../store/slice/userSlice'
import { getOrderPayer } from '../../../store/slice/orderPayerSlice'
import PhysicianTab from '../orderEdit/views/Physician.tab'
import PatientUpsert from '../orderEdit/views/forms/PatientUpsert'
import InsuranceTab from '../orderEdit/views/Insurance.tab'

const resetValue = {
  _field_preset: ['status', 'verification'],
  patient_id: null,
  device_category_id: null
}

const NewOrder = (props) => {
  const dispatch = useDispatch()
  const { patient_id } = useParams()
  const [redirect, setRedirect] = React.useState(false)
  const [values, setValues] = React.useState(resetValue)
  const [orderTests, setOrderTests] = React.useState([{ oxygen: 0 }])
  const [orderInsurancePayers, setOrderInsurancePayers] = React.useState([{}])
  const [payers, setPayers] = React.useState({})
  const [physician, setPhysician] = React.useState({})
  const [checkboxesValues, setCheckboxesValues] = React.useState({
    patient: false,
    physician: false,
    insurance: false
  })
  console.log('values => ', values)
  const deviceCategories = useSelector((state) => state.deviceCategory.rows)
  const patient = useSelector((state) => state.user.row)
  const modal = useSelector((state) => state.modal.row)
  const order_payer = useSelector((state) => state.orderPayer.rows)
  const order = useSelector((state) => state.order.row)

  const editPatientInfo = 'Edit Patient Information'.toUpperCase()
  const editPhysicianInfo = 'Edit Physician Information'.toUpperCase()
  const editInsuranceInfo = 'Edit Insurance Information'.toUpperCase()

  const patientName = generalHelper.formatName(
    patient.first_name,
    patient.middle_name,
    patient.last_name,
    patient.suffix
  )

  const orderValues = {
    patient_id: patient_id,
    add_join: 'order_payer.payer',
    limit: 1,
    order_by: 'id,DESC'
  }

  React.useEffect(() => {
    if (Array.isArray(orderTests)) {
      setValues({ ...values, orderTest: orderTests })
    }
  }, [orderTests])

  React.useEffect(() => {
    dispatch(getDeviceCategories({ add_join: 'device_type' }))
    if (patient_id) {
      dispatch(getUser({ id: patient_id }))
    }
    dispatch(getOrder(orderValues))
  }, [])

  React.useEffect(() => {
    if (order && order?.order_payer?.length > 0) {
      // setPayersFromOrder(order?.order_payer)
    }
  }, [order])

  React.useEffect(() => {
    if (patient_id && modal?.content === 1) {
      dispatch(getUser({ id: patient_id }))
    }
    if (modal?.content === 2 && modal?.physician) {
      setPhysician(modal?.physician)
    }
    if (modal?.payers && modal?.content === 3) {
      setPayers(modal?.payers)
    }
  }, [modal])

  const formatPayer = (payer) => {
    const insurance = {
      payer_id: payer?.insurance_payer?.value ?? '',
      phone: payer?.insurance_phone ? payer?.insurance_phone.replace(/\D/g, '') ?? '' : '',
      member_id: payer?.member_id
    }
    return insurance
  }

  const setPayersFromOrder = (payersOrder) => {
    let newPayers
    if (payersOrder[0]) {
      newPayers = {
        payer1: {
          ...payersOrder[0],
          insurance_payer: {
            label: payersOrder[0]?.payer?.name,
            value: payersOrder[0]?.payer?.id,
            __isNew__: false
          }
        }
      }
    }
    if (payersOrder[1]) {
      newPayers = {
        ...newPayers,
        payer2: {
          ...payersOrder[1],
          insurance_payer: {
            label: payersOrder[1]?.payer?.name,
            value: payersOrder[1]?.payer?.id,
            __isNew__: false
          }
        }
      }
    }
    if (payersOrder[2]) {
      newPayers = {
        ...newPayers,
        payer3: {
          ...payersOrder[2],
          insurance_payer: {
            label: payersOrder[2]?.payer?.name,
            value: payersOrder[2]?.payer?.id,
            __isNew__: false
          }
        }
      }
    }

    setPayers(newPayers)
  }

  const getValues = () => {
    const orderPayers = []
    let newOrderValues
    if (
      orderInsurancePayers &&
      orderInsurancePayers?.payer1 &&
      orderInsurancePayers?.payer1?.insurance_payer
    ) {
      orderPayers.push(formatPayer(orderInsurancePayers?.payer1))
    }
    if (
      orderInsurancePayers &&
      orderInsurancePayers?.payer2 &&
      orderInsurancePayers?.payer2?.insurance_payer
    ) {
      orderPayers.push(formatPayer(orderInsurancePayers?.payer2))
    }
    if (
      orderInsurancePayers &&
      orderInsurancePayers?.payer3 &&
      orderInsurancePayers?.payer3?.insurance_payer
    ) {
      orderPayers.push(formatPayer(orderInsurancePayers?.payer3))
    }
    newOrderValues = {
      patient_id: patient_id,
      device_category_id: values?.device_category_id,
      order_test: values?.orderTest,
      _field_preset: ['status', 'verification']
    }

    if (orderPayers.length > 0) {
      newOrderValues.order_payer = orderPayers
    }
    if (physician?.id && physician?.physician_location_id) {
      newOrderValues.physician_id = physician?.id
      newOrderValues.physician_location_id = physician?.physician_location_id
    } else if (physician?.npi) {
      newOrderValues.physician = physician
    }

    return newOrderValues
  }

  const sendNewOrder = (params, response) => {
    setRedirect(`${params._routes.index}${params._routes.order.edit}${response.payload.id}/RxForm`)
  }

  const handleOrderSettings = () => {
    return (
      <div className="container">
        <div className="row">
          <div className="col-sm-12 mb-1">
            <strong className="pl-0">{generalHelper.t('Test Type')}</strong>
          </div>
        </div>

        <div className="d-flex flex-column pl-2">
          {deviceCategories &&
            Array.isArray(deviceCategories) &&
            deviceCategories.map((row, index) => {
              return (
                <div key={index} className={`col-sm-12 ${index === 2 ? 'mb-1' : 'mb-3'}`}>
                  <Radio
                    className="form-check-input"
                    name="device_category_id"
                    value={row?.id}
                    onChange={(event) => {
                      handleChange(event, values, setValues)
                    }}
                  />
                  <label htmlFor={`radio-${row?.id}`} className="form-check-label">
                    {generalHelper.formatTestTypes(row?.name)}
                  </label>
                </div>
              )
            })}
        </div>
        <div className="row">
          <div className="col-sm-12 mt-2">
            <div>
              <strong>{generalHelper.t('Nights')}</strong>
            </div>
            <div className="row mt-2">
              <OrderTest values={orderTests} setValues={setOrderTests} />
            </div>
          </div>
        </div>

        <div className="col-sm-12 mb-3 text-right">
          <ButtonSave
            type="button"
            label="Add Order"
            data-dismiss="modal"
            disabled={
              !values?.device_category_id ||
              !checkboxesValues?.patient ||
              !checkboxesValues?.physician ||
              !checkboxesValues?.insurance
            }
            onClick={(event) => {
              handleSubmit(event, {
                values: getValues(),
                functions: {
                  insert: insertOrder
                },
                callback: {
                  function: sendNewOrder,
                  params: {
                    _routes: routes,
                    _order: order
                  }
                },
                dispatch: dispatch
              })
            }}
          />
        </div>
      </div>
    )
  }

  const handlePhysicianValidationCheck = (physician) => {
    return !(physician && (physician?.id || physician?.physician_location_id || physician?.npi))
  }

  const handleInsuranceValidationCheck = (insurance) => {
    return !(insurance && insurance?.payer1 && insurance?.payer1?.insurance_payer)
  }

  // NOTE! In customContent === 3 case it is wrongly checking physician validation just for rendering purposes. Needs to be fixed.
  const checkboxEnable = (customContent) => {
    return customContent === 1
      ? handlePatientValidationCheck(patient)
      : customContent === 2
      ? handlePhysicianValidationCheck(physician)
      : customContent === 3
      ? handleInsuranceValidationCheck(orderInsurancePayers)
      : ''
  }

  const handlePatientValidationCheck = (patient) => {
    return !(
      patient &&
      !!patient.first_name &&
      !!patient.last_name &&
      !!patient.dob &&
      !!patient.address
    )
  }

  const handleActionButton = (customContent, title) => {
    return [
      <>
        {!checkboxEnable(customContent) ? (
          <div className="col pl-4 pr-2 py-2 border text-center box_BiPAP">
            <input
              type="checkbox"
              value={
                false || customContent === 1
                  ? checkboxesValues?.patient
                  : customContent === 2
                  ? checkboxesValues?.physician
                  : checkboxesValues?.insurance
              }
              className="form-check-input"
              name={
                customContent === 1 ? 'patient' : customContent === 2 ? 'physician' : 'insurance'
              }
              checked={
                customContent === 1
                  ? checkboxesValues?.patient
                  : customContent === 2
                  ? checkboxesValues?.physician
                  : checkboxesValues?.insurance
              }
              onChange={(event) => {
                handleChange(event, checkboxesValues, setCheckboxesValues)
              }}
              id={`${customContent}-checkbox`}
            />
            <label className="form-check-label" htmlFor={`${customContent}-checkbox`}>
              <b>{generalHelper.t('I have reviewed, this is correct')}</b>
            </label>
          </div>
        ) : (
          ''
        )}
      </>,
      <>
        {customContent === 1 && (
          <ButtonSave className="ml-3 btn btn-primary" label="Save Changes" />
        )}
      </>
    ]
  }

  const newOrderContent = () => {
    return (
      <section className="row">
        <div className="col-sm-8">
          <ContentBox
            content={
              <PatientUpsert
                patient={patient}
                isNewOrder={true}
                actionButtons={handleActionButton(1, editPhysicianInfo)}
              />
            }
          />
          <ContentBox
            content={
              <PhysicianTab
                newOrderPhysician={physician}
                setNewOrderPhysician={setPhysician}
                actionButtons={handleActionButton(2, editPhysicianInfo)}
              />
            }
          />
          <ContentBox
            content={
              <InsuranceTab
                patient={patient}
                newOrderValues={orderInsurancePayers}
                setNewOrdersValues={setOrderInsurancePayers}
                actionButtons={handleActionButton(3, editInsuranceInfo)}
              />
            }
          />
        </div>
        <div className="col-sm-4">
          <ContentBox title={generalHelper.t('Order settings')} content={handleOrderSettings()} />
        </div>
      </section>
    )
  }

  return (
    <div>
      {redirect && <Navigate to={redirect} />}
      <DashboardContent
        title="NEW ORDER"
        subtitle="Adding New Order For Existing Patient"
        content={newOrderContent()}
      />
    </div>
  )
}

export default NewOrder

import { useEffect, useState, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { startCase } from 'lodash'

import * as generalHelper from 'helpers/generalHelper'
import { KeyVal } from 'store/types'

const ROWS_PER_PAGE = 100

interface QueryString {
  testType?: string
  bucket?: string
  sort?: string
  field?: string
  order?: string
  page?: string
  size?: string
  actionItems?: string
}

interface SortOptions {
  id?: string
  desc?: boolean
  field?: string
  order?: string
}

export interface OrderTableHelpers {
  testType: string
  actionItems: string
  currentPage: number
  setCurrentPage: (v: number) => void
  pageSize: number
  setPageSize: (v: number) => void
  setSortOptions: (v: SortOptions) => void
  bucket?: string
  bucketName: string
  sortField?: string
  sortOrder?: string
  setUrlSearch: (params: KeyVal) => void
  setTestType: (type: 'HST' | 'POX_COX') => void
  showTestTypeFilter: boolean
}

export const useOrdersTable = (search: KeyVal, clearSearch: () => void): OrderTableHelpers => {
  const queryString: QueryString = {}
  const [urlSearch, setUrlSearch] = useSearchParams()
  urlSearch.forEach((v: string, key: string) => (queryString[key] = v))
  const { testType, bucket, sort, field, order, page, size, actionItems } = queryString
  const [currentPage, setCurrentPage] = useState(page || 0)
  const [pageSize, setPageSize] = useState(size || ROWS_PER_PAGE)
  const [sortOptions, setSortOptions] = useState<SortOptions>(
    sort ? { field: sort.split('_')[0], order: sort.split('_')[1] } : {},
  )
  const effectCount = useRef(0)
  const bucketName = startCase(bucket?.replace(/_/g, ' ')?.toLowerCase())

  const showTestTypeFilter =
    (['DEMO_VER', 'INS_VER', 'NEED_ATTENTION', 'AWAITING_REVIEW'].includes(bucket!) &&
      actionItems === '1') ||
    ['AUTHORIZATION', 'BILLING_REVIEW'].includes(bucket!)

  useEffect(() => {
    if (effectCount.current > 0) {
      setCurrentPage(0)
      clearSearch()
    }
    effectCount.current++
  }, [bucketName, testType])

  useEffect(() => {
    urlSearch.set('page', currentPage.toString())
    setUrlSearch(urlSearch)
  }, [currentPage, urlSearch, setUrlSearch])

  useEffect(() => {
    urlSearch.set('size', pageSize.toString())
    setUrlSearch(urlSearch)
  }, [pageSize, urlSearch, setUrlSearch])

  useEffect(() => {
    if (sortOptions?.id) {
      const newOptions = {
        field: sortOptions.id,
        order: sortOptions?.desc ? 'desc' : 'asc',
      }
      setUrlSearch({ ...queryString, ...newOptions })
    } else {
      if (queryString.field || queryString.order) {
        delete queryString.field
        delete queryString.order
        setUrlSearch({ ...queryString })
      }
    }
  }, [sortOptions])

  useEffect(() => {
    const searchValues = generalHelper.cleanObj(search)
    setUrlSearch({
      ...generalHelper.cleanObj({ testType, bucket, sort, field, order, actionItems }),
      ...searchValues,
    })
  }, [search])

  useEffect(() => {
    const testTypeFilter = localStorage.getItem('testTypeFilter')
    if (!showTestTypeFilter || !testTypeFilter || testTypeFilter === testType) {
      return
    }

    console.log('queryString', queryString)
    setUrlSearch({ ...queryString, testType: testTypeFilter })
  })

  const setTestType = (type: 'HST' | 'POX_COX') => {
    setUrlSearch({ ...queryString, testType: type })
    localStorage.setItem('testTypeFilter', type)
  }

  return {
    testType,
    actionItems,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    setSortOptions,
    bucket,
    bucketName,
    sortField: field ? field : bucket === 'RECENT' ? 'recency' : 'id',
    sortOrder: order ? order : bucket === 'RECENT' ? 'desc' : 'asc',
    setTestType,
    showTestTypeFilter,
  }
}

/** @jsxImportSource @emotion/react */
import { useState } from 'react'
import { VisibilityOff, Visibility } from '@mui/icons-material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faKey } from '@fortawesome/free-solid-svg-icons'

import useKeyLock from 'hooks/useKeyLock'

export const PasswordField = ({
  setPassword,
  label = 'Password',
  placeholder = 'Enter password',
}: {
  setPassword: (password: string) => void
  label?: string
  placeholder?: string
}) => {
  const [showPassword, setShowPassword] = useState(false)
  const capsLock = useKeyLock('CapsLock')

  return (
    <div className="form-group">
      <label>
        {' '}
        <FontAwesomeIcon icon={faKey} /> {label}
      </label>
      <div
        css={{
          position: 'relative',
          '& .password-toggle-icon': {
            position: 'absolute',
            top: '7px',
            right: '15px',
            cursor: 'pointer',
          },
        }}>
        <input
          type={showPassword ? 'text' : 'password'}
          className="form-control"
          placeholder={placeholder}
          autoComplete="current-password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <span
          className="password-toggle-icon"
          onClick={() => {
            setShowPassword(!showPassword)
          }}>
          {' '}
          {!showPassword ? <Visibility /> : <VisibilityOff />}
        </span>
      </div>
      {capsLock && <small className="text-danger">Caps Lock is on</small>}
    </div>
  )
}

import styled from '@emotion/styled'

export const TableWrapper = styled.div`
  overflow-y: auto;
  max-height: calc(100vh - 123px);
  padding: 0 10px 10px 10px;

  Table thead {
    background: white;
    position: sticky;
    top: 0px;
    margin: 0 0 0 0;
  }
`

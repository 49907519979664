import { startCase } from 'lodash'

export enum DeviceStates {
  INTERNAL_USE = 'INTERNAL_USE',
  STOCK = 'STOCK',
  RENEWED_STOCK = 'RENEWED_STOCK',
  CONSIGNED = 'CONSIGNED',
  SOLD = 'SOLD',
  RETURN_IN_TRANSIT = 'RETURN_IN_TRANSIT',
  RETURN_RECEIVED = 'RETURN_RECEIVED',
  IN_REPAIR = 'IN_REPAIR',
  DEFECTIVE = 'DEFECTIVE',
  DAMAGED = 'DAMAGED',
  LOST = 'LOST',
  BILLED = 'BILLED',
}

export const statusOptions = Object.keys(DeviceStates).map((s) => ({
  value: s,
  key: startCase(s.replace(/_/g, ' ').toLowerCase()),
}))

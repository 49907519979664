/** @jsxImportSource @emotion/react */
import { useEffect, useState, useMemo } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import Select from 'react-select'
import _ from 'lodash'

import Input from 'components/atoms/Input'
import { handleChange } from 'components/atoms/Upsert'
import DashboardContent from '../atoms/DashboardContent'
import { useLazyGetLabDmeUsersQuery } from 'store/services/users'
import {
  useGetCompaniesQuery,
  useGetCompanyQuery,
  useGetLabOfficesQuery,
} from 'store/services/office'
import routes from '../constants/routes'
import Button from 'components/atoms/Button'
import DataTable from 'components/atoms/DataTable'
import { TableWrapper } from 'components/atoms/TableWrapper'
import { skipToken } from '@reduxjs/toolkit/query/react'

const StyledTableWrapper = styled(TableWrapper)`
  th {
    padding: 0 0.2rem 0.4rem 0.1rem;
  }
  td {
    vertical-align: middle;
    padding: 0.2rem;
    div {
      span {
        font-size: 0.9rem;
      }
    }
  }
`

const CellWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 40px;
  justify-content: center;
  > div {
    white-space: nowrap;
    display: flex;
  }
`

interface SearchForm {
  search: string
  companyId: string
  regionUuid: string
  officeId: string
}

const initialSearchVals = {
  search: '',
  companyId: '',
  regionUuid: '',
  officeId: '',
}

const PAGE_SIZE = 15

const DmeUserDirectory = () => {
  const navigate = useNavigate()
  const [values, setValues] = useState<SearchForm>(initialSearchVals)
  const [companySearch, setCompanySearch] = useState('')
  const [currentPage, setCurrentPage] = useState(0)
  const [sortOptions, setSortOptions] = useState<{ id: string; desc: boolean }>()
  const { data: companies } = useGetCompaniesQuery({
    name: companySearch || undefined,
  })
  const { data: company } = useGetCompanyQuery(
    values.companyId ? { id: values.companyId } : skipToken,
  )
  const { data: offices } = useGetLabOfficesQuery(
    values.companyId ? { companyId: values.companyId } : skipToken,
  )
  const [getDmeUsers, { data: users, isLoading }] = useLazyGetLabDmeUsersQuery()

  const companyOptions = companies?.map((company) => ({
    label: company.companyName,
    value: company.id,
  }))
  const regions =
    useMemo(
      () =>
        company?.regions?.map((region) => ({
          label: region.regionName,
          value: region.uuid,
        })),
      [company],
    ) || []

  const officesOptions =
    useMemo(
      () =>
        offices
          ?.filter((o) => !values.regionId || o.companyRegionUuid === values.regionId)
          .map((office) => ({
            label: `${office.officeName} (${office.addressCity}, ${office.addressState})`,
            value: office.id,
          })),
      [offices, values],
    ) || []

  useEffect(() => {
    setTimeout(onSearch, 100)
  }, [currentPage])

  useEffect(() => {
    setCurrentPage(0)
  }, [values])

  useEffect(() => {
    onSearch()
  }, [sortOptions, values.search]);

  const onSearch = () => {
    getDmeUsers({
      search: values.search || undefined,
      companyId: values.companyId || undefined,
      regionUuid: values.regionUuid || undefined,
      officeId: values.officeId || undefined,
      size: PAGE_SIZE,
      page: currentPage,
      sortField: sortOptions?.id,
      sortOrder: sortOptions?.desc ? 'desc' : 'asc',
    })
  }

  const headers = [
    {
      Header: 'User ID',
      accessor: 'userId',
      Cell: ({ row }) => row.original.id,
    },
    {
      Header: 'User Name',
      accessor: 'lastName',
      columnSize: 3,
      Cell: ({ row }) => (
        <>
          <Link to={`${routes.index}${routes.dmeUsers.root}${routes.upsert}${row.original.id}`}>
            {`${_.capitalize(row.original.lastName)}${
              row.original.suffix ? ' ' + row.original.suffix : ''
            }, ${_.capitalize(row.original.firstName)} ${
              row.original.middleName ? _.capitalize(row.original.middleName) : ''
            }`}
          </Link>
        </>
      ),
    },
    {
      Header: 'Email',
      accessor: 'email',
      columnSize: 3,
      Cell: ({ row }) => (
        <CellWrapper>
          <div>{row.original.email}</div>
        </CellWrapper>
      ),
    },
    {
      Header: 'Company',
      accessor: 'companyName',
      columnSize: 3,
      Cell: ({ row }) => (
        <CellWrapper>
          <div>{row.original.companyName}</div>
        </CellWrapper>
      ),
    },
    {
      Header: 'Office',
      accessor: 'officeName',
      columnSize: 3,
      Cell: ({ row }) => (
        <CellWrapper>
          <div>{row.original.officeName}</div>
          {row.original.officesCount > 1 && (
            <div className="text-gray">
              +{row.original.officesCount - 1} office{row.original.officesCount > 2 ? 's' : ''}
            </div>
          )}
        </CellWrapper>
      ),
    },
    {
      Header: 'Role',
      accessor: 'role',
      columnSize: 3,
      Cell: ({ row }) => (
        <CellWrapper>
          <div>{row.original.roles?.[0]?.replace('_', ' ')}</div>
        </CellWrapper>
      ),
    },
    {
      Header: 'Active',
      accessor: 'status',
      columnSize: 3,
      Cell: ({ row }) => (
        <CellWrapper>
          <div css={{ margin: 'auto' }}>
            {row.original.active ? (
              <i className="fa fa-circle text-success" />
            ) : (
              <i className="fa fa-circle text-danger" />
            )}
          </div>
        </CellWrapper>
      ),
    },
    {
      Header: 'SSO',
      accessor: 'isSsoEnabled',
      columnSize: 3,
      Cell: ({ row }) => (
        <CellWrapper>
          <div css={{ margin: 'auto' }}>
            {row.original.isSsoEnabled ? <i className="fas fa-check-circle" /> : null}
          </div>
        </CellWrapper>
      ),
    },
    {
      Header: 'Admin',
      accessor: 'isAdmin',
      columnSize: 3,
      Cell: ({ row }) => (
        <CellWrapper>
          <div css={{ margin: 'auto' }}>
            {row.original.adminOf ? <i className="fas fa-check-circle" /> : null}
          </div>
        </CellWrapper>
      ),
    },
  ]

  return (
    <DashboardContent
      title="Users"
      subtitle={
        <>
          DME User Management
          <Button
            label="Add User"
            className="btn btn-primary ml-3 position-absolute"
            css={{ top: '15px', right: '15px' }}
            onClick={() => navigate(`${routes.index}${routes.dmeUsers.root}${routes.upsert}`)}
          />
        </>
      }
      content={
        <div className="card card-primary">
          <div className="card-body">
            <div className="row px-4 mb-3">
              <div
                className="pl-2"
                css={{
                  margin: '0 !important',
                  '& input': { minWidth: '300px', margin: '0 !important' },
                }}>
                <label>Search Users</label>
                <Input
                  value={values.search}
                  label=""
                  placeholder="Search Users"
                  name="search"
                  autoFocus={true}
                  handleChange={(event) => {
                    handleChange(event, values, setValues)
                  }}
                  onKeyPress={(event) => event.key === 'Enter' && onSearch()}
                />
              </div>
            </div>
            <div className="row px-4 mb-4">
              <div className="col-3">
                <label>Company</label>
                <Select
                  isClearable
                  name="companyId"
                  value={companyOptions?.find((c) => c?.value == values.companyId) ?? null}
                  options={companyOptions}
                  onInputChange={(v) => v && setCompanySearch(v)}
                  onChange={(event) => {
                    console.log(event)
                    setValues((p) => ({ ...p, companyId: event?.value }))
                  }}
                />
              </div>
              <div className="col-3">
                <label>Region</label>
                <Select
                  isClearable
                  name="regionUuid"
                  value={regions.find((c) => c?.value == values.regionUuid) ?? null}
                  options={regions}
                  onChange={(event) => {
                    setValues((p) => ({ ...p, regionUuid: event?.value }))
                  }}
                />
              </div>
              <div className="col-3">
                <label>Office</label>
                <Select
                  isClearable
                  name="officeId"
                  value={officesOptions.find((c) => c?.value == values.officeId) ?? null}
                  options={officesOptions}
                  onChange={(event) => {
                    setValues((p) => ({ ...p, officeId: event?.value }))
                  }}
                />
              </div>
              <div className="col-3 d-flex align-items-end">
                <Button
                  label="Clear"
                  className="btn btn-default mx-2"
                  onClick={() => {
                    setValues(initialSearchVals)
                    setCurrentPage(0)
                  }}
                />
                <Button
                  label="Search"
                  className="btn btn-primary mr-3"
                  onClick={() => {
                    setCurrentPage(0)
                    onSearch()
                  }}
                />
              </div>
            </div>
            <div className="container-fluid">
              <StyledTableWrapper
                className="card-body row"
                style={{
                  overflowY: !users?.items?.length || users?.items?.length < 10 ? 'unset' : 'auto',
                }}>
                <DataTable
                  data={users}
                  status={isLoading}
                  tableColumns={headers}
                  pageSize={PAGE_SIZE}
                  setCurrentPage={setCurrentPage}
                  useCustomSortHandler={true}
                  setSortOptions={setSortOptions}
                  entityName="DME users"
                  onRowClick={(row) =>
                    navigate(
                      `${routes.index}${routes.dmeUsers.root}${routes.upsert}${row.original.id}`,
                    )
                  }
                />
              </StyledTableWrapper>
            </div>
          </div>
        </div>
      }
      breadcrumb={[
        {
          label: 'User Directory',
          to: `${routes.index}${routes.dmeUsers.root}`,
        },
      ]}
    />
  )
}

export default DmeUserDirectory

import { useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'
import * as generalHelper from '../../helpers/generalHelper'
import { VoidExpression } from 'typescript'

export interface Item {
  label: string
  to: string
  type?: string
  linkClass?: string
  open?: boolean
  roleType?: string
  rightArrow?: boolean
  menu?: string
  icon?: string
  showIcon?: boolean
  badgeClass?: string
  className?: string
  labelClass?: string
  extraClass?: string
  extraStyle?: any
  bucket?: string
  badgeParams?: BadgeData
  sub?: Item[]
}

interface IProps {
  data: Item[]
  badgeData: any[]
  devices?: any
  setMenuData: (d: Item[]) => VoidExpression
}

const SidebarSection = ({ badgeData, data, setMenuData, devices }: IProps) => {
  const { search } = useLocation()
  const queryString = useMemo(() => new URLSearchParams(search), [search])
  const { menu, bucket } = Object.fromEntries(queryString)
  const badgeDataLookup = useMemo(
    () =>
      badgeData?.reduce((agg, curr) => {
        const { bucket, testType, count } = curr
        if (!agg[testType]) {
          agg[testType] = { [bucket]: count }
        } else {
          agg[testType][bucket] = count
        }

        return agg
      }, {}),
    [badgeData],
  )

  // console.log(badgeData, badgeDataLookup)

  const handleShowHide = (row: Item) => {
    if (row.alwaysOpen) return null
    setMenuData((menu: Item[]) =>
      menu.map((i: Item) =>
        i.alwaysOpen
          ? { ...i, open: true }
          : i.label !== row.label
            ? { ...i, open: false }
            : { ...i, open: !row.open },
      ),
    )
  }

  const getMenuItem = (row: Item) => {
    if (menu) {
      const parts = menu.split('--')
      if (Object.hasOwn(row, 'sub')) {
        // this is parent menu, let's see if we need need to open it automatically
        if (Array.isArray(parts) && parts.find((element) => element === row.menu)) {
          row.linkClass = row.linkClass?.includes('active')
            ? row.linkClass
            : row.linkClass + ' active'
          // row.open = true
        }
      } else {
        if (row.menu === menu) {
          row.linkClass = row.linkClass?.includes('active bg')
            ? row.linkClass
            : row.linkClass + ' active bg'
        } else {
          // delete row.linkClass
          row.linkClass = row.linkClass?.replace(/active bg/g, '')
        }
      }
    }

    if (row && row.type === 'hr') {
      return <hr className={`${row.className}`} />
    } else if (row && row.type === 'button') {
      return getButton(row)
    } else {
      return getLink(row)
    }
  }

  const getButton = (row: Item) => {
    return (
      <Link
        className={`btn btn-success mb-3 btn-md text-white ${row.extraClass}`}
        style={{ width: '100%', borderRadius: 0, ...(row.extraStyle ? row.extraStyle : {}) }}
        type="button"
        to={row.to ? row.to + (row.menu ? `&menu=${row.menu}` : '') : ''}
        onClick={() => {
          handleShowHide(row)
        }}>
        {row.icon && <i className={`mr-2 ${row.icon}`}></i>}
        {generalHelper.t(row.label)}
      </Link>
    )
  }

  const getLink = (row: Item) => {
    let badge = null
    if (row.badgeParams) {
      if (Array.isArray(row.badgeParams)) {
        badge = row.badgeParams.reduce((agg, curr) => {
          const { bucket, testType } = curr
          if (badgeDataLookup?.[testType]?.[bucket]) {
            agg += badgeDataLookup?.[testType]?.[bucket]
          }
          return agg
        }, 0)
      } else {
        badge =
          row.badgeParams === 'activeDevices'
            ? devices?.totalItems
            : badgeDataLookup?.[row.badgeParams.testType]?.[row.badgeParams.bucket] || 0
      }
    }

    const badgeClass = row.badgeClass && !!badge ? row.badgeClass : 'badge-secondary'
    const link = row.to ? row.to + (row.menu ? `&menu=${row.menu}` : '') : ''

    const active =
      (!!row.bucket && row.bucket === bucket) || window.location.pathname.includes(row.to)

    const Component = link?.includes('http') ? 'a' : Link

    return (
      <Component
        {...(Component === 'a' && { href: link, target: '_blank' })}
        to={link}
        onClick={(e) => {
          if (!link) e.preventDefault()
          !!row.sub && handleShowHide(row)
        }}
        className={`nav-link ${row.linkClass ?? ''} ${active ? 'active bg' : ''}`}>
        <i className={`mr-2 ${row.sub ? '' : 'ml-0'}`}></i>
        {row.icon && row.showIcon && <i className={`mr-2 ${row.icon}`}></i>}
        <p className={row.labelClass}>
          {generalHelper.t(row.label)}
          <i
            className={`right ${
              row.sub
                ? row.open
                  ? 'fas fa-angle-down'
                  : 'fas fa-angle-right'
                : row.rightArrow
                  ? 'fas fa-angle-right'
                  : ''
            }`}></i>
        </p>
        {(badge || badge === 0) && (
          <span
            className={`badge ${badgeClass || ''} right`}
            style={{ top: '0.4rem', right: '0.8rem' }}>
            {badge}
          </span>
        )}
      </Component>
    )
  }

  return (
    <div>
      {Array.isArray(data) &&
        data.map((row, index) => {
          return (
            <li key={index} className="nav-item">
              {getMenuItem(row)}
              {row.sub &&
                Array.isArray(row.sub) &&
                row.sub.map((row_sub, index_sub) => (
                  <ul
                    key={index_sub}
                    className="nav nav-treeview"
                    style={{
                      display: 'block',
                      height: row.open ? 30 : 0,
                      opacity: row.open ? 1 : 0,
                      transition: 'height 300ms ease-out, opacity 300ms ease',
                    }}>
                    <li className="nav-item menu-open">
                      {getMenuItem(row_sub)}

                      <ul
                        className="nav nav-treeview"
                        style={{
                          display: 'block',
                        }}>
                        {Array.isArray(row_sub.sub) &&
                          row_sub.sub.map((row_sub2, index_sub2) => (
                            <li key={index_sub2} className="nav-item">
                              {getMenuItem(row_sub2)}
                            </li>
                          ))}
                      </ul>
                    </li>
                  </ul>
                ))}
            </li>
          )
        })}
    </div>
  )
}

export default SidebarSection

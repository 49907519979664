interface IProps {
  uppercase?: boolean
  label: string
  icon?: string
  className?: string
}

const TitleLead = ({ uppercase, icon, label, ...props }: IProps) => {
  return (
    <div {...props}>
      {icon && <i className={icon} />}{' '}
      <span
        style={{
          fontSize: '14px',
          textTransform: uppercase ? 'uppercase' : 'inherit',
        }}>
        {label}
      </span>
    </div>
  )
}

TitleLead.defaultTypes = {
  style: {},
  label: '',
  icon: null,
  uppercase: true,
}

export default TitleLead

import DashboardContent from '../atoms/DashboardContent'
import Stats from '../stats/Stats'

const TSIndex = () => {
  return (
    <DashboardContent
      title="Dashboard"
      content={
        <div>
          <Stats />
        </div>
      }
      breadcrumb={[{ label: 'Home' }]}
    />
  )
}

export default TSIndex

import { forwardRef } from 'react'
import Button from './Button'

interface IProps {
  data_toggle?: string
  data_target?: string
  data_dismiss?: string
  label: string
  type?: 'button' | 'reset' | 'submit' | undefined
  className?: string
  href?: string
  onClick?: () => void
}

const ButtonModal = forwardRef((props: IProps, ref) => {
  return (
    <Button
      {...props}
      ref={ref}
      data-toggle={props.data_toggle}
      data-target={props.data_target}
      data-dismiss={props.data_dismiss}
    />
  )
})

export default ButtonModal

import { useState } from 'react'
import { useAppSelector } from 'hooks/redux'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

import {
  OrderSettingsProvider,
  useOrderSettings,
} from 'components/dme/order/order-settings/useOrderSettings'
import DashboardContent from '../atoms/DashboardContent'
import routes from '../constants/routes'
import TitleLead from 'components/atoms/TitleLead'
import Button from 'components/atoms/Button'
import Toast from 'components/atoms/Toast'
import OrderSettings from 'components/dme/order/order-settings/OrderSettings'
import { newPatientInHistorySelector } from 'store/slice/patientSlice'
import { useCreateLabOrderMutation } from 'store/services/labOrder'
import * as generalHelper from 'helpers/generalHelper'

const NewPatientOrder = () => {
  const navigate = useNavigate()
  const [submitting, setSubmitting] = useState(false)
  const { state: orderSettings } = useOrderSettings()
  const { officeId, ...newPatient } = useAppSelector(newPatientInHistorySelector) || {}
  const [createOrder, { isLoading: isUpdating }] = useCreateLabOrderMutation()

  const patientOverEighteen = moment().diff(moment(newPatient?.dob, 'MM/DD/YYYY'), 'years') >= 18
  const isReadyToSubmit =
    orderSettings.isValid && newPatient && (patientOverEighteen || orderSettings.testType !== 'HST')

  const PatientInformation = () => (
    <div className="row border bg-white m-2 mb-3 pb-3">
      <div className="col-sm-12 d-flex flex-column px-3">
        <TitleLead className="ml-2 my-3" label={generalHelper.t('PATIENT INFORMATION')} />
        <hr className="w-100 mt-0" />
      </div>

      <div className="col-sm-12 ml-2">
        <div className="row">
          <div className="col-6 d-flex flex-column ml-1">
            <span className="font-weight-bold textGray">Patient Name</span>
            <span className="my-2">
              {newPatient?.firstName} {newPatient?.lastName}
              {newPatient?.suffix && `, ${newPatient?.suffix}`}
            </span>
          </div>
          <div className="col d-flex flex-column">
            <span className="font-weight-bold textGray">Date Of Birth</span>
            <span className="my-2">{newPatient?.dob}</span>
          </div>

          <div className="col d-flex flex-column">
            <span className="font-weight-bold textGray">Gender</span>
            <span className="my-2">{newPatient?.gender}</span>
          </div>
        </div>
      </div>
    </div>
  )

  const onSubmit = async () => {
    try {
      if (newPatient && !submitting) {
        setSubmitting(true)
        const data = {
          patient: {
            ...newPatient,
            dob: moment(newPatient.dob, 'MM/DD/YYYY').format('YYYY-MM-DD'),
          },
          tests: Object.values(orderSettings.tests || []).map((conditions, idx) => ({
            ...conditions,
            night: idx + 1,
          })),
          testType: orderSettings.testType,
          officeId,
        }
        const { data: orderCreated } = await createOrder(data)
        console.log('Order Created', orderCreated)
        Toast({ type: 'success', label: 'New order created!' })

        navigate(`${routes.index}${routes.order.edit}${orderCreated.id}?bucket=OPEN`)
      }
    } catch (error: any) {
      console.log(`Error submitting new order and patient info: ${error.message}`)
      Toast({
        type: 'error',
        label: `Error creating new Patient: ${error.message}`,
      })
    }
    setSubmitting(false)
  }

  return (
    <DashboardContent
      title="New Order"
      subtitle="New Patient"
      content={
        <>
          <PatientInformation />
          <OrderSettings officeId={officeId} />
          <div className="d-flex flex-column align-items-end">
            <Button
              className="btn btn-danger float-right mr-2 text-nowrap"
              onClick={onSubmit}
              disabled={!isReadyToSubmit || submitting}
              label={generalHelper.t('Create Order')}
            />
            {!patientOverEighteen && orderSettings.testType === 'HST' && (
              <div className="row mt-2">
                <div className="col-sm-12 d-flex justify-content-end">
                  <span className="font-weight-bold text-danger">
                    Cannot order HST if patient is under 18
                  </span>
                </div>
              </div>
            )}
          </div>
        </>
      }
      breadcrumb={[
        {
          label: 'New Order',
          to: `${routes.index}${routes.order.newOrder}`,
        },
        {
          label: 'New Patient',
          to: `${routes.index}${routes.order.newOrder}/${routes.patient.new}`,
        },
      ]}
    />
  )
}

const NewPatientOrderWrapper = () => {
  return (
    <OrderSettingsProvider>
      <NewPatientOrder />
    </OrderSettingsProvider>
  )
}

export default NewPatientOrderWrapper

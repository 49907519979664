import { CSSProperties } from 'react'

type Option = {
  key: string
  name: string
  disabled?: boolean
}

interface IProps {
  options: Option[]
  value: string
  onChange: (key: string) => void
}

const styles: Record<string, CSSProperties> = {
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
  },
}

const ToggleBtnGroup = ({ options = [], value, onChange }: IProps) => {
  return (
    <div className="btn-group" style={styles.buttonGroup}>
      {options.map(({ key, name, disabled }) => (
        <button
          key={key}
          disabled={disabled}
          type="button"
          onClick={() => onChange(key)}
          className={`btn ${value === key ? 'btn-primary' : 'btn-light'}`}>
          {name}
        </button>
      ))}
    </div>
  )
}

export default ToggleBtnGroup

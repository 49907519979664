const Loading = ({ className }: { className?: string }) => (
  <div></div>
  // <div className="loading">
  //     <div
  //         className={`loading_spinner spinner-border ${
  //             className ? className : ""
  //         }`}
  //         style={{ color: "#4a89b3" }}
  //     />
  // </div>
)

export const LoadingSpinner = () => {
  return (
    <div className="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}

export default Loading

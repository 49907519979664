import dmeRoutes from 'components/dme/constants/routes'
import labRoutes from 'components/lab/constants/routes'
import { TestCode } from 'store/types'

export const orderStatusDefinitions = (
  context: 'LAB' | 'DME',
  orderData: any,
  testType?: TestCode,
  physician?: boolean,
) => {
  const routes = {
    LAB: labRoutes,
    DME: dmeRoutes,
  }[context]

  return [
    // Basic Order information
    [
      {
        name: 'PATIENT',
        icon: 'fa-user-circle',
        to:
          !physician &&
          `${routes.index}${routes.order.edit}${orderData.id}/${routes.order.patient ?? ''}`,
      },
      {
        name: 'PHYSICIAN',
        icon: 'fa-stethoscope',
        to:
          !physician &&
          `${routes.index}${routes.order.edit}${orderData.id}/${routes.order.physician ?? ''}`,
      },
      {
        name: 'PAYER',
        icon: 'fa-medkit',
        to:
          !physician &&
          `${routes.index}${routes.order.edit}${orderData.id}/${routes.order.insurance ?? ''}`,
      },
      {
        name: 'RX',
        icon: 'RX',
        icon_text: true,
        to:
          !physician &&
          `${routes.index}${routes.order.edit}${orderData.id}/${routes.order.rx ?? ''}`,
      },
      ...((testType === 'HST' || testType === 'PSG') && !physician
        ? [
            {
              name: 'ON',
              icon: 'ON',
              icon_text: true,
              suffix: '|',
              to: `${routes.index}${routes.order.edit}${orderData.id}/officeNotes`,
            },
          ]
        : []),
    ],
    // AOB
    ...(testType !== 'HST' && testType !== 'PSG' && !physician
      ? [
          [
            {
              name: 'AOB',
              icon: 'AOB',
              icon_text: true,
              prefix: '|',
              suffix: '|',
              to: `${routes.index}${routes.order.edit}${orderData.id}/${routes.order.aob ?? ''}`,
            },
          ],
        ]
      : []),
    // Additional Order Information
    ...(!physician
      ? [
          [
            {
              name: 'UPLOAD',
              icon: testType === 'HST' ? 'fa-file' : 'fa-upload',
              to:
                orderData.progress?.UPLOAD === 'PENDING'
                  ? `${routes.index}${routes.order.edit}${orderData.id}/${routes.order.upload}`
                  : null,
            },
            {
              name: 'COMMUNICATION',
              icon: 'fa-fax',
            },
          ],
        ]
      : []),
  ]
}

import React from 'react'
import { useAppSelector, useAppDispatch } from 'hooks/redux'
import { useParams } from 'react-router-dom'
import TitleLead from '../../../atoms/TitleLead'
import * as generalHelper from '../../../../helpers/generalHelper'
import { getDevices } from '../../../../store/slice/deviceSlice'

const DefaultComponent = () => {
  const rows = useAppSelector(state => state.device.rows)
  const dispatch = useAppDispatch()
  let { tab } = useParams()
  if (!tab) {
    tab = 'checkout'
  }

  React.useEffect(() => {
    if (tab == 'avaible') {
      dispatch(
        getDevices({
          add_join: 'brand|device_category.device_type',
          no_cache: 1,
          order_by: ['device_category_id,ASC', 'name,ASC'],
        })
      )
    }
  }, [])

  return (
    <div className="card-body row">
      <div className="col-sm-12 my-2">
        <TitleLead
          label="Checked Out Devices"
          className="lead text-info"
          icon="fad fa-hdd mr-2"
        />
        <hr />
      </div>
      <div className="table-responsive">
        <table className="table table-sm">
          <thead>
            <tr>
              <th width="9%" className="table-col">
                {generalHelper.t('ID / Label')}
              </th>

              <th className="table-col">{generalHelper.t('Device Model')}</th>

              <th className="table-col">
                {generalHelper.t('Current Patient')}
              </th>
              <th className="table-col">{generalHelper.t('Checked-out')}</th>
              <th className="table-col">{generalHelper.t('Order Open')}</th>
              <th className="table-col">
                {generalHelper.t('Referring Physician')}
              </th>

              <th className="table-col">{generalHelper.t('Upload Device')}</th>
              <th className="table-col">{generalHelper.t('Remove Patient')}</th>
            </tr>
          </thead>
          <tbody>
            {rows &&
              Array.isArray(rows) &&
              rows.map((row, index) => {
                return (
                  <tr key={index} className="text-info">
                    <td>
                      <strong className="list-patient">
                        <button className="btn btn-sm btn-outline-dark">
                          <strong>{row.id}</strong>/{row.label}
                        </button>
                      </strong>
                    </td>

                    <td>
                      <strong>{row.brand && row.brand.name}</strong>
                    </td>

                    <td>
                      <strong>{row.patient} Mc Loving </strong>
                    </td>
                    <td>
                      <button className="btn btn-sm btn-warning">
                        1 {row.checkedout} days
                      </button>
                    </td>
                    <td>
                      <button className="btn btn-sm btn-danger">
                        2 {row.opened} days
                      </button>
                    </td>

                    <td>
                      <strong>{row.physician} Fake Doctor </strong>
                    </td>

                    <td>
                      <button className="btn btn-sm btn-primary">
                        <i className="fas fa-upload"></i>
                      </button>
                    </td>
                    <td>
                      <button className="btn btn-sm btn-primary">
                        <i className="fas fa-user-slash"></i>
                      </button>
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default DefaultComponent

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../atoms/Table";
import DashboardContent from "../atoms/DashboardContent";
import * as generalHelper from "../../../helpers/generalHelper";
import { getDeviceCategories } from "../../../store/slice/deviceCategorySlice";
import ButtonNew from "../../atoms/ButtonNew";
import routes from "../constants/routes";

export default function (props) {
    const rows = useSelector((state) => state.deviceCategory.rows);
    const office_id = useSelector((state) => state.init.office_id);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getDeviceCategories({ no_cache: 1, add_join: "device_type" }));
    }, [office_id]);

    function Content(props) {
        const header = [
            {
                key: "id",
                to: `${routes.index}${routes.deviceCategory.root}${routes.upsert}`,
                label: "Id",
            },
            { key: "device_type", key2: "name", label: "Type" },
            { key: "name", label: "Category" },
        ];
        return (
            <div className="card card-primary">
                <form type="post" onSubmit={props.handleSubmit}>
                    <div className="card-header">
                        <h3 className="card-title"></h3>
                        <div className="card-tools">
                            <ButtonNew
                                to={`${routes.index}${routes.deviceCategory.root}${routes.upsert}`}
                            />
                        </div>
                    </div>
                    <div className="card-body row">
                        <Table
                            header={header}
                            rows={Array.isArray(rows) ? rows : []}
                        />
                    </div>
                </form>
            </div>
        );
    }

    return (
        <DashboardContent
            title="Device Categories"
            icon="fa fa-list"
            content={Content(props)}
            breadcrumb={[
                {
                    label: "Devices",
                    to: `${routes.index}${routes.device.root}`,
                },
                { label: "Device Categories" },
            ]}
        />
    );
}

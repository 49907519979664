import { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import statesList from 'constants/states'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import Pagination from 'components/atoms/Pagination'
import _ from 'lodash'
import moment from 'moment'

import { useGetPatientsQuery } from 'store/services/patient'
import { Order, Patient } from 'store/types'
import * as generalHelper from 'helpers/generalHelper'
import { setModal } from 'store/slice/modalSlice'
import Button from '../../atoms/Button'
import Checkbox from '../../atoms/Checkbox'
import Input from '../../atoms/Input'
import Select from '../../atoms/Select'
import TitleLead from '../../atoms/TitleLead'
import { handleChange } from '../../atoms/Upsert'
import DashboardContent from '../atoms/DashboardContent'
import routes from '../constants/routes'
import InputMask from 'components/atoms/InputMask'
import { confirm } from 'components/molecules/Confirmation'
import { setCurrentOfficeTests, switchOffice } from 'store/slice/initSlice'
import { useLazyGetTestTypesQuery } from 'store/services/testTypes'

const states = statesList.map((s) => ({ key: s.id, name: s.name }))

const CellWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    white-space: nowrap;
    display: flex;
  }
`

interface SearchForm {
  firstName: string
  lastName: string
  dob: string
  state: string
  openOrder: boolean
}

const initialSearchVals = {
  firstName: '',
  lastName: '',
  dob: '',
  state: '',
  openOrder: false,
}

const PAGE_SIZE = 10

const PatientSearch = () => {
  const navigate = useNavigate()
  const [values, setValues] = useState<SearchForm>(initialSearchVals)
  const [currentPage, setCurrentPage] = useState(0)
  const currentOfficeId = useAppSelector((state) => state.init.currentOfficeId)
  const offices = useAppSelector((state) => state.init.offices)
  const dispatch = useAppDispatch()
  const [getTestTypes] = useLazyGetTestTypesQuery()
  const {
    data: patients,
    isFetching,
    isLoading,
  } = useGetPatientsQuery({
    ...(values.firstName && { first_name: values.firstName }),
    ...(values.lastName && { last_name: values.lastName }),
    ...(values.dob.length > 7 &&
      values.dob.replace(/\s+|[,\/]/g, '').length > 5 && {
        dob: moment(values.dob).format('YYYY-MM-DD'),
      }),
    ...(values.state && { state: values.state }),
    ...(values.openOrder && { only_open_orders: 'true' }),
    ...(values.firstName || values.lastName ? { size: 100 } : {}),
    page: currentPage,
    size: PAGE_SIZE,
  })
  const location = useLocation()
  const multiOffice = offices?.length > 1
  // console.log(patients, values.dob, values.dob.replace(/\s+|[,\/]/g, ''))

  useEffect(() => {
    dispatch(setModal({}))
    setCurrentPage(0)
    // handleSearch()
  }, [values])

  const inNewOrder = location.pathname.indexOf('newOrder') > -1

  const addUser = () => {
    return (
      <Link
        className="btn btn-primary text-nowrap"
        to={`${routes.index}${routes.order.newOrder}${routes.patient.new}`}
        state={values}>
        {generalHelper.t('Add Patient')}
      </Link>
    )
  }

  const capitalizeWords = (string: string) => {
    const words = string.split(' ')
    words.forEach((element, index: number) => {
      words[index] = words[index]?.toLowerCase()
      words[index] = words[index]?.[0]?.toUpperCase() + words[index].substr(1)
    })
    return words.join(' ')
  }

  const changeLocation = async (
    officeName: string,
    officeId: number,
    route: string,
    state: any,
  ) => {
    try {
      await confirm('', {
        title: 'Warning',
        description: `This patient does not belong to the current office. Do you want to switch to ${officeName}?`,
        yesAction: 'Yes',
        noAction: 'Cancel',
      })

      sessionStorage.removeItem('ts-dme-menu')
      dispatch(switchOffice(officeId))
      const testTypes = await getTestTypes({ officeId: officeId.toString() })
      dispatch(setCurrentOfficeTests(testTypes.data))

      navigate(route, state)
    } catch {
      console.log('no')
    }
  }

  const headers = [
    {
      columnName: 'Patient Name',
      columnSize: multiOffice ? 2 : 2,
      template: (patient: Patient) => (
        <>
          <Link
            to={
              inNewOrder
                ? `${routes.index}${routes.order.newOrder}patient/${patient?.id}/orderDetails`
                : `${routes.index}${routes.patient.edit}${patient?.id}`
            }>
            {`${_.capitalize(patient?.lastName)}${
              patient?.suffix ? ' ' + patient?.suffix : ''
            }, ${_.capitalize(patient?.firstName)} ${
              patient?.middleName ? _.capitalize(patient?.middleName) : ''
            }`}
          </Link>
        </>
      ),
    },
    {
      columnName: 'Date of Birth',
      columnSize: multiOffice ? 1 : 2,
      template: (patient: Patient) => (
        <>
          {moment(moment(patient?.dob).format('MM/DD/YYYY')).isValid()
            ? moment(patient?.dob).format('MM/DD/YYYY')
            : 'N/A'}
        </>
      ),
    },
    {
      columnName: 'Address',
      columnSize: multiOffice ? 2 : 2,
      template: (patient: Patient) => (
        <CellWrapper>
          <div>{patient.primaryAddressStreetLine1}</div>
          <div>
            {patient?.primaryAddressState && (
              <span>{`${capitalizeWords(
                patient?.primaryAddressCity,
              )}, ${patient?.primaryAddressState.toUpperCase()}, ${patient?.primaryAddressZip}`}</span>
            )}
          </div>
        </CellWrapper>
      ),
    },
    ...(multiOffice
      ? [
          {
            columnName: 'Office',
            columnSize: 2,
            template: (patient: Patient) => (
              <CellWrapper>
                <div>{patient?.office?.officeName}</div>
              </CellWrapper>
            ),
          },
        ]
      : []),
    {
      columnName: 'Open Order',
      columnSize: 2,
      template: (patient: Patient) => (
        <CellWrapper>
          {patient?.orders
            ?.filter((i) => i.bucket !== 'COMPLETED')
            ?.map((order: Order) => (
              <div>
                <Link to={`${routes.index}${routes.order.edit}${order.id}`} className=" mr-3">
                  {`#${order.id ?? ''}`}
                </Link>
                {order.testType} - {_.startCase(order.bucket?.replace(/_/g, ' ')?.toLowerCase())} -{' '}
                {moment(order.createdAt).format('MM/DD/YYYY')}
              </div>
            ))}
        </CellWrapper>
      ),
    },
    {
      columnName: 'Completed Order',
      columnSize: 2,
      template: (patient: Patient) => (
        <CellWrapper>
          {patient?.orders
            ?.filter((i) => i.bucket === 'COMPLETED')
            ?.sort((a, b) => (a.communicationScheduledOn > b.communicationScheduledOn ? -1 : 1))
            ?.slice(0, 1)
            ?.map((order: Order) => (
              <div>
                <Link to={`${routes.index}${routes.order.edit}${order.id}`} className=" mr-3">
                  {`#${order.id ?? ''}`}
                </Link>
                {order.testType} - {_.startCase(order.bucket?.replace(/_/g, ' ')?.toLowerCase())} -{' '}
                {moment(order.communicationScheduledOn ?? order.sessionsSelectedAt).format(
                  'MM/DD/YYYY',
                )}
              </div>
            ))}
        </CellWrapper>
      ),
    },
    {
      columnName: 'Action',
      columnSize: 1,
      template: (patient: Patient) => (
        <>
          <Button
            className="btn btn-primary py-0"
            onClick={async () => {
              if (patient.officeId !== currentOfficeId) {
                return changeLocation(
                  patient?.office?.officeName,
                  patient.officeId,
                  `${routes.index}${routes.order.newOrder}patient/${patient?.id}/orderDetails`,
                  {
                    state: {
                      activeOrders: patient?.orders?.filter((i) => i.bucket !== 'COMPLETED'),
                    },
                  },
                )
              }
              navigate(
                `${routes.index}${routes.order.newOrder}patient/${patient?.id}/orderDetails`,
                {
                  state: { activeOrders: patient?.orders?.filter((i) => i.bucket !== 'COMPLETED') },
                },
              )
            }}
            label={generalHelper.t('New Order')}
          />
        </>
      ),
    },
  ]

  return (
    <DashboardContent
      title={`${inNewOrder ? 'Orders' : 'Patients'}`}
      subtitle={`${inNewOrder ? 'New Order' : 'Patient Directory'}`}
      content={
        <div className="card card-primary">
          <div className="card-body">
            {inNewOrder ? (
              <div className="row">
                <div className="col-sm-12 d-flex justify-content-start">
                  <TitleLead
                    className="ml-2 my-3"
                    label={generalHelper.t('Please Select A Patient Below')}
                  />
                </div>
              </div>
            ) : (
              ''
            )}
            <div className="row">
              <div className="col-sm-12">
                <div className="row pl-2">
                  <div className="col-sm-2">
                    <div className="form-group">
                      <label>{generalHelper.t('First Name')}</label>

                      <Input
                        value={values.firstName}
                        label=""
                        placeholder="First Name"
                        name="firstName"
                        autoFocus={true}
                        autoComplete="one-time-code"
                        handleChange={(event) => {
                          handleChange(event, values, setValues)
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="form-group">
                      <label>{generalHelper.t('Last Name')}</label>
                      <Input
                        value={values.lastName}
                        label=""
                        placeholder="Last Name"
                        name="lastName"
                        autoComplete="one-time-code"
                        handleChange={(event) => {
                          handleChange(event, values, setValues)
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="form-group">
                      <label htmlFor="dob">{generalHelper.t('Date Of Birth')}</label>
                      <InputMask
                        value={values.dob ?? ' '}
                        className="form-control"
                        placeholder="Date Of Birth (MM/DD/YYYY)"
                        name="dob"
                        onChange={(event) => {
                          handleChange(event, values, setValues)
                        }}
                        mask="99/99/9999"
                      />
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="form-group">
                      <Select
                        options={states}
                        value={values.state}
                        defaultLabel="All States"
                        name="state"
                        label="State"
                        handleChange={(event) => {
                          handleChange(event, values, setValues)
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-2 col-md-2 pt-4 pb-3 m-auto">
                    <div className="form-check">
                      <Checkbox
                        value={values.openOrder}
                        label="Only Open Orders"
                        name="openOrder"
                        // className="form-check-input"
                        handleChange={(e) => {
                          handleChange(e, values, setValues)
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-2 col-md-2 pt-4">
                    <Button
                      label="Search"
                      className="btn btn-primary mr-3"
                      // onClick={handleSearch}
                      onClick={() => {}}
                    />
                    <Button
                      label="Clear"
                      className="btn btn-default"
                      onClick={() => {
                        setValues(initialSearchVals)
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid">
              <div className="row border-bottom m-2 pb-2 pl-2">
                {Array.isArray(headers) &&
                  headers.map((row, index) => {
                    return (
                      <div
                        className={`col${row?.columnSize ? '-' + row.columnSize : ''} textGray`}
                        key={index}>
                        {row?.columnName}
                      </div>
                    )
                  })}
              </div>
              {Array.isArray(patients?.items) &&
                patients.items.map((patient: Patient, dataIndex: number) => {
                  return (
                    <div className="row border rounded m-2 py-3 pl-2" key={dataIndex}>
                      {Array.isArray(headers) &&
                        headers.map((row, index) => {
                          return (
                            <div
                              className={`col${row?.columnSize ? '-' + row.columnSize : ''} m-auto`}
                              key={index}>
                              {row?.template(patient)}
                            </div>
                          )
                        })}
                    </div>
                  )
                })}
              {Array.isArray(patients) && patients.length === 0 && (
                <>
                  <div className="row border rounded m-2 py-3 pl-2 justify-content-center font-italic textGray font-weight-bold">
                    {generalHelper.t('No patients found.')}
                  </div>
                </>
              )}
            </div>
          </div>
          <Pagination
            onPageChange={setCurrentPage}
            totalCount={patients?.totalItems}
            currentPage={currentPage}
            pageSize={PAGE_SIZE}
          />
        </div>
      }
      titleActionButton={addUser()}
      breadcrumb={[
        { label: 'Home', to: `${routes.index}` },
        {
          label: `${inNewOrder ? 'New Order' : 'Patient Directory'}`,
          to: `${
            inNewOrder
              ? `${routes.index}${routes.order.newOrder}`
              : `${routes.index}${routes.patient.root}`
          }`,
        },
      ]}
    />
  )
}

export default PatientSearch

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashboardContent from "../atoms/DashboardContent";
import { useParams } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { handleChange, handleSubmit } from "../../atoms/Upsert";
import Input from "../../atoms/Input";
import Select from "../../atoms/Select";
import ButtonCancel from "../../atoms/ButtonCancel";
import ButtonSave from "../../atoms/ButtonSave";
import * as generalHelper from "../../../helpers/generalHelper";

import {
    getStatus,
    insertStatus,
    updateStatus,
} from "../../../store/slice/statusSlice";
import routes from "../constants/routes";

export default function (props) {
    const dispatch = useDispatch();
    const [values, setValues] = useState({});
    const [redirect, setRedirect] = useState(false);
    const { id } = useParams();
    const statusTypes = [
        { key: "Order", value: "Order" },
        { key: "Device", value: "Device" },
        { key: "DeviceLog", value: "Device Log" },
        { key: "User", value: "User" },
        { key: "Ticket", value: "Ticket" },
        { key: "Default", value: "Default" },
    ];

    React.useEffect(() => {
        if (id) {
            dispatch(getStatus({ id: id })).then((response) => {
                setValues(response.payload);
            });
        }
    }, []);

    function Content(props) {
        return (
            <div className="card card-primary">
                <form
                    type="post"
                    onSubmit={(event) => {
                        handleSubmit(event, {
                            id: id,
                            values: values,
                            functions: {
                                insert: insertStatus,
                                update: updateStatus,
                            },
                            callback: {
                                function: setRedirect,
                                params: `${routes.index}${routes.status.root}`,
                            },
                            dispatch: dispatch,
                        });
                    }}
                >
                    <div className="card-body row">
                        <div className="col-sm-6 col-md-4">
                            <Select
                                label="Type"
                                name="type"
                                options={statusTypes}
                                value={values.type}
                                handleChange={(event) => {
                                    handleChange(event, values, setValues);
                                }}
                            />
                        </div>
                        <div className="col-sm-6 col-md-4">
                            <Input
                                label="Name"
                                name="name"
                                value={values.name}
                                handleChange={(event) => {
                                    handleChange(event, values, setValues);
                                }}
                            />
                        </div>
                    </div>
                    <div className="card-footer text-right">
                        <ButtonCancel /> <ButtonSave />
                    </div>
                </form>
            </div>
        );
    }

    return (
        <div>
            {redirect && <Navigate to={redirect} />}
            <DashboardContent
                title={id ? "Edit Status" : "Create Status"}
                icon="fa fa-list-alt"
                content={Content(props)}
                breadcrumb={[
                    {
                        label: "Statuss",
                        to: `${routes.index}${routes.status.root}`,
                    },
                    { label: id ? "Edit" : "New" },
                ]}
            />
        </div>
    );
}

/** @jsxImportSource @emotion/react */
import { ReactNode } from 'react'
import { Placement } from 'react-bootstrap/esm/types'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'

interface IProps {
  text?: string
  customHeader?: ReactNode
  customContent?: ReactNode
  children: JSX.Element
  placement?: Placement
}

export const PopoverWrapper = ({
  text,
  children,
  customHeader,
  customContent,
  placement = 'bottom',
}: IProps): JSX.Element => {
  const popover = (
    <Popover>
      {customHeader && <Popover.Header as="h3">{customHeader}</Popover.Header>}
      {customContent ? customContent : <span css={{ padding: '0 5px' }}>{text}</span>}
    </Popover>
  )

  if (!(customContent || text)) {
    return children
  }

  return (
    <OverlayTrigger trigger={['hover', 'focus']} placement={placement} overlay={popover}>
      {children}
    </OverlayTrigger>
  )
}

/** @jsxImportSource @emotion/react */
import orderStateHelpers from 'helpers/orderStateHelpers'
import { useAppDispatch } from 'hooks/redux'
import { useState } from 'react'
import Button from 'react-bootstrap/Button'
import { useNavigate, useParams } from 'react-router-dom'

import { useGetLabOrderQuery } from 'store/services/labOrder'
import { setModal } from 'store/slice/modalSlice'
import { useUpdateOrderMutation } from "store/services/dmeOrder";
import DashboardContent from '../atoms/DashboardContent'
import { HstDeviceModal } from './NightOwl/HstDeviceModal'
import { NightOwlFormHandler } from './NightOwl/NightOwlFormHandler'
import { PatientHeaderTwo } from './NightOwl/PatientHeaderTwo'
import { OrderMsgDropdown } from './OrderMsgDropdown'

const NightOwlEdit = () => {
  const { orderId } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const orderIdNum = Number(orderId)

  const [deviceModalOpen, setDeviceModalOpen] = useState(false)
  const { data: order, isLoading } = useGetLabOrderQuery(Number(orderId), {
    refetchOnFocus: true,
  })
  const [updateOrder] = useUpdateOrderMutation()

  const orderState = orderStateHelpers(order)

  const openOrderTestModal = () => {
    dispatch(
      setModal({
        id: 'order_test_modal',
        patient_id: null,
        device_type_id: null,
      }),
    )
  }

  return (
    <div
      css={{
        '.bg-transparent, .bg-white, .dashboard_header_subtitle': {
          backgroundColor: 'transparent !important',
        },
      }}>
      <HstDeviceModal
        order={order}
        open={deviceModalOpen}
        onClose={() => setDeviceModalOpen(false)}
      />
      <DashboardContent
        title="NightOwl Order"
        icon={<></>}
        subtitle={
          <PatientHeaderTwo
            order={order}
            addNight={async (night) => {
              await updateOrder({
                id: order.id,
                testType: order.testType,
                tests: [
                  ...order.tests,
                  {
                    oxygen: 0,
                    oxygenLpm: 0,
                    deviceType: '',
                    papType: '',
                    apapMax: 0,
                    apapMin: 0,
                    bipapE: 0,
                    bipapI: 0,
                    cpap: 0,
                    ventilatorType: '',
                    night
                  },
                ],
              })
            }}
            onChangeDeviceClick={() => setDeviceModalOpen(true)}
            onEditTestConditionsClick={openOrderTestModal}
          />
        }
        headerClassName={orderState?.isUnderVerification ? 'bg-warning' : ''}
        headerCenterContent={<></>}
        content={
          <div className="card card-primary mt-3">
            <NightOwlFormHandler isLoading={isLoading} order={order} orderState={orderState} />
          </div>
        }
        headerRightContent={
          <div className="col d-flex justify-content-end my-auto pr-2 align-items-center">
            <Button
              css={{ float: 'right' }}
              variant="outline-light"
              onClick={() => navigate(`/lab/orderEdit/${order?.id}`)}>
              Change Demographics
            </Button>
            <OrderMsgDropdown orderId={orderIdNum} />
          </div>
        }
      />
    </div>
  )
}

export default NightOwlEdit

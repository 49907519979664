import * as generalHelper from '../../../helpers/generalHelper'
import Link from '../../atoms/Link'
import routes from '../constants/routes'

type Office = {
  id: number
  name: string
}

const OfficesTable = (props: { offices: Office[] }) => {
  const offices = props.offices

  return (
    <div className="table-responsive">
      <table className="table table-sm">
        <thead>
          <tr>
            <th width="18%" className="table-col">
              {generalHelper.t('Office ID')}
            </th>
            <th width="18%" className="table-col">
              {generalHelper.t('Office')}
            </th>
            <th width="18%" className="table-col">
              {generalHelper.t('Region')}
            </th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(offices) &&
            offices.map((row, index) => {
              return (
                <tr key={index}>
                  <td>{row.id}</td>
                  <td>
                    <strong>
                      <Link
                        to={`${routes.index}${routes.offices.officeDetail}${row.id}`}
                        label={`${row.name}`}
                      ></Link>
                    </strong>
                  </td>
                  <td>
                    <div>South</div>
                  </td>
                </tr>
              )
            })}
        </tbody>
      </table>
    </div>
  )
}

export default OfficesTable

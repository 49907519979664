import Menu from './menu/Menu'
import Index from './index/Index'

import { Routes, Route } from 'react-router-dom'
import Nav from './nav/Nav'
import PhysicianOrderListing from './orderListing/PhysicianOrderListing'
import PhysicianReportListing from './reportListing/PhysicianReportListing'

const DashboardPhysicians = () => {
  return (
    <div className="wrapper">
      <Nav />
      <Menu />
      <div className="content-wrapper" style={{ marginLeft: 230 }}>
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path={`reports/all`} element={<PhysicianReportListing />} />
          <Route path={`reports/:npi`} element={<PhysicianReportListing />} />
          <Route path={`orders/:type`} element={<PhysicianOrderListing />} />
        </Routes>
      </div>
    </div>
  )
}

export default DashboardPhysicians

import { ChangeEvent } from 'react'
import { FieldInputProps } from 'formik'

interface IProps {
  type?: string
  value: string | number
  name?: string
  label?: string
  placeholder?: string
  defaultValue?: string
  autoComplete?: string
  handleChange?: (e: ChangeEvent<HTMLInputElement>) => void
  autoFocus?: boolean
  disabled?: boolean
  className?: string
  minValue?: string
  maxValue?: string
  maxLength?: string
  parentDiv?: boolean | null
  error?: string
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void
}

const InputComponent = (props: IProps) => {
  const type = props?.type ?? 'text'
  const value = props?.value ?? ''
  const defaultValue = props?.defaultValue && !props?.value ? props.defaultValue : undefined
  const autoComplete = type === 'password' ? 'new-password' : props.autoComplete ?? ''
  const handleChange = props?.handleChange ?? (() => {})
  const autoFocus = props?.autoFocus ?? false
  const disabled = props?.disabled === true
  const className = props?.className ?? 'form-control'
  const minValue = props?.minValue
  const maxValue = props?.maxValue
  const error = props?.error ?? ''

  const inputOnly = (
    <>
      <input
        min={minValue}
        max={maxValue}
        maxLength={props.maxLength ? Number(props.maxLength) : undefined}
        autoFocus={autoFocus}
        type={type}
        className={`${className} ${props.error ? 'is-invalid' : ''}`}
        id={props.name}
        placeholder={props.placeholder}
        name={props.name}
        value={value}
        defaultValue={defaultValue}
        autoComplete={autoComplete}
        onChange={handleChange}
        onBlur={handleChange}
        disabled={disabled}
        onKeyPress={props.onKeyPress}
      />
      {error && <div className="text-danger">{error}</div>}
    </>
  )

  if (props.parentDiv === null) {
    return inputOnly
  } else {
    return (
      <div className="form-group mb-0">
        {props.label && (
          <label className="text-gray" htmlFor={props.name}>
            {props.label}
          </label>
        )}
        {inputOnly}
      </div>
    )
  }
}

export const TextInput = ({
  field,
  form,
  ...props
}: {
  form: any
  field: FieldInputProps<string>
  label: string
}) => (
  <InputComponent
    {...field}
    {...props}
    handleChange={field.onChange}
    error={form.errors[field.name]}
  />
)

export default InputComponent

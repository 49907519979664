import * as generalHelper from "../../helpers/generalHelper";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ------------------------------------------------------------
export const getDeviceCategories = createAsyncThunk(
    "getDeviceCategories",
    async (params, { rejectWithValue }) => {
        try {
            const data = await generalHelper.apiGet(
                "/api/device-category",
                params
            );
            if (!data) {
                return rejectWithValue([]);
            }
            return data;
        } catch (error) {
            console.log("error", error);
        }
    }
);

// ------------------------------------------------------------
export const getDeviceCategory = createAsyncThunk(
    "getDeviceCategory",
    async (params, { rejectWithValue }) => {
        try {
            params.return_key = "one";
            const data = await generalHelper.apiGet(
                "/api/device-category",
                params
            );
            if (!data) {
                return rejectWithValue([]);
            }
            return data;
        } catch (error) {
            console.log("error", error);
        }
    }
);

// ------------------------------------------------------------
export const updateDeviceCategory = createAsyncThunk(
    "updateDeviceCategory",
    async ({ id, params }, { rejectWithValue }) => {
        try {
            const data = await generalHelper.apiPut(
                "/api/device-category",
                id,
                params
            );
            if (!data) {
                return rejectWithValue([]);
            }
            return data;
        } catch (error) {
            console.log("error", error);
        }
    }
);

// ------------------------------------------------------------
export const insertDeviceCategory = createAsyncThunk(
    "insertDeviceCategory",
    async (params, { rejectWithValue }) => {
        try {
            const data = await generalHelper.apiPost(
                "/api/device-category",
                params
            );
            if (!data) {
                return rejectWithValue([]);
            }
            return data;
        } catch (error) {
            console.log("error", error);
        }
    }
);

// ------------------------------------------------------------
export const deleteDeviceCategory = createAsyncThunk(
    "deleteDeviceCategory",
    async ({ id, params }, { rejectWithValue }) => {
        try {
            const data = await generalHelper.apiDelete(
                "/api/device-category",
                id,
                params
            );
            if (!data) {
                return rejectWithValue([]);
            }
            return data;
        } catch (error) {
            console.log("error", error);
        }
    }
);

// ------------------------------------------------------------
const initialState = {
    pending: false,
    rows: null,
    row: null,
    update: {},
    insert: {},
    delete: {},
    rejected: false,
};

// ------------------------------------------------------------
const localSlice = createSlice({
    name: "deviceCategory",
    initialState,
    extraReducers: {
        [getDeviceCategories.rejected]: (state, action) => {
            state.pending = false;
            state.rejected = true;
            state.rows = null;
        },
        [getDeviceCategories.pending]: (state, action) => {
            state.pending = true;
            state.rejected = false;
            state.rows = null;
        },
        [getDeviceCategories.fulfilled]: (state, action) => {
            state.pending = false;
            state.rejected = false;
            state.rows = action.payload;
        },
        [getDeviceCategory.rejected]: (state, action) => {
            state.pending = false;
            state.rejected = true;
            state.row = null;
        },
        [getDeviceCategory.pending]: (state, action) => {
            state.pending = true;
            state.rejected = false;
            state.row = null;
        },
        [getDeviceCategory.fulfilled]: (state, action) => {
            state.pending = false;
            state.rejected = false;
            state.row = action.payload;
        },
        [updateDeviceCategory.rejected]: (state, action) => {
            state.pending = false;
            state.rejected = true;
            state.rows = null;
        },
        [updateDeviceCategory.pending]: (state, action) => {
            state.pending = true;
            state.rejected = false;
            state.rows = null;
        },
        [updateDeviceCategory.fulfilled]: (state, action) => {
            state.pending = false;
            state.rejected = false;
            state.update = action.payload;
        },
        [insertDeviceCategory.rejected]: (state, action) => {
            state.pending = false;
            state.rejected = true;
            state.rows = null;
        },
        [insertDeviceCategory.pending]: (state, action) => {
            state.pending = true;
            state.rejected = false;
            state.rows = null;
        },
        [insertDeviceCategory.fulfilled]: (state, action) => {
            state.pending = false;
            state.rejected = false;
            state.insert = action.payload;
        },
        [deleteDeviceCategory.rejected]: (state, action) => {
            state.pending = false;
            state.rejected = true;
            state.rows = null;
        },
        [deleteDeviceCategory.pending]: (state, action) => {
            state.pending = true;
            state.rejected = false;
            state.rows = null;
        },
        [deleteDeviceCategory.fulfilled]: (state, action) => {
            state.pending = false;
            state.rejected = false;
            state.delete = action.payload;
        },
        "logout/fulfilled": (state, action) => initialState,
    },
});

export default localSlice.reducer;

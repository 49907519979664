/** @jsxImportSource @emotion/react */
import { useEffect, useMemo, useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import Spinner from 'react-bootstrap/Spinner'

import { useAppDispatch, useAppSelector } from 'hooks/redux'
import * as generalHelper from 'helpers/generalHelper'
import Upload from 'components/atoms/Upload'
import Button from 'components/atoms/Button'
import TitleLead from 'components/atoms/TitleLead'
import Input from 'components/atoms/Input'
import Link from 'components/atoms/Link'
import config from 'config/app'
import TabHeader from 'components/atoms/TabHeader'
import MaskedInput from 'react-text-mask'
import { phoneNumberMask } from 'helpers/masks'
import { formatPhoneNumber } from 'helpers/format'
import routes from '../../constants/routes'
import { confirm } from 'components/molecules/Confirmation'
import * as api from 'helpers/api'
import {
  useAddFileMutation,
  useRemoveFileMutation,
  usePromiseFileMutation,
  useSendAobSignRequestMutation,
} from 'store/services/dmeOrder'
import { Order } from 'store/types'

interface IProps {
  order: Order
  stateHelpers: any
}

const AobFormTab = ({ order, stateHelpers }: IProps) => {
  const { tab, orderId } = useParams()
  const [loadingPdf, setLoadingPdf] = useState(false)
  const [addFile, result] = useAddFileMutation()
  const [removeFile] = useRemoveFileMutation()
  const [promiseFile] = usePromiseFileMutation()
  const [loading, setLoading] = useState(false)
  const [sendAobSignRequest] = useSendAobSignRequestMutation()
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState()
  const files = order?.files ? Object.values(order.files).filter((f) => f.uploadType === 'AOB') : []
  const canDelete = order?.progress?.AOB !== 'DONE'

  const getMostRecentRequest = useCallback(
    (type: 'email' | 'sms') => {
      if (!order?.aobRequested) return null
      const requests = Object.values(order.aobRequested)
        .filter((v) => v.method === type)
        .sort((a, b) => (a.requestDate < b.requestDate ? 1 : -1))
      return requests?.[0]
    },
    [order],
  )

  const mostRecentAobEmail = useMemo(() => getMostRecentRequest('email'), [getMostRecentRequest])
  const mostRecentAobSms = useMemo(() => getMostRecentRequest('sms'), [getMostRecentRequest])

  const [aobSent, setAobSent] = useState({
    email: false,
    sms: false,
  })

  const fetchAobTemplatePdf = async () => {
    setLoadingPdf(true)
    const result = await api.get(`/dme/orders/${orderId}/aob-template`, {}, true)
    const url = window.URL.createObjectURL(new Blob([result], { type: 'application/pdf' }))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'AOB_Template.pdf')
    document.body.appendChild(link)
    link.click()
    setLoadingPdf(false)
  }

  useEffect(() => {
    setEmail(order?.patient?.email)
  }, [order])

  const handleUpload = async (acceptedFiles: File[]) => {
    setLoading(true)
    try {
      console.log(acceptedFiles)
      const [file] = acceptedFiles

      const payload = {
        orderId,
        uploadType: 'AOB',
      }
      const { url, key } = await api.get(`/dme/upload-url`, {
        ...payload,
        fileName: file.name,
      })

      console.log(url, key)
      await api.s3Put(url, file)
      await addFile({ ...payload, fileKey: key, fileName: file.name })
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const handleRemoveFile = ({ key }) => {
    if (key) {
      confirm('file').then(() => {
        removeFile({ orderId, fileKey: key })
      })
    }
  }

  const validateAndSendSms = (formattedPhone: string) => {
    confirm('', {
      title: 'Patient Consent?',
      description: 'Has patient consent been obtained to send the AOB form via SMS?',
      yesAction: 'Yes, Send SMS',
      noAction: 'No, Cancel',
    })
      .then(() => {
        sendAobSignRequest({
          orderId: Number(orderId),
          method: 'sms',
          phone: formattedPhone,
        })
        setAobSent({ ...aobSent, sms: true })
      })
      .catch()
  }

  const handleSendSms = () => {
    const formattedPhone = `${(phone || order?.patient?.mobilePhone).replace(/[()-\s]/g, '')}`

    if (orderId && (phone || order?.patient?.mobilePhone) && order?.state?.AOB !== 'DONE') {
      validateAndSendSms(formattedPhone)
    } else if (orderId && (phone || order?.patient?.mobilePhone) && order?.state?.AOB === 'DONE') {
      confirm('', {
        title: 'Are you sure?',
        description:
          'There is already an AOB on file.  This will resend the AOB form to the patient.',
        yesAction: 'Send Anyway',
        noAction: 'Cancel',
      })
        .then(() => validateAndSendSms(formattedPhone))
        .catch()
    }
  }

  const handleSendEmail = () => {
    if (orderId && email && order?.state?.AOB !== 'DONE') {
      sendAobSignRequest({
        orderId: Number(orderId),
        method: 'email',
        email,
      })
      setAobSent({ ...aobSent, email: true })
    } else if (orderId && email && order?.state?.AOB === 'DONE') {
      confirm('', {
        title: 'Are you sure?',
        description:
          'There is already an AOB on file.  This will resend the AOB form to the patient.',
        yesAction: 'Send Anyway',
        noAction: 'Cancel',
      })
        .then(() => {
          sendAobSignRequest({
            orderId: Number(orderId),
            method: 'email',
            email,
          })
          setAobSent({ ...aobSent, email: true })
        })
        .catch()
    }
  }

  const actionButtons = [
    ...(!order?.promisedFiles?.AOB && !files?.length
      ? [
          <Button
            label="Fax AOB Form"
            className="mr-3 btn-outline-secondary"
            onClick={() => promiseFile({ orderId: Number(orderId), promiseType: 'AOB' })}
          />,
        ]
      : []),
    ...(!files?.length
      ? [
          <Upload
            label="Upload AOB Form"
            className="btn btn-primary"
            disabled={loading}
            handleUpload={handleUpload}>
            {loading && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="mr-1"
              />
            )}
          </Upload>,
        ]
      : []),
  ]

  return (
    <div className="container">
      <TabHeader
        title="Assignment of Benefits Form"
        back={`${routes.index}${routes.order.edit}${orderId}/device`}
        next={`${routes.index}${routes.order.edit}${orderId}/upload`}
        actionButtons={actionButtons}
      />

      <div className="row">
        <div className="col-sm-12 mt-3">
          <TitleLead label="Uploaded files" />{' '}
        </div>
        <div className="col-sm-12">
          <div className="table-responsive">
            <table className="table table-hover table-sm">
              <thead>
                <tr>
                  <th>{generalHelper.t('Document Name')}</th>
                  <th>{generalHelper.t('Date')}</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {Array.isArray(files) &&
                  files.map((row, index) => (
                    <tr key={index}>
                      <td>
                        <a target="_blank" href={row.url} rel="noreferrer">
                          {row.filename}
                        </a>
                      </td>
                      <td>{`${moment(row.uploadDate).format('h:mm a MMM Do, YYYY')}`}</td>
                      <td>
                        {canDelete && (
                          <Link
                            to="#"
                            label="Remove"
                            className="btn btn-outline-danger btn-sm"
                            onClick={() => handleRemoveFile(row)}
                          />
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {files?.length === 0 && !order?.promisedFiles?.AOB && (
              <div className="card mt-0 ml-2 mr-2 mb-3">
                <div className="card-body text-center font-italic text-muted">
                  {generalHelper.t('No Files have been uploaded to this order')}.
                </div>
              </div>
            )}
            {files?.length === 0 && order?.promisedFiles?.AOB && (
              <div className="card">
                <div className="card-body text-center font-italic text-muted">
                  This order has been marked to receive a fax. The AOB form now needs to be faxed to
                  TestSmarter at:{' '}
                  <span className="text-bold" css={{ fontStyle: 'normal' }}>
                    {config?.testSmarterFaxNumber}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {!stateHelpers.isAobUploaded && (
        <>
          <div className="row ">
            <div className="col-sm-12 mt-2 mb-3">
              <TitleLead label="Select a delivery method for the AOB form to the Patient:" />
            </div>

            <div className="col-sm-6 col-md-4 d-flex flex-column">
              <div className="text-bold mt-2 mb-2">
                <TitleLead label="Send via E-Email" />
              </div>
              <div className="mt-2">
                {generalHelper.t(
                  'The patient will receive the AOB form to their emails where they can sign online.',
                )}
              </div>
              <div className="mt-2 d-flex justify-content-end flex-column" style={{ flex: 1 }}>
                <Input
                  value={email}
                  type="text"
                  handleChange={(e) => setEmail(e.target.value)}
                  className="form-control mb-1"
                  placeholder="Email"
                />
                <Button
                  disabled={!order?.patient?.email && !email}
                  className="btn btn-lg btn-primary btn-block"
                  label="Send via E-mail"
                  onClick={handleSendEmail}
                />
              </div>
            </div>

            <div className="col-sm-6 col-md-4 d-flex flex-column">
              <div className="text-bold mt-2 mb-2">
                <TitleLead label="Send via SMS" />
              </div>
              <div className="mt-2">
                {generalHelper.t(
                  'The patient will receive an SMS message with a link to where they can sign online.',
                )}
              </div>
              <div className="mt-2 d-flex justify-content-end flex-column" style={{ flex: 1 }}>
                <MaskedInput
                  value={phone}
                  type="text"
                  defaultValue={order?.patient?.mobilePhone?.replace(/^\+1/, '')}
                  onChange={(e) => setPhone(e.target.value)}
                  mask={phoneNumberMask}
                  className="form-control mb-1"
                  placeholder="Mobile Phone"
                />
                <Button
                  disabled={!order?.patient?.mobilePhone && !phone}
                  className="btn btn-lg btn-primary btn-block"
                  label="Send via SMS"
                  onClick={handleSendSms}
                />
              </div>
            </div>

            <div className="col-sm-6 col-md-4 d-flex flex-column">
              <div className="text-bold mt-2 mb-2">
                <TitleLead label="Print PDF" />
              </div>
              <div className="mt-2">
                {generalHelper.t(
                  'Download a PDF intended to be printed and added to the order via scan or fax.  After clicking button below, look in Downloads folder.',
                )}
              </div>
              <div className="mt-2 d-flex align-items-end" style={{ flex: 1 }}>
                <Button
                  disabled={loadingPdf}
                  className="btn btn-lg btn-primary btn-block"
                  label="Print PDF"
                  onClick={fetchAobTemplatePdf}>
                  {loadingPdf ? (
                    <Spinner
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="mr-3 mb-1"
                    />
                  ) : (
                    <></>
                  )}
                </Button>
              </div>
            </div>
          </div>

          {(mostRecentAobEmail || mostRecentAobSms) && (
            <div className="row pt-3">
              <div className="col-sm-4" style={{ opacity: 0.6 }}>
                {mostRecentAobEmail && (
                  <>
                    An email has been sent to: <br />
                    Email: <span style={{ fontWeight: 600 }}>{mostRecentAobEmail.email}</span>
                    <br />
                    Time:{' '}
                    <span style={{ fontWeight: 600 }}>
                      {moment(mostRecentAobEmail.requestDate).format('lll')}
                    </span>
                  </>
                )}
              </div>
              <div className="col-sm-4" style={{ opacity: 0.6 }}>
                {mostRecentAobSms && (
                  <>
                    An SMS has been sent to: <br />
                    Number:{' '}
                    <span style={{ fontWeight: 600 }}>
                      {formatPhoneNumber(mostRecentAobSms.phone)}
                    </span>
                    <br />
                    Time:{' '}
                    <span style={{ fontWeight: 600 }}>
                      {moment(mostRecentAobSms.requestDate).format('lll')}
                    </span>
                  </>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default AobFormTab

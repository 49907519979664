/** @jsxImportSource @emotion/react */
import { useEffect } from 'react'
import { css } from '@emotion/react'
import { Field } from 'formik'
import Button from 'react-bootstrap/Button'
import moment from 'moment'

import { confirm } from 'components/molecules/Confirmation'
import { formatPhoneNumber } from 'helpers/format'
import { Order } from 'store/types'
import {
  useRefundNightOwlShipmentMutation,
  useLazyGetNightOwlShippingHistoryQuery,
} from 'store/services/labOrder'

interface IProps {
  order: Order
  values: any
  errors: any
  touched: any
  orderState: any
}

export const DeviceLogistics = ({ order, values, errors, touched, orderState }: IProps) => {
  const orderId = Number(order.id)
  const [refundNightOwlShipment] = useRefundNightOwlShipmentMutation()
  const [getShippingHistory, { currentData: shippingHistory }] =
    useLazyGetNightOwlShippingHistoryQuery({ orderId })

  const { thirdPartyDevice } = order || {}
  const welcomeSent = !!thirdPartyDevice?.activationCodeDate
  const deviceShipped = !!thirdPartyDevice?.serial

  useEffect(() => {
    if (deviceShipped) {
      getShippingHistory({ orderId })
    }
  }, [deviceShipped])

  return (
    <div>
      <div className="mt-4 d-flex">
        <i className="fa fa-check text-success mr-2" css={{ fontSize: '1.5rem' }} />
        <h5>Order has been submitted to NightOwl</h5>
      </div>

      <div>
        {!welcomeSent ? (
          <div>
            <div className="mt-4 d-flex">
              <i
                className="fas fa-exclamation-triangle text-danger mr-2"
                css={{ fontSize: '1.5rem' }}
              />
              <h5>Send welcome email with testing instructions to patient</h5>
            </div>
            <span className="ml-4">
              An invite to e-sign the AOB will also be sent to the patient via SMS to{' '}
              {formatPhoneNumber(order?.patient.mobilePhone)}.
            </span>
            <div className="col-6 mt-3 ml-3 form-group textGray">
              <label htmlFor="email">Confirm Email</label>
              <Field
                type="text"
                name="email"
                className="form-control"
                placeholder="Email"
                value={values.email}
                autoComplete="off"
              />
              {touched.email && errors.email ? (
                <div className="text-danger">{errors.email}</div>
              ) : null}
            </div>
            <Button variant="primary ml-4" type="submit" data-flag="sendWelcome">
              Send Welcome Email
            </Button>
          </div>
        ) : (
          <div>
            <div className="mt-4 d-flex">
              <i className="fa fa-check text-success mr-2" css={{ fontSize: '1.5rem' }} />
              <h5>Welcome email has been sent to patient</h5>
            </div>
            <div>
              {moment(thirdPartyDevice?.activationCodeDate).format('lll')}: Sent to{' '}
              {order?.patient?.nightOwls?.[0]?.email}, Activation Code:{' '}
              {thirdPartyDevice?.activationCode}
            </div>
            <Button
              variant="outline-primary"
              className="mt-3"
              type="submit"
              data-flag="sendWelcome">
              Re-Send Welcome Email
            </Button>
            {orderState.isTestSmarterNightOwlFulfillment && (
              <div css={{ lineHeight: '1.2rem' }}>
                {!deviceShipped ? (
                  <>
                    <div className="mt-4 d-flex">
                      <i
                        className="fas fa-exclamation-triangle text-danger mr-2"
                        css={{ fontSize: '1.5rem' }}
                      />
                      <h5>Ship Device to Patient</h5>
                    </div>
                    <span>No shipping label has been created yet.</span>
                    <div className="mt-3 ml-3 form-group textGray">
                      <label htmlFor="email">Scan or enter NightOwl device serial number:</label>
                      <Field
                        type="text"
                        name="serialNumber"
                        className="form-control"
                        placeholder="Serial number..."
                        value={values.serialNumber}
                        autoComplete="off"
                        css={css`
                          max-width: 300px;
                        `}
                      />
                      {touched.serialNumber && errors.serialNumber ? (
                        <div className="text-danger">{errors.serialNumber}</div>
                      ) : null}
                      <Button
                        variant="primary"
                        className="mt-2"
                        type="submit"
                        data-flag="assignDevice">
                        Assign Device & Create Shipping Label
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="mt-4 d-flex">
                      <i className="fa fa-check text-success mr-2" css={{ fontSize: '1.5rem' }} />
                      <h5>Device Shipped to Patient</h5>
                    </div>
                    <strong>NightOwl Device Serial #:</strong> {thirdPartyDevice?.serial}
                    <br />
                    <strong>Tracking Number:</strong> {thirdPartyDevice?.trackingNumber}{' '}
                    <a
                      href={thirdPartyDevice?.trackingUrlProvider}
                      target="_blank"
                      rel="noreferrer">
                      Track Online
                    </a>
                    <br />
                    <Button
                      variant="primary"
                      className="mt-3"
                      target="_blank"
                      href={thirdPartyDevice?.shippingLabel}>
                      Print Shipping Label
                    </Button>
                    <br />
                    <Button
                      variant="danger"
                      className="mt-3"
                      css={{ maxWidth: 250 }}
                      onClick={() =>
                        confirm('', {
                          title: 'Are you sure?',
                          description:
                            'This will cancel the shipment and request a refund. Proceed?',
                          yesAction: 'Yes, proceed',
                          noAction: 'No, cancel',
                        })
                          .then(() => refundNightOwlShipment({ orderId: orderId }))
                          .catch(() => {})
                      }>
                      Cancel Shipment & Request Refund (Device Has Not Been Shipped Yet)
                    </Button>
                    <br />
                    <br />
                    <h5>Shipping Updates</h5>
                    {!!shippingHistory ? (
                      <ul css={{ listStyle: 'disc' }}>
                        {shippingHistory?.tracking_history?.map((item, index) => (
                          <li key={index}>
                            <strong css={{ minWidth: 100 }}>
                              {moment(item.status_date).format('MM/DD/YYYY hh:mm a')}
                            </strong>
                            : {item.status_details} ({item?.location?.city}{' '}
                            {item?.location?.state ? `, ${item?.location?.state}` : ''})
                          </li>
                        ))}
                      </ul>
                    ) : (
                      'Tracking information will be displayed below as it becomes available.'
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

import parseFullname from 'parse-full-name'
import orderStatus from '../../constants/orderStatus'

export const polar = [
  { value: '', key: '' },
  { value: 0, key: 'No' },
  { value: 1, key: 'Yes' },
]

export const oxygenLpm = [
  { value: '' },
  { value: 'Unspecified' },
  { value: '0.25' },
  { value: '0.50' },
  { value: '0.75' },
  { value: '1.00' },
  { value: '1.25' },
  { value: '1.50' },
  { value: '1.75' },
  { value: '2.00' },
  { value: '2.25' },
  { value: '2.50' },
  { value: '2.75' },
  { value: '3.00' },
  { value: '3.25' },
  { value: '3.50' },
  { value: '3.75' },
  { value: '4.00' },
  { value: '4.25' },
  { value: '4.50' },
  { value: '4.75' },
  { value: '5.00' },
  { value: '5.25' },
  { value: '5.50' },
  { value: '5.75' },
  { value: '6.00' },
  { value: '6.25' },
  { value: '6.50' },
  { value: '6.75' },
  { value: '7.00' },
  { value: '7.25' },
  { value: '7.50' },
  { value: '7.75' },
  { value: '8.00' },
  { value: '8.25' },
  { value: '8.50' },
  { value: '8.75' },
  { value: '9.00' },
  { value: '9.25' },
  { value: '9.50' },
  { value: '9.75' },
  { value: '10.00' },
  { value: '10.25' },
  { value: '10.50' },
  { value: '10.75' },
  { value: '11.00' },
  { value: '11.25' },
  { value: '11.50' },
  { value: '11.75' },
  { value: '12.00' },
  { value: '12.25' },
  { value: '12.50' },
  { value: '12.75' },
  { value: '13.00' },
  { value: '13.25' },
  { value: '13.50' },
  { value: '13.75' },
  { value: '14.00' },
  { value: '14.25' },
  { value: '14.50' },
  { value: '14.75' },
  { value: '15.00' },
  { value: '15.25' },
  { value: '15.50' },
  { value: '15.75' },
  { value: '16.00' },
]

export const papType = [
  { value: '' },
  { value: 'Unspecified' },
  { value: 'APAP' },
  { value: 'BiPAP' },
  { value: 'CPAP' },
  { value: 'OTHER' },
]

export const minPressure = [
  { value: '' },
  { value: 'Unspecified' },
  { value: '4' },
  { value: '5' },
  { value: '6' },
  { value: '7' },
  { value: '8' },
  { value: '9' },
  { value: '10' },
  { value: '11' },
  { value: '12' },
  { value: '13' },
  { value: '14' },
  { value: '15' },
  { value: '16' },
  { value: '17' },
  { value: '18' },
  { value: '19' },
  { value: '20' },
]

export const maxPressure = [
  { value: '' },
  { value: 'Unspecified' },
  { value: '8' },
  { value: '9' },
  { value: '10' },
  { value: '11' },
  { value: '12' },
  { value: '13' },
  { value: '14' },
  { value: '15' },
  { value: '16' },
  { value: '17' },
  { value: '18' },
  { value: '19' },
  { value: '20' },
  { value: '21' },
  { value: '22' },
  { value: '23' },
  { value: '24' },
]

export const formatOrderTestPayload = (isNewOrder, values) => {
  if (!isNewOrder) {
    values.order_id = Number(values.order_id)
  }
  values.oxygen = Number(values.oxygen)
  values.pap = Number(values.pap)
  if (values.oxygen === 0) {
    values.oxygen_lpm = null
  }
  if (values.pap === 0) {
    values.pap_type = null
    values.apap_min = null
    values.apap_max = null
  }
  if (values.pap === 1 && values.pap_type === 'Unspecified') {
    values.apap_min = null
    values.apap_max = null
  }
  if (values.oxygen == 1) {
    values.oxygen_lpm = Number(values.oxygen_lpm).toFixed(2)
  }
  if (values.pap == 1 && values.pap_type === 'APAP') {
    values.apap_min = Number(values.apap_min)
    values.apap_max = Number(values.apap_max)
    values.bipap_i = null
    values.bipap_e = null
    values.cpap = null
  }
  if (values.pap == 1 && values.pap_type === 'BiPAP') {
    values.apap_min = null
    values.apap_max = null
    values.bipap_i = Number(values.bipap_i)
    values.bipap_e = Number(values.bipap_e)
    values.cpap = null
  }
  if (values.pap == 1 && values.pap_type === 'CPAP') {
    values.apap_min = null
    values.apap_max = null
    values.bipap_i = null
    values.bipap_e = null
    values.cpap = Number(values.cpap)
  }
  values.non_invasive = +values.non_invasive
  values.adaptive_support = +values.adaptive_support
  values.oral_appliace = +values.oral_appliace
  if (values.hasOwnProperty('select_patient')) {
    delete values.select_patient
  }
  delete values.order
  return values
}

export const formatOrderPayload = (values, patientVal) => {
  const patient = Array.isArray(patientVal) ? patientVal[0] : patientVal
  const returnValues = {
    device_category_id: Number(values.device_category_id),
    order_test: formatOrderTestPayload(true, values),
  }
  if (patient && !patient.customOption) {
    returnValues.patient_id = Number(patient.id)
  } else {
    const fullNameParser = parseFullname
    const fullName = fullNameParser.parseFullName(patient.label)
    returnValues.patient = {
      first_name: fullName.first,
      middle_name: fullName.middle,
      last_name: fullName.last,
      suffix: fullName.suffix,
    }
  }
  returnValues.status_id = orderStatus.open
  return returnValues
}

export const formatOrderModalPayload = (values) => {
  const patient = Array.isArray(values.select_patient)
    ? values.select_patient[0]
    : values.select_patient
  const returnValues = {
    device_category_id: Number(values.device_category_id),
  }
  if (patient && !patient.customOption) {
    returnValues.patient_id = Number(patient.id)
  } else {
    const fullNameParser = parseFullname
    const fullName = fullNameParser.parseFullName(patient.label)
    returnValues.patient = {
      first_name: fullName.first,
      middle_name: fullName.middle,
      last_name: fullName.last,
      suffix: fullName.suffix,
    }
  }
  returnValues.status_id = orderStatus.open
  return returnValues
}

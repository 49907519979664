/** @jsxImportSource @emotion/react */
import { useRef, useEffect } from 'react'

import { Order } from 'store/types'
import { ReportListing } from 'components/organisms/reports/ReportListing'

interface IProps {
  order: Order
  refetchOrder: () => void
}

const ReportTab = ({ order, refetchOrder }: IProps) => {
  const reportQueued = order?.reports && Object.values(order?.reports).some((v) => v === 'QUEUED')
  const intervalRef = useRef()

  useEffect(() => {
    if (reportQueued && !intervalRef.current) {
      intervalRef.current = setInterval(refetchOrder, 2000)
    } else {
      clearInterval(intervalRef?.current?.cancel)
    }

    return () => clearInterval(intervalRef.current)
  }, [reportQueued])

  return <ReportListing order={order} />
}

export default ReportTab

import { useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import { useAuth0 } from '@auth0/auth0-react'
import { v4 as uuidv4 } from 'uuid'

import Toast from 'components/atoms/Toast'
import { post } from 'helpers/api'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import * as authHelper from 'helpers/authHelper'
import { getInit, setCurrentOfficeTests } from 'store/slice/initSlice'
import { useLazyGetDmeOfficeTestsQuery } from 'store/services/office'
import type { TokenExchangePayload } from 'ts-common/types/TokenExchangePayload';
import Logo from '../../assets/img/logo2.png'

type TokenExchangePayload = {
  access_token: string
  expires_in: number
  id_token: string
  login_email: string
  nonce: string
  scope: string
}

const LoginCallback = () => {
  const navigate = useNavigate()
  const init = useAppSelector((state) => state.init)
  const dispatch = useAppDispatch()
  const [getDmeOffice] = useLazyGetDmeOfficeTestsQuery()
  const { getAccessTokenSilently, isAuthenticated } = useAuth0()

  const exchangeTokenAndInit = useCallback(async () => {
    if (!isAuthenticated || authHelper.isLoggedIn()) return

    getAccessTokenSilently({
      detailedResponse: true,
    }).then((auth0Tokens) => {
      const loginEmail = localStorage.getItem('login_email') || '';
      const nonce = uuidv4();
      const data: TokenExchangePayload = {
        ...auth0Tokens,
        login_email: loginEmail,
        nonce: nonce,
      };
      localStorage.removeItem('login_email');
      post('/auth/token', data).then((tsTokens) => {
        authHelper.setToken(tsTokens)
        console.log(`Init pending? ${init.pending}`)
        dispatch(getInit())
      }).catch(err => {
        console.log(err)
        if (err.response?.status === 422 && err.response?.data?.message) {
          Toast({ type: 'error', label: err.response?.data.message, options: { position: "bottom-center" } })
        }
        authHelper.logout()
      })
    })

  }, [isAuthenticated, init])

  useEffect(() => {
    exchangeTokenAndInit()
  }, [isAuthenticated, exchangeTokenAndInit])

  useEffect(() => {
    if (init?.userType === 'LAB') {
      navigate('/lab')
    } else if (init?.userType === 'DME') {
      getDmeOffice({ id: Number(init?.currentOfficeId) })
        .unwrap()
        .then((tests) => dispatch(setCurrentOfficeTests(tests)))
      navigate('/dme')
    }
  }, [init])

  return (
    <Container>
      <div style={{ position: 'absolute', left: '50%', maxWidth: '320px', textAlign: 'center', top: '50%', transform: 'translate(-50%, -50%)' }}>
        <div style={{ borderBottom: '2px solid #006ca5', marginBottom: '15px', paddingBottom: '15px' }}>
          <img src={Logo} alt="logo" />
        </div>
        <p style={{ fontSize: '1.15em', fontWeight: '600' }}>Please wait a moment.</p>
        <p>
          <span style={{ whiteSpace: 'nowrap' }}>If you are not automatically redirected,</span> &nbsp;
          <span style={{ whiteSpace: 'nowrap' }}>please <a href="/">click here to try again</a>.</span>
        </p>
      </div>
    </Container>
  )
}

export default LoginCallback

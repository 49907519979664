import { Fragment, ReactNode } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import Loader from 'components/atoms/LoadingPage'
import { useAppSelector } from 'hooks/redux'
import routes from 'components/dme/constants/routes'
import { isDmeUser, isLabUser } from 'helpers/authRolesHelper'

interface IProps {
  children: ReactNode
}

const Protected = ({ children }: IProps) => {
  const location = useLocation()
  const login = useAppSelector((state) => state.login)
  const userType = useAppSelector((state) => state.init.userType)
  const init = useAppSelector((state) => state.init)
  const onDmeRoute = location.pathname.startsWith('/dme')
  const onLabRoute = location.pathname.startsWith('/lab')
  const accessToken = localStorage.getItem('access_token')

  if (login?.pending || init?.pending) {
    return (
      <Container maxWidth="sm">
        <Box sx={{ height: '100vh' }}>
          <Loader>
            <Typography variant="h6">Loading...</Typography>
          </Loader>
        </Box>
      </Container>
    )
  }

  if (!login?.data?.access_token && !accessToken) {
    return <Navigate to={`/${routes.login}`} state={{ from: location }} replace />
  }
  if (onDmeRoute && userType !== 'DME') {
    return <Navigate to={`/${routes.login}`} state={{ from: location }} replace />
  }
  if (onLabRoute && userType !== 'LAB') {
    return <Navigate to={`/${routes.login}`} state={{ from: location }} replace />
  }

  return <Fragment>{children}</Fragment>
}

export default Protected

import React from 'react'
import { useAppSelector, useAppDispatch } from 'hooks/redux'
import { useParams } from 'react-router-dom'
import TitleLead from '../../../atoms/TitleLead'
import * as generalHelper from '../../../../helpers/generalHelper'
import { getDevices } from '../../../../store/slice/deviceSlice'

const DefaultComponent = () => {
  const rows = useAppSelector(state => state.device.rows)
  const dispatch = useAppDispatch()
  let { tab } = useParams()
  if (!tab) {
    tab = 'checkout'
  }

  React.useEffect(() => {
    if (tab === 'avaible') {
      dispatch(
        getDevices({
          add_join: 'brand|device_category.device_type',
          no_cache: 1,
          order_by: ['device_category_id,ASC', 'name,ASC'],
        })
      )
    }
  }, [])

  return (
    <div className="card-body row">
      <div className="col-sm-12 my-2">
        <TitleLead
          label="Available Devices"
          className="lead text-info"
          icon="fad fa-hdd mr-2"
        />
        <hr />
      </div>
      <div className="table-responsive">
        <table className="table table-sm">
          <thead>
            <tr>
              <th width="9%" className="table-col">
                {generalHelper.t('ID / Label')}
              </th>

              <th className="table-col">{generalHelper.t('Device Model')}</th>

              <th className="table-col">
                {generalHelper.t('Device Returned')}
              </th>
              <th className="table-col">{generalHelper.t('Last Checkout')}</th>
              <th className="table-col">
                {generalHelper.t('Check Out Device')}
              </th>
            </tr>
          </thead>
          <tbody>
            {rows &&
              Array.isArray(rows) &&
              rows.map((row, index) => {
                return (
                  <tr key={index} className="text-info">
                    <td>
                      <strong className="list-patient">
                        <div className="btn btn-sm btn-outline-dark">
                          <strong>{row.id}</strong>/{row.label}
                        </div>
                      </strong>
                    </td>

                    <td>
                      <strong>{row && row.brand && row.brand.name}</strong>
                    </td>

                    <td>
                      <button className="btn btn-sm btn-secondary">
                        1 {row.returned} days
                      </button>
                    </td>
                    <td>{row.created_at}</td>
                    <td>
                      <button className="btn btn-sm btn-primary">
                        <i className="fas fa-arrow-alt-circle-up"></i>
                      </button>
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default DefaultComponent

import { Field } from 'formik'

import states from 'constants/states'
import { TextInput } from 'components/atoms/Input'
import { SelectInput } from 'components/atoms/Select'
import { MaskedTextInput } from 'components/atoms/InputMask'

export interface PayerFormValues {
  name: string
  telephone: string
  fax: string
  addressStreetLine1: string
  addressStreetLine2: string
  addressCity: string
  addressState: string
  addressZip: string
}

export const PayerForm = () => {
  return (
    <>
      <div className="row">
        <div className="col-12 form-group">
          <label className="textGray">Name *</label>
          <Field
            component={TextInput}
            label={false}
            placeholder="Payor Name"
            name="name"
            autoFocus
            autoComplete="new-password"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 form-group">
          <label className="textGray">Telephone Number</label>
          <Field
            component={MaskedTextInput}
            name="telephone"
            mask="(999) 999-9999"
            autoComplete="new-password"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 form-group">
          <label className="textGray">Fax Number</label>
          <Field
            component={MaskedTextInput}
            name="fax"
            mask="(999) 999-9999"
            autoComplete="new-password"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 form-group">
          <label className="textGray">Address</label>
          <Field
            component={TextInput}
            label={false}
            placeholder="Street Address"
            name="addressStreetLine1"
            autoComplete="new-password"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 form-group">
          <Field
            component={TextInput}
            label={false}
            placeholder="Suite/Apt"
            name="addressStreetLine2"
            autoComplete="new-password"
          />
        </div>
        <div className="col-12"></div>
      </div>
      <div className="row">
        <div className="col-4 form-group">
          <label className="textGray">City</label>
          <Field
            component={TextInput}
            label={false}
            placeholder="City"
            name="addressCity"
            autoComplete="new-password"
          />
        </div>
        <div className="col-4 form-group">
          <Field
            component={SelectInput}
            options={states || []}
            defaultLabel="State"
            labelClassName="textGray"
            name="addressState"
            label="State"
            autoComplete="new-password"
          />
        </div>
        <div className="col-4 form-group">
          <label className="textGray">Zip Code</label>
          <Field
            component={TextInput}
            label={false}
            placeholder="Zip Code"
            name="addressZip"
            autoComplete="new-password"
          />
        </div>
      </div>
    </>
  )
}

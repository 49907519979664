/** @jsxImportSource @emotion/react */
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import moment from 'moment'

import { PreAuthorizationForm } from '../payor/PreAuthorizationForm'
import { Order } from 'store/types'
import { useSetCopayAmountMutation, useSetLabOrderPayerInfoMutation } from 'store/services/labOrder'
import routes from 'components/lab/constants/routes'

interface IProps {
  order: Order
}

export const PreAuthorization = ({ order }: IProps) => {
  const navigate = useNavigate()
  const [urlSearch] = useSearchParams()
  const [setOrderPayerInfo] = useSetLabOrderPayerInfoMutation()
  const [setCopayAmount] = useSetCopayAmountMutation()
  const bucket = urlSearch.get('bucket')
  const testType = urlSearch.get('testType')

  const onSubmit = async (values: any) => {
    await Promise.all([
      setOrderPayerInfo({
        orderId: order?.id,
        followUpDate: values.followUpDate ? moment(values.followUpDate).format('YYYY-MM-DD') : '',
        notes: values.notes,
        authorizationNumber: values.authorizationNumber,
        authorizationState: values.authorizationState,
        authorizationValidStartDate: values.authorizationValidStartDate,
        authorizationValidEndDate: values.authorizationValidEndDate,
      }).unwrap(),
      setCopayAmount({
        orderId: Number(order?.id),
        value: values.copayAmount,
      }).unwrap(),
    ])
  }

  return (
    <div className="w-100">
      <div className="row">
        <div className="col-sm-10 p-0 d-flex align-items-center">
          <h4 className="m-0">Pre Authorization</h4>
        </div>
      </div>
      <div className="row">
        <div
          className="col-xl-6 col-lg-12 p-3 mt-3 d-flex flex-column align-items-end"
          css={{ background: '#F5F5F5' }}>
          <PreAuthorizationForm order={order} onSubmit={onSubmit} />
          <div className="d-flex">
            <Button variant="primary" className="mt-2" type="submit" form="insuranceInfo">
              Save Changes
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

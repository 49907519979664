import axios from 'axios'
import { refreshAccessToken } from './authHelper'

const axiosApiInstance = axios.create()

axiosApiInstance.interceptors.response.use((response) => {
  return response
}, async function (error) {
  const originalRequest = error.config
  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true
    const access_token = await refreshAccessToken()

    if (!access_token) {
      return Promise.reject(error)
    }
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token
    return axiosApiInstance(originalRequest)
  }
  return Promise.reject(error)
})

export default axiosApiInstance
/** @jsxImportSource @emotion/react */
import CheckboxComponent from 'components/atoms/Checkbox'
import { Dispatch, SetStateAction, useState } from 'react'
import { Button } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'
import MaskedInput from 'react-text-mask'
import { useDisregardOrderFaxMutation } from 'store/services/labOrder'
import { Order } from 'store/types'
import { phoneNumberMask } from '../../../../helpers/masks'

interface IProps {
  order: Order
  open: boolean
  reportsSelections: number[]
  faxNumber: string
  disregardReportId: number | null
  setFaxNumber: Dispatch<SetStateAction<string>>
  onClose: () => void
}

export const DisregardReportModal = ({
  order,
  open,
  reportsSelections,
  onClose,
  faxNumber,
  disregardReportId,
  setFaxNumber,
}: IProps) => {
  const [sendFax, setSendFax] = useState<boolean>(false)
  const [showError, setShowError] = useState<boolean>(false)
  const [disregardOrderFax] = useDisregardOrderFaxMutation()

  const onSubmit = () => {
    const rawNumber = faxNumber.match(/\d/g)!.join('')

    if (!reportsSelections.length && !disregardReportId) {
      onClose()
      return
    }

    disregardOrderFax({
      orderId: Number(order.id),
      reports: disregardReportId ? [disregardReportId] : reportsSelections,
      sendFax,
      toFax: `+1${rawNumber}`,
    })
    onClose()
  }

  return (
    <Modal centered show={open} onHide={onClose} size="sm">
      <Modal.Header>
        <Modal.Title>Disregard reports</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="mb-1 pb-2">
          <label>
            Enter fax number<span className="text-danger">*</span>
          </label>
          <MaskedInput
            value={faxNumber}
            type="text"
            onChange={(e) => {
              setShowError(false)
              setFaxNumber(e.target.value)
            }}
            mask={phoneNumberMask}
            className="form-control mb-1"
            placeholder="Fax number"
          />
          {showError ? <div className="text-danger">Invalid phone number</div> : ''}
          <CheckboxComponent
            checked={sendFax}
            handleChange={(e) => setSendFax(e.target.checked)}
            label="Send fax"
            parentClassName="mr-3 custom-control mt-3"
            id="send-fax"
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            const rawNumber = faxNumber?.match(/\d/g)?.join('')

            if (!rawNumber || rawNumber.length !== 10) {
              setShowError(true)
              return
            }

            onSubmit()
          }}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

import DashboardContent from '../atoms/DashboardContent'
import CalloutComponent from '../../atoms/Callout'

const HelpKnowledge = () => (
  <DashboardContent
    title="Knowledge Base"
    icon="fa fa-lightbulb-on"
    content={
      <div className="col-12">
        <div className="card card-default">
          <div className="card-body">
            <CalloutComponent
              title="Knowledge Base"
              className="danger"
              content={<div>Testing information here</div>}
            />
          </div>
        </div>
      </div>
    }
    breadcrumb={[{ label: 'Knowledge Base' }]}
  />
)

export default HelpKnowledge

import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DashboardContent from '../atoms/DashboardContent'
import { useParams } from 'react-router-dom'
import { Navigate } from 'react-router-dom'
import { handleChange, handleSubmit } from '../../atoms/Upsert'
import { getDeviceTypes } from '../../../store/slice/deviceTypeSlice'
import Input from '../../atoms/Input'
import Select from '../../atoms/Select'
import ButtonCancel from '../../atoms/ButtonCancel'
import ButtonSave from '../../atoms/ButtonSave'
import * as generalHelper from '../../../helpers/generalHelper'

import {
  getDeviceCategory,
  insertDeviceCategory,
  updateDeviceCategory,
} from '../../../store/slice/deviceCategorySlice'
import routes from '../constants/routes'

const DeviceCategoryUpsert = props => {
  const dispatch = useDispatch()
  const [values, setValues] = useState({})
  const [redirect, setRedirect] = useState(false)
  const deviceTypes = useSelector(state => state.deviceType.rows)
  const { id } = useParams()

  useEffect(() => {
    dispatch(getDeviceTypes({}))
    if (id) {
      dispatch(getDeviceCategory({ id: id })).then(response => {
        setValues(response.payload)
      })
    }
  }, [])

  function Content(props) {
    return (
      <div className="card card-primary">
        <form
          type="post"
          onSubmit={event => {
            handleSubmit(event, {
              id: id,
              values: values,
              functions: {
                insert: insertDeviceCategory,
                update: updateDeviceCategory,
              },
              callback: {
                function: setRedirect,
                params: `${routes.index}${routes.device.deviceCategory}`,
              },
              dispatch: dispatch,
            })
          }}
        >
          <div className="card-body row">
            <div className="col-4">
              <Select
                label={generalHelper.t('Type')}
                name="device_type_id"
                options={deviceTypes}
                value={values.device_type_id}
                handleChange={event => {
                  handleChange(event, values, setValues)
                }}
              />
            </div>

            <div className="col-4">
              <Input
                label={generalHelper.t('Name')}
                name="name"
                value={values.name}
                handleChange={event => {
                  handleChange(event, values, setValues)
                }}
              />
            </div>
            <div className="col-4">
              <Input
                label={generalHelper.t('Status')}
                name="status"
                value={values.status}
                handleChange={event => {
                  handleChange(event, values, setValues)
                }}
              />
            </div>
            <div className="col-4">
              <Input
                label={generalHelper.t('Sort')}
                name="sort"
                type="number"
                value={values.sort}
                handleChange={event => {
                  handleChange(event, values, setValues)
                }}
              />
            </div>
          </div>
          <div className="card-footer text-right">
            <ButtonCancel /> <ButtonSave />
          </div>
        </form>
      </div>
    )
  }

  return (
    <div>
      {redirect && <Navigate to={redirect} />}
      <DashboardContent
        title={id ? 'Edit Device Category' : 'Create Device Category'}
        icon="fa fa-disc-drive"
        content={Content(props)}
        breadcrumb={[
          {
            label: 'Devices',
            to: `${routes.index}${routes.device.root}`,
          },
          {
            label: 'Categories',
            to: `${routes.index}${routes.device.deviceCategory}`,
          },
          { label: id ? 'Edit' : 'New' },
        ]}
      />
    </div>
  )
}

export default DeviceCategoryUpsert

import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DashboardContent from '../atoms/DashboardContent'
import { useParams } from 'react-router-dom'
import { getRoleTypes } from '../../../store/slice/roleTypeSlice'
import { Navigate } from 'react-router-dom'
import { handleChange, handleSubmit } from '../../atoms/Upsert'
import Input from '../../atoms/Input'
import ButtonCancel from '../../atoms/ButtonCancel'
import ButtonSave from '../../atoms/ButtonSave'
import Select from '../../atoms/Select'
import * as generalHelper from '../../../helpers/generalHelper'

import { getRole, insertRole, updateRole } from '../../../store/slice/roleSlice'
import routes from '../constants/routes'

const ManageRoleUpsert = props => {
  const dispatch = useDispatch()
  const roleTypes = useSelector(state => state.roleType.rows)
  const [values, setValues] = useState({})
  const [redirect, setRedirect] = useState(false)
  const { id } = useParams()
  let managerId = id ? id : null

  React.useEffect(() => {
    dispatch(getRoleTypes())
    if (managerId) {
      dispatch(getRole({ id: managerId })).then(response => {
        setValues(response.payload)
      })
    }
  }, [])

  function Content(props) {
    return (
      <div className="card card-primary">
        <form
          type="post"
          onSubmit={event => {
            handleSubmit(event, {
              id: managerId,
              values: values,
              functions: {
                insert: insertRole,
                update: updateRole,
              },
              callback: {
                function: setRedirect,
                params: `${routes.index}${routes.manager.role}`,
              },
              dispatch: dispatch,
            })
          }}
        >
          <div className="card-body row">
            <div className="col-6">
              <Select
                label={generalHelper.t('Role Type')}
                name="role_type_id"
                options={roleTypes}
                value={values.role_type_id}
                handleChange={event => {
                  handleChange(event, values, setValues)
                }}
              />
            </div>
            <div className="col-6">
              <Input
                label={generalHelper.t('Name')}
                name="name"
                value={values.name}
                handleChange={event => {
                  handleChange(event, values, setValues)
                }}
              />
            </div>
          </div>
          <div className="card-footer text-right">
            <ButtonCancel to="/device" /> <ButtonSave />
          </div>
        </form>
      </div>
    )
  }

  return (
    <div>
      {redirect && <Navigate to={redirect} />}
      <DashboardContent
        title={managerId ? 'Edit Role' : 'Create Role'}
        icon="fas fa-user-tag"
        content={Content(props)}
        breadcrumb={[
          { label: 'Home', to: `${routes.index}` },
          {
            label: 'Roles',
            to: `${routes.index}${routes.manager.role}`,
          },
          { label: managerId ? 'Edit' : 'New' },
        ]}
      />
    </div>
  )
}

export default ManageRoleUpsert

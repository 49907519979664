import classnames from 'classnames'
import { usePagination, DOTS } from '../customHooks/usePagination'

interface IProps {
  onPageChange: (page: number) => void
  totalCount: number
  siblingCount: number
  currentPage: number
  pageSize: number
  className: string
  setPageSize: (v: number) => void
}

const Pagination = (props: IProps) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    setPageSize,
    className,
  } = props

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  })
  // console.log(paginationRange)

  // If there are less than 2 times in pagination range we shall not render the component
  if (paginationRange?.length < 2) {
    return null
  }

  const onNext = () => {
    onPageChange(Number(Number(currentPage) + 1))
  }

  const onPrevious = () => {
    onPageChange(Number(Number(currentPage) - 1))
  }

  const lastPage = paginationRange?.[paginationRange?.length - 1]
  return (
    <div className="row mt-3 justify-content-between" style={{ width: '100%' }}>
      <div className="pagination_outer-contaiiner row">
        {!isNaN(Number(currentPage)) && !!lastPage && (
          <>
            <span className="pageCounter ml-3">
              Page: {Number(Number(currentPage) + 1)} of {lastPage}
            </span>

            <ul
              className={classnames('pagination-container', {
                [className]: className,
              })}>
              {/* Left navigation arrow */}
              <li
                className={classnames('pagination-item', {
                  disabled: currentPage === 0,
                })}
                onClick={onPrevious}>
                <div className="arrow left" />
              </li>
              {paginationRange?.map((pageNumber, key) => {
                // If the pageItem is a DOT, render the DOTS unicode character
                if (pageNumber === DOTS) {
                  return (
                    <li key={`page_dots_${key}`} className="pagination-item dots">
                      &#8230;
                    </li>
                  )
                }

                // Render our Page Pills
                return (
                  <li
                    onClick={(event) => {
                      event.preventDefault()
                      onPageChange(pageNumber - 1)
                    }}
                    key={`page_${key}`}
                    className={classnames('pagination-item', {
                      selected: pageNumber === currentPage + 1,
                    })}>
                    {pageNumber}
                  </li>
                )
              })}
              {/*  Right Navigation arrow */}
              <li
                className={classnames('pagination-item', {
                  disabled: currentPage + 1 === lastPage,
                })}
                onClick={onNext}>
                <div className="arrow right" />
              </li>
            </ul>
          </>
        )}
      </div>
    </div>
  )
}

export default Pagination

/** @jsxImportSource @emotion/react */
import { skipToken } from '@reduxjs/toolkit/query/react'
import React from 'react'
import {
  AddressModel,
  BrightreeOrder,
} from 'ts-back-end/dist/ts-back-end/src/business/brightree/types/order'
import { useGetLabPayerQuery } from 'store/services/payer'
import { Order, Payer } from 'store/types'
import Button from '../../atoms/Button'

interface IProps {
  order: Order
}

const Header = ({
  text,
  secondary,
}: {
  text: string | React.ReactElement
  secondary?: boolean
}) => (
  <h3
    css={{
      background: !secondary ? '#2c83bd' : '#e8e8e8',
      fontSize: !secondary ? 16 : 14,
      fontWeight: 500,
      padding: '5px 10px',
      width: '100%',
      color: !secondary ? 'white' : 'black',
      margin: 0,
    }}>
    {text}
  </h3>
)

const Field = ({
  name,
  value1,
  value2,
  uri,
}: {
  name: string
  value1: string | number | null
  value2?: string | number | null
  uri?: string
}) => (
  <div
    css={{
      padding: '10px 10px',
      borderBottom: '1px solid #efefef',
      display: 'flex',
      alignItems: 'center',
    }}>
    <div
      css={{
        color: 'grey',
        fontWeight: 600,
        fontSize: 12,
        width: '30%',
      }}>
      {name}
    </div>
    <div
      css={{
        fontSize: 12,
        width: '30%',
      }}>
      {value1 || '-'}
    </div>
    <div
      css={{
        fontSize: 12,
        width: '30%',
      }}>
      {value2 || '-'}
    </div>
    {uri && (
      <Button
        type="button"
        onClick={() => window.open(uri, '_blank')}
        className="btn btn-primary"
        label="Download"
        css={{
          height: 25,
          display: 'flex',
          alignItems: 'center',
        }}
      />
    )}
  </div>
)

const AddressBlock = ({
  address,
  orderAddress,
}: {
  address: AddressModel
  orderAddress: AddressModel
}) => (
  <>
    <Field name={'ADDRESS 1'} value1={address?.Address1} value2={orderAddress?.Address1} />
    <Field name={'ADDRESS 2'} value1={address?.Address2} value2={orderAddress?.Address2} />
    <Field name={'ADDRESS 3'} value1={address?.Address3} value2={orderAddress?.Address3} />
    <Field name={'CITY'} value1={address?.City} value2={orderAddress?.City} />
    <Field name={'STATE'} value1={address?.State} value2={orderAddress?.State} />
    <Field name={'POSTAL CODE'} value1={address?.PostalCode} value2={orderAddress?.PostalCode} />
    <Field name={'PHONE'} value1={address?.Phone} value2={orderAddress.Phone} />
    <Field name={'MOBILE PHONE'} value1={address?.MobilePhone} value2={orderAddress?.MobilePhone} />
    <Field name={'FAX'} value1={address?.Fax} value2={orderAddress?.Fax} />
    <Field name={'EMAIL'} value1={address?.EmailAddress} value2={orderAddress?.EmailAddress} />
  </>
)

export const BrightreeLogs = ({ order }: IProps) => {
  const brightreeSource = order.brightreeSource as BrightreeOrder

  return (
    <div>
      <Header
        text={
          <div className="d-flex">
            <div css={{ width: '30%' }}>Property</div>
            <div css={{ width: '30%' }}>Brightree data</div>
            <div>Order data</div>
          </div>
        }
        secondary={true}
      />
      <Header text={'PATIENT IDENTIFIERS'} />
      <Field
        name={'BRANCH'}
        value1={brightreeSource.Patient.BasicDemographics.Branch?.Value}
        value2={order.brightreeSiteNickname}
      />
      <Field
        name={'BRANCH ID'}
        value1={brightreeSource.Patient.BasicDemographics?.Branch?.BrightreeID}
        value2={brightreeSource.Patient.BasicDemographics?.Branch?.BrightreeID}
      />
      <Field
        name={'BRIGHTREE PATIENT ID'}
        value1={brightreeSource.Patient.BrightreeID}
        value2={order.patient?.btPatientId}
      />
      <Field
        name={"DME'S PATIENT ID"}
        value1={brightreeSource.Patient.PatientID}
        value2={order.patient?.id}
      />
      <Field
        name={'EXTERNAL ID'}
        value1={brightreeSource.Patient.ExternalID}
        value2={order.patient?.universalId}
      />
      <Field
        name={'ACCOUNT NUMBER'}
        value1={brightreeSource.Patient.BasicDemographics?.AccountNumber}
      />
      <Field
        name={'ACCOUNT GROUP'}
        value1={brightreeSource.Patient.BasicDemographics?.AccountGroup}
      />
      <Header text={'PATIENT INFORMATION'} />
      <Field
        name={'FULL NAME'}
        value1={[
          brightreeSource.Patient.BasicDemographics?.Name?.Title,
          brightreeSource.Patient.BasicDemographics?.Name?.First,
          brightreeSource.Patient.BasicDemographics?.Name?.Middle,
          brightreeSource.Patient.BasicDemographics?.Name?.Last,
          brightreeSource.Patient.BasicDemographics?.Name?.Suffix,
        ]
          .filter(Boolean)
          .join(' ')}
        value2={order.patient?.fullName}
      />
      <Field
        name={'NAME (FIRST)'}
        value1={brightreeSource.Patient.BasicDemographics?.Name?.First}
        value2={order.patient?.firstName}
      />
      <Field
        name={'NAME (MIDDLE)'}
        value1={brightreeSource.Patient.BasicDemographics?.Name?.Middle}
        value2={order.patient?.middleName}
      />
      <Field
        name={'NAME (LAST)'}
        value1={brightreeSource.Patient.BasicDemographics?.Name?.Last}
        value2={order.patient?.lastName}
      />
      <Field
        name={'NAME (SUFFIX)'}
        value1={brightreeSource.Patient.BasicDemographics?.Name?.Suffix}
        value2={order.patient?.suffix}
      />
      <Field
        name={'NAME (TITLE)'}
        value1={brightreeSource.Patient.BasicDemographics?.Name?.Title}
      />
      <Field
        name={'GENDER'}
        value1={
          brightreeSource.Patient.BasicDemographics?.Gender
            ? brightreeSource.Patient.BasicDemographics?.Gender === 'M'
              ? 'Male'
              : 'Female'
            : null
        }
        value2={order.patient?.gender ? (order.patient.gender === 'M' ? 'Male' : 'Female') : null}
      />
      <Field
        name={'HEIGHT'}
        value1={brightreeSource.Patient.BasicDemographics?.Height}
        value2={`${order.patient?.heightFt}' ${order.patient?.heightIn}"`}
      />
      <Field
        name={'WEIGHT'}
        value1={brightreeSource.Patient.BasicDemographics?.Weight}
        value2={order.patient?.weight}
      />
      <Field
        name={'DOB'}
        value1={brightreeSource.Patient.BasicDemographics?.BirthDate}
        value2={order.patient?.dob}
      />
      <Header text={'DELIVERY ADDRESS'} />
      <AddressBlock
        address={brightreeSource.Patient.BasicDemographics?.DeliveryAddress}
        orderAddress={{
          Address1: order.patient?.deliveryAddressStreetLine1,
          Address2: order.patient?.deliveryAddressStreetLine2,
          Address3: null,
          City: order.patient?.deliveryAddressCity,
          State: order.patient?.deliveryAddressState,
          PostalCode: order.patient?.deliveryAddressZip,
          Fax: null,
          Phone: order.patient?.homePhone,
          MobilePhone: order.patient?.mobilePhone,
          EmailAddress: order.patient?.email,
        }}
      />
      <Header text={'BILLING ADDRESS'} />
      <AddressBlock
        address={brightreeSource.Patient.BasicDemographics?.BillingAddress}
        orderAddress={{
          Address1: order.patient?.primaryAddressStreetLine1,
          Address2: order.patient?.primaryAddressStreetLine2,
          Address3: null,
          City: order.patient?.primaryAddressCity,
          State: order.patient?.primaryAddressState,
          PostalCode: order.patient?.primaryAddressZip,
          Fax: null,
          Phone: order.patient?.homePhone,
          MobilePhone: order.patient?.mobilePhone,
          EmailAddress: order.patient?.email,
        }}
      />
      <Header text={'DIAGNOSES'} />
      {brightreeSource.Patient.ClinicalInformation?.Diagnosis?.map(
        ({ ICDCode, Description, DiagType, Sequence }, i) => (
          <>
            <Header text={`DIAGNOSIS #${i + 1}`} secondary={true} />
            <Field name={'DESCRIPTION'} value1={Description} />
            <Field name={'TYPE'} value1={DiagType} />
            <Field name={'ICD CODE'} value1={ICDCode} />
            <Field name={'SEQUENCE'} value1={Sequence} />
          </>
        ),
      )}
      <Header text={`PHYSICIAN`} />
      <Field
        name={'BRIGHTREE ID'}
        value1={brightreeSource.Patient.ClinicalInformation?.OrderingDoctor?.BrightreeID}
        value2={order.physician?.btPhysicianId}
      />
      <Field
        name={'NPI'}
        value1={brightreeSource.Patient.ClinicalInformation?.OrderingDoctor?.NPI}
        value2={order.physician?.npi}
      />
      <Field
        name={'FULL NAME'}
        value1={[
          brightreeSource.Patient.ClinicalInformation?.OrderingDoctor?.Name?.Title,
          brightreeSource.Patient.ClinicalInformation?.OrderingDoctor?.Name?.First,
          brightreeSource.Patient.ClinicalInformation?.OrderingDoctor?.Name?.Middle,
          brightreeSource.Patient.ClinicalInformation?.OrderingDoctor?.Name?.Last,
          brightreeSource.Patient.ClinicalInformation?.OrderingDoctor?.Name?.Suffix,
        ]
          .filter(Boolean)
          .join(' ')}
        value2={[order.physician?.firstName, order.physician?.middleName, order.physician?.lastName]
          .filter(Boolean)
          .join(' ')}
      />
      <Field
        name={'NAME (FIRST)'}
        value1={brightreeSource.Patient.ClinicalInformation?.OrderingDoctor?.Name?.First}
        value2={order.physician?.firstName}
      />
      <Field
        name={'NAME (MIDDLE)'}
        value1={brightreeSource.Patient.ClinicalInformation?.OrderingDoctor?.Name?.Middle}
        value2={order.physician?.middleName}
      />
      <Field
        name={'NAME (LAST)'}
        value1={brightreeSource.Patient.ClinicalInformation?.OrderingDoctor?.Name?.Last}
        value2={order.physician?.lastName}
      />
      <Field
        name={'NAME (SUFFIX)'}
        value1={brightreeSource.Patient.ClinicalInformation?.OrderingDoctor?.Name?.Suffix}
      />
      <Field
        name={'NAME (TITLE)'}
        value1={brightreeSource.Patient.ClinicalInformation?.OrderingDoctor?.Name?.Title}
      />
      <Field
        name={'FAX'}
        value1={brightreeSource.Patient.ClinicalInformation?.OrderingDoctor?.Fax}
        value2={order.physicianAddress?.fax}
      />
      <Header text={`PHYSICIAN ADDRESS`} />
      <AddressBlock
        address={brightreeSource.Patient.ClinicalInformation?.OrderingDoctor?.Address}
        orderAddress={{
          Address1: order.physicianAddress?.addressStreetLine1,
          Address2: order.physicianAddress?.addressStreetLine2,
          Address3: null,
          City: order.physicianAddress?.addressCity,
          State: order.physicianAddress?.addressState,
          PostalCode: order.physicianAddress?.addressZip,
          Fax: order.physicianAddress?.fax,
          Phone: order.physicianAddress?.telephone,
          MobilePhone: null,
          EmailAddress: null,
        }}
      />
      <Header text={`INSURANCE`} />
      {brightreeSource.Patient.InsuranceSummary.Policies.map((payer, i) => {
        const levelToSection = (level: 'Primary' | 'Secondary' | 'Tertiary') => {
          let result
          switch (level) {
            case 'Primary':
              result = 1
              break
            case 'Secondary':
              result = 2
              break
            case 'Tertiary':
              result = 3
              break
            default:
              result = 0
          }
          return result
        }

        const sectionToLevel = (level: 1 | 2 | 3) => {
          let result
          switch (level) {
            case 1:
              result = 'Primary'
              break
            case 2:
              result = 'Secondary'
              break
            case 3:
              result = 'Tertiary'
              break
            default:
              result = 'Unknown'
          }

          return result
        }

        const orderPayer =
          order.verifiedPayers?.find(({ position }) => position === levelToSection(payer.Level)) ||
          order.payers.find(({ position }) => position === levelToSection(payer.Level))
        const { data: labPayer } = useGetLabPayerQuery<Payer>(
          orderPayer?.labPayerId ? { id: orderPayer.labPayerId.toString() } : skipToken,
        )

        return (
          <>
            <Header text={`PAYOR #${i + 1}`} secondary={true} />
            <Field
              name={'BRIGHTREE ID'}
              value1={payer.Payor.BrightreeID}
              value2={orderPayer?.btPayerId}
            />
            <Field name={'PAYOR'} value1={payer.Payor.Value} value2={orderPayer?.name} />
            <Field name={'CONTACT'} value1={payer.Payor.Contact} />
            <Field name={'COVERAGE TYPES'} value1={payer.Payor.CoverageTypes.join(', ')} />
            <Field
              name={'POLICY LEVEL'}
              value1={payer.Level}
              value2={sectionToLevel(orderPayer?.position)}
            />
            <Field
              name={'POLICY START DATE'}
              value1={payer.StartDate}
              value2={order.payersInfo.authorizationValidStartDate}
            />
            <Field
              name={'POLICY END DATE'}
              value1={payer.EndDate}
              value2={order.payersInfo.authorizationValidEndDate}
            />
            <Field name={'POLICY PAY PERCENT'} value1={payer.PayPercent} />
            <Field name={'POLICY DEDUCTIBLE'} value1={payer.Deductible} />
            <Field
              name={'POLICY NUMBER'}
              value1={payer.PolicyNumber}
              value2={orderPayer?.memberId}
            />
            <Field
              name={'POLICY GROUP NUMBER'}
              value1={payer.GroupNumber}
              value2={orderPayer?.groupId}
            />
            <Field name={'POLICY HOLDER'} value1={payer.PolicyHolder} />
            <Field name={'POLICY EMPLOYER'} value1={payer.Employer} />
            <Field name={'POLICY CONTACT'} value1={payer.Contact} />
            <Header text={`ADDRESS`} secondary={true} />
            <AddressBlock
              address={payer.Payor.Address}
              orderAddress={{
                Address1: labPayer?.addressStreetLine1,
                Address2: labPayer?.addressStreetLine2,
                Address3: null,
                City: labPayer?.addressCity,
                State: labPayer?.addressState,
                PostalCode: labPayer?.addressZip,
                Fax: labPayer?.fax,
                Phone: labPayer?.telephone,
                MobilePhone: null,
                EmailAddress: null,
              }}
            />
          </>
        )
      })}
      <Header text={`SUPPORTING DOCUMENTS`} />
      {brightreeSource.SupportingDocuments.map(({ ExpirationDate }) => (
        <Field name={'EXPIRATION DATE'} value1={ExpirationDate} />
      ))}
    </div>
  )
}

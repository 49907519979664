import {
  DeviceBrandsList,
  DevicesList,
  DevicesListQuery,
  CreateDeviceDto,
  DeviceListItem,
} from '../types'
import { baseApi } from './baseApi'

export const deviceApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getDeviceBrands: builder.query<DeviceBrandsList, { userType: string }>({
      query: ({ userType }) => ({
        url: `/${userType === 'DME' ? 'dme' : 'lab'}/device-brands/list`,
      }),
      providesTags: ['DeviceBrands'],
    }),
    getLabDevices: builder.query<DevicesList, DevicesListQuery>({
      query: (query) => ({ url: `/lab/devices/list`, params: query }),
      providesTags: ['Devices'],
    }),
    getLabDevice: builder.query<DeviceListItem, number>({
      query: (id) => ({ url: `/lab/devices/${id}` }),
      providesTags: ['Devices'],
    }),
    createLabDevice: builder.mutation<void, CreateDeviceDto>({
      query: (body) => ({ url: `/lab/devices`, method: 'POST', body }),
      invalidatesTags: ['Devices'],
    }),
    createDmeDevice: builder.mutation<void, CreateDeviceDto>({
      query: (body) => ({ url: `/dme/devices`, method: 'POST', body }),
      invalidatesTags: ['Devices'],
    }),
    updateLabDevice: builder.mutation<void, { id: number } & CreateDeviceDto>({
      query: (body) => ({ url: `/lab/devices/${body.id}`, method: 'PATCH', body }),
      invalidatesTags: ['Devices'],
    }),
    updateDmeDevice: builder.mutation<void, { id: number } & CreateDeviceDto>({
      query: (body) => ({ url: `/dme/devices/${body.id}`, method: 'PATCH', body }),
      invalidatesTags: ['Devices'],
    }),
    getDmeDevices: builder.query<DevicesList, DevicesListQuery>({
      query: (params) => ({ url: `/dme/devices/list`, params }),
      providesTags: ['Devices'],
    }),
    getDmeDeviceBrands: builder.query<DeviceBrandsList, void>({
      query: () => ({ url: `/dme/device-brands/list` }),
      providesTags: ['DeviceBrands'],
    }),
  }),
})

export const {
  useGetDeviceBrandsQuery,
  useGetLabDevicesQuery,
  useLazyGetLabDevicesQuery,
  useGetLabDeviceQuery,
  useCreateLabDeviceMutation,
  useCreateDmeDeviceMutation,
  useUpdateLabDeviceMutation,
  useUpdateDmeDeviceMutation,
  useGetDmeDevicesQuery,
  useLazyGetDmeDevicesQuery,
  useGetDmeDeviceBrandsQuery,
} = deviceApi

/** @jsxImportSource @emotion/react */
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import moment from 'moment/moment'
import { useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import { useParams } from 'react-router-dom'
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined'
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn'
import BlockIcon from '@mui/icons-material/Block'
import PrintIcon from '@mui/icons-material/Print'

import {
  useLazyGetNightOwlShippingHistoryQuery,
  useRefundNightOwlShipmentMutation,
  useReturnThirdPartyDeviceMutation,
} from 'store/services/labOrder'
import { Order } from 'store/types'
import Toast from 'components/atoms/Toast'
import { confirm } from 'components/molecules/Confirmation'

interface IProps {
  order: Order
}

const ShippingSection = ({ order }: IProps) => {
  const { orderId } = useParams()
  const [refundShipment] = useRefundNightOwlShipmentMutation()
  const [returnThirdPartyDevice] = useReturnThirdPartyDeviceMutation()
  const [getShippingHistory, { currentData: shippingHistory }] =
    useLazyGetNightOwlShippingHistoryQuery({ orderId })

  const deviceShippedBack = !!order.thirdPartyDevice?.returnShippedAt
  const devicePendingReturn = order.state?.DEVICE?.NIGHT_OWL?.TS_DELIVERY === 'PENDING_RETURN'

  const deviceShipped = !!order.thirdPartyDevice?.shippedAt
  const deviceDelivered = !!order.thirdPartyDevice?.deliveredAt
  const deviceReturned = !!order.thirdPartyDevice?.returnedAt

  const manualShipping = !!order.thirdPartyDevice?.manualShipping
  const localPickup = !!order.thirdPartyDevice?.localPickup

  useEffect(() => {
    if (deviceShipped) {
      getShippingHistory({ orderId })
    }
  }, [deviceShipped])

  return (
    <div>
      <div className="mt-3 d-flex">
        {deviceReturned ? (
          <>
            <CheckBoxOutlinedIcon className={'mr-2 text-success'} />
            <h5 className={'font-weight-600'}>Device Returned to TestSmarter</h5>
          </>
        ) : deviceShippedBack ? (
          <>
            <CheckBoxOutlinedIcon className={'mr-2 text-success'} />
            <h5 className={'font-weight-600'}>Device Shipped to TestSmarter</h5>
          </>
        ) : devicePendingReturn ? (
          <>
            <ErrorOutlineIcon className={'mr-2 text-danger'} />
            <h5 className={'font-weight-600'}>
              Device has not yet been {localPickup ? 'Returned' : 'Shipped'} to TestSmarter
            </h5>
          </>
        ) : deviceDelivered ? (
          <>
            <CheckBoxOutlinedIcon className={'mr-2 text-success'} />
            <h5 className={'font-weight-600'}>Device Delivered to Patient</h5>
          </>
        ) : deviceShipped ? (
          <>
            <CheckBoxOutlinedIcon className={'mr-2 text-success'} />
            <h5 className={'font-weight-600'}>Device Shipped to Patient</h5>
          </>
        ) : (
          <>
            <ErrorOutlineIcon className={'mr-2 text-danger'} />
            <h5 className={'font-weight-600'}>Device has not yet been Shipped to Patient</h5>
          </>
        )}
        {(manualShipping || localPickup) && (
          <span
            css={{
              marginLeft: 10,
              fontSize: 18,
              color: '#969696',
            }}>
            {manualShipping ? '(Manual Shipping)' : '(Local Pickup)'}
          </span>
        )}
      </div>
      <div className={'d-flex flex-column'}>
        <span>
          Device Serial #: <span className={'text-bold'}>{order.thirdPartyDevice?.serial}</span>
        </span>
        {!localPickup && (
          <>
            <span className={'mt-2'}>
              Tracking Number:{' '}
              <span className={'text-bold'}>{order.thirdPartyDevice?.trackingNumber || '-'}</span>
              {order.thirdPartyDevice?.trackingNumber && (
                <a
                  href={order.thirdPartyDevice?.trackingUrlProvider}
                  target="_blank"
                  rel="noreferrer"
                  className={'text-blue ml-2'}>
                  Track Online
                </a>
              )}
            </span>
            <span>
              Return Tracking Number:{' '}
              <span className={'text-bold'}>
                {order.thirdPartyDevice?.returnTrackingNumber || '-'}
              </span>
              {order.thirdPartyDevice?.returnTrackingNumber && (
                <a
                  href={order.thirdPartyDevice?.returnTrackingUrlProvider}
                  target="_blank"
                  rel="noreferrer"
                  className={'text-blue ml-2'}>
                  Track Online
                </a>
              )}
            </span>
          </>
        )}
      </div>
      <div className={'d-flex mt-3'}>
        <Button
          variant="primary"
          className={'d-flex align-items-center'}
          disabled={!order.thirdPartyDevice?.trackingNumber || manualShipping}
          onClick={() => {
            const file = Object.values(order.files).find(
              (x) => x.filename === 'shippo-shipping-labels.pdf',
            )

            if (file) {
              window.open(file.url, '_blank')
            }
          }}>
          <PrintIcon className={'text-normal mr-2'} /> Print Shipping Labels
        </Button>
        {/* <Button
          variant={deviceDelivered ? 'success' : 'danger'}
          className="ml-2 d-flex align-items-center"
          disabled={!order.thirdPartyDevice?.trackingNumber || deviceReturned}
          onClick={() =>
            confirm('', {
              title: 'Are you sure?',
              description: deviceDelivered
                ? 'This will mark the device as returned. Proceed?'
                : 'This will cancel the shipment and request a refund. Proceed?',
              yesAction: 'Yes, proceed',
              noAction: 'No, cancel',
            })
              .then(async () => {
                if (deviceDelivered) {
                  await returnThirdPartyDevice({ orderId: Number(orderId) }).unwrap()
                } else {
                  await refundShipment({ orderId: Number(orderId) }).unwrap()
                }
                Toast({
                  type: 'success',
                  label: deviceDelivered ? 'Device marked as returned' : 'Shipping cancelled',
                })
              })
              .catch(() =>
                Toast({
                  type: 'error',
                  label: 'The request encountered a problem. Please try again or contact support.',
                }),
              )
          }>
          {deviceDelivered ? (
            <>
              <KeyboardReturnIcon className={'text-normal mr-2'} /> Return Device
            </>
          ) : (
            <>
              <BlockIcon className={'text-normal mr-2'} /> Cancel Shipment & Request Refund
            </>
          )}
        </Button> */}
        {!deviceDelivered && !manualShipping && (
          <Button
            variant={'danger'}
            className="ml-2 d-flex align-items-center"
            disabled={!order.thirdPartyDevice?.trackingNumber || deviceReturned}
            onClick={() =>
              confirm('', {
                title: 'Are you sure?',
                description: 'This will cancel the shipment and request a refund. Proceed?',
                yesAction: 'Yes, proceed',
                noAction: 'No, cancel',
              })
                .then(async () => {
                  await refundShipment({ orderId: Number(orderId) }).unwrap()
                  Toast({
                    type: 'success',
                    label: 'Shipping cancelled',
                  })
                })
                .catch(() => {})
            }>
            <BlockIcon className={'text-normal mr-2'} /> Cancel Shipment & Request Refund
          </Button>
        )}
        <Button
          variant={'success'}
          className="ml-2 d-flex align-items-center"
          onClick={() =>
            confirm('', {
              title: 'Are you sure?',
              description: 'This will mark the device as returned. Proceed?',
              yesAction: 'Yes, proceed',
              noAction: 'No, cancel',
            })
              .then(async () => {
                await returnThirdPartyDevice({ orderId: Number(orderId) }).unwrap()
                Toast({
                  type: 'success',
                  label: 'Device marked as returned',
                })
              })
              .catch(() => {})
          }>
          <KeyboardReturnIcon className={'text-normal mr-2'} /> Return Device
        </Button>
      </div>
      {!localPickup && !manualShipping && (
        <>
          <h5 className={'mt-4 mb-2'}>Shipping Updates</h5>
          {!!shippingHistory ? (
            <ul className={'pl-0'}>
              {shippingHistory?.tracking_history?.map((item, index) => (
                <li key={index}>
                  <strong css={{ minWidth: 100 }}>
                    {moment(item.status_date).format('MM/DD/YYYY hh:mm a')}
                  </strong>
                  : {item.status_details} ({item?.location?.city}
                  {''}
                  {item?.location?.state ? `, ${item?.location?.state}` : ''})
                </li>
              ))}
            </ul>
          ) : (
            'Tracking information will be displayed below as it becomes available.'
          )}
        </>
      )}
    </div>
  )
}

export default ShippingSection

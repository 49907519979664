/** @jsxImportSource @emotion/react */
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import Button from 'components/atoms/Button'
import { requiredPhoneValidation } from 'helpers/validate'
import { PhysicianForm } from 'components/organisms/physician/PhysicianForm'
import { Physician, PhysicianAddress } from 'store/types'
import { AddressHistory } from './AddressHistory'

const physicianValidation = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  fax: requiredPhoneValidation,
  telephone: requiredPhoneValidation,
  addressStreetLine1: Yup.string().required('Required'),
  addressCity: Yup.string().required('Required'),
  addressState: Yup.string().required('Required'),
  addressZip: Yup.string().required('Required'),
})

interface IProps {
  onCancel: () => void
  onSubmit: (physician: Partial<Physician>, address: PhysicianAddress) => Promise<void>
  physician: Physician
  physicianAddress?: PhysicianAddress
  physicianHistory?: any[]
}

export const AddEditLocation = ({
  onSubmit,
  onCancel,
  physician,
  physicianAddress,
  physicianHistory,
}: IProps) => {
  const initialValues = {
    firstName: physician?.firstName ?? '',
    lastName: physician?.lastName ?? '',
    credential: physician?.credential ?? '',
    fax: physicianAddress?.fax?.replace(/^\+1/, '') ?? '',
    telephone: physicianAddress?.telephone?.replace(/^\+1/, '') ?? '',
    addressStreetLine1: physicianAddress?.addressStreetLine1 ?? '',
    addressStreetLine2: physicianAddress?.addressStreetLine2 ?? '',
    addressCity: physicianAddress?.addressCity ?? '',
    addressState: physicianAddress?.addressState ?? '',
    addressZip: physicianAddress?.addressZip ?? '',
    uuid: physicianAddress?.uuid,
    comment: physicianAddress?.labComment,
  }

  return (
    <div>
      <div className="p-2 border">
        <div className="row rounded justify-content-between m-0">
          <div className="col-1 d-flex align-items-center ml-3">
            <i className="text-success fa fa-user-md" />
          </div>
          <div className="col-2 d-flex align-items-center">{physician.npi}</div>
          <div className="col-4 d-flex align-items-center">
            {physician.firstName} {physician.lastName}
          </div>

          <div className="col-4 pr-0 justify-content-end d-flex align-items-center">
            <Button
              className="btn btn-sm btn-outline-primary"
              label="Cancel"
              type="button"
              onClick={onCancel}
            />
          </div>
        </div>
      </div>
      <Formik
        enableReinitialize={true}
        validationSchema={physicianValidation}
        initialValues={initialValues}
        onSubmit={({ firstName, lastName, credential, ...address }) =>
          onSubmit({ firstName, lastName, credential }, address)
        }>
        {({ isValid, values }) => (
          <Form className="mt-2 border p-2">
            <PhysicianForm includeName />
            <div className="d-flex justify-content-end">
              <Button
                className="mx-1 btn-outline-primary"
                id="btn-ts"
                label="Cancel"
                onClick={onCancel}
              />
              <Button
                className="mx-1 btn-primary"
                id="btn-ts"
                label="Save Changes"
                type="submit"
                disabled={JSON.stringify(initialValues) === JSON.stringify(values) || !isValid}
              />
            </div>
          </Form>
        )}
      </Formik>
      <AddressHistory
        physician={physician}
        physicianHistory={physicianHistory}
        addressUuid={physicianAddress?.uuid}
      />
    </div>
  )
}

import { useEffect } from 'react'

interface IProps {
  isDirty: boolean
  setFormDirty: (d: boolean) => void
}

export const FormikDirtyStateSync = ({ isDirty, setFormDirty }: IProps) => {
  useEffect(() => {
    setFormDirty(isDirty)
  }, [isDirty, setFormDirty])
  return <></>
}

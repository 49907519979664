import { Field, FormikErrors, FormikValues, FormikTouched } from 'formik'
import Link from '../../atoms/Link'
import Button from '../../atoms/Button'
import routes from '../constants/routes'
import {
  polar,
  oxygenLpm,
  papType,
  minPressure,
  maxPressure,
} from './utils/orderFormUtils'

export interface TestConditionsFormValues {
  order: boolean
  select_patient: string
  device_category_id: string
  oxygen: string
  oxygen_lpm: string
  pap: string
  pap_type: string
  apap_min: string
  apap_max: string
  bipap_i: string
  bipap_e: string
  cpap: string
  non_invasive: string
  adaptive_support: string
  oral_appliace: string
}

interface IProps {
  values: FormikValues
  errors: FormikErrors<TestConditionsFormValues>
  touched: FormikTouched<TestConditionsFormValues>
  isOrder: boolean
  order: any
  orderTest: any
  deviceCategories: any[]
  handleDelete: () => void
}

const OrderForm = ({
  values,
  errors,
  touched,
  isOrder,
  order,
  orderTest,
  deviceCategories,
  handleDelete,
}: IProps) => {
  const deviceCategoriesModel =
    deviceCategories &&
    Array.isArray(deviceCategories) &&
    deviceCategories.reduce((rows, key, index) => {
      return (
        (index % 4 == 0 ? rows.push([key]) : rows[rows.length - 1].push(key)) &&
        rows
      )
    }, [])
  const backRoute = isOrder
    ? routes.index
    : `${routes.index}${routes.order.test}${order.id}`

  return (
    <div className="mt-4">
      <div>
        <div className="container p-3">
          <div className="row pb-3 mb-3 border-bottom">
            <div className="row flex-fill">
              <div className="col-9 text-bold">Test Type</div>
              <div className="col-3">
                <span className="text-danger">*</span> denotes a required field
              </div>
            </div>
          </div>
          {deviceCategoriesModel &&
            deviceCategoriesModel.map((row, index) => (
              <div className="row mb-5" key={`row${index}`}>
                {row &&
                  row.map((col, index) =>
                    isOrder || values.device_category_id === `${col.id}` ? (
                      <div className="col-3 form-check" key={`col${index}`}>
                        <Field
                          className="form-check-input"
                          type="radio"
                          disabled={!isOrder}
                          id={col.name}
                          name="device_category_id"
                          value={`${col.id}`}
                        />
                        <label className="form-check-label" htmlFor={col.name}>
                          {col.device_type && col.device_type.name}
                        </label>
                      </div>
                    ) : null
                  )}
                {touched.device_category_id && errors.device_category_id ? (
                  <div className="text-danger">{errors.device_category_id}</div>
                ) : null}
              </div>
            ))}
          <div className="row pb-3 mb-3 border-bottom">
            <div className="row flex-fill">
              <div className="col-12 text-bold">Conditions</div>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-3">
              <label htmlFor="oxygen">{'On Oxygen?'}</label>
              <Field className="form-control" as="select" name="oxygen">
                {polar &&
                  polar.map((p, i) => (
                    <option key={i} value={p.value}>
                      {p.key}
                    </option>
                  ))}
              </Field>
              {touched.oxygen && errors.oxygen ? (
                <div className="text-danger">{errors.oxygen}</div>
              ) : null}
            </div>
            {values.oxygen == 1 ? (
              <div className="col-3">
                <label htmlFor="oxygen_lpm">{'Oxygen LPM'}</label>
                <Field className="form-control" as="select" name="oxygen_lpm">
                  {oxygenLpm &&
                    oxygenLpm.map((ol, i) => (
                      <option key={i} value={ol.value}>
                        {ol.value}
                      </option>
                    ))}
                </Field>
                {touched.oxygen_lpm && errors.oxygen_lpm ? (
                  <div className="text-danger">{errors.oxygen_lpm}</div>
                ) : null}
              </div>
            ) : null}
          </div>
          <div className="row mb-3">
            <div className="col-3">
              <label htmlFor="pap">{'With PAP?'}</label>
              <Field className="form-control" as="select" name="pap">
                {polar &&
                  polar.map((p, i) => (
                    <option key={i} value={p.value}>
                      {p.key}
                    </option>
                  ))}
              </Field>
              {touched.pap && errors.pap ? (
                <div className="text-danger">{errors.pap}</div>
              ) : null}
            </div>
            {values.pap == 1 ? (
              <div className="col-3">
                <label htmlFor="pap_type">{'PAP Type'}</label>
                <Field className="form-control" as="select" name="pap_type">
                  {papType &&
                    papType.map((pt, i) => (
                      <option key={i} value={pt.value}>
                        {pt.value}
                      </option>
                    ))}
                </Field>
                {touched.pap_type && errors.pap_type ? (
                  <div className="text-danger">{errors.pap_type}</div>
                ) : null}
              </div>
            ) : null}
            {values.pap == 1 &&
            values.pap_type !== '' &&
            values.pap_type !== 'Unspecified' &&
            values.pap_type === 'APAP' ? (
              <div className="col-3">
                <label htmlFor="apap_min">{'Min Pressure (cmH2O)'}</label>
                <Field className="form-control" as="select" name="apap_min">
                  {minPressure &&
                    minPressure.map((mp, i) => (
                      <option key={i} value={mp.value}>
                        {mp.value}
                      </option>
                    ))}
                </Field>
                {touched.apap_min && errors.apap_min ? (
                  <div className="text-danger">{errors.apap_min}</div>
                ) : null}
              </div>
            ) : null}
            {values.pap == 1 &&
            values.pap_type !== '' &&
            values.pap_type !== 'Unspecified' &&
            values.pap_type === 'BiPAP' ? (
              <div className="col-3">
                <label htmlFor="bipap_i">{'IPAP Pressure (cmH2O)'}</label>
                <Field className="form-control" as="select" name="bipap_i">
                  {minPressure &&
                    minPressure.map((mp, i) => (
                      <option key={i} value={mp.value}>
                        {mp.value}
                      </option>
                    ))}
                </Field>
                {touched.bipap_i && errors.bipap_i ? (
                  <div className="text-danger">{errors.bipap_i}</div>
                ) : null}
              </div>
            ) : null}
            {values.pap == 1 &&
            values.pap_type !== '' &&
            values.pap_type !== 'Unspecified' &&
            values.pap_type === 'CPAP' ? (
              <div className="col-3">
                <label htmlFor="cpap">{'CPAP Pressure (cmH2O)'}</label>
                <Field className="form-control" as="select" name="cpap">
                  {minPressure &&
                    minPressure.map((mp, i) => (
                      <option key={i} value={mp.value}>
                        {mp.value}
                      </option>
                    ))}
                </Field>
                {touched.cpap && errors.cpap ? (
                  <div className="text-danger">{errors.cpap}</div>
                ) : null}
              </div>
            ) : null}
            {values.pap == 1 &&
            values.pap_type !== '' &&
            values.pap_type !== 'Unspecified' &&
            values.pap_type === 'APAP' ? (
              <div className="col-3">
                <label htmlFor="apap_max">{'Max Pressure (cmH2O)'}</label>
                <Field className="form-control" as="select" name="apap_max">
                  {maxPressure &&
                    maxPressure.map((mp, i) => (
                      <option key={i} value={mp.value}>
                        {mp.value}
                      </option>
                    ))}
                </Field>
                {touched.apap_max && errors.apap_max ? (
                  <div className="text-danger">{errors.apap_max}</div>
                ) : null}
              </div>
            ) : null}
            {values.pap == 1 &&
            values.pap_type !== '' &&
            values.pap_type !== 'Unspecified' &&
            values.pap_type === 'BiPAP' ? (
              <div className="col-3">
                <label htmlFor="bipap_e">{'EPAP Pressure (cmH2O)'}</label>
                <Field className="form-control" as="select" name="bipap_e">
                  {minPressure &&
                    minPressure.map((mp, i) => (
                      <option key={i} value={mp.value}>
                        {mp.value}
                      </option>
                    ))}
                </Field>
                {touched.bipap_e && errors.bipap_e ? (
                  <div className="text-danger">{errors.bipap_e}</div>
                ) : null}
              </div>
            ) : null}
          </div>
          <div className="row">
            <div className="col-12 form-group text-bold">
              Check any that apply:
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <div className="form-check">
                <Field
                  type="checkbox"
                  name="non_invasive"
                  className="form-check-input"
                  id="non_invasive"
                />
                <label className="form-check-label" htmlFor="non_invasive">
                  Non-Invasive Ventilator
                </label>
              </div>
            </div>
            <div className="col-4">
              <div className="form-check">
                <Field
                  type="checkbox"
                  name="adaptive_support"
                  className="form-check-input"
                  id="adaptive_support"
                />
                <label className="form-check-label" htmlFor="adaptive_support">
                  Adaptive Support Ventilation
                </label>
              </div>
            </div>
            <div className="col-4">
              <div className="form-check">
                <Field
                  type="checkbox"
                  name="oral_appliace"
                  className="form-check-input"
                  id="oral_appliace"
                />
                <label className="form-check-label" htmlFor="oral_appliace">
                  Oral appliance
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="m-5">
        <div className="row">
          <div className="col-6 text-left">
            <Link
              className="btn btn-default"
              to={backRoute}
              label="Back"
              icon="fas fa-arrow-left"
            />
          </div>
          <div className="col-6 text-right">
            <Button
              type="submit"
              className="btn"
              id="btn-ts"
              label={orderTest ? 'Save Changes' : 'Add Order'}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrderForm

const OneTrip = () => {
  return (
    <ul className="text-left">
      <li>
        <span>1.</span> Be sure the TestSmarter OneTrip USB cable is connected
        securely to your computer.
      </li>
      <li>
        <span>2.</span> Press the power button on the face of the oximeter until
        it powers on.
      </li>
      <li>
        <span>3.</span> <span>IMPORTANT:</span> Be sure the display reads
        "Finger Out" before you begin download.
      </li>
    </ul>
  )
}

export default OneTrip

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

interface ModalState {
  open?: boolean
  title?: string
  device?: any
  employee?: any
  isActiveDevice?: boolean
  isRetireDevice?: boolean
  content?: number
  patient?: any
  physician?: any
  preValidate?: boolean
}

// ------------------------------------------------------------
export const setModal = createAsyncThunk('setModal', async (status: ModalState) => {
  try {
    return status
  } catch (error) {
    console.log('error', error)
  }
})

// ------------------------------------------------------------
interface ModalSliceState {
  row: ModalState
}

const initialState: ModalSliceState = {
  row: {},
}

// ------------------------------------------------------------
const localSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setModal.fulfilled, (state, action) => {
      state.row = action.payload
    })
  },
})

export default localSlice.reducer

import * as generalHelper from "helpers/generalHelper";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ------------------------------------------------------------
export const getUsers = createAsyncThunk(
  "getUsers",
  async (params, { rejectWithValue }) => {
    try {
      const data = await generalHelper.apiGet("/api/user", params);
      if (!data) {
        return rejectWithValue([]);
      }
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// ------------------------------------------------------------
export const getUser = createAsyncThunk(
  "getUser",
  async (params, { rejectWithValue }) => {
    try {
      params.return_key = "one";
      const data = await generalHelper.apiGet("/api/user", params);
      if (!data) {
        return rejectWithValue([]);
      }
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// ------------------------------------------------------------
export const updateUser = createAsyncThunk(
  "updateUser",
  async ({ id, params }, { rejectWithValue }) => {
    try {
      const data = await generalHelper.apiPut("/api/user", id, params);
      if (!data) {
        return rejectWithValue([]);
      }
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// ------------------------------------------------------------
export const insertUser = createAsyncThunk(
  "insertUser",
  async (params, { rejectWithValue }) => {
    try {
      const data = await generalHelper.apiPost("/api/user", params);
      if (!data) {
        return rejectWithValue([]);
      }
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// ------------------------------------------------------------
export const deleteUser = createAsyncThunk(
  "deleteUser",
  async ({ id, params }, { rejectWithValue }) => {
    try {
      const data = await generalHelper.apiDelete("/api/user", id, params);
      if (!data) {
        return rejectWithValue([]);
      }
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// -------------------------------------------------------------

export const reset = createAsyncThunk(
  "reset",
  async (params, { rejectWithValue }) => {
    return { payload: [] };
  }
);

// ------------------------------------------------------------
const initialState = {
  pending: false,
  rows: [],
  row: {},
  update: {},
  insert: {},
  delete: {},
  rejected: false,
  patientNewHistory: {
    patient: null,
  },
};

// ------------------------------------------------------------
const localSlice = createSlice({
  name: "user",
  initialState,

  extraReducers: {
    [getUsers.rejected]: (state, action) => {
      state.pending = false;
      state.rejected = true;
      state.data = null;
    },
    [getUsers.pending]: (state, action) => {
      state.pending = true;
      state.rejected = false;
      state.update = null;
    },
    [getUsers.fulfilled]: (state, action) => {
      state.pending = false;
      state.rejected = false;
      state.rows = action.payload;
    },
    [getUser.rejected]: (state, action) => {
      state.pending = false;
      state.rejected = true;
      state.data = null;
    },
    [getUser.pending]: (state, action) => {
      state.pending = true;
      state.rejected = false;
      state.update = null;
    },
    [getUser.fulfilled]: (state, action) => {
      state.pending = false;
      state.rejected = false;
      state.row = action.payload;
    },
    [updateUser.rejected]: (state, action) => {
      state.pending = false;
      state.rejected = true;
      state.data = null;
    },
    [updateUser.pending]: (state, action) => {
      state.pending = true;
      state.rejected = false;
      state.update = null;
    },
    [updateUser.fulfilled]: (state, action) => {
      state.pending = false;
      state.rejected = false;
      state.update = action.payload;
    },
    [insertUser.rejected]: (state, action) => {
      state.pending = false;
      state.rejected = true;
      state.data = null;
    },
    [insertUser.pending]: (state, action) => {
      state.pending = true;
      state.rejected = false;
      state.update = null;
    },
    [insertUser.fulfilled]: (state, action) => {
      state.pending = false;
      state.rejected = false;
      state.insert = action.payload;
    },
    [deleteUser.rejected]: (state, action) => {
      state.pending = false;
      state.rejected = true;
      state.data = null;
    },
    [deleteUser.pending]: (state, action) => {
      state.pending = true;
      state.rejected = false;
      state.update = null;
    },
    [deleteUser.fulfilled]: (state, action) => {
      state.pending = false;
      state.rejected = false;
      state.delete = action.payload;
    },
    [reset.rejected]: (state, action) => {
      state.pending = false;
      state.rejected = true;
      state.rows = null;
    },
    [reset.pending]: (state, action) => {
      state.pending = true;
      state.rejected = false;
      state.data = null;
    },
    [reset.fulfilled]: (state, action) => {
      state.pending = false;
      state.rejected = false;
      state.data = [];
    },
  },
});

export default localSlice.reducer;

import { useParams, Link, useNavigate } from 'react-router-dom'
import { skipToken } from '@reduxjs/toolkit/query'
import Moment from 'moment'
import styled from '@emotion/styled'

import { TableWrapper } from 'components/atoms/TableWrapper'
import { useAppSelector } from 'hooks/redux'
import DashboardContent from '../atoms/DashboardContent'
import * as generalHelper from 'helpers/generalHelper'
import routes from '../constants/routes'
import { useGetPatientQuery } from 'store/services/patient'
import PatientUpsert from '../orderEdit/views/forms/PatientUpsert'
import { useGetOrdersQuery } from 'store/services/dmeOrder'
import OrderTableColumns from '../order/OrderTableColumns'
import DataTable from 'components/atoms/DataTable'

const StyledTableWrapper = styled(TableWrapper)`
  th {
    padding: 0 0.2rem 0.4rem 0.1rem;
  }
  td {
    vertical-align: middle;
    padding: 0.2rem;
    div {
      span {
        font-size: 0.9rem;
      }
    }
  }
`

const PatientEdit = () => {
  const { patientId } = useParams()
  const navigate = useNavigate()
  const isFetchingUser = useAppSelector((state) => state.user?.pending)
  const isFetchingOrder = useAppSelector((state) => state.order?.pending)
  const isFetchingDeviceTypes = useAppSelector((state) => state.deviceType?.pending)
  const isFetching = isFetchingOrder && isFetchingDeviceTypes && isFetchingUser
  const { data: patient, isLoading: patientsLoading } = useGetPatientQuery({ id: patientId })
  const { data: orders, isLoading: ordersLoading } = useGetOrdersQuery(
    patient
      ? {
          type: 'all',
          officeId: patient?.officeId,
          patientId,
        }
      : skipToken,
  )

  const subtitle = patient
    ? `${patient?.lastName}${patient?.suffix ? ' ' + patient?.suffix : ''}, ${patient?.firstName} ${
        patient?.middleName ? patient?.middleName : ''
      }`
    : ''
  const subtitleExtra =
    patient && patient.dob
      ? `${generalHelper.t('Date Of Birth')}: ${Moment(patient.dob).format('MM/DD/YYYY')}`
      : null

  const PatientEditView = (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <PatientUpsert patient={patient} />
          <div className="card card-primary mt-3">
            <div className="row p-3">
              <StyledTableWrapper
                className="card-body row"
                style={{
                  overflowY:
                    !orders?.items?.length || orders?.items?.length < 10 ? 'unset' : 'auto',
                }}>
                <DataTable
                  data={orders}
                  status={patientsLoading || ordersLoading}
                  tableColumns={OrderTableColumns([], 'POXCOX')}
                  pageSize={30}
                  setCurrentPage={() => {}}
                  setSortOptions={() => {}}
                  onRowClick={(row) => {
                    navigate(`${routes.index}${routes.order.edit}${row.original.id}/patient`)
                  }}
                />
              </StyledTableWrapper>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  return !isFetching ? (
    <div>
      <DashboardContent
        className="bg-primary"
        title={generalHelper.t('Patient')}
        subtitle={subtitle}
        subtitleExtra={subtitleExtra}
        content={PatientEditView}
        titleActionButton={
          <Link
            className="btn btn-primary text-nowrap"
            to={`${routes.index}${routes.order.newOrder}patient/${patient?.id}/orderDetails`}
            state={{ activeOrders: patient?.orders }}>
            Add Order
          </Link>
        }
        breadcrumb={[
          { label: 'Home', to: `${routes.index}` },
          {
            label: 'Patients',
            to: `${routes.index}${routes.patient.root}`,
          },
          {
            label: patient ? `#${patient.id} - ${patient.lastName} ${patient.firstName}` : '',
            to: '',
          },
        ]}
      />
    </div>
  ) : null
}

export default PatientEdit

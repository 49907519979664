/** @jsxImportSource @emotion/react */

import moment from 'moment'
import { useRef, useState } from 'react'
import Button from 'react-bootstrap/Button'

import { Order } from 'store/types'
import {
  useCompleteHstOrderMutation,
  useScheduleInLabTestingMutation,
  useUploadHstReportMutation,
} from 'store/services/labOrder'
import Upload from 'components/atoms/Upload'
import Toast from 'components/atoms/Toast'
import DateTimePicker from '../../../atoms/DateTimePicker'
import { confirm } from '../../../molecules/Confirmation'
import { TimeWrapper } from './FollowUpForm'

interface IProps {
  order: Order
}

export const PsgPatientScheduling = ({ order }: IProps) => {
  const [uploadReport] = useUploadHstReportMutation()
  const [completeHstOrder] = useCompleteHstOrderMutation()
  const [scheduleInLabTesting] = useScheduleInLabTestingMutation()
  const [dateOfTesting, setDateOfTesting] = useState<string | undefined>(
    order?.inLabTestingScheduledAt,
  )
  const uploadRef = useRef<HTMLInputElement>()

  const handleUpload = async (acceptedFiles: File[]) => {
    const [file] = acceptedFiles
    if (!file?.name) return
    const formData = new FormData()
    formData.append('report', file)
    formData.append('orderId', String(order.id))
    formData.append('reportType', order?.tests?.[0]?.procedure || 'PSG')

    uploadReport({ orderId: Number(order.id), formData }).then(() => {
      Toast({ type: 'success', label: `Report uploaded!` })
    })
  }

  return (
    <div className="w-100 mt-2 pl-3">
      <Upload className="invisible" ref={uploadRef} handleUpload={handleUpload} invisible />
      <div
        className="row"
        css={{
          width: '100%',
        }}>
        <div
          className="p-0 d-flex align-items-center justify-content-between"
          css={{
            width: '100%',
          }}>
          <div
            className="d-flex align-items-end justify-content-between"
            css={{
              width: '100%',
            }}>
            <div className="d-flex align-items-end justify-content-between" css={{ width: '100%' }}>
              <div className="d-flex align-items-end">
                <TimeWrapper css={{ maxWidth: 300 }}>
                  <label className="textGray" htmlFor="effectiveDate">
                    Schedule Date of Testing
                    <span className="text-danger">*</span>
                  </label>
                  <DateTimePicker
                    value={dateOfTesting && moment(dateOfTesting, 'YYYY-MM-DD').toDate()}
                    handleChange={(date) => {
                      setDateOfTesting(moment(date).format('YYYY-MM-DD'))
                    }}
                    disabled={!!order?.inLabTestingScheduledAt}
                    showTimeSelect={false}
                    dateFormat="MMMM d, yyyy"
                  />
                </TimeWrapper>
                {order?.inLabTestingScheduledAt ? (
                  <Button
                    className="ml-2"
                    variant={'outline-danger'}
                    onClick={() => {
                      scheduleInLabTesting({ orderId: Number(order.id), date: undefined })
                      setDateOfTesting(undefined)
                      Toast({ type: 'success', label: 'Testing date removed.' })
                    }}
                    css={{ cursor: 'default', width: 250, height: 32 }}>
                    Remove Testing Date
                  </Button>
                ) : (
                  <Button
                    disabled={!dateOfTesting}
                    className="ml-2"
                    onClick={() => {
                      if (!dateOfTesting) {
                        return
                      }

                      scheduleInLabTesting({ orderId: Number(order.id), date: dateOfTesting })
                      Toast({ type: 'success', label: 'Testing date scheduled!' })
                    }}
                    css={{ cursor: 'default', width: 200, height: 32 }}>
                    Schedule Testing
                  </Button>
                )}
              </div>
              <div>
                <Button
                  className="ml-2"
                  disabled={!order?.inLabTestingScheduledAt}
                  onClick={() => {
                    uploadRef?.current?.click()
                  }}
                  css={{ cursor: 'pointer' }}>
                  Upload Report
                </Button>
                {!!order.reportRenderRequests.length && (
                  <Button
                    variant="primary"
                    className="ml-2 text-nowrap"
                    onClick={async () => {
                      confirm('', {
                        title: 'Are you sure?',
                        description:
                          'This will mark the order as complete and start the fax process.',
                        yesAction: 'Yes, proceed',
                        noAction: 'Cancel',
                      })
                        .then(async () => {
                          try {
                            await completeHstOrder({ orderId: Number(order.id) })
                            Toast({ type: 'success', label: `Order completed` })
                          } catch (err) {
                            Toast({ type: 'error', label: `Error completing order.` })
                          }
                        })
                        .catch(() => {})
                    }}>
                    Complete Order
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

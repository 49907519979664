import { useMemo } from 'react'
import OneTrip from './tsot'
import C50F from './c50f'
import C50I from './c50i'
import WristOx from './n3150'
import Nonin2500 from './n2500'
import Nonin8500 from './n8500'
import Respironics920MPlus from './920mp'
import Respironics920M from './n920m'
import Nonin7500 from './n7500'
import MasimoRAD8 from './rad8'
import { DeviceType } from '../reset'

const types = {
  tsot: OneTrip,
  c50f: C50F,
  c50i: C50I,
  n3150: WristOx,
  n2500: Nonin2500,
  n8500: Nonin8500,
  '920mp': Respironics920MPlus,
  n920m: Respironics920M,
  n7500: Nonin7500,
  rad8: MasimoRAD8,
}

const Empty = () => null

const Instructions = ({ type }: { type?: DeviceType }) => {
  const Component = useMemo(() => (type ? types[type] : Empty), [type])
  return <Component />
}

export default Instructions

/** @jsxImportSource @emotion/react */
import { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import { Field, Form, Formik } from 'formik'
import Toast from 'components/atoms/Toast'
import * as Yup from 'yup'
import Select from 'react-select'
import styled from '@emotion/styled'

import { confirm } from 'components/molecules/Confirmation'
import DashboardContent from '../atoms/DashboardContent'
import states from 'constants/states'
import { PopoverWrapper } from 'components/atoms/PopoverWrapper'
import MaskInput from 'components/atoms/MaskInput'
import { CheckboxInput } from 'components/atoms/Checkbox'
import GoogleAutocomplete from 'components/atoms/GoogleAutocomplete'
import { phoneNumberMask, zipMask } from 'helpers/masks'
import {
  useCreateOfficeMutation,
  useGetCompanyQuery,
  useGetLabOfficeQuery,
  useUpdateOfficeAvailableTestsMutation,
  useUpdateOfficeMutation,
} from 'store/services/office'
import { useGetLabDmeUsersQuery } from 'store/services/users'
import DataTable from 'components/atoms/DataTable'
import { getAddressFields2 } from 'components/dme/forms/utils/userFormUtils'
import * as generalHelper from 'helpers/generalHelper'
import routes from '../constants/routes'
import { skipToken } from '@reduxjs/toolkit/query/react'
import { TableWrapper } from 'components/atoms/TableWrapper'
import { phoneValidation, requiredPhoneValidation } from 'helpers/validate'
import { useImpersonation } from 'hooks/useImpersonation'

const PAGE_SIZE = 10

const StyledTableWrapper = styled(TableWrapper)`
  th {
    padding: 0 0.2rem 0.4rem 0.1rem;
  }
  td {
    vertical-align: middle;
    padding: 0.2rem;
    div {
      span {
        font-size: 0.9rem;
      }
    }
  }
`
const CellWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 40px;
  justify-content: center;
  > div {
    white-space: nowrap;
    display: flex;
  }
`

const officeValidation = Yup.object().shape({
  officeName: Yup.string().required('Required'),
  telephone: requiredPhoneValidation,
  notificationsNumber: phoneValidation,
  notificationsEmail: Yup.string().email('Invalid Email'),
  fax: requiredPhoneValidation,
  npi: Yup.string().test(
    'len',
    'Must be exactly 10 characters',
    (val?: string) => !val || val?.length === 10,
  ),
  region: Yup.string(),
  officeNumber: Yup.string(),
  addressStreetLine1: Yup.string().required('Required'),
  addressCity: Yup.string().required('Required'),
  addressState: Yup.string().required('Required'),
  addressZip: Yup.string().required('Required'),
})

interface FormValues {
  officeName: string
  telephone: string
  fax: string
  npi: string
  region: string
  officeNumber: string
  addressStreetLine1: string
  addressStreetLine2: string
  notificationsEmail: string
  notificationsNumber: string
  addressCity: string
  addressState: string
  addressZip: string
  address: string
  btBranchId?: { siteNickname: string; branchId: string }[]
}

const initialValues: FormValues = {
  officeName: '',
  telephone: '',
  fax: '',
  npi: '',
  region: '',
  officeNumber: '',
  addressStreetLine1: '',
  addressStreetLine2: '',
  notificationsEmail: '',
  notificationsNumber: '',
  addressCity: '',
  addressState: '',
  addressZip: '',
  address: '',
  btBranchId: [],
}

const OfficeUpsert = () => {
  const navigate = useNavigate()
  const { officeId } = useParams()
  const [searchParams] = useSearchParams()
  const { impersonate } = useImpersonation()
  const [currentPage, setCurrentPage] = useState(0)
  const [availableTests, setAvailableTests] = useState({ COX: false, POX: false, HST: false, PSG: false })
  const [createOffice] = useCreateOfficeMutation()
  const [updateOffice] = useUpdateOfficeMutation()
  const [updateOfficeAvailableTests] = useUpdateOfficeAvailableTestsMutation()
  const { data: officeData } = useGetLabOfficeQuery(officeId ? { id: Number(officeId) } : skipToken)
  const companyId = searchParams?.get('companyId') || officeData?.companyId
  const { data: companyData } = useGetCompanyQuery(companyId ? { id: companyId } : skipToken)
  const { data: usersData, isLoading } = useGetLabDmeUsersQuery(
    officeId
      ? {
          officeId,
          size: PAGE_SIZE,
          page: currentPage,
        }
      : skipToken,
  )

  console.log(`!!! OFFICE ID: ${officeId}`);

  const companyName = companyData?.companyName

  useEffect(() => {
    if (officeData?.availableTests) {
      setAvailableTests({
        POX: officeData?.availableTests?.POX?.available,
        COX: officeData?.availableTests?.COX?.available,
        HST: officeData?.availableTests?.HST?.available,
        PSG: officeData?.availableTests?.PSG?.available,
      })
    }
  }, [officeData])

  const init = useMemo(
    () =>
      officeData && {
        officeName: officeData?.officeName,
        telephone: officeData?.telephone,
        fax: officeData?.fax,
        npi: officeData?.npi,
        officeNumber: officeData?.officeNumber,
        address: officeData?.addressStreetLine1,
        addressStreetLine1: officeData?.addressStreetLine1,
        addressStreetLine2: officeData?.addressStreetLine2,
        addressCity: officeData?.addressCity,
        addressState: officeData?.addressState,
        addressZip: officeData?.addressZip,
        region: officeData?.region?.uuid,
        btBranchId: officeData?.btBranchId,
        oneShipSupported: officeData?.oneShipSupported,
        notificationsReroute: officeData?.notificationsReroute,
        notificationsEmail: officeData?.notificationsEmail,
        notificationsNumber: officeData?.notificationsNumber,
        rxRecognitionEnabled: officeData?.rxRecognitionEnabled,
        rxVerificationEnabled: officeData?.rxVerificationEnabled,
      },
    [officeData],
  )

  const regions = useMemo(
    () =>
      companyData?.regions?.map((region) => ({
        label: region.regionName,
        value: region.uuid,
      })),
    [companyData],
  )

  const employeesHeader = [
    {
      Header: 'ID',
      accessor: 'userId',
      Cell: ({ row }) => row.original.id,
    },
    {
      Header: 'Name',
      accessor: 'userName',
      columnSize: 3,
      Cell: ({ row }) => (
        <>
          <Link to={`${routes.index}${routes.dmeUsers.root}${routes.upsert}${row.original.id}`}>
            {`${_.capitalize(row.original.lastName)}${
              row.original.suffix ? ' ' + row.original.suffix : ''
            }, ${_.capitalize(row.original.firstName)} ${
              row.original.middleName ? _.capitalize(row.original.middleName) : ''
            }`}
          </Link>
        </>
      ),
    },
    {
      Header: 'Role',
      accessor: 'role',
      columnSize: 3,
      Cell: ({ row }) => (
        <CellWrapper>
          <div>{row.original.roles?.[0]?.replace('_', ' ')}</div>
        </CellWrapper>
      ),
    },
    {
      Header: 'Admin',
      accessor: 'isAdmin',
      columnSize: 3,
      Cell: ({ row }) => (
        <CellWrapper>
          <div css={{ margin: 'auto' }}>
            {row.original.adminOf ? <i className="fas fa-check-circle" /> : null}
          </div>
        </CellWrapper>
      ),
    },
  ]

  return (
    <div>
      <DashboardContent
        icon="fas fa-building"
        title={officeId ? 'Edit Office' : 'Create Office'}
        subtitle={
          <div>
            <Link to={`${routes.index}${routes.company.root}${routes.upsert}${companyId}`}>
              {`< Back to ${companyName}`}
            </Link>
            {officeId && (
              <PopoverWrapper
                placement="left"
                customContent={<div className="p-2">Impersonate this DME office</div>}>
                <Button
                  variant="primary"
                  className="ml-2 float-right"
                  css={{ minWidth: '40px' }}
                  size="sm"
                  onClick={async (e) => {
                    e.stopPropagation()
                    impersonate(Number(officeId))
                  }}>
                  <i className="fas fa-external-link-alt mr-2" />
                  Impersonate
                </Button>
              </PopoverWrapper>
            )}
          </div>
        }
        breadcrumb={[
          {
            label: 'Companies',
            to: `${routes.index}${routes.company.root}`,
          },
          {
            label: companyName,
            to: `${routes.index}${routes.company.root}${routes.upsert}/1`,
          },
          { label: officeId ? 'Edit' : 'New' },
        ]}
        content={
          <>
            <div className="card-body row">
              <div className="col-md-12 col-lg-6 mb-3">
                <div className="card card-primary p-2">
                  <div
                    className="card-header"
                    css={{
                      color: 'black !important',
                      backgroundColor: 'transparent !important',
                    }}>
                    <h3 className="card-title">OFFICE INFORMATION</h3>
                  </div>
                  <Formik
                    enableReinitialize={true}
                    validationSchema={officeValidation}
                    initialValues={init ?? initialValues}
                    onSubmit={async (values) => {
                      try {
                        const { address, nickname, branchId, ...vals } = values
                        if (!vals.region) delete vals.region
                        vals.availableTests = availableTests
                        if (vals.btBranchId?.length) {
                          vals.availableTests.POX = true
                          vals.availableTests.COX = true
                        }
                        if (officeId) {
                          await updateOffice({
                            id: officeId,
                            ...vals,
                          }).unwrap()
                          Toast({ type: 'success', label: `Office updated!` })
                        } else {
                          const newOffice = await createOffice({
                            companyId: Number(companyId),
                            ...vals,
                          }).unwrap()
                          navigate(
                            `${routes.index}${routes.office.root}${routes.upsert}${newOffice.id}`,
                          )
                          Toast({ type: 'success', label: `Office created!` })
                        }
                      } catch (err) {
                        Toast({ type: 'error', label: err.data.message })
                      }
                    }}>
                    {({ values, errors, touched, handleChange, setFieldValue }) => (
                      <Form id="officeUpsert" autoComplete="one-time-code">
                        <div className={`container bg-white mb-3 px-1 p-0`}>
                          <div className={`container pt-3 pb-1`}>
                            <div className="row">
                              <div className="col-4 form-group textGray">
                                <label htmlFor="officeName">
                                  Office Name
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  name="officeName"
                                  className="form-control"
                                  placeholder="Office Name"
                                  value={values.officeName}
                                  onChange={handleChange}
                                />
                                {touched.officeName && errors.officeName && (
                                  <div className="text-danger">{errors.officeName}</div>
                                )}
                              </div>
                              <div className="col-4 form-group textGray">
                                <label htmlFor="telephone">
                                  Telephone <span className="text-danger">*</span>
                                </label>
                                <MaskInput
                                  value={values.telephone}
                                  onChange={handleChange}
                                  name="telephone"
                                  mask={phoneNumberMask}
                                  placeholder="(###) ###-####"
                                  autoComplete="off"
                                />
                                {touched.telephone && errors.telephone && (
                                  <div className="text-danger">{errors.telephone}</div>
                                )}
                              </div>
                              <div className="col-4 form-group textGray">
                                <label htmlFor="lastName">
                                  Fax
                                  <span className="text-danger">*</span>
                                </label>
                                <MaskInput
                                  value={values.fax}
                                  onChange={handleChange}
                                  name="fax"
                                  mask={phoneNumberMask}
                                  placeholder="(###) ###-####"
                                  autoComplete="off"
                                />
                                {touched.fax && errors.fax && (
                                  <div className="text-danger">{errors.fax}</div>
                                )}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-4 form-group textGray">
                                <label htmlFor="npi">NPI</label>
                                <Field
                                  type="text"
                                  name="npi"
                                  maxlength="10"
                                  className="form-control"
                                  placeholder="NPI"
                                  value={values.npi}
                                  onChange={handleChange}
                                />
                                {touched.npi && errors.npi && (
                                  <div className="text-danger">{errors.npi}</div>
                                )}
                              </div>
                              <div className="col-4 form-group textGray">
                                <label htmlFor="region">Region</label>
                                <Select
                                  className="basic-single w-100 mr-3 mb-3"
                                  css={{
                                    '& .select__control': {
                                      height: '34px !important',
                                      minHeight: 'unset',
                                    },
                                  }}
                                  classNamePrefix="select"
                                  isClearable
                                  isSearchable
                                  placeholder="Select Region"
                                  name="region"
                                  autoComplete="region"
                                  onChange={(item) => setFieldValue('region', item.value)}
                                  options={regions}
                                  value={regions?.find((i) => i.value === values.region) ?? null}
                                />
                              </div>
                            </div>
                          </div>

                          <div
                            className="row mx-2 mb-2"
                            css={{
                              border: '1px solid #ccc',
                              borderRadius: '5px',
                              padding: '5px 10px',
                            }}>
                            <div className="col-6 form-group textGray">
                              <label htmlFor="npi">Brightree Branch Info</label>
                              <div className="d-flex align-items-center">
                                <Field
                                  as="select"
                                  name="nickname"
                                  className="form-control mr-2"
                                  placeholder="Site Nickname"
                                  value={values.nickname}
                                  onChange={handleChange}>
                                  <option value="" disabled selected>
                                    Nickname
                                  </option>
                                  {companyData?.btSiteNickname?.map((n) => (
                                    <option key={n} value={n}>
                                      {n}
                                    </option>
                                  ))}
                                </Field>

                                <Field
                                  type="text"
                                  name="branchId"
                                  className="form-control"
                                  placeholder="Branch ID"
                                  value={values.branchId}
                                  onChange={handleChange}
                                />
                                <i
                                  onClick={() => {
                                    if (values.nickname) {
                                      setFieldValue('btBranchId', [
                                        {
                                          siteNickname: values.nickname,
                                          branchId: values.branchId,
                                        },
                                        ...(values.btBranchId || []),
                                      ])
                                      setFieldValue('nickname', '')
                                      setFieldValue('branchId', '')
                                      setAvailableTests((p) => ({
                                        ...p,
                                        POX: true,
                                        COX: true,
                                      }))
                                    }
                                  }}
                                  className="fa fa-plus-circle ml-2"
                                  aria-hidden="true"
                                  css={{
                                    cursor: 'pointer',
                                    color: values.nickname && values.branchId ? '#2c83bd' : '#ccc',
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-6" css={{ overflowY: 'auto', maxHeight: 150 }}>
                              {!!values.btBranchId?.length && (
                                <table
                                  className="table"
                                  css={{ '& td, th': { padding: '0.25rem' } }}>
                                  <thead>
                                    <tr>
                                      <th>Site Nickname</th>
                                      <th>Branch ID</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {values.btBranchId.map((n) => (
                                      <tr key={n.branchId}>
                                        <td>{n.siteNickname}</td>
                                        <td>{n.branchId}</td>
                                        <td>
                                          <i
                                            onClick={() =>
                                              confirm('brightree branch')
                                                .then(() =>
                                                  setFieldValue(
                                                    'btBranchId',
                                                    values.btBranchId?.filter(
                                                      (b) => b.branchId !== n.branchId,
                                                    ),
                                                  ),
                                                )
                                                .catch(() => {})
                                            }
                                            className="fa fa-times-circle ml-2"
                                            aria-hidden="true"
                                            css={{ cursor: 'pointer', color: '#2c83bd' }}
                                          />
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              )}
                            </div>
                          </div>

                          <div className={`container py-2 px-3`}>
                            <div className="row">
                              <div className="col-12">
                                <div className="row">
                                  <div className="col-12 form-group textGray">
                                    <label htmlFor="addressStreetLine1">
                                      {generalHelper.t('Primary Address')}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <GoogleAutocomplete
                                      id="address"
                                      name="address"
                                      onInputChanged={(v) => setFieldValue('addressStreetLine1', v)}
                                      onChange={(place) => {
                                        const { address, city, state, zip } =
                                          getAddressFields2(place)
                                        console.log(address, city, state, zip)
                                        document.getElementById('address').value = address
                                        setFieldValue('addressStreetLine1', address)
                                        setFieldValue('addressCity', city)
                                        setFieldValue('addressState', state)
                                        setFieldValue('addressZip', zip)
                                      }}
                                    />
                                    {touched.addressStreetLine1 && errors.addressStreetLine1 ? (
                                      <div className="text-danger">{errors.addressStreetLine1}</div>
                                    ) : null}
                                  </div>
                                  <div className="col-12 form-group textGray">
                                    <Field
                                      type="text"
                                      name="addressStreetLine2"
                                      className="form-control"
                                      placeholder="Suite / Apt"
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-4 form-group textGray">
                                    <label htmlFor="addressCity">
                                      City
                                      <span className="text-danger">*</span>
                                    </label>
                                    <Field
                                      type="text"
                                      name="addressCity"
                                      className="form-control"
                                      placeholder="City"
                                    />
                                    {touched.addressCity && errors.addressCity ? (
                                      <div className="text-danger">{errors.addressCity}</div>
                                    ) : null}
                                  </div>
                                  <div className="col-4 form-group textGray">
                                    <label htmlFor="addressState">
                                      State
                                      <span className="text-danger">*</span>
                                    </label>
                                    <Field
                                      className="form-control"
                                      as="select"
                                      name="addressState"
                                      placeholder="State">
                                      {states &&
                                        states.map((s, i) => (
                                          <option key={i} value={s.id}>
                                            {s.name}
                                          </option>
                                        ))}
                                    </Field>
                                    {touched.addressState && errors.addressState ? (
                                      <div className="text-danger">{errors.addressState}</div>
                                    ) : null}
                                  </div>
                                  <div className="col-4 form-group textGray">
                                    <label htmlFor="addressZip">
                                      Zip Code
                                      <span className="text-danger">*</span>
                                    </label>
                                    <MaskInput
                                      value={values.addressZip}
                                      onChange={handleChange}
                                      name="addressZip"
                                      mask={zipMask}
                                      placeholder="#####-####"
                                    />

                                    {touched.addressZip && errors.addressZip && (
                                      <div className="text-danger">{errors.addressZip}</div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-12 py-3 pr-0 border-top">
                            <Field
                              id="oneShipSupported"
                              name="oneShipSupported"
                              component={CheckboxInput}
                              label="OneShip Supported"
                            />
                          </div>
                          <div className="col-sm-12 py-3 pr-0 border-top">
                            <Field
                              id="rxRecognitionEnabled"
                              name="rxRecognitionEnabled"
                              component={CheckboxInput}
                              label="Enable Rx Recognition"
                            />
                            <Field
                              id="rxVerificationEnabled"
                              name="rxVerificationEnabled"
                              component={CheckboxInput}
                              label="Enable Rx Verification"
                            />
                          </div>
                          <div className="col-sm-12 py-3 pr-0 border-top">
                            <Field
                              id="notificationsReroute"
                              name="notificationsReroute"
                              component={CheckboxInput}
                              label="Re-route notifications"
                            />
                          </div>
                          {values.notificationsReroute && (
                            <div className="row">
                              <div className="col-5 form-group textGray">
                                <label htmlFor="notificationsEmail">Notifications Email</label>
                                <Field
                                  type="text"
                                  name="notificationsEmail"
                                  className="form-control"
                                  placeholder="abc@abc.com"
                                  value={values.notificationsEmail}
                                  onChange={handleChange}
                                />
                                {touched.notificationsEmail && errors.notificationsEmail && (
                                  <div className="text-danger">{errors.notificationsEmail}</div>
                                )}
                              </div>
                              <div className="col-5 form-group textGray">
                                <label htmlFor="notificationsNumber">Notifications Number</label>
                                <MaskInput
                                  value={values.notificationsNumber}
                                  onChange={handleChange}
                                  name="notificationsNumber"
                                  mask={phoneNumberMask}
                                  placeholder="(###) ###-####"
                                  autoComplete="off"
                                />
                                {touched.notificationsNumber && errors.notificationsNumber && (
                                  <div className="text-danger">{errors.notificationsNumber}</div>
                                )}
                              </div>
                            </div>
                          )}
                          <div className="col-sm-12 pt-3 pr-0 border-top">
                            <div className="col-sm-12 d-flex justify-content-end">
                              <Button variant="primary" type="submit">
                                Save Changes
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
              <div className="col-md-12 col-lg-6 mb-3">
                {officeId && (
                  <div className="card card-primary p-2">
                    <div
                      className="card-header d-flex"
                      css={{
                        color: 'black !important',
                        backgroundColor: 'transparent !important',
                        justifyContent: 'space-between',
                        '&::after': {
                          display: 'none',
                        },
                      }}>
                      <h3 className="card-title">EMPLOYEES</h3>
                      <Button
                        variant="primary"
                        onClick={() =>
                          navigate(
                            `${routes.index}${routes.dmeUsers.root}${routes.upsert}?companyId=${officeData?.companyId}&officeId=${officeId}`,
                          )
                        }
                        size="sm">
                        Add User
                      </Button>
                    </div>
                    <StyledTableWrapper
                      className="card-body row mt-3"
                      style={{
                        overflowY:
                          !usersData?.items?.length || usersData?.items?.length < 10
                            ? 'unset'
                            : 'auto',
                      }}>
                      <DataTable
                        data={usersData}
                        status={isLoading}
                        tableColumns={employeesHeader}
                        pageSize={PAGE_SIZE}
                        setCurrentPage={setCurrentPage}
                        setSortOptions={() => {}}
                        entityName="employees"
                        onRowClick={(row) =>
                          navigate(
                            `${routes.index}${routes.dmeUsers.root}${routes.upsert}${row.original.id}`,
                          )
                        }
                      />
                    </StyledTableWrapper>
                  </div>
                )}
              </div>
            </div>
            <div className="card-body row pt-0">
              <div className="col-md-12 col-lg-6 mb-3">
                <div className="card card-primary p-2">
                  <div
                    className="card-header"
                    css={{
                      color: 'black !important',
                      backgroundColor: 'transparent !important',
                    }}>
                    <h3 className="card-title">AVAILABLE TESTS</h3>
                  </div>
                  <div className="row mt-3">
                    <div className="col-3">
                      <div className="col-12 form-check pb-3 ml-2">
                        <input
                          type="checkbox"
                          value="POX"
                          name="payer2"
                          className="form-check-input"
                          checked={availableTests.POX}
                          onChange={(event) =>
                            setAvailableTests({ ...availableTests, POX: event.target.checked })
                          }
                          id="pox-checkbox"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="payer2-checkbox"
                          css={{ paddingTop: '2px' }}>
                          Pulse Oximetry (POX)
                        </label>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="col-12 form-check pb-3 ml-2">
                        <input
                          type="checkbox"
                          value="COX"
                          name="payer2"
                          className="form-check-input"
                          checked={availableTests.COX}
                          onChange={(event) =>
                            setAvailableTests({ ...availableTests, COX: event.target.checked })
                          }
                          id="pox-checkbox"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="payer2-checkbox"
                          css={{ paddingTop: '2px' }}>
                          Capnography (CAPOX)
                        </label>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="col-12 form-check pb-3 ml-2">
                        <input
                          type="checkbox"
                          value="HST"
                          name="payer2"
                          className="form-check-input"
                          checked={availableTests.HST}
                          onChange={(event) =>
                            setAvailableTests({ ...availableTests, HST: event.target.checked })
                          }
                          id="pox-checkbox"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="payer2-checkbox"
                          css={{ paddingTop: '2px' }}>
                          Home Sleep Testing (HST)
                        </label>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="col-12 form-check pb-3 ml-2">
                        <input
                          type="checkbox"
                          value="PSG"
                          name="payer2"
                          className="form-check-input"
                          checked={availableTests.PSG}
                          onChange={(event) =>
                            setAvailableTests({ ...availableTests, PSG: event.target.checked })
                          }
                          id="pox-checkbox"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="payer2-checkbox"
                          css={{ paddingTop: '2px' }}>
                          In-Lab Sleep Testing (PSG)
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-end p-3">
                    {!!officeData?.btBranchId?.length && (
                      <span css={{ position: 'absolute', left: 10, bottom: 20, opacity: 0.7 }}>
                        Brightree sites have to have POX and CAPOX enabled
                      </span>
                    )}
                    <Button
                      variant="primary"
                      onClick={async () => {
                        try {
                          await updateOfficeAvailableTests({
                            id: officeId,
                            availableTests,
                          }).unwrap()
                          Toast({ type: 'success', label: `Office updated!` })
                        } catch (err) {
                          Toast({ type: 'error', label: err.data.message })
                        }
                      }}>
                      Save Changes
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </>
        }
      />
    </div>
  )
}

export default OfficeUpsert

/** @jsxImportSource @emotion/react */
import { useState, useEffect, useMemo } from 'react'
import styled from '@emotion/styled'
import moment from 'moment'
import { Document, Page, pdfjs } from 'react-pdf'
import Dropdown from 'react-bootstrap/Dropdown'

import { Order, UploadType } from 'store/types'
import { VerifyStatusButton } from './VerifyStatusButton'
import Button from 'components/atoms/Button'
import Link from 'components/atoms/Link'
import { TooltipWrapper } from './TooltipWrapper'
import { VerifyType } from './OrderEdit'
import { useChangeLabFileCategoryMutation } from 'store/services/labOrder'
import { confirm } from 'components/molecules/Confirmation'
import useWindowSize from 'hooks/useWindowSize'

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`

const StyledDropdownItem = styled(Dropdown.Item)`
  padding: 0.55rem 1rem 0.55rem 1.7rem;
`

const FilenameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  padding-left: 1rem;
`

const ListGroupItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 1rem;
  padding-right: 0.5rem;
`

const DocumentWrapper = styled.div`
  background-color: #ccc;
  margin-right: 1rem;
  max-height: 65vh;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  padding: 1rem;
  transition: all 0.5s ease;
`

const DocImage = styled.img`
  width: 100%;
`

interface IProps {
  expanded: boolean
  order: Order
  uploadType: UploadType
  className?: string
  verification?: String
  setVerification: (v: string) => void
  removeFile: (fileKey: string) => void
  orderState?: any
  showVerifyButtons?: boolean
  rxUploaded: boolean
  aobUploaded: boolean
}

const verifyableDocuments = (testType: string) =>
  testType === 'HST' || testType === 'PSG' ? ['RX', 'AOB', 'OFFICE_NOTES', 'PAPERWORK'] : ['RX', 'AOB']

export const DocumentPanel = ({
  order,
  uploadType,
  className,
  verification,
  setVerification,
  removeFile,
  orderState,
  showVerifyButtons,
  rxUploaded,
  aobUploaded,
}: IProps) => {
  const files = useMemo(
    () => Object.values(order.files).filter((f) => f.uploadType === uploadType),
    [],
  )
  const { height } = useWindowSize()
  const [numPages, setNumPages] = useState<number | null>(null)
  const [expanded, setExpanded] = useState(files.map((f) => verification !== 'verified'))
  const [zoom, setZoom] = useState<{ [idx: string]: number }>({})
  const [changeFileCategory] = useChangeLabFileCategoryMutation()
  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages)
  }

  const diagnosisCodesEntered = !!order?.diagnosis?.length
  const verifyableDocument = verifyableDocuments(order?.testType)?.includes(uploadType)

  useEffect(() => {
    if (verification === 'verified') {
      setExpanded(files.map((f) => verification !== 'verified'))
    }
  }, [verification])

  const changeFileType = (category: string, fileKey: string) => () => {
    changeFileCategory({ orderId: Number(order.id), fileKey, toUploadType: category })
  }

  return files.map((file, idx) => {
    const ext = file?.filename.split('.').pop() ?? ''
    const docUrl = file?.url
    const isExpanded = expanded[idx]

    return (
      <div
        className={className}
        css={{ cursor: 'pointer' }}
        onClick={(e) => {
          if (['fas fa-ellipsis-v', 'btn', 'dropdown-item'].includes(e.target.className)) {
            return false
          }
          setExpanded((p) => [...p.slice(0, idx), !p[idx], ...p.slice(idx + 1)])
        }}>
        <div className="row" css={{ position: 'relative' }}>
          {isExpanded && (
            <div
              css={{
                position: 'absolute',
                top: 58,
                right: 40,
                zIndex: 1,
                background: 'white',
                opacity: 0.8,
                padding: 5,
                borderRadius: 10,
              }}>
              <i
                className="fa fa-search-minus"
                css={{
                  color: '#2c83bd',
                  cursor: 'pointer',
                  fontSize: 30,
                  marginRight: 10,
                }}
                onClick={(e) => {
                  e.stopPropagation()
                  if (zoom[idx] > 1) {
                    setZoom((p) => ({ ...p, [idx]: p[idx] - 0.1 }))
                  }
                }}
              />
              <i
                className="fa fa-search-plus"
                css={{
                  color: '#2c83bd',
                  cursor: 'pointer',
                  fontSize: 30,
                }}
                onClick={(e) => {
                  e.stopPropagation()
                  if (!zoom[idx] || zoom[idx] <= 3) {
                    setZoom((p) => ({ ...p, [idx]: (p[idx] || 1) + 0.1 }))
                  }
                }}
              />
            </div>
          )}
          <div className="col-sm-12">
            <div className="list-group text-secondary">
              <TooltipWrapper orderState={orderState}>
                <ListGroupItem className="row list-group-item list-group-item-action">
                  <i className={isExpanded ? 'fas fa-chevron-down' : 'fas fa-chevron-up'} />
                  <div className="col-sm-2 text-gray">
                    {uploadType?.replace(/_/, ' ')}
                    {uploadType === 'RX' && order.officeNotesInRx && (
                      <span className="ml-1">/ Office Notes</span>
                    )}
                  </div>
                  <i className={`fa fa-fw fa-download`} style={{ color: '#4A89B3' }}></i>

                  <FilenameWrapper>
                    <Link to="#">{file?.filename}</Link>
                    <span style={{ fontSize: '0.8rem', opacity: 0.5, marginTop: 5 }}>
                      {moment(file?.uploadDate).format('lll')}
                    </span>
                  </FilenameWrapper>
                  {showVerifyButtons && verifyableDocument && (
                    <VerifyStatusButton
                      disabled={!orderState?.isUnderVerification}
                      value={verification}
                      onChange={(type: VerifyType) => {
                        if (type === 'verified' && !diagnosisCodesEntered && uploadType === 'RX') {
                          try {
                            confirm('', {
                              title: 'Missing Diagnosis Codes',
                              description:
                                'RX document cannot be verified until diagnosis codes have been entered for the order.',
                              yesAction: `OK`,
                              noAction: null,
                            })
                          } catch {}
                        } else {
                          setVerification(type)
                        }
                      }}
                    />
                  )}

                  <div className="dropdown ml-2">
                    <button
                      className="btn"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false">
                      <i className="fas fa-ellipsis-v"></i>
                    </button>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <a className="dropdown-item" href={docUrl} target="_blank">
                        Download
                      </a>
                      {verification !== 'verified' && (
                        <>
                          <Dropdown.Header css={{ textAlign: 'start' }}>
                            Change Type:
                          </Dropdown.Header>
                          <StyledDropdownItem
                            disabled={rxUploaded}
                            onClick={changeFileType('RX', file.key)}>
                            RX {rxUploaded && <span className="text-muted">(Uploaded)</span>}
                          </StyledDropdownItem>
                          {order?.testType !== 'HST' && (
                            <StyledDropdownItem
                              disabled={aobUploaded}
                              onClick={changeFileType('AOB', file.key)}>
                              AOB {aobUploaded && <span className="text-muted">(Uploaded)</span>}{' '}
                            </StyledDropdownItem>
                          )}
                          <StyledDropdownItem onClick={changeFileType('BILLING_DOC', file.key)}>
                            Billing Doc
                          </StyledDropdownItem>
                          <StyledDropdownItem onClick={changeFileType('OTHER', file.key)}>
                            Other
                          </StyledDropdownItem>
                          <Dropdown.Divider />
                          <Button
                            label="Permanently Delete"
                            className="dropdown-item"
                            href="#"
                            onClick={() => removeFile(file?.key)}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </ListGroupItem>
              </TooltipWrapper>
            </div>
          </div>
        </div>
        <DocumentWrapper
          css={{ cursor: 'default', paddingLeft: zoom[idx] ? (zoom[idx] - 1) * 1000 : 0 }}
          {...(!isExpanded && { css: { visibility: 'hidden', height: 0 } })}
          onClick={(e) => e.stopPropagation()}>
          {['png', 'jpg', 'jpeg', 'webp', 'gif'].includes(ext?.toLowerCase()) && (
            <DocImage src={file?.url} />
          )}
          {ext?.toLowerCase() === 'pdf' && (
            <Document
              file={docUrl}
              onLoadSuccess={onDocumentLoadSuccess}
              externalLinkTarget="_blank">
              {Array.from({ length: numPages ?? 0 }, (v, i) => i + 1).map((page) => (
                <Page
                  pageNumber={page}
                  scale={zoom[idx] ?? 1}
                  key={page}
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                  height={Math.max(height * 0.65, 700)}
                />
              ))}
            </Document>
          )}
        </DocumentWrapper>
      </div>
    )
  })
}

/** @jsxImportSource @emotion/react */
import { useMemo } from 'react'
import { capitalize } from 'lodash'
import { useParams, Link, useNavigate } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import { Formik, Form, Field } from 'formik'
import Toast from 'components/atoms/Toast'
import * as Yup from 'yup'
import { v4 as uuidv4 } from 'uuid'

import { useAppSelector } from 'hooks/redux'
import SwitchButton from 'components/atoms/SwitchButton'
import { confirm } from 'components/molecules/Confirmation'
import MaskInput from 'components/atoms/MaskInput'
import { phoneNumberMask } from 'helpers/masks'
import Checkbox from 'components/atoms/Checkbox'
import {
  useGetLabUserQuery,
  useCreateLabUserMutation,
  useUpdateLabUserMutation,
  useDeleteUserMutation,
  useSendResetPasswordEmailMutation,
} from 'store/services/users'
import { UserType } from 'store/types'
import DashboardContent from '../atoms/DashboardContent'
import { permissions } from './permissions'
import routes from '../constants/routes'
import { skipToken } from '@reduxjs/toolkit/query/react'
import { phoneValidation } from 'helpers/validate'

const userValidation = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  officeNumber: phoneValidation,
  directNumber: phoneValidation,
  email: Yup.string().required('Required').email('Invalid email'),
  role: Yup.string().required('Required'),
})

interface FormValues {
  firstName: string
  lastName: string
  officeNumber: string
  directNumber: string
  email: string
  role: string
}

const initialValues = {
  firstName: '',
  lastName: '',
  officeNumber: '',
  directNumber: '',
  email: '',
  role: '',
  active: true,
}

const roles = ['LAB_ADMIN', 'BILLING_USER', 'BILLING_ADMIN', 'OUTSOURCED_USER']

const UserUpsert = () => {
  const navigate = useNavigate()
  const { userId } = useParams()
  const [createLabUser] = useCreateLabUserMutation()
  const [updateLabUser] = useUpdateLabUserMutation()
  const [deleteUser] = useDeleteUserMutation()
  const [sendResetPasswordEmail] = useSendResetPasswordEmailMutation()
  const init = useAppSelector((state) => state.init)
  const { data: user } = useGetLabUserQuery(userId ? { id: Number(userId) } : skipToken)

  const initValues = useMemo(() => {
    if (user) {
      return {
        firstName: user.firstName,
        lastName: user.lastName,
        officeNumber: user.officePhone,
        directNumber: user.directPhone,
        email: user.email,
        role: user.roles?.[0],
        active: user.active,
        ...user.permissions.reduce((acc: any, curr: string) => {
          acc[`permissions_${curr}`] = true
          return acc
        }, {}),
      }
    } else {
      return initialValues
    }
  }, [user])

  const setPermissions = (value: boolean, setValues: any) => (e) => {
    e.preventDefault()
    const newValues = {}
    Object.values(permissions).forEach((permissionSet) => {
      permissionSet.forEach(({ code }) => {
        newValues[`permissions_${code}`] = value
      })
    })
    Object.keys(permissions).forEach((section) => {
      const el = document.getElementsByName(`permissions_${section}`)[0]
      if (el) {
        el.checked = value
      }
    })
    setValues((p) => ({ ...p, ...newValues }))
  }

  const setSectionPermissions = (permissions: any, setValues: any) => (e) => {
    const newValues = {}
    permissions.forEach(({ code }) => {
      newValues[`permissions_${code}`] = e.target.checked
    })
    setValues((p) => ({ ...p, ...newValues }))
  }

  return (
    <div>
      <DashboardContent
        icon="fas fa-building"
        title={userId ? 'Edit User' : 'Create User'}
        subtitle={
          <>
            <Link to={`${routes.index}${routes.labUsers.root}${routes.labUsers.directory}`}>
              {'< Back to User List'}
            </Link>

            <div className="float-right">
              {userId && (
                <>
                  <Button
                    variant="outline-danger"
                    css={{ marginRight: 10 }}
                    disabled={Number(userId) === init?.id}
                    onClick={() =>
                      confirm('user')
                        .then(async () => {
                          await deleteUser({ id: Number(userId) }).unwrap()
                          navigate(
                            `${routes.index}${routes.labUsers.root}${routes.labUsers.directory}`,
                          )
                        })
                        .catch(() => {})
                    }>
                    Delete User
                  </Button>
                  <Button
                    variant="outline-danger mr-5"
                    css={{}}
                    onClick={() => {
                      confirm('', {
                        title: 'Are you sure?',
                        description: 'This will send a password reset email to this user.',
                        yesAction: 'Yes, proceed',
                        noAction: 'Cancel',
                      })
                        .then(async () => {
                          try {
                            await sendResetPasswordEmail({ email: user?.email }).unwrap()
                            Toast({ type: 'success', label: `Password reset email sent.` })
                          } catch (err) {
                            Toast({ type: 'error', label: `Error sending password reset email.` })
                          }
                        })
                        .catch(() => {})
                    }}>
                    Reset Password
                  </Button>
                </>
              )}
              <Button variant="primary" type="submit" form="userUpsert">
                Save Changes
              </Button>
            </div>
          </>
        }
        breadcrumb={[
          {
            label: 'Users',
            to: `${routes.index}${routes.company.root}`,
          },
          { label: userId ? 'Edit' : 'New' },
        ]}
        content={
          <Formik
            enableReinitialize={true}
            validationSchema={userValidation}
            initialValues={initValues}
            onSubmit={async (values) => {
              const { directNumber, officeNumber, role, ...vals } = values
              vals.officePhone = officeNumber
              vals.directPhone = directNumber
              vals.permissions = []
              vals.userType = UserType.LAB
              vals.roles = [role]

              for (const key in vals) {
                if (key.includes('permissions_')) {
                  if (vals[key]) {
                    vals.permissions.push(key.replace('permissions_', ''))
                  }
                  delete vals[key]
                }
              }
              try {
                if (userId) {
                  await updateLabUser({
                    id: Number(userId),
                    ...vals,
                  }).unwrap()
                } else {
                  vals.password = uuidv4()
                  await createLabUser(vals).unwrap()
                }
                navigate(`${routes.index}${routes.labUsers.root}${routes.labUsers.directory}`)
                Toast({ type: 'success', label: `User ${userId ? 'updated' : 'created'}!` })
              } catch (err) {
                Toast({ type: 'error', label: err.data.message })
              }
            }}>
            {({ values, errors, touched, handleChange, setFieldValue, setValues }) => (
              <Form id="userUpsert">
                <div className="card-body row">
                  <div className="col-md-12 col-lg-6 mb-3">
                    <div className="card card-primary p-2">
                      <div
                        className="card-header d-flex"
                        css={{
                          color: 'black !important',
                          backgroundColor: 'transparent !important',
                          justifyContent: 'space-between',
                          '&::after': {
                            display: 'none',
                          },
                        }}>
                        <h3 className="card-title">LAB USER INFORMATION</h3>
                        <SwitchButton
                          onlabel="Active"
                          offlabel="Inactive"
                          width={80}
                          size="md"
                          checked={values.active}
                          onChange={(checked) => setFieldValue('active', checked)}
                        />
                      </div>
                      <div className={`container bg-white mb-3 px-1 p-0`}>
                        <div className={`container pt-3 pb-2`}>
                          <div className="row mb-3">
                            <div className="col-6 form-group textGray">
                              <label htmlFor="firstName">
                                First Name
                                <span className="text-danger">*</span>
                              </label>
                              <Field
                                type="text"
                                name="firstName"
                                className="form-control"
                                placeholder="First Name"
                                value={values.firstName}
                                onChange={handleChange}
                                autoComplete="one-time-code"
                              />
                              {touched.firstName && errors.firstName && (
                                <div className="text-danger">{errors.firstName}</div>
                              )}
                            </div>
                            <div className="col-6 form-group textGray">
                              <label htmlFor="lastName">
                                Last Name
                                <span className="text-danger">*</span>
                              </label>
                              <Field
                                type="text"
                                name="lastName"
                                className="form-control"
                                placeholder="Last Name"
                                value={values.lastName}
                                onChange={handleChange}
                                autoComplete="one-time-code"
                              />
                              {touched.lastName && errors.lastName && (
                                <div className="text-danger">{errors.lastName}</div>
                              )}
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-6 form-group textGray">
                              <label htmlFor="officeNumber">Office Number</label>
                              <MaskInput
                                value={values.officeNumber}
                                onChange={handleChange}
                                name="officeNumber"
                                mask={phoneNumberMask}
                                placeholder="(###) ###-####"
                                autoComplete="off"
                              />
                              {touched.officeNumber && errors.officeNumber && (
                                <div className="text-danger">{errors.officeNumber}</div>
                              )}
                            </div>
                            <div className="col-6 form-group textGray">
                              <label htmlFor="directNumber">Mobile Number</label>
                              <MaskInput
                                value={values.directNumber}
                                onChange={handleChange}
                                name="directNumber"
                                mask={phoneNumberMask}
                                placeholder="(###) ###-####"
                                autoComplete="off"
                              />

                              {touched.directNumber && errors.directNumber && (
                                <div className="text-danger">{errors.directNumber}</div>
                              )}
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-6 form-group textGray">
                              <label htmlFor="email">Email</label>
                              <span className="text-danger">*</span>
                              <Field
                                type="text"
                                name="email"
                                className="form-control"
                                placeholder="Email"
                                value={values.email}
                                onChange={handleChange}
                                autoComplete="one-time-code"
                              />
                              {touched.email && errors.email && (
                                <div className="text-danger">Required</div>
                              )}
                            </div>
                            <div className="col-6 form-group textGray">
                              <label htmlFor="role">Role</label>
                              <span className="text-danger">*</span>
                              <Field
                                className="form-control"
                                as="select"
                                name="role"
                                placeholder="Role">
                                <option value="">Select Role</option>
                                {roles.map((s) => (
                                  <option key={s} value={s}>
                                    {capitalize(s.replace('_', ' '))}
                                  </option>
                                ))}
                              </Field>
                              {touched.role && errors.role && (
                                <div className="text-danger">Required</div>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* <div className="col-sm-12 pt-3 pr-0 border-top">
                          <div className="col-sm-12 d-flex justify-content-end">
                            <Button variant="primary">Save Changes</Button>
                          </div>
                        </div>*/}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-6 mb-3">
                    <div className="card card-primary p-2">
                      <div
                        className="card-header"
                        css={{
                          color: 'black !important',
                          backgroundColor: 'transparent !important',
                        }}>
                        <h3 className="card-title">PERMISSIONS</h3>
                      </div>
                      <div className="card-body">
                        <div className="d-flex" css={{ marginBottom: 12 }}>
                          <Link to="#" onClick={setPermissions(true, setValues)} className="mr-2">
                            Select All
                          </Link>
                          |
                          <Link to="#" onClick={setPermissions(false, setValues)} className="ml-2">
                            Clear
                          </Link>
                        </div>

                        {Object.entries(permissions).map(([section, permissions]) => (
                          <div key={section}>
                            <div className="d-flex" css={{ '& input': { marginTop: 4 } }}>
                              <Checkbox
                                name={`permissions_${section}`}
                                handleChange={setSectionPermissions(permissions, setValues)}
                                value={permissions.every(
                                  ({ code }) => values[`permissions_${code}`],
                                )}
                              />
                              <h5>{capitalize(section)}</h5>
                            </div>
                            <div className="row mb-4 pl-4">
                              {permissions.map(({ code, name }) => (
                                <div key={code} className="col-6">
                                  <div className="form-check">
                                    <Field
                                      className="form-check-input"
                                      type="checkbox"
                                      name={`permissions_${code}`}
                                      id={`permissions_${code}`}
                                    />
                                    <label
                                      className="form-check-label"
                                      css={{ paddingTop: '2px' }}
                                      htmlFor={`permissions_${code}`}>
                                      {name}
                                    </label>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        }
      />
    </div>
  )
}

export default UserUpsert

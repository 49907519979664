import { ChangeEvent, useMemo } from 'react'
import { skipToken } from '@reduxjs/toolkit/query/react'
import ReactSelect from 'react-select'

import { handleChange } from 'components/atoms/Upsert'
import { useAppDispatch } from 'hooks/redux'
import * as generalHelper from 'helpers/generalHelper'
import Select from 'components/atoms/Select'
import Input from 'components/atoms/Input'
import Button from 'components/atoms/Button'
import states from 'constants/states'
import SearchResultItem from './SearchResultItem'
import {
  physicianApi as physicianApiDme,
  useGetLatestPhysiciansQuery,
  useGetPhysiciansQuery,
} from 'store/services/physician'
import {
  physicianApi as physicianApiLab,
  useGetDmePhysiciansQuery,
  useGetDmeLatestPhysiciansQuery,
} from 'store/services/labPhysician'
import { Order, Physician, PhysicianAddress } from 'store/types'
import { confirm } from 'components/molecules/Confirmation'

export type SearchFormValues =
  | {
      npi: string
      firstName: string
      lastName: string
      addressState: string
    }
  | {}

interface IProps {
  order: Order
  searchValues: SearchFormValues
  setSearchValues: (v: SearchFormValues) => void
  resultsLimit: number
  setResultsLimit: (v: number) => void
  setPhysician: (physician: Physician, location: PhysicianAddress) => void
  handleClear: () => void
  selectedLatestPhy: string
  setSelectedLatestPhy: (npi: string) => void
  openPhysicianModal?: (physician: Physician, addressIdx: number) => void
  currentOfficeId: string | number
  usedBy: 'lab' | 'dme'
  onSelect: () => void
}

export const PhysicianSearch = ({
  order,
  searchValues,
  setSearchValues,
  resultsLimit,
  setResultsLimit,
  setPhysician,
  handleClear,
  selectedLatestPhy,
  setSelectedLatestPhy,
  openPhysicianModal,
  currentOfficeId,
  usedBy,
  onSelect = () => {},
}: IProps) => {
  const useLatestPhysicians =
    usedBy === 'lab' ? useGetDmeLatestPhysiciansQuery : useGetLatestPhysiciansQuery
  const usePhysicians = usedBy === 'lab' ? useGetDmePhysiciansQuery : useGetPhysiciansQuery
  const getPhysicians =
    usedBy === 'lab'
      ? physicianApiLab.endpoints.getDmePhysicians
      : physicianApiDme.endpoints.getPhysicians

  const dispatch = useAppDispatch()
  const { data: latestPhysicians } = useLatestPhysicians(
    order?.patient?.id
      ? {
          officeId: currentOfficeId,
          patientId: order?.patient?.id,
          count: 20,
        }
      : skipToken,
    {
      refetchOnFocus: true,
    },
  )
  const searchParams = generalHelper.cleanObj(searchValues) || {}
  const isSearchValid = searchParams.npi || (searchParams.lastName && searchParams.addressState)
  searchParams.officeId = currentOfficeId || 1

  const { data: physicians } = usePhysicians(isSearchValid ? searchParams : skipToken)

  const latestPhysiciansOptions = useMemo(
    () =>
      [...(latestPhysicians || [])]
        ?.sort((a, b) => (a.lastName > b.lastName ? 1 : -1))
        ?.map((p) => ({
          value: p.npi,
          label: `${p.lastName}, ${p.credential}, ${p.firstName} | ${p.addresses?.[0]?.addressCity}, ${p.addresses?.[0]?.addressState} | NPI: ${p.npi}`,
        })),
    [latestPhysicians],
  )

  const limitedAmountOfPhysicians = physicians?.slice(0, resultsLimit)
  const rolodexPhysicians = limitedAmountOfPhysicians?.filter((p) => p.id)
  const npiPhysicians = limitedAmountOfPhysicians?.filter((p) => !p.id)

  const handleChangeLocal = async (
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>,
  ) => {
    handleChange(event, searchValues, setSearchValues)
  }

  const handleSearch = async (e) => {
    e.preventDefault()
    setResultsLimit(20)
    const searchParams = generalHelper.cleanObj(searchValues) || {}
    searchParams.officeId = currentOfficeId

    if (isSearchValid) {
      dispatch(
        getPhysicians.initiate(searchParams, {
          subscribe: false,
          forceRefetch: true,
        }),
      )
    } else {
      confirm('', {
        title: 'Insufficient Search Criteria',
        description: 'Please enter at least last name and state or NPI.',
        yesAction: 'Ok',
        noAction: '',
      })
    }
  }

  return (
    <form method="post" onSubmit={handleSearch}>
      <div className="col-sm-12 text-gray col-md-6 p-0 mb-2">
        <ReactSelect
          className="basic-single w-100 mr-3"
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 10000 }) }}
          classNamePrefix="select"
          isClearable
          isSearchable
          placeholder="Recently Used Physicians"
          name="officerecentPhysiciansId"
          onChange={({ value: npi }) => {
            setResultsLimit(20)
            setSelectedLatestPhy(npi)
            setSearchValues({ npi })
          }}
          options={latestPhysiciansOptions || []}
          value={selectedLatestPhy ?? null}
        />
      </div>
      <div className="row">
        <div className="col-sm-12 my-2">
          <span className="font-weight-bold text-gray">Search Physicians</span>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-2">
          <Input
            value={searchValues.lastName}
            label="Last Name"
            placeholder="Last Name"
            name="lastName"
            handleChange={handleChangeLocal}
            autoComplete="one-time-code"
          />
        </div>
        <div className="col-sm-12 col-md-2">
          <Input
            value={searchValues ? searchValues.firstName : ''}
            label="First Name"
            placeholder="First Name"
            name="firstName"
            handleChange={handleChangeLocal}
            autoComplete="one-time-code"
          />
        </div>
        <div className="col-sm-12 col-md-2">
          <Input
            value={searchValues.npi}
            label="NPI"
            placeholder="NPI"
            name="npi"
            autoFocus={true}
            handleChange={handleChangeLocal}
            autoComplete="one-time-code"
          />
        </div>
        <div className="col-sm-12 text-gray col-md-2">
          <Select
            options={states || []}
            value={searchValues.addressState}
            defaultLabel="All States"
            name="addressState"
            label="State"
            handleChange={handleChangeLocal}
          />
        </div>
        <div className="col-sm-6 col-md-4 align-self-end">
          <div className="form-group">
            <Button className="btn btn-primary mr-2" label="Search" onClick={handleSearch} />
            <Button className="btn btn-default" label="Clear" type="button" onClick={handleClear} />
          </div>
        </div>
        {!!rolodexPhysicians?.length && (
          <div className="col-sm-12 search-results-card">
            <div className="row">
              <div className="col-sm-12 my-2">
                <span className="font-weight-bold text-gray">Rolodex Physicians</span>
              </div>
            </div>
            {rolodexPhysicians.map((physician, index) => (
              <SearchResultItem
                physician={physician}
                index={index}
                setPhysician={setPhysician}
                openPhysicianModal={openPhysicianModal}
              />
            ))}
          </div>
        )}
        {!!npiPhysicians?.length && (
          <div className="col-sm-12 search-results-card">
            <div className="row">
              <div className="col-sm-12 my-2">
                <span className="font-weight-bold text-gray">Physicians for Import</span>
              </div>
            </div>
            {npiPhysicians.map((physician, index) => (
              <SearchResultItem
                physician={physician}
                index={index}
                setPhysician={setPhysician}
                openPhysicianModal={openPhysicianModal}
                onSelect={onSelect}
              />
            ))}
          </div>
        )}
      </div>
    </form>
  )
}

import Button from './Button'
import * as generalHelper from '../../helpers/generalHelper'
import { SyntheticEvent } from 'react'

interface IProps {
  id?: string
  label?: string
  icon?: string
  className?: string
  type?: string
  disabled?: boolean
  onClick?: (e: SyntheticEvent) => void
  form?: string
}

const ButtonSaveComponent = (props: IProps) => {
  const label = props.label ? props.label : 'Save'
  const icon = props.icon ? props.icon : 'fa fa-check'
  const className = props.className ? props.className : 'btn btn-primary'

  return <Button {...props} label={generalHelper.t(label)} className={className} icon={icon} />
}

export default ButtonSaveComponent

// import { UserPermission } from 'ts-back-end/src/business/user/dto/create-user.dto'

export enum UserPermission {
  EDIT_PHYSICIAN_OFFICES = 'EDIT_PHYSICIAN_OFFICES',
  EDIT_PHYSICIANS = 'EDIT_PHYSICIANS',
  EDIT_OFFICES = 'EDIT_OFFICES',
  EDIT_REPORTS = 'EDIT_REPORTS',
  MODIFY_TEST_CONDITIONS = 'MODIFY_TEST_CONDITIONS',
  MOVE_REPORTS = 'MOVE_REPORTS',
  MODIFY_INSURANCE = 'MODIFY_INSURANCE',
  MANAGE_DEVICES = 'MANAGE_DEVICES',
  MANAGE_DEVICE_INVENTORY = 'MANAGE_DEVICE_INVENTORY',
  EDIT_TEMPLATES = 'EDIT_TEMPLATES',
  DELETE_INCOMING_FAXES = 'DELETE_INCOMING_FAXES',
  CANCEL_ORDERS = 'CANCEL_ORDERS',
  MARK_MESSAGES_AS_READ = 'MARK_MESSAGES_AS_READ',

  SUBMIT_BRIGHTREE_ORDERS = 'SUBMIT_BRIGHTREE_ORDERS',

  EDIT_DME_USERS = 'EDIT_DME_USERS',
  EDIT_LAB_USERS = 'EDIT_LAB_USERS',
  EDIT_INSURANCE_PAYERS = 'EDIT_INSURANCE_PAYERS',
  EDIT_INS_AUTHORIZATION = 'EDIT_INS_AUTHORIZATION',
  OUTSOURCE_REVIEW_CHAT = 'OUTSOURCE_REVIEW_CHAT',
}

export const permissions = {
  users: [
    {
      name: 'Add/Update DME users',
      code: UserPermission.EDIT_DME_USERS,
    },
    {
      name: 'Add/Update Lab users',
      code: UserPermission.EDIT_LAB_USERS,
    },
  ],
  physician: [
    {
      name: 'Add/Update physician office',
      code: UserPermission.EDIT_PHYSICIAN_OFFICES,
    },
    { name: 'Update physician name', code: UserPermission.EDIT_PHYSICIANS },
  ],
  insurance: [
    {
      name: 'Add/Update insurance payers',
      code: UserPermission.EDIT_INSURANCE_PAYERS,
    },
    {
      name: 'Add/Update insurance authorization',
      code: UserPermission.EDIT_INS_AUTHORIZATION,
    },
  ],
  offices: [{ name: 'Add/Update office', code: UserPermission.EDIT_OFFICES }],
  orders: [
    {
      name: 'Add/Update patient insurance during billing review',
      code: UserPermission.MODIFY_INSURANCE,
    },
    { name: 'Cancel order', code: UserPermission.CANCEL_ORDERS },
    { name: 'Mark direct message as read', code: UserPermission.MARK_MESSAGES_AS_READ },
  ],
  reports: [
    { name: 'Update date of service', code: UserPermission.EDIT_REPORTS },
    { name: 'Update test conditions', code: UserPermission.MODIFY_TEST_CONDITIONS },
    { name: 'Move report', code: UserPermission.MOVE_REPORTS },
  ],
  devices: [
    { name: 'Add/Update device', code: UserPermission.MANAGE_DEVICES },
    { name: 'Assign device to office', code: UserPermission.MANAGE_DEVICE_INVENTORY },
  ],
  templates: [
    {
      name: 'Add/Update template and canned responses',
      code: UserPermission.EDIT_TEMPLATES,
    },
  ],
  faxes: [{ name: 'Delete incoming fax', code: UserPermission.DELETE_INCOMING_FAXES }],
  outsource: [
    {
      name: 'Access to outsource review and chat',
      code: UserPermission.OUTSOURCE_REVIEW_CHAT,
    },
  ],
}

/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled'
import React from 'react'
import { useGetPhysicianGroupStatsQuery } from '../../../store/services/physician'
import DashboardContent from '../../dme/atoms/DashboardContent'

const Wrap = styled.div`
  background: white;
  border-top: 3px solid #2c83bd;
  border-radius: 3px;
  padding: 10px;
  margin: 15px;
  box-shadow: 0 1px 3px 1px #e8e5e5;
`
interface IProps {
  name: string
  loading: boolean
  color: string
  year?: number
  month?: number
  week?: number
}

const StatsBlock = (props: IProps) => (
  <div
    css={{
      borderRadius: 3,
      overflow: 'hidden',
      width: '100%',
      boxShadow: '3px 3px 5px 1px #f2f2f2',
      height: 150,
    }}>
    <div
      css={{
        backgroundColor: 'white',
        userSelect: 'none',
        borderTop: `3px solid ${props.color}`,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '100%',
        color: props.loading ? '#c5c5c5' : 'black',
      }}>
      <div css={{ fontSize: 18, padding: 10, paddingLeft: 10, borderBottom: '1px solid #ced4da' }}>
        {props.name}
      </div>
      <div
        className={'d-flex justify-content-around align-items-center'}
        css={{ width: '100%', height: '100%' }}>
        <div>
          <h1 className={'mb-2 d-flex justify-content-center'} css={{ width: '100%' }}>
            {(!props.year && props.year !== 0) || props.loading ? '--' : props.year}
          </h1>
          <p>THIS YEAR</p>
        </div>
        <div>
          <h1 className={'mb-2 d-flex justify-content-center'} css={{ width: '100%' }}>
            {(!props.month && props.month !== 0) || props.loading ? '--' : props.month}
          </h1>
          <p>THIS MONTH</p>
        </div>
        <div>
          <h1 className={'mb-2 d-flex justify-content-center'} css={{ width: '100%' }}>
            {(!props.week && props.week !== 0) || props.loading ? '--' : props.week}
          </h1>
          <p>THIS WEEK</p>
        </div>
      </div>
    </div>
  </div>
)

export default function () {
  const { data: stats, isLoading } = useGetPhysicianGroupStatsQuery({})

  return (
    <DashboardContent
      title="Dashboard"
      content={
        <Wrap>
          <div
            css={{
              marginTop: 5,
              marginBottom: 15,
              paddingBottom: 15,
              fontSize: 18,
              borderBottom: '1px solid #ced4da',
            }}>
            Physician Group Order statistics
          </div>
          <div>
            <div className={'d-flex mb-3'} css={{ gap: 20 }}>
              <StatsBlock
                name={'NEW ORDERS'}
                loading={isLoading}
                color={'#e69c37'}
                year={stats?.new.year}
                month={stats?.new.month}
                week={stats?.new.week}
              />
              <StatsBlock
                name={'COMPLETED ORDERS'}
                loading={isLoading}
                color={'#2c83bd'}
                year={stats?.completed.year}
                month={stats?.completed.month}
                week={stats?.completed.week}
              />
            </div>
            <div className={'d-flex mb-3'} css={{ gap: 20 }}>
              <StatsBlock
                name={'QUALIFIED'}
                loading={isLoading}
                color={'#4ea45f'}
                year={stats?.qualified.year}
                month={stats?.qualified.month}
                week={stats?.qualified.week}
              />
              <StatsBlock
                name={'NON-QUALIFIED'}
                loading={isLoading}
                color={'#d42020'}
                year={stats?.nonQualified.year}
                month={stats?.nonQualified.month}
                week={stats?.nonQualified.week}
              />
            </div>
          </div>
        </Wrap>
      }
      breadcrumb={[{ label: 'Home' }]}
    />
  )
}

import DatePicker from 'react-datepicker'
import { KeyVal } from 'store/types'
import { forwardRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import styled from '@emotion/styled'

const StyledInput = styled.input`
  border-width: 1px;
  border-radius: 5px;
  padding: 4px 8px;
  border-color: #c2c8cf;
  border-style: solid;
`

export const CustomDateInput = forwardRef(({ value, onClick, disabled }, ref) => (
  <div style={{ position: 'relative' }}>
    <StyledInput onClick={onClick} ref={ref} value={value} disabled={disabled} />
    <FontAwesomeIcon icon={faCalendarAlt} style={{ position: 'absolute', right: 5, top: 5 }} />
  </div>
))
interface IProps {
  name?: string
  value: Date | null
  handleChange: (obj: Date) => void
  className?: string
  format?: string
  minDate?: Date
  maxDate?: Date
  dateFormat?: string
  todayButton?: string
  timeIntervals?: number
  disabled?: boolean
}

const DatePickerComponent = (props: IProps) => {
  const {
    value = null,
    handleChange = (date: Date) => {},
    className = 'form-control',
    ...rest
  } = props

  return (
    <DatePicker
      selected={value}
      className={className}
      showTimeSelect
      dateFormat="MMMM d, yyyy h:mm aa"
      onChange={handleChange}
      customInput={<CustomDateInput disabled={rest.disabled} />}
      {...rest}
    />
  )
}

export default DatePickerComponent

import { useState } from 'react'
import { Form, Formik } from 'formik'
import moment from 'moment'

import VerifyForm from './forms/VerifyForm'
import Button from '../../atoms/Button'
import verifyValidation from './schemas/verifyValidationSchema'
import { Order, PatientRelationship } from 'store/types'
import { SignAobRequest } from 'store/services/dmeOrder'

interface IProps {
  order: Order
  setAobPdf: (url: string) => void
  signAob: (data: SignAobRequest) => Promise<any>
}

const VerifyUpsert = ({ order, setAobPdf, signAob }: IProps) => {
  const [loading, setLoading] = useState(false)

  const initialValues = {
    email: order?.patient?.email ?? '',
    relationship: 'self',
    signature: '',
    signedBy: '',
    signatureDateTime: '',
  }
  return (
    <Formik
      enableReinitialize={true}
      validationSchema={verifyValidation}
      initialValues={initialValues}
      onSubmit={async (values) => {
        setLoading(true)
        try {
          const result = await signAob({
            orderId: Number(order.id),
            signature: values.signature,
            relationship: values.relationship as PatientRelationship,
            signatureDateTime: moment().format('YYYY-MM-DD HH:mm'),
            ...(values.relationship !== 'self' && { signedBy: values.signedBy }),
            email: values.email,
          })
          const files = result?.data?.files || {}

          const aobKey = Object.keys(files || {}).find(
            (key: string): boolean => files[key].uploadType === 'AOB',
          )

          if (aobKey) {
            setAobPdf(files[aobKey]?.url)
          }
          setLoading(false)
        } catch (e) {
          console.log(e)
        } finally {
          setLoading(false)
        }
      }}>
      {({ values, errors, touched, setFieldValue, handleSubmit }) => (
        <Form>
          <div className="d-flex">
            <h3>Verify Information</h3>
            <Button
              id="btn-ts"
              label="Verify"
              type="button"
              className="btn-lg ml-auto"
              disabled={loading}
              onClick={handleSubmit}
            />
          </div>
          <VerifyForm
            values={values}
            errors={errors}
            touched={touched}
            setFieldValue={setFieldValue}
          />
        </Form>
      )}
    </Formik>
  )
}

export default VerifyUpsert

import { ChangeEvent, SyntheticEvent } from 'react'
import Toast from './Toast'
import { KeyVal } from 'store/types'
import _ from 'lodash'

type ResType = { payload?: {id: string} & Record<string, unknown> }

export const handleResponse = (res: ResType, params: {callback?: unknown; id?: unknown}) => {
  const { callback } = params
  if (res?.payload && (res.payload?.id || (Array.isArray(res.payload) && res.payload[0].id))) {
    if (params.id) {
      Toast({ type: 'success', label: 'Updated', extra: res.payload.id })
    } else {
      Toast({
        type: 'success',
        label: 'Inserted',
        extra: res.payload.id,
      })
    }
    let callbackArray = Array.isArray(callback) ? callback : [callback]
    callbackArray.forEach((call) => {
      if (call && call.function) {
        if (call.dispatch) {
          call.dispatch(call.function(call.params))
        } else {
          if (call.params_add) {
            call.function(call.params + res.payload?.[call.params_add])
          } else {
            call.function(call.params, res)
          }
        }
      }
    })
  } else {
    Toast({
      type: 'error',
      label: 'Error',
      extra: res as string,
    })
  }
}

/**
 *
 * Handles eventChange events / functions for managed fields, when setting secsToWait function becomes debounced
 *
 * @param {*} event
 * @param {*} values
 * @param callback
 * @param extraParams
 * @param secsToWait
 */
export const handleChange = (
  event: ChangeEvent<HTMLInputElement>,
  values: KeyVal,
  callback: (v: any) => void,
  extraParams?: any,
  secsToWait?: number,
) => {
  const doChangeThings = () => {
    if (event?.target?.name) {
      let name = event.target.name
      let value: string | boolean = event.target.value

      if (event?.target && (event.target.type === 'checkbox' || event.target.type === 'radio')) {
        value = event.target.checked
      }

      let callbackArray = Array.isArray(callback) ? callback : [callback]
      callbackArray.forEach((call) => {
        let params = call.params
          ? call.params
          : {
              ...values,
              [name]: value,
            }
        if (call.function) {
          call.function(params)
        } else {
          call(params)
        }
      })
    } else if (typeof event == 'object' && !extraParams) {
      let callbackArray = Array.isArray(callback) ? callback : [callback]
      callbackArray.forEach((call) => {
        let params = call.params
          ? call.params
          : {
              ...values,
              ...event,
            }
        if (call.function) {
          call.function(params)
        } else {
          call(params)
        }
      })
    }
  }
  if (secsToWait) {
    _.debounce(doChangeThings, secsToWait)
  } else {
    doChangeThings()
  }
}

// export const handleChangeArray = (index: string | number,
//                                   event: ChangeEvent<HTMLInputElement>,
//                                   values: Array<unknown>,
//                                   callback: (a: unknown) => unknown,
//                                   _extraParams: unknown
// ) => {
//   if (event?.target?.name) {
//     let name = event.target.name
//     let value = event.target.value
//     let newValues = Object.assign([...values])
//     newValues[index][name] = value
//     callback(newValues)
//   }
// }

interface Params {
  id?: number
  values?: KeyVal
  functions?: any
  callback?: () => void
  dispatch?: any
}
export const handleSubmit = async (event: SyntheticEvent, params: Params = {}) => {
  event.preventDefault()
  const { id, values, functions, dispatch } = params
  if (Number(id) > 0) {
    dispatch(functions.update({ id, params: values })).then((res: ResType) => {
      handleResponse(res, params)
    })
  } else {
    dispatch(functions.insert(values)).then((res: ResType) => {
      handleResponse(res, params)
    })
  }
}

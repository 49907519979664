/** @jsxImportSource @emotion/react */
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css'
import { ChatContainer, MessageList, Message as ChatMessage } from '@chatscope/chat-ui-kit-react'
import moment from 'moment'
import * as Yup from 'yup'
import { Formik, Form, Field } from 'formik'
import { Button as BsButton } from 'react-bootstrap'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import Button from '../../atoms/Button'
import NoData from '../../atoms/NoData'
import { Message } from 'store/types'
import { cannedMessages } from './cannedMsgs'

const MailButton = styled(BsButton)`
  padding: 0 2px;
`

const iconMap = {
  'PATIENT.BAD': 'fa-user-circle',
  'PHYSICIAN.BAD': 'fa-stethoscope',
  'PAYER.BAD': 'fa-medkit',
  'PAYER_2.BAD': 'fa-medkit',
  'PAYER_3.BAD': 'fa-medkit',
  'RX.BAD': 'fa-prescription',
  'AOB.BAD': 'fa-file-alt',
}

const validation = Yup.object().shape({
  newMessage: Yup.string().required('Required'),
})

interface IProps {
  userContext: 'LAB' | 'DME'
  messages?: Message[]
  addMessage: (m: string) => Promise<void>
  updateMessageReadStatus: (params: {
    uuid: string
    readStatus: 'READ' | 'UNREAD'
  }) => Promise<void>
}

const Panel = ({ userContext, messages, addMessage, updateMessageReadStatus }: IProps) => {
  const keyboardAction = (event, cb) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      cb()
    }
  }

  return (
    <div>
      <div className="row mb-2">
        <div className="col">
          <div className="msg_panel">
            {!messages?.length && (
              <NoData text="No Messages" className="msg_noData font-italic py-3 my-3" />
            )}
            <ChatContainer className="my-3">
              <MessageList>
                {messages?.map((messageObj) => {
                  const isLabMessage = messageObj.sentBy === 'lab'
                  const name = `${messageObj.userFirstName} ${messageObj.userLastName}`
                  const rightStyle =
                    (isLabMessage && userContext === 'LAB') ||
                    (!isLabMessage && userContext === 'DME')
                  const icon = messageObj.context ? (
                    <button
                      className="btn-failed btn btn-sm"
                      css={css`
                        position: absolute;
                        top: 5px;
                        right: 5px;
                        padding: 0.05rem 0.1rem;
                        cursor: default !important;
                        line-height: 0.5rem;
                      `}>
                      <i
                        className={`fa fa-fw ${iconMap[messageObj.context]}`}
                        css={css`
                          font-size: 0.8rem;
                        `}
                      />
                    </button>
                  ) : null
                  return (
                    <div>
                      {!rightStyle && (
                        <ChatMessage.Header sender={name}>
                          <span
                            css={{
                              color: 'rgba(77,77,77,0.87)',
                              textAlign: 'end',
                              marginTop: '5px',
                            }}>
                            {name}
                          </span>
                        </ChatMessage.Header>
                      )}
                      <ChatMessage
                        model={{
                          direction: rightStyle ? 'outgoing' : 'incoming',
                          type: 'custom',
                          sender: name,
                          position: 'single',
                        }}
                        css={css`
                          position: relative;
                          .cs-message__content-wrapper {
                            min-width: 180px;
                            position: relative;
                            line-height: 1.3rem;
                          }
                        `}>
                        <ChatMessage.CustomContent>
                          <div
                            css={{
                              fontSize: '0.9rem',
                              color: !rightStyle ? '#6c757d' : '#eee',
                              paddingBottom: '4px',
                              lineHeight: '1.3rem',
                            }}>
                            {moment(messageObj.createdAt).format('MM/DD/YYYY HH:mm a')}
                          </div>
                          {!rightStyle && messageObj.readAt && (
                            <MailButton
                              size="sm"
                              css={{
                                position: 'absolute',
                                ...(userContext === 'LAB' ? { top: 5 } : { bottom: 5 }),
                                right: 5,
                                cursor: 'pointer',
                                paddingTop: 2,
                              }}
                              onClick={() =>
                                updateMessageReadStatus({
                                  uuid: messageObj.uuid,
                                  readStatus: 'UNREAD',
                                })
                              }>
                              <i className="fa fa-undo" css={{ color: 'white' }} />
                            </MailButton>
                          )}
                          {rightStyle || messageObj.readAt ? (
                            <div>{messageObj.text}</div>
                          ) : (
                            <div className="d-flex justify-content-center w-100">
                              <MailButton
                                size="sm"
                                onClick={() =>
                                  updateMessageReadStatus({
                                    uuid: messageObj.uuid,
                                    readStatus: 'READ',
                                  })
                                }>
                                <i className="fa fa-envelope" /> Open
                              </MailButton>
                            </div>
                          )}
                          {rightStyle && (
                            <i
                              css={{
                                position: 'absolute',
                                top: 5,
                                right: 5,
                                color: 'white',
                                fontSize: '0.8rem',
                              }}
                              className={`fas fa-envelope${messageObj.readAt ? '-open' : ''}`}
                            />
                          )}
                          {!rightStyle && icon}
                        </ChatMessage.CustomContent>
                      </ChatMessage>
                      {rightStyle && (
                        <div
                          css={{ display: 'flex', justifyContent: 'flex-end', marginTop: '5px' }}>
                          <span style={{ color: 'rgba(77,77,77,0.87)', textAlign: 'end' }}>
                            {name}
                          </span>
                        </div>
                      )}
                    </div>
                  )
                })}
              </MessageList>
            </ChatContainer>
          </div>
        </div>
      </div>

      <Formik
        initialValues={{ newMessage: '' }}
        validationSchema={validation}
        enableReinitialize={true}
        onSubmit={async ({ newMessage }, { resetForm }) => {
          await addMessage(newMessage)
          resetForm()
        }}>
        {({ touched, errors, setFieldValue, handleSubmit, isSubmitting }) => (
          <Form onKeyDown={(event) => keyboardAction(event, handleSubmit)}>
            {userContext === 'LAB' ? (
              <div className="row mb-2 ml-1 mr-1">
                <div className="col form-group">
                  <Field
                    className="form-control"
                    as="select"
                    name="cannedMsg"
                    onChange={(e) => {
                      setFieldValue('newMessage', e.target.value)
                      setFieldValue('cannedMsg', '')
                    }}>
                    <option value="">Select Message Template</option>
                    {cannedMessages.map((s, i) => (
                      <option key={i} value={s.content}>
                        {s.title}
                      </option>
                    ))}
                  </Field>
                </div>
              </div>
            ) : null}

            <div className="row mb-2 ml-1 mr-1">
              <div className="col form-group">
                <label htmlFor="msg_input">Add a message</label>
                <Field
                  component="textarea"
                  className="form-control msg_textarea"
                  name="newMessage"
                  rows="4"
                  placeholder="Describe your request..."
                />
                {touched.newMessage && errors.newMessage ? (
                  <div className="text-danger">{errors.newMessage}</div>
                ) : null}
              </div>
            </div>
            <div className="row">
              <div className="col text-right mr-2">
                {/* <Button className="btn-default mr-2" label="Cancel" onClick={handleReset} /> */}
                <Button
                  className=""
                  id="btn-ts"
                  label="Send"
                  type="submit"
                  disabled={isSubmitting}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default Panel

import { ReactNode } from 'react'
import styled from '@emotion/styled'

import Link from './Link'

const StyledLink = styled(Link)`
  margin-bottom: -2px !important;
  &.active {
    &:hover {
      border-bottom: 1px solid white !important;
      margin-bottom: -2px !important;
    }
  }
`

const TabPanel = styled.div`
  min-height: 30vh;
`

interface TabData {
  label: string
  key?: string
  to?: string
  toKey?: string
  labelSmall?: string
  disabled?: boolean
  disabledTab?: boolean
  className?: string
  icon?: string
  content?: ReactNode
}

interface IProps {
  tabs?: TabData[]
  activeTab?: string
  handleTabChange?: () => void
  tabProps: any
}

const TabComponent = (props: IProps) => {
  const tabs = props && Array.isArray(props.tabs) ? props.tabs : []
  const handleTabChange = props?.handleTabChange ?? ((t: TabData) => null)

  return (
    <div className="row">
      <div className="col-12 col-sm-12">
        <div className="tabs">
          <div className="p-0">
            <ul className="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">
              {Array.isArray(tabs) &&
                tabs.map((row, index) => {
                  if (row.disabled) {
                    return
                  }
                  let className =
                    row.key == props.activeTab ? 'active textDark nav-tabs-no-bckg' : ''
                  let label = row.label ? row.label : ''
                  // if (row.labelSmall) {
                  //   label = row.labelSmall
                  // }
                  let slash = row.to && row.to.slice(-1) !== '/' ? '/' : ''

                  let toKey = typeof row.toKey == 'string' ? row.toKey : row.key

                  return (
                    <li
                      key={index}
                      onClick={() => {
                        handleTabChange(row)
                      }}
                      className={`nav-item ${row.disabledTab ? 'disabled' : null}`}>
                      <StyledLink
                        to={`${row.to}${slash}${toKey}`}
                        className={`nav-link ${className} ${row.className} tabs-header py-3`}
                        data-toggle="pill"
                        role="tab"
                        label={row.label}
                        labelSmall={row.labelSmall}
                        icon={`${row.icon}`}
                      />
                    </li>
                  )
                })}
            </ul>
          </div>
          <div className="tabs_container bg-white">
            <div className="tab-content" id="custom-tabs-one-tabContent">
              {Array.isArray(tabs) &&
                tabs.map((row, index) => {
                  let className =
                    row.key === props.activeTab ? 'tab-pane fade show active' : 'tab-pane fade'

                  const { Content } = row
                  return (
                    <TabPanel key={index} className={`${className}`} id={row.key} role="tabpanel">
                      {row.key === props.activeTab ? <Content {...props.tabProps} /> : <></>}
                    </TabPanel>
                  )
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TabComponent

import styled from '@emotion/styled'
import { Message, Note } from 'store/types'

import MsgPanel from 'components/organisms/message/Panel'
import NotePanel from 'components/organisms/note/Panel'
import { OrderLogs } from 'components/organisms/log/OrderLogs'

const StyledLink = styled.span`
  margin-bottom: -3px !important;
  padding: 6px 0px 3px 0px !important;
  min-width: 70px;
  &.active {
    &:hover {
      border-bottom: 1px solid white !important;
      margin-bottom: -2px !important;
    }
  }
`

const StyledChatContainer = styled.div`
  .cs-message-list__scroll-wrapper {
    max-height: calc(100vh - 492px);
  }
`

interface IProps {
  orderId: number
  setSideTab: (t: string) => void
  sideTab: string
  messages: Message[]
  notes: Note[]
  addMessage: (m: string) => Promise<void>
  addNote: (n: string) => Promise<void>
  pinNote: (param: { uuid: string; pinned: boolean }) => Promise<void>
  updateMessageReadStatus: (params: {
    uuid: string
    readStatus: 'READ' | 'UNREAD'
  }) => Promise<void>
}

const MsgsNotes = ({
  setSideTab,
  sideTab,
  messages,
  notes,
  addMessage,
  addNote,
  updateMessageReadStatus,
  pinNote,
  orderId,
}: IProps) => {
  const unreadMessages = messages.reduce(
    (agg, curr) => (curr.sentBy === 'dme' || curr.readAt ? agg : agg + 1),
    0,
  )

  return (
    <div className="tabs">
      <ul className="nav nav-tabs nav-fill">
        <li
          className="nav-item"
          onClick={() => setSideTab('notes')}
          style={{ cursor: 'pointer', background: 'lightyellow' }}>
          <StyledLink
            className={`py-3 font-weight-bold nav-link tabs-header ${
              sideTab === 'notes' ? 'textDark active nav-tabs-no-bckg' : ''
            }`}>
            <i
              className="text-warning fa fa-sticky-note"
              style={{ marginLeft: -13, marginRight: 3 }}
            />{' '}
            Notes <br />
            <small style={{ fontSize: '0.8rem' }}>(internal)</small>
          </StyledLink>
        </li>
        <li className="nav-item" onClick={() => setSideTab('msgs')} style={{ cursor: 'pointer' }}>
          <StyledLink
            className={`py-3 font-weight-bold nav-link tabs-header ${
              sideTab === 'msgs' ? 'textDark active nav-tabs-no-bckg' : ''
            }`}>
            <i className="text-primary fa fa-comments" style={{ marginRight: 3 }} /> Messages{' '}
            {unreadMessages ? (
              <span
                className="badge bg-danger right"
                style={{ marginLeft: 6, borderRadius: '47%' }}>
                {unreadMessages}
              </span>
            ) : (
              <></>
            )}
            <br />
            <small style={{ fontSize: '0.8rem' }}>(to TestSmarter)</small>
          </StyledLink>
        </li>
        <li className="nav-item" onClick={() => setSideTab('log')} style={{ cursor: 'pointer' }}>
          <StyledLink
            className={`py-3 font-weight-bold nav-link tabs-header ${
              sideTab === 'log' ? 'textDark active nav-tabs-no-bckg' : ''
            }`}>
            Log <br />
            <small style={{ fontSize: '0.8rem' }}>(Order History)</small>
          </StyledLink>
        </li>
      </ul>
      <div className="tab-content tabs_container bg-white">
        <div className={`tab-pane fade ${sideTab === 'notes' ? 'show active' : ''}`}>
          <NotePanel userContext="DME" notes={notes} addNote={addNote} pinNote={pinNote} />
        </div>
        <StyledChatContainer className={`tab-pane fade ${sideTab === 'msgs' ? 'show active' : ''}`}>
          <MsgPanel
            userContext="DME"
            messages={messages}
            addMessage={addMessage}
            updateMessageReadStatus={updateMessageReadStatus}
          />
        </StyledChatContainer>
        <div className={`tab-pane mt-4 fade ${sideTab === 'log' ? 'show active' : ''}`}>
          <OrderLogs orderId={orderId} context="DME" visible={sideTab === 'log'} />
        </div>
      </div>
    </div>
  )
}

export default MsgsNotes

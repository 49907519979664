import { OrderVerifyStatus } from './OrderEdit'

export enum Panels {
  PATIENT = 0,
  PHYSICIAN = 1,
  INSURANCE = 2,
  DIAGNOSIS = 3,
  DOCUMENTS = 4,
}

type PanelStates = boolean[]

const combineAnd = (arr1: boolean[], arr2: boolean[]) => arr1.map((b, i) => b && arr2[i])

export const getDefaultPanelStates = (
  verification: OrderVerifyStatus,
  bucket?: string,
): PanelStates => {
  const panelStates = [true, true, true, true, true]

  if (verification.PATIENT !== 'pending') {
    panelStates[Panels.PATIENT] = false
  }
  if (verification.PHYSICIAN !== 'pending') {
    panelStates[Panels.PHYSICIAN] = false
  }
  if (verification.RX !== 'pending' && verification.AOB !== 'pending') {
    panelStates[Panels.DOCUMENTS] = false
  }
  if (
    verification.PAYER !== 'pending' &&
    verification.PAYER_2 !== 'pending' &&
    verification.PAYER_3 !== 'pending'
  ) {
    panelStates[Panels.INSURANCE] = false
  }

  if (bucket === 'BILLING_REVIEW') {
    return [false, false, true, false, true]
  } else if (['INS_VER', 'AUTHORIZATION', 'BILLING_HELD'].includes(bucket)) {
    return combineAnd(panelStates, [false, false, true, false, true])
  } else {
    return combineAnd(panelStates, [true, true, false, true, true])
  }
}

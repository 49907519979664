import * as generalHelper from "../../helpers/generalHelper";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ------------------------------------------------------------
export const getOrderPayers = createAsyncThunk(
  "getOrderPayers",
  async (params, { rejectWithValue }) => {
    try {
      // const data = await generalHelper.apiGet("/api/order-payer", params);
      // if (!data) {
      //     return rejectWithValue([]);
      // }
      // return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// ------------------------------------------------------------
export const getOrderPayer = createAsyncThunk(
  "getOrderPayer",
  async (params, { rejectWithValue }) => {
    try {
      // params.return_key = "one";
      // const data = await generalHelper.apiGet("/api/order-payer", params);
      // if (!data) {
      //     return rejectWithValue([]);
      // }
      // return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// ------------------------------------------------------------
export const updateOrderPayer = createAsyncThunk(
  "updateOrderPayer",
  async ({ id, params }, { rejectWithValue }) => {
    try {
      // const data = await generalHelper.apiPut(
      //     "/api/order-payer",
      //     id,
      //     params
      // );
      // if (!data) {
      //     return rejectWithValue([]);
      // }
      // return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// ------------------------------------------------------------
export const insertOrderPayer = createAsyncThunk(
  "insertOrderPayer",
  async (params, { rejectWithValue }) => {
    try {
      // const data = await generalHelper.apiPost(
      //     "/api/order-payer",
      //     params
      // );
      // if (!data) {
      //     return rejectWithValue([]);
      // }
      // return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// ------------------------------------------------------------
export const deleteOrderPayer = createAsyncThunk(
  "deleteOrderPayer",
  async ({ id, params }, { rejectWithValue }) => {
    try {
      const data = await generalHelper.apiDelete(
        "/api/order-payer",
        id,
        params
      );
      if (!data) {
        return rejectWithValue([]);
      }
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

// ------------------------------------------------------------
const initialState = {
  pending: false,
  rows: [],
  row: {},
  update: {},
  insert: {},
  delete: {},
  rejected: false,
};

// ------------------------------------------------------------
const localSlice = createSlice({
  name: "orderPayer",
  initialState,
  extraReducers: {
    [getOrderPayers.rejected]: (state, action) => {
      state.pending = false;
      state.rejected = true;
      state.rows = null;
    },
    [getOrderPayers.pending]: (state, action) => {
      state.pending = true;
      state.rejected = false;
      state.rows = null;
    },
    [getOrderPayers.fulfilled]: (state, action) => {
      state.pending = false;
      state.rejected = false;
      state.rows = action.payload;
    },
    [getOrderPayer.rejected]: (state, action) => {
      state.pending = false;
      state.rejected = true;
      state.row = null;
    },
    [getOrderPayer.pending]: (state, action) => {
      state.pending = true;
      state.rejected = false;
      state.row = null;
    },
    [getOrderPayer.fulfilled]: (state, action) => {
      state.pending = false;
      state.rejected = false;
      state.row = action.payload;
    },
    [updateOrderPayer.rejected]: (state, action) => {
      state.pending = false;
      state.rejected = true;
      state.update = null;
    },
    [updateOrderPayer.pending]: (state, action) => {
      state.pending = true;
      state.rejected = false;
      state.update = null;
    },
    [updateOrderPayer.fulfilled]: (state, action) => {
      state.pending = false;
      state.rejected = false;
      state.update = action.payload;
    },
    [insertOrderPayer.rejected]: (state, action) => {
      state.pending = false;
      state.rejected = true;
      state.update = null;
    },
    [insertOrderPayer.pending]: (state, action) => {
      state.pending = true;
      state.rejected = false;
      state.update = null;
    },
    [insertOrderPayer.fulfilled]: (state, action) => {
      state.pending = false;
      state.rejected = false;
      state.update = action.payload;
    },
    [deleteOrderPayer.rejected]: (state, action) => {
      state.pending = false;
      state.rejected = true;
      state.update = null;
    },
    [deleteOrderPayer.pending]: (state, action) => {
      state.pending = true;
      state.rejected = false;
      state.update = null;
    },
    [deleteOrderPayer.fulfilled]: (state, action) => {
      state.pending = false;
      state.rejected = false;
      state.update = action.payload;
    },
  },
});

export default localSlice.reducer;

/** @jsxImportSource @emotion/react */
import Button from 'components/atoms/Button'
import * as generalHelper from 'helpers/generalHelper'
import { Physician, PhysicianAddress } from 'store/types'
import { TooltipWrapper } from 'components/atoms/TooltipWrapper'

interface IProps {
  physician: Physician
  selectedAddress: PhysicianAddress
  imported: boolean
  verified: boolean
  setPhysician: (physician: Physician, location: PhysicianAddress) => void
  setReview: (idx: number) => void
  setAddLocation: () => void
  setEditAddressIdx: (idx: number) => void
  allowEdits: boolean
  rxPhysicianData: any
  rxPhysicianResult: any
}

export const CurrentSelection = ({
  physician,
  selectedAddress,
  verified,
  imported,
  setReview,
  setPhysician,
  setAddLocation,
  setEditAddressIdx,
  allowEdits,
  rxPhysicianData,
  rxPhysicianResult,
}: IProps) => {
  const physicianAddresses =
    physician.addresses?.filter(
      (address: PhysicianAddress) => address.addressPurpose !== 'MAILING',
    ) ?? []
  if (!physicianAddresses?.length) {
    physicianAddresses?.push(selectedAddress)
  }

  return (
    <div className="row border rounded p-2 justify-content-between">
      <div className="col-1 d-flex align-items-center ml-3">
        {verified ? (
          <i className="text-success fa fa-user-check" />
        ) : imported ? (
          <i className="text-success fa fa-user-md" />
        ) : (
          <i className="text-default fa fa-user-times" />
        )}
      </div>
      <TooltipWrapper
        text={() => (
          <div css={{ textAlign: 'left' }}>
            RX NPI: {rxPhysicianData?.NPI}
            <br />
            {rxPhysicianData?.ordering_provider?.ordering_provider_name}
            <br />
            {rxPhysicianData?.provider_address?.full_address}
            <br />
            fax: {rxPhysicianData?.ordering_provider_fax}
            <br />
            phone: {rxPhysicianData?.ordering_provider_phone_number}
            <br />
          </div>
        )}
        show={!rxPhysicianResult?.npi && !!rxPhysicianData}>
        <div
          className="col-2 d-flex align-items-center"
          css={{ color: !rxPhysicianResult?.npi && !!rxPhysicianData ? 'red' : undefined }}>
          {physician.npi}
        </div>
      </TooltipWrapper>
      <div className="col-2 d-flex align-items-center">
        {physician.firstName} {physician.lastName}
      </div>

      <div className="col-6">
        {physicianAddresses?.map((address: PhysicianAddress, idx: number) => (
          <div
            className={`py-1 mb-2 d-flex ${
              idx < physicianAddresses.length - 1 ? 'border-bottom' : ''
            }`}>
            <div
              css={{
                ...(selectedAddress?.uuid === address?.uuid && {
                  border: '1px solid #007bff',
                  borderRadius: '5px',
                  padding: '5px',
                  background: '#e9f5ff',
                }),
                flex: 1,
                display: 'flex',
              }}>
              {!!address ? (
                <div
                  className={address?.status === 'rejected' ? 'text-danger' : ''}
                  css={{
                    flex: 1,
                    ...(address?.status === 'rejected' && { textDecoration: 'line-through' }),
                  }}>
                  <div>
                    {address?.addressStreetLine1}
                    {', '}
                    {address?.addressStreetLine2 ?? ''}
                    <br />
                    {address?.addressCity}
                    {', '}
                    {address?.addressState} {generalHelper.formatZip(address?.addressZip)}
                  </div>
                  <div className="pb-2">
                    {address?.telephone && <strong>{generalHelper.t('Tel')}: </strong>}
                    {address?.telephone}
                    {address?.fax && (
                      <>
                        <br />
                        <strong>{generalHelper.t('Fax')}: </strong>
                      </>
                    )}
                    {address?.fax}{' '}
                  </div>
                  {address?.labComment && (
                    <div className="text-gray" css={{ fontStyle: 'italic' }}>
                      Lab Comment: {address.labComment}
                    </div>
                  )}
                  {address?.comment && (
                    <div className="text-gray" css={{ fontStyle: 'italic' }}>
                      Comment: {address.comment}
                    </div>
                  )}
                </div>
              ) : (
                <div className="text-danger d-flex justify-content-center align-items-center">
                  No address present
                </div>
              )}
              <div
                className="col text-right d-flex align-items-center justify-content-end"
                css={{ flex: 0 }}>
                {['imported', 'rejected', 'edited'].includes(address?.status) ? (
                  <Button
                    disabled={!allowEdits}
                    className="btn btn-sm btn-warning"
                    label="Review"
                    type="button"
                    onClick={() => setReview(idx)}
                  />
                ) : (
                  <>
                    <Button
                      label="Modify"
                      className="btn btn-sm btn-primary ml-1 mr-2"
                      css={{ minWidth: 60 }}
                      disabled={!allowEdits}
                      onClick={() => setEditAddressIdx(idx)}
                    />
                    <div css={{ minWidth: 60 }} className="d-flex justify-content-center">
                      {selectedAddress?.uuid === address?.uuid ? (
                        <div className="d-flex flex-column align-items-center">
                          <i className="fa fa-check text-success" css={{ fontSize: '1.8rem' }} />
                          <span className="text-success" css={{ fontSize: '0.8rem' }}>
                            Selected
                          </span>
                        </div>
                      ) : (
                        <Button
                          className="btn btn-sm btn-primary"
                          label="Select"
                          type="button"
                          disabled={!allowEdits}
                          onClick={() => {
                            setPhysician(physician, address)
                          }}
                        />
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        ))}
        {verified && (
          <div className="mt-2 pr-4 d-flex justify-content-end">
            <Button
              className="btn btn-sm btn-link mr-1"
              disabled={!allowEdits}
              onClick={setAddLocation}>
              ADD LOCATION
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

/** @jsxImportSource @emotion/react */
import { useEffect } from 'react'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import Button from 'react-bootstrap/Button'

import routes from '../../constants/routes'
import { Order } from 'store/types'
import { confirm } from 'components/molecules/Confirmation'
import { useRemoveDeviceMutation } from 'store/services/dmeOrder'
import { useLazyGetNightOwlShippingHistoryQuery } from 'store/services/labOrder'
import { NightOwlDeviceData } from 'ts-back-end/dist/ts-back-end/src/business/order/dto'
import { isUnderVerification, isVerificationDone } from 'helpers/orderStateHelpers'

interface IProps {
  order: Order
}

const OneShipTab = ({ order }: IProps) => {
  const navigate = useNavigate()
  const [removeDevice] = useRemoveDeviceMutation()
  const [getShippingHistory, { currentData: shippingHistory }] =
    useLazyGetNightOwlShippingHistoryQuery()

  const thirdPartyDevice = (order.thirdPartyDevice as NightOwlDeviceData) || {}
  const welcomeSent = Object.keys(order.mobileInvites || {})?.length > 0
  const activationCode = Object.values(order?.mobileInvites || {})
    ?.sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1))
    ?.pop()?.code
  const { timestamp, email } = order.mobileInvites?.[activationCode] || {}
  const deviceShipped = !!thirdPartyDevice?.trackingNumber
  const canRevertDevice = !isUnderVerification(order) && !isVerificationDone(order)

  useEffect(() => {
    if (deviceShipped) {
      getShippingHistory({ orderId: Number(order.id) })
    }
  }, [deviceShipped])

  return (
    <div className="container ">
      <div className="row px-4">
        {!welcomeSent && canRevertDevice && (
          <div className="mt-4 d-flex flex-column">
            <span css={{ marginBottom: 10 }}>
              <strong>TestSmarter has not started the OneShip process yet.</strong>
            </span>
            <Button
              onClick={() => {
                confirm('Device')
                  .then(async () => {
                    await removeDevice({ orderId: Number(order.id) }).unwrap()
                    navigate(`${routes.index}${routes.order.edit}${order.id}/summary`)
                  })
                  .catch(() => {})
              }}
              size="lg">
              Revert to Standard Device
            </Button>
          </div>
        )}
        {!welcomeSent && !canRevertDevice && (
          <div className="mt-4 d-flex">
            <i className="fa fa-spinner text-warning mr-2" css={{ fontSize: '1.5rem' }} />
            <h5>Waiting for TestSmarter to start the OneShip process.</h5>
          </div>
        )}
        {welcomeSent && (
          <div>
            <div className="mt-4 d-flex">
              <i className="fa fa-check text-success mr-2" css={{ fontSize: '1.5rem' }} />
              <h5>Welcome email has been sent to patient</h5>
            </div>
            <div>
              {moment(timestamp).format('lll')}: Sent to {email}, Activation Code:{' '}
              <strong css={{ letterSpacing: '4px', marginLeft: '5px' }}>{activationCode}</strong>
            </div>
          </div>
        )}
      </div>
      {deviceShipped && (
        <div className="row px-4">
          <div className="mt-4 d-flex w-100">
            <i className="fa fa-check text-success mr-2" css={{ fontSize: '1.5rem' }} />
            <h5>Device Shipped to Patient</h5>
          </div>
          <div className="mt-1 d-flex w-100">
            <strong className="mr-2">TestSmarter Device Serial #:</strong>{' '}
            {thirdPartyDevice?.serial}
          </div>
          <div className="mt-1 d-flex w-100">
            <strong className="mr-2">Tracking Number:</strong> {thirdPartyDevice?.trackingNumber}{' '}
            <a
              className="ml-2"
              href={thirdPartyDevice?.trackingUrlProvider}
              target="_blank"
              rel="noreferrer">
              Track Online
            </a>
          </div>
          <br />
          <br />
          <div className="col">
            <h5>Shipping Updates</h5>
            {!!shippingHistory ? (
              <ul css={{ listStyle: 'disc' }}>
                {shippingHistory?.tracking_history?.map((item, index) => (
                  <li key={index}>
                    <strong css={{ minWidth: 100 }}>
                      {moment(item.status_date).format('MM/DD/YYYY hh:mm a')}
                    </strong>
                    : {item.status_details} ({item?.location?.city}{' '}
                    {item?.location?.state ? `, ${item?.location?.state}` : ''})
                  </li>
                ))}
              </ul>
            ) : (
              <div>Tracking information will be displayed below as it becomes available.</div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default OneShipTab

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Loader from "../../../atoms/Loader";
import TitleLead from "../../../atoms/TitleLead";
import { getPatientOrders } from "../../../../store/slice/orderSlice";
import OrdersTable from "../../atoms/OrdersTable";

export default (props) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.row);
    const pastOrders = useSelector((state) => state.order.patientOrders);
    const { user_id } = useParams();

    React.useEffect(() => {
        dispatch(
            getPatientOrders({
                patient_id: user_id,
                add_join:
                    "status|patient[id,first_name,last_name,dob]|order_note|ticket|physician",
            })
        );
    }, []);

    return (
        <>
            <div className="container">
                <div className="col-sm-12 my-2">
                    <TitleLead
                        label="Route Orders"
                        className="lead text-info"
                        icon="fad fa-shipping-fast mr-2"
                    />
                    <hr />
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <OrdersTable orders={pastOrders} type="patient" />

                        <div className="row">&nbsp;</div>
                    </div>
                </div>
            </div>
        </>
    );
};

/** @jsxImportSource @emotion/react */
import moment from 'moment'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import styled from '@emotion/styled'

import TitleLead from 'components/atoms/TitleLead'
import Link from 'components/atoms/Link'
import * as generalHelper from 'helpers/generalHelper'
import { confirm } from 'components/molecules/Confirmation'
import { useRemoveFileMutation, useChangeFileCategoryMutation } from 'store/services/dmeOrder'
import { useAppSelector } from 'hooks/redux'
import { useDeviceForms } from 'hooks/useDeviceForms'

const deletableFileTypes = ['RX', 'AOB', 'OFFICE_NOTES', 'OTHER']

const StyledDropdownItem = styled(Dropdown.Item)`
  padding: 0.55rem 1rem;
`

interface IProps {
  files: any
  order: any
}

export const DocumentListing = ({ files, order }: IProps) => {
  const [removeFile] = useRemoveFileMutation()
  const [changeFileCategory] = useChangeFileCategoryMutation()
  const loading = useAppSelector((state) => state.loader.row)
  const rxUploaded = !!files.find((file) => file.uploadType === 'RX')
  const aobUploaded = !!files.find((file) => file.uploadType === 'AOB')
  const orderId = order?.id
  const { downloadForms, downloadDocument } = useDeviceForms(order)

  const { AOB, RX } = order?.progress || {}
  const { OFFICE_NOTES } = order?.state || {}
  const canModify = {
    RX: RX !== 'DONE',
    AOB: AOB !== 'DONE',
    OFFICE_NOTES: RX !== 'DONE', // this is on purpose because state of office notes should reflect how RX is handled
    OTHER: true,
    BRIGHTREE_OTHER: true,
    OTHER_BRIGHTREE: true,
  }

  const officeNotesIncluded = order?.testType === 'HST' && OFFICE_NOTES === 'DONE_RX'

  const changeFileType = (category: string, fileKey: string) => () => {
    changeFileCategory({ orderId: Number(orderId), fileKey, toUploadType: category })
  }

  const handleRemoveFile = ({ key }) => {
    if (key) {
      confirm('file').then(() => {
        removeFile({ orderId, fileKey: key })
      })
    }
  }

  return (
    <div className="row">
      <div className="col-sm-12 mt-3 mb-3">
        <TitleLead label="Uploaded files" />{' '}
      </div>
      <div className="col-sm-12">
        <div className="table-responsive" css={{ paddingBottom: '110px' }}>
          <table className="table table-hover table-sm">
            <thead>
              <tr>
                <th>{generalHelper.t('Type')}</th>
                <th>{generalHelper.t('Document Name')}</th>
                <th>{generalHelper.t('Date')}</th>
                <th>{generalHelper.t('Action')}</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(files) &&
                files.map((row, index) => (
                  <tr key={index}>
                    <td width="80">
                      {row?.uploadType?.replace(/_/g, ' ')}{' '}
                      {row?.uploadType === 'RX' && officeNotesIncluded && ' / Office Notes'}
                    </td>
                    <td>
                      {row?.url && (
                        <a href={row.url} target="_blank" rel="noreferrer">
                          {row.filename}
                        </a>
                      )}
                    </td>
                    <td>{`${moment(row.uploadDate).format('lll')}`}</td>
                    <td>
                      <div className="d-flex">
                        {deletableFileTypes.includes(row?.uploadType) &&
                          canModify[row?.uploadType] && (
                            <Link
                              to="#"
                              label="Remove"
                              className="btn btn-outline-danger btn-sm mr-2"
                              onClick={() => handleRemoveFile(row)}
                            />
                          )}
                        {['RX', 'AOB', 'OTHER', 'OFFICE_NOTES', 'OTHER_BRIGHTREE'].includes(
                          row?.uploadType,
                        ) &&
                          canModify[row?.uploadType] && (
                            <DropdownButton
                              disabled={loading}
                              title="Change Type"
                              size="sm"
                              variant="outline-primary">
                              <StyledDropdownItem
                                disabled={rxUploaded}
                                onClick={changeFileType('RX', row.key)}>
                                RX {rxUploaded && <span className="text-muted">(Uploaded)</span>}
                              </StyledDropdownItem>
                              {order.testType === 'HST' ? (
                                <StyledDropdownItem
                                  disabled={row.uploadType === 'OFFICE_NOTES'}
                                  onClick={changeFileType('OFFICE_NOTES', row.key)}>
                                  Office Notes
                                </StyledDropdownItem>
                              ) : (
                                <StyledDropdownItem
                                  disabled={aobUploaded}
                                  onClick={changeFileType('AOB', row.key)}>
                                  AOB{' '}
                                  {aobUploaded && <span className="text-muted">(Uploaded)</span>}{' '}
                                </StyledDropdownItem>
                              )}

                              <StyledDropdownItem onClick={changeFileType('OTHER', row.key)}>
                                Other
                              </StyledDropdownItem>
                            </DropdownButton>
                          )}
                      </div>
                    </td>
                  </tr>
                ))}
              {!!downloadForms && (
                <>
                  <tr>
                    <td>Instructions</td>
                    <td>
                      <Link
                        to="#"
                        label="Patient Instructions - English"
                        onClick={downloadDocument(downloadForms?.keyEn!)}
                      />
                    </td>
                    <td />
                    <td>
                      <Link
                        to="#"
                        label="Download"
                        onClick={downloadDocument(downloadForms?.keyEn!)}
                      />
                    </td>
                  </tr>
                  {downloadForms?.keyEs && (
                    <tr>
                      <td>Instructions</td>
                      <td>
                        <Link
                          to="#"
                          label="Patient Instructions - Spanish"
                          onClick={downloadDocument(downloadForms?.keyEs!)}
                        />
                      </td>
                      <td />
                      <td>
                        <Link
                          to="#"
                          label="Download"
                          onClick={downloadDocument(downloadForms?.keyEs!)}
                        />
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </table>
          {!files?.length && (
            <div className="card">
              <div className="card-body text-center font-italic text-muted">
                {generalHelper.t('No Files have been uploaded to this order')}.
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

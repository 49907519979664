import { SyntheticEvent, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

import TextArea from 'components/atoms/Textarea'

interface IProps {
  show?: boolean
  onClose: () => void
  onSubmit: (message: string) => void
  context?: 'DME' | 'LAB'
  cannotCancelReason?: string
}

export const CancelDialog = ({
  show,
  onClose,
  onSubmit,
  context = 'LAB',
  cannotCancelReason,
}: IProps) => {
  const [message, setMessage] = useState('')

  return (
    <Modal centered show={!!show} onHide={onClose}>
      <Modal.Header>
        <Modal.Title>
          {cannotCancelReason ? 'ORDER CANNOT BE CANCELLED' : 'CANCEL ORDER?'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {cannotCancelReason ? (
          <div>{cannotCancelReason}</div>
        ) : (
          <>
            <span className="text-bold text-gray">Attention</span>
            <br />
            <br />
            Are you sure you want to cancel this order?
            <br />
            <br />
            Please enter a short summary of the reason below that will be used to send a message to
            the
            {context === 'LAB' ? ' DME Office' : ' Lab'}. <br />
            <br />
            <TextArea
              handleChange={(e: SyntheticEvent) => setMessage(e.target.value)}
              name="cancelReason"
              label="Message Notes"
              value={message}
              rows={5}
              labelClassName="text-gray"
            />
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        {cannotCancelReason ? (
          <Button variant="primary" onClick={onClose}>
            OK
          </Button>
        ) : (
          <>
            <Button variant="outline-secondary" onClick={onClose}>
              No
            </Button>
            <Button
              autoFocus
              variant="primary"
              disabled={context === 'DME' && !message}
              onClick={() => {
                setMessage('')
                onSubmit(message)
              }}>
              Yes
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  )
}

import { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'

import ModifyPhysicianModal from 'components/organisms/physician/ModifyPhysicianModal'
import * as generalHelper from 'helpers/generalHelper'

interface IProps {
  icon: string
  title: string
  subtitle?: string | ReactNode
  headerCenterContent?: ReactNode
  content: ReactNode
  headerRightContent?: ReactNode
  breadcrumb?: any[]
  headerClassName?: string
  subtitleClassName?: string
}

const DashboardContent = (props: IProps) => {
  const {
    title,
    subtitle,
    icon,
    content,
    breadcrumb,
    headerClassName,
    subtitleClassName = '',
    headerCenterContent,
    headerRightContent,
  } = props
  return (
    <div>
      <ModifyPhysicianModal id="modify_physician_modal" modalSize="modal-md" />
      <div className={`content-header order-header ${headerClassName}`}>
        <div className="container-fluid pt-1">
          <div className="row mb-2">
            <div className="col-sm-5 my-auto">
              <h4 className="m-0">
                {icon && <i className={`${icon} mr-2`} style={{ fontSize: '1.3rem' }}></i>}{' '}
                {generalHelper.t(title)}
              </h4>
            </div>
            <div className="col-sm-2 my-auto d-flex justify-content-center">
              {headerCenterContent}
            </div>
            <div className="col-sm-5 my-auto">
              {headerRightContent ? (
                headerRightContent
              ) : (
                <ol className="breadcrumb float-sm-right font80p">
                  <li className="breadcrumb-item">
                    <Link to="/" className="breadcrumb-item">
                      {generalHelper.t('Home')}
                    </Link>
                  </li>
                  {Array.isArray(breadcrumb) &&
                    breadcrumb.map((row, index) => {
                      return (
                        <li key={index} className="breadcrumb-item active">
                          {row.to && (
                            <Link to={row.to} className="breadcrumb-item-option">
                              {generalHelper.t(row.label)}
                            </Link>
                          )}
                          {!row.to && generalHelper.t(row.label)}
                        </li>
                      )
                    })}
                </ol>
              )}
            </div>
          </div>
        </div>
      </div>
      {subtitle && (
        <div className={`${subtitleClassName} container-fluid ml-0 mr-0 bg-white`}>
          <div className={`row bg-white`}>
            <div className="col mx-0 p-0">
              <div className="dashboard_header_subtitle pl-3" style={{ minHeight: '65px' }}>
                <div className="holder w-100">
                  <h3 className={`mb-0`} style={{ fontSize: '1.5rem' }}>
                    {subtitle}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="content">
        <div className="container-fluid">
          <div className="row justify-content-md-center">
            <div className="col-lg-12 pt-2">{content}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashboardContent

const Respironics920MPlus = () => {
  return (
    <ul className="text-left">
      <li>
        <span>1.</span> Be sure the 920M Plus cable is connected securely to
        your computer.
      </li>
      <li className="highlight">
        <span>2.</span> <small>IMPORTANT:</small> Press the button below to
        begin download <strong>before</strong> powering on the device.
      </li>
      <li>
        <span>3.</span> Press <strong>both</strong> the{' '}
        <i className="fa fa-power-off"></i> and <i className="fa fa-minus"></i>{' '}
        buttons at the same time until the display flashes "888 888".
      </li>
    </ul>
  )
}

export default Respironics920MPlus

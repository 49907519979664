import _ from 'lodash'
import * as Yup from 'yup'

export const isDate = (str: string) => str && str.trim().length > 9 && !isNaN(Date.parse(str))
export const isEmpty = (str: string) => _.isEmpty(str)

export const phoneRegExp = /^\+?1?[2-9][0-9]{2}[2-9][0-9]{6}$/

export const phoneValidation = Yup.string()
  .transform((val) => val.replace(/[^0-9+]/g, ''))
  .matches(phoneRegExp, 'Phone number is not valid')

export const requiredPhoneValidation = phoneValidation.required('Required')

export const userValidation = (domains: { domain: string; sso: boolean }[]) =>
  Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    primaryOfficeId: Yup.number().required('Required'),
    officePhone: phoneValidation,
    directPhone: phoneValidation,
    email: Yup.string()
      .required('Required')
      .email('Invalid email')
      .test('email', 'Not a valid company domain', (value) => {
        if (!domains || !domains.length) return true
        const emailDomain = value?.split('@')[1]
        return !!emailDomain && domains.find(({ domain }) => domain === emailDomain)
      }),
    role: Yup.string().optional(),
  })

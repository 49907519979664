export const formatPhoneNumber = (str: string, hideCc?: boolean): string => {

  // Remove all non-numeric characters
  let phone: RegExpMatchArray | string | null = String(str).replace(/\D/g, '');

  // Remove prefix / country code
  phone = phone.replace(/^1+/, '');

  // Validate phone number
  if ((phone = phone.match(/^([2-9][0-9]{2})([2-9][0-9]{2})([0-9]{4})$/))) {

    // Return nicely formatted, e.g. "(555) 555-1212"
    str = `(${phone[1]}) ${phone[2]}-${phone[3]}`;

    // Optionally, with or without country code
    return (hideCc ? str : `+1 ${str}`);
  }

  // It is not a valid number; return as it was
  return str;
}

export const formatZipcode = (str: string): string => {

  // Remove all non-numeric characters
  let zip: string = String(str).replace(/\D/g, '');

  // Validate zip code
  if (zip.length === 9) {
    // Return nicely formatted ZIP+4, e.g. "12345-6789"
    return `${zip.slice(0, 5)}-${zip.slice(5)}`;
  }
  if (zip.length === 5) {
    // Return ZIP as-is
    return zip;
  }

  // It is not a valid ZIP code; return as it was
  return str;
}


// This returns the isoDate in the user's local time zone
export const toLocalDateString = (isoDateInput: string) => {
  const date = new Date(isoDateInput)
  const dateStr = date.toLocaleString('sv')

  return dateStr.slice(0, 20).replace(' ', 'T')
}

export const isString = value => {
  return typeof value === 'string'
}

export const isArray = value => {
  return Array.isArray(value)
}

export const isObject = value => {
  return typeof value === 'object' && !isArray(value) && value !== null
}

export const compareVersions = (a, b) => {
  const pa = a.split('.')
  const pb = b.split('.')

  for (let i = 0; i < 3; i++) {
    const na = Number(pa[i])
    const nb = Number(pb[i])
    if (na > nb) return true
    if (nb > na) return false
    if (!isNaN(na) && isNaN(nb)) return true
    if (isNaN(na) && !isNaN(nb)) return false
  }

  return true
}

export const parseSerialPort = (port, type) => {
  let label = null
  let match = null

  const { manufacturer } = port

  if (manufacturer) {
    if (
      manufacturer.match(/\(standard port types\)/i) &&
      ['COM1', 'COM2'].indexOf(port.path.toUpperCase()) >= 0
    ) {
      // this is a standard serial port, so we have no way to know what type
      // of device is connected
      label = 'Standard Serial Port'
      match = 'rs232'
    } else if (manufacturer.match(/(oxford|semiconductor|gigaware)/i)) {
      // these are some loose pattern matches for various serial adapters we recognize
      label = 'External Serial Adapter'
      match = 'rs232'
    } else if (manufacturer.match(/ftdi/i)) {
      // we assume any device using the FTDI chipset is either a
      // Nonin/Respironics 920M or the Masimo Rad-97 because those
      // are the only devices we support which use that chipset
      label = 'Nonin/Respironics 920M or Masimo Rad-97'
      match = 'ftdi'
    } else if (manufacturer.match(/microsoft/i)) {
      // we assume any device using the Microsoft chipset is a WristOx
      // because that's the only device we support that uses that chipset
      label = 'Nonin WristOx 3150'
      match = 'microsoft'
    } else if (manufacturer.match(/(silicon|integrisys)/i)) {
      // we assume any device using a Silicon Laboratories chipset is Contec
      // because those are the only devices we support using that chipset...
      // the only way to differentiate whether it's a TSOT is if the user
      // chose a TSOT from the device selection screen
      label = 'tsot' === type ? 'TestSmarter OneTrip' : 'C50F / C50i'
      match = 'silabs'
    }
  }

  if (!label) {
    label = 'Unrecognized USB device'
    match = null
  }

  return { label, match, path: port.path }
}

export const getPortByType = type => {
  switch (type) {
    case '920m':
    case '920mp':
    case 'n2500':
    case 'n8500':
    case 'n3150':
    case 'rad97':
      return 'ftdi'
    case 'c50f':
    case 'c50i':
    case 'tsot':
      return 'silabs'
    case 'n7500':
    case 'rad8':
      return 'rs232'
  }
}

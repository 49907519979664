import * as generalHelper from '../../helpers/generalHelper'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { IdAndParam, KeyVal } from '../types'

// ------------------------------------------------------------
export const getOrderToken = createAsyncThunk(
  'getOrderToken',
  async (params, { rejectWithValue }) => {
    try {
      // const data = await generalHelper.apiGet('/api/order-get-token', params)
      // if (!data) {
      //   return rejectWithValue([])
      // }
      // return data
    } catch (error) {
      console.log('error', error)
    }
  }
)

// ------------------------------------------------------------
export const getPatientOrders = createAsyncThunk(
  'getPatientOrders',
  async (params, { rejectWithValue }) => {
    try {
      // const data = await generalHelper.apiGet('/api/order', params)
      // if (!data) {
      //   return rejectWithValue([])
      // }
      // return data
    } catch (error) {
      console.log('error', error)
    }
  }
)

// ------------------------------------------------------------
export const getOrderByDeviceStatus = createAsyncThunk(
  'getOrderByDeviceStatus',
  async (params: KeyVal, { rejectWithValue }) => {
    try {
      // params._where_preset = 'order_by_device_status'
      // const data = await generalHelper.apiGet('/api/order', params)
      // if (!data) {
      //   return rejectWithValue([])
      // }
      // return data
    } catch (error) {
      console.log('error', error)
    }
  }
)

// ------------------------------------------------------------
export const getOrders = createAsyncThunk(
  'getOrders',
  async (params, { rejectWithValue }) => {
    try {
      const data = await generalHelper.apiGet('/api/order', params)
      if (!data) {
        return rejectWithValue([])
      }
      return data
    } catch (error) {
      console.log('error', error)
    }
  }
)

// ------------------------------------------------------------
export const getOrder = createAsyncThunk(
  'getOrder',
  async (params: KeyVal, { rejectWithValue }) => {
    try {
      params.return_key = 'one'
      const data = await generalHelper.apiGet('/api/order', params)
      if (!data) {
        return rejectWithValue([])
      }
      return data
    } catch (error) {
      console.log('error', error)
    }
  }
)

// ------------------------------------------------------------
export const updateOrder = createAsyncThunk(
  'updateOrder',
  async ({ id, params }: IdAndParam, { rejectWithValue }) => {
    try {
      const data = await generalHelper.apiPut('/api/order', id, params)
      if (!data) {
        return rejectWithValue([])
      }
      return data
    } catch (error) {
      console.log('error', error)
    }
  }
)

// ------------------------------------------------------------
export const insertOrder = createAsyncThunk(
  'insertOrder',
  async (params, { rejectWithValue }) => {
    try {
      const data = await generalHelper.apiPost('/api/order', params)
      if (!data) {
        return rejectWithValue([])
      }
      return data
    } catch (error) {
      console.log('error', error)
    }
  }
)

// ------------------------------------------------------------
export const deleteOrder = createAsyncThunk(
  'deleteOrder',
  async ({ id, params }: IdAndParam, { rejectWithValue }) => {
    try {
      const data = await generalHelper.apiDelete('/api/order', id, params)
      if (!data) {
        return rejectWithValue([])
      }
      return data
    } catch (error) {
      console.log('error', error)
    }
  }
)

// ------------------------------------------------------------

interface OrderState {
  pending: boolean
  rows: any[] | null
  row: any
  update: any
  insert: any
  delete: any
  rejected: boolean
  orderByDeviceStatus: any[]
  patientOrders: any[]
  token: string | null
}

const initialState: OrderState = {
  pending: false,
  rows: null,
  row: null,
  update: {},
  insert: {},
  delete: {},
  rejected: false,
  orderByDeviceStatus: [],
  patientOrders: [],
  token: null,
}

// ------------------------------------------------------------
const localSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getOrders.rejected, (state, action) => {
        state.pending = false
        state.rejected = true
        state.rows = null
      })
      .addCase(getOrders.pending, (state, action) => {
        state.pending = true
        state.rejected = false
        state.rows = null
      })
      .addCase(getOrders.fulfilled, (state, action) => {
        state.pending = false
        state.rejected = false
        state.rows = action.payload
      })
      .addCase(getOrder.rejected, (state, action) => {
        state.pending = false
        state.rejected = true
        state.row = null
      })
      .addCase(getOrder.pending, (state, action) => {
        state.pending = true
        state.rejected = false
        state.row = null
      })
      .addCase(getOrder.fulfilled, (state, action) => {
        state.pending = false
        state.rejected = false
        state.row = action.payload
      })
      .addCase(updateOrder.rejected, (state, action) => {
        state.pending = false
        state.rejected = true
        state.rows = null
      })
      .addCase(updateOrder.pending, (state, action) => {
        state.pending = true
        state.rejected = false
        state.rows = null
      })
      .addCase(updateOrder.fulfilled, (state, action) => {
        state.pending = false
        state.rejected = false
        state.update = action.payload
      })
      .addCase(insertOrder.rejected, (state, action) => {
        state.pending = false
        state.rejected = true
        state.rows = null
      })
      .addCase(insertOrder.pending, (state, action) => {
        state.pending = true
        state.rejected = false
        state.rows = null
      })
      .addCase(insertOrder.fulfilled, (state, action) => {
        state.pending = false
        state.rejected = false
        state.insert = action.payload
      })
      .addCase(deleteOrder.rejected, (state, action) => {
        state.pending = false
        state.rejected = true
        state.rows = null
      })
      .addCase(deleteOrder.pending, (state, action) => {
        state.pending = true
        state.rejected = false
        state.rows = null
      })
      .addCase(deleteOrder.fulfilled, (state, action) => {
        state.pending = false
        state.rejected = false
        state.delete = action.payload
      })
      .addCase(getOrderByDeviceStatus.rejected, (state, action) => {
        state.pending = false
        state.rejected = true
        state.rows = null
      })
      .addCase(getOrderByDeviceStatus.pending, (state, action) => {
        state.pending = true
        state.rejected = false
        state.rows = null
      })
      .addCase(getOrderByDeviceStatus.fulfilled, (state, action) => {
        state.pending = false
        state.rejected = false
        state.orderByDeviceStatus = action.payload
      })
      .addCase(getPatientOrders.rejected, (state, action) => {
        state.pending = false
        state.rejected = true
        state.rows = null
      })
      .addCase(getPatientOrders.pending, (state, action) => {
        state.pending = true
        state.rejected = false
        state.rows = null
      })
      .addCase(getPatientOrders.fulfilled, (state, action) => {
        state.pending = false
        state.rejected = false
        state.patientOrders = action.payload
      })
      .addCase(getOrderToken.fulfilled, (state, action) => {
        state.pending = false
        state.rejected = false
        state.token =
          action.payload && action.payload._token ? action.payload._token : null
      })
      .addCase('logout/fulfilled', (state, action) => initialState)
  },
})

export default localSlice.reducer

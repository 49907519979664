import { useMemo } from 'react'

type Error = {
  title: string
  content: string[]
  instructions?: JSX.Element[]
}

interface Errors {
  [key: string]: Error
}

const errors: Errors = {
  ServiceError: {
    title: 'Something went wrong',
    content: [
      'An error prevented us from communicating with the TestSmarter Reader App on your computer:',
    ],
  },
  IncompleteDataError: {
    title: 'There was not enough data on the oximeter',
    content: [
      'We were unable to collect all of the data from the oximeter.',
      "Please be sure the oximeter is connected to one of your computer's USB or serial ports and try again.",
      'If you plugged the device into a blue USB 3.0 port, try moving your cable to a black USB 2.0 port.',
    ],
  },

  NoninNormalModeError: {
    title: 'The oximeter is in the wrong mode',
    content: [
      "It looks like you've powered the oximeter device on, but it is not in download mode.",
      'Please read the instructions for placing the oximeter in download mode and try again.',
    ],
  },
  SocketError: {
    title: 'Connection failed',
    content: [
      'We ran into a problem while communicating with the oximeter.  Please make sure',
      'only one window is open and attempting to connect to the device reader.',
    ],
  },
  UploadError: {
    title: 'An error has occurred',
    content: [
      'We were unable to upload the data to our server.',
      `Please contact support for assistance.`,
    ],
  },
  UploadRejected: {
    title: 'Upload Rejected',
    content: [],
  },

  DeviceReadError: {
    title: 'There was a problem reading from the device',
    content: [],
  },

  DeviceTimeoutError: {
    title: 'Connection failed',
    content: [
      'We ran into a problem while communicating with the oximeter.',
      "Please be sure the oximeter is connected to one of your computer's USB or serial ports and try again.",
      'If you plugged the device into a blue USB 3.0 port, try moving your cable to a black USB 2.0 port.',
    ],
  },

  NoResponseError: {
    title: 'An error has occurred',
    content: [
      "We can't communicate with the TS Reader App",
      `Please contact support for assistance.`,
    ],
    instructions: [
      <li>
        <span>1</span> If you're new to TestSmarter or don't have our app installed on your
        computer,{' '}
        <a href="https://testsmarter.net/download/TestSmarterReaderSetup32.exe">
          click here to download and install the TS Reader App
        </a>
        .
      </li>,
      <li>
        <span>1</span> If you're new to TestSmarter or don't have our app installed on your
        computer,{' '}
        <a href="https://testsmarter.net/download/TestSmarterReaderSetup64.exe">
          click here to download and install the TS Reader App
        </a>
        .
      </li>,
      <li>
        <span>2</span> If the TS Reader App has been previously installed, please check that the app
        is running. You should see the icon in your system tray near the date and time at the bottom
        right of your screen: &nbsp;{' '}
        <img
          src="/apps/reader/dev/web/images/reader-icon-32.png"
          style="margin-right:15px;vertical-align:middle;"
          alt=""
        />
      </li>,
      <li>
        <span>3</span> If you don't see the icon in your tray, but you know the app is installed,
        restart the app via the Start Menu or restart your computer.
      </li>,
    ],
  },

  ClearMemoryFailedError: {
    title: 'Reset Failed',
    content: [
      'There was a problem resetting the oximeter, and you will need to reset the device manually.',
      "Please follow the appropriate owner/operator's manual for this device to complete the reset.",
    ],
  },

  ResetError: {
    title: 'Reset Failed',
    content: [],
  },

  UnknownError: {
    title: 'Something Went Wrong',
    content: [
      'There was an unknown error',
      'Please reload the page or try again. If the error persist please contact support for assistance.',
    ],
  },
}

const ErrorMessage = ({ type, message }: { type: string; message: string }) => {
  const error = useMemo(() => errors[type] || errors['UnknownError'], [type])

  return message ? (
    <div className="px-3 py-2">
      <div className="info-box bg-danger" style={{ minHeight: 20 }}>
        <div className="info-box-content">Error: {error.title}</div>
      </div>

      {error.content.map((line: string, index: number) => (
        <p key={`error-${type}-${index}`}>{line}</p>
      ))}

      {typeof message === 'string' && <p className="my-2">{message}</p>}
    </div>
  ) : null
}

export default ErrorMessage

/** @jsxImportSource @emotion/react */
import { useState } from 'react'

import { useApproveAddressMutation, useRejectAddressMutation } from 'store/services/labPhysician'
import { useGetPhysicianHistoryQuery } from 'store/services/labPhysician'
import { Order, PhysicianAddress, Physician } from 'store/types'
import { CurrentSelection } from './CurrentSelection'
import { ReviewPhysician } from './ReviewPhysician'
import { AddEditLocation } from './AddEditLocation'
import { useRecognitionState } from 'helpers/rxRecognitionHelper'

interface IProps {
  order: Order
  updatePhysician: (physician: Partial<Physician>, location: PhysicianAddress) => Promise<void>
  setPhysician: (physician: Physician, location: PhysicianAddress) => void
  allowEdits: boolean
}

enum SectionState {
  CurrentSelection,
  PhysicianReview,
  AddLocation,
  EditLocation,
}

export const PhysicianReview = ({ order, setPhysician, updatePhysician, allowEdits }: IProps) => {
  const [state, setState] = useState<SectionState>(SectionState.CurrentSelection)
  const [editAddressIdx, setEditAddressIdx] = useState<number | null>(null)
  const [approve] = useApproveAddressMutation()
  const [reject] = useRejectAddressMutation()
  const { data: physicianHistory, refetch: refetchPhysicianHistory } = useGetPhysicianHistoryQuery({
    id: order.physician.id,
  })
  const { rxPhysicianData, rxPhysicianResult } = useRecognitionState(order)

  const { physician, id: orderId } = order
  const verified =
    physician?.addresses?.some((a) => a.status === 'approved') ||
    order?.physicianAddress?.status === 'approved'
  const imported =
    physician?.addresses?.some((a) => ['imported', 'edited'].includes(a.status)) ||
    order?.physicianAddress?.status === 'imported'

  const onSubmitPhysician = async (
    physician: Partial<Physician>,
    address: PhysicianAddress,
    revertPanelState = true,
  ) => {
    await updatePhysician(physician, address)
    if (revertPanelState) setState(SectionState.CurrentSelection)
    refetchPhysicianHistory()
  }

  return (
    <div>
      {state === SectionState.CurrentSelection && (
        <CurrentSelection
          allowEdits={allowEdits}
          physician={physician}
          rxPhysicianData={rxPhysicianData}
          rxPhysicianResult={rxPhysicianResult}
          selectedAddress={order.physicianAddress}
          verified={verified}
          imported={imported}
          setPhysician={setPhysician}
          setReview={(idx) => {
            setEditAddressIdx(idx)
            setState(SectionState.PhysicianReview)
          }}
          setAddLocation={() => setState(SectionState.AddLocation)}
          setEditAddressIdx={(idx) => {
            setEditAddressIdx(idx)
            setState(SectionState.EditLocation)
          }}
        />
      )}
      {state === SectionState.PhysicianReview && (
        <ReviewPhysician
          physician={physician}
          physicianAddress={physician?.addresses?.[editAddressIdx!]}
          onCancel={() => setState(SectionState.CurrentSelection)}
          onSubmit={onSubmitPhysician}
          onApprove={() => {
            approve({
              orderId,
              id: physician.id,
              uuid: physician?.addresses?.[editAddressIdx!].uuid,
            })
            setState(SectionState.CurrentSelection)
          }}
          onReject={() => {
            reject({
              orderId,
              id: physician.id,
              uuid: physician?.addresses?.[editAddressIdx!].uuid,
            })
            setState(SectionState.CurrentSelection)
          }}
          physicianHistory={physicianHistory}
        />
      )}
      {state === SectionState.AddLocation && (
        <AddEditLocation
          physician={physician}
          onCancel={() => setState(SectionState.CurrentSelection)}
          onSubmit={onSubmitPhysician}
        />
      )}
      {state === SectionState.EditLocation && editAddressIdx !== null && (
        <AddEditLocation
          physician={physician}
          physicianAddress={physician?.addresses?.[editAddressIdx] || order.physicianAddress}
          onCancel={() => setState(SectionState.CurrentSelection)}
          onSubmit={onSubmitPhysician}
          physicianHistory={physicianHistory}
        />
      )}
    </div>
  )
}

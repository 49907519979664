import Link from './Link'
import * as generalHelper from '../../helpers/generalHelper'

interface IProps {
  label?: string
  to: string
  onClick?: () => void
}

const ButtonNewComponent = (props: IProps) => {
  const label = props.label ? props.label : 'New'
  return (
    <Link
      to={props.to ? props.to : '#'}
      className="btn btn-primary"
      icon="fa fa-plus-circle"
      label={generalHelper.t(label)}
      onClick={props.onClick}
    />
  )
}

export default ButtonNewComponent

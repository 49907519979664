/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { useParams, useNavigate } from 'react-router-dom'
import Accordion from 'react-bootstrap/Accordion'
import Button from 'react-bootstrap/Button'
import { skipToken } from '@reduxjs/toolkit/query/react'

import DashboardContent from '../atoms/DashboardContent'
import { CompanyForm, CompanyFormValues } from './CompanyForm'
import { EmailDomains } from './EmailDomains'
import { Regions } from './Regions'
import { KeyPeople } from './KeyPeople'
import { Offices } from './Offices'
import {
  useCreateCompanyMutation,
  useGetCompanyQuery,
  useUpdateCompanyMutation,
} from 'store/services/office'
import routes from '../constants/routes'
import { getDefaultPanelStates, Panels } from './helpers'
import { KeyPerson } from 'ts-back-end/src/business/company/dto/company.dto'

export type CompanyCollections = {
  regions?: string[]
  companyDomains?: { domain: string; sso: boolean }[] | null
  keyPersons?: KeyPerson[] | null
}

const StyledAccordion = styled(Accordion)`
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: white;

  .accordion-button {
    background: white;
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
    padding-top: 8px;
    padding-bottom: 8px;
    min-height: 50px;

    > div {
      margin-left: 20px;
      color: #444;
    }
  }

  .accordion-button::after {
    left: 10px;
    top: 14px;
    position: absolute;
    filter: grayscale(1);
  }
`

const disableAccordion = {
  '& .accordion-button': {
    pointerEvents: 'none',
    opacity: 0.5,
    background: '#f5f5f5',
  },
}

const CompanyUpsert = () => {
  const navigate = useNavigate()
  const { companyId } = useParams()
  const [panelStates, setPanelStates] = useState<boolean[]>(getDefaultPanelStates(companyId))
  const [createCompany] = useCreateCompanyMutation()
  const [updateCompany] = useUpdateCompanyMutation()
  const { data: companyData } = useGetCompanyQuery(
    companyId ? { id: Number(companyId) } : skipToken,
  )

  const onCreateCompany = async (values: CompanyFormValues) => {
    const company = await createCompany(values).unwrap()
    navigate(`${routes.index}${routes.company.root}${routes.upsert}${company.id}`)
  }
  const onUpdateCompany = async (values: CompanyFormValues) => {
    return updateCompany(values)
  }

  const onUpdateCompanyCollection = async (values: Partial<CompanyCollections>) => {
    if (companyId) {
      return updateCompany({ id: Number(companyId), ...values }).unwrap()
    }
  }

  useEffect(() => {
    if (companyId) {
      setPanelStates(getDefaultPanelStates(companyId))
    }
  }, [companyId])

  return (
    <div>
      <DashboardContent
        icon="fas fa-building"
        title={companyId ? 'Edit Company' : 'Create Company'}
        breadcrumb={[
          {
            label: 'Companies',
            to: `${routes.index}${routes.company.root}`,
          },
          { label: companyId ? 'Edit' : 'New' },
        ]}
        content={
          <div className="card-body row">
            <div className="col-md-12 col-lg-6 mb-3">
              <StyledAccordion
                defaultActiveKey="0"
                activeKey={panelStates[Panels.COMPANY_INFO] ? '0' : null}
                onSelect={() =>
                  setPanelStates((p) => p.map((i, idx) => (idx === Panels.COMPANY_INFO ? !i : i)))
                }>
                <Accordion.Item eventKey="0">
                  <Accordion.Button>
                    <div className="col-sm-6">COMPANY INFORMATION</div>
                  </Accordion.Button>
                  <Accordion.Collapse eventKey="0">
                    <CompanyForm
                      onCreateCompany={onCreateCompany}
                      onUpdateCompany={onUpdateCompany}
                      initialValues={companyData}
                    />
                  </Accordion.Collapse>
                </Accordion.Item>
              </StyledAccordion>
            </div>
            <div className="col-md-12 col-lg-6 mb-3">
              <StyledAccordion
                defaultActiveKey="0"
                activeKey={panelStates[Panels.EMAIL] ? '0' : '1'}
                onSelect={() =>
                  companyId &&
                  setPanelStates((p) => p.map((i, idx) => (idx === Panels.EMAIL ? !i : i)))
                }>
                <Accordion.Item eventKey="0">
                  <Accordion.Header css={!companyId ? disableAccordion : {}}>
                    <div className="col-sm-6">EMAIL</div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <EmailDomains
                      domains={companyData?.companyDomains}
                      onUpdateCompanyCollection={onUpdateCompanyCollection}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              </StyledAccordion>
            </div>
            <div className="col-md-12 col-lg-6 mb-3">
              <StyledAccordion
                defaultActiveKey="0"
                activeKey={panelStates[Panels.KEY_PEOPLE] ? '0' : '1'}
                onSelect={() =>
                  setPanelStates((p) => p.map((i, idx) => (idx === Panels.KEY_PEOPLE ? !i : i)))
                }>
                <Accordion.Item eventKey="0">
                  <Accordion.Header css={!companyId ? disableAccordion : {}}>
                    <div className="col-sm-6">KEY PEOPLE</div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <KeyPeople
                      keyPeople={companyData?.keyPersons}
                      onUpdateCompanyCollection={onUpdateCompanyCollection}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              </StyledAccordion>
            </div>
            <div className="col-md-12 col-lg-6 mb-3">
              <StyledAccordion
                defaultActiveKey="0"
                activeKey={panelStates[Panels.REGIONS] ? '0' : '1'}
                onSelect={() =>
                  setPanelStates((p) => p.map((i, idx) => (idx === Panels.REGIONS ? !i : i)))
                }>
                <Accordion.Item eventKey="0">
                  <Accordion.Header css={!companyId ? disableAccordion : {}}>
                    <div className="col-sm-6">REGIONS</div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Regions companyId={companyId} regions={companyData?.regions || []} />
                  </Accordion.Body>
                </Accordion.Item>
              </StyledAccordion>
            </div>
            <div className="col-md-12 col-lg-6 mb-3">
              <StyledAccordion
                defaultActiveKey="0"
                activeKey={panelStates[Panels.OFFICES] ? '0' : '1'}
                onSelect={() =>
                  setPanelStates((p) => p.map((i, idx) => (idx === Panels.OFFICES ? !i : i)))
                }>
                <Accordion.Item eventKey="0">
                  <Accordion.Header css={!companyId ? disableAccordion : {}}>
                    <div className="col-sm-6">OFFICES</div>
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={(e) => {
                        e.stopPropagation()
                        navigate(
                          `${routes.index}${routes.office.root}${routes.upsert}?companyId=${companyId}`,
                        )
                      }}>
                      Add Office
                    </Button>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Offices companyId={companyId} regions={companyData?.regions || []} />
                  </Accordion.Body>
                </Accordion.Item>
              </StyledAccordion>
            </div>
            <div className="col-md-12 col-lg-6 mb-3">
              <StyledAccordion
                defaultActiveKey="0"
                activeKey={panelStates[Panels.LOGS] ? '0' : '1'}
                onSelect={() =>
                  setPanelStates((p) => p.map((i, idx) => (idx === Panels.LOGS ? !i : i)))
                }>
                <Accordion.Item eventKey="0">
                  <Accordion.Header css={!companyId ? disableAccordion : {}}>
                    <div className="col-sm-6">LOGS</div>
                  </Accordion.Header>
                  <Accordion.Body></Accordion.Body>
                </Accordion.Item>
              </StyledAccordion>
            </div>
          </div>
        }
      />
    </div>
  )
}

export default CompanyUpsert

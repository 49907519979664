import { useDispatch } from 'react-redux'

import * as generalHelper from '../../helpers/generalHelper'
import { Link } from 'react-router-dom'
import moment from 'moment'
import ButtonModal from '../atoms/ButtonModal'
import { setModal } from '../../store/slice/modalSlice'
import Button from './Button'

interface IProps {
  header: any[]
  rows?: any[]
}

const TableComponent = props => {
  const dispatch = useDispatch()

  const dayClass = row => {
    let diff = generalHelper.dateDiff(row.created_at, 'today')
    if (diff > 10) {
      return 'btn-danger'
    } else if (diff > 4) {
      return 'btn-warning'
    } else {
      return 'btn-success'
    }
  }

  const dayDeviceClass = row => {
    let diff = generalHelper.dateDiff(row.device_at, 'today')
    if (diff > 10) {
      return 'btn-danger'
    } else if (diff > 4) {
      return 'btn-warning'
    } else {
      return 'btn-success'
    }
  }

  const getLabel = (header, row, type = 'string', _props = null) => {
    let returnValue = ''
    let props = header.props
    let raw = header.raw
    let key = header.key
    let key2 = header.key2
    let toKey = header.toKey
    let format = header.format
    let customKey = header.customKey
    let customKey2 = header.customKey2
    let customSpacerBetweenKeys = header.customSpacer

    if (row) {
      if (customKey && customKey2) {
        returnValue = `
                ${row[customKey] ? row[customKey] : ''}
                ${
                  customSpacerBetweenKeys && row[customKey] && row[customKey2]
                    ? customSpacerBetweenKeys
                    : ''
                } 
                ${row[customKey2] ? row[customKey2] : ''}`
      } else if (raw) {
        if (raw.type == 'new_order_modal') {
          returnValue = (
            <Link {...raw.props} to={`${header.to}${row.id}`}>
              {generalHelper.t(raw?.props?.label)}
            </Link>
          )
        } else if (raw.type === 'device_status') {
          let curentRow =
            Array.isArray(row[raw.key]) && row[raw.key]?.length > 0 && raw.key2
              ? row[raw.key][0]
              : row
          let assigned = (
            <div className="d-flex align-items-center">
              <div className="d-flex flex-column ml-1">
                <div className="text-bold">
                  {generalHelper.t('Assigned').toUpperCase()}
                </div>
              </div>
              <div className="d-flex flex-column ml-1">
                {
                  <div className={`btn btn-sm ${dayDeviceClass(curentRow)}`}>
                    {generalHelper.dateDiff(curentRow.device_at, 'today')}{' '}
                    {generalHelper.t('Days')}
                  </div>
                }
              </div>
            </div>
          )
          let unassigned = (
            <div className="d-flex">
              <div className="d-flex flex-column ml-1">
                <div className="text-bold">
                  {generalHelper.t('Available').toUpperCase()}
                </div>
              </div>
            </div>
          )
          returnValue =
            Array.isArray(row[raw.key]) && row[raw.key]?.length > 0
              ? assigned
              : unassigned
        } else if (raw.type === 'assign_modal') {
          let assignProps = {
            label: 'ASSIGN',
            className: 'btn btn-primary',
            type: 'button',
            data_toggle: 'modal',
            data_target: '#assign_device_modal',
          }
          let assign = (
            <ButtonModal
              {...assignProps}
              onClick={() => {
                dispatch(
                  setModal({
                    open: true,
                    device_id: `${row.id}`,
                    device: row,
                  })
                )
              }}
            />
          )
          let unassignProps = {
            label: 'UNASSIGN',
            className: 'btn btn-danger',
            type: 'button',
            data_toggle: 'modal',
            data_target: '#unassign_device_modal',
          }
          let unassign = (
            <ButtonModal
              {...unassignProps}
              onClick={() => {
                dispatch(
                  setModal({
                    open: true,
                    device: row,
                  })
                )
              }}
            />
          )
          returnValue =
            Array.isArray(row[raw.key]) && row[raw.key]?.length > 0
              ? unassign
              : assign
        } else if (raw.type === 'template') {
          // This will render a JSX element, but we need to expand the required row info for it to work
          returnValue = raw.template(
            raw.attribute && typeof row[raw.attribute] === 'object'
              ? row[raw.attribute]
              : row,
            _props.extra
          )
        }
      } else if (key && key2 && row[key]) {
        if (type == 'link') {
          returnValue =
            toKey && row[key][toKey] ? row[key][toKey] : row[key][key2]
        } else if (Array.isArray(row[key]) && key2) {
          returnValue = row[key]?.length > 0 ? `#${row[key][0][key2]}` : ''
        } else {
          returnValue = row[key][key2]
        }
      } else {
        if (type == 'link') {
          returnValue = toKey ? row[toKey] : row[key]
        } else if (format == 'date') {
          returnValue = row[key] ? moment(row[key]).format('MM/DD/YYYY') : ''
        } else {
          returnValue = row[key]
        }
      }
    }
    return returnValue
  }

  return (
    <div className="table-responsive">
      <table className="table table-hover table-sm">
        <thead>
          <tr>
            {props.header.map((row, index) => {
              return (
                <th key={index} className="textGray" {...row?.tdProps}>
                  {generalHelper.t(row.label)}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {!props.rows ||
            (Array.isArray(props.rows) && props.rows?.length == 0 && (
              <tr>
                <td colSpan="20" align="center" className="textGray">
                  {generalHelper.t(
                    props.emptymessage
                      ? props.emptymessage
                      : 'No records to display'
                  )}
                </td>
              </tr>
            ))}
          {props.rows &&
            props.rows.map((row, index) => {
              return (
                <tr key={index}>
                  {props.header.map((header, index_header) => {
                    return (
                      <td className="align-middle" key={index_header}>
                        {header.to && (header.customKey || header.deepLink) && (
                          <Link
                            to={`${header.to}${row.id}`}
                            className={`${header.style ? header.style : ''}`}
                          >
                            {getLabel(header, row, 'string', props)}
                          </Link>
                        )}
                        {header.to &&
                          !header.customKey &&
                          !header.toRaw &&
                          !header.key2 &&
                          !header.deepLink &&
                          !header.linkButton && (
                            <Link
                              to={`${header.to}${getLabel(
                                header,
                                row,
                                'link',
                                props
                              )}`}
                              className={`${header.style ? header.style : ''}`}
                            >
                              {getLabel(header, row, 'string', props)}
                            </Link>
                          )}
                        {header.to &&
                          !header.customKey &&
                          !header.toRaw &&
                          !header.key2 &&
                          !header.deepLink &&
                          header.linkButton && <>{getLabel(header, row)}</>}
                        {!header.to &&
                          !header.toRaw &&
                          getLabel(header, row, 'string', props)}
                        {header.toRaw && (
                          <a
                            href={row[header.toRaw.url]}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className={header.toRaw.icon}></i>{' '}
                            {header.toRaw.label}
                          </a>
                        )}
                        {header.toLocal && (
                          <Link
                            to={`${header.toLocal.to}${row.id}`}
                            className={header.toLocal.className}
                          >
                            <i className={header.toLocal.icon}></i>{' '}
                            {generalHelper.t(header.toLocal.label)}
                          </Link>
                        )}

                        {header.to &&
                          header.key &&
                          header.key2 &&
                          row[header.key]?.length > 0 && (
                            <div className="d-flex">
                              <Link
                                className="border border-primary rounded p-2 mr-1"
                                to={`${header.to}${
                                  row[header.key][0][header.key2]
                                }`}
                              >
                                {getLabel(header, row, 'string', props)}
                              </Link>
                              <div className="d-flex flex-column ml-1">
                                <div className="text-bold">
                                  {row[header.key][0]?.patient?.last_name},{' '}
                                  {row[header.key][0]?.patient?.first_name}
                                </div>
                                {row[header.key][0]?.patient?.dob && (
                                  <div>
                                    {moment(
                                      row[header.key][0]?.patient?.dob
                                    ).format('MM/DD/YYYY')}
                                  </div>
                                )}
                              </div>
                              <div className="d-flex flex-column ml-1">
                                {
                                  <div
                                    className={`btn btn-sm ${dayClass(
                                      row[header.key][0]
                                    )}`}
                                  >
                                    {generalHelper.dateDiff(
                                      row[header.key][0].created_at,
                                      'today'
                                    )}{' '}
                                    {generalHelper.t('Days')}
                                  </div>
                                }
                              </div>
                            </div>
                          )}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
        </tbody>
      </table>
    </div>
  )
}

export default TableComponent

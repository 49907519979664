/** @jsxImportSource @emotion/react */
import { useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { StylesConfig } from 'react-select'
import Toast from 'components/atoms/Toast'

import { confirm } from 'components/molecules/Confirmation'
import InputMask from 'components/atoms/InputMask'
import AsyncCreatableSelect from 'components/atoms/AsyncCreatableSelect'
import { handleChange } from 'components/atoms/Upsert'
import Input from 'components/atoms/Input'
import * as generalHelper from 'helpers/generalHelper'
import * as api from 'helpers/api'
import { Payer } from 'store/types'
import {
  useCreatePayerMutation,
  useModifyPayerMutation,
  useDeletePayerMutation,
} from 'store/services/payer'
import PayorDetailsModal from 'components/organisms/payer/PayerDetailsModal'
import { PayerFormValues } from 'components/organisms/payer/PayerForm'

const Checkbox = styled.input`
  margin-top: 0.1rem;
  margin-left: -1.5rem;
`
const selectStyles = {
  option: (styles: StylesConfig, { data }) => ({
    ...styles,
    fontWeight: data.value === '__new__' ? 'bold' : 'normal',
    textDecoration: data.value === '__new__' ? 'underline' : 'none',
    cursor: 'pointer',
  }),
}

interface IProps {
  payers: any
  inTabView?: boolean
  setNewOrdersValues?: () => void
  submitForm: (payload: Payer[]) => void
  submitRef: any
  undoRef?: any
  setFormDirty?: (d: boolean) => void
  setFormValid?: (d: boolean) => void
  officeId: string | number
  verificationResult?: { PAYER: string; PAYER_2: string; PAYER_3: string }
}

export const PayerSelectionForm = ({
  payers,
  inTabView,
  setNewOrdersValues,
  submitForm,
  submitRef,
  undoRef,
  setFormDirty = () => {},
  setFormValid = () => {},
  officeId,
  verificationResult,
}: IProps) => {
  const valuesDefault = {
    payer2: false,
    payer3: false,
  }
  const [values, setValues] = useState(valuesDefault)
  const [payer1, setPayer1] = useState({})
  const [payer1Text, setPayer1Text] = useState('')
  const [payer2, setPayer2] = useState({})
  const [payer2Text, setPayer2Text] = useState('')
  const [payer3, setPayer3] = useState({})
  const [payer3Text, setPayer3Text] = useState('')
  const [payerDetailsModal, setPayerDetailsModal] = useState<
    (Partial<PayerFormValues> & { idx: number }) | null
  >()
  const initializedRef = useRef(false)
  const [createPayer] = useCreatePayerMutation()
  const [updatePayer] = useModifyPayerMutation()
  const [deletePayer] = useDeletePayerMutation()
  const canEditPayer1 = verificationResult?.PAYER !== 'DONE'
  const canEditPayer2 = verificationResult?.PAYER_2 !== 'DONE'
  const canEditPayer3 = verificationResult?.PAYER_3 !== 'DONE'

  useEffect(() => {
    if (setNewOrdersValues) {
      setNewOrdersValues({ payer1, payer2, payer3 })
    }
    // console.log('Payers: ', payer1, payer2, payer3)
  }, [payer1, payer2, payer3])

  useEffect(() => {
    initialize()
  }, [payers])

  useEffect(() => {
    if (!initializedRef.current || !Array.isArray(payers) || (!payer1?.name && !!payers?.length))
      return
    if (
      payer1?.name !== payer1?.insurance_payer?.label ||
      payers[0]?.memberId !== payer1?.memberId ||
      payers[0]?.groupId !== payer1?.groupId ||
      payer2?.name !== payer2?.insurance_payer?.label ||
      payers[1]?.memberId !== payer2?.memberId ||
      payers[1]?.groupId !== payer2?.groupId ||
      payer3?.name !== payer3?.insurance_payer?.label ||
      payers[2]?.memberId !== payer3?.memberId ||
      payers[2]?.groupId !== payer3?.groupId
    ) {
      // console.log(values, payer1, payer2, payer3)
      setFormDirty(true)
      setFormValid(handleValidation(true))
    }
  }, [values, payer1, payer2, payer3])

  const initialize = () => {
    if (Array.isArray(payers)) {
      payers[0]
        ? setPayer1({
            ...payers[0],
            insurance_payer: {
              label: payers[0].name,
              value: payers[0].id,
              __isNew__: false,
            },
          })
        : setPayer1({})
      payers[1]
        ? setPayer2({
            ...payers[1],
            insurance_payer: {
              label: payers[1].name,
              value: payers[1].id,
              __isNew__: false,
            },
          })
        : setPayer2({})
      payers[2]
        ? setPayer3({
            ...payers[2],
            insurance_payer: {
              label: payers[2].name,
              value: payers[2].id,
              __isNew__: false,
            },
          })
        : setPayer3({})

      if (payers[2]) {
        setValues({ ...values, payer2: true, payer3: true })
      } else if (payers[1]) {
        setValues({ ...values, payer2: true })
      } else {
        setValues(valuesDefault)
      }
      initializedRef.current = true
    }
  }

  const loadPayers = async (inputValue: string) => {
    const data = await api.get(`/dme/dme-payers?officeId=${officeId}&name=${inputValue}`)
    return generalHelper.getLabelValue(data, 'id', 'name')
  }

  const handleCheckboxes = (event, values) => {
    if (event.target.name === 'payer2' && event.target.checked) {
      setValues({ ...values, payer2: true })
    }
    if (event.target.name === 'payer2' && !event.target.checked) {
      setValues({ ...values, payer2: false, payer3: false })
      setPayer2({})
      setPayer3({})
    }
    if (event.target.name === 'payer3' && event.target.checked) {
      setValues({ ...values, payer2: true, payer3: true })
    }
    if (event.target.name === 'payer3' && !event.target.checked) {
      setValues({ ...values, payer3: false })
      setPayer3({})
    }
  }

  const managePayerButtons = (payer, setPayer) => {
    if (payer.insurance_payer) {
      return (
        <div>
          <div
            css={{ position: 'absolute', bottom: 47, right: 16, cursor: 'pointer' }}
            onClick={async () => {
              const payerDetails = await api.get(`/dme/dme-payers/${payer.insurance_payer.value}`)
              setPayerDetailsModal({ ...payerDetails, idx: payer.position })
            }}>
            <i className="fa fa-pencil text-gray" />
          </div>
          {/* <div
            className="text-danger"
            css={{ position: 'absolute', bottom: 47, right: 16, cursor: 'pointer' }}
            onClick={() =>
              confirm('Payor')
                .then(async () => {
                  await deletePayer({ id: payer.insurance_payer.value })
                  setPayer({})
                })
                .catch()
            }>
            <i className="fa fa-trash text-danger" />
          </div> */}
        </div>
      )
    }
  }

  const handleValidation = (checkOnly?: boolean) => {
    let isValidForm = true
    const invalidForm = {
      primary_insurance_payer_is_invalid: false,
      primary_insurance_member_id_is_invalid: false,
      primary_insurance_phone_is_invalid: false,
      secondary_insurance_payer_is_invalid: false,
      secondary_insurance_member_id_is_invalid: false,
      secondary_insurance_phone_is_invalid: false,
      tertiary_insurance_payer_is_invalid: false,
      tertiary_insurance_member_id_is_invalid: false,
      tertiary_insurance_phone_is_invalid: false,
    }
    if (!payer1.insurance_payer) {
      invalidForm.primary_insurance_payer_is_invalid = true
      isValidForm = false
    }
    if (!payer1.memberId) {
      invalidForm.primary_insurance_member_id_is_invalid = true
      isValidForm = false
    }
    if (payer1.insurance_payer && payer1.insurance_payer.__isNew__ && !payer1.insurance_phone) {
      invalidForm.primary_insurance_phone_is_invalid = true
      isValidForm = false
    }
    if (values.payer2 && !payer2.insurance_payer) {
      invalidForm.secondary_insurance_payer_is_invalid = true
      isValidForm = false
    }
    if (values.payer2 && !payer2.memberId) {
      invalidForm.secondary_insurance_member_id_is_invalid = true
      isValidForm = false
    }
    if (
      values.payer2 &&
      payer2.insurance_payer &&
      payer2.insurance_payer.__isNew__ &&
      !payer2.insurance_phone
    ) {
      invalidForm.secondary_insurance_phone_is_invalid = true
      isValidForm = false
    }
    if (values.payer3 && !payer3.insurance_payer) {
      invalidForm.tertiary_insurance_payer_is_invalid = true
      isValidForm = false
    }
    if (values.payer3 && !payer3.memberId) {
      invalidForm.tertiary_insurance_member_id_is_invalid = true
      isValidForm = false
    }
    if (
      values.payer3 &&
      payer3.insurance_payer &&
      payer3.insurance_payer.__isNew__ &&
      !payer3.insurance_phone
    ) {
      invalidForm.tertiary_insurance_phone_is_invalid = true
      isValidForm = false
    }
    if (!checkOnly) {
      setValues({ ...values, invalidForm })
    }
    return isValidForm
  }

  const setInitialValidationValues = () => {
    const invalidForm = {
      primary_insurance_payer_is_invalid: false,
      primary_insurance_member_id_is_invalid: false,
      primary_insurance_phone_is_invalid: false,
      secondary_insurance_payer_is_invalid: false,
      secondary_insurance_member_id_is_invalid: false,
      secondary_insurance_phone_is_invalid: false,
      tertiary_insurance_payer_is_invalid: false,
      tertiary_insurance_member_id_is_invalid: false,
      tertiary_insurance_phone_is_invalid: false,
    }
    setValues({ ...values, invalidForm })
  }

  const onSave = async () => {
    if (!handleValidation()) {
      return
    }
    console.log(payer1, payer2, payer3)
    const payers = [payer1, payer2, payer3]
      .filter((i) => !!i.insurance_payer)
      .map((payer, idx) => ({
        id: Number(payer.insurance_payer.value),
        name: payer.insurance_payer.label,
        memberId: payer.memberId,
        groupId: payer.groupId,
        autoVerified: true,
        position: idx + 1,
        telephone: payer.insurance_phone ?? payer.telephone,
        fax: payer.fax,
        addressStreetLine1: payer.addressStreetLine1,
        addressStreetLine2: payer.addressStreetLine2,
        addressCity: payer.addressCity,
        addressState: payer.addressState,
        addressZip: payer.addressZip,
      }))

    await submitForm(payers)
    Toast({ type: 'success', label: `Payer(s) updated!` })
    setInitialValidationValues()
  }

  if (submitRef) {
    submitRef.current = onSave
  }
  if (undoRef) {
    undoRef.current = initialize
  }

  return (
    <div className={`container ${inTabView ? 'p-4' : ''}`}>
      <PayorDetailsModal
        open={!!payerDetailsModal}
        onClose={() => setPayerDetailsModal(null)}
        payorDetails={payerDetailsModal}
        onSave={async (values: PayerFormValues) => {
          const { data } = payerDetailsModal?.id
            ? await updatePayer({ ...values, id: payerDetailsModal?.id })
            : await createPayer({ ...values, officeId: Number(officeId) })

          setPayerDetailsModal(null)
          const payload = {
            insurance_payer: {
              label: data.name,
              value: data.id,
              __isNew__: false,
            },
          }
          if (payerDetailsModal?.idx === 1) {
            setPayer1((p) => ({ ...p, ...payload }))
          } else if (payerDetailsModal?.idx === 2) {
            setPayer2((p) => ({ ...p, ...payload }))
          } else if (payerDetailsModal?.idx === 3) {
            setPayer3((p) => ({ ...p, ...payload }))
          }
        }}
      />
      <div className="row">
        <div className="col-sm-4">
          <div className="d-flex flex-column">
            <div className="p-1 text-bold">{generalHelper.t('Primary Insurance')}</div>
            <div className="p-1 form-group mb-0" css={{ position: 'relative' }}>
              <label className="textGray" htmlFor="primary_insurance_payer">
                Payor
                <span className="text-danger">*</span>
              </label>
              <AsyncCreatableSelect
                disabled={!canEditPayer1}
                createLabel="Create new payer"
                value={payer1.insurance_payer}
                name="insurance_payer"
                loadOptions={loadPayers}
                styles={selectStyles}
                onInputChange={setPayer1Text}
                handleNew={() => setPayerDetailsModal({ idx: 1, name: payer1Text })}
                handleChange={(row) => {
                  if (!row) {
                    setPayer1({})
                  } else {
                    handleChange(
                      {
                        insurance_payer: row,
                      },
                      payer1,
                      setPayer1,
                    )
                  }
                }}
              />
              {values.invalidForm?.primary_insurance_payer_is_invalid ? (
                <div className="text-danger">Required</div>
              ) : null}
              {managePayerButtons(payer1, setPayer1)}
            </div>
            <div className="p-1">
              <label className="textGray" htmlFor="primary_insurance_member_id">
                Member ID
                <span className="text-danger">*</span>
              </label>
              <Input
                value={payer1.memberId}
                placeholder=""
                name="memberId"
                disabled={!canEditPayer1}
                handleChange={(event) => {
                  handleChange(event, payer1, setPayer1)
                }}
                error={values.invalidForm?.primary_insurance_member_id_is_invalid ? 'Required' : ''}
              />
            </div>
            <div className="p-1 form-group">
              <label className="textGray" htmlFor="primary_insurance_group_id">
                Group ID
              </label>
              <Input
                value={payer1.groupId}
                placeholder=""
                name="groupId"
                disabled={!canEditPayer1}
                handleChange={(event) => {
                  handleChange(event, payer1, setPayer1)
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="d-flex flex-column">
            <div className="p-1 form-check ml-4">
              <Checkbox
                type="checkbox"
                value={values.payer2}
                name="payer2"
                disabled={!canEditPayer2}
                className="form-check-input"
                checked={values.payer2}
                onChange={(event) => {
                  handleCheckboxes(event, values)
                }}
                id="payer2-checkbox"
              />
              <label className="form-check-label" htmlFor="payer2-checkbox">
                <b>{generalHelper.t('Secondary Insurance')}</b>
              </label>
            </div>
            {values.payer2 ? (
              <>
                <div className="p-1 mb-0 form-group" css={{ position: 'relative' }}>
                  <label className="textGray" htmlFor="insurance_payer">
                    Payor
                    <span className="text-danger">*</span>
                  </label>
                  <AsyncCreatableSelect
                    createLabel="Create new payer"
                    id="insurance_payer"
                    disabled={!canEditPayer2}
                    value={payer2.insurance_payer}
                    name="insurance_payer"
                    loadOptions={loadPayers}
                    styles={selectStyles}
                    onInputChange={setPayer2Text}
                    handleNew={() => setPayerDetailsModal({ idx: 2, name: payer2Text })}
                    handleChange={(row) => {
                      if (!row) {
                        setPayer2({})
                      } else {
                        handleChange(
                          {
                            insurance_payer: row,
                          },
                          payer2,
                          setPayer2,
                        )
                      }
                    }}
                  />
                  {values.invalidForm?.secondary_insurance_payer_is_invalid ? (
                    <div className="text-danger">Required</div>
                  ) : null}
                  {managePayerButtons(payer2, setPayer2)}
                </div>
                <div className="p-1">
                  <label className="textGray" htmlFor="secondary_insurance_member_id">
                    Member ID
                    <span className="text-danger">*</span>
                  </label>
                  <Input
                    value={payer2.memberId}
                    label={false}
                    placeholder=""
                    className="form-control"
                    name="memberId"
                    disabled={!canEditPayer2}
                    handleChange={(event) => {
                      handleChange(event, payer2, setPayer2)
                    }}
                    error={
                      values.invalidForm?.secondary_insurance_member_id_is_invalid ? 'Required' : ''
                    }
                  />
                </div>
                <div className="p-1 form-group">
                  <label className="textGray" htmlFor="secondary_insurance_group_id">
                    Group ID
                  </label>
                  <Input
                    value={payer2.groupId}
                    placeholder=""
                    name="groupId"
                    disabled={!canEditPayer2}
                    handleChange={(event) => {
                      handleChange(event, payer2, setPayer2)
                    }}
                  />
                </div>
              </>
            ) : null}
          </div>
        </div>
        <div className="col-sm-4">
          <div className="d-flex flex-column">
            <div className="p-1 form-check ml-4">
              <Checkbox
                type="checkbox"
                value={values.payer3}
                name="payer3"
                disabled={!canEditPayer3}
                className="form-check-input"
                checked={values.payer3}
                onChange={(event) => {
                  handleCheckboxes(event, values)
                }}
                id="payer3-checkbox"
              />
              <label className="form-check-label" htmlFor="payer3-checkbox">
                <b>{generalHelper.t('Tertiary Insurance')}</b>
              </label>
            </div>
            {values.payer3 ? (
              <>
                <div className="p-1 mb-0 form-group" css={{ position: 'relative' }}>
                  <label className="textGray" htmlFor="insurance_payer">
                    Payor
                    <span className="text-danger">*</span>
                  </label>
                  <AsyncCreatableSelect
                    createLabel="Create new payer"
                    id="insurance_payer"
                    disabled={!canEditPayer3}
                    value={payer3.insurance_payer}
                    name="insurance_payer"
                    loadOptions={loadPayers}
                    styles={selectStyles}
                    onInputChange={setPayer3Text}
                    handleNew={() => setPayerDetailsModal({ idx: 3, name: payer3Text })}
                    handleChange={(row) => {
                      if (!row) {
                        setPayer3({})
                      } else {
                        handleChange(
                          {
                            insurance_payer: row,
                          },
                          payer3,
                          setPayer3,
                        )
                      }
                    }}
                  />
                  {values.invalidForm?.tertiary_insurance_payer_is_invalid ? (
                    <div className="text-danger">Required</div>
                  ) : null}
                  {managePayerButtons(payer3, setPayer3)}
                </div>
                <div className="p-1">
                  <label className="textGray" htmlFor="tertiary_insurance_member_id">
                    Member ID
                    <span className="text-danger">*</span>
                  </label>
                  <Input
                    value={payer3.memberId}
                    label={false}
                    placeholder=""
                    disabled={!canEditPayer3}
                    className="form-control"
                    name="memberId"
                    handleChange={(event) => {
                      handleChange(event, payer3, setPayer3)
                    }}
                    error={
                      values.invalidForm?.tertiary_insurance_member_id_is_invalid ? 'Required' : ''
                    }
                  />
                </div>
                <div className="p-1 form-group">
                  <label className="textGray" htmlFor="tertiary_insurance_group_id">
                    Group ID
                  </label>
                  <Input
                    value={payer3.groupId}
                    placeholder=""
                    disabled={!canEditPayer3}
                    name="groupId"
                    handleChange={(event) => {
                      handleChange(event, payer3, setPayer3)
                    }}
                  />
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

import { DeviceType } from 'components/dme/instructions/reset'
import { DeviceListData } from './reader'
import rad97 from 'assets/img/device-photos/rad97.jpg'

export type Device = {
  image: string
  name: string
  type: DeviceType
}

export const devices: Device[] = [
  {
    type: 'tsot',
    image: '/img/device-photos/tsot-200.png',
    name: 'TestSmarter OneTrip',
  },
  {
    type: 'viatom',
    image: '/img/device-photos/viatom-200.png',
    name: 'Viatom OxyFit',
  },
  {
    type: 'c50f',
    image: '/img/device-photos/c50f-200.png',
    name: 'C50F',
  },
  {
    type: 'c50i',
    image: '/img/device-photos/c50i-200.png',
    name: 'C50i',
  },
  {
    type: 'n3150',
    image: '/img/device-photos/n3150-200.png',
    name: 'Nonin WristOx 3150',
  },
  {
    type: 'n2500',
    image: '/img/device-photos/n2500-200.png',
    name: 'Nonin 2500',
  },
  {
    type: 'n8500',
    image: '/img/device-photos/n8500-200.png',
    name: 'Nonin Model 8500',
  },
  {
    type: '920mp',
    image: '/img/device-photos/920mp-200.png',
    name: 'Respironics 920M Plus',
  },
  {
    type: 'n920m',
    image: '/img/device-photos/920m-200.png',
    name: 'Respironics 920M',
  },
  {
    type: 'n7500',
    image: '/img/device-photos/n7500-200.png',
    name: 'Nonin 7500',
  },
  {
    type: 'rad8',
    image: '/img/device-photos/rad8-200.png',
    name: 'Masimo Rad-8',
  },
  {
    type: 'capnostream35',
    image: '',
    name: 'Medtronic Capnostream 35',
  },
  {
    type: 'nlsii',
    image: '/img/device-photos/nlsii-200.png',
    name: 'Nonin LifeSense II',
  },
  {
    type: 'rad97',
    image: rad97,
    name: 'Masimo Rad-97',
  },
]

interface DeviceListInput {
  hid: DeviceListData[]
  serial: DeviceListData[]
}

export interface DeviceListOptions {
  connectionId: string
  connectionPath: string
  connectionType: string
  optionLabel: string
}
export const filterSpO2Devices = ({ hid, serial }: DeviceListInput): DeviceListOptions[] => {
  const options = []

  // check for HID devices
  if (hid.length) {
    // iterate that array
    for (const device of hid) {
      // check if device is supported
      let option = null
      if (650 === device.productId && 10473 === device.vendorId) {
        // this is a new HID OneTrip
        option = {
          connectionId: 'usb-hid:c50f',
          connectionPath: device.path,
          connectionType: 'usb-hid',
          optionLabel: 'HID: TestSmarter OneTrip',
        }
      } else {
        continue
      }
      // add to options array
      options.push(option)
    }
  }

  // check for serial devices
  if (serial.length) {
    // iterate that array
    for (const device of serial) {
      // check if device is supported
      let option = null
      if (
        '(Standard port types)' === device.manufacturer &&
        ('COM1' === device.path || 'COM2' === device.path)
      ) {
        // this is a standard RS232 serial connection (e.g. Masimo Rad-8)
        option = {
          connectionId: 'serial:rs232',
          connectionPath: device.path,
          connectionType: 'serial:rs232',
          optionLabel: device.path + ': Standard Serial Port (not USB)',
        }
      } else if (device.manufacturer.match(/(gigaware|keyspan|oxford|prolific|semiconductor)/i)) {
        // this is a USB-to-Serial adapter
        option = {
          connectionId: 'usb-serial:adapter',
          connectionPath: device.path,
          connectionType: 'usb-serial',
          optionLabel: device.path + ': USB-Serial Adapter',
        }
      } else if (device.manufacturer.match(/^ftdi$/i)) {
        // this is most likely a Nonin (e.g. 2500/8500 or Respironics 920M/Plus) or Masimo Rad-97
        option = {
          connectionId: 'usb-serial:ftdi',
          connectionPath: device.path,
          connectionType: 'usb-serial',
          optionLabel: device.path + ': Nonin or Masimo',
        }
      } else if (device.manufacturer.match(/(microsoft|nonin)/i)) {
        // this is most likely a Nonin 3150 (WristOx)
        option = {
          connectionId: 'usb-serial:n3150',
          connectionPath: device.path,
          connectionType: 'usb-serial',
          optionLabel: device.path + ': Nonin',
        }
      } else if (device.manufacturer.match(/(silicon|integrisys)/i)) {
        // this is most likely a TestSmarter OneTrip or other Contec (e.g. CMS50F, CMS50i) device
        option = {
          connectionId: 'usb-serial:cp210x',
          connectionPath: device.path,
          connectionType: 'usb-serial',
          optionLabel: device.path + ': TestSmarter OneTrip or Contec',
        }
      }
      if (!option) {
        // device is not supported
        continue
      }
      options.push(option)
    }
  }

  return options?.sort((a, b) => {
    if (a.connectionType < b.connectionType) return -1
    if (a.connectionType > b.connectionType) return 1
    return a.optionLabel < b.optionLabel ? -1 : 1
  })
}

import { useEffect, useState } from 'react'
import { useAppDispatch } from 'hooks/redux'
import { useParams } from 'react-router-dom'
import ButtonCancelModal from 'components/atoms/ButtonCancelModal'
import ButtonSave from 'components/atoms/ButtonSave'
import Modal from 'components/atoms/Modal'
import OrderTest from 'components/dme/orderTest/OrderTest'
import { setModal } from 'store/slice/modalSlice'
import * as generalHelper from 'helpers/generalHelper'
import { Order, IOrderTest } from 'store/types'
import { useUpdateOrderMutation } from 'store/services/dmeOrder'

interface IProps {
  modalId: string
  title: string
  order: Order
  modalKey: number
}

const defaultTest = {
  apapMax: 0,
  apapMin: 0,
  bipapE: 0,
  bipapI: 0,
  cpap: 0,
  night: 1,
  original: 'On Room Air',
  oxygen: 0,
  oxygenLpm: 0,
}

const OrderTestModal = (props: IProps) => {
  let { orderId } = useParams()
  const dispatch = useAppDispatch()
  const { modalId, title, order, modalKey } = props
  const orderTest = order?.tests?.length ? order.tests : [defaultTest]
  const [orderTestValues, setOrderTestValues] = useState<IOrderTest[]>([])
  const [updateOrder, { isLoading: isUpdating }] = useUpdateOrderMutation()

  useEffect(() => {
    if (Array.isArray(orderTest)) {
      setOrderTestValues(orderTest)
    }
  }, [order, modalKey])

  const handleSubmitLocal = async () => {
    dispatch(setModal({}))
    if (Array.isArray(orderTestValues) && orderId) {
      console.log(orderTestValues)
      await updateOrder({
        id: orderId,
        testType: order.testType,
        tests: orderTestValues,
      })
    }
  }

  return (
    <Modal
      title={title}
      id={modalId}
      content={
        <div className="container">
          {!order?.tests?.length && (
            <div className="alert alert-warning mt-2" role="alert">
              No test conditions have been set for this order. Defaulting to 1 night.
            </div>
          )}
          <div className="modal-body">
            <div className="col-sm-12">
              <div>
                <strong>{generalHelper.t('Nights')}</strong>
              </div>
              <div className="row mt-3">
                <OrderTest
                  id={modalId}
                  testType={order?.testType}
                  values={orderTestValues}
                  setValues={setOrderTestValues}
                  hideDeleteNight={true}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <ButtonCancelModal
              type="button"
              data_dismiss="modal"
              onClick={() => {
                dispatch(setModal({}))
              }}
            />
            <ButtonSave
              type="button"
              label="Save"
              data-dismiss="modal"
              onClick={(event) => {
                handleSubmitLocal()
              }}
            />
          </div>
        </div>
      }
    />
  )
}

export default OrderTestModal

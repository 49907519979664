import { ReactNode } from 'react'
import { useParams } from 'react-router-dom'
import PatientUpsert from './forms/PatientUpsert'
import routes from '../../constants/routes'
import { Order } from 'store/types'

interface IProps {
  order: Order
  isNewOrder: boolean
  actionButtons: ReactNode
}

const PatientTab = ({ order, isNewOrder, actionButtons }: IProps) => {
  const { orderId, tab } = useParams()

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12 p-0">
          <PatientUpsert
            unlockedForm
            patient={order?.patient}
            route={`${routes.index}${routes.order.edit}${orderId}/${routes.order.physician}`}
            isNewOrder={isNewOrder}
            actionButtons={actionButtons}
          />
        </div>
      </div>
    </div>
  )
}

export default PatientTab

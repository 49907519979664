import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Table from '../../atoms/Table'
import ButtonNew from '../../atoms/ButtonNew'
import DashboardContent from '../atoms/DashboardContent'
import * as generalHelper from '../../../helpers/generalHelper'
import { getDeviceCategories } from '../../../store/slice/deviceCategorySlice'
import routes from '../constants/routes'

const DeviceCategory = props => {
  const rows = useSelector(state => state.deviceCategory.rows)
  const office_id = useSelector(state => state.init.office_id)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getDeviceCategories({ add_join: 'device_type', no_cache: 1 }))
  }, [office_id])

  function Content(props) {
    const header = [
      {
        key: 'id',
        to: `${routes.index}${routes.device.deviceCategory}${routes.upsert}`,
        label: 'Id',
      },
      { key: 'name', label: 'Name' },
      { key: 'device_type', key2: 'name', label: 'Type' },
      { key: 'status', label: 'Status' },
    ]
    return (
      <div className="card card-primary">
        <form type="post" onSubmit={props.handleSubmit}>
          <div className="card-header">
            <h3 className="card-title"></h3>
            <div className="card-tools">
              <ButtonNew
                to={`${routes.index}${routes.device.deviceCategory}${routes.upsert}`}
              />
            </div>
          </div>

          <div className="card-body row">
            <Table header={header} rows={rows} />
          </div>
        </form>
      </div>
    )
  }
  const content = Content(props)

  return (
    <DashboardContent
      title={generalHelper.t('Device Categories')}
      icon="fa fa-disc-drive"
      content={content}
      breadcrumb={[
        {
          label: 'Devices',
          to: `${routes.index}${routes.device.root}`,
        },
        { label: 'Categories' },
      ]}
    />
  )
}

export default DeviceCategory

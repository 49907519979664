/** @jsxImportSource @emotion/react */
import { Chart as ChartInstance, Plugin, registerables } from 'chart.js'
import { Line } from 'react-chartjs-2'

ChartInstance.register(...registerables)

export interface ChartDatapoint {
  label: string
  value: number
}

export const Chart = ({ datapoints }: { datapoints: ChartDatapoint[] }) => {
  const pointLabelPlugin: Plugin = {
    id: 'pointLabelPlugin',
    afterDatasetsDraw(chart: ChartInstance<'line'>) {
      const { ctx, chartArea } = chart

      chart.data.datasets.forEach((dataset, i) => {
        const meta = chart.getDatasetMeta(i)

        if (!meta.hidden) {
          meta.data.forEach((element, index: number) => {
            ctx.fillStyle = '#3784E0'

            const fontSize = 14
            const fontStyle = 'normal'
            const fontFamily = 'Arial'

            ctx.font = `${fontStyle} ${fontSize}px ${fontFamily}`
            ctx.textAlign = 'center'
            ctx.textBaseline = 'bottom'

            const padding = 10
            const dataString = dataset.data[index]!.toString()

            if (element.y <= chartArea.top + (fontSize + padding)) {
              ctx.textBaseline = 'top'
              ctx.fillText(dataString, element.x, element.y + padding)
              return
            }

            ctx.textBaseline = 'bottom'
            ctx.fillText(dataString, element.x, element.y - padding)
          })
        }
      })
    },
  }

  const config = {
    data: {
      labels: datapoints.map(({ label }) => label),
      datasets: [
        {
          label: 'Orders',
          data: datapoints.map(({ value }) => value),
          fill: false,
          borderColor: '#d3d3d3',
          borderWidth: 2,
          pointBackgroundColor: '#3784E0',
          pointBorderColor: '#3784E0',
          pointRadius: 4,
          pointHoverRadius: 8,
          tension: 0.3,
        },
      ],
    },
    options: {
      interaction: {
        intersect: false,
        mode: 'index' as 'index',
      },
      scales: {
        x: {
          ticks: {
            maxRotation: 90,
            minRotation: 70,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
    },
    plugins: [pointLabelPlugin],
  }

  return (
    <div
      css={{
        position: 'relative',
      }}>
      <Line
        datasetIdKey="order-stats"
        data={config.data}
        options={config.options}
        plugins={config.plugins}
        height={35}
        width={200}
      />
      {!datapoints.length && (
        <div
          css={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#f4f6f9',
            color: '#8a8a8a',
            fontSize: 20,
            padding: '5px',
            textAlign: 'center',
          }}>
          Not enough data for chart
        </div>
      )}
    </div>
  )
}

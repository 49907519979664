import { useEffect, useState } from 'react'
import debounce from 'lodash.debounce'

import { useSelector, useDispatch } from 'react-redux'
import DashboardContent from '../../atoms/DashboardContent'
import OfficesTable from '../../atoms/OfficesTable'
import routes from '../../constants/routes'
import { handleChange } from '../../../atoms/Upsert'
import Input from '../../../atoms/Input'
import Select from '../../../atoms/Select'
import Button from '../../../atoms/Button'
import { setLoader } from '../../../../store/slice/loaderSlice'
import { getOffices } from '../../../../store/slice/officeSlice'
import * as generalHelper from '../../../../helpers/generalHelper'

// import { useGetOffices } from '../../../../hooks/office'
// import { useGetOfficesQuery } from 'store/services/office'

const formFields = {}
const SEARCH_DELAY = 800

const OfficeSearch = () => {
  const rows = useSelector(state => state.user.rows)
  const office_id = useSelector(state => state.init.office_id)
  const offices = useSelector(state => state.office.rows)
  const [values, setValues] = useState(formFields)
  const dispatch = useDispatch()

  // const { data, error, loading } = useGetOfficesQuery({ company_id: 1 })

  // const [search, setSearch] = useState("");
  // const [values, setValues] = useState(formFields);
  // const { data: rows, isLoading } = useGetOffices({
  //     search,
  // });

  const onClear = () => {
    setValues(formFields)
  }

  useEffect(() => {
    const searchFn = debounce(() => {
      dispatch(
        getOffices({
          ...values,
          order_by: 'updated_at,DESC',
        })
      ).then(() => {
        dispatch(setLoader(false))
      })
    }, SEARCH_DELAY)

    searchFn()

    return () => {
      searchFn.cancel()
    }
  }, [values])

  function Content() {
    const header = [
      {
        key: 'office_id',
        label: 'Office Id',
      },
      {
        key: 'office',
        label: 'Office',
        to: '',
      },
      {
        key: 'region',
        label: 'Region',
        to: '',
      },
    ]

    return (
      <div className="card card-primary">
        <div className="card-body row">
          <div className="col-sm-12">
            <div className="row">
              <div className="col-sm-4">
                <div className="form-group">
                  <label>{generalHelper.t('Search')}</label>

                  <Input
                    value={values.name_is_like}
                    label={false}
                    placeholder="Search.."
                    name="name_is_like"
                    parentDiv={null}
                    autoFocus={true}
                    handleChange={event => {
                      handleChange(event, values, setValues)
                    }}
                  />
                </div>
              </div>

              <div className="col-sm-1">
                <div className="form-group">
                  <Select
                    value={values.office_id}
                    defaultLabel="All Regions"
                    name="region"
                    label="Region"
                    handleChange={event => {
                      handleChange(event, values, setValues)
                    }}
                  />
                </div>
              </div>
              <div className="col-sm-3" style={{ paddingTop: '2rem' }}>
                <Button
                  label="Clear"
                  className="btn btn-default"
                  onClick={onClear}
                />
                <Button label="Search" className="btn btn-primary ml-2 mr-3" />
              </div>
            </div>
          </div>
          <OfficesTable offices={offices} />
        </div>
      </div>
    )
  }

  return (
    <DashboardContent
      title={generalHelper.t('Company Directory')}
      subtitle={generalHelper.t('Offices')}
      content={Content()}
      breadcrumb={[
        { label: 'Home', to: `${routes.index}` },
        { label: 'Company Directory' },
        { label: 'Offices' },
      ]}
    />
  )
}

export default OfficeSearch

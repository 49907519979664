/** @jsxImportSource @emotion/react */
import { faCheck, faRedo, faStop } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FaxIcon from '@mui/icons-material/Fax'

import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop'
import NoBackpackIcon from '@mui/icons-material/NoBackpack'
import { PopoverWrapper } from 'components/atoms/PopoverWrapper'

import TabHeader from 'components/atoms/TabHeader'
import { confirm } from 'components/molecules/Confirmation'
import { ReportViewerDialog } from 'components/molecules/ReportViewer'
import groups from 'constants/orderResultGroups'
import orderStateHelper from 'helpers/orderStateHelpers'
import { keyBy } from 'lodash'
import moment from 'moment'
import { Dispatch, SetStateAction, useState } from 'react'
import Button from 'react-bootstrap/Button'
import {
  useCancelOrderFaxMutation,
  useCancelReportFaxMutation,
  useMarkOrderReportAsSentMutation,
  useRefaxOrderReportMutation,
  useRemoveDeviceReportDataMutation,
  useScheduleReportFaxMutation,
  useReRenderReportMutation,
  useMarkOrderAsBilledMutation,
} from 'store/services/labOrder'
import { Order } from 'store/types'
import { FaxStatus, SendHistoryItem } from 'ts-back-end/src/business/report/dto/report.dto'
import CheckboxComponent from '../../atoms/Checkbox'
import { CountDown } from './CountDown'
import { MoveReportDialog } from './MoveReportDialog'
import { MarkBilledDialog } from './MarkBilledDialog'

const getFaxEventTime = (status: FaxStatus, history: SendHistoryItem[]) => {
  if (!status || !history) return ''
  return [...history]
    ?.sort((a, b) => (a.when > b.when ? -1 : 1))
    ?.find((event) => event.newStatus === status)?.when
}

const faxStates = {
  NOT_READY: 'Preparing Fax',
  READY: 'Preparing Fax',
  SENDING: 'Sending Fax',
  SENT: 'Faxed',
  FAILED: 'Retrying Fax',
  FAILED_PERMANENTLY: 'Failed Fax',
  CANCELLED: 'Faxing cancelled',
}

const activeFaxStates = ['NOT_READY', 'READY', 'IMMEDIATE', 'SENDING']

interface IProps {
  order: Order
  userType: 'lab' | 'dme'
  openUpdateModal: () => void
  openDisregardModal: (reportId?: number) => void
  reportsSelections: number[]
  setReportsSelections: Dispatch<SetStateAction<number[]>>
  setDisregardFaxNumber: Dispatch<SetStateAction<string>>
  billingSucceeded: boolean
}

const formatFaxNumber = (number: string) => {
  const numericOnly = (number.startsWith('+1') ? number.slice(2, number.length) : number).replace(
    /\D/g,
    '',
  )

  return `(${numericOnly.slice(0, 3)}) ${numericOnly.slice(3, 6)}-${numericOnly.slice(6, 10)}`
}

export const ReportListing = ({
  order,
  userType,
  openUpdateModal,
  openDisregardModal,
  reportsSelections,
  setReportsSelections,
  setDisregardFaxNumber,
  billingSucceeded,
}: IProps) => {
  const [openReport, setOpenReport] = useState('')
  const [openMoveReport, setOpenMoveReport] = useState(false)
  const [openMarkBilled, setOpenMarkBilled] = useState(false)
  const [cancelReportFax] = useCancelReportFaxMutation()
  const [scheduleReportFax] = useScheduleReportFaxMutation()
  const [removeDeviceReportData] = useRemoveDeviceReportDataMutation()
  const [markOrderReportAsSent] = useMarkOrderReportAsSentMutation()
  const [refaxOrderReport] = useRefaxOrderReportMutation()
  const [markOrderAsBilled] = useMarkOrderAsBilledMutation()
  const renderRequests = order?.reportRenderRequests
    .filter((rr) => !rr.before)
    .filter((i) =>
      userType === 'lab'
        ? i.type === 'REPORT_PDF' || i.type === 'DISREGARD_LETTER'
        : i.type === 'REPORT_PDF' && !i.wiped && !i.disregarded,
    )
    .sort((a, b) => (a.reportId > b.reportId ? 1 : -1))
  const faxing = keyBy(order?.faxing, 'id')
  const isLabUser = userType === 'lab'
  const queuedIn = moment(order.communicationScheduledOn).diff(moment(), 'seconds')
  const { prerequisitesForReport } = orderStateHelper(order)
  const blockingTasks = order?.tasks?.filter(
    (t) => t.blocking && !t.resolved && t.namespace === 'DEVICE_DATA_PROBLEM',
  )

  const showPrerequisites = () => (
    <div className="mt-3 pt-2" css={{ fontSize: '14px', '& li': { fontSize: '14px' } }}>
      Remaining pre-requisites:
      <ul css={{ listStyleType: 'disc', marginTop: '5px' }}>
        {!prerequisitesForReport?.isPatientValid && (
          <li>Valid and complete patient info must be provided</li>
        )}
        {!prerequisitesForReport?.isPhysicianValid && (
          <li>Valid and complete physician info must be provided</li>
        )}
        {!prerequisitesForReport?.isPayerValid && (
          <li>Valid and complete payer info must be provided</li>
        )}
        {!prerequisitesForReport?.isRxProvidedOrPromised && <li>RX documents must be uploaded</li>}
        {!prerequisitesForReport?.isAobProvidedOrPromised && (
          <li>AOB documents must be uploaded</li>
        )}
        {!prerequisitesForReport?.isVerificationDone && (
          <li>Lab needs to verify all order information</li>
        )}
        {prerequisitesForReport?.pendingDeviceUpload && <li>Device data must be uploaded</li>}
        {prerequisitesForReport?.pendingSessionsSubmission && (
          <li>Device sessions need to be selected</li>
        )}
        {!!prerequisitesForReport?.pendingTasks && (
          <li>Device data sessions are pending Lab review</li>
        )}
        {!prerequisitesForReport?.diagnosisCodesProvided && <li>Diagnosis codes must be added</li>}
      </ul>
    </div>
  )

  return (
    <div className="mx-2 p-3" css={{ paddingBottom: '4px !important' }}>
      <MoveReportDialog
        show={openMoveReport}
        onClose={() => setOpenMoveReport(false)}
        sourceOrder={Number(order.id)}
      />
      <MarkBilledDialog
        show={openMarkBilled}
        onClose={() => setOpenMarkBilled(false)}
        markOrderAsBilled={(reason: string) =>
          markOrderAsBilled({ orderId: Number(order.id), reason }).unwrap()
        }
      />
      <TabHeader
        title="Reports & Faxing"
        actionButtons={
          isLabUser && [
            <>
              {queuedIn > 0 ? (
                <div className="d-flex justify-content-center align-items-center" css={{ flex: 1 }}>
                  {Object.keys(faxing)
                    ?.map((x) => faxing[x].status)
                    .includes('READY') && (
                    <>
                      <CountDown seconds={queuedIn} />
                      <Button
                        variant="outline-primary"
                        className="ml-2"
                        onClick={() =>
                          confirm('', {
                            title: 'Are you sure?',
                            description: 'This will fax all pending reports immediately.',
                            yesAction: 'Yes',
                          })
                            .then(() =>
                              scheduleReportFax({
                                orderId: Number(order.id),
                                in: 1,
                              }),
                            )
                            .catch()
                        }>
                        Send immediately
                      </Button>
                      <Button
                        variant="outline-danger"
                        className="ml-2"
                        onClick={() => cancelReportFax({ orderId: Number(order.id) })}>
                        Cancel
                      </Button>
                    </>
                  )}
                </div>
              ) : (
                <>
                  {isLabUser && renderRequests.length !== 0 && (
                    <>
                      <Button
                        variant="outline-danger"
                        className="ml-2"
                        onClick={() => {
                          confirm('', {
                            title: 'Are you sure?',
                            description:
                              'This will permanently delete the report and all device data.',
                            yesAction: 'Delete',
                          })
                            .then(() => removeDeviceReportData({ orderId: Number(order.id) }))
                            .catch()
                        }}>
                        Delete report and all device data
                      </Button>

                      <Button
                        variant="outline-primary"
                        className="ml-2"
                        onClick={() => setOpenMoveReport(true)}>
                        Move report
                      </Button>
                      <Button variant="outline-primary" className="ml-2" onClick={openUpdateModal}>
                        Modify report
                      </Button>
                      {!billingSucceeded && (
                        <Button
                          variant="outline-primary"
                          className="ml-2"
                          onClick={() => setOpenMarkBilled(true)}>
                          Mark as billed
                        </Button>
                      )}
                    </>
                  )}
                  {/* <Button
                    variant="outline-primary"
                    className="ml-2"
                    onClick={() => scheduleReportFax({ orderId: Number(order.id), in: 1 })}>
                    Send Report Fax
                  </Button> */}
                </>
              )}
            </>,
          ]
        }
      />
      <ReportViewerDialog reportUrl={openReport} closeDialog={() => setOpenReport('')} />
      <div
        className="row flex-row flex-nowrap justify-content-between align-items-center p-2 mt-2"
        css={{
          color: '#6D757E',
          borderBottom: '1px solid #ced4da',
        }}>
        <div
          css={{
            width: 140,
            display: 'flex',
            alignItems: 'center',
          }}>
          {isLabUser && (
            <div
              className="d-flex align-items-center"
              css={{
                marginRight: 5,
              }}>
              <CheckboxComponent
                parentClassName="custom-control d-flex align-items-center"
                css={{
                  height: 16,
                  width: 16,
                }}
                value={Object.keys(faxing)
                  .map(Number)
                  .every((x) => reportsSelections.includes(x))}
                handleChange={(e) =>
                  setReportsSelections(e.target.checked ? Object.keys(faxing).map(Number) : [])
                }
              />
            </div>
          )}
          <div>ID</div>
        </div>
        <div
          css={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}>
          <div
            css={{
              width: '70%',
            }}>
            {order.testType === 'HST'
              ? 'Type / Date of Service'
              : order.testType === 'PSG'
                ? 'Type'
                : 'Type / Result'}
          </div>
          <div
            css={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}>
            {order.testType !== 'HST' && order.testType !== 'PSG' && (
              <div
                css={{
                  width: isLabUser ? '60%' : '40%',
                }}>
                Test Time
              </div>
            )}
            {order.testType === 'HST' && (
              <div
                css={{
                  width: isLabUser ? '60%' : '40%',
                }}>
                AHI
              </div>
            )}
            <div
              css={{
                width: isLabUser ? '60%' : '40%',
              }}>
              Created At
            </div>
            <div
              css={{
                width: '100%',
              }}>
              Status
            </div>
            {isLabUser && (
              <div
                css={{
                  width: '50%',
                }}>
                Actions
              </div>
            )}
          </div>
        </div>
      </div>
      {renderRequests.length === 0 || blockingTasks?.length ? (
        <>
          <div
            className="row flex-row flex-nowrap justify-content-center align-items-center my-2 p-3 font-italic text-muted text-bold"
            css={{ border: '1px solid #ced4da', borderRadius: 4 }}>
            {order.testType === 'HST' || order.testType === 'PSG'
              ? 'Report will be available after patient completes testing.'
              : 'A report will be generated for this order when all pre-requisites are met.'}
          </div>
          {order.testType !== 'HST' && order.testType !== 'PSG' && showPrerequisites()}
        </>
      ) : (
        renderRequests
          .filter((i) => !i.canceledAt && !i.disregarded)
          .map((report, index) =>
            report.type === 'DISREGARD_LETTER' ? (
              <>
                <Report
                  order={order}
                  report={report}
                  index={index}
                  reportsSelections={reportsSelections}
                  setReportsSelections={setReportsSelections}
                  isLabUser={isLabUser}
                  setDisregardFaxNumber={setDisregardFaxNumber}
                  openDisregardModal={openDisregardModal}
                />
                {renderRequests
                  .filter((i) => i.disregardLetterId?.includes(report.reportId))
                  .map((disregardedReport, di) => (
                    <div
                      css={{
                        paddingLeft: 35,
                        position: 'relative',
                      }}>
                      <div
                        css={{
                          height: 24,
                          width: 14,
                          borderBottom: '1.5px dashed #bfbfbf',
                          borderLeft: '1.5px dashed #bfbfbf',
                          position: 'absolute',
                          left: 7.5,
                        }}
                      />
                      <Report
                        order={order}
                        report={disregardedReport}
                        setDisregardFaxNumber={setDisregardFaxNumber}
                        openDisregardModal={openDisregardModal}
                        index={index}
                        reportsSelections={reportsSelections}
                        setReportsSelections={setReportsSelections}
                        isLabUser={isLabUser}
                        isNested={true}
                        addMarginTop={di !== 0}
                      />
                    </div>
                  ))}
              </>
            ) : (
              <Report
                order={order}
                report={report}
                setDisregardFaxNumber={setDisregardFaxNumber}
                openDisregardModal={openDisregardModal}
                index={index}
                reportsSelections={reportsSelections}
                setReportsSelections={setReportsSelections}
                isLabUser={isLabUser}
              />
            ),
          )
      )}
      {isLabUser && renderRequests.length !== 0 && (
        <div className="d-flex justify-content-end my-3">
          <Button
            variant="outline-primary"
            className="ml-2"
            disabled={
              !reportsSelections.length ||
              reportsSelections.some((x) => {
                const report = renderRequests.find(({ reportId }) => Number(reportId) === x)
                const { status } = faxing[x]
                return report.disregarded || report.wiped || status === 'SENT'
              })
            }
            onClick={() =>
              confirm('', {
                title: 'Are you sure?',
                description: 'This will mark as faxed all reports you selected.',
                yesAction: 'Proceed',
                noAction: 'Cancel',
              }).then(() =>
                markOrderReportAsSent({
                  orderId: Number(order.id),
                  reports: reportsSelections.map(String),
                }),
              )
            }>
            Mark as faxed
          </Button>
          <Button
            variant="outline-primary"
            className="ml-2"
            disabled={
              !reportsSelections.length ||
              reportsSelections.some((x) => {
                const report = renderRequests.find(({ reportId }) => Number(reportId) === x)
                return report.disregarded || report.wiped
              })
            }
            onClick={() =>
              confirm('', {
                title: 'Are you sure?',
                description: "This will fax the reports you selected to the physician's fax again.",
                yesAction: 'Proceed',
                noAction: 'Cancel',
              }).then(() =>
                refaxOrderReport({
                  orderId: Number(order.id),
                  reports: reportsSelections.map(String),
                }),
              )
            }>
            Send selected reports
          </Button>
          <Button
            variant="outline-primary"
            className="ml-2"
            onClick={() => {
              const faxNumber = order.physicianAddress?.fax

              if (faxNumber) {
                setDisregardFaxNumber(formatFaxNumber(faxNumber))
              }

              openDisregardModal()
            }}
            disabled={
              !reportsSelections.length ||
              reportsSelections.some((x) => {
                const { reportType } = faxing[x]
                return reportType === 'DISREGARD_LETTER'
              })
            }>
            Disregard selected reports
          </Button>
        </div>
      )}
    </div>
  )
}

interface ReportProps {
  order: Order
  report: any
  index: number
  reportsSelections: number[]
  openDisregardModal: (reportId?: number) => void
  setReportsSelections: Dispatch<SetStateAction<number[]>>
  setDisregardFaxNumber: Dispatch<SetStateAction<string>>
  isLabUser: boolean
  isNested?: boolean
  addMarginTop?: boolean
}

const Report = ({
  order,
  report,
  index,
  reportsSelections,
  openDisregardModal,
  setReportsSelections,
  setDisregardFaxNumber,
  isLabUser,
  isNested,
  addMarginTop,
}: ReportProps) => {
  const [markOrderReportAsSent] = useMarkOrderReportAsSentMutation()
  const [refaxOrderReport] = useRefaxOrderReportMutation()
  const [cancelOrderFax] = useCancelOrderFaxMutation()
  const [reRenderReport] = useReRenderReportMutation()

  const isHst = order?.testType === 'HST'
  const reportFiles = order?.files
  const faxing = keyBy(order?.faxing, 'id')

  const conditions = report.conditions
    ? report.conditions.oxygen
      ? 'Oxygen'
      : 'On Room Air'
    : false
  const viewableFile = report.pdfs?.viewable?.file || report.pdfs?.any?.file
  const printableFile: string | undefined = report.pdfs?.printable?.file || report.pdfs?.any?.file
  const faxedFile = report.pdfs?.faxable?.file
  const printablePreview = printableFile
    ? reportFiles[printableFile]?.previewUrl ?? reportFiles[printableFile]?.url
    : ''
  const viewablePreview = isHst
    ? printablePreview
    : viewableFile
      ? reportFiles[viewableFile]?.previewUrl
      : ''
  const faxablePreview = faxedFile ? reportFiles[faxedFile]?.previewUrl : ''
  const pending = report.queuedAt && !Object.keys(report.pdfs).length && !report.failed
  const faxInfo = faxing[report.reportId]
  const faxStateDisplay = faxStates[faxInfo.status]
  const faxStateDate = getFaxEventTime(faxInfo.status, faxInfo.sendHistory)
  const isReportDisregarded = report.disregarded
  const isWiped = report.wiped && !isReportDisregarded
  const isDisregardLetter = report.type === 'DISREGARD_LETTER'
  const disregardLetterUrl = isDisregardLetter && reportFiles[report?.pdfs?.any?.file]?.previewUrl
  const disregardedReports = order?.reportRenderRequests
    .filter((i) => i.disregardLetterId?.includes(report.reportId))
    .map(({ reportId }) => reportId)

  let statusColor

  switch (faxInfo.status) {
    case 'CANCELLED':
    case 'FAILED':
    case 'FAILED_PERMANENTLY':
      statusColor = '#EA3323'
      break
    case 'NOT_READY':
    case 'READY':
    case 'SENDING':
      statusColor = '#EEA842'
      break
    case 'SENT':
      statusColor = '#73B666'
      break
    default:
      statusColor = '#B7B7B7'
  }

  if (isWiped) {
    statusColor = '#EA3323'
  }

  if (isReportDisregarded) {
    statusColor = '#A03C38'
  }

  return (
    <div
      key={`report-${index}`}
      className={`row flex-row flex-nowrap align-items-center ${
        !isNested ? 'my-2' : addMarginTop ? 'mt-2' : ''
      } p-1 px-2`}
      css={{
        border: '1px solid #ced4da',
        borderRadius: 4,
        background: (isReportDisregarded || isWiped) && 'rgba(255,0,0,0.13)',
      }}>
      <div
        css={{
          width: isNested ? 99 : 140,
          display: 'flex',
          alignItems: 'center',
        }}>
        {isLabUser && (
          <div
            className="d-flex align-items-center"
            css={{
              marginRight: 5,
            }}>
            <CheckboxComponent
              parentClassName="custom-control d-flex align-items-center"
              css={{
                height: 16,
                width: 16,
              }}
              value={reportsSelections.includes(faxInfo.id)}
              handleChange={(e) =>
                setReportsSelections((p) =>
                  e.target.checked ? [...p, faxInfo.id] : p.filter((i) => i !== faxInfo.id),
                )
              }
            />
          </div>
        )}
        <div>
          <div
            css={{
              textAlign: 'center',
              border: '1px solid',
              borderColor: '#000',
              borderRadius: 4,
              color: '#000',
              padding: '1px 7px',
            }}>
            {report.reportId}
          </div>
        </div>
      </div>
      {report.failed && (
        <div
          className="d-flex justify-content-center align-items-center"
          css={{ flex: 1, opacity: 0.6 }}>
          {moment(report.failed).format('lll')}: This report failed to render.{' '}
          {report.failure?.split('Error')?.[0] || ''}
          <PopoverWrapper text="Re-render report">
            <Button
              css={{ borderRadius: '50%', marginLeft: 5, height: 28, width: 28 }}
              className="d-flex justify-content-center align-items-center"
              variant="primary"
              size="sm"
              onClick={() =>
                confirm('', {
                  title: 'Are you sure?',
                  description: 'This will re-queue the report for rendering.',
                  yesAction: 'Proceed',
                  noAction: 'Cancel',
                }).then(() =>
                  reRenderReport({
                    orderId: Number(order.id),
                    reportId: report.reportId,
                  }),
                )
              }>
              <FontAwesomeIcon icon={faRedo} />
            </Button>
          </PopoverWrapper>
        </div>
      )}

      {pending && (
        <div className="d-flex justify-content-center" css={{ flex: 1, opacity: 0.6 }}>
          This report is rendering...
        </div>
      )}

      {!report.failed && !pending && (
        <div
          className="d-flex align-items-center justify-content-between"
          css={{
            width: '100%',
          }}>
          <div
            className="d-flex align-items-center justify-content-between"
            css={{
              width: '70%',
            }}>
            {!isHst && order.testType !== 'PSG' ? (
              <div
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                }}>
                <div
                  css={{
                    width: '100%',
                  }}>
                  <span className="font-weight-bold">
                    {isDisregardLetter
                      ? 'Disregard Letter'
                      : report.verdict !== 0
                        ? groups[report.verdict]
                        : 'Not Qualified'}
                  </span>
                </div>
                <div
                  css={{
                    width: '100%',
                  }}>
                  <div>
                    {isDisregardLetter
                      ? disregardedReports.join(', ')
                      : `Night ${report.night} / ${conditions}`}
                  </div>
                </div>
              </div>
            ) : (
              <div
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                }}>
                <div
                  css={{
                    width: '100%',
                  }}>
                  <span className="font-weight-bold">
                    {report.hstReportType === 'PSG'
                      ? 'PSG'
                      : report.hstReportType === 'TITRATION'
                        ? 'Titration'
                        : report.hstReportType === 'HST'
                          ? 'HST'
                          : report.hstReportType === 'FAILED_HST'
                            ? 'Failed HST'
                            : report.hstReportType}
                  </span>
                </div>
                <div
                  css={{
                    width: '100%',
                  }}>
                  <div>{report.dateOfService && moment(report.dateOfService).format('ll')}</div>
                </div>
              </div>
            )}
            <div
              className="d-flex align-items-center"
              css={{
                width: 200,
              }}>
              <div css={{ minWidth: 42 }}>
                {viewablePreview && (
                  <PopoverWrapper text="View">
                    <Button variant="primary" size="sm" href={viewablePreview} target="_blank">
                      <InsertDriveFileIcon sx={{ fontSize: 16, margin: 0, padding: 0 }} />
                    </Button>
                  </PopoverWrapper>
                )}
              </div>
              <div css={{ minWidth: 42 }}>
                {printablePreview && (
                  <PopoverWrapper text="Print">
                    <Button variant="primary" size="sm" href={printablePreview} target="_blank">
                      <LocalPrintshopIcon sx={{ fontSize: 16 }} />
                    </Button>
                  </PopoverWrapper>
                )}
              </div>
              <div css={{ minWidth: 42 }}>
                {disregardLetterUrl ? (
                  <PopoverWrapper text="Physician Fax">
                    <Button variant="primary" size="sm" href={disregardLetterUrl} target="_blank">
                      <FaxIcon sx={{ fontSize: 16 }} />
                    </Button>
                  </PopoverWrapper>
                ) : (
                  faxablePreview && (
                    <PopoverWrapper text="Physician Fax">
                      <Button variant="primary" size="sm" href={faxablePreview} target="_blank">
                        <FaxIcon sx={{ fontSize: 16 }} />
                      </Button>
                    </PopoverWrapper>
                  )
                )}
              </div>
            </div>
          </div>
          <div
            css={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}>
            {order.testType !== 'HST' && order.testType !== 'PSG' && (
              <div
                css={{
                  width: '60%',
                }}>
                {report.testingTimeStarted ? moment(report.testingTimeStarted).format('lll') : '-'}
              </div>
            )}
            {order.testType === 'HST' && (
              <div
                css={{
                  width: '60%',
                }}>
                {report.ahi ?? ''}
              </div>
            )}
            <div
              css={{
                width: '60%',
              }}>
              {moment(report.queuedAt).format('lll')}
            </div>
            <div
              css={{
                width: '100%',
              }}>
              <div className="font-weight-bold" css={{ color: statusColor }}>
                {isReportDisregarded ? 'Disregarded' : isWiped ? 'Wiped' : faxStateDisplay}
              </div>
              <div>
                {faxInfo.faxNumber || order.physicianAddress?.fax
                  ? `${formatFaxNumber(
                      faxInfo.faxNumber || order.physicianAddress?.fax,
                    )} on ${moment(faxStateDate).format('lll')}`
                  : moment(faxStateDate).format('lll')}
              </div>
            </div>
            <div
              css={{
                width: '50%',
              }}>
              {isLabUser && (
                <div css={{ display: 'flex' }}>
                  {(faxInfo.status === 'FAILED_PERMANENTLY' || faxInfo.status === 'CANCELLED') && (
                    <PopoverWrapper text="Mark as sent">
                      <Button
                        css={{ borderRadius: '50%', marginLeft: 5, height: 28, width: 28 }}
                        className="d-fl ex justify-content-center align-items-center"
                        variant="primary"
                        size="sm"
                        disabled={isReportDisregarded || isWiped}
                        onClick={() =>
                          confirm('', {
                            title: 'Are you sure?',
                            description:
                              'This will mark the report as sent and remove it from the failed fax list.',
                            yesAction: 'Proceed',
                            noAction: 'Cancel',
                          })
                            .then(() =>
                              markOrderReportAsSent({
                                orderId: Number(order.id),
                                reports: [report.reportId],
                              }),
                            )
                            .catch()
                        }>
                        <FontAwesomeIcon icon={faCheck} />
                      </Button>
                    </PopoverWrapper>
                  )}
                  {activeFaxStates.includes(faxInfo.status) && (
                    <PopoverWrapper text="Cancel fax attempt">
                      <Button
                        css={{ borderRadius: '50%', marginLeft: 5, height: 28, width: 28 }}
                        className="d-flex justify-content-center align-items-center"
                        variant="primary"
                        size="sm"
                        disabled={isReportDisregarded || isWiped}
                        onClick={() =>
                          confirm('', {
                            title: 'Are you sure?',
                            description:
                              'This will cancel the report fax delivery to the physician.',
                            yesAction: 'Proceed',
                            noAction: 'Cancel',
                          })
                            .then(() =>
                              cancelOrderFax({
                                orderId: Number(order.id),
                                reports: [report.reportId],
                              }),
                            )
                            .catch()
                        }>
                        <FontAwesomeIcon icon={faStop} />
                      </Button>
                    </PopoverWrapper>
                  )}
                  <PopoverWrapper text="Retry fax">
                    <Button
                      css={{ borderRadius: '50%', marginLeft: 5, height: 28, width: 28 }}
                      className="d-flex justify-content-center align-items-center"
                      variant="primary"
                      size="sm"
                      disabled={isReportDisregarded || isWiped}
                      onClick={() =>
                        confirm('', {
                          title: 'Are you sure?',
                          description: "This will fax the report to the physician's fax again.",
                          yesAction: 'Proceed',
                          noAction: 'Cancel',
                        }).then(() =>
                          refaxOrderReport({
                            orderId: Number(order.id),
                            reports: [report.reportId],
                          }),
                        )
                      }>
                      <FontAwesomeIcon icon={faRedo} />
                    </Button>
                  </PopoverWrapper>
                  {(['SENT', 'CANCELLED', 'READY', 'FAILED_PERMANENTLY'].includes(faxInfo.status) ||
                    report.wiped) &&
                    !isDisregardLetter && (
                      <PopoverWrapper text="Disregard Fax">
                        <Button
                          css={{ borderRadius: '50%', marginLeft: 5, height: 28, width: 28 }}
                          className="d-flex justify-content-center align-items-center"
                          variant="primary"
                          size="sm"
                          onClick={() => {
                            const faxNumber = order.physicianAddress?.fax

                            if (faxNumber) {
                              setDisregardFaxNumber(formatFaxNumber(faxNumber))
                            }

                            openDisregardModal(Number(report.reportId))
                          }}>
                          <NoBackpackIcon sx={{ fontSize: 16 }} />
                        </Button>
                      </PopoverWrapper>
                    )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

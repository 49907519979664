import { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter } from 'react-router-dom'
import { Auth0Provider } from '@auth0/auth0-react'
import config from 'config/app'

import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { baseApi } from 'store/services/baseApi'
import Login from 'components/login/Login'
import PasswordResetRequest from 'components/login/ForgotPassword'
import DashboardDme from 'components/dme/DashboardDme'
import DashboardLab from 'components/lab/DashboardLab'
import Landing from 'components/landing/Landing'
import PasswordReset from 'components/landing/PasswordReset'
import Protected from 'components/login/Protected'
import LoginCallback from 'components/login/LoginCallback'
import DashboardPhysicians from './components/physicians/DashboardPhysicians'
import { getInit } from 'store/slice/initSlice'
import './sass/app.scss'

const auth0Domain: string = config?.auth0?.domain ?? ''
const auth0ClientId: string = config?.auth0?.clientId ?? ''
const auth0AuthRedirectUri: string = config?.auth0?.authRedirectUri ?? ''

function App() {
  const dispatch = useAppDispatch()
  const [init, setInit] = useState(false)
  const { data } = useAppSelector((state) => state.login)
  const { pending } = useAppSelector((state) => state.init)
  const initRef = useRef(false)

  useEffect(() => {
    dispatch(baseApi.util.resetApiState())

    if (
      data?.access_token &&
      !pending &&
      !window.location.pathname.includes('login') &&
      !initRef.current
    ) {
      dispatch(getInit())
    }
    setInit(true)
    initRef.current = true
  }, [])

  if (!init) {
    return null
  }

  return (
    <Auth0Provider
      domain={auth0Domain}
      clientId={auth0ClientId}
      authorizationParams={{
        redirect_uri: auth0AuthRedirectUri,
      }}>
      <BrowserRouter>
        <Routes>
          <Route index element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/login/callback" element={<LoginCallback />} />
          <Route path="/forgot-password" element={<PasswordResetRequest />} />
          <Route
            path="/physicians/*"
            element={
              <Protected>
                <DashboardPhysicians />
              </Protected>
            }
          />
          <Route
            path="/lab/*"
            element={
              <Protected>
                <DashboardLab />
              </Protected>
            }
          />
          <Route
            path="/dme/*"
            element={
              <Protected>
                <DashboardDme />
              </Protected>
            }
          />
          <Route path="/landing/password-reset" element={<PasswordReset />} />
          <Route path="/landing/*" element={<Landing />} />
        </Routes>
      </BrowserRouter>
    </Auth0Provider>
  )
}

export default App

export enum Panels {
  COMPANY_INFO = 0,
  EMAIL = 1,
  KEY_PEOPLE = 2,
  REGIONS = 3,
  OFFICES = 4,
  LOGS = 5,
}

type PanelStates = boolean[]

export const getDefaultPanelStates = (companyId?: string): PanelStates => {
  const panelStates = [true, true, true, true, true]

  if (!companyId) {
    panelStates[1] = false
    panelStates[2] = false
    panelStates[3] = false
    panelStates[4] = false
  }

  return panelStates
}

/** @jsxImportSource @emotion/react */
import { useEffect, useState, useRef } from 'react'
import Button from 'react-bootstrap/Button'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import Spinner from 'react-bootstrap/Spinner'

import TabHeader from 'components/atoms/TabHeader'
import SessionsTable from 'components/dme/atoms/SessionsTable'
import { Step, Stepper } from 'components/atoms/Stepper'
import {
  DeviceListOptions,
  devices as deviceTypeList,
  useDevice,
  useReader,
} from 'components/reader'
import { axiosRequest, maybePlural, spellNumber } from 'helpers/generalHelper'
import UploadInstructions from 'components/dme/instructions/upload'
import ErrorMessage from 'components/dme/errors/ErrorMessage'
import config from 'config/app'
import { confirm } from 'components/molecules/Confirmation'
import { Event } from 'components/reader/reader'
import { Order, TimestampOverride } from 'store/types'
import { toLocalDateString } from 'helpers/format'
import { useSelectSessionsMutation, useDeleteDeviceDataMutation } from 'store/services/dmeOrder'
import routes from 'components/dme/constants/routes'
import { DeviceConnectError } from 'components/dme/errors/DeviceConnectError'
import { Cap35Upload } from './upload/cap35'
import { useAppSelector } from 'hooks/redux'
import versionConfig from 'config/version.json'
import { readCapnostream35DataFile } from 'helpers/capnostream35'

const host = config?.newApi
const getUploadEndpoint = (orderId: string | number) => `${host}/dme/orders/${orderId}/device-data`

enum UploadSteps {
  INITIAL = 0,
  DEVICE_LIST = 1,
  UPLOADING = 2,
  SESSION_SELECTION = 3,
}

interface IProps {
  order: Order
  refetchOrder: () => void
}
export interface SessionSelection {
  [night: string]: number[]
}

const UploadTab = ({ order, refetchOrder }: IProps) => {
  const navigate = useNavigate()
  const token = localStorage.getItem('access_token')
  const init = useAppSelector((state) => state.init)
  const [step, setStep] = useState<UploadSteps>(UploadSteps.INITIAL)
  const [devices, setDevices] = useState<DeviceListOptions[] | null>(null)
  const [selectedDevice, setSelectedDevice] = useState<DeviceListOptions>()
  const [loading, setLoading] = useState(false)
  const device = useDevice({
    selectedDevice,
    deviceType: order?.device?.model,
    orderId: order?.id,
    token,
  })
  const { error, reader } = useReader()
  const [lastError, setLastError] = useState<{ type: string; message: string } | null>(null)
  const [sessionSelection, setSessionSelection] = useState<SessionSelection>({})
  const [timeOverrides, setTimeOverrides] = useState({})
  const [anomalies, setAnomalies] = useState<string[]>([])
  const [cap35UploadProgress, setCap35UploadProgress] = useState(0)
  const [cap35UploadError, setCap35UploadError] = useState('')
  const [selectSessions] = useSelectSessionsMutation()
  const [deleteDeviceData] = useDeleteDeviceDataMutation()
  const orderId = order?.id
  const deviceImage = deviceTypeList.find((d) => d.type === order?.device?.model)?.image
  const numSessionsSelected = Object.keys(sessionSelection).length
  const nights = order?.tests && order?.tests?.length > 1 ? 2 : 1
  const initializedView = useRef(false)
  const deviceRef = useRef(device)
  deviceRef.current = device

  const sessions = order?.sessions?.sessions.map((s, index) => ({
    ...s,
    index,
  }))
  const sessionGroups = order?.sessions?.sessionGroups

  const deviceStatus = device?.status

  const connect = () => {
    setLastError(null)
    reader.disconnect()
    reader.connect()

    reader.on('connect', (e: Event) => {
      console.log('Reader connected.')
      reader.send()
      reader.sendClientInfo({
        clientVersion: versionConfig.version,
        userId: init?.id,
        username: init?.profile.email,
      })
      reader.getConnectionList()
    })
    reader.on('deviceList', (deviceList: DeviceListOptions[]) => {
      console.log('Device List: ', deviceList)
      setDevices(deviceList)
      setStep(UploadSteps.DEVICE_LIST)

      reader.setUploadEndpoint(getUploadEndpoint(orderId))
    })

    reader.on('uploadCompleted', () => {
      reader.disconnect()
      refetchOrder()
      setStep(UploadSteps.SESSION_SELECTION)
    })

    reader.on('socketError', (error: string) => {
      console.log('SOCKET ERROR in upload tab', error)
      setLastError({ type: 'SocketError', message: error })
    })
    reader.on('uploadError', (error: string) => {
      console.log('UPLOAD ERROR in upload tab', error)
      setLastError({ type: 'UploadError', message: `Device upload failed. ${error}` })
    })
    reader.on('uploadRejected', (error: string) => {
      console.log('UPLOAD ERROR in upload tab', error)
      setLastError({ type: 'UploadRejected', message: error })
    })
    reader.on('deviceResetCompleted', () => {
      console.log('Device reset completed.')
      setLastError(null)
      setStep(UploadSteps.INITIAL)
    })
    reader.on('resetError', (error) => {
      console.log('Device reset error.')
      setLastError({ type: 'ResetError', message: `Device reset failed. ${error}` })
      setStep(UploadSteps.INITIAL)
    })
    reader.on('error', (error: string) => {
      console.log('ERROR in upload tab', error)
      setLastError({ type: 'DeviceReadError', message: error })
    })
  }

  useEffect(() => {
    if (initializedView.current) return

    if (deviceStatus === 'uploaded' || sessions?.length) {
      if (!order.thirdPartyDevice && order.device?.model === 'capnostream35') {
        setStep(1)
      } else {
        setStep(UploadSteps.SESSION_SELECTION)
      }
      initializedView.current = true
    }
  }, [deviceStatus, sessions])

  useEffect(() => {
    if (!error || !error?.message) return

    console.log('>>>>>>>>>>>>>>> Reader Error: ', error)
    setLastError(error)
  }, [error])

  const onCancelUpload = () => {
    setDevices(null)
    setLastError(null)
    setStep(UploadSteps.INITIAL)
    reader.disconnect()
  }

  const onDeleteDeviceData = () => {
    confirm('Device Data')
      .then(() => {
        deleteDeviceData({
          orderId: Number(orderId),
        })
          .unwrap()
          .then(() => {
            setTimeout(() => setStep(UploadSteps.INITIAL), 500)
          })
      })
      .catch()
  }

  const onUploadCapnostream35Files = async (inputFiles: File[]) => {
    const url = getUploadEndpoint(orderId)
    const files: string[] = []
    try {
      for (const inputFile of inputFiles) {
        const file: string = await readCapnostream35DataFile(inputFile)
        files.push(file)
      }
    } catch (error) {
      const message: string = error instanceof Error ? error.message : 'Bad file selected.'
      setCap35UploadProgress(0)
      setCap35UploadError(`Upload failed: ${message}`)
      return
    }
    const data = files.join('\n')
    const deviceType = 'capnostream35'
    await axiosRequest({
      data: {
        data,
        deviceType,
        orderId,
      },
      method: 'POST',
      url,
      onUploadProgress: (p) => setCap35UploadProgress((100 * p.loaded) / p.total),
    })
      .then(() => {
        setStep(1)
        refetchOrder()
      })
      .catch((error) => {
        console.log('UPLOAD ERROR', error)
        setCap35UploadProgress(0)
        setCap35UploadError('Upload failed. Please try again.')
      })
  }

  const onFinalizeUpload = async () => {
    setLoading(true)
    let startTime: string
    let endTime: string
    try {
      const numNightsRequired = Number(nights)
      const numNightsSelected = Number(numSessionsSelected)

      if (!numSessionsSelected || numSessionsSelected < nights) {
        confirm('', {
          title: 'Not enough nights selected',
          description: () => {
            return (
              <div>
                <p>
                  Only {spellNumber(numNightsSelected)} {maybePlural('night', numNightsSelected)} of
                  data has been selected for a {spellNumber(numNightsRequired)}-night order. The
                  patient must test for another night, or to proceed with{' '}
                  {spellNumber(numNightsSelected)}
                  &nbsp;{maybePlural('night', numNightsSelected)} of data, change the testing
                  conditions to a &nbsp;{spellNumber(numNightsSelected)}-night order.
                </p>
              </div>
            )
          },
          noAction: '',
          yesAction: 'OK',
          yesColor: 'light',
        })
        return false
      }

      const sessionSubmission = Array.from((sessions ?? [])?.map((s) => -1))
      const timestampOverrides: TimestampOverride[] = Array.from((sessions ?? [])?.map((s) => null))
      const anomaliesSubmission = Array.from((sessions ?? [])?.map((s) => null))

      sessionSelection?.[0]?.forEach((sessionIdx, idx) => {
        if (timeOverrides[0]) {
          startTime = idx === 0 ? timeOverrides[0].toISOString() : endTime
          endTime = moment(startTime).add(sessions[sessionIdx]?.duration, 'seconds').toISOString()
          timestampOverrides[sessionIdx] = {
            timeStarted: toLocalDateString(startTime),
            timeStopped: toLocalDateString(endTime),
          }
        }

        if (sessions[sessionIdx].isValid) {
          sessionSubmission[sessionIdx] = 1
        }
        anomaliesSubmission[sessionIdx] = anomalies[0][idx]
      })
      sessionSelection?.[1]?.forEach((sessionIdx, idx) => {
        if (timeOverrides[1]) {
          startTime = idx === 0 ? timeOverrides[1].toISOString() : endTime
          endTime = moment(startTime).add(sessions[sessionIdx]?.duration, 'seconds').toISOString()
          timestampOverrides[sessionIdx] = {
            timeStarted: toLocalDateString(startTime),
            timeStopped: toLocalDateString(endTime),
          }
        }

        if (sessions[sessionIdx].isValid) {
          sessionSubmission[sessionIdx] = 2
        }
        anomaliesSubmission[sessionIdx] = anomalies[1][idx]
      })

      await selectSessions({
        orderId: Number(orderId),
        sessions: sessionSubmission,
        timestampOverrides,
        anomalies: anomaliesSubmission,
      })

      confirm('', {
        title: 'Clear Device Data?',
        description:
          'Do you want to clear the device data so it is ready to hand out to the next patient?',
        yesColor: 'light',
      })
        .then(() => navigate(`${routes.index}${routes.device.deviceReset}`))
        .catch(() => {
          navigate(`${routes.index}${routes.order.edit}${orderId}/${routes.order.report}`)
          setTimeout(() => refetchOrder(), 2000)
        })
    } catch (error) {
      console.log('UPLOAD ERROR', error)
      setLastError(error)
    } finally {
      setLoading(false)
    }
  }

  // console.log('lastError ===>', lastError?.type, lastError?.message)

  return (
    <div className="container py-2">
      <div className="row flex-row flex-nowrap justify-content-between align-items-center pl-3">
        <TabHeader title="Upload device data" />

        {lastError && (
          <div className="flex-shrink-0 mr-4">
            <button className="btn btn-default" onClick={onCancelUpload}>
              Try again
            </button>
          </div>
        )}

        {(step === UploadSteps.SESSION_SELECTION ||
          (order.device?.model === 'capnostream35' && step === 1)) &&
          !lastError && (
            <div className="flex-shrink-0 mr-4">
              <Button variant="outline-danger" className="mr-2" onClick={onDeleteDeviceData}>
                Cancel Upload & Delete Device Data
              </Button>
              {nights === 2 && (
                <Button
                  variant="outline-primary"
                  className="mr-2"
                  onClick={() => {
                    setStep(UploadSteps.INITIAL)
                  }}>
                  Upload More Data
                </Button>
              )}
              <button
                className="btn btn-primary"
                onClick={onFinalizeUpload}
                disabled={loading || !numSessionsSelected}>
                Complete Upload
                {loading && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="ml-2"
                  />
                )}
              </button>
            </div>
          )}
      </div>

      {lastError && lastError?.type !== 'ServiceError' && (
        <ErrorMessage type={lastError?.type} message={lastError?.message} />
      )}
      {['DeviceReadError', 'ServiceError'].includes(lastError?.type) && (
        <DeviceConnectError onReturn={connect} />
      )}

      {order?.thirdPartyDevice && <div>Patient is using a NightOwl device for this test.</div>}

      {!!order &&
        !order.thirdPartyDevice &&
        order.device?.model !== 'capnostream35' &&
        !['DeviceReadError', 'ServiceError'].includes(lastError?.type) && (
          <Stepper step={step} className="px-3">
            <Step>
              <Button disabled={!order?.device?.id} onClick={connect}>
                Detect Device(s)
              </Button>
              {!order?.device?.id && (
                <div className="mt-3">
                  Please choose an oximeter for this order before uploading.
                </div>
              )}
            </Step>

            <Step>
              <div className="d-flex flex-column align-items-start">
                <div className="row mb-4">
                  <div className="col-5">
                    <img src={deviceImage} alt="device" />
                  </div>
                  <div className="col-7 d-flex align-items-center">
                    <UploadInstructions type={order?.device?.model} />
                  </div>
                </div>
                {!!devices?.length ? (
                  devices.map((d) => (
                    <>
                      <Button
                        onClick={() => {
                          setSelectedDevice(d)
                          setStep(UploadSteps.UPLOADING)
                          setTimeout(() => deviceRef.current.read(), 1000)
                        }}
                        className="mb-2"
                        css={{ width: 300 }}>
                        Upload - {d.optionLabel}
                      </Button>
                    </>
                  ))
                ) : (
                  <div className="mb-2 mt-2">
                    <h4>
                      No devices detected. <br />
                    </h4>
                    <Button onClick={connect}>
                      <i className="fas fa-undo mr-2" /> Retry Detecting Devices
                    </Button>
                  </div>
                )}
              </div>
            </Step>

            <Step>
              <div className="row mb-4">
                <div className="col-6">
                  <img src={deviceImage} alt="device" />
                </div>
                {!lastError && (
                  <div
                    className="col-6 d-flex justify-content-center flex-column"
                    css={{ paddingLeft: 40 }}>
                    <p>
                      Status: <strong>{device?.status}</strong>
                    </p>
                    <p>
                      Device read data: <strong>{device?.progress} bytes</strong>
                    </p>
                    {device?.status === 'uploaded' ? (
                      <p>
                        Data uploaded for order: <strong>{orderId}</strong>
                      </p>
                    ) : null}
                  </div>
                )}
              </div>
            </Step>

            <Step>
              <SessionsTable
                sessions={sessions || []}
                sessionGroups={sessionGroups || []}
                tests={order.tests || {}}
                sessionSelection={sessionSelection}
                onChange={setSessionSelection}
                timeOverrides={timeOverrides}
                setTimeOverrides={setTimeOverrides}
                setAnomalies={setAnomalies}
                finalizeUpload={onFinalizeUpload}
                loading={loading}
              />
            </Step>
          </Stepper>
        )}
      {!!order && !order.thirdPartyDevice && order.device?.model === 'capnostream35' && (
        <Stepper step={step} className="px-3">
          <Step>
            <Cap35Upload
              upload={onUploadCapnostream35Files}
              uploadProgress={cap35UploadProgress}
              error={cap35UploadError}
            />
          </Step>
          <Step>
            <SessionsTable
              sessions={sessions || []}
              sessionGroups={sessionGroups || []}
              tests={order.tests || {}}
              sessionSelection={sessionSelection}
              onChange={setSessionSelection}
              timeOverrides={timeOverrides}
              setTimeOverrides={setTimeOverrides}
              setAnomalies={setAnomalies}
            />
          </Step>
        </Stepper>
      )}
    </div>
  )
}

export default UploadTab

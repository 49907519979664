/** @jsxImportSource @emotion/react */
import { useEffect, useState, SyntheticEvent } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { skipToken } from '@reduxjs/toolkit/query/react'
import { pick } from 'lodash'

import { useAppSelector, useAppDispatch } from 'hooks/redux'
import * as generalHelper from 'helpers/generalHelper'
import Button from 'components/atoms/Button'
import TabHeader from 'components/atoms/TabHeader'
import { confirm } from 'components/molecules/Confirmation'
import routes from '../../constants/routes'
import { setModal } from 'store/slice/modalSlice'
import ButtonModal from 'components/atoms/ButtonModal'
import { useAssignPhysicianMutation, useRemovePhysicianMutation } from 'store/services/dmeOrder'
import {
  useGetPhysiciansQuery,
  useImportPhysicianMutation,
  useUpdatePhysicianMutation,
  useCreatePhysicianAddressMutation,
  useUpdatePhysicianAddressMutation,
} from 'store/services/physician'
import { PhysicianSearch, SearchFormValues } from 'components/organisms/physician/PhysicianSearch'
import { Order, Physician, PhysicianAddress } from 'store/types'
import { formatPhoneNumber } from 'helpers/format'

interface IProps {
  actionButtons?: any
  order: Order
  onModify?: () => void
  refetchOrder: () => void
}

const PhysicianTab = ({ order, actionButtons, onModify = () => {}, refetchOrder }: IProps) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { orderId, tab } = useParams()
  const isNewOrder = location.pathname.indexOf('newOrder') > -1
  const [searchValues, setSearchValues] = useState<SearchFormValues>({})
  const [resultsLimit, setResultsLimit] = useState(4)
  // const [selectedPhysician, setSelectedPhysician] = useState({})
  const [selectedLatestPhy, setSelectedLatestPhy] = useState('')
  const currentOfficeId = useAppSelector((state) => state.init.currentOfficeId)
  const offices = useAppSelector((state) => state.init.offices)
  const officeState = offices?.find((o) => o.id == currentOfficeId)?.addressState

  const { data: physicianDetails, refetch: refetchPhysician } = useGetPhysiciansQuery(
    order?.physician?.npi ? { npi: order.physician.npi, officeId: currentOfficeId } : skipToken,
  )
  const physicianAddresses = physicianDetails?.[0]?.addresses.filter(
    (address) => address.addressPurpose !== 'MAILING',
  )

  const [assignPhysician, { isLoading }] = useAssignPhysicianMutation()
  const [modifyPhysician] = useUpdatePhysicianMutation()
  const [removePhysician] = useRemovePhysicianMutation()
  const [importPhysician] = useImportPhysicianMutation()
  const [createPhysicianAddress] = useCreatePhysicianAddressMutation()
  const [updatePhysicianAddress] = useUpdatePhysicianAddressMutation()

  useEffect(() => {
    if (officeState && !searchValues.addressState) {
      setSearchValues((p) => ({ ...p, addressState: officeState }))
    }
  }, [officeState])

  const handleClear = (event?: SyntheticEvent) => {
    if (event) {
      event.preventDefault()
    }
    setSearchValues({ addressState: officeState })
    setSelectedLatestPhy('')
    setResultsLimit(0)
  }

  const setPhysician = async (physician: Physician, location: PhysicianAddress) => {
    if (!orderId) return
    let physicianId = physician.id
    let addressUuid = location?.uuid
    if (!physicianId) {
      const { data } = await importPhysician({
        officeId: currentOfficeId,
        ...pick(physician, ['npi', 'credential']),
        address: location,
      })
      physicianId = data.id
      addressUuid = data.addresses[0].uuid
    } else if (!location.uuid) {
      const { data } = await createPhysicianAddress({
        officeId: currentOfficeId,
        physicianId,
        ...location,
      })
      addressUuid = data.uuid
    }

    const payload = {
      orderId: Number(orderId),
      physicianId,
      addressUuid,
    }
    assignPhysician(payload)
  }

  const updatePhysician = async (physician: Physician, location: PhysicianAddress) => {
    if (!orderId) return
    const physicianId = physician.id
    let addressUuid = location.uuid

    await modifyPhysician({
      officeId: currentOfficeId,
      id: physicianId,
      npi: physician.npi,
      credential: physician.credential,
    })

    const payload = {
      orderId: Number(orderId),
      officeId: currentOfficeId,
      physicianId,
      ...location,
    }
    if (addressUuid) {
      await updatePhysicianAddress(payload).unwrap()
    } else {
      const { data } = await createPhysicianAddress(payload)
      addressUuid = data.uuid
    }

    if (order.physician.id !== physicianId || order.physicianAddress.uuid !== addressUuid) {
      assignPhysician({
        orderId: Number(orderId),
        physicianId,
        addressUuid,
      })
    }
    refetchOrder()
    refetchPhysician()
  }

  const openPhysicianModal = (
    physician: Physician,
    indexLocation: number | null,
    preValidate = false,
  ) => {
    dispatch(
      setModal({
        open: true,
        physician,
        indexLocation,
        setPhysician,
        preValidate,
      }),
    )
  }

  const noPhysicianSelected = (
    <div className="card">
      <div className="card-body text-center font-italic text-muted">
        {generalHelper.t('No Physician is currently assigned to this order')}.
      </div>
    </div>
  )

  const physicianSelection = () => {
    const target = order.physician
    const verified = target?.addresses?.some((a) => a.status === 'approved')
    const imported = target?.addresses?.some((a) => a.status === 'imported')

    return (
      <div>
        <div className="d-flex justify-content-end mb-2">
          <Button
            className="btn btn-sm btn-outline-danger mr-2 float-right"
            label="Remove"
            type="button"
            onClick={() => {
              if (orderId) {
                confirm('Physician').then(() => {
                  removePhysician({ orderId })
                  handleClear()
                })
              }
            }}
          />
        </div>
        <div className="row border rounded p-2 justify-content-between">
          <div className="col-1 d-flex align-items-center ml-3">
            {verified ? (
              <i className="text-success fa fa-user-check" />
            ) : imported ? (
              <i className="text-success fa fa-user-md" />
            ) : (
              <i className="text-default fa fa-user-md" />
            )}
          </div>
          <div className="col-2 d-flex align-items-center">{target.npi}</div>
          <div className="col-2 d-flex align-items-center">
            {target.firstName} {target.lastName}
          </div>

          <div className="col-6">
            {physicianAddresses?.map((address, idx) => (
              <div
                className={`py-1 mb-2  ${
                  idx < physicianAddresses.length - 1 ? 'border-bottom' : ''
                }`}>
                <div
                  className="d-flex"
                  css={
                    order.physicianAddress?.uuid === address.uuid && {
                      border: '1px solid #007bff',
                      borderRadius: '5px',
                      padding: '5px',
                      background: '#e9f5ff',
                    }
                  }>
                  <div>
                    <div>
                      {address?.addressStreetLine1}
                      {', '}
                      {address?.addressStreetLine2 ? address?.addressStreetLine2 : ''}
                      <br />
                      {address?.addressCity}
                      {', '}
                      {address?.addressState} {generalHelper.formatZip(address?.addressZip)}
                    </div>
                    <div className="pb-2">
                      {address?.telephone && <strong>{generalHelper.t('Tel')}: </strong>}
                      {formatPhoneNumber(address?.telephone)}
                      {address.fax ? (
                        <>
                          <br />
                          <strong>{generalHelper.t('Fax')}: </strong>
                          {formatPhoneNumber(address.fax)}{' '}
                        </>
                      ) : (
                        <>
                          <br />
                          <span className="text-danger">(missing fax #)</span>
                        </>
                      )}
                    </div>
                    {address.comment && (
                      <div className="text-gray" css={{ fontStyle: 'italic' }}>
                        {address.comment}
                      </div>
                    )}
                  </div>
                  <div className="col text-right d-flex align-items-center justify-content-end">
                    <ButtonModal
                      label="Modify"
                      className="btn btn-sm btn-primary ml-1 mr-2"
                      css={{ minWidth: 60 }}
                      type="button"
                      data_toggle="modal"
                      data_target="#modify_physician_modal"
                      onClick={() => {
                        onModify()
                        dispatch(
                          setModal({
                            open: true,
                            physician: target,
                            physicianAddress: address,
                            setPhysician: updatePhysician,
                          }),
                        )
                      }}
                    />
                    <div css={{ minWidth: 60 }} className="d-flex justify-content-center">
                      {order.physicianAddress?.uuid === address.uuid ? (
                        <div className="d-flex flex-column align-items-center">
                          <i className="fa fa-check text-success" css={{ fontSize: '1.8rem' }} />
                          <span className="text-success" css={{ fontSize: '0.8rem' }}>
                            Selected
                          </span>
                        </div>
                      ) : !address.fax ? (
                        <ButtonModal
                          label="Select"
                          className="btn btn-sm btn-primary"
                          data_toggle="modal"
                          data_target="#modify_physician_modal"
                          onClick={() => {
                            dispatch(
                              setModal({
                                open: true,
                                physician: target,
                                physicianAddress: address,
                                setPhysician: updatePhysician,
                              }),
                            )
                          }}
                        />
                      ) : (
                        <Button
                          className="btn btn-sm btn-primary"
                          label="Select"
                          type="button"
                          onClick={() => {
                            setPhysician(order.physician, address)
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {(imported || verified) && (
              <div className="row">
                <div className="col-6" />
                <div className="col-6 mt-2 pr-4 d-flex justify-content-end">
                  <ButtonModal
                    className="btn btn-sm btn-link mr-1"
                    data_toggle="modal"
                    data_target="#modify_physician_modal"
                    onClick={() => {
                      onModify()
                      openPhysicianModal(order.physician, null)
                    }}>
                    ADD LOCATION
                  </ButtonModal>
                </div>
              </div>
            )}
          </div>
        </div>
        {tab === 'physician' && (
          <Button
            className="btn btn-sm btn-primary mr-2 ml-1 mt-4 float-right"
            style={{ minWidth: 60 }}
            label="Next"
            type="button"
            onClick={() => navigate(`${routes.index}${routes.order.edit}${orderId}/insurance`)}
          />
        )}
      </div>
    )
  }

  return (
    <>
      <div className="container">
        <TabHeader
          title="Physician Information"
          back={`${routes.index}${routes.order.edit}${orderId}/patient`}
          next={`${routes.index}${routes.order.edit}${orderId}/insurance`}
          actionButtons={actionButtons}
        />
      </div>
      {isNewOrder && <hr className="mt-0" />}
      <div className={`container ${isNewOrder ? 'pb-4' : 'p-4'}`}>
        {order?.physician && false && (
          <div className="row">
            <div className="col-sm-12 mt-4 mb-4">
              <div className="bg-primary p-2 rounded">
                This information is marked as being ready for Lab Review. Any changes to the
                information below will reset this status.
              </div>
              <div className="bg-secondary p-2 rounded">
                This information has been submitted for Lab Review. If any changes are needed to the
                below information, send a Message to the Lab.
              </div>
              <div className="bg-success p-2 rounded">
                This information has been verified by the Lab. If any changes are needed to the
                below information, send a Message to the Lab.
              </div>
            </div>
          </div>
        )}

        {(order?.physician?.id || order?.physician?.npi) && physicianSelection()}
        {!isNewOrder && !order?.physician && noPhysicianSelected}
        {isNewOrder && !order.physician?.id && !order.physician?.npi && noPhysicianSelected}

        <div className="mt-5" />

        {!order?.physician && (
          <PhysicianSearch
            usedBy="dme"
            order={order}
            searchValues={searchValues}
            setSearchValues={setSearchValues}
            resultsLimit={resultsLimit}
            setPhysician={setPhysician}
            setResultsLimit={setResultsLimit}
            handleClear={handleClear}
            openPhysicianModal={openPhysicianModal}
            selectedLatestPhy={selectedLatestPhy}
            setSelectedLatestPhy={setSelectedLatestPhy}
            currentOfficeId={currentOfficeId}
            onSelect={onModify}
          />
        )}
      </div>
    </>
  )
}

export default PhysicianTab

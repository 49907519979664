/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { OrderLogs } from 'components/organisms/log/OrderLogs'

import MsgPanel from 'components/organisms/message/Panel'
import NotePanel from 'components/organisms/note/Panel'
import { Message, Note } from 'store/types'
import { usePinNoteMutation } from 'store/services/notes'

const StyledLink = styled.span`
  margin-bottom: -2px !important;
  &.active {
    &:hover {
      border-bottom: 1px solid white !important;
      margin-bottom: -2px !important;
    }
  }
`

const StyledTabBody = styled.div`
  min-height: 200px;

  .msg_panel {
    min-height: unset;

    .cs-message-list__scroll-wrapper {
      max-height: 40vh;
    }
  }
`

interface IProps {
  orderId: number
  tab: string
  setTab: (v: string) => void
  className?: string
  messages?: Message[]
  addMessage: (m: string) => Promise<void>
  notes?: Note[]
  addNote: (m: string, type?: string) => Promise<void>
  deleteNote?: (param: { uuid: string }) => Promise<void>
  updateMessageReadStatus: (uuid: string, status: 'READ' | 'UNREAD') => Promise<void>
}

export const TabControl = ({
  orderId,
  tab,
  setTab,
  className,
  addMessage,
  addNote,
  messages,
  notes,
  deleteNote,
  updateMessageReadStatus,
}: IProps) => {
  const [pinNote] = usePinNoteMutation()

  const unreadMessages = messages?.reduce(
    (agg, curr) => (curr.sentBy === 'lab' || curr.readAt ? agg : agg + 1),
    0,
  )
  return (
    <div className={`tabs ${className}`}>
      <ul className="nav nav-tabs nav-fill">
        <li className="nav-item" onClick={() => setTab('notes')} style={{ cursor: 'pointer' }}>
          <StyledLink
            className={`py-3 font-weight-bold nav-link tabs-header ${
              tab === 'notes' ? 'textDark active nav-tabs-no-bckg' : ''
            }`}>
            Notes
          </StyledLink>
        </li>
        <li
          className="nav-item"
          onClick={() => setTab('schedulingNotes')}
          style={{ cursor: 'pointer' }}>
          <StyledLink
            className={`py-3 font-weight-bold nav-link tabs-header ${
              tab === 'schedulingNotes' ? 'textDark active nav-tabs-no-bckg' : ''
            }`}>
            Scheduling Notes
          </StyledLink>
        </li>
        <li className="nav-item" onClick={() => setTab('messages')} style={{ cursor: 'pointer' }}>
          <StyledLink
            className={`py-3 font-weight-bold nav-link tabs-header ${
              tab === 'messages' ? 'textDark active nav-tabs-no-bckg' : ''
            }`}>
            Messages{' '}
            {unreadMessages ? (
              <span
                className="badge bg-danger right"
                css={css`
                  width: 18px;
                  height: 18px;
                  font-size: 0.85rem;
                `}
                style={{ marginLeft: 6, borderRadius: '47%' }}>
                {unreadMessages}
              </span>
            ) : (
              <></>
            )}
          </StyledLink>
        </li>
        <li className="nav-item" onClick={() => setTab('logs')} style={{ cursor: 'pointer' }}>
          <StyledLink
            className={`py-3 font-weight-bold nav-link tabs-header ${
              tab === 'logs' ? 'textDark active nav-tabs-no-bckg' : ''
            }`}>
            Logs
          </StyledLink>
        </li>
      </ul>
      <div className="tab-content tabs_container bg-white">
        <StyledTabBody className={`tab-pane fade ${tab === 'messages' ? 'show active' : ''}`}>
          <MsgPanel
            updateMessageReadStatus={updateMessageReadStatus}
            userContext="LAB"
            messages={messages}
            addMessage={addMessage}
          />
        </StyledTabBody>
        <StyledTabBody
          className={`tab-pane fade ${tab === 'schedulingNotes' ? 'show active' : ''}`}>
          <NotePanel
            type="scheduling"
            userContext="LAB"
            notes={notes}
            addNote={addNote}
            pinNote={pinNote}
            deleteNote={deleteNote}
          />
        </StyledTabBody>
        <StyledTabBody className={`tab-pane fade ${tab === 'notes' ? 'show active' : ''}`}>
          <NotePanel
            userContext="LAB"
            notes={notes}
            addNote={addNote}
            pinNote={pinNote}
            deleteNote={deleteNote}
          />
        </StyledTabBody>
        <StyledTabBody className={`tab-pane fade ${tab === 'logs' ? 'show active' : ''}`}>
          <OrderLogs orderId={orderId} visible={tab === 'logs'} />
        </StyledTabBody>
      </div>
    </div>
  )
}

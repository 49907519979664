/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import Textarea from 'components/atoms/Textarea'

interface IProps {
  open: boolean
  onClose: () => void
  onSubmit: (note: string) => void
}

export const UpdatePayorConfirmation = ({ open, onClose, onSubmit }: IProps) => {
  const [changeNote, setChangeNote] = useState('')

  useEffect(() => {
    if (!open) {
      setChangeNote('')
    }
  }, [open])

  return (
    <Modal centered show={open} onHide={onClose}>
      <Modal.Header>
        <Modal.Title>PLEASE CONFIRM</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div css={{ marginBottom: 15 }}>Are you sure you want to update the payor information?</div>
        <Textarea
          label="Change Notes"
          value={changeNote}
          handleChange={(e) => setChangeNote(e.target.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={() => onSubmit(changeNote)}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

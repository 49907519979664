import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../atoms/Table";
import Link from "../../atoms/Link";
import DashboardContent from "../atoms/DashboardContent";
import { getFaxes } from "../../../store/slice/faxSlice";
import { setLoader } from "../../../store/slice/loaderSlice";
import Loader from "../../atoms/Loader";

export default function (props) {
    const rows = useSelector((state) => state.fax.rows);
    const office_id = useSelector((state) => state.init.office_id);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setLoader(true));
        dispatch(getFaxes({ no_cache: 1, _lab: 1 })).then(() => {
            dispatch(setLoader(false));
        });
    }, [office_id]);

    function Content(props) {
        const header = [
            { key: "from", label: "From" },
            { key: "to", label: "To" },
            { key: "direction", label: "Direction" },
            { key: "status", label: "Status" },
            {
                key: "media_url",
                label: "File",
                toRaw: {
                    url: "media_url",
                    icon: "fa fa-file-pdf",
                    label: "File",
                },
            },
        ];
        return (
            <div className="card card-primary">
                <form type="post" onSubmit={props.handleSubmit}>
                    <div className="card-header ">
                        <div className="row">
                            <div className="col-sm-10 text-right"></div>
                            <div className="col-sm-2 text-right">
                                <Link
                                    to="#"
                                    label="Refresh"
                                    icon="fa fa-sync"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="card-body row">
                        <Table header={header} rows={rows} />
                    </div>
                </form>
            </div>
        );
    }

    return (
        <DashboardContent
            title="Faxes"
            icon="fa fa-fax"
            content={Content(props)}
            breadcrumb={[
                {
                    label: "Faxes",
                },
            ]}
        />
    );
}

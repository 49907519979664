import React from "react";
import DashboardContent from "../atoms/DashboardContent";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import { insertOrder } from "../../../store/slice/orderSlice";
import { handleChange, handleSubmit } from "../../atoms/Upsert";
import { getRoute } from "../../../store/slice/routeSlice";
import routes from "../constants/routes";
import RouteTab from "./views/Route.tab";
import OrdersTab from "./views/Orders.tab";
import Tab from "../../atoms/Tab";

export default (props) => {
    const dispatch = useDispatch();
    const [values, setValues] = React.useState({});
    const route = useSelector((state) => state.route.row);
    const [redirect, setRedirect] = React.useState(false);
    let { tab, route_id } = useParams();
    !tab ? (tab = "route") : false;

    const getParams = {
        id: route_id,
        no_cache: 1,
    };

    React.useEffect(() => {
        dispatch(getRoute(getParams));
    }, [tab]);

    let title = route ? route.name : `Route`;
    let subtitle = "";

    const tabsArray = [
        {
            key: "route",
            tab: tab,
            to: `${routes.index}${routes.route.root}${routes.route.edit}${route_id}`,
            className: "font87p",
            labelSmall: "Route",
            icon: "fas fa-id-card",
            content: RouteTab(props),
        },
        {
            key: "orders",
            tab: tab,
            to: `${routes.index}${routes.route.root}${routes.route.edit}${route_id}`,
            className: "font87p",
            labelSmall: "Route Orders",
            icon: "fas fa-folder-open",
            content: OrdersTab(props),
        },
        {
            key: "unassign",
            tab: tab,
            to: `${routes.index}${routes.route.root}${routes.route.edit}${route_id}`,
            className: "font87p",
            labelSmall: "Unassign",
            icon: "fas fa-unlink",
            content: <DummyData />,
        },
    ];

    function DummyData() {
        return "Pending Form";
    }

    function View() {
        return (
            <div className="card card-primary container-fluid">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 text-right mb-2">
                            {/* <Link
                            icon="fal fa-folder-plus"
                            label="New Order"
                            className="btn btn-success"
                            to={`${routes.index}${routes.order.add}${route_id}`}
                        /> */}
                        </div>
                        <div className="col-12">
                            {
                                <Tab
                                    cardClass="card-default"
                                    handleTabChange={() => {}}
                                    tabs={tabsArray}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div>
            {redirect && <Navigate to={redirect} />}
            <DashboardContent
                className="bg-primary"
                title={title}
                subtitle={subtitle}
                icon="fal fa-truck"
                content={View()}
                breadcrumb={[
                    {
                        label: "Routes",
                        to: `${routes.index}${routes.route.root}`,
                    },
                    { label: `Route #${route_id}` },
                ]}
            />
        </div>
    );
};

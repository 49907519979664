/** @jsxImportSource @emotion/react */
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { Button } from 'react-bootstrap'
import Select from 'react-select'
import { skipToken } from '@reduxjs/toolkit/query/react'

import { PopoverWrapper } from 'components/atoms/PopoverWrapper'
import DataTable from 'components/atoms/DataTable'
import { TableWrapper } from 'components/atoms/TableWrapper'
import routes from 'components/lab/constants/routes'
import { confirm } from 'components/molecules/Confirmation'
import Input from 'components/atoms/Input'
import { useImpersonation } from 'hooks/useImpersonation'
import { useGetLabOfficeListQuery, useDeleteOfficeMutation } from 'store/services/office'
import { useAppSelector } from 'hooks/redux'

const StyledTableWrapper = styled(TableWrapper)`
  max-height: calc(100vh - 230px);
  th {
    padding: 0 0.2rem 0.4rem 0.1rem;
  }
  td {
    vertical-align: middle;
    padding: 0.6rem;
    div {
      span {
        font-size: 0.9rem;
      }
    }
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

type Office = {
  id: number
  officeName: string
  addressCity: string
  addressState: string
  addressStreetLine1: string
  addressStreetLine2: string
  addressZip: string
  npi: string
  region: string
}
type Region = {
  regionName: string
  uuid: string
}
interface IProps {
  companyId: string
  regions: Region[]
  disabled?: boolean
}

const PAGE_SIZE = 10

export const Offices = ({ regions, companyId, disabled }: IProps) => {
  const navigate = useNavigate()
  const profile = useAppSelector((state) => state.init.profile)
  const { impersonate } = useImpersonation()
  const [currentPage, setCurrentPage] = useState(0)
  const [region, setRegion] = useState<{ value: string; label: string }>()
  const [search, setSearch] = useState('')
  const [deleteOffice] = useDeleteOfficeMutation()
  const { data: offices, isLoading } = useGetLabOfficeListQuery(
    companyId
      ? {
          companyId: Number(companyId),
          ...(search && { name: search }),
          ...(region && { region: region.value }),
          page: currentPage,
          size: PAGE_SIZE,
        }
      : skipToken,
  )
  const canDelete = [
    // 'lab0@tsmrtr.pro',
    'lawrence@testsmarter.net',
    'brad@testsmarter.net',
  ].includes(profile?.email)

  const header = [
    {
      accessor: 'id',
      Header: 'Id',
      className: 'col-1',
    },
    {
      accessor: 'officeName',
      Header: 'Office',
      className: 'col-4',
      Cell: ({ row }) =>
        `${row.original.officeName} - ${row.original.addressCity}, ${row.original.addressState}`,
    },
    {
      accessor: 'region',
      Header: 'Region',
      className: 'col-2',
      Cell: ({ row }) => (
        <span css={{ margin: 'auto' }}>{row.original.region.regionName ?? '-'}</span>
      ),
    },
    {
      accessor: 'action',
      Header: 'Action',
      className: 'col-3',
      Cell: ({ row }) => (
        <>
          <Button
            variant="primary"
            size="sm"
            css={{ minWidth: '60px' }}
            onClick={() =>
              navigate(`${routes.index}${routes.office.root}${routes.upsert}${row.original.id}`)
            }
            disabled={disabled}>
            Edit
          </Button>
          <PopoverWrapper
            placement="left"
            customContent={<div className="p-2">Impersonate this DME office</div>}>
            <Button
              variant="primary"
              className="ml-2"
              css={{ minWidth: '40px' }}
              size="sm"
              onClick={async (e) => {
                e.stopPropagation()
                impersonate(row.original.id)
              }}
              disabled={disabled}>
              <i className="fas fa-external-link-alt" />
            </Button>
          </PopoverWrapper>
          {canDelete && (
            <Button
              variant="danger"
              className="ml-2"
              size="sm"
              onClick={(e) => {
                e.stopPropagation()
                confirm('office')
                  .then(() => deleteOffice({ id: row.original.id }))
                  .catch(() => {})
              }}
              disabled={disabled}>
              <i className="fas fa-trash" />
            </Button>
          )}
        </>
      ),
    },
  ]

  return (
    <Container>
      <div css={{ maxWidth: '500px' }}>
        <span style={{ color: '#6D757E' }} className="mb-1">
          Region
        </span>
        <Select
          className="basic-single w-100 mr-3 mb-3"
          classNamePrefix="select"
          isClearable
          isSearchable
          placeholder="Select Region"
          name="Region"
          onChange={(item) => setRegion(item)}
          options={regions.map((region) => ({ value: region.uuid, label: region.regionName }))}
          value={region ?? null}
        />
        <span style={{ color: '#6D757E' }}>Office Name</span>
        <div className="d-flex w-100">
          <Input
            parentDiv={null}
            name="officeName"
            disabled={disabled}
            className="form-control mr-3"
            placeholder="Search"
            value={search}
            handleChange={(e) => setSearch(e.target.value)}
          />
          <Button
            disabled={!search || disabled}
            variant="primary"
            style={{ minWidth: 100 }}
            onClick={() => {}}>
            Search
          </Button>
        </div>
      </div>
      <StyledTableWrapper className="card-body row mt-3">
        <DataTable
          data={offices}
          status={isLoading}
          tableColumns={header}
          pageSize={PAGE_SIZE}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          setSortOptions={() => {}}
          entityName="offices"
          onRowClick={(row) =>
            navigate(`${routes.index}${routes.office.root}${routes.upsert}${row.original.id}`)
          }
        />
      </StyledTableWrapper>
    </Container>
  )
}

/** @jsxImportSource @emotion/react */
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import styled from '@emotion/styled'

import * as generalHelper from 'helpers/generalHelper'
import { formatPhoneNumber, formatZipcode } from 'helpers/format'
import TabHeader from 'components/atoms/TabHeader'
import Button from 'components/atoms/Button'
import TitleLead from 'components/atoms/TitleLead'
import { Order } from 'store/types'
import orderStateHelper from 'helpers/orderStateHelpers'
import { UpdatePatientModal } from './Patient/UpdatePatientModal'
import { UpdatePhysicianModal } from './physician/UpdatePhysicianModal'
import { UpdateInsuranceModal } from './payer/UpdateInsuranceModal'

const StyledAlert = styled.div`
  line-height: 1.3rem;
`
interface IProps {
  order: Order
  refetchOrder: () => void
}

const SummaryTab = ({ order, refetchOrder }: IProps) => {
  const navigate = useNavigate()
  const [patientModalOpen, setPatientModalOpen] = useState(false)
  const [physicianModalOpen, setPhysicianModalOpen] = useState(false)
  const [insuranceModalOpen, setInsuranceModalOpen] = useState(false)
  const orderState = orderStateHelper(order)
  const isHstPsg = order.testType === 'HST' || order.testType === 'PSG'
  const physician = order?.physician
  const physicianAddress = order?.physicianAddress
  const fileList = Object.values(order.files || {})
  // console.log('Summary Order: ', order, orderState)

  return (
    <div className="container-fluid">
      <TabHeader title="Order Summary" />
      {orderState.isReadyForReview && (
        <StyledAlert className="alert alert-default-primary" role="alert">
          Please review the information below prior to submitting for Lab Verification.
        </StyledAlert>
      )}

      {orderState.isAwaitingVerification && (
        <StyledAlert className="alert alert-default-success" role="alert">
          This information has been submitted for Lab Review. If any changes are needed to the below
          information, click the Modify button before lab review starts.
        </StyledAlert>
      )}

      {orderState.isUnderVerification && (
        <StyledAlert className="alert alert-default-primary" role="alert">
          This order is currently being reviewed by the Lab. No changes can be made at this point.
          If the lab requires changes, those sections will be marked and can be updated later.
        </StyledAlert>
      )}

      {orderState.isVerificationDone && (
        <StyledAlert className="alert alert-success" role="alert">
          This information has been verified by the Lab. If any changes are needed to the below
          information, send a Message to the Lab.
        </StyledAlert>
      )}

      {orderState.hasFailedVerification && (
        <StyledAlert className="alert alert-danger" role="alert">
          There is an issue with the verification of the below order information. Please review the
          adjacent order messages for the specific issue.
        </StyledAlert>
      )}

      <UpdatePatientModal
        order={order}
        open={patientModalOpen}
        onClose={() => setPatientModalOpen(false)}
      />
      <UpdatePhysicianModal
        order={order}
        open={physicianModalOpen}
        onClose={() => setPhysicianModalOpen(false)}
        refetchOrder={refetchOrder}
      />
      <UpdateInsuranceModal
        order={order}
        open={insuranceModalOpen}
        onClose={() => setInsuranceModalOpen(false)}
      />
      <div className="row px-2 mb-3">
        <div className="col mr-3 px-4 d-flex flex-column border bg-white">
          <div className="row align-items-center">
            <div className="col my-2 d-flex justify-content-between p-0">
              <div className="d-flex align-items-center">
                {orderState.hasPatientFailedVerification && (
                  <i className="fas fa-exclamation-triangle text-danger" />
                )}
                <TitleLead className="m-2" label={generalHelper.t('Patient')} />
              </div>
              {orderState.isPatientModifiable && (
                <Button
                  onClick={() => setPatientModalOpen(true)}
                  className="btn-outline-secondary btn btn-sm "
                  label="Modify"
                />
              )}
            </div>
            <hr className="w-100 mt-0" />
          </div>
          <div className="row mb-3">
            <div className="col">
              <span className="font-weight-bold textGray h5">
                {`${order?.patient?.firstName} ${order?.patient?.lastName}`}
              </span>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <span className="font-weight-bold textGray">Date of Birth: </span>
              <span>{moment(order?.patient?.dob).format('MM/DD/YYYY')}</span>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <span className="font-weight-bold textGray">Gender: </span>
              <span>{order?.patient?.gender}</span>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <span className="font-weight-bold textGray">Home Phone: </span>
              <span>{formatPhoneNumber(order?.patient?.homePhone, true)}</span>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <span className="font-weight-bold textGray">Mobile Phone: </span>
              <span>{formatPhoneNumber(order?.patient?.mobilePhone, true)}</span>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <span className="font-weight-bold textGray">Email: </span>
              <span>{order?.patient?.email}</span>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex flex-column">
              <span className="font-weight-bold textGray">Primary Address: </span>
              {order?.patient?.primaryAddressStreetLine1 && (
                <div>{order?.patient?.primaryAddressStreetLine1}</div>
              )}
              {order?.patient?.primaryAddressStreetLine2 && (
                <div> {order?.patient?.primaryAddressStreetLine2}</div>
              )}
              {order?.patient?.primaryAddressCity && (
                <div>
                  {order?.patient?.primaryAddressCity}
                  {order?.patient?.primaryAddressState
                    ? `, ${order?.patient?.primaryAddressState}`
                    : ''}
                  {order?.patient?.primaryAddressZip
                    ? `, ${formatZipcode(order?.patient?.primaryAddressZip)}`
                    : ''}
                </div>
              )}
            </div>
          </div>
          <div className="row mb-2">
            <div className="col d-flex flex-column">
              <span className="font-weight-bold textGray">Device Delivery Address: </span>
              {order?.patient?.deliveryAddressStreetLine1 && (
                <div> {order?.patient?.deliveryAddressStreetLine1}</div>
              )}
              {order?.patient?.deliveryAddressStreetLine2 && (
                <div> {order?.patient?.deliveryAddressStreetLine2}</div>
              )}
              {order?.patient?.deliveryAddressCity && (
                <div>
                  {order?.patient?.deliveryAddressCity}
                  {order?.patient?.deliveryAddressState
                    ? `, ${order?.patient?.deliveryAddressState}`
                    : ''}
                  {order?.patient?.deliveryAddressZip
                    ? `, ${formatZipcode(order?.patient?.deliveryAddressZip)}`
                    : ''}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="col mr-3 px-4 d-flex flex-column border bg-white">
          <div className="row align-items-center">
            <div className="col my-2 d-flex justify-content-between p-0">
              <div className="d-flex align-items-center">
                {orderState.hasPhysicianFailedVerification && (
                  <i className="fas fa-exclamation-triangle text-danger" />
                )}
                <TitleLead className="m-2" label={generalHelper.t('Physician')} />
              </div>
              {orderState.isPhysicianModifiable && !isHstPsg && (
                <Button
                  onClick={() => setPhysicianModalOpen(true)}
                  className="btn-outline-secondary btn btn-sm "
                  label="Modify"
                />
              )}
            </div>
            <hr className="w-100 mt-0" />
          </div>
          {physician ? (
            <>
              <div className="row mb-3">
                <div className="col">
                  <span className="font-weight-bold textGray h5">
                    {`${physician?.firstName ?? ''} ${physician?.lastName ?? ''} ${physician?.credential ?? ''}`}
                  </span>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <span className="font-weight-bold textGray">NPI: </span>
                  <span>{physician?.npi}</span>
                </div>
              </div>
              {physicianAddress?.telephone ? (
                <div className="row mb-3">
                  <div className="col">
                    <span className="font-weight-bold textGray">Telephone: </span>
                    <span>{formatPhoneNumber(physicianAddress?.telephone)}</span>
                  </div>
                </div>
              ) : null}

              {physicianAddress?.fax ? (
                <div className="row mb-3">
                  <div className="col">
                    <span className="font-weight-bold textGray">Fax: </span>
                    <span>{formatPhoneNumber(physicianAddress?.fax)}</span>
                  </div>
                </div>
              ) : null}

              <div className="row mb-3">
                <div className="col d-flex flex-column">
                  <span className="font-weight-bold textGray">Address: </span>
                  {physicianAddress?.addressStreetLine1 && (
                    <div> {physicianAddress?.addressStreetLine1}</div>
                  )}
                  {physicianAddress?.addressStreetLine2 && (
                    <div> {physicianAddress?.addressStreetLine2}</div>
                  )}
                  {physicianAddress?.addressCity && (
                    <div>
                      {physicianAddress?.addressCity}
                      {physicianAddress?.addressState ? `, ${physicianAddress?.addressState}` : ''}
                      {physicianAddress?.addressZip ? `, ${physicianAddress?.addressZip}` : ''}
                    </div>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col d-flex flex-column textGray">{physicianAddress?.comment}</div>
              </div>
            </>
          ) : (
            <div className="row mb-3">
              <div className="col">
                {isHstPsg ? (
                  <span className="">TestSmarter will populate this information shortly</span>
                ) : (
                  <span className="font-weight-bold text-danger">
                    No physician has been selected
                  </span>
                )}
              </div>
            </div>
          )}
        </div>

        <div className="col px-4 d-flex flex-column border bg-white">
          <div className="row align-items-center">
            <div className="col my-2 d-flex justify-content-between p-0">
              <div className="d-flex align-items-center">
                {orderState.hasPayerFailedVerification && (
                  <i className="fas fa-exclamation-triangle text-danger" />
                )}
                <TitleLead className="m-2" label={generalHelper.t('Insurance')} />
              </div>
              {orderState.isPayerModifiable && !isHstPsg && (
                <Button
                  onClick={() => setInsuranceModalOpen(true)}
                  className="btn-outline-secondary btn btn-sm "
                  label="Modify"
                />
              )}
            </div>
            <hr className="w-100 mt-0" />
          </div>
          {!!order?.payers?.length ? (
            order.payers.map((payer, index) => {
              return (
                <div key={index} className="row mb-2">
                  <div className="col d-flex flex-column mb-3">
                    <span className="font-weight-bold mb-2 textGray">
                      {((index === 0 && orderState.hasPayer1FailedVerification) ||
                        (index === 1 && orderState.hasPayer2FailedVerification) ||
                        (index === 2 && orderState.hasPayer3FailedVerification)) && (
                        <i className="fas fa-exclamation-triangle text-danger mr-2" />
                      )}
                      {index === 0
                        ? 'Primary Insurance'
                        : index === 1
                          ? 'Secondary Insurance'
                          : index === 2
                            ? 'Tertiary Insurance'
                            : ''}
                    </span>
                    <div className="mb-2">
                      <span className="font-weight-bold textGray">Payor: </span>
                      <span>{payer.name}</span>
                    </div>
                    <div className="mb-2">
                      <span className="font-weight-bold textGray">Member ID: </span>
                      <span>{payer.memberId}</span>
                    </div>
                    <div className="mb-2">
                      <span className="font-weight-bold textGray">Group ID: </span>
                      <span>{payer.groupId}</span>
                    </div>
                  </div>
                </div>
              )
            })
          ) : (
            <div className="row mb-3">
              <div className="col">
                {isHstPsg ? (
                  <span className="">TestSmarter will populate this information shortly</span>
                ) : (
                  <span className="font-weight-bold text-danger">
                    No insurance has been selected
                  </span>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="row px-2 mb-2">
        <div className="col px-4 d-flex flex-column border bg-white pb-3">
          <div className="row align-items-center">
            <div className="col my-2 d-flex justify-content-between">
              <TitleLead className="my-2" label={generalHelper.t('Uploaded Forms')} />
              {(orderState.isRxModifiable || orderState.isOfficeNotesModifiable) && (
                <Button
                  onClick={() => navigate(`/dme/orderEdit/${order.id}/Documents`)}
                  className="btn-outline-secondary btn btn-sm "
                  label="Modify"
                />
              )}
            </div>
            <hr className="w-100 mt-0" />
          </div>
          <div className="row border-bottom pl-2 pb-2 mb-2">
            <div className="col-2 m-auto">Type</div>
            <div className="col m-auto">Document Name</div>
            <div className="col m-auto">Date</div>
          </div>
          {fileList?.some((f) => ['RX', 'OFFICE_NOTES'].includes(f.uploadType)) ? (
            fileList.map((file) => {
              if (['RX', 'OFFICE_NOTES'].includes(file.uploadType)) {
                return (
                  <div key={`file-${file.key}`} css={{ marginBottom: 5 }}>
                    <div className="row border rounded py-3 pl-2">
                      <div className="col-2 m-auto">
                        <div className="d-flex align-items-center">
                          {((file.uploadType === 'RX' && orderState.hasRxFailedVerification) ||
                            (file.uploadType === 'OFFICE_NOTES' &&
                              orderState.hasOfficeNotesFailedVerification)) && (
                            <i className="fas fa-exclamation-triangle text-danger mr-2" />
                          )}
                          <div>{file.uploadType.replace('_', ' ')}</div>
                        </div>
                      </div>
                      <div className="col m-auto">
                        <a href={file.url} target="_blank" rel="noreferrer">
                          {file.filename}
                        </a>
                      </div>
                      <div className="col m-auto">
                        {`${moment(file.uploadDate).format('h:mm a MMM Do, YYYY')}`}
                      </div>
                    </div>
                  </div>
                )
              }
              return null
            })
          ) : (
            <div className="card mt-0 ml-2 mr-2 mb-3">
              <div className="card-body text-center font-italic text-muted">
                <i className="fas fa-exclamation-triangle text-danger mr-2" /> No documents uploaded
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default SummaryTab

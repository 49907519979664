import versionConfig from 'config/version.json'

const Footer = () => {
  return (
    <footer className="main-footer tsFooter">
      <div className="tsFooter_version">v.{versionConfig.version}</div>
    </footer>
  )
}

export default Footer

import {CSSProperties, useState} from 'react'
import ToggleBtnGroup from '../atoms/ToggleBtnGroup'

type Val = {
  showRx?: boolean
  summaryOnly?: boolean
}

interface IProps {
  value: Val
  onChange: (pref: any) => void
}

const styles: Record<string, CSSProperties> = {
  row: {
    display: 'flex',
    flexDirection: 'row',
    gap: '15px',
  },
  col: {
    display: 'block',
  },
};

const ReportPreferenceOptions = ({ value = {}, onChange: onChangeProp }: IProps) => {
  const [pref, setPref] = useState(value)

  const onChange = (key: string, value: string | boolean) => {
    if ('summaryOnly' === key) {
      value = ('SUMMARY' === value);
    } else if ('showRx' === key) {
      value = ('ON' === value);
    }
    onChangeProp({ ...pref, [key]: value })
    setPref((state) => ({ ...state, [key]: value }))
  }

  return (
    <div style={styles.row}>
      <div style={styles.col}>
        <ToggleBtnGroup
          options={[
            {key: 'SUMMARY', name: 'Summary'},
            {key: 'DETAILED', name: 'Detailed'},
          ]}
          value={pref.summaryOnly ? 'SUMMARY' : 'DETAILED'}
          onChange={(view) => onChange('summaryOnly', view)}
        />
      </div>
      <div style={styles.col}>
        <ToggleBtnGroup
          options={[
            {key: 'OFF', name: 'Show RX'},
            {key: 'ON', name: 'Show Guidelines'},
          ]}
          value={(pref.showRx ? 'ON' : 'OFF')}
          onChange={(showRx) => onChange('showRx', showRx)}
        />
      </div>
    </div>
  )
}

export default ReportPreferenceOptions

interface IProps {
  text: string
  className?: string
}

const NoData = ({ text, className }: IProps) => (
  <div className={className}>{text}</div>
)

export default NoData

export const diagnosisCodes = [
  {
    value: 'A31.0',
    label: 'Pulmonary mycobacterial infection',
  },
  {
    value: 'A41.9',
    label: 'Sepsis, unspecified organism',
  },
  {
    value: 'B94.8',
    label: 'Sequelae of other specified infectious and parasitic diseases',
  },
  {
    value: 'B97.4',
    label:
      'Respiratory syncytial virus as the cause of diseases classified elsewhere',
  },
  {
    value: 'B97.89',
    label: 'Other viral agents as the cause of diseases classified elsewhere',
  },
  {
    value: 'C18.9',
    label: 'Malignant neoplasm of colon, unspecified',
  },
  {
    value: 'C34.00',
    label: 'Malignant neoplasm of unspecified main bronchus',
  },
  {
    value: 'C34.01',
    label: 'Malignant neoplasm of right main bronchus',
  },
  {
    value: 'C34.02',
    label: 'Malignant neoplasm of left main bronchus',
  },
  {
    value: 'C34.10',
    label: 'Malignant neoplasm of upper lobe, unspecified bronchus or lung',
  },
  {
    value: 'C34.11',
    label: 'Malignant neoplasm of upper lobe, right bronchus or lung',
  },
  {
    value: 'C34.12',
    label: 'Malignant neoplasm of upper lobe, left bronchus or lung',
  },
  {
    value: 'C34.2',
    label: 'Malignant neoplasm of middle lobe, bronchus or lung',
  },
  {
    value: 'C34.30',
    label: 'Malignant neoplasm of lower lobe, unspecified bronchus or lung',
  },
  {
    value: 'C34.31',
    label: 'Malignant neoplasm of lower lobe, right bronchus or lung',
  },
  {
    value: 'C34.32',
    label: 'Malignant neoplasm of lower lobe, left bronchus or lung',
  },
  {
    value: 'C34.80',
    label:
      'Malignant neoplasm of overlapping sites of unspecified bronchus and lung',
  },
  {
    value: 'C34.81',
    label: 'Malignant neoplasm of overlapping sites of right bronchus and lung',
  },
  {
    value: 'C34.82',
    label: 'Malignant neoplasm of overlapping sites of left bronchus and lung',
  },
  {
    value: 'C34.90',
    label:
      'Malignant neoplasm of unspecified part of unspecified bronchus or lung',
  },
  {
    value: 'C34.91',
    label: 'Malignant neoplasm of unspecified part of right bronchus or lung',
  },
  {
    value: 'C34.92',
    label: 'Malignant neoplasm of unspecified part of left bronchus or lung',
  },
  {
    value: 'C50.919',
    label:
      'Malignant neoplasm of unspecified site of unspecified female breast',
  },
  {
    value: 'C61',
    label: 'Malignant neoplasm of prostate',
  },
  {
    value: 'C78.00',
    label: 'Secondary malignant neoplasm of unspecified lung',
  },
  {
    value: 'C79.51',
    label: 'Secondary malignant neoplasm of bone',
  },
  {
    value: 'C80.1',
    label: 'Malignant (primary) neoplasm, unspecified',
  },
  {
    value: 'C90.00',
    label: 'Multiple myeloma not having achieved remission',
  },
  {
    value: 'C91.10',
    label:
      'Chronic lymphocytic leukemia of B-cell type not having achieved remission',
  },
  {
    value: 'D45',
    label: 'Polycythemia vera',
  },
  {
    value: 'D47.3',
    label: 'Essential (hemorrhagic) thrombocythemia',
  },
  {
    value: 'D50.8',
    label: 'Other iron deficiency anemias',
  },
  {
    value: 'D50.9',
    label: 'Iron deficiency anemia, unspecified',
  },
  {
    value: 'D57.1',
    label: 'Sickle-cell disease without crisis',
  },
  {
    value: 'D58.2',
    label: 'Other hemoglobinopathies',
  },
  {
    value: 'D64.9',
    label: 'Anemia, unspecified',
  },
  {
    value: 'D69.6',
    label: 'Thrombocytopenia, unspecified',
  },
  {
    value: 'D72.1',
    label: 'Eosinophilia',
  },
  {
    value: 'D72.10',
    label: 'Eosinophilia, unspecified',
  },
  {
    value: 'D72.829',
    label: 'Elevated white blood cell count, unspecified',
  },
  {
    value: 'D75.0',
    label: 'Familial erythrocytosis',
  },
  {
    value: 'D75.1',
    label: 'Secondary polycythemia',
  },
  {
    value: 'D84.9',
    label: 'Immunodeficiency, unspecified',
  },
  {
    value: 'D86.0',
    label: 'Sarcoidosis of lung',
  },
  {
    value: 'D86.2',
    label: 'Sarcoidosis of lung with sarcoidosis of lymph nodes',
  },
  {
    value: 'D86.9',
    label: 'Sarcoidosis, unspecified',
  },
  {
    value: 'E03.8',
    label: 'Other specified hypothyroidism',
  },
  {
    value: 'E03.9',
    label: 'Hypothyroidism, unspecified',
  },
  {
    value: 'E04.1',
    label: 'Nontoxic single thyroid nodule',
  },
  {
    value: 'E06.3',
    label: 'Autoimmune thyroiditis',
  },
  {
    value: 'E11.22',
    label: 'Type 2 diabetes mellitus with diabetic chronic kidney disease',
  },
  {
    value: 'E11.40',
    label: 'Type 2 diabetes mellitus with diabetic neuropathy, unspecified',
  },
  {
    value: 'E11.42',
    label: 'Type 2 diabetes mellitus with diabetic polyneuropathy',
  },
  {
    value: 'E11.59',
    label: 'Type 2 diabetes mellitus with other circulatory complications',
  },
  {
    value: 'E11.65',
    label: 'Type 2 diabetes mellitus with hyperglycemia',
  },
  {
    value: 'E11.69',
    label: 'Type 2 diabetes mellitus with other specified complication',
  },
  {
    value: 'E11.9',
    label: 'Type 2 diabetes mellitus without complications',
  },
  {
    value: 'E16.1',
    label: 'Other hypoglycemia',
  },
  {
    value: 'E16.2',
    label: 'Hypoglycemia, unspecified',
  },
  {
    value: 'E29.1',
    label: 'Testicular hypofunction',
  },
  {
    value: 'E44.0',
    label: 'Moderate protein-calorie malnutrition',
  },
  {
    value: 'E53.8',
    label: 'Deficiency of other specified B group vitamins',
  },
  {
    value: 'E55.9',
    label: 'Vitamin D deficiency, unspecified',
  },
  {
    value: 'E61.1',
    label: 'Iron deficiency',
  },
  {
    value: 'E66.01',
    label: 'Morbid (severe) obesity due to excess calories',
  },
  {
    value: 'E66.09',
    label: 'Other obesity due to excess calories',
  },
  {
    value: 'E66.2',
    label: 'Morbid (severe) obesity with alveolar hypoventilation',
  },
  {
    value: 'E66.3',
    label: 'Overweight',
  },
  {
    value: 'E66.8',
    label: 'Other obesity',
  },
  {
    value: 'E66.9',
    label: 'Obesity, unspecified',
  },
  {
    value: 'E78.0',
    label: 'Pure hypercholesterolemia',
  },
  {
    value: 'E78.00',
    label: 'Pure hypercholesterolemia, unspecified',
  },
  {
    value: 'E78.1',
    label: 'Pure hyperglyceridemia',
  },
  {
    value: 'E78.2',
    label: 'Mixed hyperlipidemia',
  },
  {
    value: 'E78.4',
    label: '',
  },
  {
    value: 'E78.5',
    label: 'Hyperlipidemia, unspecified',
  },
  {
    value: 'E80.6',
    label: 'Other disorders of bilirubin metabolism',
  },
  {
    value: 'E83.52',
    label: 'Hypercalcemia',
  },
  {
    value: 'E84.0',
    label: 'Cystic fibrosis with pulmonary manifestations',
  },
  {
    value: 'E84.19',
    label: 'Cystic fibrosis with other intestinal manifestations',
  },
  {
    value: 'E84.9',
    label: 'Cystic fibrosis, unspecified',
  },
  {
    value: 'E87.1',
    label: 'Hypo-osmolality and hyponatremia',
  },
  {
    value: 'E87.2',
    label: 'Acidosis',
  },
  {
    value: 'E87.6',
    label: 'Hypokalemia',
  },
  {
    value: 'E88.01',
    label: 'Alpha-1-antitrypsin deficiency',
  },
  {
    value: 'E88.81',
    label: 'Metabolic syndrome',
  },
  {
    value: 'F02.80',
    label:
      'Dementia in other diseases classified elsewhere, unspecified severity, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety',
  },
  {
    value: 'F03.90',
    label:
      'Unspecified dementia, unspecified severity, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety',
  },
  {
    value: 'F11.20',
    label: 'Opioid dependence, uncomplicated',
  },
  {
    value: 'F11.90',
    label: 'Opioid use, unspecified, uncomplicated',
  },
  {
    value: 'F17.200',
    label: 'Nicotine dependence, unspecified, uncomplicated',
  },
  {
    value: 'F17.201',
    label: 'Nicotine dependence, unspecified, in remission',
  },
  {
    value: 'F17.210',
    label: 'Nicotine dependence, cigarettes, uncomplicated',
  },
  {
    value: 'F17.211',
    label: 'Nicotine dependence, cigarettes, in remission',
  },
  {
    value: 'F17.219',
    label:
      'Nicotine dependence, cigarettes, with unspecified nicotine-induced disorders',
  },
  {
    value: 'F31.9',
    label: 'Bipolar disorder, unspecified',
  },
  {
    value: 'F32.8',
    label: 'Other depressive episodes',
  },
  {
    value: 'F32.89',
    label: 'Other specified depressive episodes',
  },
  {
    value: 'F32.9',
    label: 'Major depressive disorder, single episode, unspecified',
  },
  {
    value: 'F32.A',
    label: 'Depression, unspecified',
  },
  {
    value: 'F33.0',
    label: 'Major depressive disorder, recurrent, mild',
  },
  {
    value: 'F33.1',
    label: 'Major depressive disorder, recurrent, moderate',
  },
  {
    value: 'F33.9',
    label: 'Major depressive disorder, recurrent, unspecified',
  },
  {
    value: 'F39',
    label: 'Unspecified mood [affective] disorder',
  },
  {
    value: 'F41.1',
    label: 'Generalized anxiety disorder',
  },
  {
    value: 'F41.8',
    label: 'Other specified anxiety disorders',
  },
  {
    value: 'F41.9',
    label: 'Anxiety disorder, unspecified',
  },
  {
    value: 'F43.10',
    label: 'Post-traumatic stress disorder, unspecified',
  },
  {
    value: 'F45.8',
    label: 'Other somatoform disorders',
  },
  {
    value: 'F51.01',
    label: 'Primary insomnia',
  },
  {
    value: 'F51.02',
    label: 'Adjustment insomnia',
  },
  {
    value: 'F51.04',
    label: 'Psychophysiologic insomnia',
  },
  {
    value: 'F51.05',
    label: 'Insomnia due to other mental disorder',
  },
  {
    value: 'F51.11',
    label: 'Primary hypersomnia',
  },
  {
    value: 'F51.12',
    label: 'Insufficient sleep syndrome',
  },
  {
    value: 'F51.3',
    label: 'Sleepwalking [somnambulism]',
  },
  {
    value: 'F51.4',
    label: 'Sleep terrors [night terrors]',
  },
  {
    value: 'F51.5',
    label: 'Nightmare disorder',
  },
  {
    value: 'F51.8',
    label:
      'Other sleep disorders not due to a substance or known physiological condition',
  },
  {
    value: 'F51.9',
    label:
      'Sleep disorder not due to a substance or known physiological condition, unspecified',
  },
  {
    value: 'F80.2',
    label: 'Mixed receptive-expressive language disorder',
  },
  {
    value: 'F80.9',
    label: 'Developmental disorder of speech and language, unspecified',
  },
  {
    value: 'F84.0',
    label: 'Autistic disorder',
  },
  {
    value: 'F88',
    label: 'Other disorders of psychological development',
  },
  {
    value: 'F90.9',
    label: 'Attention-deficit hyperactivity disorder, unspecified type',
  },
  {
    value: 'G12.21',
    label: 'Amyotrophic lateral sclerosis',
  },
  {
    value: 'G12.9',
    label: 'Spinal muscular atrophy, unspecified',
  },
  {
    value: 'G20',
    label: "Parkinson's disease",
  },
  {
    value: 'G25.81',
    label: 'Restless legs syndrome',
  },
  {
    value: 'G30.9',
    label: "Alzheimer's disease, unspecified",
  },
  {
    value: 'G31.84',
    label: 'Mild cognitive impairment of uncertain or unknown etiology',
  },
  {
    value: 'G35',
    label: 'Multiple sclerosis',
  },
  {
    value: 'G40.109',
    label:
      'Localization-related (focal) (partial) symptomatic epilepsy and epileptic syndromes with simple partial seizures, not intractable, without status epilepticus',
  },
  {
    value: 'G40.909',
    label: 'Epilepsy, unspecified, not intractable, without status epilepticus',
  },
  {
    value: 'G43.009',
    label: 'Migraine without aura, not intractable, without status migrainosus',
  },
  {
    value: 'G43.109',
    label: 'Migraine with aura, not intractable, without status migrainosus',
  },
  {
    value: 'G43.701',
    label:
      'Chronic migraine without aura, not intractable, with status migrainosus',
  },
  {
    value: 'G43.709',
    label:
      'Chronic migraine without aura, not intractable, without status migrainosus',
  },
  {
    value: 'G43.909',
    label: 'Migraine, unspecified, not intractable, without status migrainosus',
  },
  {
    value: 'G44.009',
    label: 'Cluster headache syndrome, unspecified, not intractable',
  },
  {
    value: 'G44.229',
    label: 'Chronic tension-type headache, not intractable',
  },
  {
    value: 'G44.52',
    label: 'New daily persistent headache (NDPH)',
  },
  {
    value: 'G44.81',
    label: 'Hypnic headache',
  },
  {
    value: 'G44.89',
    label: 'Other headache syndrome',
  },
  {
    value: 'G45.9',
    label: 'Transient cerebral ischemic attack, unspecified',
  },
  {
    value: 'G47.00',
    label: 'Insomnia, unspecified',
  },
  {
    value: 'G47.01',
    label: 'Insomnia due to medical condition',
  },
  {
    value: 'G47.09',
    label: 'Other insomnia',
  },
  {
    value: 'G47.10',
    label: 'Hypersomnia, unspecified',
  },
  {
    value: 'G47.11',
    label: 'Idiopathic hypersomnia with long sleep time',
  },
  {
    value: 'G47.12',
    label: 'Idiopathic hypersomnia without long sleep time',
  },
  {
    value: 'G47.13',
    label: 'Recurrent hypersomnia',
  },
  {
    value: 'G47.14',
    label: 'Hypersomnia due to medical condition',
  },
  {
    value: 'G47.19',
    label: 'Other hypersomnia',
  },
  {
    value: 'G47.20',
    label: 'Circadian rhythm sleep disorder, unspecified type',
  },
  {
    value: 'G47.21',
    label: 'Circadian rhythm sleep disorder, delayed sleep phase type',
  },
  {
    value: 'G47.22',
    label: 'Circadian rhythm sleep disorder, advanced sleep phase type',
  },
  {
    value: 'G47.26',
    label: 'Circadian rhythm sleep disorder, shift work type',
  },
  {
    value: 'G47.30',
    label: 'Sleep apnea, unspecified',
  },
  {
    value: 'G47.31',
    label: 'Primary central sleep apnea',
  },
  {
    value: 'G47.32',
    label: 'High altitude periodic breathing',
  },
  {
    value: 'G47.33',
    label: 'Obstructive sleep apnea (adult) (pediatric)',
  },
  {
    value: 'G47.34',
    label: 'Idiopathic sleep related nonobstructive alveolar hypoventilation',
  },
  {
    value: 'G47.35',
    label: 'Congenital central alveolar hypoventilation syndrome',
  },
  {
    value: 'G47.36',
    label: 'Sleep related hypoventilation in conditions classified elsewhere',
  },
  {
    value: 'G47.37',
    label: 'Central sleep apnea in conditions classified elsewhere',
  },
  {
    value: 'G47.39',
    label: 'Other sleep apnea',
  },
  {
    value: 'G47.411',
    label: 'Narcolepsy with cataplexy',
  },
  {
    value: 'G47.419',
    label: 'Narcolepsy without cataplexy',
  },
  {
    value: 'G47.50',
    label: 'Parasomnia, unspecified',
  },
  {
    value: 'G47.52',
    label: 'REM sleep behavior disorder',
  },
  {
    value: 'G47.61',
    label: 'Periodic limb movement disorder',
  },
  {
    value: 'G47.62',
    label: 'Sleep related leg cramps',
  },
  {
    value: 'G47.63',
    label: 'Sleep related bruxism',
  },
  {
    value: 'G47.8',
    label: 'Other sleep disorders',
  },
  {
    value: 'G47.9',
    label: 'Sleep disorder, unspecified',
  },
  {
    value: 'G62.9',
    label: 'Polyneuropathy, unspecified',
  },
  {
    value: 'G70.00',
    label: 'Myasthenia gravis without (acute) exacerbation',
  },
  {
    value: 'G70.9',
    label: 'Myoneural disorder, unspecified',
  },
  {
    value: 'G71.0',
    label: 'Muscular dystrophy',
  },
  {
    value: 'G71.00',
    label: 'Muscular dystrophy, unspecified',
  },
  {
    value: 'G71.01',
    label: 'Duchenne or Becker muscular dystrophy',
  },
  {
    value: 'G71.09',
    label: 'Other specified muscular dystrophies',
  },
  {
    value: 'G71.11',
    label: 'Myotonic muscular dystrophy',
  },
  {
    value: 'G80.0',
    label: 'Spastic quadriplegic cerebral palsy',
  },
  {
    value: 'G80.8',
    label: 'Other cerebral palsy',
  },
  {
    value: 'G80.9',
    label: 'Cerebral palsy, unspecified',
  },
  {
    value: 'G82.50',
    label: 'Quadriplegia, unspecified',
  },
  {
    value: 'G89.29',
    label: 'Other chronic pain',
  },
  {
    value: 'G89.4',
    label: 'Chronic pain syndrome',
  },
  {
    value: 'G90.1',
    label: 'Familial dysautonomia [Riley-Day]',
  },
  {
    value: 'G93.1',
    label: 'Anoxic brain damage, not elsewhere classified',
  },
  {
    value: 'G93.3',
    label: 'Postviral fatigue syndrome',
  },
  {
    value: 'G93.32',
    label: 'Myalgic encephalomyelitis/chronic fatigue syndrome',
  },
  {
    value: 'G93.40',
    label: 'Encephalopathy, unspecified',
  },
  {
    value: 'H35.109',
    label: 'Retinopathy of prematurity, unspecified, unspecified eye',
  },
  {
    value: 'H91.90',
    label: 'Unspecified hearing loss, unspecified ear',
  },
  {
    value: 'I01.8',
    label: 'Other acute rheumatic heart disease',
  },
  {
    value: 'I07.1',
    label: 'Rheumatic tricuspid insufficiency',
  },
  {
    value: 'I09.81',
    label: 'Rheumatic heart failure',
  },
  {
    value: 'I10',
    label: 'Essential (primary) hypertension',
  },
  {
    value: 'I11.0',
    label: 'Hypertensive heart disease with heart failure',
  },
  {
    value: 'I11.9',
    label: 'Hypertensive heart disease without heart failure',
  },
  {
    value: 'I12.9',
    label:
      'Hypertensive chronic kidney disease with stage 1 through stage 4 chronic kidney disease, or unspecified chronic kidney disease',
  },
  {
    value: 'I13.0',
    label:
      'Hypertensive heart and chronic kidney disease with heart failure and stage 1 through stage 4 chronic kidney disease, or unspecified chronic kidney disease',
  },
  {
    value: 'I13.2',
    label:
      'Hypertensive heart and chronic kidney disease with heart failure and with stage 5 chronic kidney disease, or end stage renal disease',
  },
  {
    value: 'I15.9',
    label: 'Secondary hypertension, unspecified',
  },
  {
    value: 'I16.0',
    label: 'Hypertensive urgency',
  },
  {
    value: 'I16.1',
    label: 'Hypertensive emergency',
  },
  {
    value: 'I16.9',
    label: 'Hypertensive crisis, unspecified',
  },
  {
    value: 'I20.8',
    label: 'Other forms of angina pectoris',
  },
  {
    value: 'I20.9',
    label: 'Angina pectoris, unspecified',
  },
  {
    value: 'I21.4',
    label: 'Non-ST elevation (NSTEMI) myocardial infarction',
  },
  {
    value: 'I25.10',
    label:
      'Atherosclerotic heart disease of native coronary artery without angina pectoris',
  },
  {
    value: 'I25.118',
    label:
      'Atherosclerotic heart disease of native coronary artery with other forms of angina pectoris',
  },
  {
    value: 'I25.119',
    label:
      'Atherosclerotic heart disease of native coronary artery with unspecified angina pectoris',
  },
  {
    value: 'I25.2',
    label: 'Old myocardial infarction',
  },
  {
    value: 'I25.5',
    label: 'Ischemic cardiomyopathy',
  },
  {
    value: 'I25.810',
    label:
      'Atherosclerosis of coronary artery bypass graft(s) without angina pectoris',
  },
  {
    value: 'I25.9',
    label: 'Chronic ischemic heart disease, unspecified',
  },
  {
    value: 'I26.02',
    label: 'Saddle embolus of pulmonary artery with acute cor pulmonale',
  },
  {
    value: 'I26.09',
    label: 'Other pulmonary embolism with acute cor pulmonale',
  },
  {
    value: 'I26.92',
    label: 'Saddle embolus of pulmonary artery without acute cor pulmonale',
  },
  {
    value: 'I26.94',
    label: 'Multiple subsegmental pulmonary emboli without acute cor pulmonale',
  },
  {
    value: 'I26.99',
    label: 'Other pulmonary embolism without acute cor pulmonale',
  },
  {
    value: 'I27.0',
    label: 'Primary pulmonary hypertension',
  },
  {
    value: 'I27.2',
    label: 'Other secondary pulmonary hypertension',
  },
  {
    value: 'I27.20',
    label: 'Pulmonary hypertension, unspecified',
  },
  {
    value: 'I27.21',
    label: 'Secondary pulmonary arterial hypertension',
  },
  {
    value: 'I27.22',
    label: 'Pulmonary hypertension due to left heart disease',
  },
  {
    value: 'I27.23',
    label: 'Pulmonary hypertension due to lung diseases and hypoxia',
  },
  {
    value: 'I27.24',
    label: 'Chronic thromboembolic pulmonary hypertension',
  },
  {
    value: 'I27.29',
    label: 'Other secondary pulmonary hypertension',
  },
  {
    value: 'I27.81',
    label: 'Cor pulmonale (chronic)',
  },
  {
    value: 'I27.82',
    label: 'Chronic pulmonary embolism',
  },
  {
    value: 'I27.89',
    label: 'Other specified pulmonary heart diseases',
  },
  {
    value: 'I27.9',
    label: 'Pulmonary heart disease, unspecified',
  },
  {
    value: 'I28.8',
    label: 'Other diseases of pulmonary vessels',
  },
  {
    value: 'I31.3',
    label: 'Pericardial effusion (noninflammatory)',
  },
  {
    value: 'I34.0',
    label: 'Nonrheumatic mitral (valve) insufficiency',
  },
  {
    value: 'I35.0',
    label: 'Nonrheumatic aortic (valve) stenosis',
  },
  {
    value: 'I35.1',
    label: 'Nonrheumatic aortic (valve) insufficiency',
  },
  {
    value: 'I38',
    label: 'Endocarditis, valve unspecified',
  },
  {
    value: 'I42.0',
    label: 'Dilated cardiomyopathy',
  },
  {
    value: 'I42.2',
    label: 'Other hypertrophic cardiomyopathy',
  },
  {
    value: 'I42.8',
    label: 'Other cardiomyopathies',
  },
  {
    value: 'I42.9',
    label: 'Cardiomyopathy, unspecified',
  },
  {
    value: 'I44.1',
    label: 'Atrioventricular block, second degree',
  },
  {
    value: 'I44.2',
    label: 'Atrioventricular block, complete',
  },
  {
    value: 'I45.10',
    label: 'Unspecified right bundle-branch block',
  },
  {
    value: 'I45.5',
    label: 'Other specified heart block',
  },
  {
    value: 'I47.1',
    label: 'Supraventricular tachycardia',
  },
  {
    value: 'I47.2',
    label: 'Ventricular tachycardia',
  },
  {
    value: 'I47.29',
    label: 'Other ventricular tachycardia',
  },
  {
    value: 'I48.0',
    label: 'Paroxysmal atrial fibrillation',
  },
  {
    value: 'I48.1',
    label: 'Persistent atrial fibrillation',
  },
  {
    value: 'I48.11',
    label: 'Longstanding persistent atrial fibrillation',
  },
  {
    value: 'I48.19',
    label: 'Other persistent atrial fibrillation',
  },
  {
    value: 'I48.2',
    label: 'Chronic atrial fibrillation',
  },
  {
    value: 'I48.20',
    label: 'Chronic atrial fibrillation, unspecified',
  },
  {
    value: 'I48.21',
    label: 'Permanent atrial fibrillation',
  },
  {
    value: 'I48.3',
    label: 'Typical atrial flutter',
  },
  {
    value: 'I48.91',
    label: 'Unspecified atrial fibrillation',
  },
  {
    value: 'I48.92',
    label: 'Unspecified atrial flutter',
  },
  {
    value: 'I49.1',
    label: 'Atrial premature depolarization',
  },
  {
    value: 'I49.3',
    label: 'Ventricular premature depolarization',
  },
  {
    value: 'I49.5',
    label: 'Sick sinus syndrome',
  },
  {
    value: 'I49.8',
    label: 'Other specified cardiac arrhythmias',
  },
  {
    value: 'I49.9',
    label: 'Cardiac arrhythmia, unspecified',
  },
  {
    value: 'I50.1',
    label: 'Left ventricular failure, unspecified',
  },
  {
    value: 'I50.20',
    label: 'Unspecified systolic (congestive) heart failure',
  },
  {
    value: 'I50.21',
    label: 'Acute systolic (congestive) heart failure',
  },
  {
    value: 'I50.22',
    label: 'Chronic systolic (congestive) heart fail',
  },
  {
    value: 'I50.22',
    label: 'Chronic systolic (congestive) heart failure',
  },
  {
    value: 'I50.23',
    label: 'Acute on chronic systolic (congestive) heart failure',
  },
  {
    value: 'I50.30',
    label: 'Unspecified diastolic (congestive) heart failu',
  },
  {
    value: 'I50.30',
    label: 'Unspecified diastolic (congestive) heart failure',
  },
  {
    value: 'I50.31',
    label: 'Acute diastolic (congestive) heart failure',
  },
  {
    value: 'I50.32',
    label: 'Chronic diastolic (congestive) heart failure',
  },
  {
    value: 'I50.33',
    label: 'Acute on chronic diastolic (congestive) heart failure',
  },
  {
    value: 'I50.40',
    label:
      'Unspecified combined systolic (congestive) and diastolic (congestive) heart failure',
  },
  {
    value: 'I50.41',
    label:
      'Acute combined systolic (congestive) and diastolic (congestive) heart failure',
  },
  {
    value: 'I50.42',
    label:
      'Chronic combined systolic (congestive) and diastolic (congestive) heart failure',
  },
  {
    value: 'I50.43',
    label:
      'Acute on chronic combined systolic (congestive) and diastolic (congestive) heart failure',
  },
  {
    value: 'I50.810',
    label: 'Right heart failure, unspecified',
  },
  {
    value: 'I50.811',
    label: 'Acute right heart failure',
  },
  {
    value: 'I50.812',
    label: 'Chronic right heart failure',
  },
  {
    value: 'I50.813',
    label: 'Acute on chronic right heart failure',
  },
  {
    value: 'I50.814',
    label: 'Right heart failure due to left heart failure',
  },
  {
    value: 'I50.82',
    label: 'Biventricular heart failure',
  },
  {
    value: 'I50.83',
    label: 'High output heart failure',
  },
  {
    value: 'I50.84',
    label: 'End stage heart failure',
  },
  {
    value: 'I50.89',
    label: 'Other heart failure',
  },
  {
    value: 'I50.9',
    label: 'Heart failure, unspecified',
  },
  {
    value: 'I51.7',
    label: 'Cardiomegaly',
  },
  {
    value: 'I51.89',
    label: 'Other ill-defined heart diseases',
  },
  {
    value: 'I51.9',
    label: 'Heart disease, unspecified',
  },
  {
    value: 'I61.5',
    label: 'Nontraumatic intracerebral hemorrhage, intraventricular',
  },
  {
    value: 'I63.9',
    label: 'Cerebral infarction, unspecified',
  },
  {
    value: 'I65.23',
    label: 'Occlusion and stenosis of bilateral carotid arteries',
  },
  {
    value: 'I69.30',
    label: 'Unspecified sequelae of cerebral infarction',
  },
  {
    value: 'I70.0',
    label: 'Atherosclerosis of aorta',
  },
  {
    value: 'I71.2',
    label: 'Thoracic aortic aneurysm, without rupture',
  },
  {
    value: 'I73.00',
    label: "Raynaud's syndrome without gangrene",
  },
  {
    value: 'I73.9',
    label: 'Peripheral vascular disease, unspecified',
  },
  {
    value: 'I77.9',
    label: 'Disorder of arteries and arterioles, unspecified',
  },
  {
    value: 'I82.409',
    label:
      'Acute embolism and thrombosis of unspecified deep veins of unspecified lower extremity',
  },
  {
    value: 'I87.2',
    label: 'Venous insufficiency (chronic) (peripheral)',
  },
  {
    value: 'I89.0',
    label: 'Lymphedema, not elsewhere classified',
  },
  {
    value: 'I95.1',
    label: 'Orthostatic hypotension',
  },
  {
    value: 'I95.9',
    label: 'Hypotension, unspecified',
  },
  {
    value: 'J06.9',
    label: 'Acute upper respiratory infection, unspecified',
  },
  {
    value: 'J12.81',
    label: 'Pneumonia due to SARS-associated coronavirus',
  },
  {
    value: 'J12.82',
    label: 'Pneumonia due to coronavirus disease 2019',
  },
  {
    value: 'J12.89',
    label: 'Other viral pneumonia',
  },
  {
    value: 'J12.9',
    label: 'Viral pneumonia, unspecified',
  },
  {
    value: 'J15.9',
    label: 'Unspecified bacterial pneumonia',
  },
  {
    value: 'J18.1',
    label: 'Lobar pneumonia, unspecified organism',
  },
  {
    value: 'J18.8',
    label: 'Other pneumonia, unspecified organism',
  },
  {
    value: 'J18.9',
    label: 'Pneumonia, unspecified organism',
  },
  {
    value: 'J20.8',
    label: 'Acute bronchitis due to other specified organisms',
  },
  {
    value: 'J20.9',
    label: 'Acute bronchitis, unspecified',
  },
  {
    value: 'J21.0',
    label: 'Acute bronchiolitis due to respiratory syncytial virus',
  },
  {
    value: 'J21.8',
    label: 'Acute bronchiolitis due to other specified organisms',
  },
  {
    value: 'J21.9',
    label: 'Acute bronchiolitis, unspecified',
  },
  {
    value: 'J30.1',
    label: 'Allergic rhinitis due to pollen',
  },
  {
    value: 'J30.2',
    label: 'Other seasonal allergic rhinitis',
  },
  {
    value: 'J30.89',
    label: 'Other allergic rhinitis',
  },
  {
    value: 'J30.9',
    label: 'Allergic rhinitis, unspecified',
  },
  {
    value: 'J31.0',
    label: 'Chronic rhinitis',
  },
  {
    value: 'J32.9',
    label: 'Chronic sinusitis, unspecified',
  },
  {
    value: 'J34.2',
    label: 'Deviated nasal septum',
  },
  {
    value: 'J34.3',
    label: 'Hypertrophy of nasal turbinates',
  },
  {
    value: 'J34.89',
    label: 'Other specified disorders of nose and nasal sinuses',
  },
  {
    value: 'J35.1',
    label: 'Hypertrophy of tonsils',
  },
  {
    value: 'J35.2',
    label: 'Hypertrophy of adenoids',
  },
  {
    value: 'J35.3',
    label: 'Hypertrophy of tonsils with hypertrophy of adenoids',
  },
  {
    value: 'J38.00',
    label: 'Paralysis of vocal cords and larynx, unspecified',
  },
  {
    value: 'J38.3',
    label: 'Other diseases of vocal cords',
  },
  {
    value: 'J38.6',
    label: 'Stenosis of larynx',
  },
  {
    value: 'J39.8',
    label: 'Other specified diseases of upper respiratory tract',
  },
  {
    value: 'J40',
    label: 'Bronchitis, not specified as acute or chronic',
  },
  {
    value: 'J41.0',
    label: 'Simple chronic bronchitis',
  },
  {
    value: 'J41.1',
    label: 'Mucopurulent chronic bronchitis',
  },
  {
    value: 'J41.8',
    label: 'Mixed simple and mucopurulent chronic bronchitis',
  },
  {
    value: 'J42',
    label: 'Unspecified chronic bronchitis',
  },
  {
    value: 'J43.0',
    label: "Unilateral pulmonary emphysema [MacLeod's syndrome]",
  },
  {
    value: 'J43.1',
    label: 'Panlobular emphysema',
  },
  {
    value: 'J43.2',
    label: 'Centrilobular emphysema',
  },
  {
    value: 'J43.8',
    label: 'Other emphysema',
  },
  {
    value: 'J43.9',
    label: 'Emphysema, unspecified',
  },
  {
    value: 'J44.0',
    label:
      'Chronic obstructive pulmonary disease with (acute) lower respiratory infection',
  },
  {
    value: 'J44.1',
    label: 'Chronic obstructive pulmonary disease with (acute) exacerbation',
  },
  {
    value: 'J44.9',
    label: 'Chronic obstructive pulmonary disease, unspecified',
  },
  {
    value: 'J45.20',
    label: 'Mild intermittent asthma, uncomplicated',
  },
  {
    value: 'J45.21',
    label: 'Mild intermittent asthma with (acute) exacerbation',
  },
  {
    value: 'J45.22',
    label: 'Mild intermittent asthma with status asthmaticus',
  },
  {
    value: 'J45.30',
    label: 'Mild persistent asthma, uncomplicated',
  },
  {
    value: 'J45.31',
    label: 'Mild persistent asthma with (acute) exacerbation',
  },
  {
    value: 'J45.32',
    label: 'Mild persistent asthma with status asthmaticus',
  },
  {
    value: 'J45.40',
    label: 'Moderate persistent asthma, uncomplicated',
  },
  {
    value: 'J45.41',
    label: 'Moderate persistent asthma with (acute) exacerbation',
  },
  {
    value: 'J45.42',
    label: 'Moderate persistent asthma with status asthmaticus',
  },
  {
    value: 'J45.50',
    label: 'Severe persistent asthma, uncomplicated',
  },
  {
    value: 'J45.51',
    label: 'Severe persistent asthma with (acute) exacerbation',
  },
  {
    value: 'J45.52',
    label: 'Severe persistent asthma with status asthmaticus',
  },
  {
    value: 'J45.901',
    label: 'Unspecified asthma with (acute) exacerbation',
  },
  {
    value: 'J45.902',
    label: 'Unspecified asthma with status asthmaticus',
  },
  {
    value: 'J45.909',
    label: 'Unspecified asthma, uncomplicated',
  },
  {
    value: 'J45.990',
    label: 'Exercise induced bronchospasm',
  },
  {
    value: 'J45.991',
    label: 'Cough variant asthma',
  },
  {
    value: 'J45.998',
    label: 'Other asthma',
  },
  {
    value: 'J47.0',
    label: 'Bronchiectasis with acute lower respiratory infection',
  },
  {
    value: 'J47.1',
    label: 'Bronchiectasis with (acute) exacerbation',
  },
  {
    value: 'J47.9',
    label: 'Bronchiectasis, uncomplicated',
  },
  {
    value: 'J60',
    label: "Coalworker's pneumoconiosis",
  },
  {
    value: 'J61',
    label: 'Pneumoconiosis due to asbestos and other mineral fibers',
  },
  {
    value: 'J62.8',
    label: 'Pneumoconiosis due to other dust containing silica',
  },
  {
    value: 'J64',
    label: 'Unspecified pneumoconiosis',
  },
  {
    value: 'J67.9',
    label: 'Hypersensitivity pneumonitis due to unspecified organic dust',
  },
  {
    value: 'J68.3',
    label:
      'Other acute and subacute respiratory conditions due to chemicals, gases, fumes and vapors',
  },
  {
    value: 'J69.0',
    label: 'Pneumonitis due to inhalation of food and vomit',
  },
  {
    value: 'J70.1',
    label: 'Chronic and other pulmonary manifestations due to radiation',
  },
  {
    value: 'J70.9',
    label: 'Respiratory conditions due to unspecified external agent',
  },
  {
    value: 'J80',
    label: 'Acute respiratory distress syndrome',
  },
  {
    value: 'J81.0',
    label: 'Acute pulmonary edema',
  },
  {
    value: 'J81.1',
    label: 'Chronic pulmonary edema',
  },
  {
    value: 'J82',
    label: 'Pulmonary eosinophilia, not elsewhere classified',
  },
  {
    value: 'J82.83',
    label: 'Eosinophilic asthma',
  },
  {
    value: 'J84.10',
    label: 'Pulmonary fibrosis, unspecified',
  },
  {
    value: 'J84.111',
    label: 'Idiopathic interstitial pneumonia, not otherwise specified',
  },
  {
    value: 'J84.112',
    label: 'Idiopathic pulmonary fibrosis',
  },
  {
    value: 'J84.115',
    label: 'Respiratory bronchiolitis interstitial lung disease',
  },
  {
    value: 'J84.116',
    label: 'Cryptogenic organizing pneumonia',
  },
  {
    value: 'J84.17',
    label:
      'Other interstitial pulmonary diseases with fibrosis in diseases classified elsewhere',
  },
  {
    value: 'J84.170',
    label:
      'Interstitial lung disease with progressive fibrotic phenotype in diseases classified elsewhere',
  },
  {
    value: 'J84.81',
    label: 'Lymphangioleiomyomatosis',
  },
  {
    value: 'J84.841',
    label: 'Neuroendocrine cell hyperplasia of infancy',
  },
  {
    value: 'J84.89',
    label: 'Other specified interstitial pulmonary diseases',
  },
  {
    value: 'J84.9',
    label: 'Interstitial pulmonary disease, unspecified',
  },
  {
    value: 'J86.9',
    label: 'Pyothorax without fistula',
  },
  {
    value: 'J90',
    label: 'Pleural effusion, not elsewhere classified',
  },
  {
    value: 'J91.0',
    label: 'Malignant pleural effusion',
  },
  {
    value: 'J91.8',
    label: 'Pleural effusion in other conditions classified elsewhere',
  },
  {
    value: 'J92.0',
    label: 'Pleural plaque with presence of asbestos',
  },
  {
    value: 'J93.9',
    label: 'Pneumothorax, unspecified',
  },
  {
    value: 'J95.1',
    label: 'Acute pulmonary insufficiency following thoracic surgery',
  },
  {
    value: 'J95.2',
    label: 'Acute pulmonary insufficiency following nonthoracic surgery',
  },
  {
    value: 'J95.3',
    label: 'Chronic pulmonary insufficiency following surgery',
  },
  {
    value: 'J95.821',
    label: 'Acute postprocedural respiratory failure',
  },
  {
    value: 'J95.822',
    label: 'Acute and chronic postprocedural respiratory failure',
  },
  {
    value: 'J96.00',
    label:
      'Acute respiratory failure, unspecified whether with hypoxia or hypercapnia',
  },
  {
    value: 'J96.01',
    label: 'Acute respiratory failure with hypoxia',
  },
  {
    value: 'J96.02',
    label: 'Acute respiratory failure with hypercapnia',
  },
  {
    value: 'J96.10',
    label:
      'Chronic respiratory failure, unspecified whether with hypoxia or hypercapnia',
  },
  {
    value: 'J96.11',
    label: 'Chronic respiratory failure with hypoxia',
  },
  {
    value: 'J96.12',
    label: 'Chronic respiratory failure with hypercapnia',
  },
  {
    value: 'J96.20',
    label:
      'Acute and chronic respiratory failure, unspecified whether with hypoxia or hypercapnia',
  },
  {
    value: 'J96.21',
    label: 'Acute and chronic respiratory failure with hypoxia',
  },
  {
    value: 'J96.22',
    label: 'Acute and chronic respiratory failure with hypercapnia',
  },
  {
    value: 'J96.90',
    label:
      'Respiratory failure, unspecified, unspecified whether with hypoxia or hypercapnia',
  },
  {
    value: 'J96.91',
    label: 'Respiratory failure, unspecified with hypoxia',
  },
  {
    value: 'J96.92',
    label: 'Respiratory failure, unspecified with hypercapnia',
  },
  {
    value: 'J98.01',
    label: 'Acute bronchospasm',
  },
  {
    value: 'J98.09',
    label: 'Other diseases of bronchus, not elsewhere classified',
  },
  {
    value: 'J98.11',
    label: 'Atelectasis',
  },
  {
    value: 'J98.2',
    label: 'Interstitial emphysema',
  },
  {
    value: 'J98.4',
    label: 'Other disorders of lung',
  },
  {
    value: 'J98.6',
    label: 'Disorders of diaphragm',
  },
  {
    value: 'J98.8',
    label: 'Other specified respiratory disorders',
  },
  {
    value: 'J98.9',
    label: 'Respiratory disorder, unspecified',
  },
  {
    value: 'J99',
    label: 'Respiratory disorders in diseases classified elsewhere',
  },
  {
    value: 'K11.7',
    label: 'Disturbances of salivary secretion',
  },
  {
    value: 'K21.0',
    label: 'Gastro-esophageal reflux disease with esophagitis',
  },
  {
    value: 'K21.9',
    label: 'Gastro-esophageal reflux disease without esophagitis',
  },
  {
    value: 'K44.9',
    label: 'Diaphragmatic hernia without obstruction or gangrene',
  },
  {
    value: 'K59.00',
    label: 'Constipation, unspecified',
  },
  {
    value: 'K74.60',
    label: 'Unspecified cirrhosis of liver',
  },
  {
    value: 'L30.9',
    label: 'Dermatitis, unspecified',
  },
  {
    value: 'M05.10',
    label:
      'Rheumatoid lung disease with rheumatoid arthritis of unspecified site',
  },
  {
    value: 'M05.79',
    label:
      'Rheumatoid arthritis with rheumatoid factor of multiple sites without organ or systems involvement',
  },
  {
    value: 'M06.9',
    label: 'Rheumatoid arthritis, unspecified',
  },
  {
    value: 'M10.9',
    label: 'Gout, unspecified',
  },
  {
    value: 'M15.9',
    label: 'Polyosteoarthritis, unspecified',
  },
  {
    value: 'M19.90',
    label: 'Unspecified osteoarthritis, unspecified site',
  },
  {
    value: 'M25.50',
    label: 'Pain in unspecified joint',
  },
  {
    value: 'M25.561',
    label: 'Pain in right knee',
  },
  {
    value: 'M32.9',
    label: 'Systemic lupus erythematosus, unspecified',
  },
  {
    value: 'M34.81',
    label: 'Systemic sclerosis with lung involvement',
  },
  {
    value: 'M34.9',
    label: 'Systemic sclerosis, unspecified',
  },
  {
    value: 'M35.00',
    label: 'Sjogren syndrome, unspecified',
  },
  {
    value: 'M35.9',
    label: 'Systemic involvement of connective tissue, unspecified',
  },
  {
    value: 'M41.9',
    label: 'Scoliosis, unspecified',
  },
  {
    value: 'M51.36',
    label: 'Other intervertebral disc degeneration, lumbar region',
  },
  {
    value: 'M54.16',
    label: 'Radiculopathy, lumbar region',
  },
  {
    value: 'M54.2',
    label: 'Cervicalgia',
  },
  {
    value: 'M54.5',
    label: 'Low back pain',
  },
  {
    value: 'M54.50',
    label: 'Low back pain, unspecified',
  },
  {
    value: 'M54.6',
    label: 'Pain in thoracic spine',
  },
  {
    value: 'M54.9',
    label: 'Dorsalgia, unspecified',
  },
  {
    value: 'M62.81',
    label: 'Muscle weakness (generalized)',
  },
  {
    value: 'M62.89',
    label: 'Other specified disorders of muscle',
  },
  {
    value: 'M79.10',
    label: 'Myalgia, unspecified site',
  },
  {
    value: 'M79.7',
    label: 'Fibromyalgia',
  },
  {
    value: 'M79.89',
    label: 'Other specified soft tissue disorders',
  },
  {
    value: 'M81.0',
    label: 'Age-related osteoporosis without current pathological fracture',
  },
  {
    value: 'M85.80',
    label:
      'Other specified disorders of bone density and structure, unspecified site',
  },
  {
    value: 'N17.9',
    label: 'Acute kidney failure, unspecified',
  },
  {
    value: 'N18.2',
    label: 'Chronic kidney disease, stage 2 (mild)',
  },
  {
    value: 'N18.3',
    label: 'Chronic kidney disease, stage 3 (moderate)',
  },
  {
    value: 'N18.30',
    label: 'Chronic kidney disease, stage 3 unspecified',
  },
  {
    value: 'N18.31',
    label: 'Chronic kidney disease, stage 3a',
  },
  {
    value: 'N18.32',
    label: 'Chronic kidney disease, stage 3b',
  },
  {
    value: 'N18.4',
    label: 'Chronic kidney disease, stage 4 (severe)',
  },
  {
    value: 'N18.6',
    label: 'End stage renal disease',
  },
  {
    value: 'N18.9',
    label: 'Chronic kidney disease, unspecified',
  },
  {
    value: 'N20.0',
    label: 'Calculus of kidney',
  },
  {
    value: 'N39.0',
    label: 'Urinary tract infection, site not specified',
  },
  {
    value: 'N39.44',
    label: 'Nocturnal enuresis',
  },
  {
    value: 'N40.0',
    label: 'Benign prostatic hyperplasia without lower urinary tract symptoms',
  },
  {
    value: 'N52.9',
    label: 'Male erectile dysfunction, unspecified',
  },
  {
    value: 'P05.10',
    label: 'Newborn small for gestational age, unspecified weight',
  },
  {
    value: 'P05.9',
    label: 'Newborn affected by slow intrauterine growth, unspecified',
  },
  {
    value: 'P07.02',
    label: 'Extremely low birth weight newborn, 500-749 grams',
  },
  {
    value: 'P07.03',
    label: 'Extremely low birth weight newborn, 750-999 grams',
  },
  {
    value: 'P07.14',
    label: 'Other low birth weight newborn, 1000-1249 grams',
  },
  {
    value: 'P07.18',
    label: 'Other low birth weight newborn, 2000-2499 grams',
  },
  {
    value: 'P07.20',
    label: 'Extreme immaturity of newborn, unspecified weeks of gestation',
  },
  {
    value: 'P07.22',
    label: 'Extreme immaturity of newborn, gestational age 23 completed weeks',
  },
  {
    value: 'P07.23',
    label: 'Extreme immaturity of newborn, gestational age 24 completed weeks',
  },
  {
    value: 'P07.24',
    label: 'Extreme immaturity of newborn, gestational age 25 completed weeks',
  },
  {
    value: 'P07.25',
    label: 'Extreme immaturity of newborn, gestational age 26 completed weeks',
  },
  {
    value: 'P07.26',
    label: 'Extreme immaturity of newborn, gestational age 27 completed weeks',
  },
  {
    value: 'P07.30',
    label: 'Preterm newborn, unspecified weeks of gestation',
  },
  {
    value: 'P07.31',
    label: 'Preterm newborn, gestational age 28 completed weeks',
  },
  {
    value: 'P07.32',
    label: 'Preterm newborn, gestational age 29 completed weeks',
  },
  {
    value: 'P07.34',
    label: 'Preterm newborn, gestational age 31 completed weeks',
  },
  {
    value: 'P07.36',
    label: 'Preterm newborn, gestational age 33 completed weeks',
  },
  {
    value: 'P07.37',
    label: 'Preterm newborn, gestational age 34 completed weeks',
  },
  {
    value: 'P07.38',
    label: 'Preterm newborn, gestational age 35 completed weeks',
  },
  {
    value: 'P22.0',
    label: 'Respiratory distress syndrome of newborn',
  },
  {
    value: 'P22.9',
    label: 'Respiratory distress of newborn, unspecified',
  },
  {
    value: 'P27.1',
    label: 'bronchopulmonary, perinatal',
  },
  {
    value: 'P27.1',
    label: 'Bronchopulmonary dysplasia originating in the perinatal period',
  },
  {
    value: 'P27.9',
    label:
      'Unspecified chronic respiratory disease originating in the perinatal period',
  },
  {
    value: 'P28.4',
    label: 'Other apnea of newborn',
  },
  {
    value: 'P28.5',
    label: 'Respiratory failure of newborn',
  },
  {
    value: 'P28.89',
    label: 'Other specified respiratory conditions of newborn',
  },
  {
    value: 'P29.3',
    label: 'Persistent fetal circulation',
  },
  {
    value: 'P29.30',
    label: 'Pulmonary hypertension of newborn',
  },
  {
    value: 'P52.0',
    label: 'Intraventricular (nontraumatic) hemorrhage, grade 1, of newborn',
  },
  {
    value: 'P61.1',
    label: 'Polycythemia neonatorum',
  },
  {
    value: 'P78.83',
    label: 'Newborn esophageal reflux',
  },
  {
    value: 'P84',
    label: 'Other problems with newborn',
  },
  {
    value: 'P91.60',
    label: 'Hypoxic ischemic encephalopathy [HIE], unspecified',
  },
  {
    value: 'P92.9',
    label: 'Feeding problem of newborn, unspecified',
  },
  {
    value: 'Q21.0',
    label: 'Ventricular septal defect',
  },
  {
    value: 'Q21.1',
    label: 'Atrial septal defect',
  },
  {
    value: 'Q21.10',
    label: 'Atrial septal defect, unspecified',
  },
  {
    value: 'Q21.12',
    label: 'Patent foramen ovale',
  },
  {
    value: 'Q21.2',
    label: 'Atrioventricular septal defect',
  },
  {
    value: 'Q21.3',
    label: 'Tetralogy of Fallot',
  },
  {
    value: 'Q23.1',
    label: 'Congenital insufficiency of aortic valve',
  },
  {
    value: 'Q24.9',
    label: 'Congenital malformation of heart, unspecified',
  },
  {
    value: 'Q25.0',
    label: 'Patent ductus arteriosus',
  },
  {
    value: 'Q25.1',
    label: 'Coarctation of aorta',
  },
  {
    value: 'Q31.5',
    label: 'Congenital laryngomalacia',
  },
  {
    value: 'Q32.0',
    label: 'Congenital tracheomalacia',
  },
  {
    value: 'Q32.2',
    label: 'Congenital bronchomalacia',
  },
  {
    value: 'Q33.6',
    label: 'Congenital hypoplasia and dysplasia of lung',
  },
  {
    value: 'Q38.1',
    label: 'Ankyloglossia',
  },
  {
    value: 'Q79.0',
    label: 'Congenital diaphragmatic hernia',
  },
  {
    value: 'Q79.1',
    label: 'Other congenital malformations of diaphragm',
  },
  {
    value: 'Q87.89',
    label:
      'Other specified congenital malformation syndromes, not elsewhere classified',
  },
  {
    value: 'Q90.9',
    label: 'Down syndrome, unspecified',
  },
  {
    value: 'R00.0',
    label: 'Tachycardia, unspecified',
  },
  {
    value: 'R00.1',
    label: 'Bradycardia, unspecified',
  },
  {
    value: 'R00.2',
    label: 'Palpitations',
  },
  {
    value: 'R01.1',
    label: 'Cardiac murmur, unspecified',
  },
  {
    value: 'R03.0',
    label: 'Elevated blood-pressure reading, without diagnosis of hypertension',
  },
  {
    value: 'R04.2',
    label: 'Hemoptysis',
  },
  {
    value: 'R05',
    label: 'Cough',
  },
  {
    value: 'R05.1',
    label: 'Acute cough',
  },
  {
    value: 'R05.3',
    label: 'Chronic cough',
  },
  {
    value: 'R05.8',
    label: 'Other specified cough',
  },
  {
    value: 'R05.9',
    label: 'Cough, unspecified',
  },
  {
    value: 'R06.00',
    label: 'Dyspnea, unspecified',
  },
  {
    value: 'R06.01',
    label: 'Orthopnea',
  },
  {
    value: 'R06.02',
    label: 'Shortness of breath',
  },
  {
    value: 'R06.03',
    label: 'Acute respiratory distress',
  },
  {
    value: 'R06.09',
    label: 'Other forms of dyspnea',
  },
  {
    value: 'R06.1',
    label: 'Stridor',
  },
  {
    value: 'R06.2',
    label: 'Wheezing',
  },
  {
    value: 'R06.3',
    label: 'Periodic breathing',
  },
  {
    value: 'R06.4',
    label: 'Hyperventilation',
  },
  {
    value: 'R06.5',
    label: 'Mouth breathing',
  },
  {
    value: 'R06.81',
    label: 'Apnea, not elsewhere classified',
  },
  {
    value: 'R06.82',
    label: 'Tachypnea, not elsewhere classified',
  },
  {
    value: 'R06.83',
    label: 'Snoring',
  },
  {
    value: 'R06.89',
    label: 'Other abnormalities of breathing',
  },
  {
    value: 'R06.9',
    label: 'Unspecified abnormalities of breathing',
  },
  {
    value: 'R07.2',
    label: 'Precordial pain',
  },
  {
    value: 'R07.89',
    label: 'Other chest pain',
  },
  {
    value: 'R07.9',
    label: 'Chest pain, unspecified',
  },
  {
    value: 'R09.01',
    label: 'Asphyxia',
  },
  {
    value: 'R09.02',
    label: 'Hypoxemia',
  },
  {
    value: 'R09.2',
    label: 'Respiratory arrest',
  },
  {
    value: 'R09.81',
    label: 'Nasal congestion',
  },
  {
    value: 'R09.82',
    label: 'Postnasal drip',
  },
  {
    value: 'R09.89',
    label:
      'Other specified symptoms and signs involving the circulatory and respiratory systems',
  },
  {
    value: 'R10.9',
    label: 'Unspecified abdominal pain',
  },
  {
    value: 'R11.0',
    label: 'Nausea',
  },
  {
    value: 'R13.10',
    label: 'Dysphagia, unspecified',
  },
  {
    value: 'R13.12',
    label: 'Dysphagia, oropharyngeal phase',
  },
  {
    value: 'R13.13',
    label: 'Dysphagia, pharyngeal phase',
  },
  {
    value: 'R13.19',
    label: 'Other dysphagia',
  },
  {
    value: 'R19.7',
    label: 'Diarrhea, unspecified',
  },
  {
    value: 'R20.2',
    label: 'Paresthesia of skin',
  },
  {
    value: 'R21',
    label: 'Rash and other nonspecific skin eruption',
  },
  {
    value: 'R23.0',
    label: 'Cyanosis',
  },
  {
    value: 'R25.1',
    label: 'Tremor, unspecified',
  },
  {
    value: 'R25.2',
    label: 'Cramp and spasm',
  },
  {
    value: 'R26.2',
    label: 'Difficulty in walking, not elsewhere classified',
  },
  {
    value: 'R26.81',
    label: 'Unsteadiness on feet',
  },
  {
    value: 'R26.89',
    label: 'Other abnormalities of gait and mobility',
  },
  {
    value: 'R26.9',
    label: 'Unspecified abnormalities of gait and mobility',
  },
  {
    value: 'R27.9',
    label: 'Unspecified lack of coordination',
  },
  {
    value: 'R29.6',
    label: 'Repeated falls',
  },
  {
    value: 'R29.818',
    label: 'Other symptoms and signs involving the nervous system',
  },
  {
    value: 'R29.898',
    label: 'Other symptoms and signs involving the musculoskeletal system',
  },
  {
    value: 'R30.0',
    label: 'Dysuria',
  },
  {
    value: 'R31.9',
    label: 'Hematuria, unspecified',
  },
  {
    value: 'R32',
    label: 'Unspecified urinary incontinence',
  },
  {
    value: 'R35.1',
    label: 'Nocturia',
  },
  {
    value: 'R40.0',
    label: 'Somnolence',
  },
  {
    value: 'R40.4',
    label: 'Transient alteration of awareness',
  },
  {
    value: 'R41.0',
    label: 'Disorientation, unspecified',
  },
  {
    value: 'R41.3',
    label: 'Other amnesia',
  },
  {
    value: 'R41.82',
    label: 'Altered mental status, unspecified',
  },
  {
    value: 'R41.840',
    label: 'Attention and concentration deficit',
  },
  {
    value: 'R41.89',
    label:
      'Other symptoms and signs involving cognitive functions and awareness',
  },
  {
    value: 'R42',
    label: 'Dizziness and giddiness',
  },
  {
    value: 'R44.3',
    label: 'Hallucinations, unspecified',
  },
  {
    value: 'R49.0',
    label: 'Dysphonia',
  },
  {
    value: 'R50.9',
    label: 'Fever, unspecified',
  },
  {
    value: 'R51',
    label: 'Headache',
  },
  {
    value: 'R51.0',
    label: 'Headache with orthostatic component, not elsewhere classified',
  },
  {
    value: 'R51.9',
    label: 'Headache, unspecified',
  },
  {
    value: 'R52',
    label: 'Pain, unspecified',
  },
  {
    value: 'R53.1',
    label: 'Weakness',
  },
  {
    value: 'R53.81',
    label: 'Other malaise',
  },
  {
    value: 'R53.82',
    label: 'Chronic fatigue, unspecified',
  },
  {
    value: 'R53.83',
    label: 'Other fatigue',
  },
  {
    value: 'R54',
    label: 'Age-related physical debility',
  },
  {
    value: 'R55',
    label: 'Syncope and collapse',
  },
  {
    value: 'R56.9',
    label: 'Unspecified convulsions',
  },
  {
    value: 'R59.0',
    label: 'Localized enlarged lymph nodes',
  },
  {
    value: 'R60.0',
    label: 'Localized edema',
  },
  {
    value: 'R60.9',
    label: 'Edema, unspecified',
  },
  {
    value: 'R61',
    label: 'Generalized hyperhidrosis',
  },
  {
    value: 'R62.0',
    label: 'Delayed milestone in childhood',
  },
  {
    value: 'R62.50',
    label:
      'Unspecified lack of expected normal physiological development in childhood',
  },
  {
    value: 'R62.51',
    label: 'Failure to thrive (child)',
  },
  {
    value: 'R62.7',
    label: 'Adult failure to thrive',
  },
  {
    value: 'R63.3',
    label: 'Feeding difficulties',
  },
  {
    value: 'R63.30',
    label: 'Feeding difficulties, unspecified',
  },
  {
    value: 'R63.4',
    label: 'Abnormal weight loss',
  },
  {
    value: 'R63.5',
    label: 'Abnormal weight gain',
  },
  {
    value: 'R63.6',
    label: 'Underweight',
  },
  {
    value: 'R63.8',
    label: 'Other symptoms and signs concerning food and fluid intake',
  },
  {
    value: 'R68.13',
    label: 'Apparent life threatening event in infant (ALTE)',
  },
  {
    value: 'R68.2',
    label: 'Dry mouth, unspecified',
  },
  {
    value: 'R68.3',
    label: 'Clubbing of fingers',
  },
  {
    value: 'R71.8',
    label: 'Other abnormality of red blood cells',
  },
  {
    value: 'R73.01',
    label: 'Impaired fasting glucose',
  },
  {
    value: 'R73.03',
    label: 'Prediabetes',
  },
  {
    value: 'R73.09',
    label: 'Other abnormal glucose',
  },
  {
    value: 'R73.9',
    label: 'Hyperglycemia, unspecified',
  },
  {
    value: 'R74.8',
    label: 'Abnormal levels of other serum enzymes',
  },
  {
    value: 'R76.8',
    label: 'Other specified abnormal immunological findings in serum',
  },
  {
    value: 'R77.8',
    label: 'Other specified abnormalities of plasma proteins',
  },
  {
    value: 'R78.81',
    label: 'Bacteremia',
  },
  {
    value: 'R79.0',
    label: 'Abnormal level of blood mineral',
  },
  {
    value: 'R79.81',
    label: 'Abnormal blood-gas level',
  },
  {
    value: 'R79.89',
    label: 'Other specified abnormal findings of blood chemistry',
  },
  {
    value: 'R79.9',
    label: 'Abnormal finding of blood chemistry, unspecified',
  },
  {
    value: 'R89.9',
    label:
      'Unspecified abnormal finding in specimens from other organs, systems and tissues',
  },
  {
    value: 'R91.1',
    label: 'Solitary pulmonary nodule',
  },
  {
    value: 'R91.8',
    label: 'Other nonspecific abnormal finding of lung field',
  },
  {
    value: 'R93.1',
    label:
      'Abnormal findings on diagnostic imaging of heart and coronary circulation',
  },
  {
    value: 'R93.8',
    label:
      'Abnormal findings on diagnostic imaging of other specified body structures',
  },
  {
    value: 'R93.89',
    label:
      'Abnormal findings on diagnostic imaging of other specified body structures',
  },
  {
    value: 'R94.2',
    label: 'Abnormal results of pulmonary function studies',
  },
  {
    value: 'R94.31',
    label: 'Abnormal electrocardiogram [ECG] [EKG]',
  },
  {
    value: 'R94.39',
    label: 'Abnormal result of other cardiovascular function study',
  },
  {
    value: 'T17.908A',
    label:
      'Unspecified foreign body in respiratory tract, part unspecified causing other injury, initial encounter',
  },
  {
    value: 'U07.0',
    label: 'Vaping-related disorder',
  },
  {
    value: 'U07.1',
    label: 'COVID-19',
  },
  {
    value: 'U09.9',
    label: 'Post COVID-19 condition, unspecified',
  },
  {
    value: 'W19.XXXA',
    label: 'Unspecified fall, initial encounter',
  },
  {
    value: 'W94.11XS',
    label: 'Exposure to residence or prolonged visit at high altitude, sequela',
  },
  {
    value: 'Z86.16',
    label: 'Personal history of COVID-19',
  },
  {
    value: 'Z99.81',
    label: 'Dependence on supplemental oxygen',
  },
  {
    value: 'Z99.89',
    label: 'Dependence on other enabling machines and devices',
  },
  {
    value: 'J95.89',
    label:
      'Other postprocedural complications and disorders of respiratory system, not elsewhere classified',
  },
  {
    value: 'Z87.891',
    label: 'Personal history of nicotine dependence / former smoker',
  },
  { value: 'Z99.81', label: 'Dependence on supplemental oxygen' },
  { value: 'T86.819', label: 'Unspecified complication of lung transplant' },
  { value: 'Z94.2', label: 'Lung transplant status' },
  { value: 'Z93.0', label: 'Tracheostomy status' },
  {
    value: 'Z99.89',
    label: 'Dependence on other enabling machines and devices',
  },
  { value: 'T86.810', label: 'Lung transplant rejection' },
  {
    value: 'J44.89',
    label: 'Other specified chronic obstructive pulmonary disease',
  },
  {
    value: 'C83.18',
    label: 'Mantle cell lymphoma, lymph nodes of multiple sites',
  },
  { value: 'C78.01', label: 'Secondary malignant neoplasm of right lung' },
  { value: 'J38.01', label: 'Paralysis of vocal cords and larynx, unilateral' },
  {
    value: 'T17.908S',
    label:
      'Unspecified foreign body in respiratory tract, part unspecified causing other injury',
  },
  { value: 'J84.114', label: 'Acute interstitial pneumonitis' },
  { value: 'Z91.89', label: 'dependence on respirator [ventilator] status.' },
  {
    value: 'J63.2',
    label:
      'Berylliosis - a chronic allergic-type lung response and chronic lung disease caused by exposure to beryllium',
  },
  { value: 'Z86.711', label: 'History of Pulmonary Embolism' },
]
  // .sort((a, b) => (a.value > b.value ? 1 : -1))
  .map(i => ({ value: i.value, label: `${i.value} - ${i.label}` }))

import { ReactNode } from 'react'
import { Button, Modal } from 'react-bootstrap'

interface DialogBoxProps {
  showDialog: boolean
  cancelNavigation: any
  confirmNavigation: any
  content: string | (() => ReactNode)
  alternateConfirmLabel?: string
}

const DialogBox: React.FC<DialogBoxProps> = ({
  showDialog,
  cancelNavigation,
  confirmNavigation,
  content,
  alternateConfirmLabel,
}) => {
  return (
    <Modal show={showDialog}>
      <Modal.Header>
        <Modal.Title>Warning</Modal.Title>
      </Modal.Header>
      <Modal.Body>{typeof content === 'function' ? content() : content}</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={cancelNavigation}>
          No
        </Button>
        <Button variant="danger" onClick={confirmNavigation}>
          {alternateConfirmLabel ?? 'Yes'}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
export default DialogBox

/** @jsxImportSource @emotion/react */
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'
import Spinner from 'react-bootstrap/Spinner'

import { useAppDispatch, useAppSelector } from 'hooks/redux'
import * as generalHelper from 'helpers/generalHelper'
import Upload from 'components/atoms/Upload'
import Button from 'components/atoms/Button'
import TitleLead from 'components/atoms/TitleLead'
import TabHeader from 'components/atoms/TabHeader'
import Link from 'components/atoms/Link'
import { setLoader } from 'store/slice/loaderSlice'
import routes from '../../constants/routes'
import { confirm } from 'components/molecules/Confirmation'
import config from 'config/app'
import * as api from 'helpers/api'
import {
  useAddFileMutation,
  useRemoveFileMutation,
  usePromiseFileMutation,
} from 'store/services/dmeOrder'
import { Order } from 'store/types'

interface IProps {
  order: Order
}

const faxOfficeNotesStatus =
  `This order has been marked to receive a fax. The Office Notes ` +
  `now need to be faxed to TestSmarter at: ${config?.testSmarterFaxNumber}`

const OfficeNotes = ({ order }: IProps) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { orderId } = useParams()
  const loading = useAppSelector((state) => state.loader.row)
  const [addFile, result] = useAddFileMutation()
  const [removeFile] = useRemoveFileMutation()
  const [promiseFile] = usePromiseFileMutation()
  const file_type_id = 1
  const files = order?.files
    ? Object.values(order.files).filter((f) => f.uploadType === 'OFFICE_NOTES')
    : []
  // const files = Array.isArray(order?.files)
  //     ? [...order.file].sort((a, b) => b.id - a.id)
  //     : []

  console.log(files)
  const handleUpload = async (acceptedFiles: File[]) => {
    dispatch(setLoader(true))
    console.log(acceptedFiles)
    const [file] = acceptedFiles

    const payload = {
      orderId,
      uploadType: 'OFFICE_NOTES',
    }
    const { url, key } = await api.get(`/dme/upload-url`, {
      ...payload,
      fileName: file.name,
    })

    console.log(url, key)
    await api.s3Put(url, file)
    await addFile({ ...payload, fileKey: key, fileName: file.name })

    dispatch(setLoader(false))
  }

  const handleRemoveFile = ({ key }) => {
    if (key) {
      confirm('file').then(() => {
        removeFile({ orderId, fileKey: key })
      })
    }
  }

  const headers = [
    {
      columnName: 'Document Name',
      columnSize: 5,
      template: (file) => (
        <>
          {(file.url || true) && (
            <a target="_blank" href={file.url} rel="noreferrer">
              {file.filename}
            </a>
          )}
        </>
      ),
    },
    {
      columnName: 'Date',
      columnSize: 5,
      template: (file) => <span>{`${moment(file.uploadDate).format('h:mm a MMM Do, YYYY')}`}</span>,
    },
    {
      columnName: 'Action',
      columnSize: 2,
      template: (file) => (
        <>
          <Link
            to="#"
            label="Remove"
            className="btn btn- btn-sm btn-outline-danger"
            onClick={() => {
              handleRemoveFile(file)
            }}
          />
        </>
      ),
    },
  ]

  return (
    <div className="container">
      <div className="container">
        <TabHeader
          title="Office Notes"
          back={`${routes.index}${routes.order.edit}${orderId}/insurance`}
          actionButtons={
            order
              ? [
                  files.length || order?.officeNotesInRx ? (
                    order?.testType !== 'HST' ? (
                      <Button
                        label="Next"
                        onClick={() =>
                          navigate(`${routes.index}${routes.order.edit}${orderId}/hstDevice`)
                        }
                        className="mr-3 btn-primary"
                        css={{ minWidth: '80px' }}
                      />
                    ) : (
                      <></>
                    )
                  ) : (
                    <>
                      {!order?.promisedFiles?.OFFICE_NOTES && (
                        <Button
                          label="Fax Office Notes"
                          onClick={() =>
                            promiseFile({
                              orderId,
                              promiseType: 'OFFICE_NOTES',
                            })
                          }
                          className="mr-3 btn-outline-secondary"
                        />
                      )}
                      <Upload
                        disabled={loading}
                        label="Upload Office Notes"
                        className="btn btn-primary"
                        handleUpload={handleUpload}>
                        {loading && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="mr-1"
                          />
                        )}
                      </Upload>
                    </>
                  ),
                ]
              : []
          }
        />
      </div>
      {!order?.officeNotesInRx ? (
        <div className="container p-4">
          <div className="row">
            <div className="col-sm-12 mb-2">
              <TitleLead label="Uploaded files" />
            </div>
            <div className="col-sm-12">
              <div className="container-fluid">
                <div className="row border-bottom my-2">
                  {Array.isArray(headers) &&
                    headers.map((row, index) => {
                      return (
                        <div
                          className={`col${row?.columnSize ? '-' + row.columnSize : ''} textGray`}
                          key={index}>
                          {row?.columnName}
                        </div>
                      )
                    })}
                </div>
                {Array.isArray(files) &&
                  files.map((dataRow, dataIndex) => {
                    return (
                      <div className="row border rounded py-3" key={dataIndex}>
                        {Array.isArray(headers) &&
                          headers.map((row, index) => {
                            return (
                              <div
                                className={`col${
                                  row?.columnSize ? '-' + row.columnSize : ''
                                } m-auto`}
                                key={index}>
                                {row?.template(dataRow)}
                              </div>
                            )
                          })}
                      </div>
                    )
                  })}
                {files?.length === 0 && !order?.promisedFiles?.OFFICE_NOTES && (
                  <div className="card">
                    <div className="card-body text-center font-italic text-muted">
                      {generalHelper.t('No Files have been uploaded to this order')}.
                    </div>
                  </div>
                )}
                {files?.length === 0 && order?.promisedFiles?.OFFICE_NOTES && (
                  <div className="card">
                    <div className="card-body text-center font-italic text-muted">
                      {faxOfficeNotesStatus}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="card">
          <div className="card-body text-center font-italic text-muted">
            Office notes are included in the RX documents.
          </div>
        </div>
      )}
    </div>
  )
}

export default OfficeNotes

import { SyntheticEvent } from 'react'
import { FieldInputProps } from 'formik'
import styled from '@emotion/styled'
import * as generalHelper from '../../helpers/generalHelper'

interface IProps {
  id?: string
  label?: string
  parentClassName?: string
  className?: string
  icon?: string
  value?: boolean
  defaultValue?: string
  name?: string
  checked?: boolean
  disabled?: boolean
  handleChange: (e: SyntheticEvent) => void
}

const Checkbox = styled.input`
  margin-top: 0.1rem;
  margin-left: -1.5rem;
`

const CheckboxComponent = (props: IProps) => {
  const parentClassName = props.parentClassName ?? 'custom-control'
  const className = props.className ?? 'danger'
  const icon = props.icon ?? null
  const id = props.id ?? 'cb-' + Math.trunc(Math.random() * 10000)
  const value = props.value ?? ''
  const defaultValue = props.defaultValue && !props.value ? props.defaultValue : undefined

  return (
    <div className={parentClassName}>
      <Checkbox
        className={`form-check-input ${className}`}
        type="checkbox"
        id={`checkbox-${id}`}
        name={props.name}
        checked={props.value}
        disabled={props.disabled}
        value={value}
        defaultValue={defaultValue}
        onChange={(e) => {
          props.handleChange(e)
        }}
      />
      {props.label && (
        <label htmlFor={`checkbox-${id}`} className="form-check-label">
          {icon && <i className={icon}></i>} {generalHelper.t(props.label)}
        </label>
      )}
    </div>
  )
}

export const CheckboxInput = ({
  field,
  form,
  ...props
}: {
  form: any
  field: FieldInputProps<boolean>
  label: string
}) => <CheckboxComponent {...field} {...props} handleChange={field.onChange} />

export default CheckboxComponent

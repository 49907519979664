import { baseApi } from './baseApi'
import { Physician } from 'store/types'

type LatestPhysiciansParams = {
  officeId: number | string
  patientId: number | string
  count: number
}

export const physicianApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getLabPhysicians: builder.query<Physician[], { [key: string]: string }>({
      query: (query) => ({ url: `/lab/physicians/list`, params: query }),
    }),
    getDmePhysicians: builder.query<Physician[], { [key: string]: string }>({
      query: (query) => ({ url: `/lab/physicians`, params: query }),
    }),
    getDmeLatestPhysicians: builder.query<Physician[], LatestPhysiciansParams>({
      query: (query) => ({ url: `/lab/physicians/latest`, params: query }),
    }),
    getPhysicianHistory: builder.query<any, { id: number }>({
      query: (query) => ({ url: `/lab/physicians/${query.id}/history` }),
    }),
    approveAddress: builder.mutation<Physician, { orderId: string; id: string; uuid: string }>({
      query: ({ orderId, id, uuid }) => ({
        method: `PUT`,
        url: `/lab/physicians/${id}/addresses/${uuid}/approval`,
      }),
      invalidatesTags: (result, error, { orderId }) => [{ type: 'Order', id: orderId }],
    }),
    rejectAddress: builder.mutation<Physician, { orderId: string; id: string; uuid: string }>({
      query: ({ orderId, id, uuid }) => ({
        method: `PUT`,
        url: `/lab/physicians/${id}/addresses/${uuid}/rejection`,
      }),
      invalidatesTags: (result, error, { orderId }) => [{ type: 'Order', id: orderId }],
    }),
  }),
})

export const {
  useGetLabPhysiciansQuery,
  useGetPhysicianHistoryQuery,
  useGetDmePhysiciansQuery,
  useGetDmeLatestPhysiciansQuery,
  useApproveAddressMutation,
  useRejectAddressMutation,
} = physicianApi

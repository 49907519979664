const routes = {
  index: '/lab/',
  upsert: 'upsert/',
  fax: {
    root: 'fax/',
    send: 'send/',
    incoming: 'incoming/',
  },
  order: {
    root: 'order/',
    new: 'new',
    edit: 'orderEdit/',
    nightOwl: 'nightowl/',
    diagnostic: 'orderDiagnostic/',
    billing: {
      authorization: {
        testType: 'POX_COX_HST',
        bucket: 'AUTHORIZATION',
      },
      billingReview: {
        testType: 'POX_COX_HST',
        bucket: 'BILLING_REVIEW',
      },
      billingHold: {
        testType: 'POX_COX_HST',
        bucket: 'BILLING_HELD',
      },
      billingQueued: {
        testType: 'POX_COX_HST',
        bucket: 'BILLING_QUEUED',
      },
      billingFailed: {
        testType: 'POX_COX_HST',
        bucket: 'BILLING_FAILED',
      },
    },
    actionItems: {
      directMessages: {
        testType: 'ALL',
        bucket: 'DM',
      },
      demographicVerification: {
        testType: 'POX_COX',
        bucket: 'DEMO_VER',
      },
      insuranceVerification: {
        testType: 'POX_COX',
        bucket: 'INS_VER',
      },
      needAttention: {
        testType: 'POX_COX',
        bucket: 'NEED_ATTENTION',
      },
      awaitingReview: {
        testType: 'POX_COX',
        bucket: 'AWAITING_REVIEW',
      },
    },
    pulseOxCapOx: {
      directMessages: {
        testType: 'ALL',
        bucket: 'DM',
      },
      active: {
        testType: 'POX_COX',
        bucket: 'ACTIVE',
      },
      all: {
        testType: 'POX_COX',
        bucket: 'ALL',
      },
      open: {
        testType: 'POX_COX',
        bucket: 'OPEN',
      },
      demographicVerification: {
        testType: 'POX_COX',
        bucket: 'DEMO_VER',
      },
      insuranceVerification: {
        testType: 'POX_COX',
        bucket: 'INS_VER',
      },
      needAttention: {
        testType: 'POX_COX',
        bucket: 'NEED_ATTENTION',
      },
      awaitingReview: {
        testType: 'POX_COX',
        bucket: 'AWAITING_REVIEW',
      },
      readyToSchedule: {
        testType: 'POX_COX',
        bucket: 'NO_NEW',
      },
      followUp: {
        testType: 'POX_COX',
        bucket: 'NO_FOLLOW_UP',
      },
      needToShip: {
        testType: 'POX_COX',
        bucket: 'NO_NEED_TO_SHIP',
      },
      readyToShip: {
        testType: 'POX_COX',
        bucket: 'NO_READY_TO_SHIP',
      },
      shipped: {
        testType: 'POX_COX',
        bucket: 'NO_SHIPPED',
      },
      delivered: {
        testType: 'POX_COX',
        bucket: 'NO_DELIVERED',
      },
      pendingReturn: {
        testType: 'POX_COX',
        bucket: 'PENDING_RETURN',
      },
      shippedBack: {
        testType: 'POX_COX',
        bucket: 'NO_SHIPPED_BACK',
      },
      completed: {
        testType: 'POX_COX',
        bucket: 'COMPLETED',
      },
      failedFaxes: {
        testType: 'ALL',
        bucket: 'FAILED_FAXES',
      },
      pendingFaxes: {
        testType: 'ALL',
        bucket: 'PENDING_FAXES',
      },
    },
    hst: {
      open: {
        testType: 'HST',
        bucket: 'OPEN',
      },
      demographicVerification: {
        testType: 'HST',
        bucket: 'DEMO_VER',
      },
      insuranceVerification: {
        testType: 'HST',
        bucket: 'INS_VER',
      },
      needAttention: {
        testType: 'HST',
        bucket: 'NEED_ATTENTION',
      },
      awaitingReview: {
        testType: 'HST',
        bucket: 'AWAITING_REVIEW',
      },
      orderVerification: {
        testType: 'HST',
        bucket: 'HST_VERIFICATION',
      },
      patientConsent: {
        testType: 'HST',
        bucket: 'HST_PATIENT_CONSENT',
      },
      preAuthorization: {
        testType: 'HST',
        bucket: 'AUTHORIZATION',
      },
      patientScheduling: {
        testType: 'HST',
        bucket: 'HST_PATIENT_SCHEDULING',
      },
      deviceShipping: {
        testType: 'HST',
        bucket: 'HST_DEVICE_SHIPPING',
      },
      readyToSchedule: {
        testType: 'HST',
        bucket: 'NO_NEW',
      },
      followUp: {
        testType: 'HST',
        bucket: 'NO_FOLLOW_UP',
      },
      needToShip: {
        testType: 'HST',
        bucket: 'NO_NEED_TO_SHIP',
      },
      readyToShip: {
        testType: 'HST',
        bucket: 'NO_READY_TO_SHIP',
      },
      shipped: {
        testType: 'HST',
        bucket: 'NO_SHIPPED',
      },
      delivered: {
        testType: 'HST',
        bucket: 'NO_DELIVERED',
      },
      pendingReturn: {
        testType: 'HST',
        bucket: 'PENDING_RETURN',
      },
      shippedBack: {
        testType: 'HST',
        bucket: 'NO_SHIPPED_BACK',
      },
      patientTesting: {
        testType: 'HST',
        bucket: 'HST_PATIENT_TESTING',
      },
      completed: {
        testType: 'HST',
        bucket: 'COMPLETED',
      },
      failedFaxes: {
        testType: 'HST',
        bucket: 'FAILED_FAXES',
      },
      pendingFaxes: {
        testType: 'HST',
        bucket: 'PENDING_FAXES',
      },
      all: {
        testType: 'HST',
        bucket: 'ALL',
      },
    },
    psg: {
      open: {
        testType: 'PSG',
        bucket: 'OPEN',
      },
      demographicVerification: {
        testType: 'PSG',
        bucket: 'DEMO_VER',
      },
      insuranceVerification: {
        testType: 'PSG',
        bucket: 'INS_VER',
      },
      needAttention: {
        testType: 'PSG',
        bucket: 'NEED_ATTENTION',
      },
      awaitingReview: {
        testType: 'PSG',
        bucket: 'AWAITING_REVIEW',
      },
      orderVerification: {
        testType: 'PSG',
        bucket: 'PSG_VERIFICATION',
      },
      patientConsent: {
        testType: 'PSG',
        bucket: 'PSG_PATIENT_CONSENT',
      },
      preAuthorization: {
        testType: 'PSG',
        bucket: 'AUTHORIZATION',
      },
      patientScheduling: {
        testType: 'PSG',
        bucket: 'PSG_PATIENT_SCHEDULING',
      },
      patientScheduled: {
        testType: 'PSG',
        bucket: 'PSG_PATIENT_SCHEDULED',
      },
      completed: {
        testType: 'PSG',
        bucket: 'COMPLETED',
      },
      failedFaxes: {
        testType: 'PSG',
        bucket: 'FAILED_FAXES',
      },
      pendingFaxes: {
        testType: 'PSG',
        bucket: 'PENDING_FAXES',
      },
      all: {
        testType: 'PSG',
        bucket: 'ALL',
      },
    },
  },
  ticket: {
    root: 'ticket/',
  },
  device: {
    root: 'device/',
  },
  batch: {
    root: 'batch/',
  },
  fileType: {
    root: 'fileType/',
  },
  file: {
    root: 'file/',
  },
  deviceType: {
    root: 'deviceType/',
  },
  deviceCategory: {
    root: 'deviceCategory/',
  },
  deviceTypeLink: {
    root: 'deviceTypeLink/',
  },
  brand: {
    root: 'brand/',
  },
  ticketType: {
    root: 'ticketType/',
  },
  ticketTopic: {
    root: 'ticketTopic/',
  },
  company: {
    root: 'company/',
  },
  physicianGroups: {
    root: 'physician-groups/',
  },
  region: {
    root: 'region/',
  },
  office: {
    root: 'office/',
  },
  patient: {
    root: 'patient/',
    directory: 'directory/',
    moveReport: 'move-report/',
    editReportConditions: 'edit-report-conditions/',
    mergePatients: 'merge-patients/',
  },
  payer: {
    root: 'payors/',
  },
  dmePayer: {
    root: 'dme-payors/',
  },
  status: {
    root: 'status/',
  },
  labUsers: {
    root: 'users/',
    directory: 'directory/',
  },
  dmeUsers: {
    root: 'dme-users/',
    directory: 'directory/',
  },
  physicianGroupUsers: {
    root: 'physician-group-users/',
    directory: 'directory/',
  },
  bulletins: {
    root: 'bulletins/',
    directory: 'directory/',
  },
  reports: {
    root: 'reports/',
    stats: 'stats',
    analytics: 'analytics',
  },
}

export default routes

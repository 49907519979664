import { Routes, Route } from 'react-router-dom'
import Verify from './verifyContract/Verify'
import HstLayout from './hstPaperwork/HstLayout'

import routes from './../dme/constants/routes'
import PaymentSuccess from './PaymentSuccess'
import PaperworkComplete from './PaperworkComplete'
import { tsOfficeFax, tsOfficePhone } from 'constants/misc'

const Landing = () => {
  return (
    <div className="landing">
      <header>
        <h1 />
        <ul>
          <li>
            <a
              href="https://www.testsmarter.net/idtf-diagnostic-testing-facility/"
              target="_blank"
              rel="noreferrer">
              Our Company
            </a>
          </li>
          <li>
            <a
              href="https://www.testsmarter.net/idtf-diagnostic-testing-facility/contact"
              target="_blank"
              rel="noreferrer">
              Contact Us
            </a>
          </li>
        </ul>
      </header>
      <div className="content">
        <Routes>
          <Route path={`${routes.landing.verifyContract}`}>
            <Route path={`hst/:orderId/:nonce/*`} element={<HstLayout />} />
            <Route path={`aob/:orderId/:aobId`} element={<Verify />} />
          </Route>
          <Route path="payment/:orderId/success" element={<PaymentSuccess />} />
          <Route path="paperwork/complete" element={<PaperworkComplete />} />
        </Routes>
      </div>
      <footer>
        <p>301 NW Cole Terrace, Ste 101</p>
        <p>Lake City, Florida 32055</p>
        <p>Phone: {tsOfficePhone}</p>
        <p>Fax: {tsOfficeFax}</p>
      </footer>
    </div>
  )
}

export default Landing

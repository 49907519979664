import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import PatientUpsert from '../forms/PatientUpsert'
import { Order } from 'store/types'

interface IProps {
  order: Order
  open: boolean
  onClose: () => void
  onSubmit: () => Promise<void>
}

export const UpdatePatientModal = ({ order, open, onClose }: IProps) => {
  return (
    <Modal centered show={open} onHide={onClose} size="lg">
      <Modal.Header>
        <Modal.Title>EDIT PATIENT INFORMATION</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PatientUpsert
          unlockedForm
          patient={order?.patient}
          isNewOrder
          modalView
          postSubmitAction={onClose}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button autoFocus variant="primary" type="submit" form="patientUpsert">
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

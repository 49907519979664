import InputMask from 'react-input-mask'
import { SyntheticEvent } from 'react'
import { FieldInputProps } from 'formik'

interface IProps {
  value: string
  defaultValue?: string
  name: string
  label?: string
  mask: string
  maskChar?: string
  className?: string
  onChange: (e: SyntheticEvent) => void
  onBlur?: (e: SyntheticEvent) => void
  disabled?: boolean
  parentDiv?: boolean
  error?: string
  autoComplete?: string
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  placeholder?: string
}

const InputMaskComponent = (props: IProps) => {
  const value = props?.value ?? ''
  const defaultValue = props?.defaultValue && !props.value ? props.defaultValue : undefined

  const name = props.name
  const mask = props.mask ?? null
  const maskChar = props.maskChar ?? ' '
  const className = props.className ? props.className : 'form-control'
  const onChange = props.onChange ?? (() => {})
  const onBlur = props.onBlur ?? (() => {})
  const onKeyPress = props.onKeyPress ?? (() => {})
  const disabled = props.disabled ? true : false
  const error = props?.error ?? ''
  const placeholder = props?.placeholder ?? ''

  /**
   * Check this for more info: https://www.npmjs.com/package/react-input-mask
   */
  const inputOnly = (
    <>
      <InputMask
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        onBlur={onBlur}
        name={name}
        mask={mask}
        maskChar={maskChar}
        className={`${className} ${props.error ? 'is-invalid' : ''}`}
        disabled={disabled}
        autoComplete={props.autoComplete}
        onKeyUp={onKeyPress}
        placeholder={placeholder}
      />
      {error && <div className="text-danger">{error}</div>}
    </>
  )

  if (props.parentDiv === null) {
    return inputOnly
  } else {
    return (
      <div className="form-group mb-0 text-gray">
        {props.label && <label htmlFor={props.name}>{props.label}</label>}
        {inputOnly}
      </div>
    )
  }
}

export const MaskedTextInput = ({
  field,
  form,
  ...props
}: {
  form: any
  field: FieldInputProps<string>
  label: string
  mask: string
}) => (
  <InputMaskComponent
    {...field}
    {...props}
    error={form.touched[field.name] ? form.errors[field.name] : ''}
  />
)

export default InputMaskComponent

import config from 'config/app'
import axios from 'axios'
import axiosApiInstance from './axios'

const host = config?.newApi

/**
 * Updates an existing Model in the API
 *
 * @param {*} url
 * @param {*} id
 * @param {*} params
 * @returns
 */
export const put = async (url: string, id: string | number, params?: any) => {
    const returnValue = await axiosApiInstance.put(`${host}${url}/${id}`, params, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    })
    return returnValue?.data
}

export const del = async (
  url: string,
  id: string | number,
  params?: any
) => {
    const returnValue = await axiosApiInstance.delete(`${host}${url}/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      data: params,
    })
    return returnValue?.data
}

export const post = async (url: string, params?: any) => {
  const returnValue = await axiosApiInstance.post(`${host}${url}`, params, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
  })
  return returnValue?.data
}

export const get = async (url: string, params?: any, arrayBuffer?: boolean) => {
  const returnValue = await axiosApiInstance.get(`${host}${url}`, {
    params,
    ...arrayBuffer && {responseType: 'arraybuffer'},
    headers: {
      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
  })
  return returnValue?.data
}

/**
 * perform S3 upload
 *
 * @param {*} url
 * @param {*} id
 * @param {*} params
 * @returns
 */
 export const s3Put = async (url: string, params?: any) => {
  try {
    const returnValue = await axios.put(url, params)
    return returnValue?.data
      ? returnValue.data
      : null
  } catch (error) {
    console.log('error', error)
  }
}
/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { css } from '@emotion/react'
import { skipToken } from '@reduxjs/toolkit/query/react'
import Spinner from 'react-bootstrap/Spinner'
import { JsonViewer } from '@textea/json-viewer';

import { useGetOrderStatesQuery } from 'store/services/devDiagnostics'
import DashboardContent from '../atoms/DashboardContent'
import routes from '../constants/routes'
import { OrderProgressBar } from 'components/molecules/OrderProgressBar'
import clsx from 'clsx';

const createOrderObject = (state: any) => ({
  ...state,
  helm: state?.state?.VERIFICATION_HELM,
})

const labBuckets = {
  ACTIVE: 'Active',
  OPEN: 'Open',
  DEMO_VER: 'Demographic Verification',
  INS_VER: 'Insurance Verification',
  AWAITING_REVIEW: 'Awaiting Review',
  NO_NEW: 'Logistics -> New/Ready to Schedule',
  NO_FOLLOW_UP: 'Logistics -> Follow up',
  NO_NEED_TO_SHIP: 'Logistics -> Need to Ship',
  NO_READY_TO_SHIP: 'Logistics -> Ready to Ship',
  NO_SHIPPED: 'Logistics -> Shipped to Patient',
  NO_DELIVERED: 'Logistics -> Delivered',
  PENDING_RETURN: 'Logistics -> Pending Return',
  COMPLETED: 'Completed',
  FAILED_VERIFICATION: '* No matching menu item *',
}

const OrderDiagnostic = () => {
  const { orderId: initialOrderId } = useParams()
  const [orderIdField, setOrderIdField] = useState(initialOrderId ?? '')
  const [orderId, setOrderId] = useState(initialOrderId ?? '')
  const [currEvent, setCurrEvent] = useState(0)
  const { data, refetch, isLoading } = useGetOrderStatesQuery(orderId ? { orderId } : skipToken)
  const orderStates = data?.map(createOrderObject)
  const selectedState = orderStates?.[currEvent]

  useEffect(() => {
    setCurrEvent(0)
  }, [data])

  return (
    <DashboardContent
      title="Order Diagnostics"
      icon="fa fa-headset"
      content={
        <div className="card card-primary mt-3">
          <div className="row p-3">
            <div className="col-6">
              <div className="row">
                <div className="col-6 d-flex align-items-end">
                  <div>
                    <Form.Label htmlFor="orderId">Order ID</Form.Label>
                    <Form.Control
                      id="orderId"
                      placeholder="Order ID"
                      value={orderIdField}
                      onChange={(e) => setOrderIdField(e.target.value)}
                    />
                  </div>
                  <Button
                    className="mx-2"
                    onClick={() => {
                      setOrderId(orderIdField)
                      if (orderId) {
                        refetch()
                      }
                    }}>
                    GO
                  </Button>
                  {isLoading && <Spinner animation="border" variant="primary" />}
                </div>
                <div
                  className="col-6"
                  css={css`
                    display: flex;
                    justify-content: flex-end;
                    align-items: flex-end;
                  `}>
                  {orderStates?.length && (
                    <>
                      <Button
                        variant="outline-primary"
                        className="mr-3"
                        css={{ width: 50 }}
                        onClick={() => setCurrEvent((p) => (p === 0 ? 0 : p - 1))}>
                        <i className="fas fa-angle-double-left"></i>
                      </Button>
                      <Button
                        variant="outline-primary"
                        css={{ width: 50 }}
                        onClick={() =>
                          setCurrEvent((p) => (p < orderStates.length! - 1 ? p + 1 : p))
                        }>
                        <i className="fas fa-angle-double-right"></i>
                      </Button>
                    </>
                  )}
                </div>
              </div>
              <div
                className="row mt-3 ml-1 p-2"
                css={css`
                  border: 1px solid lightgray;
                  height: calc(100vh - 230px);
                  border-radius: 5px;
                  overflow-y: auto;
                  background: #f5f5f5;
                  display: block !important;
                `}>
                {orderStates?.length &&
                  orderStates.map((state, idx) => (
                    <div
                      onClick={() => setCurrEvent(idx)}
                      key={`${state.event}-${idx}`}
                      className="w-100 d-flex mb-1 p-1"
                      css={css`
                        background: ${idx === currEvent ? '#2C83BD99' : 'unset'};
                        :hover {
                          background: #2c83bd40;
                        }
                        cursor: pointer;
                        justify-content: space-between;
                        align-items: center;
                      `}>
                      <span className={clsx({'not-translated': state.event === false})}
                        css={css`
                          font-size: 0.85rem;
                          line-height: 1.2rem;
                          
                          &.not-translated {
                            font-style: italic;
                          }
                        `}>
                        {idx}.{' '}
                        {typeof state.event === 'string'
                          ? state.event
                          : typeof state.event === 'boolean'
                          ? state.originalEvent
                          : state.event.type}
                        <br />
                        <span
                          css={css`
                            margin-left: 11px;
                            margin-top: 3px;
                            font-size: inherit;
                          `}>
                          <i className="fas fa-steering-wheel" /> {state.helm}
                        </span>
                      </span>
                      <OrderProgressBar context="DME" orderData={state} />
                    </div>
                  ))}
              </div>
            </div>
            <div className="col-5 pl-5 mt-4">
              <div className="row py-3 align-items-center">
                <div className="col-4">
                  <strong>State after current event</strong>
                </div>
                <div className="col-8">
                  <OrderProgressBar context="DME" orderData={selectedState} />
                </div>
              </div>
              <div className="row py-3">
                {/*{Object.keys(selectedState?.progress).map((key: string) => {*/}
                {/*  return <span key={key}>{key}: {selectedState.progress[key]} </span>*/}
                {/*})}*/}
                <JsonViewer value={selectedState?.progress} rootName="Progress"/>
              </div>
              <div className="row py-3">
                <div className="col-4">
                  <strong>DME Bucket</strong>
                </div>
                <div className="col-8">{selectedState?.bucket}</div>
              </div>
              <div className="row py-3">
                <div className="col-4">
                  <strong>Lab Buckets</strong>
                </div>
                <div className="col-8">
                  {[...(selectedState?.labBuckets ?? [])]
                    ?.sort((a, b) => (a > b ? 1 : -1))
                    .map((bucket: string) => (
                      <div className="d-flex w-100">
                        <div css={{ flex: 1, lineHeight: '1.2rem' }}>{bucket}</div>
                        <div css={{ flex: 1, lineHeight: '1.2rem', overflow: 'nowrap' }}>
                          {labBuckets[bucket]}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <div className="row py-3">
                <div className="col-4">
                  <strong>Helm</strong>
                </div>
                <div className="col-8">{selectedState?.state?.VERIFICATION_HELM}</div>
              </div>
              <div className="row py-3">
                <div className="col-4">
                  <strong>State after event</strong>
                </div>
                <div className="col-8">
                  <JsonViewer value={selectedState?.state || {}} editable={false} rootName="STATE"/>

                </div>
              </div>
              <div className="row py-3">
                <div className="col-4">
                  <strong>Context after event</strong>
                </div>
                <div className="col-8">
                  <JsonViewer value={selectedState?.context || {}} editable={false} rootName="CONTEXT"/>

                </div>
              </div>
              <div className="row py-3">
                <div className="col-4">
                  <strong>event</strong>
                </div>
                <div className="col-8">
                  <JsonViewer value={selectedState?.esEvent || {}} editable={false} rootName="ES Event"/>

                </div>
              </div>
            </div>
          </div>
        </div>
      }
      breadcrumb={[
        {
          label: 'Orders',
          to: `${routes.index}${routes.fax.root}`,
        },
        {
          label: 'Diagnostics',
        },
      ]}
    />
  )
}

export default OrderDiagnostic

import { SyntheticEvent } from 'react'
import { FieldInputProps } from 'formik'
interface IProps {
  name: string
  label: string
  value: string
  type?: string
  rows: number
  disabled?: boolean
  placeholder?: string
  handleChange: (e: SyntheticEvent) => void
  labelClassName?: string
}
const TextareaComponent = (props: IProps) => {
  const type = props?.type ?? 'text'
  let value = props?.value ?? ''

  return (
    <div className="form-group">
      <label htmlFor={props.name} className={props.labelClassName}>
        {props.label}
      </label>
      <textarea
        type={type}
        className="form-control"
        id={props.name}
        disabled={props.disabled}
        placeholder={props.placeholder}
        name={props.name}
        rows={props.rows}
        value={value}
        onChange={(e) => {
          props.handleChange(e)
        }}
        onBlur={(e) => {
          props.handleChange(e)
        }}></textarea>
    </div>
  )
}

export const TextAreaInput = ({
  field,
  form,
  ...props
}: {
  form: any
  field: FieldInputProps<string>
  label: string
}) => (
  <TextareaComponent
    {...field}
    {...props}
    handleChange={field.onChange}
    error={form.errors[field.name]}
  />
)

export default TextareaComponent

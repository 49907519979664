import { Field, FormikErrors, FormikValues, FormikTouched } from 'formik'
import {
  polar,
  oxygenLpm,
  papType,
  minPressure,
  maxPressure,
} from './utils/orderFormUtils'
import { TestConditionsFormValues } from './OrderForm'

interface IProps {
  values: FormikValues
  errors: FormikErrors<TestConditionsFormValues>
  touched: FormikTouched<TestConditionsFormValues>
  setFieldValue: (k: string, v: string) => void
  isOrder: boolean
  testTypes: any
}

const TestConditionModalForm = ({
  values,
  errors,
  touched,
  testTypes,
}: IProps) => {
  return (
    <>
      <div className="row">
        <div className="col form-group">
          <label htmlFor="device_category_id">Test type</label>
          {testTypes?.map((row, index) => (
            <div className="ml-4" key={index}>
              <Field
                className="form-check-input"
                type="radio"
                id={row.name}
                name="device_category_id"
                value={`${row.id}`}
              />
              <label className="form-check-label" htmlFor={row.name}>
                {row.device_type && row.device_type.name}
              </label>
            </div>
          ))}
        </div>
      </div>
      <div className="row">
        <div className="col form-group">
          <label htmlFor="device_category_id">Conditions</label>
        </div>
      </div>
      <div className="row">
        <div className="col-6 form-group">
          <label htmlFor="oxygen">{'On Oxygen?'}</label>
          <Field className="form-control" as="select" name="oxygen">
            {polar &&
              polar.map((p, i) => (
                <option key={i} value={p.value}>
                  {p.key}
                </option>
              ))}
          </Field>
          {touched.oxygen && errors.oxygen ? (
            <div className="text-danger">{errors.oxygen}</div>
          ) : null}
        </div>
        {values.oxygen == 1 ? (
          <div className="col-6">
            <label htmlFor="oxygen_lpm">{'Oxygen LPM'}</label>
            <Field className="form-control" as="select" name="oxygen_lpm">
              {oxygenLpm &&
                oxygenLpm.map((ol, i) => (
                  <option key={i} value={ol.value}>
                    {ol.value}
                  </option>
                ))}
            </Field>
            {touched.oxygen_lpm && errors.oxygen_lpm ? (
              <div className="text-danger">{errors.oxygen_lpm}</div>
            ) : null}
          </div>
        ) : null}
      </div>
      <div className="row">
        <div className="col-3 form-group">
          <label htmlFor="pap">{'With PAP?'}</label>
          <Field className="form-control" as="select" name="pap">
            {polar &&
              polar.map((p, i) => (
                <option key={i} value={p.value}>
                  {p.key}
                </option>
              ))}
          </Field>
          {touched.pap && errors.pap ? (
            <div className="text-danger">{errors.pap}</div>
          ) : null}
        </div>
        {values.pap == 1 ? (
          <div className="col-3 form-group">
            <label htmlFor="pap_type">{'PAP Type'}</label>
            <Field className="form-control" as="select" name="pap_type">
              {papType &&
                papType.map((pt, i) => (
                  <option key={i} value={pt.value}>
                    {pt.value}
                  </option>
                ))}
            </Field>
            {touched.pap_type && errors.pap_type ? (
              <div className="text-danger">{errors.pap_type}</div>
            ) : null}
          </div>
        ) : null}
        {values.pap == 1 &&
        values.pap_type !== '' &&
        values.pap_type !== 'Unspecified' &&
        values.pap_type === 'APAP' ? (
          <div className="col-3 form-group">
            <label htmlFor="apap_min">{'Min Pressure (cmH2O)'}</label>
            <Field className="form-control" as="select" name="apap_min">
              {minPressure &&
                minPressure.map((mp, i) => (
                  <option key={i} value={mp.value}>
                    {mp.value}
                  </option>
                ))}
            </Field>
            {touched.apap_min && errors.apap_min ? (
              <div className="text-danger">{errors.apap_min}</div>
            ) : null}
          </div>
        ) : null}
        {values.pap == 1 &&
        values.pap_type !== '' &&
        values.pap_type !== 'Unspecified' &&
        values.pap_type === 'BiPAP' ? (
          <div className="col-3 form-group">
            <label htmlFor="bipap_i">{'IPAP Pressure (cmH2O)'}</label>
            <Field className="form-control" as="select" name="bipap_i">
              {minPressure &&
                minPressure.map((mp, i) => (
                  <option key={i} value={mp.value}>
                    {mp.value}
                  </option>
                ))}
            </Field>
            {touched.bipap_i && errors.bipap_i ? (
              <div className="text-danger">{errors.bipap_i}</div>
            ) : null}
          </div>
        ) : null}
        {values.pap == 1 &&
        values.pap_type !== '' &&
        values.pap_type !== 'Unspecified' &&
        values.pap_type === 'CPAP' ? (
          <div className="col-3 form-group">
            <label htmlFor="cpap">{'CPAP Pressure (cmH2O)'}</label>
            <Field className="form-control" as="select" name="cpap">
              {minPressure &&
                minPressure.map((mp, i) => (
                  <option key={i} value={mp.value}>
                    {mp.value}
                  </option>
                ))}
            </Field>
            {touched.cpap && errors.cpap ? (
              <div className="text-danger">{errors.cpap}</div>
            ) : null}
          </div>
        ) : null}
        {values.pap == 1 &&
        values.pap_type !== '' &&
        values.pap_type !== 'Unspecified' &&
        values.pap_type === 'APAP' ? (
          <div className="col-3 form-group">
            <label htmlFor="apap_max">{'Max Pressure (cmH2O)'}</label>
            <Field className="form-control" as="select" name="apap_max">
              {maxPressure &&
                maxPressure.map((mp, i) => (
                  <option key={i} value={mp.value}>
                    {mp.value}
                  </option>
                ))}
            </Field>
            {touched.apap_max && errors.apap_max ? (
              <div className="text-danger">{errors.apap_max}</div>
            ) : null}
          </div>
        ) : null}
        {values.pap == 1 &&
        values.pap_type !== '' &&
        values.pap_type !== 'Unspecified' &&
        values.pap_type === 'BiPAP' ? (
          <div className="col-3 form-group">
            <label htmlFor="bipap_e">{'EPAP Pressure (cmH2O)'}</label>
            <Field className="form-control" as="select" name="bipap_e">
              {minPressure &&
                minPressure.map((mp, i) => (
                  <option key={i} value={mp.value}>
                    {mp.value}
                  </option>
                ))}
            </Field>
            {touched.bipap_e && errors.bipap_e ? (
              <div className="text-danger">{errors.bipap_e}</div>
            ) : null}
          </div>
        ) : null}
      </div>
      <div className="row">
        <div className="col form-group text-bold">Check any that apply:</div>
      </div>
      <div className="row">
        <div className="col-4 form-group">
          <div className="form-check">
            <Field
              type="checkbox"
              name="non_invasive"
              className="form-check-input"
              id="non_invasive"
            />
            <label className="form-check-label" htmlFor="non_invasive">
              Non-Invasive Ventilator
            </label>
          </div>
        </div>
        <div className="col-4 form-group">
          <div className="form-check">
            <Field
              type="checkbox"
              name="adaptive_support"
              className="form-check-input"
              id="adaptive_support"
            />
            <label className="form-check-label" htmlFor="adaptive_support">
              Adaptive Support Ventilation
            </label>
          </div>
        </div>
        <div className="col-4 form-group">
          <div className="form-check">
            <Field
              type="checkbox"
              name="oral_appliace"
              className="form-check-input"
              id="oral_appliace"
            />
            <label className="form-check-label" htmlFor="oral_appliace">
              Oral appliance
            </label>
          </div>
        </div>
      </div>
    </>
  )
}

export default TestConditionModalForm

import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { insertOrderTest } from 'store/slice/orderTestSlice'
import { Formik, Form } from 'formik'
import TestConditionModalForm from '../../../forms/TestConditionModalForm'
import { handleSubmit } from '../../../../atoms/Upsert'
import Button from '../../../../atoms/Button'
import ButtonModal from '../../../../atoms/ButtonModal'
import validationSchema from '../../../forms/schemas/orderValidationSchema'
import { formatOrderTestPayload } from '../../../forms/utils/orderFormUtils'
import { closeOneModal } from 'helpers/generalHelper'
import { useGetTestTypesQuery } from 'store/services/testTypes'

interface IProps {
  order: any
}

interface FormValues {
  order
  device_category_id
  oxygen
  oxygen_lpm
  pap
  pap_type
  apap_min
  apap_max
  bipap_i
  bipap_e
  cpap
  non_invasive
  adaptive_support
  oral_appliace
}

const TestConditionUpsert = ({ order }: IProps) => {
  const dispatch = useAppDispatch()
  const officeId = useAppSelector((state) => state.init.currentOfficeId)
  const { data: testTypesRaw } = useGetTestTypesQuery({ officeId })
  const testTypes =
    testTypesRaw &&
    Object.entries(testTypesRaw)
      .map(([code, data]) => ({
        ...data,
        id: code,
      }))
      .sort((a, b) => (a.order > b.order ? 1 : -1))

  const initialValues = {
    order: false,
    device_category_id: `${order?.device_category_id}` ?? '',
    oxygen: '',
    oxygen_lpm: '',
    pap: '',
    pap_type: '',
    apap_min: '',
    apap_max: '',
    bipap_i: '',
    bipap_e: '',
    cpap: '',
    non_invasive: '',
    adaptive_support: '',
    oral_appliace: '',
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        values.order_id = order.id
        await handleSubmit(event, {
          values: formatOrderTestPayload(false, values),
          functions: {
            insert: insertOrderTest,
          },
          dispatch: dispatch,
        })
        closeOneModal('testCondition_modal')
      }}>
      {({ values, errors, touched, handleSubmit }) => (
        <>
          <Form>
            <div className="container">
              <div className="modal-body">
                <TestConditionModalForm
                  values={values}
                  errors={errors}
                  touched={touched}
                  isOrder={false}
                  testTypes={testTypes}
                />
              </div>
              <div className="modal-footer">
                <ButtonModal
                  className="btn-default"
                  label="Cancel"
                  type="button"
                  data_dismiss="modal"
                />
                <Button
                  label="Add Test Condition"
                  type="button"
                  id="btn-ts"
                  onClick={handleSubmit}
                  className="btn ml-2"
                />
              </div>
            </div>
          </Form>
        </>
      )}
    </Formik>
  )
}

export default TestConditionUpsert

import { SyntheticEvent } from 'react'
import { Link } from 'react-router-dom'
import React from 'react'
import * as generalHelper from '../../helpers/generalHelper'

interface IProps {
  to: string
  className?: string
  onClick?: (e: SyntheticEvent) => void
  icon?: string
  label?: string
  labelSmall?: string
  role?: string
  data_toggle?: string
  data_target?: string
  children?: string
  target?: string
  style?: React.CSSProperties
}

const LinkComponent = (props: IProps) => {
  const to = props.to ? props.to : '#'
  const className = props.className ? props.className : ''
  const onClick = props.onClick ? props.onClick : () => {}
  const icon = props.icon ? props.icon : ''
  const label = props.label ? props.label : ''
  const labelSmall = props.labelSmall ? props.labelSmall : ''
  const role = props.role ? props.role : ''
  const data_toggle = props.data_toggle ? props.data_toggle : ''
  const data_target = props.data_target ? props.data_target : ''
  const children = props.children ? props.children : ''

  return (
    <Link
      to={to}
      className={className}
      role={role}
      onClick={onClick}
      data-toggle={data_toggle}
      data-target={data_target}
      target={props.target}
      style={props.style}>
      {icon && <i className={`${icon} mr-1`}></i>} {generalHelper.t(label)}{' '}
      {labelSmall && <span>{generalHelper.t(labelSmall)}</span>}
      {children}
    </Link>
  )
}

export default LinkComponent

import moment from 'moment'
import styled from '@emotion/styled'

import TitleLead from '../../atoms/TitleLead'
import Button from '../../atoms/Button'
import * as generalHelper from '../../../helpers/generalHelper'
import { formatPhoneNumber, formatZipcode } from '../../../helpers/format'

const SectionHeader = styled.div`
  font-size: 1.3rem;
  padding-bottom: 8px;
`

export type ReviewValues = {
  isPatientCorrect: boolean
  isPhysicianCorrect: boolean
  isInsuranceCorrect: boolean
}

interface IProps {
  patient: any
  physician: any
  physicianAddress: any
  isPhysicianLoading: boolean
  isPatientLoading: boolean
  onEditSection: () => void
  reviewFieldValues: ReviewValues
  onReviewChange: (v: ReviewValues) => void
  isPayersLoading: boolean
  orderPayers: any
}

const PatientInformationReview = ({
  patient,
  isPatientLoading,
  physician,
  physicianAddress,
  isPhysicianLoading,
  onEditSection,
  reviewFieldValues,
  onReviewChange,
  isPayersLoading,
  orderPayers,
}: IProps) => {
  const isOrderPayersEmpty = !orderPayers?.length

  const onReview = (e) => {
    onReviewChange({
      ...reviewFieldValues,
      [e.target.name]: e.target.checked,
    })
  }

  const handlePatientContent = () => {
    if (isPatientLoading) return <div className="py-2">Loading ...</div>
    if (!patient) return <div className="mb-3">No Patient Found</div>

    return (
      <>
        <div className="row mb-2">
          <div className="col">
            <SectionHeader className="font-weight-bold textGray">
              {`${patient.firstName} ${patient.lastName}`}
            </SectionHeader>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col">
            <span className="font-weight-bold textGray">Date Of Birth: </span>
            <span>{moment(patient.dob).format('MM/DD/YYYY')}</span>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col">
            <span className="font-weight-bold textGray">Gender: </span>
            <span>{patient.gender ?? 'N/A'}</span>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col">
            <span className="font-weight-bold textGray">Home Telephone: </span>
            <span>{formatPhoneNumber(patient.homePhone)}</span>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col">
            <span className="font-weight-bold textGray">Smartphone: </span>
            <span>{formatPhoneNumber(patient.mobilePhone)}</span>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col">
            <span className="font-weight-bold textGray">Email: </span>
            <span>{patient.email}</span>
          </div>
        </div>

        <div className="row mb-2">
          <div className="col d-flex flex-column">
            <span className="font-weight-bold textGray">Address: </span>
            {patient.primaryAddressStreetLine1 && <div> {patient.primaryAddressStreetLine1}</div>}
            {patient.primaryAddressStreetLine2 && <div> {patient.primaryAddressStreetLine2}</div>}
            {patient.primaryAddressCity && (
              <div>
                {patient.primaryAddressCity}
                {patient.primaryAddressState ? `, ${patient.primaryAddressState}` : ''}
                {patient.primaryAddressZip ? `, ${formatZipcode(patient.primaryAddressZip)}` : ''}
              </div>
            )}
          </div>
        </div>

        {patient.deliveryAddressStreetLine1 ? (
          <div className="row mb-2">
            <div className="col d-flex flex-column">
              <span className="font-weight-bold textGray">Delivery: </span>
              {patient.deliveryAddressStreetLine1 && (
                <div> {patient.deliveryAddressStreetLine1}</div>
              )}
              {patient.deliveryAddressStreetLine2 && (
                <div> {patient.deliveryAddressStreetLine2}</div>
              )}
              {patient.deliveryAddressCity && (
                <div>
                  {patient.deliveryAddressCity}
                  {patient.deliveryAddressState ? `, ${patient.deliveryAddressState}` : ''}
                  {patient.deliveryAddressZip
                    ? `, ${formatZipcode(patient.deliveryAddressZip)}`
                    : ''}
                </div>
              )}
            </div>
          </div>
        ) : null}
      </>
    )
  }

  const handlePhysicianContent = () => {
    if (isPhysicianLoading) return <div className="py-2">Loading ...</div>
    if (!physician) return <div className="mb-3">No Physician Found</div>

    const location = {
      address: physicianAddress.addressStreetLine1,
      address2: physicianAddress.addressStreetLine2,
      city: physicianAddress.addressCity,
      state: physicianAddress.addressState,
      zip: formatZipcode(physicianAddress.addressZip),
    }

    return (
      <>
        <div className="row mb-2">
          <div className="col">
            <SectionHeader className="font-weight-bold textGray">
              {`${physician.firstName} ${physician.lastName}`}
            </SectionHeader>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col">
            <span className="font-weight-bold textGray">NPI: </span>
            <span>{physician.npi}</span>
          </div>
        </div>
        {physicianAddress.telephone ? (
          <div className="row mb-2">
            <div className="col">
              <span className="font-weight-bold textGray">Telephone: </span>
              <span>{physicianAddress.telephone}</span>
            </div>
          </div>
        ) : null}

        {physicianAddress.fax ? (
          <div className="row mb-2">
            <div className="col">
              <span className="font-weight-bold textGray">Fax: </span>
              <span>{physicianAddress.fax}</span>
            </div>
          </div>
        ) : null}

        <div className="row mb-2">
          <div className="col d-flex flex-column">
            <span className="font-weight-bold textGray">Address: </span>
            {location.address && <div> {location.address}</div>}
            {location.address2 && <div> {location.address2}</div>}
            {location.city && (
              <div>
                {location.city}
                {location.state ? `, ${location.state}` : ''}
                {location.zip ? `, ${location.zip}` : ''}
              </div>
            )}
          </div>
        </div>
      </>
    )
  }

  const handleInsuranceContent = () => {
    if (isPayersLoading) return <div className="py-2">Loading ...</div>
    if (isOrderPayersEmpty) return <div className="mb-3">No Insurance Found</div>

    const primaryInsurance = orderPayers?.find((p) => p.position === 1)
    const secondaryInsurance = orderPayers?.find((p) => p.position === 2)
    const tertiaryInsurance = orderPayers?.find((p) => p.position === 3)

    return (
      <>
        {primaryInsurance && (
          <div className="row mb-2">
            <div className="col d-flex flex-column mb-3">
              <SectionHeader className="font-weight-bold mb-2 textGray">
                Primary Insurance
              </SectionHeader>
              <div className="mb-2">
                <span className="font-weight-bold textGray">Payer: </span>
                <span>{primaryInsurance.name}</span>
              </div>
              <div className="mb-2">
                <span className="font-weight-bold textGray">Member ID: </span>
                <span>{primaryInsurance.memberId}</span>
              </div>
            </div>
          </div>
        )}

        {secondaryInsurance && (
          <div className="row mb-2">
            <div className="col d-flex flex-column mb-3">
              <span className="font-weight-bold textGray mb-2">Secondary Insurance</span>
              <div className="mb-2">
                <span className="font-weight-bold textGray">Payer: </span>
                <span>{secondaryInsurance.name}</span>
              </div>
              <div className="mb-2">
                <span className="font-weight-bold textGray">Member ID: </span>
                <span>{secondaryInsurance.memberId}</span>
              </div>
            </div>
          </div>
        )}

        {tertiaryInsurance && (
          <div className="row mb-2">
            <div className="col d-flex flex-column mb-3">
              <span className="font-weight-bold textGray mb-2">Tertiary Insurance</span>
              <div className="mb-2">
                <span className="font-weight-bold textGray">Payer: </span>
                <span>{tertiaryInsurance.name}</span>
              </div>
              <div className="mb-2">
                <span className="font-weight-bold textGray">Member ID: </span>
                <span>{tertiaryInsurance.memberId}</span>
              </div>
            </div>
          </div>
        )}
      </>
    )
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row px-2 mb-3">
          <div className="col mr-3 px-4 d-flex flex-column border bg-white">
            <div className="row align-items-center">
              <div className="col my-2">
                <TitleLead className="my-2" label="Patient" />
              </div>
              <div className="col text-right">
                <Button
                  onClick={() => onEditSection('patient')}
                  className="my-2 btn btn-primary"
                  label="Update"
                />
              </div>
              <hr className="w-100 mt-0" />
            </div>

            {handlePatientContent()}

            {patient && (
              <div className="row mt-auto">
                <label className="col-sm-12 mb-3 py-1 border box_BiPAP d-flex align-items-center">
                  <input
                    type="checkbox"
                    name="isPatientCorrect"
                    value={reviewFieldValues.isPatientCorrect}
                    checked={reviewFieldValues.isPatientCorrect}
                    onChange={onReview}
                  />
                  <span className="font-weight-bold ml-2">
                    {generalHelper.t('I have reviewed, this is correct')}
                  </span>
                </label>
              </div>
            )}
          </div>

          <div className="col mr-3 px-4 d-flex flex-column border bg-white">
            <div className="row align-items-center">
              <div className="col my-2">
                <TitleLead className="my-2" label="Physician" />
              </div>
              <div className="col text-right">
                <Button
                  onClick={() => onEditSection('physician')}
                  className="my-2 btn btn-primary"
                  label="Update"
                />
              </div>
              <hr className="w-100 mt-0" />
            </div>

            {handlePhysicianContent()}

            {physician && (
              <div className="row mt-auto">
                <label className="col-sm-12 mb-3 py-1 border box_BiPAP d-flex align-items-center">
                  <input
                    type="checkbox"
                    name="isPhysicianCorrect"
                    value={reviewFieldValues.isPhysicianCorrect}
                    checked={reviewFieldValues.isPhysicianCorrect}
                    onChange={onReview}
                  />
                  <span className="font-weight-bold ml-2">
                    {generalHelper.t('I have reviewed, this is correct')}
                  </span>
                </label>
              </div>
            )}
          </div>

          <div className="col px-4 d-flex flex-column border bg-white">
            <div className="row align-items-center">
              <div className="col my-2">
                <TitleLead className="my-2" label="Insurance" />
              </div>
              <div className="col text-right">
                <Button
                  onClick={() => onEditSection('insurance')}
                  className="my-2 btn btn-primary"
                  label="Update"
                />
              </div>
              <hr className="w-100 mt-0" />
            </div>

            {handleInsuranceContent()}

            {!isOrderPayersEmpty && (
              <div className="row mt-auto">
                <label className="col-sm-12 mb-3 py-1 border box_BiPAP d-flex align-items-center">
                  <input
                    type="checkbox"
                    name="isInsuranceCorrect"
                    value={reviewFieldValues.isInsuranceCorrect}
                    checked={reviewFieldValues.isInsuranceCorrect}
                    onChange={onReview}
                  />
                  <span className="font-weight-bold ml-2">
                    {generalHelper.t('I have reviewed, this is correct')}
                  </span>
                </label>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default PatientInformationReview

import { useRef } from 'react'
import { Field } from 'formik'
import SignatureCanvas from 'react-signature-canvas'
import Button from '../../../atoms/Button'

const VerifyForm = ({ values, errors, touched, setFieldValue }) => {
  const sigRef = useRef<any>()

  const clearSignature = () => {
    sigRef.current.clear()
    setFieldValue('signature', '')
  }

  return (
    <>
      <div className="row">
        <div className="col divSize form-group">
          <label htmlFor="Email">Email Address</label>
          <Field type="text" name="email" className="form-control" />
          {touched.email && errors.email ? <div className="text-danger">{errors.email}</div> : null}
        </div>
      </div>
      <div className="row">
        <div className="col divSize form-group">
          <label className="d-block mb-2">Relationship to Patient</label>
          <div className="form-check">
            <Field
              className="form-check-input"
              type="radio"
              id="I am the patient"
              name="relationship"
              value="self"
            />
            <label className="form-check-label" htmlFor="I am the patient">
              I am the patient
            </label>
          </div>
          <div className="form-check">
            <Field
              className="form-check-input"
              type="radio"
              id="I am the patient's legal representative"
              name="relationship"
              value="legal_representative"
            />
            <label className="form-check-label" htmlFor="I am the patient's legal representative">
              I am the patient's legal representative
            </label>
          </div>
          <div className="form-check">
            <Field
              className="form-check-input"
              type="radio"
              id="I am the patient's parent or legal guardian"
              name="relationship"
              value="parent_or_guardian"
            />
            <label
              className="form-check-label"
              htmlFor="I am the patient's parent or legal guardian">
              I am the patient's parent or legal guardian
            </label>
          </div>
          {touched.relationship && errors.relationship ? (
            <div className="text-danger">{errors.relationship}</div>
          ) : null}
        </div>
      </div>
      {values.relationship && values.relationship !== 'self' && (
        <div className="row">
          <div className="col divSize form-group">
            <label htmlFor="Email">Guardian or Legal Representative Name</label>
            <Field type="text" name="signedBy" className="form-control" />
            {touched.signedBy && errors.signedBy ? (
              <div className="text-danger">{errors.signedBy}</div>
            ) : null}
          </div>
        </div>
      )}
      <div className="row">
        <div className="col form-group">
          <label htmlFor="signature">Sign the box below</label>
          <div className="sigHolder" style={{ display: 'unset' }}>
            <div className="divSize">
              <Field name="signature">
                {({ field }) => (
                  <SignatureCanvas
                    penColor="black"
                    canvasProps={{
                      width: 400,
                      height: 100,
                      className: 'sigCanvas',
                    }}
                    ref={sigRef}
                    onEnd={() => {
                      const imageData = sigRef.current.toDataURL('image/png')
                      setFieldValue('signature', imageData)
                    }}
                  />
                )}
              </Field>
              {touched.signature && errors.signature ? (
                <div className="text-danger">{errors.signature}</div>
              ) : null}
            </div>
            <div className="buttonHolder">
              <Button label="Clear Signature" onClick={clearSignature} className="btn-default" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default VerifyForm

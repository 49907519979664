import { TheAddress } from 'ts-back-end/dist/ts-back-end/src/common/types/the-address.dto'
import {
  CreatePatientDto,
  UpdatePatientDto,
} from 'ts-back-end/src/business/patient/dto/patient.dto'
import { OutputPayer as PayerDto } from 'ts-back-end/src/business/payer/dto/payer.dto'
import {
  PhysicianAddressJoined,
  PhysicianDto,
  PhysiciansGroupPhysician,
  UpdatePhysicianSettingsDto,
} from 'ts-back-end/src/business/physician/dto/physician.dto'
import {
  CreateOrderDto,
  OrderOutputDto,
  TestConditions,
  DeviceSession as DeviceSessionDto,
} from 'ts-back-end/src/business/order/dto/create-order.dto'
import { VerificationResultDto } from 'ts-back-end/src/business/order/dto/verify-order.dto'
import { MessageEntity } from 'ts-back-end/src/business/message/entity/message.entity'
import { AddMessageDto } from 'ts-back-end/src/business/message/types/messages'
import { NoteEntity } from 'ts-back-end/src/business/note/entity/note.entity'
import { AddNoteDto } from 'ts-back-end/src/business/note/types/notes'
import type { StoreOffice } from 'ts-back-end/src/business/office/dto/office.dto'

export type { TestCode, TestTypesList } from 'ts-back-end/src/common/testtypes/test-types.const'
export type { UploadType } from 'ts-back-end/src/shared/files/utils'
export type { PatientRelationship } from 'ts-back-end/src/business/order/dto/request-aob.dto'
export type { DeviceDeliveryScheduledDto } from 'ts-back-end/src/business/order/dto/device.dto'
export type {
  DeviceDto,
  AssignThirdPartyDeviceDto,
} from 'ts-back-end/src/business/device/dto/device.dto'
export type { DefineHstDeviceDto } from 'ts-back-end/src/business/device/dto/device.dto'
export type { BucketValue } from 'ts-back-end/src/business/order/state-machine/order-state.const'
export type {
  ProgressValues,
  StateExtended,
} from 'ts-back-end/src/business/order/queries/get-orders-list.query'
export type { IncomingFaxesEntry } from 'ts-back-end/src/business/incoming-fax/types/incoming-faxes.types'
export type { AttachFaxDto } from 'ts-back-end/src/business/incoming-fax/types/incoming-faxes.types'
export type {
  LabAssignThirdPartyDeviceDto,
  SendNightOwlWelcomeDto,
  SubmitToNightOwlDto,
  LabRequestDeliveryDto,
  LabManualShippingDto,
  HoldNightOwlOrderDto,
  RevertNightOwlOrder,
} from 'ts-back-end/src/business/night-owl/types/night-owl'
export type { RefundShippoTransactionDto } from 'ts-back-end/src/business/night-owl/types/shippo'
export type { OrderDisplayDmePayer } from 'ts-back-end/src/business/order/dto/assign-payer.dto'
export type { CompanyDto } from 'ts-back-end/src/business/company/dto/company.dto'
export type { CompaniesListQuery } from 'ts-back-end/src/business/company/dto/companies-list'
export type { CreateCompanyDto } from 'ts-back-end/src/business/company/dto/create-company.dto'
export type {
  CreateOfficeDto,
  UpdateOfficeDto,
  StoreOffice,
  OutputOffice,
} from 'ts-back-end/src/business/office/dto/office.dto'
export type {} from 'packages/ts-back-end/src/business/office/dto/storeOffice'
export type { CreateUserDto, MeUserDto } from 'ts-back-end/src/business/user/dto/create-user.dto'
export type { UsersListQuery } from 'ts-back-end/src/business/user/dto/users-list'
export type { HstPatientPaperworkDto } from 'ts-back-end/src/business/order/dto/hst-patient-paperwork.dto'
export type { ProvidePaymentAuthorizationDto } from 'ts-back-end/src/business/order/dto/payment-authorization'
export type { DeviceBrandsList } from 'ts-back-end/src/business/device/dto/device-brands-list'
export type {
  DevicesList,
  DevicesListQuery,
  DeviceListItem,
} from 'ts-back-end/src/business/device/dto/device-list.dto'
export type { CreateDeviceDto } from 'ts-back-end/src/business/device/dto/device.dto'
export type {
  TimestampOverride,
  SelectedDeviceSessionsDto,
} from 'ts-back-end/src/business/order/dto/create-order.dto'
export type {
  CreateTicketDto,
  TicketDto,
  FreshDeskTicket,
  FreshDeskTicketsList,
} from 'ts-back-end/src/business/ticket/dto/create-ticket.dto'
export type { ListResult as TicketsList } from 'ts-back-end/src/business/ticket/dto/list-tickets.dto'

export type {
  CreateBulletinDto,
  BulletinDto,
  UpdateBulletinDto,
} from 'ts-back-end/src/business/bulletin/dto/bulletin.dto'
export interface CreatePatient extends Omit<CreatePatientDto, 'dob'> {
  dob: string
}
export type Patient = UpdatePatientDto & {
  id: string
}

export type CreatePhysicianUserDto = {
  firstName: string
  lastName: string
  email: string
  physicianGroup: number
}

export type PhysicianOrders = { id: number; fullName: string; amount: number }

export type PhysicianOrderCounts = {
  total: PhysicianOrders | null
  month: PhysicianOrders | null
  lastMonth: PhysicianOrders | null
  year: PhysicianOrders | null
  completed: PhysicianOrders | null
}

export type PhysicianGroupDto = {
  id: number
  groupName: string
  telephone: string
  fax: string
  npi?: string
  physicians?: PhysiciansGroupPhysician[]
} & TheAddress

export interface Physician extends PhysicianDto {}
export interface PhysicianSettings extends UpdatePhysicianSettingsDto {}

export type PhysicianAddress = PhysicianAddressJoined

export interface Payer extends PayerDto {}

export interface CreateOrder extends CreateOrderDto {}

export interface IOrderTest extends TestConditions {}
export interface Order extends OrderOutputDto {}

export type DeviceSession = DeviceSessionDto & { originalIdx: number }

// just temporary
export type KeyVal = {
  [key: string]: string | Date | boolean | any
}

export type IdAndParam = {
  id: number | string
  params: KeyVal
}
export interface Company {}

export interface Office extends StoreOffice {}

export type Offices = Office[]

export type VerificationResult = VerificationResultDto

export enum VerificationSection {
  RX = 'RX',
  AOB = 'AOB',
  OFFICE_NOTES = 'OFFICE_NOTES',
  PATIENT = 'PATIENT',
  PAYER = 'PAYER',
  PAYER_2 = 'PAYER_2',
  PAYER_3 = 'PAYER_3',
  PHYSICIAN = 'PHYSICIAN',
}

export interface Message extends MessageEntity {
  userFirstName: string
  userLastName: string
  userEmail: string
}
export interface AddMessage extends AddMessageDto {}

export interface Note extends NoteEntity {
  userFirstName: string
  userLastName: string
  userEmail: string
}
export interface AddNote extends AddNoteDto {}

export enum UserType {
  LAB = 'LAB',
  DME = 'DME',
  PHYSICIAN_GROUP = 'PHYSICIAN_GROUP',
  SYSTEM = 'SYSTEM',
  ADMIN = 'ADMIN',
  PATIENT = 'PATIENT',
}

export enum HstDeviceKind {
  NIGHT_OWL = 'NIGHT_OWL',
  DREAM_CLEAR = 'DREAM_CLEAR',
  HST = 'HST',
}

export enum DreamclearStatus {
  S001 = 'Order Created',
  S002 = 'Device Shipped',
  S003 = 'Device Delivered',
  S004 = 'Test Data uploaded in Dreamclear for Night 1',
  S005 = 'Test Data uploaded in Dreamclear for Night 2',
  S006 = 'Test Not Completed for Night 1',
  S007 = 'Test Not Completed for Night 2',
  S008 = 'Data uploaded to Ensodata from DreamClear for Night 1',
  S009 = 'Data uploaded to Ensodata for Night 2',
  S010 = 'Scoring Completed for Night 1',
  S011 = 'Scoring Completed for Night 2',
  S012 = 'Scoring Completed but could not score one of the nights',
  S013 = 'Reports Downloaded to Dreamclear from Ensodata',
  S014 = 'Interpretation Signed in Dreamclear',
  S015 = 'Signed Report Transferred',
}

export enum DreamclearStatusCodes {
  S001 = 'S001',
  S002 = 'S002',
  S003 = 'S003',
  S004 = 'S004',
  S005 = 'S005',
  S006 = 'S006',
  S007 = 'S007',
  S008 = 'S008',
  S009 = 'S009',
  S010 = 'S010',
  S011 = 'S011',
  S012 = 'S012',
  S013 = 'S013',
  S014 = 'S014',
  S015 = 'S015',
}

export enum FollowUpNamespace {
  BILLING = 'BILLING',
  HST_PATIENT_CONSENT = 'HST_PATIENT_CONSENT',
  HST_PAPERWORK = 'HST_PAPERWORK',
  HST_PAYMENT = 'HST_PAYMENT',
  AUTHORIZATION = 'AUTHORIZATION',
  DEVICE = 'DEVICE',
  HST_DEVICE = 'HST_DEVICE',
}

import { useState } from 'react'
import { useParams, Navigate } from 'react-router-dom'
import { useAppDispatch } from 'hooks/redux'
import {
  insertOrderTest,
  updateOrderTest,
} from '../../../store/slice/orderTestSlice'
import { handleSubmit } from '../../atoms/Upsert'
import { deleteOrderTest } from '../../../store/slice/orderTestSlice'
import { Formik, Form } from 'formik'
import OrderForm from '../forms/OrderForm'
import validationSchema from '../forms/schemas/orderValidationSchema'
import { formatOrderTestPayload } from '../forms/utils/orderFormUtils'
import routes from '../constants/routes'

interface IProps {
  order: any
  orderTests: any[]
  deviceCategories: any[]
}

const TestConditionUpsert = ({
  order,
  orderTests,
  deviceCategories,
}: IProps) => {
  const dispatch = useAppDispatch()
  const [redirect, setRedirect] = useState()
  const { order_id, orderTest_id } = useParams()

  const handleDelete = () => {
    dispatch(deleteOrderTest({ id: orderTest_id })).then(res => {
      setRedirect(`${routes.index}${routes.order.test}${order?.id}`)
    })
  }

  let selectedTest: any
  let initialValues = {
    order: false,
    device_category_id: `${order?.device_category_id}` ?? '',
    oxygen: '',
    oxygen_lpm: '',
    pap: '',
    pap_type: '',
    apap_min: '',
    apap_max: '',
    bipap_i: '',
    bipap_e: '',
    cpap: '',
    non_invasive: false,
    adaptive_support: false,
    oral_appliace: false,
  }

  if (orderTest_id && orderTests && orderTests.length) {
    selectedTest = orderTests.find(ot => `${ot?.id}` === orderTest_id)
    initialValues = {
      order: false,
      device_category_id: `${order.device_category_id}` ?? '',
      oxygen: selectedTest.oxygen ?? '',
      oxygen_lpm: selectedTest.oxygen_lpm ?? '',
      pap: selectedTest.pap ?? '',
      pap_type: selectedTest.pap_type ?? '',
      apap_min: selectedTest.apap_min ?? '',
      apap_max: selectedTest.apap_max ?? '',
      bipap_i: selectedTest.bipap_i ?? '',
      bipap_e: selectedTest.bipap_e ?? '',
      cpap: selectedTest.cpap ?? '',
      non_invasive: selectedTest.non_invasive == 1 ? true : false,
      adaptive_support: selectedTest.adaptive_support == 1 ? true : false,
      oral_appliace: selectedTest.oral_appliace == 1 ? true : false,
    }
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={values => {
        values.order_id = order_id
        handleSubmit(event, {
          id: orderTest_id,
          values: formatOrderTestPayload(false, values),
          functions: {
            insert: insertOrderTest,
            update: updateOrderTest,
          },
          callback: {
            function: setRedirect,
            params: `${routes.index}${routes.order.test}${order_id}`,
          },
          dispatch: dispatch,
        })
      }}
    >
      {({ values, errors, touched }) => (
        <>
          {redirect && <Navigate to={redirect} />}
          <Form>
            <OrderForm
              values={values}
              errors={errors}
              touched={touched}
              isOrder={false}
              order={order}
              orderTest={selectedTest}
              deviceCategories={deviceCategories}
              handleDelete={handleDelete}
            />
          </Form>
        </>
      )}
    </Formik>
  )
}

export default TestConditionUpsert

import { baseApi } from './baseApi'
import { Order, Patient, HstPatientPaperworkDto } from 'store/types'
import { SignAobRequest } from 'store/services/dmeOrder'

export const patientApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getPatientOrder: builder.query<Order, void>({
      query: () => ({ url: `/patient/order` }),
    }),
    signPatientAob: builder.mutation<Order, SignAobRequest>({
      query: (params) => ({
        method: `PUT`,
        url: `/patient/aob-signature`,
        body: params,
      }),
      invalidatesTags: ['Order'],
    }),
    startPatientPaperwork: builder.mutation<Patient, number>({
      query: () => ({
        method: `POST`,
        url: `/patient/hst/start-paperwork`,
      }),
      invalidatesTags: ['Order'],
    }),
    savePatientPaperwork: builder.mutation<void, Partial<HstPatientPaperworkDto>>({
      query: (data) => ({
        method: `PATCH`,
        url: `/patient/hst/save-paperwork`,
        body: data,
      }),
      invalidatesTags: ['Order'],
    }),
    finishPatientPaperwork: builder.mutation<void, { orderId: number; signature: string }>({
      query: ({ orderId, signature }) => ({
        method: `POST`,
        url: `/patient/hst/finish-paperwork`,
        body: { orderId, signature },
      }),
      invalidatesTags: ['Order'],
    }),
    createPatientCheckout: builder.mutation<Order, { orderId: number; amount: number }>({
      query: (params) => ({
        method: `POST`,
        url: `/patient/checkout`,
        body: params,
      }),
      invalidatesTags: ['Order'],
    }),
  }),
})

export const {
  useGetPatientOrderQuery,
  useStartPatientPaperworkMutation,
  useSavePatientPaperworkMutation,
  useFinishPatientPaperworkMutation,
  useCreatePatientCheckoutMutation,
  useLazyGetPatientOrderQuery,
  useSignPatientAobMutation,
} = patientApi

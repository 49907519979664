import { useState } from 'react'
import { useAppSelector, useAppDispatch } from 'hooks/redux'
import Autocomplete from '../../../atoms/Autocomplete'
import { getUsers } from '../../../../store/slice/userSlice'
import { setLoader } from '../../../../store/slice/loaderSlice'
import moment from 'moment'

interface IProps {
  id: string
  name: string
  onChange: () => void
  errors: { [key: string]: string }
}

const PatientUpsert = (props: IProps) => {
  const patients_limit = 12
  const [isLoading, setIsLoading] = useState(false)
  const patients = useAppSelector(state => state.user.rows)
  const dispatch = useAppDispatch()

  const getData = async (inputValue: string): Promise<any[]> => {
    if (inputValue && inputValue.length > 1) {
      setIsLoading(true)
      dispatch(setLoader(true))
      dispatch(
        getUsers({
          search: inputValue,
          _where_preset: 'patient',
          limit: patients_limit,
        })
      ).then(() => {
        setIsLoading(false)
        dispatch(setLoader(false))
      })
    }

    return patients
  }

  return (
    <>
      <Autocomplete
        allowNew={true}
        id={props.id}
        isLoading={isLoading}
        labelKey={option =>
          `${option.first_name} ${option.last_name} - ${
            moment(option?.dob).format('MM/DD/YYYY') ?? ''
          }`
        }
        onChange={props.onChange}
        onSearch={getData}
        options={patients}
        newLabel="Create new patient: "
        placeholder={'Search for patients'}
      />
      {props.errors[props.name] ? (
        <div className="text-danger">{props.errors[props.name]}</div>
      ) : null}
    </>
  )
}

export default PatientUpsert

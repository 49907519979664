/** @jsxImportSource @emotion/react */
import { useRef, useMemo, useEffect } from 'react'
import { Formik, Form, Field, FormikProps } from 'formik'
import * as Yup from 'yup'

import Modal from 'components/atoms/Modal'
import { TextInput } from 'components/atoms/Input'
import ButtonSave from 'components/atoms/ButtonSave'
import ButtonCancelModal from 'components/atoms/ButtonCancelModal'
import { setModal } from 'store/slice/modalSlice'
import { useAppSelector, useAppDispatch } from 'hooks/redux'
import { requiredPhoneValidation } from 'helpers/validate'
import { PhysicianForm, PhysicianFormFields } from './PhysicianForm'

interface IProps {
  id: string
  modalSize: string
}

const physicianValidation = Yup.object().shape({
  fax: requiredPhoneValidation,
  telephone: requiredPhoneValidation,
  addressStreetLine1: Yup.string().required('Required'),
  addressCity: Yup.string().required('Required'),
  addressState: Yup.string().required('Required'),
  addressZip: Yup.string().required('Required'),
})

const ModifyPhysicianModal = (props: IProps) => {
  const modal = useAppSelector((state) => state.modal.row)
  const dispatch = useAppDispatch()
  const cancelRef = useRef()
  const formRef = useRef<FormikProps<any>>(null)

  const {
    setPhysician,
    physician,
    indexLocation: addressIdx,
    physicianAddress,
    open,
    preValidate,
  } = modal
  const location = physicianAddress ?? physician?.addresses?.[addressIdx] ?? {}

  const verified = location?.status === 'approved'
  const imported = location?.status === 'imported' || location?.status === 'edited'

  const initialValues: PhysicianFormFields = useMemo(
    () => ({
      fax: location?.fax?.replace(/^\+1/, '') ?? '',
      telephone: location?.telephone?.replace(/^\+1/, '') ?? '',
      addressStreetLine1: location?.addressStreetLine1 ?? '',
      addressStreetLine2: location?.addressStreetLine2 ?? '',
      addressCity: location?.addressCity ?? '',
      addressState: location?.addressState ?? '',
      addressZip: location?.addressZip ?? '',
      uuid: location?.uuid ?? '',
      credential: physician?.credential ?? '',
    }),
    [location],
  )

  useEffect(() => {
    if (preValidate && formRef.current) {
      formRef.current.validateForm()
    }
  }, [preValidate, open])

  const onSubmit = async ({ credential, ...address }: PhysicianFormFields) => {
    cancelRef.current?.click()
    await setPhysician(
      { ...physician, credential },
      {
        ...address,
        addressPurpose: location?.addressPurpose ?? 'LOCATION',
      },
    )
  }

  return (
    <>
      <Modal
        {...props}
        title={`${!!physicianAddress ? 'EDIT' : 'ADD'} PHYSICIAN ADDRESS`}
        content={
          open ? (
            <Formik
              enableReinitialize
              innerRef={formRef}
              validateOnMount={preValidate}
              validationSchema={physicianValidation}
              initialValues={initialValues}
              initialTouched={preValidate && { fax: true, telephone: true }}
              onSubmit={onSubmit}>
              {({ isValid }) => (
                <Form>
                  <div className="container p-3">
                    <div className="d-flex w-100 mb-3 pb-3 border-bottom">
                      <div className="col-sm-1 pt-2 d-flex align-items-center">
                        {verified ? (
                          <i className="text-success fa fa-user-check" />
                        ) : imported ? (
                          <i className="text-success fa fa-user-md" />
                        ) : (
                          <i className="text-default fa fa-user-md" />
                        )}
                      </div>
                      <div className="col-sm-3 pt-2  d-flex align-items-center">
                        {physician?.npi}
                      </div>
                      <div className="col-sm-5 pt-2  d-flex align-items-center">{`${physician?.firstName} ${physician?.lastName}`}</div>
                      <div className="col-sm-1 pt-2 form-group">
                        <label htmlFor="suffix" className="textGray">
                          Suffix
                        </label>
                        <Field
                          component={TextInput}
                          label={false}
                          disabled
                          placeholder="Suffix"
                          name="credential"
                          className="form-control"
                          css={{ minWidth: '100px' }}
                        />
                      </div>
                    </div>
                    <PhysicianForm />

                    <div className="modal-footer">
                      <ButtonCancelModal
                        ref={cancelRef}
                        type="button"
                        data_dismiss="modal"
                        onClick={() => dispatch(setModal({}))}
                      />
                      <ButtonSave type="submit" label="Confirm" disabled={!isValid} />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          ) : null
        }
      />
    </>
  )
}

export default ModifyPhysicianModal

/** @jsxImportSource @emotion/react */
import { Field } from 'formik'

import { data, notification } from './notificationsData'
import { CheckboxInput } from 'components/atoms/Checkbox'

export const NotificationPreferences = () => {
  const rows: JSX.Element[] = []

  Object.keys(data).forEach((key: string): void => {
    rows.push(
      <tr>
        <td colSpan={4} css={{ fontWeight: 'bold' }} className="text-gray py-2">
          {key.slice(0, 1).toUpperCase() + key.slice(1)}
        </td>
      </tr>,
    )
    data[key].forEach((n: notification) => {
      rows.push(
        <tr>
          <td css={{ textAlign: 'center', paddingTop: '5px' }}>
            <Field
              id={`EMAIL_${n.flag}`}
              name={`EMAIL_${n.flag}`}
              component={CheckboxInput}
              label=""
            />
          </td>
          {/*
          <td css={{ textAlign: 'center', paddingTop: '5px' }}>
            <Field id={`SMS_${n.flag}`} name={`SMS_${n.flag}`} component={CheckboxInput} label="" />
          </td>
          }*/}
          <td>{n.label}</td>
          <td>{n.description}</td>
        </tr>,
      )
    })
  })

  return (
    <div className="card-body row" css={{ paddingTop: 0 }}>
      <div className="col-md-12 mb-3">
        <div className="card card-primary p-2">
          <div
            className="card-header"
            css={{
              color: 'black !important',
              backgroundColor: 'transparent !important',
            }}>
            <h3 className="card-title">NOTIFICATION PREFERENCES</h3>
          </div>
          <div className="card-body">
            <div className="row mb-3">
              <div className="col">
                <table css={{ borderCollapse: 'collapse' }}>
                  <thead css={{ borderBottom: '1px solid rgb(206, 212, 218)' }}>
                    <tr>
                      <th css={{ width: '80px' }} className="text-gray">
                        Email
                      </th>
                      {/*
                      <th css={{ width: '80px' }} className="text-gray">
                        SMS
                      </th>
                      */}
                      <th css={{ width: '200px' }} className="text-gray">
                        Notification
                      </th>
                      <th css={{ width: 'auto' }} className="text-gray">
                        Description
                      </th>
                    </tr>
                  </thead>
                  <tbody>{rows}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Navigate, useParams } from 'react-router-dom'
import Modal from '../../../atoms/Modal'
import { setModal } from '../../../../store/slice/modalSlice'
import ButtonCancelModalComponent from '../../../atoms/ButtonCancelModal'
import * as generalHelper from '../../../../helpers/generalHelper'
import { handleChange, handleSubmit } from '../../../atoms/Upsert'
import InputComponent from '../../../atoms/Input'
import ButtonSave from '../../../atoms/ButtonSave'
import Select from '../../../atoms/Select'
import suffixes from '../../constants/suffixes'
import InputMask from '../../../atoms/InputMask'
import Radio from '../../../atoms/Radio'
import routes from '../../constants/routes'
import { insertPatient } from '../../../../store/slice/patientSlice'
import moment from 'moment'

const AddPatientModal = (props) => {
  const dispatch = useDispatch()
  const [redirect, setRedirect] = React.useState(false)
  const [patient, setPatient] = React.useState({})

  const insertNewPatient = (patient) => {
    const payload = {
      values: patient,
      functions: {
        insert: insertPatient
      },
      callback: {
        function: setRedirect,
        params:
          location.pathname.indexOf('newOrder') > -1
            ? `${routes.index}${routes.order.newOrder}/`
            : `${routes.index}${routes.patient.edit}`,
        params_add: 'id'
      },
      dispatch: dispatch
    }
    return payload
  }

  const temporarySuffixes = [
    { name: 'Jr.' },
    { name: 'Sr.' },
    { name: 'I' },
    { name: 'II' },
    { name: 'III' },
    { name: 'IV' },
    { name: 'V' },
    { name: 'VI' },
    { name: 'VII' },
    { name: 'VIII' },
    { name: 'IX' },
    { name: 'X' }
  ]

  return (
    <Modal
      {...props}
      content={
        <div className='container'>
          {redirect && <Navigate to={redirect} />}
          <div className='modal-body'>
            <div className='row pb-4'>
              <div className='col ml-auto text-right text-bold'>
                <span className='text-danger'>*</span> denotes a required field
              </div>
            </div>
            <div className='row'>
              <div className='col form-group'>
                <label htmlFor='first_name'>
                  {generalHelper.t('First Name')}
                  <span className='text-danger'>*</span>
                </label>
                <InputComponent
                  value={patient.first_name}
                  label={false}
                  placeholder=''
                  className='form-control'
                  name='first_name'
                  handleChange={(event) => {
                    handleChange(event, patient, setPatient)
                  }}
                />
              </div>
              <div className='col form-group'>
                <label htmlFor='last_name'>{generalHelper.t('Middle Name / Initial')}</label>
                <InputComponent
                  value={patient.middle_name}
                  label={false}
                  placeholder=''
                  className='form-control'
                  name='middle_name'
                  handleChange={(event) => {
                    handleChange(event, patient, setPatient)
                  }}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col form-group'>
                <label htmlFor='last_name'>
                  {generalHelper.t('Last Name')}
                  <span className='text-danger'>*</span>
                </label>
                <InputComponent
                  value={patient.last_name}
                  label={false}
                  placeholder=''
                  className='form-control'
                  name='last_name'
                  handleChange={(event) => {
                    handleChange(event, patient, setPatient)
                  }}
                />
              </div>
              <div className='col form-group'>
                <label htmlFor='suffix'>{generalHelper.t('Suffix')}</label>
                <Select
                  options={temporarySuffixes}
                  value={patient.suffix}
                  defaultLabel={generalHelper.t('Select a Suffix')}
                  name='suffix'
                  handleChange={(event) => {
                    handleChange(event, patient, setPatient)
                  }}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col form-group'>
                <label htmlFor='dob'>
                  {generalHelper.t('Date Of Birth (MM/DD/YYYY)')}
                  <span className='text-danger'>*</span>
                </label>
                <InputMask
                  value={patient.dob ?? ' '}
                  className='form-control'
                  name='dob'
                  onChange={(event) => {
                    handleChange(event, patient, setPatient)
                  }}
                  mask='99/99/9999'
                />
              </div>
              <div className='col form-group'>
                <div className='row'>
                  <div className='col-12 font-weight-bold pb-2'>
                    <label htmlFor='gender'>{generalHelper.t('Gender')}</label>
                  </div>
                  <div className='col-sm-1'></div>
                  <div className='col-sm-4'>
                    <Radio
                      name="gender"
                      value="F"
                      className="form-check-input"
                      checkedvalue={patient.gender}
                      onChange={(event) => {
                        setPatient({...patient, gender: "F"})
                      }}
                    />
                    <label className='form-check-label'>{generalHelper.t('Female')}</label>
                  </div>
                  <div className='col-sm-7'>
                    <Radio
                      name="gender"
                      value="M"
                      className="form-check-input"
                      checkedvalue={patient.gender}
                      onChange={(event) => {
                        setPatient({...patient, gender: "M"})
                      }}
                    />
                    <label className='form-check-label'>{generalHelper.t('Male')}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='modal-footer'>
            <ButtonCancelModalComponent
              type='button'
              data_dismiss='modal'
              onClick={(e) => {
                dispatch(setModal({}))
              }}
            />
            <ButtonSave
              type='button'
              label='Add Patient'
              data-dismiss='modal'
              disabled={
                (!patient && !patient.first_name) ||
                !patient.last_name ||
                !patient.dob ||
                !moment(patient?.dob, 'MM/DD/YYYY', true).isValid()
              }
              onClick={(event) => {
                handleSubmit(event, insertNewPatient(patient))
                dispatch(setModal({}))
              }}
            />
          </div>
        </div>
      }
    />
  )
}

export default AddPatientModal

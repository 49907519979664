import createSocket from './socket'
import createEmitter from './emitter'
import { config } from './config'
import { isObject } from './utils'
import { filterSpO2Devices } from './devices'

export type DeviceListData = {
  manufacturer: string
  path: string
  product?: string
  productId: number | string
  vendorId: number | string
  serialNumber?: string
}

type Options = {
  autoConnect: boolean
  minVersion: string
}

export type Event = {
  event: string
  data: string
}

type SendRequestData = {
  type?: string
  path?: string
  connectionType?: string
  authToken?: string
  orderId?: string
  uploadEndpoint?: string
  url?: string
  key?: string
}
type ReaderRequestData = {
  deviceType?: string
  connectionPath?: string
  connectionType?: string
  authToken?: string
  orderId?: string
  uploadEndpoint?: string
}

type ClientInfo = {
  clientVersion: string
  userId: string
  username: string
}

type AppVersionPayload = {
  message: 'app:info'
  data: {
    appVersion: string
    ldrVersion: string
    uuid: string
  }
}
type ConnectionListPayload = {
  message: 'connection:list'
  data: {
    hid: DeviceListData[]
    serial: DeviceListData[]
  }
}
type ErrorPayload = {
  message: 'device:error' | 'device:read:error' | 'device:reset:error' | 'upload:error'
  data: {
    message: string
  }
}

type NonDataPayload = {
  message:
    | 'app:error'
    | 'device:read:completed'
    | 'device:reset:completed'
    | 'device:timeout'
    | 'socket:error'
    | 'upload:accepted'
    | 'upload:rejected'
    | 'upLoad:rejected'
}
type ProgressPayload = {
  message: 'device:read:progress' | 'device:reset:progress' | 'upload:progress'
  data: number
}

type MessageEventPayload =
  | AppVersionPayload
  | ConnectionListPayload
  | NonDataPayload
  | ProgressPayload
  | ErrorPayload

export type ReaderRequest = {
  message: string
  data?: ReaderRequestData
}

export const createReader = (url: string, options: Options) => {
  if (isObject(url)) {
    options = url
    url = null
  }

  const {
    minVersion,
    url: defaultUrl,
    ...opt
  } = {
    ...config,
    ...(options || {}),
  }

  url = url || defaultUrl

  const emitter = createEmitter()
  const socket = createSocket(url, opt)

  const onConnect = (event: Event) => {
    emitter.emit('connect', event)
    getAppInfo()
  }

  const onReconnect = (event: Event) => {
    emitter.emit('reconnect', event)
  }

  const onDisconnect = (event: Event) => {
    emitter.emit('disconnect', event)
  }

  const onEvent = (event: Event) => {
    if (event.event) {
      emitter.emit(event.event, event)
    }

    emitter.emit('event', event)
  }

  const onMessage = (payload: MessageEventPayload) => {
    switch (payload.message) {
      case 'app:error': {
        const errorMsg = `TestSmarter Reader has crashed. Please restart the app and try again.`
        console.log(errorMsg)
        emitter.emit('error', errorMsg)
        break
      }
      case 'app:info': {
        const { appVersion, ldrVersion, uuid } = payload.data
        console.log(
          `App version: ${appVersion}, Ldr version: ${ldrVersion}, Installation ID: ${uuid}`,
        )
        emitter.emit('appInfo', { appVersion, ldrVersion, uuid })
        break
      }
      case 'connection:list': {
        const { hid, serial } = payload.data
        const pulseOxDevices = filterSpO2Devices({ hid, serial })
        emitter.emit('deviceList', pulseOxDevices)
        break
      }
      case 'device:read:progress': {
        emitter.emit('deviceReadProgress', { data: payload.data })
        break
      }
      case 'upload:progress': {
        emitter.emit('uploadProgress', { data: payload.data })
        break
      }
      case 'socket:error': {
        socket.close()
        console.log('socket error', payload.data)
        emitter.emit('socketError', payload.data.message)
        break
      }
      case 'upload:error':
        socket.close()
        console.log('upload error', payload.data)
        emitter.emit('uploadError', payload.data.message)
        break
      case 'device:error':
      case 'device:read:error': {
        socket.close()
        console.log('device error', payload.data)
        emitter.emit('error', payload.data.message)
        break
      }
      // Seems that right now our server is not responding in the right way, so it
      // reports an upload error
      case 'upload:rejected': {
        emitter.emit('uploadRejected', payload.data.message)
        break
      }
      case 'upload:accepted': {
        emitter.emit('uploadCompleted')
        break
      }
      case 'device:reset:progress': {
        emitter.emit('deviceResetProgress', { data: payload.data })
        break
      }
      case 'device:reset:completed': {
        emitter.emit('deviceResetCompleted')
        socket.close()
        break
      }
      case 'device:reset:error': {
        emitter.emit('resetError', payload.data.message)
        socket.close()
        break
      }
      default:
        console.log('Unhandled reader message type', payload)
    }
  }

  const onError = (event: Event) => {
    if (event instanceof Error) {
      event.error = event
    }

    const error = event?.error
      ? isObject(event.error)
        ? event.error
        : new Error(event.error)
      : new Error('Unknown error')

    emitter.emit('error', error)
  }

  const send = (message: string, { type, path, ...data }: SendRequestData = {}) => {
    const command: ReaderRequest = { data, message }
    if (type) command.data.deviceType = type
    if (path) command.data.connectionPath = path

    console.log('SENDING COMMAND', command)
    socket.send(command)
  }

  const getAppInfo = () => {
    send('app:info')
  }

  const sendClientInfo = ({ clientVersion, userId, username }: ClientInfo) => {
    send('app:info', { clientVersion, userId, username })
  }

  const getConnectionList = () => {
    send('connection:list')
  }

  // For testing only, will be hard coded in Prod
  const setUploadEndpoint = (url: string) => {
    send('app:set_upload_endpoint_url', { key: 'sFgP7YbCVgjiUCdBUjv35yDBqo1u7UwF', url })
  }

  const connect = () => {
    console.log('Connecting Websocket')
    socket.open()
  }

  const disconnect = () => {
    socket.close()
    // setTimeout(() => emitter.removeAllListeners(), 1000)
  }

  socket.on('event', onEvent)
  socket.on('error', onError)
  socket.on('open', onConnect)
  socket.on('reconnect', onReconnect)
  socket.on('disconnect', onDisconnect)
  socket.on('connectionLost', onDisconnect)
  socket.on('message', onMessage)

  return Object.assign(emitter, {
    minVersion,
    send,
    connect,
    disconnect,
    getAppInfo,
    sendClientInfo,
    getConnectionList,
    setUploadEndpoint,
  })
}

import styled from '@emotion/styled'
import { CheckboxInput } from 'components/atoms/Checkbox'
import { FormikDirtyStateSync } from 'components/atoms/FormikDirtyStateSync'
import MaskInput from 'components/atoms/MaskInput'
import TitleLead from 'components/atoms/TitleLead'
import { Field, Form, Formik } from 'formik'

import { phoneNumberMask } from 'helpers/masks'
import { requiredPhoneValidation } from 'helpers/validate'
import { toast } from 'react-toastify'
import { DefineHstDeviceDto, HstDeviceKind, Order } from 'store/types'
import * as Yup from 'yup'
import { DeviceType } from '../HstDevice.tab'

const InputWrapper = styled.div`
  input {
    max-width: 250px;
  }
`

const formValidation = Yup.object().shape({
  mobilePhone: requiredPhoneValidation,
  email: Yup.string().required('Required').email('Invalid Email'),
  hasSmartPhone: Yup.bool().oneOf([true], 'Must be checked'),
  smsConsent: Yup.bool().oneOf([true], 'Must be checked'),
})

interface IProps {
  order: Order
  updateDevice: (params: DefineHstDeviceDto) => void
  onClose?: () => void
  setFormDirty: (isDirty: boolean) => void
  deviceType: DeviceType
}

export const TraditionalDeviceForm = ({ order, updateDevice, onClose, setFormDirty, deviceType }: IProps) => {
  console.log(deviceType, onClose)
  return (
    <div className="row align-items-center px-4">
      <Formik
        enableReinitialize={true}
        validationSchema={formValidation}
        initialValues={{
          email: order?.patient?.email,
          mobilePhone: order?.patient?.mobilePhone?.replace(/^\+1/, ''),
          hasSmartPhone: !!order?.thirdPartyDevice || false,
          smsConsent: !!order?.thirdPartyDevice || false,
        }}
        onSubmit={async (values) => {
          console.log(values)
          try {
            await updateDevice({
              orderId: Number(order?.id),
              mobilePhone: values.mobilePhone ? `+1${values.mobilePhone.replace(/\D/g, '')}` : '',
              email: values.email,
              deviceType: deviceType === 'dreamclear' ? HstDeviceKind.DREAM_CLEAR : HstDeviceKind.HST,
            }).unwrap()
            console.log(onClose)
            if (onClose) {
              console.log('helloo')
              onClose()
            }
          } catch (err) {
            toast.error(err?.data?.message ?? 'Unable to submit with the provided data.')
          }
        }}>
        {({ values, errors, touched, handleChange, setFieldValue, dirty }) => (
          <Form id={deviceType} className="container-fluid">
            <FormikDirtyStateSync isDirty={dirty} setFormDirty={setFormDirty} />
            <TitleLead className="my-2 text-bold mr-5" label="Patient" />
            <div className="row">
              <InputWrapper className="col-5 form-group textGray">
                <label htmlFor="mobilePhone">Smart Mobile Phone Number</label>
                <span className="text-danger ml-2">(required)</span>
                <MaskInput
                  value={values.mobilePhone}
                  onChange={handleChange}
                  name="mobilePhone"
                  mask={phoneNumberMask}
                  placeholder="(###) ###-####"
                />
                {touched.mobilePhone && errors.mobilePhone ? (
                  <div className="text-danger">{errors.mobilePhone}</div>
                ) : null}
              </InputWrapper>
              <InputWrapper className="col-5 form-group textGray">
                <label htmlFor="mobilePhone">E-mail</label>
                <span className="text-danger ml-2">(required)</span>
                <Field
                  type="text"
                  name="email"
                  className="form-control"
                  placeholder="Email"
                  value={values.email}
                />
                {touched.email && errors.email ? (
                  <div className="text-danger">{errors.email}</div>
                ) : null}
              </InputWrapper>
            </div>
            <Field
              id="hasSmartPhone"
              name="hasSmartPhone"
              component={CheckboxInput}
              label="I have confirmed the patient has a smart phone."
              parentClassName={`custom-control my-2 ${errors.hasSmartPhone && 'text-danger'}`}
            />
            <Field
              id="smsConsent"
              name="smsConsent"
              component={CheckboxInput}
              label="Patient has consented to receive text messages from TestSmarter."
              parentClassName={`custom-control my-2 ${errors.hasSmartPhone && 'text-danger'}`}
            />
          </Form>
        )}
      </Formik>
    </div>
  )
}

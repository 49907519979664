const Success = ({ pdf }) => {
  return (
    <div>
      <h3 className="text-success">AOB complete, thank you!</h3>
      <p>
        Your electronic signature was accepted and the Assignment of Benefits
        form is now complete. You may now close this browser window.
      </p>
      <p>
        <a href={pdf} target="_blank" rel="noreferrer">
          Download a copy for your records
        </a>
      </p>
      <p>
        <a href="https://www.testsmarter.net/" className="btn btn-primary">
          Close this and go to our website
        </a>
      </p>
    </div>
  )
}

export default Success

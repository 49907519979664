const Nonin8500 = () => {
  return (
    <ul className="text-left">
      <li>
        <span>1.</span> Be sure the Nonin 8500 cable is connected securely to
        your computer.
      </li>
      <li className="highlight">
        <span>2.</span> <small>IMPORTANT:</small> Press the button below to
        begin download <strong>before</strong> powering on the device.
      </li>
      <li>
        <span>3.</span> Press <strong>both</strong> the &#x2503; and &#x2B06;
        buttons on the oximeter at the same time until the display flashes "888
        888".
      </li>
    </ul>
  )
}

export default Nonin8500

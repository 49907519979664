import { Formik, Form } from 'formik'
import DobForm from './forms/DobForm'
import Button from 'components/atoms/Button'
import verifyValidation from './schemas/dobValidationSchema'

const DobUpsert = ({ verifyDob }) => {
  const initialValues = {
    patientDob: '',
    month: '',
    day: '',
    year: '',
    dob: '',
  }
  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={verifyValidation}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values) => {
        const { year, month, day } = values
        verifyDob(`${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`)
      }}>
      {({ values, errors, touched, setFieldValue, handleSubmit }) => (
        <Form>
          <div className="d-flex pt-2 mb-4">
            <h4>Verify Patient's Date of Birth</h4>
            <Button
              type="button"
              id="btn-ts"
              label="Verify and Continue"
              className="btn-sm ml-auto"
              onClick={handleSubmit}
            />
          </div>
          <DobForm
            values={values}
            errors={errors}
            touched={touched}
            setFieldValue={setFieldValue}
          />
        </Form>
      )}
    </Formik>
  )
}

export default DobUpsert

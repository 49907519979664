/** @jsxImportSource @emotion/react */
import { useEffect, useMemo, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { skipToken } from '@reduxjs/toolkit/query/react'
import { Formik, Form, Field } from 'formik'
import Select from 'react-select'
import * as Yup from 'yup'

import { phoneNumberMask } from 'helpers/masks'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { useGetAllUsersQuery } from '../../../store/services/users'
import { setLoader } from '../../../store/slice/loaderSlice'
import DashboardContent from '../atoms/DashboardContent'
import { TextInput } from 'components/atoms/Input'
import { SelectInput } from 'components/atoms/Select'
import { TextAreaInput } from 'components/atoms/Textarea'
import ButtonSave from 'components/atoms/ButtonSave'
import routes from '../constants/routes'
import {
  useCreateTicketMutation,
  useGetTicketQuery,
  useUpdateTicketMutation,
} from 'store/services/tickets'
import MaskInput from 'components/atoms/MaskInput'

export interface TicketFormValues {
  email: string
  company: string
  mobileNumber: string
  orderNumber: string
  issue: string
  message: string
}

const validationSchema = Yup.object().shape({
  email: Yup.string().required('Required'),
  company: Yup.string().required('Required'),
  mobileNumber: Yup.string(),
  issue: Yup.string().required('Required'),
  orderNumber: Yup.string(),
  message: Yup.string().required('Required'),
})

const TicketUpsert = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const init = useAppSelector((state) => state.init)
  const { impersonate } = useAppSelector((state) => state.login)
  const officeId = init.currentOfficeId
  const [error, setError] = useState('')
  const [userSearch, setUserSearch] = useState('')
  const { data: users } = useGetAllUsersQuery({
    officeId,
    search: userSearch || undefined,
  })
  const usersOptions = users?.items?.map((user) => ({
    label: `${user.firstName} ${user.lastName} (${user.email})`,
    value: user.email,
  }))
  const dispatch = useAppDispatch()
  const { data: ticket, isLoading } = useGetTicketQuery(id ? { id, officeId } : skipToken)
  const [createTicket] = useCreateTicketMutation()
  const [updateTicket] = useUpdateTicketMutation()
  const office = init.offices.find((o) => Number(o.id) === Number(officeId))

  const initialValues: TicketFormValues = useMemo(
    () =>
      id === undefined
        ? {
            email: impersonate ? '' : init.profile.email,
            company: office?.officeName ?? '',
            mobileNumber: '',
            orderNumber: '',
            issue: '',
            message: '',
          }
        : {
            email: ticket?.contact?.email || '',
            company: office?.officeName ?? '',
            mobileNumber: ticket?.custom_fields.cf_mobile_number,
            orderNumber: ticket?.custom_fields.cf_order,
            issue: ticket?.subject ?? '',
            message: ticket?.description_text ?? '',
          },
    [ticket],
  )

  useEffect(() => {
    if (isLoading) {
      dispatch(setLoader(true))
    } else {
      dispatch(setLoader(false))
    }
  }, [isLoading])

  return (
    <div>
      <DashboardContent
        title={id ? 'Edit Ticket' : 'Submit a Ticket'}
        icon="fas fa-comment-dots"
        content={
          <div className="card card-primary p-4">
            <Formik
              enableReinitialize={true}
              validationSchema={validationSchema}
              initialValues={initialValues}
              onSubmit={async (values) => {
                console.log(values)
                setError('')
                const submitValues = {
                  officeId: Number(officeId),
                  mobileNumber: values.mobileNumber,
                  type: 'Problem',
                  subject: values.issue,
                  description: values.message,
                  orderId: values.orderNumber,
                  status: '2',
                  tags: [],
                  email: values.email,
                }
                try {
                  if (id) {
                    await updateTicket({ id: Number(id), ...submitValues }).unwrap()
                  } else {
                    await createTicket(submitValues).unwrap()
                  }
                  navigate(`${routes.index}${routes.ticket.root}`)
                  console.log(submitValues)
                } catch (error) {
                  setError(error.data.message)
                }
              }}>
              {({ values, errors, touched, handleChange, setValues }) => (
                <Form>
                  <div className="col-sm-8 col-md-6 form-group">
                    <label htmlFor="brandId" className="textGray">
                      Your Name
                    </label>
                    {!!id || !impersonate ? (
                      <Field
                        disabled
                        component={TextInput}
                        value={usersOptions?.find((c) => c?.value == values.email)?.label ?? null}
                      />
                    ) : (
                      <Field
                        component={Select}
                        isClearable
                        value={usersOptions?.find((c) => c?.value == values.email) ?? null}
                        onInputChange={(v: string) => v && setUserSearch(v)}
                        onChange={(event) => {
                          console.log(event)
                          setValues((p) => ({ ...p, email: event?.value }))
                        }}
                        name="email"
                        options={usersOptions}
                      />
                    )}
                    {touched.email && errors.email ? (
                      <div className="text-danger">{errors.email}</div>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="col-sm-8 col-md-6 form-group">
                    <label htmlFor="label" className="textGray">
                      Company
                    </label>
                    <Field disabled component={TextInput} name="company" />
                  </div>
                  <div className="col-sm-8 col-md-6 form-group">
                    <label htmlFor="serial" className="textGray">
                      Phone Number
                    </label>
                    <MaskInput
                      value={values.mobileNumber}
                      disabled={isLoading}
                      onChange={handleChange}
                      name="mobileNumber"
                      mask={phoneNumberMask}
                      placeholder="(###) ###-####"
                      autoComplete="off"
                    />
                    {touched.mobileNumber && errors.mobileNumber ? (
                      <div className="text-danger">{errors.mobileNumber}</div>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="col-sm-8 col-md-6 form-group">
                    <Field
                      component={SelectInput}
                      label="Issue"
                      name="issue"
                      disabled={isLoading}
                      options={[
                        { value: 'Need Something Changed On An Order' },
                        { value: 'Need Something Changed On My Account' },
                        { value: 'Problem Installing the TestSmarter Reader App' },
                        { value: 'Problem Uploading / Resetting Oximeter' },
                        { value: 'Other TestSmarter Reader App Issue' },
                        { value: 'Other Issue Not Listed Here' },
                      ]}
                    />
                  </div>

                  <div className="col-sm-8 col-md-6 form-group">
                    <Field
                      component={TextInput}
                      label="Order #"
                      name="orderNumber"
                      type="number"
                      disabled={isLoading}
                    />
                    <span css={{ fontSize: 13, paddingTop: '3px' }}>
                      * only enter order # if this issue is related to a specific order
                    </span>
                  </div>
                  <div className="col-sm-8 col-md-6">
                    <Field
                      component={TextAreaInput}
                      name="message"
                      label="Message"
                      rows={5}
                      disabled={isLoading}
                      placeholder="Please describe your issue here..."
                    />
                    <div css={{ fontSize: 13, marginTop: '-10px' }}>
                      Be sure to include specific information that will help us with your support
                      requests such as order numbers and error messages.
                    </div>
                    {touched.message && errors.message ? (
                      <div className="text-danger">{errors.message}</div>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="card-footer text-right mt-2">
                    {error && <span className="text-danger mr-3">{error}</span>}{' '}
                    <ButtonSave type="submit" label="Send Message" />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        }
        breadcrumb={[
          {
            label: 'Tickets',
            to: `${routes.index}${routes.ticket.root}`,
          },
          { label: id ? 'Edit' : 'New' },
        ]}
      />
    </div>
  )
}

export default TicketUpsert

/** @jsxImportSource @emotion/react */
import { Button } from 'react-bootstrap'

import LinkBtn from 'components/atoms/LinkBtn'
import usbIcon from 'assets/img/help-images/usb-icon.png'
import trayIcon from 'assets/img/help-images/tray-ts-icon.jpg'

export const DeviceConnectError = ({ onReturn }: { onReturn: () => void }) => (
  <div css={{ fontSize: 16, lineHeight: '22px' }}>
    We're not able to communicate with your TestSmarter Reader app. <br />
    Please follow these troubleshooting steps before contacting us: <br />
    <br />
    <ol
      css={{
        listStyleType: 'decimal',
        li: { fontSize: 16, lineHeight: '22px' },
      }}>
      <li>
        <span className="text-bold" css={{ fontSize: 16, lineHeight: '22px' }}>
          If you're new to TestSmarter, or this is a new computer
        </span>
        , you may not have the TestSmarter Reader app installed yet. Go to your Windows Start Menu
        and type in "TestSmarter Reader". If nothing comes up, you will need to install the app:
        <br />
        <LinkBtn
          className="my-3 btn btn-primary text-decoration-none text-white"
          label={
            <>
              <i className="fas fa-external-link-alt mr-2" /> Go to downloads page
            </>
          }
          to="/dme/helpDownload/"
        />
      </li>
      <li>
        <span className="text-bold" css={{ fontSize: 16, lineHeight: '22px' }}>
          If TestSmarter Reader is already installed
        </span>
        , check if it's currently open. You should see the icon in your task bar near the clock at
        the bottom right of your screen:
        <img src={trayIcon} alt="tray icon" className="img-fluid my-3" css={{ width: 400 }} />
        <br />
        <span className="text-bold" css={{ fontSize: 16, lineHeight: '22px' }}>
          If you're running an older version
        </span>
        , you may see the following icon instead:{' '}
        <img src={usbIcon} alt="tray icon" className="img-fluid ml-3" />
      </li>
      <li>
        <span className="text-bold" css={{ fontSize: 16, lineHeight: '22px' }}>
          If the app is installed but not running
        </span>
        , you just need to open the app: <br />
        Go to your Windows Start Menu and type in "TestSmarter Reader", then click on the app to
        open it.
      </li>
    </ol>
    If you are still having trouble, please call us at (386) 382-4200 or click here to submit a
    ticket.
    <br />
    <Button className="mt-4" onClick={onReturn}>
      <i className="fas fa-undo mr-2" /> Start Over
    </Button>
  </div>
)

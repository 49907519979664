import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../atoms/Table";
import DashboardContent from "../atoms/DashboardContent";
import * as generalHelper from "../../../helpers/generalHelper";
import { getFileTypes } from "../../../store/slice/fileTypeSlice";
import ButtonNew from "../../atoms/ButtonNew";
import Input from "../../atoms/Input";
import { handleChange } from "../../atoms/Upsert";
import { setLoader } from "../../../store/slice/loaderSlice";
import Loader from "../../atoms/Loader";
import routes from "../constants/routes";

export default function (props) {
    const rows = useSelector((state) => state.fileType.rows);
    const office_id = useSelector((state) => state.init.office_id);
    const [values, setValues] = useState({ _lab: 1, search: "" });
    const dispatch = useDispatch();

    React.useEffect(() => {
        let params = {
            add_join: "status",
            no_cache: 1,
            _lab: 1,
        };
        if (values.search) {
            params.search = values.search;
        }
        dispatch(setLoader(true));
        dispatch(getFileTypes(params)).then(() => {
            dispatch(setLoader(false));
        });
    }, [office_id, values.search]);

    function Content(props) {
        const header = [
            {
                key: "id",
                to: `${routes.index}${routes.fileType.root}${routes.upsert}`,
                label: "Id",
            },
            { key: "name", label: "Name" },
            { key: "status", key2: "name", label: "Status" },
        ];
        return (
            <div className="card card-primary">
                <form type="post" onSubmit={props.handleSubmit}>
                    <div className="card-header row">
                        <div className="col-10 input-group mb-3">
                            <Input
                                autoFocus={true}
                                value={values.search}
                                label={false}
                                placeholder="Search..."
                                name="search"
                                parentDiv={null}
                                handleChange={(event) => {
                                    handleChange(event, values, setValues);
                                }}
                            />

                            <div className="input-group-append">
                                <span className="input-group-text">
                                    <i className="fas fa-search" />
                                </span>
                            </div>
                        </div>

                        <div className="col-2 text-right">
                            <ButtonNew
                                to={`${routes.index}${routes.fileType.root}${routes.upsert}`}
                            />
                        </div>
                    </div>
                    <div className="card-body row">
                        <Table header={header} rows={rows} />
                    </div>
                </form>
            </div>
        );
    }

    return (
        <DashboardContent
            title="File Types"
            icon="fa fa-file"
            content={Content(props)}
            breadcrumb={[{ label: "File Types" }]}
        />
    );
}

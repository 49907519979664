/** @jsxImportSource @emotion/react */
import { useMemo } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import { phoneValidation } from 'helpers/validate'
import { PayerForm, PayerFormValues } from './PayerForm'

interface IProps {
  open: boolean
  onClose: () => void
  payorDetails?: Partial<PayerFormValues> | null
  onSave: (payor: PayerFormValues) => void
}

export const payorValidation = Yup.object().shape({
  name: Yup.string().required('Required'),
  telephone: Yup.lazy((v) =>
    !v || v === '' ? Yup.string().nullable().optional() : phoneValidation,
  ),
  fax: Yup.lazy((v) => (!v || v === '' ? Yup.string().nullable().optional() : phoneValidation)),
  addressStreetLine1: Yup.string().optional(),
  addressCity: Yup.string().optional(),
  addressState: Yup.string().optional(),
  addressZip: Yup.string().optional(),
})

const PayorDetailsModal = ({ open, payorDetails, onSave, onClose }: IProps) => {
  const initialValues: PayerFormValues = useMemo(
    () => ({
      name: payorDetails?.name ?? '',
      fax: payorDetails?.fax?.replace(/^\+1/, '') ?? '',
      telephone: payorDetails?.telephone?.replace(/^[\+1|-]/, '') ?? '',
      addressStreetLine1: payorDetails?.addressStreetLine1 ?? '',
      addressStreetLine2: payorDetails?.addressStreetLine2 ?? '',
      addressCity: payorDetails?.addressCity ?? '',
      addressState: payorDetails?.addressState ?? '',
      addressZip: payorDetails?.addressZip ?? '',
    }),
    [payorDetails],
  )

  return (
    <Modal centered show={open} onHide={onClose}>
      <Modal.Header>
        <Modal.Title>Payor Details</Modal.Title>
      </Modal.Header>
      {open ? (
        <Formik
          enableReinitialize={true}
          validationSchema={payorValidation}
          initialValues={initialValues}
          initialTouched={{
            telephone: true,
            fax: true,
          }}
          onSubmit={onSave}>
          {({ isValid }) => (
            <Form>
              <Modal.Body>
                <PayerForm />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="outline-secondary" onClick={onClose}>
                  Cancel
                </Button>
                <Button type="submit" disabled={!isValid}>
                  Confirm
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      ) : null}
    </Modal>
  )
}

export default PayorDetailsModal

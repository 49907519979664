import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../atoms/Table";
import DashboardContent from "../atoms/DashboardContent";
import * as generalHelper from "../../../helpers/generalHelper";
import { getDeviceTypeLinks } from "../../../store/slice/deviceTypeLinkSlice";
import ButtonNew from "../../atoms/ButtonNew";
import Input from "../../atoms/Input";
import { setLoader } from "../../../store/slice/loaderSlice";
import Loader from "../../atoms/Loader";
import { handleChange } from "../../atoms/Upsert";
import routes from "../constants/routes";

export default function (props) {
    const rows = useSelector((state) => state.deviceTypeLink.rows);
    const office_id = useSelector((state) => state.init.office_id);
    const [values, setValues] = useState({ _lab: 1, search: "" });
    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(setLoader(true));
        dispatch(
            getDeviceTypeLinks({
                no_cache: 1,
                add_join: "status|device|device_type",
            })
        ).then(() => {
            dispatch(setLoader(false));
        });
    }, [office_id]);
    function Content(props) {
        const header = [
            {
                key: "id",
                to: `${routes.index}${routes.deviceTypeLink.root}${routes.upsert}`,
                label: "Id",
            },
            { key: "device", key2: "name", label: "Device Name" },
            { key: "device", key2: "label", label: "Device Label" },
            { key: "device_type", key2: "name", label: "Type" },
            { key: "status", key2: "name", label: "Status" },
        ];
        return (
            <div className="card card-primary">
                <form type="post" onSubmit={props.handleSubmit}>
                    <div className="card-header row">
                        <div className="col-10 input-group mb-3">
                            {/* <Input
                                autoFocus={true}
                                value={values.search}
                                label={false}
                                placeholder="Search..."
                                name="search"
                                parentDiv={null}
                                handleChange={(event) => {
                                    handleChange(event, values, setValues);
                                }}
                            />

                            <div className="input-group-append">
                                <span className="input-group-text">
                                    <i className="fas fa-search" />
                                </span>
                            </div> */}
                        </div>

                        <div className="col-2 text-right">
                            <ButtonNew
                                to={`${routes.index}${routes.deviceTypeLink.root}${routes.upsert}`}
                            />
                        </div>
                    </div>
                    <div className="card-body row">
                        <Table header={header} rows={rows} />
                    </div>
                </form>
            </div>
        );
    }

    return (
        <DashboardContent
            title="Device Type Links"
            icon="fa fa-link"
            content={Content(props)}
            breadcrumb={[
                {
                    label: "Devices",
                    to: `${routes.index}${routes.device.root}`,
                },
                { label: "Device Type Links" },
            ]}
        />
    );
}

import { Link } from 'react-router-dom'

interface IProps {
  id: string
  className: string
  to: string
  label: string
}

const LinkBtn = (props: IProps) => {
  return (
    <Link
      {...props}
      id={props.id}
      className={`btn ${props.className ? props.className : ''}`}
      to={props.to}
    >
      {props.label}
    </Link>
  )
}

export default LinkBtn

import Link from './Link'
import { useNavigate } from 'react-router-dom'

interface IProps {
  label: string
  icon: string
  className?: string
  to?: string
  onClick?: () => void
}

const ButtonCancelComponent = (props: IProps) => {
  const navigate = useNavigate()
  const label = props.label ?? 'Cancel'
  const icon = props.icon ?? 'fa fa-times'
  const className = `btn btn-default ${props.className ?? ''}`
  const onClick = props.onClick
    ? props.onClick
    : () => {
        navigate(-1)
      }
  const to = props.to ? props.to : '#'

  return (
    <Link
      to={to}
      label={label}
      icon={icon}
      onClick={onClick}
      className={className}
    />
  )
}

export default ButtonCancelComponent

/** @jsxImportSource @emotion/react */
import { useState } from 'react'
import styled from '@emotion/styled'
import { Button } from 'react-bootstrap'

import Input from 'components/atoms/Input'
import Checkbox from 'components/atoms/Checkbox'
import { CompanyCollections } from './CompanyUpsert'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

interface IProps {
  domains: { domain: string; sso: boolean }[] | null
  onUpdateCompanyCollection: (data: Partial<CompanyCollections>) => void
}

export const EmailDomains = ({ domains = [], onUpdateCompanyCollection }: IProps) => {
  const [newEntry, setNewEntry] = useState('')
  const [isSso, setIsSso] = useState(false)
  const isValidRegex = /^(?!-)[A-Za-z0-9-]+([\-\.]{1}[a-z0-9]+)*\.[A-Za-z]{2,6}$/
  const isValid = isValidRegex.test(newEntry) && !domains?.find((d) => d.domain === newEntry)

  const onSubmit = () => {
    const newDomain = {
      domain: newEntry,
      sso: isSso,
    }
    onUpdateCompanyCollection({ companyDomains: [newDomain, ...(domains || [])] })
    setNewEntry('')
    setIsSso(false)
  }

  return (
    <Container>
      <span style={{ color: '#6D757E' }} className="mb-1">
        Add Company Email Domain
      </span>
      <div className="d-flex w-100 position-relative align-items-center">
        <Input
          parentDiv={null}
          name="newEntry"
          className="form-control mr-2"
          placeholder="Add Email Domain"
          value={newEntry}
          handleChange={(e) => setNewEntry(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter' && isValid) {
              onSubmit()
            }
          }}
        />
        {isValid && (
          <i
            className="fas fa-check-circle"
            css={{
              color: isValid ? '#28a745' : '#6D757E',
              position: 'absolute',
              right: 180,
              top: 10,
            }}
          />
        )}
        <Checkbox
          disabled={!isValid}
          handleChange={() => setIsSso(!isSso)}
          value={isSso}
          label="SSO"
        />
        <Button
          variant="primary"
          style={{ minWidth: 100, marginLeft: 10 }}
          disabled={!isValid}
          onClick={() => {
            if (newEntry) {
              onSubmit()
            }
          }}>
          Add Domain
        </Button>
      </div>
      {domains?.length ? (
        <>
          <div
            className="row flex-row flex-nowrap justify-content-between align-items-center p-2 my-2"
            style={{
              color: '#6D757E',
              borderBottom: '1px solid #ced4da',
            }}>
            <div style={{ width: '100%' }}>Company Email Domain</div>
            <div className="flex-shrink-0 pr-3" style={{ width: 100, textAlign: 'end' }}>
              Action
            </div>
          </div>
          {domains.map(({ domain, sso }) => (
            <div className="row my-1" key={domain}>
              <div className="col-sm-12 p-0">
                <div className="list-group text-secondary">
                  <div className="list-group-item list-group-item-action flex-column align-items-start">
                    <div className="d-flex py-1 justify-content-between align-items-center">
                      <span>{domain}</span>
                      <span>{sso ? '(SSO)' : ''}</span>
                      <Button
                        variant="primary"
                        size="sm"
                        onClick={() => {
                          onUpdateCompanyCollection({
                            companyDomains: domains.filter((d) => d.domain !== domain),
                          })
                        }}>
                        Remove
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      ) : (
        <div className="row my-1 mt-3">
          <div className="col-sm-12 p-0">
            <div className="list-group text-secondary">
              <div className="list-group-item list-group-item-action flex-column align-items-center">
                <div className="d-flex py-1 justify-content-center align-items-center">
                  <span>No domains provided</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Container>
  )
}

/** @jsxImportSource @emotion/react */
import { SyntheticEvent, ReactNode } from 'react'
import { Field, FormikErrors, FormikTouched } from 'formik'
import Button from 'react-bootstrap/Button'
import { css } from '@emotion/react'

import states from 'constants/states'
import MaskInput from 'components/atoms/MaskInput'
import InputMask from 'components/atoms/InputMask'
import GoogleAutocomplete from 'components/atoms/GoogleAutocomplete'
import { phoneNumberMask, zipMask } from 'helpers/masks'
import * as generalHelper from 'helpers/generalHelper'
import { getAddressFields } from 'components/dme/forms/utils/userFormUtils'

interface FormValues {
  firstName: string
  lastName: string
  dob: string
  mobilePhone: string
  email: string
  primaryAddressStreetLine1: string
  primaryAddressStreetLine2: string
  primaryAddressCity: string
  primaryAddressState: string
  primaryAddressZip: string
  numberOfNight: number
}

interface IProps {
  values: FormValues
  errors: FormikErrors<FormValues>
  touched: FormikTouched<FormValues>
  handleChange: (e: SyntheticEvent) => void
  setFieldValue: (k: string, v: string) => void
  setFieldTouched: (k: string) => void
}

export const PatientDataForm = ({
  values = {},
  errors = {},
  touched,
  handleChange,
  setFieldValue,
  setFieldTouched,
}: IProps) => {
  return (
    <div
      className={`container bg-white mt-2 mb-3 px-3`}
      css={css`
        max-width: 500px;
        border: 1px solid #e0e0e0;
        padding: 1rem;
        border-radius: 5px;
        margin-left: unset;
        margin-right: unset;
      `}>
      Please review the patient information, then submit the order to NightOwl:
      <br />
      <br />
      <span css={{ fontStyle: 'italic', color: 'gray' }}>
        Note that any changes made here will update the patient record.
      </span>
      <div className="row mt-3">
        <div className="col-6 form-group textGray">
          <label htmlFor="dob">Date Of Birth</label>
          <InputMask
            value={values?.dob ?? ' '}
            className="form-control"
            name="dob"
            onChange={handleChange}
            mask="99/99/9999"
          />
          {touched.dob && errors.dob ? <div className="text-danger">Required</div> : ''}
        </div>
      </div>
      <div className="row">
        <div className="col-6 form-group textGray">
          <label htmlFor="firstName">First Name</label>
          <Field
            type="text"
            name="firstName"
            className="form-control"
            placeholder="First Name"
            value={values.firstName}
            onChange={handleChange}
            autoComplete="one-time-code"
          />
          {touched.firstName && errors.firstName ? (
            <div className="text-danger">{errors.firstName}</div>
          ) : (
            ''
          )}
        </div>
        <div className="col-6 form-group textGray">
          <label htmlFor="lastName">Last Name</label>
          <Field
            type="text"
            name="lastName"
            className="form-control"
            placeholder="Last Name"
            value={values.lastName}
            onChange={handleChange}
            autoComplete="one-time-code"
          />
          {touched.lastName && errors.lastName ? (
            <div className="text-danger">{errors.lastName}</div>
          ) : (
            ''
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-6 form-group textGray">
          <label htmlFor="mobilePhone">Mobile Number</label>
          <MaskInput
            value={values.mobilePhone}
            onChange={handleChange}
            name="mobilePhone"
            mask={phoneNumberMask}
            placeholder="(###) ###-####"
            autoComplete="off"
          />
          {touched.mobilePhone && errors.mobilePhone ? (
            <div className="text-danger">{errors.mobilePhone}</div>
          ) : null}
        </div>

        <div className="col-6 form-group textGray">
          <label htmlFor="email">Email</label>
          <Field
            type="text"
            name="email"
            className="form-control"
            placeholder="Email"
            value={values.email}
            autoComplete="one-time-code"
          />
          {touched.email && errors.email ? <div className="text-danger">{errors.email}</div> : null}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="col-12 form-group textGray">
              <label htmlFor="primaryAddressStreetLine1">
                {generalHelper.t('Primary Address')}
              </label>
              <GoogleAutocomplete
                id="address"
                name="address"
                onInputChanged={(v) => {
                  setFieldValue('primaryAddressStreetLine1', v)
                  setFieldTouched('primaryAddressStreetLine1')
                }}
                onChange={(place) => {
                  const { address, city, state, zip } = getAddressFields(place, 'address')
                  console.log(address, city, state, zip)
                  setFieldValue('primaryAddressStreetLine1', address)
                  setFieldValue('primaryAddressCity', city)
                  setFieldValue('primaryAddressState', state)
                  setFieldValue('primaryAddressZip', zip)
                }}
              />
              {touched.primaryAddressStreetLine1 && errors.primaryAddressStreetLine1 ? (
                <div className="text-danger">{errors.primaryAddressStreetLine1}</div>
              ) : null}
            </div>
            <div className="col-12 form-group textGray">
              <Field
                type="text"
                name="primaryAddressStreetLine2"
                className="form-control"
                placeholder="Suite / Apt"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-4 form-group textGray">
              <label htmlFor="primaryAddressCity">City</label>
              <Field
                type="text"
                name="primaryAddressCity"
                className="form-control"
                placeholder="City"
              />
              {touched.primaryAddressCity && errors.primaryAddressCity ? (
                <div className="text-danger">{errors.primaryAddressCity}</div>
              ) : null}
            </div>
            <div className="col-4 form-group textGray">
              <label htmlFor="primaryAddressState">State</label>
              <Field
                className="form-control"
                as="select"
                name="primaryAddressState"
                placeholder="State">
                {states &&
                  states.map((s, i) => (
                    <option key={i} value={s.id}>
                      {s.name}
                    </option>
                  ))}
              </Field>
              {touched.primaryAddressState && errors.primaryAddressState ? (
                <div className="text-danger">{errors.primaryAddressState}</div>
              ) : null}
            </div>
            <div className="col-4 form-group textGray">
              <label htmlFor="primaryAddressZip">Zip Code</label>
              <MaskInput
                value={values.primaryAddressZip}
                onChange={handleChange}
                name="primaryAddressZip"
                mask={zipMask}
                placeholder="#####-####"
              />

              {touched.primaryAddressZip && errors.primaryAddressZip ? (
                <div className="text-danger">{errors.primaryAddressZip}</div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="col-6"></div>{' '}
      </div>
      <div className="row">
        <div className="col-4 form-group textGray">
          <label htmlFor="email">How Many Nights?</label>
          <Field
            type="text"
            name="numberOfNight"
            className="form-control"
            placeholder="Nights"
            value={values.numberOfNight}
            autoComplete="off"
          />
          {touched.numberOfNight && errors.numberOfNight ? (
            <div className="text-danger">{errors.numberOfNight}</div>
          ) : null}
        </div>
      </div>
      <Button className="mt-2" type="submit" data-flag="submitToNightOwl">
        Submit To NightOwl
      </Button>
    </div>
  )
}

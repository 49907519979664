import DashboardContent from '../atoms/DashboardContent'
import UserUpsert from './UserUpsert'
import routes from '../constants/routes'

export default () => (
  <DashboardContent
    title="Manage Account Settings"
    icon="fas fa-user"
    content={<UserUpsert />}
    breadcrumb={[{ label: 'Home', to: `${routes.index}` }, { label: 'Manage Account Settings' }]}
  />
)

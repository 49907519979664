export const READER_SETUP =
  'https://ts-platform-web-assets.s3.amazonaws.com/reader-app/TestSmarterReaderSetup-22.11.21.222.exe'

export const ONE_TRIP_DRIVER =
  'https://ts-platform-web-assets.s3.amazonaws.com/reader-app/OneTripDriverPackSetup-22.11.17.exe'
export const KEYSPAN_DRIVER =
  'https://ts-platform-web-assets.s3.amazonaws.com/reader-app/KeyspanDriverPackSetup-22.11.17.exe'
export const NONIN_DRIVER =
  'https://ts-platform-web-assets.s3.amazonaws.com/reader-app/NoninDriverPackSetup-22.11.17.exe'
export const PROLIFIC_DRIVER =
  'https://ts-platform-web-assets.s3.amazonaws.com/reader-app/ProlificDriverPackSetup-22.11.17.exe'
export const FTDI_DRIVER =
  'https://ts-platform-web-assets.s3.amazonaws.com/reader-app/FTDIDriverPackSetup-22.11.17.exe'

export const INSTRUCTIONS =
  'https://ts-platform-web-assets.s3.amazonaws.com/reader-app/2022-11-25-TestSmarterReaderSetup.pdf'

export const READER_SETUP_ADMIN =
  'https://ts-platform-web-assets.s3.amazonaws.com/reader-app/support-admin/TestSmarterReaderSetup-22.11.21.222.msi'
export const ONE_TRIP_DRIVER_ADMIN =
  'https://ts-platform-web-assets.s3.amazonaws.com/reader-app/support-admin/OneTripDriverPackSetup-22.11.17.msi'
export const KEYSPAN_DRIVER_ADMIN =
  'https://ts-platform-web-assets.s3.amazonaws.com/reader-app/support-admin/KeyspanDriverPackSetup-22.11.17.msi'
export const NONIN_DRIVER_ADMIN =
  'https://ts-platform-web-assets.s3.amazonaws.com/reader-app/support-admin/NoninDriverPackSetup-22.11.17.msi'
export const PROLIFIC_DRIVER_ADMIN =
  'https://ts-platform-web-assets.s3.amazonaws.com/reader-app/support-admin/ProlificDriverPackSetup-22.11.17.msi'
export const FTDI_DRIVER_ADMIN =
  'https://ts-platform-web-assets.s3.amazonaws.com/reader-app/support-admin/FTDIDriverPackSetup-22.11.17.msi'

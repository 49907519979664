/** @jsxImportSource @emotion/react */
import React from 'react'

interface IProps {
  title: string
  count: number | null | undefined
  color: string
  loading?: boolean
  active?: boolean
  onClick?: () => void
  containerStyle?: React.CSSProperties
}

const CountsBlock = ({ onClick, active, containerStyle, count, color, loading, title }: IProps) => (
  <div
    css={{
      borderRadius: 3,
      overflow: 'hidden',
      width: '100%',
      cursor: onClick ? 'pointer' : 'default',
      boxShadow: '3px 3px 10px 1px #cbcbcb',
      ...containerStyle,
    }}
    onClick={onClick}>
    <div
      css={{
        backgroundColor: active ? '#e4ffdb' : 'white',
        userSelect: 'none',
        borderTop: `3px solid ${color}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        color: loading ? '#a2a2a2' : 'black',
        height: '100%',
      }}>
      <h1 className={'mb-0'}>{(!count && count !== 0) || loading ? '--' : count}</h1>
      <p>
        {title}
        {active && <i className="fas fa-angle-down ml-2" css={{ color: '#757575' }} />}
      </p>
    </div>
  </div>
)

export default CountsBlock

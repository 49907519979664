import Button from '../../atoms/Button'
import { Physician, PhysicianAddress } from 'store/types'

interface IProps {
  isEditMode: boolean
  physician: Physician
  physicianLocation: PhysicianAddress
  onRemove: () => void
  onEdit: (v: boolean) => void
}

const PhysicianSelectedBox = ({
  isEditMode,
  physician,
  physicianLocation: physicianLocationProp,
  onRemove,
  onEdit,
}: IProps) => {
  const location = {
    addressStreetLine1: physicianLocationProp?.addressStreetLine1 || '',
    addressCity: physicianLocationProp?.addressCity || '',
    addressState: physicianLocationProp?.addressState || '',
    telephone: physicianLocationProp?.telephone || '',
    fax: physicianLocationProp?.fax || '',
  }

  return (
    <div className="container-fluid">
      <div className="row border rounded p-2 justify-content-between">
        <div className="col-1 d-flex align-items-center ml-3">
          {physician.id ? (
            <i className="text-success fa fa-user-check" />
          ) : (
            <i className="text-default fa fa-user-md" />
          )}
        </div>
        <div className="col d-flex align-items-center">{physician.npi}</div>
        <div className="col d-flex align-items-center">
          {physician.firstName} {physician.lastName}
        </div>
        <div>
          <div>
            {location.addressStreetLine1}
            {', '}
            {location.addressCity}
            {', '}
            {location.addressState}
          </div>
          <div>
            {location.telephone && <strong>Tel: </strong>}
            {location.telephone} {location.fax && <strong>Fax: </strong>}
            {location.fax}{' '}
          </div>
        </div>

        <div className="col text-right d-flex align-items-center justify-content-end">
          {!isEditMode && (
            <Button
              onClick={() => onEdit(true)}
              label="Modify"
              className="btn btn-sm btn-outline-primary mr-1"
              type="button"
            />
          )}
          <Button
            onClick={onRemove}
            className="btn btn-sm btn-outline-danger"
            label="Remove"
            type="button"
          />
        </div>
      </div>
    </div>
  )
}

export default PhysicianSelectedBox

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getLogout } from './loginSlice'

// ------------------------------------------------------------
export const setLoader = createAsyncThunk('setLoader', async (status: boolean) => status)

// ------------------------------------------------------------
const initialState = {
  row: false,
}

// ------------------------------------------------------------
const localSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setLoader.fulfilled, (state, action) => {
      state.row = action.payload
    })
    builder.addCase(getLogout.fulfilled, () => initialState)
  },
})

export default localSlice.reducer

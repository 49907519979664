/** @jsxImportSource @emotion/react */
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { Field } from 'formik'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import BsButton from 'react-bootstrap/Button'
import { useParams } from 'react-router-dom'
import ReactSelect from 'react-select'
import { DevicesList } from 'ts-back-end/src/business/device/dto/device-list.dto'
import { DeviceDto } from 'ts-back-end/src/business/device/dto/device.dto'
import { OrderThirdPartyDeviceOutput } from 'ts-back-end/src/business/order/dto'
import { useCreateLabDeviceMutation, useUpdateLabDeviceMutation } from 'store/services/devices'
import {
  useAssignNightOwlDeviceMutation,
  useGetShippingRatesMutation,
  useInitiateManualShippingMutation,
  useLocalPickupMutation,
  useRequestDeliveryMutation,
  useValidateShippingAddressMutation,
} from 'store/services/labOrder'
import CreateDeviceForm from '../../device/CreateDeviceForm'
import Toast from 'components/atoms/Toast'
import { ShippingMethod } from './ShippingMethod'

interface IProps {
  thirdPartyDevice: OrderThirdPartyDeviceOutput | undefined
  testType: string
  values: any
  setValues: (func: (p: any) => any) => void
  devices: DevicesList
  startShipping: boolean
  allowedDevices?: number[]
}

const CreateDevice = ({
  id,
  orderId,
  close,
  setValues,
  allowedDevices,
  startShipping,
}: {
  id: number | undefined
  orderId: number
  close: () => void
  setValues: (func: (p: any) => any) => void
  startShipping: boolean
  allowedDevices?: number[]
}) => {
  const [submitting, setSubmitting] = useState(false)
  const [createDevice] = useCreateLabDeviceMutation()
  const [updateDevice] = useUpdateLabDeviceMutation()
  const [assignDevice] = useAssignNightOwlDeviceMutation()
  const [getShippingRates] = useGetShippingRatesMutation()
  const [validateShippingAddress] = useValidateShippingAddressMutation()
  const [requestDelivery] = useRequestDeliveryMutation()

  return (
    <div className={'mt-4'}>
      <CreateDeviceForm
        id={id ? Number(id) : undefined}
        allowedDevices={allowedDevices || [10, 13]}
        onCancel={() => close()}
        submitting={submitting}
        onSubmit={async (values) => {
          if (submitting) return

          setSubmitting(true)

          try {
            const submitValues = {
              ...values,
              ...(!values.officeId && { officeId: null }),
              statusUpdatedAt: values.statusUpdatedAt
                ? moment(values.statusUpdatedAt).format('YYYY-MM-DD')
                : undefined,
            }

            setValues((p) => ({
              ...p,
              deviceBrandId: Number(submitValues?.brandId) || -1,
            }))

            if (id) {
              updateDevice({ id: Number(id), ...submitValues })
              close()
            } else {
              if (startShipping) {
                const validation = await validateShippingAddress({
                  orderId: Number(orderId),
                })

                if (!validation.data?.valid) {
                  Toast({
                    type: 'error',
                    label:
                      'The provided shipping address is invalid. Please verify the address and try again.',
                  })
                  return
                }

                await createDevice(submitValues)
                await assignDevice({
                  orderId: Number(orderId),
                  serial: submitValues.serial,
                })
                close()

                const rates = await getShippingRates({
                  orderId: Number(orderId),
                })

                await requestDelivery({
                  orderId: Number(orderId),
                  selectedDeliveryRate: rates.data.selectedDeliveryRate,
                  selectedReturnRate: rates.data.selectedReturnRate,
                  selectedBy: 'test',
                })
              } else {
                await createDevice(submitValues)
                await assignDevice({
                  orderId: Number(orderId),
                  serial: submitValues.serial,
                })
                close()
              }
            }
          } finally {
            setSubmitting(false)
          }
        }}
      />
    </div>
  )
}

const DeviceSelection = ({
  thirdPartyDevice,
  testType,
  values,
  setValues,
  allowedDevices,
  startShipping,
  devices,
}: IProps) => {
  const { orderId } = useParams()
  const [assignDevice] = useAssignNightOwlDeviceMutation()
  const [localPickup] = useLocalPickupMutation()
  const [initiateManualShipping] = useInitiateManualShippingMutation()
  const [getShippingRates] = useGetShippingRatesMutation()
  const [validateShippingAddress] = useValidateShippingAddressMutation()
  const [requestDelivery] = useRequestDeliveryMutation()
  const [editDevice, setEditDevice] = useState(false)
  const [resultsShown, setResultsShown] = useState(false)

  const UNAVAILABLE_DEVICE_STATES = ['RETURN_RECEIVED', 'IN_REPAIR', 'RETURN_IN_TRANSIT']

  const getLabel = (d: DeviceDto) =>
    `Label: ${d.label?.trim()?.toUpperCase() ?? 'N/A'} / Serial #: ${
      d.serial ?? 'N/A'
    } / Mac addr: ${d.mac ?? 'N/A'}`

  const filteredDevices = useMemo(() => {
    return devices?.items
      ?.filter((d) => !UNAVAILABLE_DEVICE_STATES.includes(d.state) && !!d.serial)
      ?.sort((a, b) => (a.orderId && !b.orderId ? 1 : -1))
      ?.map((d) => ({
        value: d.serial,
        label: getLabel(d),
      }))
  }, [devices])

  useEffect(() => {
    if (resultsShown && !editDevice && !devices?.items?.length && !thirdPartyDevice?.serial) {
      setEditDevice(true)
    }
  }, [resultsShown])

  return (
    <div
      css={{
        width: editDevice ? '100%' : 600,
      }}>
      {!thirdPartyDevice?.serial ? (
        <>
          <div className="mt-4 d-flex">
            <ErrorOutlineIcon className={'mr-2 text-danger'} />
            <h5 className={'font-weight-600'}>Enter device information</h5>
          </div>
          <span>
            {editDevice
              ? !thirdPartyDevice?.serial
                ? 'No devices with this data were found, please enter the data manually'
                : ''
              : resultsShown
                ? 'We have found devices with matching information, select a device from the list'
                : 'First, enter the serial number, Mac Address or Label of the device. If a device with this data is found, the system will automatically fill in the required information If the device with this serial number is not found in the system - fill in the form manually.'}
          </span>
          {editDevice ? (
            <CreateDevice
              id={undefined}
              orderId={Number(orderId)}
              setValues={setValues}
              close={() => setEditDevice(false)}
              startShipping={startShipping}
              allowedDevices={allowedDevices}
            />
          ) : resultsShown ? (
            <div>
              <label
                htmlFor="deviceSerial"
                className={'font-weight-normal mt-2'}
                css={{ color: '#888888' }}>
                Select Device
              </label>
              <ReactSelect
                className="basic-single w-100 mr-3 mb-3"
                classNamePrefix="select"
                isSearchable
                placeholder="Select the Device"
                name="deviceSerial"
                onChange={(item) =>
                  setValues((p) => ({
                    ...p,
                    deviceSerial: item,
                    deviceBrandId:
                      devices?.items?.find((x) => x?.serial === item?.value)?.brandId || -1,
                  }))
                }
                options={filteredDevices ?? []}
                value={values.deviceSerial}
              />
              {startShipping && values.deviceSerial && (
                <ShippingMethod
                  testType={testType}
                  values={values}
                  onShipping={async () => {
                    const validation = await validateShippingAddress({
                      orderId: Number(orderId),
                    })

                    if (!validation.data?.valid) {
                      Toast({
                        type: 'error',
                        label:
                          'The provided shipping address is invalid. Please verify the address and try again.',
                      })
                      return
                    }

                    await assignDevice({
                      orderId: Number(orderId),
                      serial: values.deviceSerial.value,
                    })

                    const rates = await getShippingRates({
                      orderId: Number(orderId),
                    })

                    await requestDelivery({
                      orderId: Number(orderId),
                      selectedDeliveryRate: rates.data.selectedDeliveryRate,
                      selectedReturnRate: rates.data.selectedReturnRate,
                      selectedBy: 'test',
                    })
                  }}
                  onManualShipping={async () => {
                    await assignDevice({
                      orderId: Number(orderId),
                      serial: values.deviceSerial.value,
                    })
                    await initiateManualShipping({
                      orderId: Number(orderId),
                      trackingNumber: values.trackingNumber,
                      returnTrackingNumber: values.returnTrackingNumber,
                    })
                  }}
                  onLocalPickup={async () => {
                    await assignDevice({
                      orderId: Number(orderId),
                      serial: values.deviceSerial.value,
                    })
                    await localPickup({
                      orderId: Number(orderId),
                    })
                  }}
                />
              )}
              {!startShipping && (
                <BsButton
                  className={'mb-2'}
                  disabled={!values.deviceSerial}
                  onClick={async () => {
                    await assignDevice({
                      orderId: Number(orderId),
                      serial: values.deviceSerial.value,
                    })
                  }}
                  css={{
                    width: '100%',
                  }}>
                  Assign Device
                </BsButton>
              )}
              <BsButton
                variant={'outline-primary'}
                onClick={() => setEditDevice(true)}
                css={{
                  width: '100%',
                  marginTop: startShipping ? 10 : 0,
                }}>
                Add new Device
              </BsButton>
            </div>
          ) : (
            <>
              <div>
                <label
                  htmlFor="searchSerialNumber"
                  className={'font-weight-normal mt-2'}
                  css={{ color: '#888888' }}>
                  Serial Number
                </label>
                <Field
                  type="text"
                  name="searchSerialNumber"
                  className="form-control"
                  placeholder="Enter Serial Number"
                  value={values.searchSerialNumber}
                  autoComplete="off"
                />
              </div>
              <div>
                <label
                  htmlFor="searchMacAddress"
                  className={'font-weight-normal mt-2'}
                  css={{ color: '#888888' }}>
                  Mac Addr.
                </label>
                <Field
                  type="text"
                  name="searchMacAddress"
                  className="form-control"
                  placeholder="Enter Mac Addr."
                  value={values.searchMacAddress}
                  autoComplete="off"
                />
              </div>
              <div>
                <label
                  htmlFor="searchLabel"
                  className={'font-weight-normal mt-2'}
                  css={{ color: '#888888' }}>
                  Label
                </label>
                <Field
                  type="text"
                  name="searchLabel"
                  className="form-control"
                  placeholder="Enter Label"
                  value={values.searchLabel}
                  autoComplete="off"
                />
              </div>
              <BsButton
                className={'mt-3'}
                disabled={
                  !values.searchSerialNumber && !values.searchMacAddress && !values.searchLabel
                }
                css={{
                  width: '100%',
                }}
                onClick={() => setResultsShown(true)}>
                Check
              </BsButton>
            </>
          )}
        </>
      ) : (
        <>
          <div className="mt-4 d-flex">
            <CheckBoxOutlinedIcon className={'mr-2 text-success'} />
            <h5 className={'font-weight-600'}>Device Information</h5>
          </div>
          {editDevice ? (
            <CreateDevice
              id={devices?.items?.[0]?.id}
              orderId={Number(orderId)}
              setValues={setValues}
              close={() => setEditDevice(false)}
              startShipping={startShipping}
              allowedDevices={allowedDevices}
            />
          ) : (
            <span className={'d-flex align-items-center'}>
              {devices?.items?.[0]
                ? getLabel(devices.items[0])
                : `Serial: ${thirdPartyDevice?.serial}`}
              <BsButton
                variant={'outline-primary'}
                className={'ml-2'}
                css={{
                  height: 30,
                }}
                disabled={!!thirdPartyDevice?.transactionId}
                onClick={() => {
                  setEditDevice(true)
                }}>
                Edit
              </BsButton>
            </span>
          )}
        </>
      )}
    </div>
  )
}

export default DeviceSelection

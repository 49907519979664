import { ReactNode } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

const Root = styled('div')({
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  minHeight: '100%',
})

interface IProps {
  children: ReactNode
}

const Loader = ({ children }: IProps) => {
  return (
    <Root>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <CircularProgress color="secondary" sx={{ mb: 4 }} />
        {children}
      </Box>
    </Root>
  )
}

export default Loader

import { ReactNode } from 'react'
import TabHeader from './TabHeader'

interface IProps {
  title: string
  className?: string
  actionButtons?: ReactNode
  content: ReactNode
}

const ContentBoxComponent = (props: IProps) => {
  const title = props?.title ?? ''
  const className = props?.className ?? ''
  const actionButtons = props?.actionButtons ?? ''

  return (
    <section
      className={`container-fluid px-3 mb-3 ml-2 tsBoxBorder tsBoxShadow bg-white ${className}`}
    >
      {title ? (
        <>
          <TabHeader
            title={title || ''}
            actionButtons={[actionButtons || '']}
            className="font-weight-bold"
          />
          <hr className="mt-0" />
        </>
      ) : (
        ''
      )}
      <div className="row">
        <div className="col-sm-12">{props.content}</div>
      </div>
    </section>
  )
}

export default ContentBoxComponent

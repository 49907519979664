/** @jsxImportSource @emotion/react */
import moment from 'moment'

import * as generalHelper from 'helpers/generalHelper'
import { Physician } from 'store/types'

interface IProps {
  physician: Physician
  addressUuid: string
  physicianHistory: any[]
}

export const AddressHistory = ({ physician, addressUuid, physicianHistory }: IProps) => {
  const addressHistory = physicianHistory?.filter((i) => i?.data?.uuid === addressUuid)

  const by = (description: string, user: { firstName: string; lastName: string } | undefined) => {
    return (
      <>
        {description} {user ? `${user.firstName} ${user.lastName}` : 'unknown'}
      </>
    )
  }

  return (
    <div className="mt-2">
      Record History:
      {addressHistory?.map(({ eventName, data, user, timestamp }) => (
        <div className="d-flex align-items-end flex-column mb-2">
          <div className="row border rounded justify-content-between m-0 pr-2 w-100">
            <div className="col-1 d-flex align-items-center ml-3">
              <i className="text-danger fa fa-user-md" />
            </div>
            <div className="col-2 d-flex align-items-center">{physician.npi}</div>
            <div className="col-3 d-flex align-items-center text-danger">
              {physician.firstName} {physician.lastName} {physician.credential}
            </div>

            <div className="col-5 pr-0">
              <div className="py-1 mb-2 d-flex">
                <div>
                  <div>
                    {data?.addressStreetLine1}
                    {', '}
                    {data?.addressStreetLine2 ? data?.addressStreetLine2 + ', ' : ''}
                    {data?.addressCity}
                    {', '}
                    {data?.addressState}
                  </div>
                  <div className="pb-2">
                    {data?.telephone && <strong>{generalHelper.t('Tel')}: </strong>}
                    {data?.telephone}
                    {data?.fax && (
                      <>
                        <br />
                        <strong>{generalHelper.t('Fax')}: </strong>
                      </>
                    )}
                    {data?.fax}{' '}
                  </div>
                  {data?.comment && (
                    <div className="text-gray" css={{ fontStyle: 'italic' }}>
                      {data?.comment}
                    </div>
                  )}
                </div>
                {/* <div className="col text-right d-flex align-items-center justify-content-end pr-0">
                  <Button variant="outline-danger" onClick={() => {}}>
                    Take this
                  </Button>
                </div> */}
              </div>
            </div>
          </div>
          <div>
            {eventName === 'PhysicianAddressImportedEvent' && `Imported from NPI`}
            {eventName === 'PhysicianAddressUpdatedEvent' && by('Suggested edit by', user)}
          </div>
          <div className="text-gray">{moment(timestamp).format('MM/DD/YYYY hh:mm a')}</div>
        </div>
      ))}
    </div>
  )
}

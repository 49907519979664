/** @jsxImportSource @emotion/react */
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'hooks/redux'

import { getLogout } from 'store/slice/loginSlice'
import { confirm } from 'components/molecules/Confirmation'
import Loader from 'components/atoms/Loader'

const Nav = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const profile = useAppSelector((state) => state.init.profile)

  const handleLogout = async () => {
    try {
      await confirm('', {
        title: 'Are you sure?',
        description: 'Are you sure you want to logout?',
        yesAction: 'Yes',
        noAction: 'No',
      })
      dispatch(getLogout())
      navigate('../login')
    } catch {}
  }

  return (
    <nav
      className={`main-header ${Loader()} navbar navbar-expand navbar-white navbar-light p-0`}>
      <ul className="nav navbar-nav mr-auto d-flex align-items-center tsNav">
        <li className="nav-item tsNav__menu">
        </li>
      </ul>
      <ul className="navbar-nav d-flex align-items-center ml-auto">
        <i className={"fas fa-user mr-3 textGray"} css={{ fontSize: 18 }} />
        <li className="nav-item mr-2 text-right">
          {profile?.firstName && profile?.lastName && (
            <span className="textGray">
              {' '}
              {profile.firstName} {profile.lastName}
            </span>
          )}
        </li>
        <li className="nav-item">
          <a className="nav-link d-flex align-items-center" href="#" role="button" onClick={handleLogout}>
            <i className="fas fa-sign-out-alt" />
          </a>
        </li>
      </ul>
    </nav>
  )
}

export default Nav

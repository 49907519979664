/** @jsxImportSource @emotion/react */
import { Button } from 'react-bootstrap'
import Table from 'react-bootstrap/Table'

import routes from 'components/dme/constants/routes'
import DashboardContent from '../atoms/DashboardContent'
import CalloutComponent from '../../atoms/Callout'
import LinkBtn from 'components/atoms/LinkBtn'

import {
  READER_SETUP,
  ONE_TRIP_DRIVER,
  KEYSPAN_DRIVER,
  NONIN_DRIVER,
  PROLIFIC_DRIVER,
  FTDI_DRIVER,
  INSTRUCTIONS,
  READER_SETUP_ADMIN,
  ONE_TRIP_DRIVER_ADMIN,
  KEYSPAN_DRIVER_ADMIN,
  NONIN_DRIVER_ADMIN,
  PROLIFIC_DRIVER_ADMIN,
  FTDI_DRIVER_ADMIN,
} from 'constants/readerFileUrls'

const HelpDownload = () => {
  return (
    <DashboardContent
      title="Downloads"
      icon="fa fa-cloud-download-alt"
      content={
        <div className="col-12" css={{ '& a:hover': { color: 'blue' } }}>
          <div className="card card-default">
            <div className="card-body">
              <CalloutComponent
                title="Installation Instructions"
                className="info"
                content={
                  <div css={{ fontSize: 16, lineHeight: '22px' }}>
                    We've prepared a step-by-step installation guide to help you get started.
                    <br />
                    You can also watch our video tutorial to learn how to get set up in under three
                    minutes!
                    <br />
                    <LinkBtn
                      label={
                        <>
                          <i className="fa fa-video mr-2" /> Watch Video Tutorial
                        </>
                      }
                      className="btn btn-warning mt-4 mr-5 text-decoration-none"
                      to={`${routes.index}${routes.help.videos}`}
                    />
                    <Button
                      className="btn btn-warning mt-4 text-decoration-none"
                      href={INSTRUCTIONS}
                      target="_blank">
                      <i className="fa fa-download mr-2" /> Read Installation Guide
                    </Button>
                  </div>
                }
              />
              <CalloutComponent
                title="TestSmarter Reader"
                className="info mt-4"
                content={
                  <div css={{ fontSize: 16, lineHeight: '22px' }}>
                    To upload and reset devices on our website, you must have the TestSmarter Reader
                    app installed on your computer.
                    <br />
                    <Button
                      className="mt-4 text-white text-decoration-none"
                      href={READER_SETUP}
                      target="_blank">
                      <i className="fa fa-download mr-2" /> Download TestSmarter Reader
                    </Button>
                  </div>
                }
              />
              <CalloutComponent
                title="Driver Packs"
                className="info mt-4"
                content={
                  <div css={{ fontSize: 16, lineHeight: '22px' }}>
                    Next, you need to install the Driver Pack for each type of device you will be
                    using with our system.
                    <br />
                    <br />
                    <h4>Device Type</h4>
                    <div className="row align-items-center mt-4">
                      <div className="col-3" css={{ maxWidth: 'unset' }}>
                        <Button
                          css={{
                            minWidth: '280px',
                            color: 'white !important',
                            textDecoration: 'none !important',
                          }}
                          href={ONE_TRIP_DRIVER}
                          target="_blank">
                          <i className="fa fa-download mr-2" /> Download OneTrip Driver Pack
                        </Button>
                      </div>
                      <div className="col-6">TestSmarter OneTrip and similar Devices</div>
                    </div>
                    <div className="row align-items-center mt-4">
                      <div className="col-3" css={{ maxWidth: 'unset' }}>
                        <Button
                          css={{
                            minWidth: '280px',
                            color: 'white !important',
                            textDecoration: 'none !important',
                          }}
                          href={FTDI_DRIVER}
                          target="_blank">
                          <i className="fa fa-download mr-2" /> Download FTDI Driver Pack
                        </Button>
                      </div>
                      <div className="col-6">
                        920M, 920M Plus, Nonin Model 7500 and Masimo Rad-97
                      </div>
                    </div>
                    <div className="row align-items-center mt-4">
                      <div className="col-3" css={{ maxWidth: 'unset' }}>
                        <Button
                          css={{
                            minWidth: '280px',
                            color: 'white !important',
                            textDecoration: 'none !important',
                          }}
                          href={NONIN_DRIVER}
                          target="_blank">
                          <i className="fa fa-download mr-2" /> Download Nonin Driver Pack
                        </Button>
                      </div>
                      <div className="col-6">Nonin WristOx 3150</div>
                    </div>
                    <div className="row align-items-center mt-4">
                      <div className="col-3" css={{ maxWidth: 'unset' }}>
                        <Button
                          css={{
                            minWidth: '280px',
                            color: 'white !important',
                            textDecoration: 'none !important',
                          }}
                          href={KEYSPAN_DRIVER}
                          target="_blank">
                          <i className="fa fa-download mr-2" /> Download Keyspan Driver Pack
                        </Button>
                      </div>
                      <div className="col-6">Keyspan USB-to-Serial Adapters</div>
                    </div>
                    <div className="row align-items-center mt-4">
                      <div className="col-3" css={{ maxWidth: 'unset' }}>
                        <Button
                          css={{
                            minWidth: '280px',
                            color: 'white !important',
                            textDecoration: 'none !important',
                          }}
                          href={PROLIFIC_DRIVER}
                          target="_blank">
                          <i className="fa fa-download mr-2" /> Download Prolific Driver Pack
                        </Button>
                      </div>
                      <div className="col-6">Prolific USB-to-Serial Adapters</div>
                    </div>
                    <br />
                    If you need assistance downloading or installing any of these items, please call
                    us at (386) 382-4200 or submit a ticket.
                  </div>
                }
              />
              <CalloutComponent
                title="IT / Support Admins"
                className="info mt-4"
                content={
                  <div css={{ fontSize: 16, lineHeight: '22px' }}>
                    We know you already have a lot on your plate, so we've prepared separate
                    resources to help save you time! These versions run silently and should be
                    compatible with any MSI-friendly deployment or package management platform. To
                    ensure that everything goes smoothly, be sure to include the /qn switch with any
                    msiexec commands, e.g.:
                    <br />
                    <br />
                    <code
                      css={{
                        background: '#2a2b2d',
                        color: '#ffcc00',
                        fontSize: '13px',
                        padding: '0.5em 1em',
                        borderRadius: '4px',
                      }}>
                      MSIExec /I TestSmarterReaderSetup.msi /qn
                    </code>
                    <br />
                    <Table striped={true}>
                      <thead>
                        <tr>
                          <th>Description</th>
                          <th>Links</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>TestSmarter Reader Installer</td>
                          <td>
                            <a href={READER_SETUP_ADMIN}>TestSmarterReaderSetup-22.11.21.222.msi</a>
                          </td>
                          <td>
                            <a href={`${READER_SETUP_ADMIN}.md5`}>[md5]</a>
                            <a href={`${READER_SETUP_ADMIN}.sha1`}>[sha1]</a>
                            <a href={`${READER_SETUP_ADMIN}.sha256`}>[sha256]</a>
                            <a href={`${READER_SETUP_ADMIN}.sha512`}>[sha512]</a>
                          </td>
                        </tr>
                        <tr>
                          <td>Device drivers for TestSmarter OneTrip and similar devices</td>
                          <td>
                            <a href={ONE_TRIP_DRIVER_ADMIN}>OneTripDriverPackSetup-22.11.17.msi</a>
                          </td>
                          <td>
                            <a href={`${ONE_TRIP_DRIVER_ADMIN}.md5`}>[md5]</a>
                            <a href={`${ONE_TRIP_DRIVER_ADMIN}.sha1`}>[sha1]</a>
                            <a href={`${ONE_TRIP_DRIVER_ADMIN}.sha256`}>[sha256]</a>
                            <a href={`${ONE_TRIP_DRIVER_ADMIN}.sha512`}>[sha512]</a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Device drivers for 920M, 920M Plus, Nonin Model 7500 and Masimo Rad-97
                          </td>
                          <td>
                            <a href={FTDI_DRIVER_ADMIN}>FTDIDriverPackSetup-22.11.17.msi</a>
                          </td>
                          <td>
                            <a href={`${FTDI_DRIVER_ADMIN}.md5`}>[md5]</a>
                            <a href={`${FTDI_DRIVER_ADMIN}.sha1`}>[sha1]</a>
                            <a href={`${FTDI_DRIVER_ADMIN}.sha256`}>[sha256]</a>
                            <a href={`${FTDI_DRIVER_ADMIN}.sha512`}>[sha512]</a>
                          </td>
                        </tr>
                        <tr>
                          <td>Device drivers for Nonin WristOx 3150</td>
                          <td>
                            <a href={NONIN_DRIVER_ADMIN}>NoninDriverPackSetup-22.11.17.msi</a>
                          </td>
                          <td>
                            <a href={`${NONIN_DRIVER_ADMIN}.md5`}>[md5]</a>
                            <a href={`${NONIN_DRIVER_ADMIN}.sha1`}>[sha1]</a>
                            <a href={`${NONIN_DRIVER_ADMIN}.sha256`}>[sha256]</a>
                            <a href={`${NONIN_DRIVER_ADMIN}.sha512`}>[sha512]</a>
                          </td>
                        </tr>
                        <tr>
                          <td>Device drivers for Keyspan USB-to-Serial Adapters</td>
                          <td>
                            <a href={KEYSPAN_DRIVER_ADMIN}>KeyspanDriverPackSetup-22.11.17.msi</a>
                          </td>
                          <td>
                            <a href={`${KEYSPAN_DRIVER_ADMIN}.md5`}>[md5]</a>
                            <a href={`${KEYSPAN_DRIVER_ADMIN}.sha1`}>[sha1]</a>
                            <a href={`${KEYSPAN_DRIVER_ADMIN}.sha256`}>[sha256]</a>
                            <a href={`${KEYSPAN_DRIVER_ADMIN}.sha512`}>[sha512]</a>
                          </td>
                        </tr>
                        <tr>
                          <td>Device drivers for Prolific USB-to-Serial Adapters</td>
                          <td>
                            <a href={PROLIFIC_DRIVER_ADMIN}>ProlificDriverPackSetup-22.11.17.msi</a>
                          </td>
                          <td>
                            <a href={`${PROLIFIC_DRIVER_ADMIN}.md5`}>[md5]</a>
                            <a href={`${PROLIFIC_DRIVER_ADMIN}.sha1`}>[sha1]</a>
                            <a href={`${PROLIFIC_DRIVER_ADMIN}.sha256`}>[sha256]</a>
                            <a href={`${PROLIFIC_DRIVER_ADMIN}.sha512`}>[sha512]</a>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                }
              />
            </div>
          </div>
        </div>
      }
      breadcrumb={[{ label: 'Downloads' }]}
    />
  )
}

export default HelpDownload

/** @jsxImportSource @emotion/react */
import { ChangeEvent, CSSProperties } from 'react'
import Button from 'react-bootstrap/Button'
import { FieldInputProps } from 'formik'

interface IProps {
  value: boolean
  label: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  style?: CSSProperties
  disabled?: boolean
  variant?: string
}

export const ToggleButton = ({ value, label, onChange, style, variant, disabled }: IProps) => {
  const checkedVariant = variant ? variant : 'primary'
  const uncheckedVariant = variant ? `outline-${variant}` : 'outline-primary'

  return (
    <div
      css={{
        width: '100%',
        '& .btn': { width: '100%' },
        ...style,
      }}>
      <Button
        variant={value ? checkedVariant : uncheckedVariant}
        onClick={() => onChange(!value)}
        disabled={disabled}>
        {label}
      </Button>
    </div>
  )
}

export const ToggleButtonInput = ({
  field,
  form,
  ...props
}: {
  form: any
  field: FieldInputProps<string>
  label: string
}) => <ToggleButton {...field} {...props} onChange={(v) => form.setFieldValue(field.name, v)} />

import * as generalHelper from '../../helpers/generalHelper'
import ButtonCancel from './ButtonCancel'

interface IProps {
  icon?: string
  title?: string
  description?: string
  btnLabel?: string
  btnIcon?: string
}

const ErrorCard = (props: IProps) => {
  const icon = props.icon ?? 'fas fa-robot'
  const title = props.title ?? 'Oops!'
  const description =
    props.description ??
    "Sorry we can't find that page or something has gone wrong..."
  const btnLabel = props.btnLabel ?? 'Go Back'
  const btnIcon = props.btnIcon ?? 'fa fa-arrow-left'

  return (
    <div className="card card-primary container-fluid">
      <div className="card-body">
        <div className="container-fluid">
          <div className="row d-flex mb-3">
            <div className="col-12 mt-5">
              <h1>
                <i className={icon}></i> {generalHelper.t(title)}
              </h1>
            </div>
            <div className="col-12 mt-3 mb-5">
              <h3>{generalHelper.t(description)}</h3>
            </div>
            <div className="col-12">
              <ButtonCancel label={btnLabel} icon={btnIcon} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ErrorCard

import { useState } from 'react'

interface IProps {
  id?: string
  buttonTypeClass?: string
  options: { label: string }[]
  onClick: (val: string) => void
  additionalText?: string
  initialValue?: string
}

const Dropdown = ({
  id,
  buttonTypeClass,
  options,
  onClick,
  initialValue,
  additionalText,
}: IProps) => {
  const [value, setValue] = useState(initialValue ?? options?.[0].label)
  const [index, setIndex] = useState(0)

  return (
    <div className="dropdown">
      <button
        className={`btn ${buttonTypeClass} dropdown-toggle`}
        type="button"
        id={id}
        data-toggle="dropdown">
        {`Show: ${value} ${additionalText ?? ''}`}
      </button>
      <div className="dropdown-menu">
        {options &&
          Array.isArray(options) &&
          options.map((opt, i) => {
            const active = i === index ? 'active' : ''
            return (
              <button
                className={`dropdown-item ${active}`}
                type="button"
                onClick={() => {
                  const buttonValue = event.target.innerHTML
                  setIndex(i)
                  setValue(buttonValue)
                  onClick(buttonValue)
                }}
                key={i}>
                {opt.label}
              </button>
            )
          })}
      </div>
    </div>
  )
}

export default Dropdown

import { useState, useMemo } from 'react'
import styled from '@emotion/styled'
import Select from 'react-select'
import { Button } from 'react-bootstrap'
import { keyBy } from 'lodash'

import { diagnosisCodes } from 'ts-common/constants/diagnosisCodes'

const keyedDiagCodes = keyBy(diagnosisCodes, 'value')

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

interface IProps {
  codes: string[]
  onAdd: (v: string) => void
  onRemove: (v: string) => void
  disabled?: boolean
}

export const DiagnosisCodes = ({ codes = [], onAdd, onRemove, disabled }: IProps) => {
  const [selectedCode, setSelectedCode] = useState<string>('')
  const selectableCodes = useMemo(
    () => diagnosisCodes.filter((i) => !codes.includes(i.value)),
    [codes],
  )

  const sortedCodes = [...codes].sort((a, b) => (a > b ? 1 : -1))

  return (
    <Container>
      <span style={{ fontWeight: '600' }}>
        PATIENT DIAGNOSIS <span className="text-danger">*</span>
      </span>
      <br />

      <span style={{ color: '#6D757E' }} className="mb-1">
        Diagnosis Code
      </span>
      <div className="d-flex w-100">
        <Select
          className="basic-single w-100 mr-3"
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 100 }) }}
          classNamePrefix="select"
          isClearable
          isSearchable
          placeholder="Select Diagnosis Code"
          name="Diagnosis Code"
          onChange={(item) => setSelectedCode(item?.value)}
          options={selectableCodes}
          isDisabled={codes.length >= 4}
          value={keyedDiagCodes[selectedCode] ?? null}
        />
        <Button
          disabled={!selectedCode || codes.length >= 4 || disabled}
          variant="primary"
          style={{ minWidth: 100 }}
          onClick={() => {
            if (selectedCode) {
              onAdd(selectedCode)
              setSelectedCode('')
            }
          }}>
          Add
        </Button>
      </div>
      {Array.isArray(codes) && (
        <>
          <div
            className="row flex-row flex-nowrap justify-content-between align-items-center p-2 my-2"
            style={{
              color: '#6D757E',
              borderBottom: '1px solid #ced4da',
            }}>
            <div style={{ width: '100%' }}>Diagnosis Code</div>
            <div className="flex-shrink-0 pr-3" style={{ width: 100, textAlign: 'end' }}>
              Action
            </div>
          </div>
          {sortedCodes?.length ? (
            sortedCodes.map((code) => (
              <div className="row my-1" key={code}>
                <div className="col-sm-12 p-0">
                  <div className="list-group text-secondary">
                    <div className="list-group-item list-group-item-action flex-column align-items-start">
                      <div className="d-flex py-1 justify-content-between align-items-center">
                        <span>{keyedDiagCodes[code]?.label ?? code}</span>
                        <Button
                          variant="primary"
                          size="sm"
                          onClick={() => onRemove(code)}
                          disabled={disabled}>
                          Remove
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="row my-1 mt-2">
              <div className="col-sm-12 p-0">
                <div className="list-group text-secondary">
                  <div className="list-group-item list-group-item-action flex-column align-items-center">
                    <div className="d-flex py-1 justify-content-center align-items-center">
                      <span>No diagnosis codes entered</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </Container>
  )
}

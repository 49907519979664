import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../atoms/Table";
import DashboardContent from "../atoms/DashboardContent";
import { getRoutes } from "../../../store/slice/routeSlice";
import ButtonNew from "../../atoms/ButtonNew";
import routes from "../constants/routes";

export default function (props) {
    const rows = useSelector((state) => state.route.rows);
    const office_id = useSelector((state) => state.init.office_id);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getRoutes({ no_cache: 1 }));
    }, [office_id]);
    function Content(props) {
        const header = [
            {
                key: "id",
                to: `${routes.index}${routes.route.root}${routes.route.edit}`,
                label: "Id",
            },
            {
                key: "name",
                label: "Route",
            },
            { key: "subject", label: "Driver" },
            { key: "status", label: "Date" },
            { key: "created_at", label: "Orders" },
        ];
        return (
            <div className="card card-primary">
                <form type="post">
                    <div className="card-header">
                        <h3 className="card-title"></h3>
                        <div className="card-tools">
                            <ButtonNew
                                to={`${routes.index}${routes.route.root}${routes.upsert}`}
                            />
                        </div>
                    </div>
                    <div className="card-body row">
                        <Table header={header} rows={rows} />
                    </div>
                </form>
            </div>
        );
    }

    return (
        <DashboardContent
            title="Routes"
            icon="fas fa-shipping-fast"
            content={Content(props)}
            breadcrumb={[{ label: "Routes" }]}
        />
    );
}

import { SyntheticEvent, useState } from 'react'
import Button from 'react-bootstrap/Button'
import { pick } from 'lodash'

import {
  useAssignLabPhysicianMutation,
  useRemoveLabPhysicianMutation,
} from 'store/services/labOrder'
import { useAppDispatch } from 'hooks/redux'
import {
  useImportPhysicianMutation,
  useUpdatePhysicianMutation,
  useCreatePhysicianAddressMutation,
  useUpdatePhysicianAddressMutation,
} from 'store/services/physician'
import { setModal } from 'store/slice/modalSlice'
import { confirm } from 'components/molecules/Confirmation'
import { PhysicianSearch, SearchFormValues } from 'components/organisms/physician/PhysicianSearch'
import { Order, Physician, PhysicianAddress } from 'store/types'
import { PhysicianReview } from './physician/PhysicianReview'

interface IProps {
  order: Order
  allowEdits: boolean
}

export const PhysicianSection = ({ order, allowEdits }: IProps) => {
  const dispatch = useAppDispatch()
  const [searchValues, setSearchValues] = useState<SearchFormValues>({})
  const [resultsLimit, setResultsLimit] = useState(4)
  const [selectedLatestPhy, setSelectedLatestPhy] = useState('')

  const [assignPhysician] = useAssignLabPhysicianMutation()
  const [removePhysician] = useRemoveLabPhysicianMutation()
  const [importPhysician] = useImportPhysicianMutation()
  const [updatePhysician] = useUpdatePhysicianMutation()
  const [createPhysicianAddress] = useCreatePhysicianAddressMutation()
  const [updatePhysicianAddress] = useUpdatePhysicianAddressMutation()
  const orderId = Number(order?.id)
  const orderOfficeId = order?.officeId.toString()
  const orderPhysicianId = order?.physician?.id

  const handleClear = (event?: SyntheticEvent) => {
    if (event) {
      event.preventDefault()
    }
    setSearchValues({})
    setSelectedLatestPhy('')
    setResultsLimit(0)
  }

  const setPhysician = async (physician: Physician, location: PhysicianAddress) => {
    if (!orderId) return
    let physicianId = physician.id
    let addressUuid = location.uuid
    if (!physicianId) {
      const { data } = await importPhysician({
        by: 'lab',
        officeId: orderOfficeId,
        ...pick(physician, ['firstName', 'lastName', 'npi', 'credential']),
        address: location,
      })
      physicianId = data.id
      addressUuid = data.addresses[0].uuid
    } else if (!location.uuid) {
      const { data } = await createPhysicianAddress({
        by: 'lab',
        officeId: orderOfficeId,
        physicianId,
        ...location,
      })
      addressUuid = data.uuid
    }

    const payload = {
      orderId: Number(orderId),
      physicianId,
      addressUuid,
    }
    assignPhysician(payload)
  }

  const updatePhysicianData = async (physician: Partial<Physician>, location: PhysicianAddress) => {
    if (!orderId) return
    let addressUuid = location.uuid

    await updatePhysician({
      by: 'lab',
      id: orderPhysicianId,
      npi: order.physician.npi,
      officeId: orderOfficeId,
      ...pick(physician, ['firstName', 'lastName', 'credential']),
    })

    const payload = {
      by: 'lab',
      officeId: orderOfficeId,
      physicianId: orderPhysicianId,
      ...location,
    }
    if (addressUuid) {
      await updatePhysicianAddress(payload)
    } else {
      const { data } = await createPhysicianAddress(payload)
      addressUuid = data.uuid
    }

    assignPhysician({
      orderId: Number(orderId),
      physicianId: orderPhysicianId,
      addressUuid,
    })
  }

  const openPhysicianModal = (
    physician: Physician,
    indexLocation: number | null,
    preValidate = false,
  ) => {
    dispatch(
      setModal({
        open: true,
        physician,
        indexLocation,
        setPhysician,
        preValidate,
      }),
    )
  }

  return !order?.physician?.id ? (
    <PhysicianSearch
      usedBy="lab"
      order={order}
      searchValues={searchValues}
      setSearchValues={setSearchValues}
      resultsLimit={resultsLimit}
      setPhysician={setPhysician}
      setResultsLimit={setResultsLimit}
      handleClear={handleClear}
      selectedLatestPhy={selectedLatestPhy}
      setSelectedLatestPhy={setSelectedLatestPhy}
      currentOfficeId={orderOfficeId}
      openPhysicianModal={openPhysicianModal}
    />
  ) : (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-2 col-sm-12">
        <h6 className="m-0">PHYSICIAN INFORMATION</h6>
        <Button
          variant="outline-danger"
          disabled={!allowEdits}
          onClick={() =>
            confirm('physician').then(() => {
              if (orderId) {
                removePhysician({ orderId })
              }
            })
          }>
          Remove Physician
        </Button>
      </div>
      <PhysicianReview
        order={order}
        allowEdits={allowEdits}
        updatePhysician={updatePhysicianData}
        setPhysician={setPhysician}
      />
    </div>
  )
}

/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled'
import DateTimePicker, { CustomDateInput } from 'components/atoms/DateTimePicker'

import Input from 'components/atoms/Input'
import { ToggleButton } from 'components/atoms/ToggleButton'
import { Form, Formik } from 'formik'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import { Order } from 'store/types'
import * as Yup from 'yup'

const TimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;

  .react-datepicker-wrapper {
    flex: 1;

    input {
      width: 100%;
      padding: 7px 8px;
    }
    svg {
      top: 9px !important;
      right: 7px !important;
    }
    button {
      margin-right: 20px;
    }
  }
`

export type FormValues = {
  authorizationRequired: boolean
  authorizationStarted: boolean
  authorizationApproved: boolean
  authorizationDenied: boolean
  authorizationNumber: string
  copayAmount: string | number
  notes: string
  followUpDate: Date | null
  authorizationState: string
  authorizationValidStartDate: string
  authorizationValidEndDate: string
}

const formValidation = Yup.object().shape({
  authorizationNumber: Yup.string().when('authorizationApproved', (authorizationApproved) => {
    if (authorizationApproved) {
      return Yup.string().required('Required')
    }
  }),
  notes: Yup.string().when('authorizationDenied', (authorizationDenied) => {
    if (authorizationDenied) {
      return Yup.string().required('Required')
    }
  }),
  authorizationValidStartDate: Yup.string()
    .nullable()
    .when('authorizationApproved', (authorizationApproved) => {
      if (authorizationApproved) {
        return Yup.string().nullable().required('Required')
      }
    }),
  authorizationValidEndDate: Yup.string()
    .nullable()
    .when('authorizationApproved', (authorizationApproved) => {
      if (authorizationApproved) {
        return Yup.string().nullable().required('Required')
      }
    }),
})

interface IProps {
  order?: Order
  onSubmit: (values: FormValues) => void
  setDirty?: (value: boolean) => void
  formikRef?: any
}

export const PreAuthorizationForm = ({
  onSubmit,
  setDirty = () => {},
  order,
  formikRef,
}: IProps) => {
  const followUpDate =
    order?.payersInfo?.followUpDate && moment(order?.payersInfo?.followUpDate).isValid()
      ? moment(order?.payersInfo?.followUpDate).toDate()
      : null

  return (
    <Formik
      innerRef={formikRef}
      enableReinitialize={true}
      validationSchema={formValidation}
      initialValues={{
        authorizationRequired:
          order?.state?.AUTHORIZATION && order?.state?.AUTHORIZATION !== 'NOT_REQUIRED',
        authorizationStarted:
          order?.state?.AUTHORIZATION &&
          !['NOT_REQUIRED', 'PENDING'].includes(order?.state?.AUTHORIZATION),
        authorizationApproved: order?.state?.AUTHORIZATION === 'PROVIDED',
        authorizationDenied: order?.state?.AUTHORIZATION === 'DENIED',
        authorizationNumber: order?.payersInfo?.authorizationNumber ?? '',
        notes: order?.payersInfo?.notes ?? '',
        copayAmount: order?.copay ?? '',
        followUpDate,
        authorizationValidStartDate: order?.payersInfo?.authorizationValidStartDate
          ? moment(order?.payersInfo?.authorizationValidStartDate).toDate()
          : null,
        authorizationValidEndDate: order?.payersInfo?.authorizationValidEndDate
          ? moment(order?.payersInfo?.authorizationValidEndDate).toDate()
          : null,
      }}
      onSubmit={async (values, { resetForm }) => {
        let authorizationState
        if (!values.authorizationRequired) {
          authorizationState = 'NOT_REQUIRED'
        } else if (!values.authorizationStarted) {
          authorizationState = 'PENDING'
        } else if (values.authorizationApproved) {
          authorizationState = 'PROVIDED'
        } else if (values.authorizationDenied) {
          authorizationState = 'DENIED'
        } else {
          authorizationState = 'STARTED'
        }

        await onSubmit({
          ...values,
          authorizationState,
          authorizationValidStartDate: values.authorizationValidStartDate
            ? values.authorizationValidStartDate.toISOString().slice(0, 10)
            : '',
          authorizationValidEndDate: values.authorizationValidEndDate
            ? values.authorizationValidEndDate.toISOString().slice(0, 10)
            : '',
        })
        resetForm()
      }}>
      {({ values, setFieldValue, resetForm, errors: formikErrors, touched, setTouched }) => (
        <Form id="insuranceInfo" className="container-fluid">
          <div className="row pl-2 mb-3">
            <div className="col-sm-3 d-flex align-items-center">
              <ToggleButton
                value={values.authorizationRequired}
                style={{ paddingRight: '15px' }}
                label="Auth Required"
                onChange={() => {
                  if (values.authorizationRequired) {
                    setFieldValue('authorizationDenied', false)
                    setFieldValue('authorizationApproved', false)
                    setFieldValue('authorizationStarted', false)
                    setFieldValue('authorizationRequired', false)
                    setFieldValue('authorizationNumber', '')
                  } else {
                    setFieldValue('authorizationRequired', true)
                  }
                  setDirty(true)
                }}
              />
              {values.authorizationRequired && (
                <div css={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                  <i className="fa fa-chevron-right" />
                </div>
              )}
            </div>
            {values.authorizationRequired && (
              <>
                <div className="col-sm-3 d-flex align-items-center">
                  <ToggleButton
                    value={values.authorizationStarted}
                    style={{ paddingRight: '15px' }}
                    label={values.authorizationStarted ? 'Auth Started' : 'Start Auth'}
                    disabled={!values.authorizationRequired}
                    onChange={() => {
                      if (values.authorizationStarted) {
                        setFieldValue('authorizationDenied', false)
                        setFieldValue('authorizationApproved', false)
                        setFieldValue('authorizationStarted', false)
                      } else {
                        setFieldValue('authorizationStarted', true)
                      }
                      setDirty(true)
                    }}
                  />
                  {values.authorizationStarted && (
                    <div css={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                      <i className="fa fa-chevron-right" />
                    </div>
                  )}
                </div>
                {values.authorizationStarted && (
                  <>
                    <div className="col-sm-3 d-flex align-items-center">
                      <ToggleButton
                        label="Approved"
                        variant="success"
                        disabled={!values.authorizationStarted}
                        value={values.authorizationApproved}
                        onChange={() => {
                          if (!values.authorizationApproved) {
                            setFieldValue('authorizationDenied', false)
                            setFieldValue('authorizationApproved', true)
                          } else {
                            setFieldValue('authorizationApproved', false)
                          }
                          setTouched({})
                          setDirty(true)
                        }}
                      />
                    </div>
                    <div className="col-sm-3 d-flex align-items-center">
                      <ToggleButton
                        variant="danger"
                        label="Denied"
                        disabled={!values.authorizationStarted}
                        value={values.authorizationDenied}
                        onChange={() => {
                          if (!values.authorizationDenied) {
                            setFieldValue('authorizationDenied', true)
                            setFieldValue('authorizationApproved', false)
                          } else {
                            setFieldValue('authorizationDenied', false)
                          }
                          setTouched({})
                          setDirty(true)
                        }}
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </div>
          <div className="row mb-2">
            {(order?.testType === 'HST' || order?.testType === 'PSG') && (
              <div className="col-sm-6">
                <label className="mb-1 textGray">
                  Copay Amount <span className="text-danger">*</span>
                </label>
                <div className="d-flex position-relative input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">$</span>
                  </div>
                  <Input
                    type="number"
                    parentDiv={null}
                    name="copayAmount"
                    disabled={false}
                    className="form-control"
                    placeholder="Copay Amount"
                    value={values.copayAmount}
                    handleChange={(e) => {
                      setFieldValue('copayAmount', e.target.value)
                      setDirty(true)
                    }}
                    css={{
                      width: '100%',
                      height: 'unset !important',
                      borderColor:
                        formikErrors.copayAmount && touched.copayAmount ? 'red' : undefined,
                    }}
                  />
                  {formikErrors.copayAmount && touched.copayAmount && (
                    <label
                      css={{
                        color: 'red',
                        position: 'absolute',
                        bottom: '-25px',
                        left: '8px',
                        fontWeight: 'normal !important',
                      }}>
                      {formikErrors.copayAmount}
                    </label>
                  )}
                </div>
              </div>
            )}
            <div className="col-sm-6 d-flex">
              <TimeWrapper>
                <div style={{ fontWeight: '500' }} className="mb-1">
                  Follow Up
                </div>
                <DateTimePicker
                  value={values.followUpDate}
                  handleChange={(date) => {
                    setFieldValue('followUpDate', date)
                    setDirty(true)
                  }}
                  minDate={new Date()}
                  showTimeSelect={false}
                  isClearable
                  dateFormat="MMMM d, yyyy"
                />
              </TimeWrapper>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-sm-6">
              <label className="mb-1 textGray">Authorization Number</label>
              <Input
                parentDiv={null}
                name="authorizationNumber"
                disabled={!values.authorizationRequired || !values.authorizationStarted}
                className="form-control"
                placeholder="Authorization Number"
                value={values.authorizationNumber}
                handleChange={(e) => {
                  setFieldValue('authorizationNumber', e.target.value)
                  setDirty(true)
                }}
                css={{
                  width: '100%',
                  height: 'unset !important',
                  borderColor:
                    formikErrors.authorizationNumber && touched.authorizationNumber
                      ? 'red'
                      : undefined,
                }}
              />
              {formikErrors.authorizationNumber && touched.authorizationNumber && (
                <label
                  css={{
                    color: 'red',
                    position: 'absolute',
                    bottom: '-25px',
                    left: '8px',
                    fontWeight: 'normal !important',
                  }}>
                  {formikErrors.authorizationNumber}
                </label>
              )}
            </div>
            <div className="col-sm-6 d-flex">
              <TimeWrapper>
                <div style={{ fontWeight: '500' }} className="mb-1">
                  Authorization Valid
                </div>
                <DatePicker
                  selectsRange={true}
                  startDate={values.authorizationValidStartDate}
                  endDate={values.authorizationValidEndDate}
                  onChange={(update) => {
                    setFieldValue('authorizationValidStartDate', update[0])
                    setFieldValue('authorizationValidEndDate', update[1])
                  }}
                  customInput={<CustomDateInput />}
                  isClearable
                />
              </TimeWrapper>
              {((formikErrors.authorizationValidStartDate && touched.authorizationValidStartDate) ||
                (formikErrors.authorizationValidEndDate && touched.authorizationValidEndDate)) && (
                <label
                  css={{
                    color: 'red',
                    position: 'absolute',
                    bottom: '-25px',
                    left: '8px',
                    fontWeight: 'normal !important',
                  }}>
                  {formikErrors.authorizationValidStartDate ||
                    formikErrors.authorizationValidEndDate}
                </label>
              )}
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-sm-12 d-flex">
              <textarea
                name="notes"
                className="form-control mt-1"
                placeholder="Notes"
                value={values.notes}
                onChange={(e) => {
                  setFieldValue('notes', e.target.value)
                  setDirty(true)
                }}
                css={{
                  borderColor: formikErrors.notes && touched.notes ? 'red' : undefined,
                }}
              />
              {formikErrors.notes && touched.notes && (
                <label
                  css={{
                    color: 'red',
                    position: 'absolute',
                    bottom: '-25px',
                    left: '8px',
                    fontWeight: 'normal !important',
                  }}>
                  {formikErrors.notes}
                </label>
              )}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

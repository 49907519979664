import axios from 'axios'

import config from 'config/app'

const host = config?.newApi

interface Token {
  language?: string
  access_token: string
  refresh_token: string
  office_id?: string
}

export const setImpersonateToken = (data: Token) => {
  try {
    const labToken = localStorage.getItem('access_token')
    const labRefreshToken = localStorage.getItem('refresh_token')
    if (data?.access_token && labToken && labRefreshToken) {
      localStorage.setItem('lab_access_token', labToken)
      localStorage.setItem('lab_refresh_token', labRefreshToken)
      localStorage.setItem('access_token', data.access_token)
      localStorage.setItem('refresh_token', data.refresh_token)
    }
  } catch (error) {
    console.log('error', error)
  }
}

export const revertImpersonateToken = async () => {
  try {
    const labToken = localStorage.getItem('lab_access_token')
    const labRefreshToken = localStorage.getItem('lab_refresh_token')
    const refreshToken = localStorage.getItem('refresh_token')
    if (refreshToken) {
      try {
        await revokeRefreshToken(refreshToken)
      } catch (error) {
        console.log('error', error)
      }
    }
    if (labToken && labRefreshToken) {
      localStorage.setItem('access_token', labToken)
      localStorage.setItem('refresh_token', labRefreshToken)
      localStorage.removeItem('lab_access_token')
      localStorage.removeItem('lab_refresh_token')
    }
  } catch (error) {
    console.log('error', error)
  }
}

export const setToken = (data: Token) => {
  try {
    if (data?.access_token) {
      localStorage.setItem('access_token', data.access_token)
    }
    if (data?.refresh_token) {
      localStorage.setItem('refresh_token', data.refresh_token)
    }
  } catch (error) {
    console.log('error', error)
  }
}

export const logout = async () => {
  try {
    localStorage.removeItem('office_id')
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
    localStorage.removeItem('lab_access_token')
    localStorage.removeItem('lab_refresh_token')
    localStorage.removeItem('persist:root')
  } catch (error) {
    console.log('error', error)
  }
}

export const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem('refresh_token')
  if (!refreshToken) {
    return
  }

  try {
    const returnValue = await axios.request({
      baseURL: host,
      url: `/auth/refresh`,
      method: 'POST',
      headers: { 'authorization': `Bearer ${refreshToken}` }
    })

    setToken(returnValue.data)
    return returnValue.data.access_token
  } catch (error) {
    console.log('error', error)
    logout()
    location.href = '/login'
  }
}

export const revokeRefreshToken = async (refreshToken: string) => refreshToken &&
  axios.request({
      baseURL: host,
      url: `/auth/revoke`,
      method: 'POST',
      headers: { 'authorization': `Bearer ${refreshToken}` }
    })

export const isLoggedIn = () => {
  const access_token = localStorage.getItem('access_token')
  const lab_access_token = localStorage.getItem('lab_access_token')
  return access_token || lab_access_token
}

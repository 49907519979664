import React from 'react'
import { useAppDispatch } from 'hooks/redux'
import * as generalHelper from '../../../helpers/generalHelper'
import Link from '../../atoms/Link'
import Loader from '../../atoms/Loader'
import routes from '../constants/routes'
import Moment from 'react-moment'
import { setLoader } from '../../../store/slice/loaderSlice'
import { getOrder } from '../../../store/slice/orderSlice'
import { getStatuses } from '../../../store/slice/statusSlice'
import { useTable, useSortBy } from 'react-table'
import { useState } from 'react'

import _ from 'lodash'
// import ReacTableFilter from '../forms/filters/ReactTableFilter'

interface IProps {
  type: string
  order_status: string
  patientReviewOnly: string
  deviceCategory: string
  queryName: string
  queryRefreshMs: number
  queryFn: () => void
}

const OrdersTable = (props: IProps) => {
  const type = props.type ? props.type : 'order'
  const order_status = props?.order_status ? props?.order_status : 'open'
  const reviewOnly = props?.patientReviewOnly ? props?.patientReviewOnly : false
  const deviceCategory = props.deviceCategory ? props.deviceCategory : []

  const [filterValues, setFilterValues] = useState({})
  const [sortValues, setSortValues] = useState({ id: 'id', desc: false })

  const queryCompundName = sortValues
    ? [props.queryName, sortValues]
    : [props.queryName, 0]
  const ReactQuery = useQuery(queryCompundName, props.queryFn, {
    refetchInterval: props.queryRefreshMs || 60000,
  })

  // Patient

  const dispatch = useAppDispatch()

  const handleModal = async row => {
    dispatch(setLoader(true))
    dispatch(getStatuses({ type: 'Order' }))
    dispatch(getOrder({ id: row.id })).then(() => {
      dispatch(setLoader(false))
    })
  }

  const dayClass = row => {
    const diff = generalHelper.dateDiff(row.created_at, 'today')
    if (diff > 10) {
      return 'btn-danger'
    } else if (diff > 4) {
      return 'btn-warning'
    } else {
      return 'btn-success'
    }
  }

  const getDeviceCategory = row => {
    if (row && row?.device_category) {
      return (
        <span className="font-weight-bold m-2">
          {row?.device_category?.name}
        </span>
      )
    }
  }

  const getOffice = row => {
    if (row && row?.office) {
      return (
        <div className="d-flex flex-column">
          <div className="font-weight-bold">{row?.office?.name}</div>
          <div className="">
            {row?.office?.city}, {row?.office?.state} |{' '}
            {row?.office?.company?.name}
          </div>
        </div>
      )
    }
  }

  const getPatient = (row, type) => {
    if (row) {
      if (type === 'name') {
        let label = row.patient
          ? `${row.patient.first_name} ${row.patient.last_name}`
          : `No patient #${row.id}`
        return (
          <Link
            to={`${routes.index}${routes.order.edit}${row.id}`}
            label={label}
          />
        )
      } else if (type == 'dob' && row.patient && row.patient.dob) {
        return (
          <small>
            <Moment format="MM/DD/YYYY" date={row.patient.dob} />
          </small>
        )
      } else if (type == 'order_id') {
        return (
          <>
            <span className="p-1 border border-primary rounded mt-1 mr-1">
              <Link
                to={`${routes.index}${routes.order.edit}${row.id}`}
                label={`#${row.id}`}
              />
            </span>
            {getDeviceCategory(row)}
          </>
        )
      }
    }
    return ''
  }
  /**
   * List of table columns
   */
  const _columns = React.useMemo(
    () => [
      {
        Header: 'Order Information',
        accessor: 'id', // accessor is the "key" in the data,
        className: 'col-3',
        sortAccessor: ['first_name', 'last_name'],
        filterAccessor: ['id', 'first_name', 'last_name'],
        isSortedDesc: true,
        Cell: props => {
          const row = props.row.original
          return (
            <div
              className={'col'}
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
              }}
            >
              <div
                className={'order-0'}
                style={{ textAlign: 'left', width: '20%' }}
                title={generalHelper.t('Click To Edit Order')}
              >
                <span className="p-1 border border-primary rounded mt-1 mr-1">
                  <Link
                    to={`${routes.index}${routes.order.edit}${row.id}`}
                    label={`#${row.id}`}
                  />
                </span>
              </div>
              <div
                className={'order-1'}
                style={{ textAlign: 'left', width: '60%' }}
                title={generalHelper.t('Click To Edit Patient Information')}
              >
                <div>
                  <strong className="list-patient">
                    {getPatient(row, 'name')}
                  </strong>
                </div>
                <div className="list-dob">{getPatient(row, 'dob')}</div>
              </div>
              <div
                className={'order-2'}
                style={{ textAlign: 'left', width: '20%' }}
                title={`Created on ${row.created_at}`}
              >
                <div className={`btn btn-sm w-30 ${dayClass(row)}`}>
                  {generalHelper.dateDiff(row.created_at, 'today')}{' '}
                  {generalHelper.t('Days')}
                </div>
              </div>
            </div>
          )
        },
      },
      {
        Header: 'Supplier',
        className: 'col-3',
        accessor: 'office.id',
        canFilter: false,
        Cell: ({ row }) => {
          const office = row.original.office
          return getOffice(row.original)
        },
      },
      {
        Header: 'Progress',
        className: 'col-4',
        Cell: props => {
          const row = props.row.original
          const orderStatus = [
            {
              row: 'patient_id',
              verification: {
                inactive: generalHelper.t('Patient'),
                incomplete: generalHelper.t('Patient Incomplete'),
                pending: generalHelper.t('Patient Pending Verification'),
                verified: generalHelper.t('Patient Verified'),
                failed: generalHelper.t('Patient Failed Verification'),
              },
              icon: 'fa-circle-user',
            },
            {
              row: 'insurance',
              verification: {
                inactive: generalHelper.t('Insurance'),
                incomplete: generalHelper.t('Insurance Incomplete'),
                pending: generalHelper.t('Insurance Pending Verification'),
                verified: generalHelper.t('Insurance Verified'),
                failed: generalHelper.t('Insurance Failed Verification'),
              },
              icon: 'fa-stethoscope',
            },
            {
              row: 'physician_id',
              verification: {
                inactive: generalHelper.t('Physician'),
                incomplete: generalHelper.t('Physician Incomplete'),
                pending: generalHelper.t('Physician Pending Verification'),
                verified: generalHelper.t('Physician Verified'),
                failed: generalHelper.t('Physician Failed Verification'),
              },
              icon: 'fa-suitcase-medical',
            },
            {
              row: 'rxform',
              verification: {
                inactive: generalHelper.t('RX Form'),
                incomplete: generalHelper.t('RX Form Incomplete'),
                pending: generalHelper.t('RX Form Pending Verification'),
                verified: generalHelper.t('RX Form Verified'),
                failed: generalHelper.t('RX Form Failed Verification'),
              },
              icon: 'RX',
              icon_text: true,
            },
            {
              row: 'aob',
              verification: {
                inactive: generalHelper.t('AOB Form'),
                incomplete: generalHelper.t('AOB Form Incomplete'),
                pending: generalHelper.t('AOB Form Pending Verification'),
                verified: generalHelper.t('AOB Form Failed Verification'),
                failed: generalHelper.t('AOB Form Failed Verification'),
              },
              icon: 'AOB',
              icon_text: true,
              prefix: '|',
              suffix: '|',
            },
            {
              row: 'upload',
              verification: {
                inactive: generalHelper.t('Uploads'),
                incomplete: generalHelper.t('Finalize Uploads'),
                verified_1: generalHelper.t('Group 1 Uploaded'),
                verified_2: generalHelper.t('Group 2 Uploaded'),
                verified_3: generalHelper.t('Group 3 Uploaded'),
              },
              icon: 'fa-upload',
            },
            {
              row: 'phone_office',
              verification: {
                inactive: generalHelper.t('Physician Communication'),
                pending: generalHelper.t('Physician Communication Pending'),
                verified: generalHelper.t('Physician Communication Sent'),
              },
              icon: 'fa-fax',
            },
          ]

          /**
           * Prints the icon for the current order status
           *
           * @param {Object} row Database row
           * @param {Object} _row
           * @param {Number} _index
           * @returns
           */
          const printIcon = (row, _row, _index) => {
            let icon
            if (row.icon) {
              if (row.icon_text) {
                icon = <small>{row.icon}</small>
              } else {
                icon = <i className={`fa fa-fw ${row.icon}`}></i>
              }
            }

            /**
             * Returns the content for the Tipsy for each icon and the corresponding class
             *
             * @param {Object} row Database row
             * @param {Object} _row Configraution object
             */
            const getContentAndClass = (_row, row) => {
              let _defaultContentText = 'Invalid'
              let _defaultContentClass = 'default'
              const _setDefaults = (attribute, row) => {
                if (
                  (row.hasOwnProperty(attribute.row) &&
                    _.isEmpty(row[attribute.row]?.status)) ||
                  _.isEmpty(row[attribute.row])
                ) {
                  return {
                    _defaultContentClass: 'incomplete',
                    _defaultContentText: attribute.verification.incomplete,
                  }
                } else {
                  return {
                    _defaultContentClass: 'verified',
                    _defaultContentText: attribute.verification.verified,
                  }
                }
              }
              ;({ _defaultContentClass, _defaultContentText } = _setDefaults(
                _row,
                row
              ))

              // Add exceptions to the general validation rule here.
              switch (_row.row) {
                case 'patient_id':
                  break
                case 'physician_id':
                  break
                case 'insurance':
                  break
                default:
                  break
              }

              return {
                contentText: _defaultContentText,
                contentClass: _defaultContentClass,
              }
            }

            const { contentText, contentClass } = getContentAndClass(row, _row)

            return (
              <React.Fragment key={_index}>
                {row.prefix && <span className="row_prefix">{row.prefix}</span>}
                <a
                  href="#"
                  className={`btn btn-sm mr-1 btn-status btn-${contentClass}`}
                  title={contentText}
                >
                  {icon}
                </a>
                {row.suffix && <span className="row_suffix">{row.suffix}</span>}
              </React.Fragment>
            )
          }

          return (
            <>
              {orderStatus.map((_row, _index) => {
                return printIcon(_row, row, _index)
              })}
            </>
          )
        },
      },
      {
        Header: 'Direct Messages',
        className: 'col-4',
        Cell: props => (
          <span>
            <Link
              to={`${routes.index}${routes.order.edit}${props.row.original.id}`}
              label={`${
                props.row.original.ticket ? props.row.original.ticket.length : 0
              } Open`}
            />
          </span>
        ),
      },
      {
        Header: 'Updated',
        accessor: 'updated_at',
        Cell: props => (
          <Moment format="MM/DD/YYYY" date={props.row.original.updated_at} />
        ),
      },
    ],
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy },
  } = useTable(
    { columns: _columns, data: ReactQuery.data || [], manualSortBy: true },
    useSortBy
  )

  // Set listener for sortBy Events from Our Table
  React.useEffect(() => {
    console.log('inUseEffect', sortBy)
    setSortValues(sortBy[0])
    // By default queries will be re-run once per minute
    // ReactQuery(props.queryName, () => props.queryFn({state: filterValues}, {enabled: false, refetchInterval: props.queryRefreshMs || 60000}))
  }, [sortBy])
  const ordersTableFilters = () => {
    //Recycle existing headers, add flag that will tell us if we need to build them as filters
  }
  return (
    <>
      <table {...getTableProps({ className: 'table' })}>
        <thead className="table_filters">
          <tr>
            <td></td>
          </tr>
        </thead>
        <thead>
          {
            // Loop over the header rows
            headerGroups.map(headerGroup => (
              // Apply the header row props
              <tr {...headerGroup.getHeaderGroupProps()}>
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map(column => (
                    // Apply the header cell props
                    <th
                      {...column.getHeaderProps({
                        ...column.getSortByToggleProps(),
                        className: `table-col ${column.className}`,
                      })}
                    >
                      {
                        // Render the header
                        column.render('Header')
                      }
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? ' ⇣'
                            : ' ⇡'
                          : ''}
                      </span>
                    </th>
                  ))
                }
              </tr>
            ))
          }
        </thead>
        <tbody {...getTableBodyProps()}>
          {ReactQuery.isLoading && (
            <tr>
              <td>{`Loading ${props.queryName}...`}</td>
            </tr>
          )}
          {rows &&
            // Loop over the table rows
            rows.map(row => {
              // Prepare the row for display
              prepareRow(row)
              return (
                // Apply the row props
                <tr {...row.getRowProps()}>
                  {
                    // Loop over the rows cells
                    row.cells.map(cell => {
                      // Apply the cell props
                      return (
                        <td {...cell.getCellProps()}>
                          {
                            // Render the cell contents
                            cell.render('Cell')
                          }
                        </td>
                      )
                    })
                  }
                </tr>
              )
            })}
        </tbody>
      </table>
    </>
  )
  // return (
  //   <div className='table-responsive'>
  //     <table className='table table-sm'>
  //       <thead>
  //         <tr>
  //           {type == 'order' && (
  //             <th width='18%' className='table-col'>
  //               {generalHelper.t('Patient')}
  //             </th>
  //           )}
  //           {type == 'patient' && (
  //             <th width='28%' className='table-col'>
  //               {generalHelper.t('Order ID')}
  //             </th>
  //           )}
  //           {reviewOnly && <th className='table-col'>{generalHelper.t('Office')}</th>}
  //           {order_status === 'open' && (
  //             <th className='table-col'>{generalHelper.t('Order Progress')}</th>
  //           )}
  //           {order_status === 'closed' && (
  //             <th className='table-col'>{generalHelper.t('Completed')}</th>
  //           )}
  //           {props.add_office && <th className='table-col'>{generalHelper.t('Office')}</th>}

  //           {order_status === 'open' && !reviewOnly && (
  //             <th className='table-col'>{generalHelper.t('Days Open')}</th>
  //           )}
  //           {!reviewOnly && (
  //             <th colSpan='2' className='table-col'>
  //               {generalHelper.t('Notes & Messages')}
  //             </th>
  //           )}
  //           {reviewOnly && order_status === 'open' && (
  //             <th className='table-col'>{generalHelper.t('Messages')}</th>
  //           )}
  //           {reviewOnly && order_status === 'open' && (
  //             <th className='table-col'>{generalHelper.t('Updated')}</th>
  //           )}
  //         </tr>
  //       </thead>
  //       <tbody>
  //         {Array.isArray(props.orders) &&
  //           props.orders.length > 0 &&
  //           props.orders.map((row, index) => {
  //             return (
  //               <tr key={index}>
  //                 {type == 'order' && (
  //                   <Tippy singleton={target} content={'Click To Edit Patient Information'}>
  //                     <td>
  //                       <strong className='list-patient'>{getPatient(row, 'name')}</strong>
  //                       <br />
  //                       <div className='list-dob'>{getPatient(row, 'dob')}</div>
  //                     </td>
  //                   </Tippy>
  //                 )}
  //                 {type == 'patient' && (
  //                   <td>
  //                     {getPatient(row, 'order_id')}
  //                     {order_status === 'open' && (
  //                       <Tippy singleton={target} content={`Created on ${row.created_at}`}>
  //                         <div className={`btn btn-sm w-30 ${dayClass(row)}`}>
  //                           {generalHelper.dateDiff(row.created_at, 'today')}{' '}
  //                           {generalHelper.t('Days')}
  //                         </div>
  //                       </Tippy>
  //                     )}
  //                   </td>
  //                 )}
  //                 {reviewOnly && <td>{getOffice(row)}</td>}
  //                 {order_status === 'open' && (
  //                   <td>
  //                     <Tippy
  //                       singleton={target}
  //                       content={
  //                         row.patient_id
  //                           ? 'Patient Information Verified'
  //                           : 'Patient Information Not Verified'
  //                       }
  //                     >
  //                       <a
  //                         href='#'
  //                         className={`btn btn-sm mr-1 btn-${
  //                           row.patient_id ? 'success' : 'default'
  //                         }`}
  //                       >
  //                         <i className='fa fa-fw fa-user-circle'></i>
  //                       </a>
  //                     </Tippy>
  //                     <Tippy
  //                       singleton={target}
  //                       content={
  //                         row.insurance
  //                           ? 'Patient Insurance Verified'
  //                           : 'Patient Insurance Not Verified'
  //                       }
  //                     >
  //                       <a
  //                         href='#'
  //                         className={`btn btn-sm mr-1 btn-${row.insurance ? 'success' : 'default'}`}
  //                       >
  //                         <i className='fa fa-fw fa-medkit'></i>
  //                       </a>
  //                     </Tippy>
  //                     <Tippy
  //                       singleton={target}
  //                       content={
  //                         row.physician_id ? <div>{row.physician_id} </div> : 'Physician Not Added'
  //                       }
  //                     >
  //                       <a
  //                         href='#'
  //                         className={`btn btn-sm mr-1 btn-${
  //                           row.physician_id ? 'success' : 'default'
  //                         }`}
  //                       >
  //                         <i className='fa fa-fw fa-notes-medical'></i>
  //                       </a>
  //                     </Tippy>
  //                     <Tippy
  //                       singleton={target}
  //                       content={row.patient_id ? 'Physician Added' : 'Physician Not Added'}
  //                     >
  //                       <a
  //                         href='#'
  //                         className={`btn btn-sm mr-1 btn-${
  //                           row.patient_id ? 'success' : 'default'
  //                         }`}
  //                       >
  //                         <i className='fa fa-fw fa-university'></i>
  //                       </a>
  //                     </Tippy>
  //                     <Tippy
  //                       singleton={target}
  //                       content={row.rxform ? 'RX Form Added' : 'RX Form Not Added'}
  //                     >
  //                       <a
  //                         href='#'
  //                         className={`btn btn-sm mr-1 btn-${row.rxform ? 'success' : 'default'}`}
  //                       >
  //                         Rx
  //                       </a>
  //                     </Tippy>
  //                     <Tippy
  //                       singleton={target}
  //                       content={row.aob ? 'AOB Form Added' : 'AOB Form Not Added'}
  //                     >
  //                       <a
  //                         href='#'
  //                         className={`btn btn-sm mr-1 btn-${row.aob ? 'success' : 'default'}`}
  //                       >
  //                         <small>AOB</small>
  //                       </a>
  //                     </Tippy>
  //                   </td>
  //                 )}
  //                 {order_status === 'closed' && (
  //                   <td>
  //                     <Moment format='MM/DD/YYYY' date={row?.updated_at} />
  //                   </td>
  //                 )}
  //                 {props.add_office && <td>{row && row.office && row.office.name}</td>}

  //                 {order_status === 'open' && !reviewOnly && (
  //                   <td>
  //                     <Tippy singleton={target} content={`Created on ${row.created_at}`}>
  //                       <div className={`btn btn-sm w-100 ${dayClass(row)}`}>
  //                         {generalHelper.dateDiff(row.created_at, 'today')}{' '}
  //                         {generalHelper.t('Days')}
  //                       </div>
  //                     </Tippy>
  //                   </td>
  //                 )}
  //                 <td className='list-activity'>
  //                   <Tippy
  //                     content={
  //                       row.order_note && row.order_note.length
  //                         ? row.order_note.map((row, index) => {
  //                             return (
  //                               <div key={index} className='row' id='notes-tip'>
  //                                 <div className='col-12'>
  //                                   <span className='text-muted'>{row.created_at}</span>
  //                                 </div>
  //                                 <div className='col-12 mb-2'>
  //                                   <span>{row.note}</span>
  //                                 </div>
  //                               </div>
  //                             )
  //                           })
  //                         : 'No notes available'
  //                     }
  //                     interactive={row.order_note && row.order_note.length ? true : false}
  //                     singleton={target}
  //                     placement={row.order_note && row.order_note.length ? 'left' : 'top'}
  //                     // appendTo={document.body}
  //                   >
  //                     <button
  //                       type='button'
  //                       className={`btn btn-sm mr-1 btn-${
  //                         row.order_note && row.order_note.length > 0 ? 'primary' : 'default'
  //                       }`}
  //                     >
  //                       <i className='fa fa-sticky-note'></i>{' '}
  //                       {row.order_note && row.order_note.length}
  //                     </button>
  //                   </Tippy>
  //                   <Tippy
  //                     content={
  //                       row.ticket && row.ticket.length
  //                         ? row.ticket.map((row, index) => {
  //                             return (
  //                               <div key={index} className='row'>
  //                                 <div className='col-12'>
  //                                   <span className='text-muted'>
  //                                     {row.created_user_id}
  //                                     {' wrote at '}
  //                                     {row.created_at}
  //                                   </span>
  //                                 </div>
  //                                 <div className='col-12 mb-2'>
  //                                   <span className=''>{row.message}</span>
  //                                 </div>
  //                               </div>
  //                             )
  //                           })
  //                         : 'No Messages'
  //                     }
  //                     interactive={row.ticket && row.ticket.length ? true : false}
  //                     singleton={target}
  //                     placement={row.ticket && row.ticket.length ? 'left' : 'top'}
  //                   >
  //                     <button
  //                       type='button'
  //                       className={`btn btn-sm mr-1 btn-${
  //                         row.ticket && row.ticket.length > 0 ? 'primary' : 'default'
  //                       }`}
  //                     >
  //                       <i className='fa fa-comment-dots'></i> {row.ticket && row.ticket.length}
  //                     </button>
  //                   </Tippy>
  //                 </td>
  //                 {reviewOnly && order_status === 'open' && (
  //                   <td>
  //                     <Moment format='MM/DD/YYYY' date={row?.updated_at} />
  //                   </td>
  //                 )}
  //               </tr>
  //             )
  //           })}
  //         {!props.orders ||
  //           (Array.isArray(props.orders) && props.orders?.length == 0 && (
  //             <tr>
  //               <td colSpan='20' align='center' className='textGray'>
  //                 {generalHelper.t('No orders are currently assigned to this patient.')}
  //               </td>
  //             </tr>
  //           ))}
  //       </tbody>
  //     </table>
  //   </div>
  // )
}

export default OrdersTable

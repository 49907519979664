import { ReactNode } from 'react'

interface IProps {
  title: string
  content: ReactNode
  className?: string
}

const CalloutComponent = (props: IProps) => {
  let { title, content, className } = props
  if (!className) {
    className = 'info'
  }

  return (
    <div className={`callout callout-${className}`}>
      {title && <h5>{title}</h5>}
      {content && <div>{content}</div>}
    </div>
  )
}

export default CalloutComponent

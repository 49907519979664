import { useEffect, useState, SyntheticEvent } from 'react'
import { useNavigate, Link, useLocation } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Alert from 'react-bootstrap/Alert'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faEnvelope, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { useAuth0 } from '@auth0/auth0-react'

import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { getLogin } from 'store/slice/loginSlice'
import { post } from 'helpers/api'
import { PasswordField } from 'components/molecules/PasswordField'
import { getInit, setCurrentOfficeTests } from 'store/slice/initSlice'
import { useLazyGetDmeOfficeTestsQuery } from 'store/services/office'
import Logo from 'assets/img/logo2.png'

const Login = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const login = useAppSelector((state) => state.login)
  const init = useAppSelector((state) => state.init)
  const dispatch = useAppDispatch()
  const [getDmeOffice] = useLazyGetDmeOfficeTestsQuery()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isSso, setIsSso] = useState<boolean | undefined>()
  const { loginWithRedirect } = useAuth0()
  const destinationPath = location.state?.from?.pathname

  useEffect(() => {
    if (init?.userType === 'LAB') {
      if (destinationPath && destinationPath?.startsWith('/lab')) {
        return navigate(`${destinationPath}${location.state.from.search}`)
      }
      navigate('/lab')
    } else if (init?.userType === 'DME') {
      getDmeOffice({ id: Number(init?.currentOfficeId) })
        .unwrap()
        .then((tests) => dispatch(setCurrentOfficeTests(tests)))

      if (destinationPath && destinationPath?.startsWith('/dme')) {
        return navigate(`${destinationPath}${location.state.from.search}`)
      }
      navigate('/dme')
    } else if (init?.userType === 'PHYSICIAN_GROUP') {
      navigate('/physicians')
    } else if (login?.data?.access_token && !init?.pending) {
      dispatch(getInit())
    }
  }, [login, init])

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault()
    if (!email) {
      return
    }
    localStorage.clear()
    if (false !== isSso && (await checkSso())) {
      return
    }
    if (!password) {
      return
    }
    dispatch(getLogin({ email, password }))
  }

  const ssoLogin = async () => {
    localStorage.setItem('login_email', email)
    await loginWithRedirect({
      authorizationParams: {
        login_hint: email,
        //organization: '',
      },
    })
  }

  const checkSso = async () => {
    try {
      const config = await post('/auth/config', { email })
      if (config?.sso) {
        await ssoLogin()
        return true
      }
    } catch (error) {}
    setIsSso(false)
    return false
  }

  function LoginLoading() {
    if (login?.pending) {
      return (
        <Alert variant="info">
          {' '}
          <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" /> Loading...
        </Alert>
      )
    }
    return null
  }

  return (
    <Container>
      <Row> &nbsp; </Row>
      <Row className="justify-content-md-center">
        <Col sm={8}>
          <div className="text-center mb-5 mt-2">
            <img src={Logo} alt="logo" />
          </div>
        </Col>
        <Col sm={8}>
          <form method="post" onSubmit={handleSubmit}>
            <div className="text-center">
              <h3>
                <FontAwesomeIcon icon={faLock} /> Login
              </h3>
            </div>

            <div className="form-group">
              <label>
                {' '}
                <FontAwesomeIcon icon={faEnvelope} /> Email address
              </label>
              <input
                type="email"
                className="form-control"
                autoComplete="username"
                placeholder="Enter email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            {isSso === false && <PasswordField setPassword={setPassword} />}
            <div className="form-group">
              <div className="custom-control custom-checkbox">
                <input type="checkbox" className="custom-control-input" id="customCheck1" />
              </div>
            </div>

            <button type="submit" className="btn btn-primary btn-block">
              Sign In
            </button>
            <br />
            <Link to="/forgot-password">Forgot Password?</Link>
            <LoginLoading />
            {login?.rejected && (
              <Alert variant="danger">
                {' '}
                <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
                {login.error ?? 'Invalid Credentials'}
              </Alert>
            )}
          </form>
          {isSso === false && (
            <button className="btn btn-secondary mt-3" onClick={ssoLogin}>
              Sign In with SSO
            </button>
          )}
        </Col>
      </Row>
    </Container>
  )
}

export default Login

import Link from '../../atoms/Link'
import TitleLead from '../../atoms/TitleLead'
import NoData from '../../atoms/NoData'
import routes from '../constants/routes'

interface IProps {
  order: any
  orderTests: any[]
}

const TestConditionSummary = ({ order, orderTests }: IProps) => {
  return (
    <div className="card mt-4">
      <div className="card-header">
        <div className="row">
          <div className="col d-flex align-items-center border-right">
            <Link
              to={`${routes.index}${routes.order.edit}${order?.id}`}
              label=""
              className="text-info"
              icon="fas fa-arrow-left"
            />
          </div>
          <div className="col-9 d-flex align-items-center">
            <TitleLead
              label="Testing Conditions"
              icon="fas fa-file-medical-alt"
              className="text-info font-weight-bold"
            />
          </div>
          <div className="col-2 border-left text-right">
            <Link
              className="btn btn-default"
              to={`${routes?.index}${routes?.order?.test}${order?.id}/${routes?.upsert}`}
              label="Add a Night"
              icon="fas fa-plus"
            />
          </div>
        </div>
      </div>
      <div className="card-body pl-4 pr-4">
        {Array.isArray(orderTests) ? (
          orderTests.map((ot, i) => {
            const summaryText = ot.oxygen
              ? `On Oxygen @${ot.oxygen_lpm}, ${
                  ot.pap ? `with ${ot.pap_type}` : ''
                }`
              : 'On Room Air'
            return (
              <div key={i} className="mb-2 mt-2">
                <div className="row p-3 bg-info text-white">
                  <div className="col-10 d-flex align-items-center">
                    <TitleLead
                      label={`Night ${ot?.id}`}
                      icon="fas fa-moon"
                      className="font-weight-bold"
                    />
                  </div>
                  <div className="col-2 text-right">
                    <Link
                      className="btn btn-info btn-outline-light"
                      to={`${routes?.index}${routes?.order?.test}${order?.id}/${routes?.upsert}${ot?.id}`}
                      label="Edit"
                      icon="far fa-edit"
                    />
                  </div>
                </div>
                <div className="row p-3 border-right border-bottom border-left">
                  {summaryText}
                </div>
              </div>
            )
          })
        ) : (
          <NoData text={'No tests for this order'} />
        )}
      </div>
      <div className="card-footer">
        <Link
          className="btn btn-default"
          to={`${routes.index}${routes.order.edit}${order?.id}`}
          label="Back"
          icon="fas fa-arrow-left"
        />
      </div>
    </div>
  )
}

export default TestConditionSummary

import { baseApi } from './baseApi'
import { Patient } from 'store/types'

export const patientApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getPatients: builder.query<Patient[], { [key: string]: string }>({
      query: (query) => ({ url: `/dme/patients`, params: query }),
    }),
    updateLabPatient: builder.mutation<Patient, Partial<Patient>>({
      query: (data) => ({
        method: `PATCH`,
        url: `/lab/patients/${data.id}`,
        body: data,
      }),
      invalidatesTags: (result, error, { id }) => ['Order', 'Patient'],
    }),
    updateLabOrderPatient: builder.mutation<Patient, Partial<Patient>>({
      query: (data: Partial<Patient> & {orderId: string}) => ({
        method: `PATCH`,
        url: `/lab/orders/${data.orderId}/patient`,
        body: data,
      }),
      invalidatesTags: (result, error, { id }) => ['Order', 'Patient'],
    }),
  }),
})

export const { useGetPatientsQuery, useUpdateLabPatientMutation, useUpdateLabOrderPatientMutation } = patientApi

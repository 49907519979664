/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { skipToken } from '@reduxjs/toolkit/query/react'
import { useState } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'

import {
  useGetLabMessagesQuery,
  useSendLabMessageMutation,
  useUpdateMessageReadStatusMutation,
} from 'store/services/messages'
import {
  useDeleteNoteMutation,
  useGetLabNotesQuery,
  useSendLabNoteMutation,
} from 'store/services/notes'
import { TabControl } from './TabControl'

interface IProps {
  orderId: number | string
}

export const OrderMsgDropdown = ({ orderId }: IProps) => {
  const [bottomTab, setBottomTab] = useState('notes')
  const [updateMessageReadStatus] = useUpdateMessageReadStatusMutation()
  const [deleteNote] = useDeleteNoteMutation()
  const [sendLabMessage] = useSendLabMessageMutation()
  const [sendLabNote] = useSendLabNoteMutation()
  const { data: messages } = useGetLabMessagesQuery(orderId ? { orderId } : skipToken, {
    refetchOnFocus: true,
  })
  const unreadMessages = messages?.reduce(
    (agg, curr) => (curr.sentBy === 'lab' || curr.readAt ? agg : agg + 1),
    0,
  )
  const { data: notes } = useGetLabNotesQuery(orderId ? { orderId } : skipToken, {
    refetchOnFocus: true,
  })

  const addMessage = async (message: string) => {
    if (!orderId) return
    await sendLabMessage({ orderId: Number(orderId), text: message, context: '' })
  }
  const addNote = async (note: string) => {
    if (!orderId) return
    await sendLabNote({ orderId: Number(orderId), text: note })
  }

  return (
    <Dropdown className="d-inline mx-2" autoClose={false}>
      <Dropdown.Toggle
        css={css`
          background: transparent !important;
          border: none;
          &:hover,
          &:active,
          &:focus,
          .show {
            background-color: transparent !important;
            border: none;
            backdrop-filter: brightness(0.9);
          }
        `}>
        <i
          className="fa fa-bars"
          css={css`
            font-size: 1.3rem;
          `}
        />
        {unreadMessages ? (
          <div
            className="badge bg-danger right"
            css={css`
              border-radius: 47%;
              position: absolute !important;
              font-size: 0.85rem;
              top: 0;
              left: 0;
              width: 18px;
              height: 18px;
            `}>
            {unreadMessages}
          </div>
        ) : (
          <></>
        )}
      </Dropdown.Toggle>

      <Dropdown.Menu
        css={css`
          width: 40vw;
          z-index: 10000;
          padding: 0;
          box-shadow: 0px 0.5rem 2rem rgb(0 0 0 / 40%);
        `}>
        <TabControl
          orderId={Number(orderId)}
          tab={bottomTab}
          setTab={setBottomTab}
          messages={messages}
          addMessage={addMessage}
          updateMessageReadStatus={updateMessageReadStatus}
          notes={notes}
          addNote={addNote}
          deleteNote={deleteNote}
        />
      </Dropdown.Menu>
    </Dropdown>
  )
}

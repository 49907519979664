/** @jsxImportSource @emotion/react */
import { Link } from 'react-router-dom'
import moment from 'moment'
import styled from '@emotion/styled'
import { startCase, capitalize } from 'lodash'

import TitleLead from 'components/atoms/TitleLead'
import { Patient } from 'store/types'
import routes from '../constants/routes'
import { formatPhoneNumber } from 'helpers/format'
import * as generalHelper from 'helpers/generalHelper'
import { Order } from 'store/types'

interface IProps {
  patients?: Patient[]
  isLoading: boolean
}

const CellWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    white-space: nowrap;
    display: flex;
  }
`

const NewPatientResults = ({ isLoading = false, patients = [] }: IProps) => {
  const headers = [
    {
      columnName: 'Patient Name',
      columnSize: 2,
      template: (patient: Patient) => (
        <>
          <Link to={`${routes.index}${routes.patient.root}${patient.id}`}>
            {`${capitalize(patient.lastName)}${
              patient.suffix ? ' ' + patient.suffix : ''
            }, ${capitalize(patient.firstName)} ${
              patient.middleName ? capitalize(patient.middleName) : ''
            }`}
          </Link>
        </>
      ),
    },
    {
      columnName: 'Date of Birth',
      template: (patient: Patient) => (
        <>
          {moment(moment(patient?.dob).format('MM/DD/YYYY')).isValid()
            ? moment(patient?.dob).format('MM/DD/YYYY')
            : 'N/A'}
        </>
      ),
    },
    {
      columnName: 'Address',
      columnSize: 3,
      template: (patient: Patient) => (
        <CellWrapper>
          <div>{patient.primaryAddressStreetLine1}</div>
          <div>
            {patient?.primaryAddressState && (
              <span>{`${patient?.primaryAddressCity}, ${patient?.primaryAddressState.toUpperCase()}, ${patient?.primaryAddressZip}`}</span>
            )}
          </div>
          <div className="d-flex flex-column">
            {patient.homePhone && (
              <span className="mr-4"> Tel: {formatPhoneNumber(patient.homePhone)} </span>
            )}

            {patient.mobilePhone && (
              <span className="mr-4"> Mobile: {formatPhoneNumber(patient.mobilePhone)} </span>
            )}

            {patient.email && <span> Email: {patient.email} </span>}
          </div>
        </CellWrapper>
      ),
    },
    {
      columnName: 'Open Order',
      columnSize: 3,
      template: (patient: Patient) => (
        <CellWrapper>
          {[...patient?.orders]
            ?.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
            ?.map((order: Order) => (
              <div css={{ fontWeight: order.bucket === 'COMPLETED' ? '400' : '600' }}>
                <Link to={`${routes.index}${routes.order.edit}${order.id}`} className=" mr-3">
                  {`#${order.id ?? ''}`}
                </Link>
                {order.testType} - {startCase(order.bucket?.replace(/_/g, ' ')?.toLowerCase())} -{' '}
                {moment(order.createdAt).format('MM/DD/YYYY')}
              </div>
            ))}
        </CellWrapper>
      ),
    },
    {
      columnName: 'Action',
      columnSize: 2,
      template: (patient: Patient) => (
        <>
          <Link
            className="btn btn-primary py-0"
            type="button"
            to={`${routes.index}${routes.order.root}${
              routes.order.new
            }patient/${patient?.id}/orderDetails?officeId=${patient?.officeId ?? '1'}`}
            state={{ activeOrders: patient?.orders?.filter((i) => i.bucket !== 'COMPLETED') }}>
            {generalHelper.t('New Order')}
          </Link>
        </>
      ),
    },
  ]

  return (
    <div className="card card-primary">
      <div className="card-body">
        <div className="row">
          <div className="col-sm-12 d-flex justify-content-start">
            <TitleLead
              className="ml-2 my-3"
              label={`${patients?.length ?? 0} patient${
                patients.length === 1 ? '' : 's'
              } match the information you entered${patients.length === 0 ? '.' : ':'}`}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            {isLoading && (
              <div className="px-2">
                <div className="row border rounded mb-3 p-2 d-flex justify-content-center">
                  <span className="font-weight-bold font-italic">
                    Looking for matching patients...
                  </span>
                </div>
              </div>
            )}
            {!!patients?.length && (
              <div className="container-fluid">
                <div className="row border-bottom m-2 pb-2 pl-2">
                  {Array.isArray(headers) &&
                    headers.map((row, index) => {
                      return (
                        <div
                          className={`col${row?.columnSize ? '-' + row.columnSize : ''} textGray`}
                          key={index}>
                          {row?.columnName}
                        </div>
                      )
                    })}
                </div>
                {Array.isArray(patients) &&
                  patients.map((patient, dataIndex) => {
                    return (
                      <div className="row border rounded m-2 py-3 pl-2" key={dataIndex}>
                        {Array.isArray(headers) &&
                          headers.map((row, index) => {
                            return (
                              <div
                                className={`col${
                                  row?.columnSize ? '-' + row.columnSize : ''
                                } m-auto`}
                                key={index}>
                                {row?.template(patient)}
                              </div>
                            )
                          })}
                      </div>
                    )
                  })}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewPatientResults

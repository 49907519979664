/** @jsxImportSource @emotion/react */
import { Form, FormikValues, FormikErrors, FormikTouched } from 'formik'
import styled from '@emotion/styled'
import { Button } from 'react-bootstrap'

import DateTimePicker from 'components/atoms/DateTimePicker'

export const TimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  .react-datepicker-wrapper {
    flex: 1;

    input {
      width: 100%;
      padding: 7px 8px;
    }
    svg {
      top: 9px !important;
      right: 7px !important;
    }
    button {
      margin-right: 20px;
    }
  }
`

interface IProps {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  values: FormikValues
  errors: FormikErrors<FormikValues>
  touched: FormikTouched<FormikValues>
}

export const FollowUpForm = ({ setFieldValue, values, errors, touched }: IProps) => {
  return (
    <Form className="col-sm-12 p-3">
      <TimeWrapper>
        <div css={{ fontWeight: '500' }} className="mb-1">
          Follow-up Date
        </div>
        <DateTimePicker
          value={values.followUpDate}
          handleChange={(date) => setFieldValue('followUpDate', date)}
          minDate={new Date()}
          showTimeSelect={false}
          isClearable
          dateFormat="MMMM d, yyyy"
        />
      </TimeWrapper>
      <div className="mt-2">
        <textarea
          name="notes"
          className="form-control"
          placeholder="Notes"
          value={values.notes}
          onChange={(e) => setFieldValue('notes', e.target.value)}
          css={{
            borderColor: errors.notes && touched.notes ? 'red' : undefined,
          }}
        />
        {errors.notes && touched.notes && (
          <label
            css={{
              color: 'red',
              position: 'absolute',
              bottom: '-25px',
              left: '8px',
              fontWeight: 'normal !important',
            }}>
            {errors.notes}
          </label>
        )}
      </div>

      <Button variant="outline-primary mt-2" type="submit">
        Update
      </Button>
    </Form>
  )
}

import { useParams } from 'react-router-dom'
import Tab from '../../atoms/Tab'
import DashboardContent from '../atoms/DashboardContent'
import * as generalHelper from '../../../helpers/generalHelper'
import ButtonNew from '../../atoms/ButtonNew'
import routes from '../constants/routes'
import Available from './views/Available.tab'
import Checkout from './views/Checkout.tab'

const Device = props => {
  let { tab } = useParams()
  !tab ? (tab = 'checkout') : false

  function Content(props) {
    const tabsArray = [
      {
        key: 'checkout',
        tab: tab,
        toKey: '',
        to: `${routes.index}${routes.device.root}`,
        className: 'font87p',
        labelSmall: 'Checkout Devices',
        icon: 'fas fa-hdd',
        content: Checkout(props),
      },
      {
        key: 'available',
        tab: tab,
        to: `${routes.index}${routes.device.root}`,
        className: 'font87p',
        labelSmall: 'Available Devices',
        icon: 'fas fa-hdd',
        content: Available(props),
      },
    ]

    return (
      <div className="card card-primary">
        <form type="post" onSubmit={props.handleSubmit}>
          <div className="card-header">
            <h3 className="card-title"></h3>
            <div className="card-tools"></div>
          </div>
          {<Tab tabs={tabsArray} handleTabChange={() => {}} />}
        </form>
      </div>
    )
  }

  return (
    <DashboardContent
      title={generalHelper.t('My Devices')}
      icon="fas fa-hdd"
      content={Content(props)}
      breadcrumb={[
        { label: 'Home', to: `${routes.index}` },
        { label: 'Devices' },
      ]}
    />
  )
}

export default Device

import TitleLead from 'components/atoms/TitleLead'
import styled from '@emotion/styled'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'

import { phoneNumberMask } from 'helpers/masks'
import { requiredPhoneValidation } from 'helpers/validate'
import MaskInput from 'components/atoms/MaskInput'
import { CheckboxInput } from 'components/atoms/Checkbox'
import Radio from 'components/atoms/Radio'
import { AssignThirdPartyDeviceDto, Order } from 'store/types'
import { toast } from 'react-toastify'
import { FormikDirtyStateSync } from 'components/atoms/FormikDirtyStateSync'

const InputWrapper = styled.div`
  input {
    max-width: 250px;
  }
`

const formValidation = Yup.object().shape({
  mobilePhone: requiredPhoneValidation,
  email: Yup.string().required('Required').email('Invalid Email'),
  hasSmartPhone: Yup.bool().oneOf([true], 'Must be checked'),
  smsConsent: Yup.bool().oneOf([true], 'Must be checked'),
})

interface IProps {
  order: Order
  updateDeviceNightOwl: (params: AssignThirdPartyDeviceDto) => void
  onClose?: () => void
  setFormDirty?: (isDirty: boolean) => void
}

export const NightOwlSelection = ({
  order,
  updateDeviceNightOwl,
  onClose,
  setFormDirty = () => {},
}: IProps) => {
  console.log('nightowl:', onClose)
  return (
    <div className="row align-items-center px-4">
      <Formik
        enableReinitialize={true}
        validationSchema={formValidation}
        initialValues={{
          email: order?.patient?.email,
          mobilePhone: order?.patient?.mobilePhone?.replace(/^\+1/, ''),
          fulfillment: order?.thirdPartyDevice?.fulfillment ?? 'TESTSMARTER',
          nightOwlSerial: order?.thirdPartyDevice?.nightOwlSerial ?? '',
          hasSmartPhone: !!order?.thirdPartyDevice || false,
          smsConsent: !!order?.thirdPartyDevice || false,
        }}
        onSubmit={async (values) => {
          console.log(values)
          try {
            await updateDeviceNightOwl({
              deviceType: 'NIGHT_OWL',
              orderId: Number(order?.id),
              mobilePhone: values.mobilePhone,
              fulfillment: values.fulfillment,
              nightOwlSerial: values.nightOwlSerial,
              email: values.email,
            }).unwrap()
            if (onClose) {
              onClose()
            }
          } catch (err) {
            toast.error(err?.data?.message ?? 'Unable to submit with the provided data.')
          }
        }}>
        {({ values, errors, touched, handleChange, setFieldValue, dirty }) => (
          <Form id="NightOwl" className="container-fluid">
            <FormikDirtyStateSync isDirty={dirty} setFormDirty={setFormDirty} />
            <TitleLead className="my-2 text-bold mr-5" label="Patient" />
            <div className="row">
              <InputWrapper className="col-5 form-group textGray">
                <label htmlFor="mobilePhone">Smart Mobile Phone Number</label>
                <span className="text-danger ml-2">(required)</span>
                <MaskInput
                  value={values.mobilePhone}
                  onChange={handleChange}
                  name="mobilePhone"
                  mask={phoneNumberMask}
                  placeholder="(###) ###-####"
                />
                {touched.mobilePhone && errors.mobilePhone ? (
                  <div className="text-danger">{errors.mobilePhone}</div>
                ) : null}
              </InputWrapper>
              <InputWrapper className="col-5 form-group textGray">
                <label htmlFor="mobilePhone">E-mail</label>
                <span className="text-danger ml-2">(required)</span>
                <Field
                  type="text"
                  name="email"
                  className="form-control"
                  placeholder="Email"
                  value={values.email}
                />
                {touched.email && errors.email ? (
                  <div className="text-danger">{errors.email}</div>
                ) : null}
              </InputWrapper>
            </div>
            <Field
              id="hasSmartPhone"
              name="hasSmartPhone"
              component={CheckboxInput}
              label="I have confirmed the patient has a smart phone."
              parentClassName={`custom-control my-2 ${errors.hasSmartPhone && 'text-danger'}`}
            />
            <Field
              id="smsConsent"
              name="smsConsent"
              component={CheckboxInput}
              label="Patient has consented to receive text messages from TestSmarter."
              parentClassName={`custom-control my-2 ${errors.hasSmartPhone && 'text-danger'}`}
            />

            <TitleLead className="my-2 text-bold mr-5" label="Fulfillment" />
            <Radio
              value={values.fulfillment}
              checked={values.fulfillment === 'TESTSMARTER'}
              name="TESTSMARTER"
              onChange={() => setFieldValue('fulfillment', 'TESTSMARTER')}
              wrapperClassName="mr-3 align-items-start my-3"
              className="mt-1"
              label="TestSmarter Will Ship New Device To Patient"
            />
            <div className="d-flex row align-items-center">
              <Radio
                value={values.fulfillment}
                checked={values.fulfillment === 'DME'}
                name="DME"
                onChange={() => setFieldValue('fulfillment', 'DME')}
                wrapperClassName="col-4 mr-3 align-items-start my-3"
                className="mt-1"
                label="DME Will Issue New Device To Patient"
              />
              <Field
                type="text"
                name="nightOwlSerial"
                className="form-control col-4"
                placeholder="serial number"
                value={values.nightOwlSerial}
              />
            </div>
            <Radio
              value={values.fulfillment}
              checked={values.fulfillment === 'PATIENT'}
              name="PATIENT"
              onChange={() => setFieldValue('fulfillment', 'PATIENT')}
              wrapperClassName="mr-3 align-items-start my-3"
              className="mt-1"
              label="Patient already has their own device, serial number is not known"
            />
            {/* <div className="d-flex justify-content-end container-fluid">
              <Button type="submit" disabled={!dirty}>
                Submit
              </Button>
            </div> */}
          </Form>
        )}
      </Formik>
    </div>
  )
}

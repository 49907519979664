import { useRef } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import { useAppSelector } from 'hooks/redux'
import { PayerSelectionForm } from './PayerSelection'
import { Order } from 'store/types'
import { useUpdatePayerMutation } from 'store/services/dmeOrder'

interface IProps {
  order: Order
  open: boolean
  onClose: () => void
}

export const UpdateInsuranceModal = ({ order, open, onClose }: IProps) => {
  const submitRef = useRef<() => void>()
  const [updatePayer] = useUpdatePayerMutation()
  const currentOfficeId = useAppSelector((state) => state.init.currentOfficeId)

  const submitForm = async (payload) => {
    try {
      await updatePayer({
        orderId: order.id,
        payers: payload,
      }).unwrap()
      onClose()
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <Modal centered show={open} onHide={onClose} size="lg">
      <Modal.Header>
        <Modal.Title>EDIT INSURANCE INFORMATION</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PayerSelectionForm
          officeId={currentOfficeId}
          payers={order.payers}
          verificationResult={order?.state?.VERIFICATION?.RESULT}
          submitForm={submitForm}
          submitRef={submitRef}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button autoFocus variant="primary" onClick={() => submitRef.current()}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

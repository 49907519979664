const WristOx = () => {
  return (
    <ul className="text-left">
      <li>
        <span>1.</span> Be sure the WristOx USB cable is connected securely to
        your computer.
      </li>
      <li>
        <span>2.</span> Tap the button on the top left side of the oximeter if
        the display is not on.
      </li>
      <li>
        <span>3.</span> <small>IMPORTANT:</small> Be sure the display reads "CP"
        before you begin download.
      </li>
    </ul>
  )
}

export default WristOx

import { useState } from 'react'
import { Navigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Formik, Form } from 'formik'
import OrderForm from '../forms/OrderForm'
import PatientUpsert from '../forms/autocompletes/PatientUpsert'
import { insertOrder } from '../../../store/slice/orderSlice'
import { handleSubmit } from '../../atoms/Upsert'
import orderValidationSchema from '../forms/schemas/orderValidationSchema'
import { formatOrderPayload } from '../forms/utils/orderFormUtils'
import routes from '../constants/routes'

export default function AddOrderUpsert({
  patient,
  patientId,
  deviceCategories,
}) {
  const [redirect, setRedirect] = useState()
  const [orderPatient, setOrderPatient] = useState(patientId ? patient : '')
  const dispatch = useDispatch()

  const initialValues = {
    order: true,
    select_patient: patientId ? orderPatient : '',
    device_category_id: '',
    oxygen: '',
    oxygen_lpm: '',
    pap: '',
    pap_type: '',
    apap_min: '',
    apap_max: '',
    bipap_i: '',
    bipap_e: '',
    cpap: '',
    non_invasive: '',
    adaptive_support: '',
    oral_appliace: '',
  }

  return (
    <Formik
      enableReinitialize={true}
      validationSchema={orderValidationSchema}
      initialValues={initialValues}
      onSubmit={values => {
        handleSubmit(event, {
          values: formatOrderPayload(values, orderPatient),
          functions: {
            insert: insertOrder,
          },
          callback: {
            function: setRedirect,
            params: `${routes.index}${routes.order.edit}`,
            params_add: 'id',
          },
          dispatch: dispatch,
        })
      }}
    >
      {({ values, errors, touched, setFieldValue }) => (
        <Form>
          <>
            {redirect && <Navigate to={redirect} />}
            <div className="row d-flex justify-content-center">
              {patient && patientId ? (
                <div className="col-5 mt-3"></div>
              ) : (
                <div className="col-11 mt-3">
                  <label
                    className="form-check-label text-bold"
                    htmlFor="select_patient"
                  >
                    Add Patient
                  </label>
                  <PatientUpsert
                    value={values.select_patient}
                    id="select_patient"
                    name="select_patient"
                    errors={errors}
                    touched={touched}
                    onChange={obj => {
                      const value = Array.isArray(obj) ? obj[0] : obj
                      setOrderPatient(obj)
                      setFieldValue('select_patient', value)
                    }}
                  />
                </div>
              )}
              <div className="col-5 mt-3" />
            </div>
            <div className="row d-flex justify-content-center">
              <div className="col-11">
                <OrderForm
                  values={values}
                  errors={errors}
                  touched={touched}
                  isOrder={true}
                  deviceCategories={deviceCategories}
                />
              </div>
            </div>
          </>
        </Form>
      )}
    </Formik>
  )
}

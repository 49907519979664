const MasimoRAD8 = () => {
  return (
    <ul className="text-left">
      <li>
        <span>1.</span> Remove the fingertip sensor cable from the front of the
        Rad-8.
      </li>
      <li>
        <span>2.</span> Be sure the data cable (back of oximeter) is connected
        securely to your computer.
      </li>
      <li className="highlight">
        <span>3.</span> Be sure the Rad-8 is configured for Normal Mode and{' '}
        <strong>not</strong> in Sleep Mode.
      </li>
      <li>
        <span>4.</span> Press and briefly hold the{' '}
        <i className="fa fa-power-off"></i> button to power on the Rad-8.
      </li>
      <li>
        <span>5.</span> <small>IMPORTANT:</small> Be sure the display reads "NO
        CBL" before you begin the download.
      </li>
    </ul>
  )
}

export default MasimoRAD8

import { SyntheticEvent, useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import styled from '@emotion/styled'

import TextArea from 'components/atoms/Textarea'
import DateTimePicker from 'components/atoms/DateTimePicker'

const PickupTimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;

  .react-datepicker-wrapper {
    flex: 1;

    input {
      width: 100%;
      padding: 7px 8px;
    }
    svg {
      top: 9px !important;
      right: 7px !important;
    }
  }
`

interface IProps {
  show?: boolean
  onClose: () => void
  onSubmit: (followUpDate: string, note?: string) => void
}

export const HoldDialog = ({ show, onClose, onSubmit }: IProps) => {
  const [note, setNote] = useState('')
  const [followUp, setFollowUp] = useState<Date | null>(null)

  useEffect(() => {
    if (!show) {
      setNote('')
      setFollowUp(null)
    }
  }, [show])

  return (
    <Modal centered show={!!show} onHide={onClose}>
      <Modal.Header>
        <Modal.Title>PUT ORDER ON HOLD</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PickupTimeWrapper className="mb-2">
          <div style={{ fontWeight: 'bold' }} className="text-gray mb-1">
            Follow Up
          </div>
          <DateTimePicker
            value={followUp}
            handleChange={setFollowUp}
            minDate={new Date()}
            showTimeSelect={false}
            dateFormat="MMMM d, yyyy"
          />
        </PickupTimeWrapper>
        <TextArea
          handleChange={(e: SyntheticEvent) => setNote(e.target.value)}
          name="notes"
          label="Notes"
          value={note}
          rows={5}
          labelClassName="text-gray"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          autoFocus
          disabled={!followUp}
          variant="primary"
          onClick={() => {
            setNote('')
            onSubmit(followUp!.toISOString(), note)
            onClose()
          }}>
          PLACE ON HOLD
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

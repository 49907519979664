import { useState } from 'react'
import { useParams } from 'react-router-dom'

import DobUpsert from './DobUpsert'
import OrderInfo from './OrderInfo'
import Success from './Success'
import VerifyUpsert from './VerifyUpsert'
import * as api from 'helpers/api'
import { useGetPatientOrderQuery, useSignPatientAobMutation } from 'store/services/patientEndpoints'
import { tsOfficePhone } from 'constants/misc'

const Verify = () => {
  const { orderId, aobId } = useParams()
  const [dobVerified, setDobVerified] = useState(false)
  const [error, setError] = useState('')
  const [orderVerified, setOrderVerified] = useState(false)
  const [aobPdf, setAobPdf] = useState<string>()
  const [skip, setSkip] = useState(true)
  const { data: order, isLoading } = useGetPatientOrderQuery(undefined, { skip })
  const [signAob] = useSignPatientAobMutation()

  const verifyDob = async (dob: string) => {
    try {
      setError('')
      const result = await api.get(`/patient/auth?`, { aobId, dob, orderId })
      if (result?.access_token) {
        localStorage.setItem('access_token', result.access_token)
        setSkip(false)
        setDobVerified(true)
      }
    } catch (err) {
      if (err.response.status > 400 && err.response.status < 500) {
        setError(err?.response?.data?.message || 'Invalid date of birth entered.')
      }
      console.log(err)
    }
  }

  return (
    <div className="container" style={{ maxWidth: 720 }}>
      <div className="mb-3">
        <h2>eSign for Oxygen Qualification Testing</h2>
        {!dobVerified ? <DobUpsert verifyDob={verifyDob} /> : null}
        {dobVerified && !orderVerified && !isLoading && order ? (
          <OrderInfo order={order} setOrderVerified={setOrderVerified} />
        ) : null}
        {dobVerified && orderVerified && !aobPdf && order ? (
          <VerifyUpsert order={order} signAob={signAob} setAobPdf={setAobPdf} />
        ) : null}
        {dobVerified && orderVerified && aobPdf ? <Success pdf={aobPdf} /> : null}
        <p className="text-danger">{error}</p>
        <div className="mt-5 mb-5 pt-3 border-top">
          <p>
            <span className="font-weight-bold">Terms and Conditions:</span> Please be aware that
            most standard text messages and emails are not a secure means of communication. This
            means that there is risk that your protected health information can be accessed through
            text messages and be intercepted and read by, or disclosed to, unauthorized third
            parties. Use of alternative and more secure methods of communication with us, such as
            telephone, fax, or the U.S. Postal Service are available to you. If you do not wish to
            accept the risks associated with non-secure unecrypted email communications from us
            containing your protected health information, please indicate that you do not wish to
            receive such text messages from us by calling us at {tsOfficePhone}.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Verify

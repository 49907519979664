import * as generalHelper from "../../helpers/generalHelper";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ------------------------------------------------------------
export const getOrderTests = createAsyncThunk(
    "getOrderTests",
    async (params, { rejectWithValue }) => {
        try {
            const data = await generalHelper.apiGet("/api/order-test", params);
            if (!data) {
                return rejectWithValue([]);
            }
            return data;
        } catch (error) {
            console.log("error", error);
        }
    }
);

// ------------------------------------------------------------
export const getOrderTest = createAsyncThunk(
    "getOrderTest",
    async (params, { rejectWithValue }) => {
        try {
            params.return_key = "one";
            const data = await generalHelper.apiGet("/api/order-test", params);
            if (!data) {
                return rejectWithValue([]);
            }
            return data;
        } catch (error) {
            console.log("error", error);
        }
    }
);

// ------------------------------------------------------------
export const updateOrderTest = createAsyncThunk(
    "updateOrderTest",
    async ({ id, params }, { rejectWithValue }) => {
        try {
            const data = await generalHelper.apiPut(
                "/api/order-test",
                id,
                params
            );
            if (!data) {
                return rejectWithValue([]);
            }
            return data;
        } catch (error) {
            console.log("error", error);
        }
    }
);

// ------------------------------------------------------------
export const insertOrderTest = createAsyncThunk(
    "insertOrderTest",
    async (params, { rejectWithValue }) => {
        try {
            const data = await generalHelper.apiPost("/api/order-test", params);
            if (!data) {
                return rejectWithValue([]);
            }
            return data;
        } catch (error) {
            console.log("error", error);
        }
    }
);

// ------------------------------------------------------------
export const deleteOrderTest = createAsyncThunk(
    "deleteOrderTest",
    async ({ id, params }, { rejectWithValue }) => {
        try {
            const data = await generalHelper.apiDelete(
                "/api/order-test",
                id,
                params
            );
            if (!data) {
                return rejectWithValue([]);
            }
            return data;
        } catch (error) {
            console.log("error", error);
        }
    }
);

// ------------------------------------------------------------
export const resetOrderTest = createAsyncThunk(
    "resetOrderTest",
    async (params, { rejectWithValue }) => {
        return params;
    }
);
// ------------------------------------------------------------
const initialState = {
    pending: false,
    rows: [],
    row: {},
    update: {},
    insert: {},
    delete: {},
    rejected: false,
};

// ------------------------------------------------------------
const localSlice = createSlice({
    name: "orderTest",
    initialState,
    extraReducers: {
        [getOrderTests.rejected]: (state, action) => {
            state.pending = false;
            state.rejected = true;
            state.rows = null;
        },
        [getOrderTests.pending]: (state, action) => {
            state.pending = true;
            state.rejected = false;
            state.rows = null;
        },
        [getOrderTests.fulfilled]: (state, action) => {
            state.pending = false;
            state.rejected = false;
            state.rows = action.payload;
        },
        [getOrderTest.rejected]: (state, action) => {
            state.pending = false;
            state.rejected = true;
            state.rows = null;
        },
        [getOrderTest.pending]: (state, action) => {
            state.pending = true;
            state.rejected = false;
            state.rows = null;
        },
        [getOrderTest.fulfilled]: (state, action) => {
            state.pending = false;
            state.rejected = false;
            state.row = action.payload;
        },
        [updateOrderTest.rejected]: (state, action) => {
            state.pending = false;
            state.rejected = true;
            state.update = null;
        },
        [updateOrderTest.pending]: (state, action) => {
            state.pending = true;
            state.rejected = false;
            state.update = null;
        },
        [updateOrderTest.fulfilled]: (state, action) => {
            state.pending = false;
            state.rejected = false;
            state.update = action.payload;
        },
        [insertOrderTest.rejected]: (state, action) => {
            state.pending = false;
            state.rejected = true;
            state.update = null;
        },
        [insertOrderTest.pending]: (state, action) => {
            state.pending = true;
            state.rejected = false;
            state.update = null;
        },
        [insertOrderTest.fulfilled]: (state, action) => {
            state.pending = false;
            state.rejected = false;
            state.update = action.payload;
        },
        [deleteOrderTest.rejected]: (state, action) => {
            state.pending = false;
            state.rejected = true;
            state.update = null;
        },
        [deleteOrderTest.pending]: (state, action) => {
            state.pending = true;
            state.rejected = false;
            state.update = null;
        },
        [deleteOrderTest.fulfilled]: (state, action) => {
            state.pending = false;
            state.rejected = false;
            state.update = action.payload;
        },
        [resetOrderTest.fulfilled]: (state, action) => {
            state.pending = false;
            state.rejected = false;
            action.payload.rows ? (state.rows = action.payload.rows) : false;
            action.payload.row ? (state.row = action.payload.row) : false;
            action.payload.update
                ? (state.update = action.payload.update)
                : false;
            action.payload.insert
                ? (state.insert = action.payload.insert)
                : false;
            action.payload.delete
                ? (state.delete = action.payload.delete)
                : false;
        },
    },
});

export default localSlice.reducer;

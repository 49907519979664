import { createContext, useContext, useEffect, useState, useReducer } from 'react'
import { createDevice } from './device'
import { DeviceListOptions } from './devices'
export * from './device'
export * from './reader'
export * from './config'
export * from './devices'
export * from './utils'

export const Context = createContext()

export const Provider = ({ reader, children }) => {
  const [status, setStatus] = useState('disconnected')
  const [device, setDevice] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    reader.on('error', onError)
    reader.on('connect', onConnect)
    reader.on('reconnect', onReconnect)
    reader.on('disconnect', onDisconnect)

    return () => {
      reader.off('error', onError)
      reader.off('connect', onConnect)
      reader.off('reconnect', onReconnect)
      reader.off('disconnect', onDisconnect)
      reader.disconnect()
    }
  }, [reader])

  const onConnect = () => {
    setStatus('connected')
  }

  const onReconnect = () => {
    setStatus('reconnecting')
  }

  const onDisconnect = () => {
    setStatus('disconnected')
  }

  const onError = (error) => {
    console.log('ON ERROR triggered !!!')
    setError(error)
  }

  const value = { reader, status, error, device, setDevice }

  return <Context.Provider value={value}>{children}</Context.Provider>
}

export const useReader = () => {
  return useContext(Context)
}

const reducer = (state, data) => {
  return { ...state, ...data }
}

interface UseDeviceProps {
  selectedDevice?: DeviceListOptions
  deviceType?: string
  token?: string | null
  orderId?: string
}
export const useDevice = ({ selectedDevice, deviceType, orderId, token }: UseDeviceProps) => {
  const { reader } = useReader()

  const [state, setState] = useReducer(reducer, {
    event: {},
    device: null,
    progress: 0,
    loading: false,
    connected: false,
    status: 'invactive',
  })

  useEffect(() => {
    if (!selectedDevice) return

    const device = createDevice(selectedDevice, reader)
    setState({ device })

    device.on('connected', onConnected)
    device.on('disconnected', onDisconnected)
    device.on('reading', onReading)
    device.on('read', onRead)
    device.on('resetting', onResetting)
    device.on('resetted', onResetted)
    device.on('uploading', onUploading)
    device.on('uploaded', onUploaded)
    device.on('timeout', onTimeout)
    device.on('error', onError)

    return () => {
      device.off('connected', onConnected)
      device.off('disconnected', onDisconnected)
      device.off('reading', onReading)
      device.off('read', onRead)
      device.off('resetting', onResetting)
      device.off('resetted', onResetted)
      device.off('uploading', onUploading)
      device.off('uploaded', onUploaded)
      device.off('timeout', onTimeout)
      device.destroy()
    }
  }, [selectedDevice])

  const onConnected = () => {
    setState({ progress: 0, connected: true })
  }

  const onDisconnected = () => {
    setState({ loading: false, connected: false })
  }

  const onTimeout = () => {
    setState({ loading: false })
  }

  const onReading = (event) => {
    setState({
      event,
      loading: true,
      status: 'reading',
      progress: event.progress,
    })
  }

  const onRead = (event) => {
    setState({ event, loading: false, status: 'read' })
  }

  const onResetting = (event) => {
    setState({
      event,
      loading: true,
      status: 'resetting',
      progress: event.progress,
    })
  }

  const onResetted = (event) => {
    setState({ event, loading: false, status: 'resetted' })
  }

  const onUploading = (event) => {
    setState({
      event,
      loading: true,
      status: 'uploading',
      progress: event.progress,
    })
  }

  const onUploaded = (event) => {
    setState({
      event,
      loading: false,
      status: 'uploaded',
    })
  }

  const onError = (event) => {
    setState({
      loading: false,
      status: 'error',
    })
  }

  const read = () => {
    if (selectedDevice?.connectionPath && deviceType && orderId && token && state.device.read) {
      state.device.read({
        orderId,
        token,
        deviceType,
      })
    } else {
      console.log('Missing parameter for device read operation')
    }
  }

  const reset = () => {
    if (state.device.reset && deviceType) {
      state.device.reset(deviceType)
    } else {
      console.log('Missing parameter for device reset operation')
    }
  }

  return { ...state?.device, ...state, read, reset }
}

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import Link from "../../../atoms/Link";
import CardHeader from "../../atoms/CardHeader";
import TitleLead from "../../../atoms/TitleLead";
import routes from "../../constants/routes";
import * as generalHelper from "../../../../helpers/generalHelper";

export default (props) => {
    const user = useSelector((state) => state.user.row);
    const [redirect, setRedirect] = React.useState(false);
    const { user_id, tab } = useParams();

    React.useEffect(() => {
        setRedirect(false);
    }, [tab]);

    return (
        <>
            {redirect && (
                <Navigate
                    to={`${routes.index}${routes.patient.edit}${user_id}/orders`}
                />
            )}
            <div className="container">
                <div className="col-sm-12 my-2">
                    <TitleLead
                        label="Route Information"
                        className="lead text-info"
                        icon="fad fa-truck mr-2"
                    />
                    <hr />
                </div>
                <div className="row">
                    <div className="col-sm-12">Edit Route Form (here)</div>
                </div>
            </div>
        </>
    );
};

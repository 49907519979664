import * as generalHelper from "../../helpers/generalHelper";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ------------------------------------------------------------
export const getRoutes = createAsyncThunk(
    "getRoutes",
    async (params, { rejectWithValue }) => {
        try {
            const data = await generalHelper.apiGet("/api/route", params);
            if (!data) {
                return rejectWithValue([]);
            }
            return data;
        } catch (error) {
            console.log("error", error);
        }
    }
);

// ------------------------------------------------------------
export const getRoute = createAsyncThunk(
    "getRoute",
    async (params, { rejectWithValue }) => {
        try {
            params.return_key = "one";
            const data = await generalHelper.apiGet("/api/route", params);
            if (!data) {
                return rejectWithValue([]);
            }
            return data;
        } catch (error) {
            console.log("error", error);
        }
    }
);

// ------------------------------------------------------------
export const updateRoute = createAsyncThunk(
    "updateRoute",
    async ({ id, params }, { rejectWithValue }) => {
        try {
            const data = await generalHelper.apiPut("/api/route", id, params);
            if (!data) {
                return rejectWithValue([]);
            }
            return data;
        } catch (error) {
            console.log("error", error);
        }
    }
);

// ------------------------------------------------------------
export const insertRoute = createAsyncThunk(
    "insertRoute",
    async (params, { rejectWithValue }) => {
        try {
            const data = await generalHelper.apiPost("/api/route", params);
            if (!data) {
                return rejectWithValue([]);
            }
            return data;
        } catch (error) {
            console.log("error", error);
        }
    }
);

// ------------------------------------------------------------
export const deleteRoute = createAsyncThunk(
    "deleteRoute",
    async ({ id, params }, { rejectWithValue }) => {
        try {
            const data = await generalHelper.apiDelete(
                "/api/route",
                id,
                params
            );
            if (!data) {
                return rejectWithValue([]);
            }
            return data;
        } catch (error) {
            console.log("error", error);
        }
    }
);

// ------------------------------------------------------------
const initialState = {
    pending: false,
    rows: [],
    row: {},
    update: {},
    insert: {},
    delete: {},
    rejected: false,
};

// ------------------------------------------------------------
const localSlice = createSlice({
    name: "route",
    initialState,
    extraReducers: {
        [getRoutes.rejected]: (state, action) => {
            state.pending = false;
            state.rejected = true;
            state.data = null;
        },
        [getRoutes.pending]: (state, action) => {
            state.pending = true;
            state.rejected = false;
            state.update = null;
        },
        [getRoutes.fulfilled]: (state, action) => {
            state.pending = false;
            state.rejected = false;
            state.rows = action.payload;
        },
        [getRoute.rejected]: (state, action) => {
            state.pending = false;
            state.rejected = true;
            state.data = null;
        },
        [getRoute.pending]: (state, action) => {
            state.pending = true;
            state.rejected = false;
            state.update = null;
        },
        [getRoute.fulfilled]: (state, action) => {
            state.pending = false;
            state.rejected = false;
            state.row = action.payload;
        },
        [updateRoute.rejected]: (state, action) => {
            state.pending = false;
            state.rejected = true;
            state.data = null;
        },
        [updateRoute.pending]: (state, action) => {
            state.pending = true;
            state.rejected = false;
            state.update = null;
        },
        [updateRoute.fulfilled]: (state, action) => {
            state.pending = false;
            state.rejected = false;
            state.update = action.payload;
        },
        [insertRoute.rejected]: (state, action) => {
            state.pending = false;
            state.rejected = true;
            state.data = null;
        },
        [insertRoute.pending]: (state, action) => {
            state.pending = true;
            state.rejected = false;
            state.update = null;
        },
        [insertRoute.fulfilled]: (state, action) => {
            state.pending = false;
            state.rejected = false;
            state.insert = action.payload;
        },
        [deleteRoute.rejected]: (state, action) => {
            state.pending = false;
            state.rejected = true;
            state.data = null;
        },
        [deleteRoute.pending]: (state, action) => {
            state.pending = true;
            state.rejected = false;
            state.update = null;
        },
        [deleteRoute.fulfilled]: (state, action) => {
            state.pending = false;
            state.rejected = false;
            state.delete = action.payload;
        },
    },
});

export default localSlice.reducer;

/** @jsxImportSource @emotion/react */
import { Link, useNavigate } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import ReactSelect from 'react-select'

import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { getLogout } from 'store/slice/loginSlice'
import Select from 'components/atoms/Select'
import { confirm } from 'components/molecules/Confirmation'
import Loader from 'components/atoms/Loader'
import routes from '../constants/routes'
import { switchOffice, setCurrentOfficeTests } from 'store/slice/initSlice'
import { useLazyGetTestTypesQuery } from 'store/services/testTypes'
import { useImpersonation } from 'hooks/useImpersonation'

const Nav = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { stopImpersonate } = useImpersonation()
  const { impersonate } = useAppSelector((state) => state.login)
  const userOffices = useAppSelector((state) => state.init.offices)
  const companyName = useAppSelector((state) => state.init.company.name)
  const officeId = useAppSelector((state) => state.init.currentOfficeId)
  const profile = useAppSelector((state) => state.init.profile)
  const [getTestTypes] = useLazyGetTestTypesQuery()
  const offices = userOffices.map((i) => ({
    value: i.id,
    label: `${i.officeName} - ${i.addressCity}, ${i.addressState}`,
  }))

  const handleLogout = async () => {
    try {
      await confirm('', {
        title: 'Are you sure?',
        description: 'Are you sure you want to logout?',
        yesAction: 'Yes',
        noAction: 'No',
      })
      dispatch(getLogout())
      navigate('../login')
    } catch {}
  }

  const handleChangeStore = async (value: string) => {
    try {
      await confirm('', {
        title: 'Are you sure?',
        description: "You'll lose any unsaved changes.",
        yesAction: 'Yes',
        noAction: 'No',
      })

      sessionStorage.removeItem('ts-dme-menu')
      dispatch(switchOffice(value))
      const testTypes = await getTestTypes({ officeId: value })
      dispatch(setCurrentOfficeTests(testTypes.data))
      navigate('/dme')
    } catch {
      console.log('no')
    }
  }

  return (
    <nav
      className={`main-header ${Loader()} navbar navbar-expand ${
        impersonate ? 'navbar-yellow' : 'navbar-white'
      } navbar-light p-0`}>
      <ul className="nav navbar-nav mr-auto d-flex align-items-center tsNav">
        <li className="nav-item tsNav__menu">
          {/* <a className="nav-link" data-widget="pushmenu" href="#" role="button">
            <i className="fas fa-bars text-white" />
          </a> */}
        </li>
        <li className="nav-item">
          {userOffices && userOffices?.length > 1 ? (
            <div css={{ minWidth: '220px' }}>
              <ReactSelect
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 100 }) }}
                className="basic-single w-100 mx-3"
                classNamePrefix="select"
                isSearchable
                placeholder="Select DME Office"
                name="office_id"
                onChange={(item) => handleChangeStore(item?.value)}
                options={offices}
                value={offices?.find((o) => o.value == officeId) ?? null}
              />
            </div>
          ) : (
            <div className="ml-4 d-flex align-items-center">
              {impersonate && <strong className="mr-2">Impersonating:</strong>}
              {offices?.[0]?.label}
              {impersonate && (
                <div className="ml-3">
                  <Button variant="light" onClick={stopImpersonate}>
                    Return to Lab Portal
                  </Button>
                </div>
              )}
            </div>
          )}
        </li>
      </ul>
      <ul className="navbar-nav d-flex align-items-center ml-auto">
        <li className="nav-item mr-2 text-right">
          {profile?.firstName && profile?.lastName && (
            <span className="textGray">
              {' '}
              {profile.firstName} {profile.lastName} <br />
              {companyName}
            </span>
          )}
        </li>
        {!impersonate && (
          <li className="nav-item">
            <Link to={`${routes.index}${routes.profile.root}`} className="nav-link" role="button">
              <i className="fas fa-cog" />
            </Link>
          </li>
        )}
        <li className="nav-item">
          <a className="nav-link" href="#" role="button" onClick={handleLogout}>
            <i className="fas fa-sign-out-alt" />
          </a>
        </li>
      </ul>
    </nav>
  )
}

export default Nav

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../atoms/Table";
import DashboardContent from "../atoms/DashboardContent";
import * as generalHelper from "../../../helpers/generalHelper";
import { getFiles } from "../../../store/slice/fileSlice";
import ButtonNew from "../../atoms/ButtonNew";
import ExternalLink from "../../atoms/ExternalLink";
import Link from "../../atoms/Link";
import routes from "../constants/routes";
import moment from "moment";

export default function (props) {
    const rows = useSelector((state) => state.file.rows);
    const office_id = useSelector((state) => state.init.office_id);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(
            getFiles({
                no_cache: 1,
                add_join: "office|order.patient|file_type",
                order_by: "id,DESC",
            })
        );
    }, [office_id]);
    function Content(props) {
        const handleFileLink = (row, params) => {
            console.log("row", row);
        };
        const header = [
            {
                key: "id",
                to: `${routes.index}${routes.deviceType.root}${routes.upsert}`,
                label: "Id",
            },
            { key: "office", key2: "name", label: "Office" },
            { key: "file_type", key2: "name", label: "Type" },
            { key: "url", label: "File", callback: handleFileLink },
        ];
        return (
            <div className="card card-primary">
                <form type="post" onSubmit={props.handleSubmit}>
                    <div className="card-header">
                        <h3 className="card-title"></h3>
                        <div className="card-tools">
                            {/* <ButtonNew
                                to={`${routes.index}${routes.deviceType.root}${routes.upsert}`}
                            /> */}
                        </div>
                    </div>
                    <div className="card-body row">
                        <div className="table-responsive">
                            <table className="table table-hover table-sm">
                                <thead>
                                    <tr>
                                        <th>{generalHelper.t("File ID")}</th>
                                        <th>{generalHelper.t("Office")}</th>
                                        <th>{generalHelper.t("Order")}</th>
                                        <th>{generalHelper.t("Type")}</th>
                                        <th>{generalHelper.t("User")}</th>
                                        <th>
                                            {generalHelper.t("Document Name")}
                                        </th>
                                        <th>{generalHelper.t("Date")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {rows &&
                                        Array.isArray(rows) &&
                                        rows.map((row, index) => {
                                            if (row) {
                                                return (
                                                    <tr key={index}>
                                                        <td>{row.id}</td>
                                                        <td>
                                                            {row.office?.name}
                                                        </td>
                                                        <td>{row.order?.id}</td>
                                                        <td>
                                                            {
                                                                row?.file_type
                                                                    ?.name
                                                            }
                                                        </td>
                                                        <td>
                                                            {row.order &&
                                                            row.order.patient
                                                                ? `${row.order.patient.first_name} ${row.order.patient.last_name}`
                                                                : ""}
                                                        </td>
                                                        <td>
                                                            {row && row.url && (<a
                                                                href={row.url}
                                                                target="_blank" rel="noreferrer"
                                                            >
                                                                {row.url.substring(
                                                                    row.url.lastIndexOf(
                                                                        "/"
                                                                    ) + 1
                                                                )}
                                                            </a>)}
                                                        </td>
                                                        <td>
                                                            {`${moment(
                                                                row.created_at
                                                            ).format(
                                                                "h:mm a MMM Do, YYYY"
                                                            )}`}
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        })}
                                </tbody>
                            </table>
                            {rows && rows.length == 0 && (
                                <div className="card">
                                    <div className="card-body text-center font-italic text-muted">
                                        {generalHelper.t(
                                            "No Files have been uploaded to this order"
                                        )}
                                        .
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </form>
            </div>
        );
    }

    return (
        <DashboardContent
            title="Files"
            icon="fa fa-file"
            content={Content(props)}
            breadcrumb={[
                {
                    label: "Files",
                    to: `${routes.index}${routes.file.root}`,
                },
            ]}
        />
    );
}

import { baseApi } from './baseApi'
import { Offices } from 'store/types'

export const devDiagnosticsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getOrderStates: builder.query<Offices, { orderId: number }>({
      query: ({ orderId }) => ({ url: `/tool/list-states/${orderId}` }),
    }),
  }),
})

export const { useGetOrderStatesQuery } = devDiagnosticsApi

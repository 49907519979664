import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashboardContent from "../atoms/DashboardContent";
import { useParams } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { handleChange, handleSubmit } from "../../atoms/Upsert";
import Input from "../../atoms/Input";
import Textarea from "../../atoms/Textarea";
import Select from "../../atoms/Select";
import ButtonCancel from "../../atoms/ButtonCancel";
import ButtonSave from "../../atoms/ButtonSave";
import * as generalHelper from "../../../helpers/generalHelper";
import {
    getRoute,
    insertRoute,
    updateRoute,
} from "../../../store/slice/routeSlice";
import routes from "../constants/routes";

export default function (props) {
    const dispatch = useDispatch();
    const rows = [];
    const [values, setValues] = useState({});
    const [redirect, setRedirect] = useState(false);
    const { id } = useParams();

    React.useEffect(() => {
        if (id) {
            dispatch(getRoute({ id: id })).then((response) => {
                setValues(response.payload);
            });
        }
    }, []);

    function Content(props) {
        return (
            <div className="card card-primary">
                <form
                    type="post"
                    onSubmit={(event) => {
                        handleSubmit(event, {
                            id: id,
                            values: values,
                            functions: {
                                insert: insertRoute,
                                update: updateRoute,
                            },
                            callback: {
                                function: setRedirect,
                                params: `${routes.index}${routes.route.root}`,
                            },
                            dispatch: dispatch,
                        });
                    }}
                >
                    <div className="card-body row">
                        {/* <div className="col-6">
                            <Select
                                label="Driver"
                                name="route_type_id"
                                options={rows}
                                value={values.route_type_id}
                                handleChange={(event) => {
                                    handleChange(event, values, setValues);
                                }}
                            />
                        </div> */}

                        <div className="col-6">
                            <Input
                                label="Name"
                                name="name"
                                value={values.name}
                                handleChange={(event) => {
                                    handleChange(event, values, setValues);
                                }}
                            />
                        </div>
                    </div>
                    <div className="card-footer text-right">
                        <ButtonCancel /> <ButtonSave />
                    </div>
                </form>
            </div>
        );
    }

    return (
        <div>
            {redirect && <Navigate to={redirect} />}
            <DashboardContent
                title={id ? "Edit Route" : "New Route"}
                icon="fas fa-truck"
                content={Content(props)}
                breadcrumb={[
                    {
                        label: "Routes",
                        to: `${routes.index}${routes.route.root}`,
                    },
                    { label: id ? "Edit" : "New" },
                ]}
            />
        </div>
    );
}

/** @jsxImportSource @emotion/react */
import { SyntheticEvent, ReactNode, useMemo } from 'react'
import { Field, FormikErrors, FormikTouched } from 'formik'
import ReactSelect from 'react-select'

import suffixes from 'components/dme/constants/suffixes'
import InputMask from '../../atoms/InputMask'
import * as generalHelper from '../../../helpers/generalHelper'

export interface FormValues {
  firstName: string
  lastName: string
  middleName: string
  suffix: string
  dob: string
  gender: string
  officeId: string
}

interface IProps {
  values: FormValues
  errors: FormikErrors<FormValues>
  touched: FormikTouched<FormValues>
  handleChange: (e: SyntheticEvent<Element, Event>) => void
  handleBlur: (e: SyntheticEvent<Element, Event>) => void
  submitButton: ReactNode
  offices: any[]
  setFieldValue: (name: string, v: any) => void
}

const NewPatientForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  submitButton,
  offices,
  setFieldValue,
}: IProps) => {
  const officesOptions = useMemo(
    () =>
      offices?.map((o) => ({
        value: o.id,
        label: `${o.officeName} (${o.addressCity}, ${o.addressState})`,
      })),
    [offices],
  )
  return (
    <>
      <div className="form-row">
        <div className="col form-group">
          <label htmlFor="firstName">
            First Name
            <span className="text-danger">*</span>
          </label>
          <Field
            type="text"
            name="firstName"
            className="form-control"
            placeholder="First Name"
            autoComplete="one-time-code"
          />
        </div>

        <div className="col form-group">
          <label htmlFor="middleName">Middle Name</label>
          <Field
            type="text"
            name="middleName"
            className="form-control"
            placeholder="Middle Name"
            autoComplete="one-time-code"
          />
        </div>

        <div className="col form-group">
          <label htmlFor="middleName">
            Last Name
            <span className="text-danger">*</span>
          </label>
          <Field
            type="text"
            name="lastName"
            className="form-control"
            placeholder="Last Name"
            autoComplete="one-time-code"
          />
        </div>

        <div className="col form-group">
          <label htmlFor="state">Suffix</label>
          <Field className="form-control" as="select" name="suffix">
            {suffixes?.map((s, i) => (
              <option key={i} value={s.name}>
                {s.name}
              </option>
            ))}
          </Field>
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-3 form-group">
          <label htmlFor="dob">
            {generalHelper.t('Date Of Birth (MM/DD/YYYY)')}
            <span className="text-danger">*</span>
          </label>
          <InputMask
            value={values.dob ?? ' '}
            className="form-control"
            name="dob"
            onChange={handleChange}
            onBlur={handleBlur}
            mask="99/99/9999"
            error={touched.dob ? errors.dob : undefined}
          />
        </div>

        <div className="col-sm-3 form-group">
          <label htmlFor="gender">
            Gender <span className="text-danger">*</span>
          </label>
          <Field className="form-control" as="select" name="gender" placeholder="Select Gender">
            <option value="">Select a Gender</option>
            <option value="F">Female</option>
            <option value="M">Male</option>
          </Field>
        </div>
        <div
          className="col-sm-3 form-group"
          css={{ '& .select__control': { height: '34px', minHeight: '34px' } }}>
          <label htmlFor="officeId">DME Office</label>
          <ReactSelect
            className="basic-single w-100 mr-3"
            classNamePrefix="select"
            isClearable
            isSearchable
            placeholder="Select DME Office"
            name="officeId"
            onChange={(item) => setFieldValue('officeId', item?.value)}
            options={officesOptions}
            value={officesOptions?.find((o) => o.value === values.officeId) ?? null}
          />
        </div>
        <div className="col-sm-3 form-group d-flex justify-content-end align-items-end">
          {submitButton}
        </div>
      </div>
    </>
  )
}

export default NewPatientForm

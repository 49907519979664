export const cannedNotes = [
  {
    title: "Christy: Insurance Calls",
    content:
`Insurance:
Phone:
Member ID:
Plan:
Eff:
Payer ID:
Claims Address:
Auth Requirements?
Rep:
Ref#`
  }, {
    title: "Christy: Insurance Calls (HST)",
    content:
`Insurance:
Phone:
Member ID:
Group #:
Plan:
Eff:
Payer ID:
Claims Address:
Auth Requirements:
HST Code(s) Requested:
Fee schedule:
POS: 12 (Home)
*In-Network benefits-
    Co-ins %:
    Patient Responsibility: $____
    Ded: $____ / Amount met: $____
    OOP: $____ / Amount met: $____
*Out-of-Network benefits-
    Co-ins %:
    Patient Responsibility: $____
    Ded: $____ / Amount met: $____
    OOP: $____ / Amount met: $____
Rep:
Ref#`
  }, {
    title: "Christy: Insurance Calls (Optum VA)",
    content: `Phone:
ID:
AUTH #: VA
EFF DATES:
PAYER ID:
REP:
REF#`
  }, {
    title: "Christy: Authorization Approval",
    content:
`AUTH #
EFF DATES:
DX CODE (S):
POS:`
  }, {
    title: "Christy: Office Notes Request (Authorization Requirement)",
    content: `FAXED REQUEST FOR OFFICE NOTES TO ORDERING PHYSICIAN, (AUTH REQUIREMENT).`
  }, {
    title: "Christy: Received Office Notes / Authorization Requested",
    content: `RECEIVED OFFICE NOTES, FAXED AUTHORIZATION REQUEST.`
  }, {
    title: "Christy: Authorization Requested",
    content: `AUTHORIZATION REQUEST SUBMITTED VIA FAX.`
  }, {
    title: "Christy: Retro-Authorizations",
    content: `*DOES NOT ACCEPT RETRO-AUTHS.`
  }, {
    title: "Christy: Insurance Verification",
    content: `Insurance: ACTIVE & BILLABLE ✔`
  }, {
    title: "Christy: Insurance Verification (Primary & Secondary)",
    content: `Primary & Secondary: ACTIVE & BILLABLE ✔`
  }, {
    title: "Christy: Insurance Verification - Secondary (Crossover)",
    content: `SECONDARY: CROSS-OVER`
  }, {
    title: "Amber: Fax - Number Verified - Resubmitted",
    content: `THE FAX NUMBER MATCHES THE REFERRAL. RE-FAXED`
  }, {
    title: "Amber: Fax - Number Verified - Send via Webfax",
    content: `SENT VIA WEBFAX. FOLLOW-UP DATE SET FOR CONFIRMATION`
  }, {
    title: "Amber: Fax - Delivered - Webfax",
    content: `Fax delivered via WebFax - PROCEED WITH BILLING`
  }, {
    title: "Amber: Fax - Physician&#39;s Office Information Updated - Resubmitted",
    content: `OFFICE INFORMATION UPDATED TO REFLECT THE REFERRAL- FAXED TO UPDATED NUMBER`
  }, {
    title: "Amber: Fax - Task",
    content: `TASK SENT to Call the physician&#39;s office to confirm office info and obtain a reliable fax number.`
  }, {
    title: "Amber: Fax - Office Information Confirmed By Phone",
    content: `CALLED AND CONFIRMED FAX NUMBER/OFFICE INFORMATION UPDATED /REFAXED`
  }, {
    title: "Amber: Testing Condition - Confirmed RA",
    content: `TESTING CONDITIONS/ ON ROOM AIR`
  }, {
    title: "Amber: Testing Conditions - Updated",
    content: `Testing Conditions UPDATED TO REFLECT REFERRAL`
  }, {
    title: "Amber: Testing Conditions - Unspecified",
    content: `TESTING CONDITIONS UNSPECIFIED / ON ROOM AIR`
  }, {
    title: "Amber: Testing Conditions PAP unspecified",
    content: `Testing Conditions UPDATED TO REFLECT REFERRAL, PAP SETTING UNSPECIFIED`
  }

]
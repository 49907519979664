/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled'
import DataTable from 'components/atoms/DataTable'
import { TableWrapper } from 'components/atoms/TableWrapper'
import * as generalHelper from 'helpers/generalHelper'
import { useOrdersTable } from 'hooks/useOrdersTable'
import moment from 'moment'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import ReactSelect from 'react-select'
import { useDebounce } from 'use-debounce'
import { useAppSelector } from '../../../hooks/redux'
import {
  useGetPhysicianGroupPhysiciansQuery,
  useGetPhysicianOrdersQuery,
} from '../../../store/services/physician'
import Dropdown from '../../atoms/Dropdown'
import Input from '../../atoms/Input'
import InputMask from '../../atoms/InputMask'
import ToggleBtnGroup from '../../atoms/ToggleBtnGroup'
import DashboardContent from '../../dme/atoms/DashboardContent'
import { SearchParams } from '../../lab/order/Order'
import OrderTableColumns from '../../dme/order/OrderTableColumns'

const Wrap = styled.div`
  background: white;
  border-top: 3px solid #2c83bd;
  border-radius: 3px;
  padding: 10px;
  box-shadow: 0 1px 3px 1px #e8e5e5;
  margin-bottom: 10px;
`

const StyledTableWrapper = styled(TableWrapper)`
  margin-top: 20px;

  th {
    padding: 0 0.2rem 0.4rem 0.1rem;
  }

  td[role='cell'] {
    padding: 0.6rem 0.2rem;
    height: 40px;
  }

  td {
    vertical-align: middle;
    padding: 0.6rem;
    div {
      span {
        font-size: 0.9rem;
      }
    }
  }
`

const InputWrapper = styled.div`
  flex: 1;
  input,
  select {
    height: 38px;
  }
`

const initialSearch: SearchParams = {
  orderId: '',
  patientFirstName: '',
  patientLastName: '',
  patientDob: '',
  physicianIds: '',
}

const PhysicianOrderListing = () => {
  const { type } = useParams()
  const [search, setSearch] = useState<SearchParams>(initialSearch)
  const clearSearch = () => setSearch(initialSearch)
  const [debouncedSearch] = useDebounce(search, 1000)
  const tableHelpers = useOrdersTable(debouncedSearch, clearSearch)
  const patientDobText = debouncedSearch?.patientDob?.replaceAll(' ', '')
  const patientDob = patientDobText?.length === 10 ? moment(patientDobText) : null
  const [npis, setNpis] = useState<string[]>([])
  const [testType, setTestType] = useState('ALL')

  const physicianGroup = useAppSelector((state) => state.init.physicianGroup)

  const { data: physicians, isLoading: isNpisLoading } = useGetPhysicianGroupPhysiciansQuery({
    id: Number(physicianGroup),
  })

  const physicianOptions = useMemo(
    () => physicians?.map((p) => ({ value: p.npi, label: p.name })),
    [physicians],
  )

  useEffect(() => {
    setNpis([...(physicians?.map(({ npi }) => npi) || [])])
  }, [physicians])

  const {
    data: orders,
    isLoading,
    refetch: refetchOrders,
  } = useGetPhysicianOrdersQuery(
    {
      orderId: search.orderId,
      types: testType === 'ALL' ? 'POX,COX,HST,PSG' : testType,
      buckets: type !== 'open' ? 'CANCELLED' : 'ACTIVE',
      physicianNpis: npis.length ? npis : undefined,
      page: tableHelpers.currentPage,
      size: tableHelpers.pageSize,
      sortField: tableHelpers.sortField,
      sortOrder:
        tableHelpers.sortField === 'deviceAssignedAt'
          ? tableHelpers.sortOrder === 'asc'
            ? 'desc'
            : 'asc'
          : tableHelpers?.sortOrder,
      ...generalHelper.cleanObj(search, true),
      ...(debouncedSearch.patientDob && {
        patientDob: patientDob?.isValid() ? patientDob?.format('YYYY-MM-DD') : undefined,
      }),
    },
    {
      pollingInterval: 60000,
      refetchOnFocus: true,
      skip: isNpisLoading || !npis.length,
    },
  )

  const changeSearch = (field: string, value: string | boolean) => {
    setSearch((p) => ({ ...p, [field]: value }))
  }

  return (
    <div>
      <DashboardContent
        title="Physician Orders"
        content={
          <div className="card card-primary mt-1 p-3">
            <Wrap>
              <div
                css={{
                  marginTop: 5,
                  marginBottom: 15,
                  paddingBottom: 15,
                  borderBottom: '1px solid #ced4da',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                <div
                  css={{
                    fontSize: 18,
                  }}>
                  {type === 'open' ? 'Open' : 'Cancelled'} orders
                </div>
                <ToggleBtnGroup
                  options={[
                    {
                      key: 'ALL',
                      name: 'All',
                    },
                    {
                      key: 'POX',
                      name: 'Pulse-Oximetry',
                    },
                    {
                      key: 'COX',
                      name: 'Capnography',
                    },
                    {
                      key: 'HST',
                      name: 'Home Sleep Test',
                    },
                    {
                      key: 'PSG',
                      name: 'In-Lab Sleep Test',
                    },
                  ]}
                  value={testType}
                  onChange={(testType) => setTestType(testType)}
                />
                <div>
                  <Dropdown
                    initialValue={tableHelpers.pageSize}
                    onClick={(v) => {
                      tableHelpers.setCurrentPage(0)
                      tableHelpers.setPageSize(Number(v))
                    }}
                    buttonTypeClass="btn-outline-primary"
                    options={[
                      { label: '12' },
                      {
                        label: '20',
                      },
                      {
                        label: '50',
                      },
                      {
                        label: '100',
                      },
                      {
                        label: '200',
                      },
                    ]}
                    additionalText="Records"
                  />
                </div>
              </div>
              <div className={'d-flex justify-content-between'}>
                <InputWrapper className="col">
                  <Input
                    value={search.orderId}
                    placeholder="Order ID"
                    label="Order ID"
                    name="orderId"
                    handleChange={(e) => changeSearch('orderId', e.target.value)}
                  />
                </InputWrapper>
                <InputWrapper className="col">
                  <Input
                    value={search.patientFirstName}
                    placeholder="Patient First Name"
                    label="Patient First Name"
                    name="patientFirstName"
                    handleChange={(e) => changeSearch('patientFirstName', e.target.value)}
                  />
                </InputWrapper>
                <InputWrapper className="col" style={{ flex: 1 }}>
                  <Input
                    value={search.patientLastName}
                    placeholder="Patient Last Name"
                    label="Patient Last Name"
                    name="patientLastName"
                    handleChange={(e) => changeSearch('patientLastName', e.target.value)}
                  />
                </InputWrapper>
                <InputWrapper className="col" style={{ flex: 1 }}>
                  <InputMask
                    value={search.patientDob}
                    placeholder="Patient Date of Birth"
                    label="Patient Date of Birth"
                    type="text"
                    name="patientDob"
                    mask="99/99/9999"
                    onChange={(event: ChangeEvent) => {
                      changeSearch('patientDob', event.target.value)
                    }}
                  />
                </InputWrapper>
                <div className="col" style={{ flex: 1 }}>
                  <label className="text-gray" htmlFor="physicianIds">
                    Physician
                  </label>
                  <ReactSelect
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 10 }) }}
                    className="basic-single w-100 mr-3"
                    classNamePrefix="select"
                    isClearable
                    isSearchable
                    placeholder="Select Physician"
                    name="physicianIds"
                    onChange={(item) =>
                      setNpis(
                        item?.value
                          ? [item?.value]
                          : [...(physicians?.map(({ npi }) => npi) || [])],
                      )
                    }
                    options={physicianOptions}
                    value={
                      physicianOptions?.find((p) => npis.length === 1 && npis.includes(p.value)) ??
                      null
                    }
                    onInputChange={(v) => v && changeSearch('physicianName', v)}
                  />
                </div>
              </div>
              <StyledTableWrapper
                className="card-body row"
                css={{
                  overflowY:
                    !orders?.items?.length || orders?.items?.length < 10 ? 'unset' : 'auto',
                }}>
                <DataTable
                  data={orders}
                  status={isLoading}
                  tableColumns={OrderTableColumns(
                    ['deviceAssignedAt', 'notes', 'messages'],
                    'POX',
                    true,
                  )}
                  select={false}
                  pageSize={tableHelpers.pageSize}
                  setCurrentPage={tableHelpers.setCurrentPage}
                  setSortOptions={tableHelpers.setSortOptions}
                />
              </StyledTableWrapper>
            </Wrap>
          </div>
        }
        breadcrumb={[
          { label: 'Home' },
          { label: `${type === 'open' ? 'Open' : 'Cancelled'} Physicians Orders` },
        ]}
      />
    </div>
  )
}

export default PhysicianOrderListing

/** @jsxImportSource @emotion/react */
import { useMemo } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import { requiredPhoneValidation } from 'helpers/validate'
import { LabPayerFormValues, LabPayerForm } from './LabPayerForm'

interface IProps {
  open: boolean
  onClose: () => void
  payorDetails?: Partial<LabPayerFormValues> | null
  onSave: (payor: LabPayerFormValues) => void
}

export const payorValidation = (requirePayerId: boolean) =>
  Yup.object().shape({
    name: Yup.string().required('Required'),
    telephone: requiredPhoneValidation,
    addressStreetLine1: Yup.string().optional(),
    addressCity: Yup.string().optional(),
    addressState: Yup.string().optional(),
    addressZip: Yup.string().optional(),
    ...(requirePayerId && {
      uPayerId: Yup.string().required('Required'),
    }),
  })

const LabPayorDetailsModal = ({ open, payorDetails, onSave, onClose }: IProps) => {
  const initialValues: LabPayerFormValues = useMemo(
    () => ({
      name: payorDetails?.name ?? payorDetails?.names?.[0]?.name ?? '',
      verificationLink: payorDetails?.verificationLink ?? '',
      telephone: payorDetails?.telephone?.replace(/^\+1/, '') ?? '',
      ourProviderId: payorDetails?.ourProviderId ?? '',
      uPayerId: payorDetails?.uPayerId ?? '',
      addressStreetLine1: payorDetails?.addressStreetLine1 ?? '',
      addressStreetLine2: payorDetails?.addressStreetLine2 ?? '',
      addressCity: payorDetails?.addressCity ?? '',
      addressState: payorDetails?.addressState ?? '',
      addressZip: payorDetails?.addressZip ?? '',
      planId: payorDetails?.planId ?? '',
      active: payorDetails?.active ?? false,
      inNetwork: payorDetails?.inNetwork ?? false,
      notes: payorDetails?.notes ?? '',
      billingLocationCode: payorDetails?.billingLocationCode ?? '',
    }),
    [payorDetails],
  )
  const validation = useMemo(
    () => payorValidation(!!payorDetails?.uPayerId),
    [payorDetails?.uPayerId],
  )

  return (
    <Modal centered show={open} onHide={onClose}>
      <Modal.Header>
        <Modal.Title>Payor Details</Modal.Title>
      </Modal.Header>
      {open ? (
        <Formik
          enableReinitialize={true}
          validationSchema={validation}
          initialValues={initialValues}
          onSubmit={onSave}>
          {({ isValid }) => (
            <Form>
              <Modal.Body>
                <LabPayerForm />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="outline-secondary" onClick={onClose}>
                  Cancel
                </Button>
                <Button type="submit" disabled={!isValid}>
                  Confirm
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      ) : null}
    </Modal>
  )
}

export default LabPayorDetailsModal

/** @jsxImportSource @emotion/react */
import { Field, FormikProps } from 'formik'
import GoogleAutocomplete from 'components/atoms/GoogleAutocomplete'
import MaskInput from 'components/atoms/MaskInput'
import { phoneNumberMask, zipMask } from 'helpers/masks'
import { getAddressFields } from '../forms/utils/userFormUtils'
import states from 'constants/states'
import { PhysicianFormValues } from './PhysicianInfoContainer'

const PhysicianForm = ({
  values,
  handleChange,
  setFieldValue,
}: FormikProps<PhysicianFormValues>) => {
  return (
    <>
      <div className="container-fluid" css={{ maxWidth: '810px' }}>
        <div className="row">
          <div className="col-3 form-group">
            <label htmlFor="npi" className="textGray">
              NPI
              <span className="text-danger">*</span>
            </label>
            <Field id="npi" name="npi" className="form-control" disabled />
          </div>

          <div className="col-3 form-group">
            <label htmlFor="firstName" className="textGray">
              First Name
              <span className="text-danger">*</span>
            </label>
            <Field
              id="firstName"
              name="firstName"
              className="form-control"
              disabled
              autoComplete="one-time-code"
            />
          </div>

          <div className="col-3 form-group">
            <label htmlFor="lastName" className="textGray">
              Last Name
              <span className="text-danger">*</span>
            </label>
            <Field
              id="lastName"
              name="lastName"
              className="form-control"
              disabled
              autoComplete="one-time-code"
            />
          </div>

          <div className="col-3 form-group">
            <label htmlFor="suffix" className="textGray">
              Suffix
            </label>
            <Field id="suffix" name="suffix" className="form-control" />
          </div>
        </div>

        <div className="row">
          <div className="col-3 form-group">
            <label htmlFor="telephone">
              Telephone
              <span className="text-danger">*</span>
            </label>
            <MaskInput
              value={values.telephone}
              onChange={handleChange}
              name="telephone"
              mask={phoneNumberMask}
              placeholder="(###) ###-####"
            />
          </div>

          <div className="col-3 form-group">
            <label className="textGray">
              Fax Number
              <span className="text-danger">*</span>
            </label>
            <MaskInput
              value={values.fax}
              onChange={handleChange}
              name="fax"
              mask={phoneNumberMask}
              placeholder="(###) ###-####"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-6 form-group">
            <label htmlFor="addressStreetLine1" className="textGray">
              Address
            </label>
            <GoogleAutocomplete
              id="addressStreetLine1"
              name="addressStreetLine1"
              onInputChanged={(v) => setFieldValue('addressStreetLine1', v)}
              onChange={(place) => {
                const { address, city, state, zip } = getAddressFields(place, 'addressStreetLine1')
                setFieldValue('address', address ?? '')
                setFieldValue('city', city ?? '')
                setFieldValue('state', state ?? '')
                setFieldValue('zip', zip ?? '')
              }}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-6 form-group">
            <Field
              type="text"
              name="addressStreetLine2"
              className="form-control"
              placeholder="Suite / Apt"
            />
          </div>
          <div className="col-6"></div>
        </div>

        <div className="row">
          <div className="col-2 form-group">
            <label htmlFor="addressCity">City</label>
            <Field type="text" name="addressCity" className="form-control" placeholder="City" />
          </div>

          <div className="col-2 form-group">
            <label htmlFor="addressState">State</label>
            <Field className="form-control" as="select" name="addressState" placeholder="State">
              {states.map((s, i) => (
                <option key={i} value={s.id}>
                  {s.name}
                </option>
              ))}
            </Field>
          </div>

          <div className="col-2 form-group">
            <label htmlFor="addressZip">Zip Code</label>
            <MaskInput
              value={values.addressZip}
              onChange={handleChange}
              name="addressZip"
              mask={zipMask}
              placeholder="#####-####"
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default PhysicianForm

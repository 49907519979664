// Capnostream 35 data is stored in a CSV (comma-separated value) format with UTF-16LE encoding.
// This function decodes that data and returns a UTF-8 string.
function parseCapnostream35DataFile(buf: ArrayBuffer): string {
  const [ bom_byte1, bom_byte2 ] = new Uint8Array(buf, 0, 2);
  if (bom_byte1 === 0xFF && bom_byte2 === 0xFE) {
    const tmp = new Uint16Array(buf)
      .map((value: number): number => {
        if (value === 0x2082) {
          // Replace subscript 2 with normal 2
          return String('2').charCodeAt(0);
        }
        return value;
      })
      .filter((value) => {
        return (value === 9 || value === 10 || (value >= 32 && value <= 126));
      });
    buf = new Uint8Array(tmp);
  } else {
    buf = new Uint8Array(buf);
  }
  return (new TextDecoder('utf-8')).decode(buf);
}

// We read the Capnostream35 files locally, so they can be injected into a JSON payload.
export async function readCapnostream35DataFile(file: File): Promise<string> {
  return new Promise((resolve, reject): void => {

    // Use the FileReader interface to read the file locally
    const r: FileReader = new FileReader();

    // Once loaded, we have the file contents
    r.addEventListener('load', (): void => {

      // First we parse the file to convert to UTF-8
      const data = parseCapnostream35DataFile(r.result as ArrayBuffer);

      // Then we return the file contents encapsulated in Base64
      resolve(btoa(data));

    });

    // Handle errors
    r.addEventListener('error', (error): void => {
      reject(new Error(`Unable to read ${file.name}.`));
    });

    // Read the file into an array buffer
    r.readAsArrayBuffer(file);
  });
}
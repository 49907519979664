import { SyntheticEvent, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

import Toast from 'components/atoms/Toast'
import Input from 'components/atoms/Input'
import { useMoveDeviceDataMutation } from 'store/services/labOrder'

interface IProps {
  show?: boolean
  onClose: () => void
  sourceOrder: number
}

export const MoveReportDialog = ({ show, onClose, sourceOrder }: IProps) => {
  const [targetOrder, setTargetOrder] = useState('')
  const [error, setError] = useState('')
  const [moveDeviceData] = useMoveDeviceDataMutation()

  return (
    <Modal centered show={!!show} onHide={onClose}>
      <Modal.Header>
        <Modal.Title>Move Report?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span className="text-bold text-gray">Attention</span>
        <br />
        <br />
        Are you sure you want to delete all report and device data from this order?
        <br />
        <br />
        If so, please enter the target order ID and submit:
        <br />
        <Input
          handleChange={(e: SyntheticEvent) => setTargetOrder(e.target.value)}
          name="cancelReason"
          label=""
          value={targetOrder}
          className="form-control mt-2 w-50"
        />
        {error && <div className="text-danger">{error}</div>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          autoFocus
          variant="primary"
          onClick={async () => {
            setError('')
            try {
              await moveDeviceData({
                orderId: sourceOrder,
                targetOrderId: Number(targetOrder),
              }).unwrap()
              onClose()
              setTargetOrder('')
              Toast({ type: 'success', label: `Report deleted and device data moved.` })
            } catch (e) {
              if (e.data?.message?.statusCode === 404) return setError('Order not found')
              setError(e.data.message)
            }
          }}>
          Move Report
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

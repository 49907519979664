/** @jsxImportSource @emotion/react */
import { useEffect, useMemo, useRef } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { skipToken } from '@reduxjs/toolkit/query'
import Button from 'react-bootstrap/Button'
import { Field, Form, Formik } from 'formik'
import Toast from 'components/atoms/Toast'
import * as Yup from 'yup'
import moment from 'moment'
import {
  MDXEditor,
  headingsPlugin,
  UndoRedo,
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  InsertImage,
  ListsToggle,
  toolbarPlugin,
  listsPlugin,
  quotePlugin,
  imagePlugin,
  linkPlugin,
  linkDialogPlugin,
  CreateLink,
} from '@mdxeditor/editor'

import {
  useCreateBulletinMutation,
  useUpdateBulletinMutation,
  useGetBulletinQuery,
} from 'store/services/bulletins'
import InputMask from 'components/atoms/InputMask'
import DashboardContent from '../atoms/DashboardContent'
import routes from '../constants/routes'

import '@mdxeditor/editor/style.css'

const validation = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  message: Yup.string().required('Message is required'),
  showAfter: Yup.string().required('Active From is required'),
  showUntil: Yup.string().required('Active To is required'),
})

interface FormValues {
  message: string // required
  title?: string // optional
  showAfter?: string // expects ISO-8601 formatted date/time string
  showUntil?: string // expects ISO-8601 formatted date/time string
  priority?: number // optional
}

const initialValues: FormValues = {
  message: '',
  title: '',
  showAfter: '',
  showUntil: '',
  priority: 0,
}

const BulletinUpsert = () => {
  const navigate = useNavigate()
  const { bulletinId } = useParams()
  const [createBulletin] = useCreateBulletinMutation()
  const [updateBulletin] = useUpdateBulletinMutation()
  const { data: bulletin } = useGetBulletinQuery(bulletinId ? Number(bulletinId) : skipToken)
  const markdownRef = useRef(null)

  const init = useMemo(() => {
    if (!bulletin) return null

    return {
      title: bulletin.title,
      message: bulletin.message,
      showAfter: moment(bulletin.showAfter).format('MM/DD/YYYY'),
      showUntil: moment(bulletin.showUntil).format('MM/DD/YYYY'),
      priority: bulletin.priority,
    }
  }, [bulletin])

  useEffect(() => {
    if (!init) return
    markdownRef.current?.setMarkdown(init.message)
  }, [init])

  const imageUploadHandler = async (image: File) => {
    const formData = new FormData()
    formData.append('image', image)
    // send the file to your server and return
    // the URL of the uploaded image in the response
    const response = await fetch('/uploads/new', {
      method: 'POST',
      body: formData,
    })
    const json = (await response.json()) as { url: string }
    return json.url
  }

  return (
    <div>
      <DashboardContent
        icon="fas fa-building"
        title={bulletinId ? 'Edit Bulletin' : 'Create Bulletin'}
        subtitle={
          <div>
            <Link to={`${routes.index}${routes.bulletins.root}${routes.bulletins.directory}`}>
              {`< Back to Bulletin Directory`}
            </Link>
          </div>
        }
        breadcrumb={[
          {
            label: 'Bulletins',
            to: `${routes.index}${routes.bulletins.root}`,
          },
          { label: bulletinId ? 'Edit' : 'New' },
        ]}
        content={
          <>
            <div className="card-body row justify-content-center">
              <div className="col-md-12 col-lg-6 mb-3">
                <div className="card card-primary p-2">
                  <div
                    className="card-header"
                    css={{
                      color: 'black !important',
                      backgroundColor: 'transparent !important',
                    }}>
                    <h3 className="card-title">BULLETIN INFORMATION</h3>
                  </div>
                  <Formik
                    enableReinitialize={true}
                    validationSchema={validation}
                    initialValues={init ?? initialValues}
                    onSubmit={async (values) => {
                      try {
                        if (bulletinId) {
                          await updateBulletin({
                            id: Number(bulletinId),
                            ...values,
                          }).unwrap()
                          Toast({ type: 'success', label: `Bulletin updated!` })
                        } else {
                          await createBulletin(values).unwrap()
                          navigate(
                            `${routes.index}${routes.bulletins.root}${routes.bulletins.directory}`,
                          )
                          Toast({ type: 'success', label: `Bulletin created!` })
                        }
                      } catch (err) {
                        Toast({ type: 'error', label: err.data.message })
                      }
                    }}>
                    {({ values, errors, touched, handleChange, setFieldValue }) => (
                      <Form id="BulletinUpsert" autoComplete="one-time-code">
                        <div className={`container bg-white mb-3 px-1 p-0`}>
                          <div className={`container pt-3 pb-1`}>
                            <div className="row">
                              <div className="col-10 form-group textGray">
                                <label htmlFor="title">
                                  Title
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  name="title"
                                  className="form-control"
                                  placeholder="Title"
                                  value={values.title}
                                  onChange={handleChange}
                                />
                                {touched.title && errors.title && (
                                  <div className="text-danger">{errors.title}</div>
                                )}
                              </div>
                              <div className="col-2 form-group textGray">
                                <label htmlFor="priority">
                                  Priority
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  name="priority"
                                  className="form-control"
                                  placeholder="Title"
                                  value={values.priority}
                                  onChange={handleChange}
                                />
                                {touched.priority && errors.priority && (
                                  <div className="text-danger">{errors.priority}</div>
                                )}
                              </div>
                            </div>
                            <div className="row">
                              <div
                                className="col-12 form-group textGray"
                                css={{
                                  '& .mdxeditor': {
                                    border: '1px solid #ccc',
                                    borderRadius: '5px',
                                    '& .markdown-editor': {
                                      '& h1,h2,h3,h4,h5': {
                                        fontWeight: 'bold',
                                      },
                                      '& h1': {
                                        fontSize: '2em',
                                      },
                                      '& h2': {
                                        fontSize: '1.5em',
                                      },
                                      '& h3': {
                                        fontSize: '1.17em',
                                      },
                                      '& h4': {
                                        fontSize: '1em',
                                      },
                                      '& h5': {
                                        fontSize: '0.83em',
                                      },
                                      '& ol': {
                                        listStyleType: 'decimal',
                                      },
                                      '& ul': {
                                        listStyleType: 'disc',
                                      },
                                    },
                                  },
                                }}>
                                <label htmlFor="message">
                                  Message
                                  <span className="text-danger">*</span>
                                </label>
                                <MDXEditor
                                  ref={markdownRef}
                                  contentEditableClassName="markdown-editor"
                                  markdown={values.message}
                                  plugins={[
                                    toolbarPlugin({
                                      toolbarContents: () => (
                                        <>
                                          {' '}
                                          <UndoRedo />
                                          <BlockTypeSelect />
                                          <BoldItalicUnderlineToggles />
                                          <ListsToggle />
                                          <InsertImage />
                                          <CreateLink />
                                        </>
                                      ),
                                    }),
                                    headingsPlugin(),
                                    listsPlugin(),
                                    quotePlugin(),
                                    linkPlugin(),
                                    linkDialogPlugin(),
                                    imagePlugin({ imageUploadHandler }),
                                  ]}
                                  onChange={(value) => setFieldValue('message', value)}
                                />
                                {touched.message && errors.message && (
                                  <div className="text-danger">{errors.message}</div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-6 form-group textGray">
                              <label htmlFor="npi">
                                Active From
                                <span className="text-danger">*</span>
                              </label>
                              <InputMask
                                value={values.showAfter ?? ' '}
                                className="form-control"
                                placeholder="MM/DD/YYYY"
                                name="showAfter"
                                onChange={handleChange}
                                error={errors?.showAfter}
                                mask="99/99/9999"
                              />
                              {touched.showAfter && errors.showAfter && (
                                <div className="text-danger">{errors.showAfter}</div>
                              )}
                            </div>
                            <div className="col-6 form-group textGray">
                              <label htmlFor="npi">
                                Active To
                                <span className="text-danger">*</span>
                              </label>
                              <InputMask
                                value={values.showUntil ?? ' '}
                                className="form-control"
                                placeholder="MM/DD/YYYY"
                                name="showUntil"
                                onChange={handleChange}
                                error={errors?.showUntil}
                                mask="99/99/9999"
                              />
                              {touched.showUntil && errors.showUntil && (
                                <div className="text-danger">{errors.showUntil}</div>
                              )}
                            </div>
                          </div>

                          <div className="col-sm-12 pt-3 pr-0 border-top">
                            <div className="col-sm-12 d-flex justify-content-end">
                              <Button variant="primary" type="submit">
                                Save
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </>
        }
      />
    </div>
  )
}

export default BulletinUpsert

import * as generalHelper from "../../helpers/generalHelper";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ------------------------------------------------------------
export const getRoles = createAsyncThunk(
    "getRoles",
    async (params, { rejectWithValue }) => {
        try {
            const data = await generalHelper.apiGet("/api/role", params);
            if (!data) {
                return rejectWithValue([]);
            }
            return data;
        } catch (error) {
            console.log("error", error);
        }
    }
);

// ------------------------------------------------------------
export const getRole = createAsyncThunk(
    "getRole",
    async (params, { rejectWithValue }) => {
        try {
            params.return_key = "one";
            const data = await generalHelper.apiGet("/api/role", params);
            if (!data) {
                return rejectWithValue([]);
            }
            return data;
        } catch (error) {
            console.log("error", error);
        }
    }
);

// ------------------------------------------------------------
export const updateRole = createAsyncThunk(
    "updateRole",
    async ({ id, params }, { rejectWithValue }) => {
        try {
            const data = await generalHelper.apiPut("/api/role", id, params);
            if (!data) {
                return rejectWithValue([]);
            }
            return data;
        } catch (error) {
            console.log("error", error);
        }
    }
);

// ------------------------------------------------------------
export const insertRole = createAsyncThunk(
    "insertRole",
    async (params, { rejectWithValue }) => {
        try {
            const data = await generalHelper.apiPost("/api/role", params);
            if (!data) {
                return rejectWithValue([]);
            }
            return data;
        } catch (error) {
            console.log("error", error);
        }
    }
);

// ------------------------------------------------------------
export const deleteRole = createAsyncThunk(
    "deleteRole",
    async ({ id, params }, { rejectWithValue }) => {
        try {
            const data = await generalHelper.apiDelete("/api/role", id, params);
            if (!data) {
                return rejectWithValue([]);
            }
            return data;
        } catch (error) {
            console.log("error", error);
        }
    }
);

// ------------------------------------------------------------
const initialState = {
    pending: false,
    rows: [],
    row: {},
    update: {},
    insert: {},
    delete: {},
    rejected: false,
};

// ------------------------------------------------------------
const localSlice = createSlice({
    name: "role",
    initialState,
    extraReducers: {
        [getRoles.rejected]: (state, action) => {
            state.pending = false;
            state.rejected = true;
            state.rows = null;
        },
        [getRoles.pending]: (state, action) => {
            state.pending = true;
            state.rejected = false;
            state.rows = null;
        },
        [getRoles.fulfilled]: (state, action) => {
            state.pending = false;
            state.rejected = false;
            state.rows = action.payload;
        },
        [getRole.rejected]: (state, action) => {
            state.pending = false;
            state.rejected = true;
            state.rows = null;
        },
        [getRole.pending]: (state, action) => {
            state.pending = true;
            state.rejected = false;
            state.rows = null;
        },
        [getRole.fulfilled]: (state, action) => {
            state.pending = false;
            state.rejected = false;
            state.row = action.payload;
        },
        [updateRole.rejected]: (state, action) => {
            state.pending = false;
            state.rejected = true;
            state.rows = null;
        },
        [updateRole.pending]: (state, action) => {
            state.pending = true;
            state.rejected = false;
            state.rows = null;
        },
        [updateRole.fulfilled]: (state, action) => {
            state.pending = false;
            state.rejected = false;
            state.update = action.payload;
        },
        [insertRole.rejected]: (state, action) => {
            state.pending = false;
            state.rejected = true;
            state.rows = null;
        },
        [insertRole.pending]: (state, action) => {
            state.pending = true;
            state.rejected = false;
            state.rows = null;
        },
        [insertRole.fulfilled]: (state, action) => {
            state.pending = false;
            state.rejected = false;
            state.insert = action.payload;
        },
        [deleteRole.rejected]: (state, action) => {
            state.pending = false;
            state.rejected = true;
            state.rows = null;
        },
        [deleteRole.pending]: (state, action) => {
            state.pending = true;
            state.rejected = false;
            state.rows = null;
        },
        [deleteRole.fulfilled]: (state, action) => {
            state.pending = false;
            state.rejected = false;
            state.delete = action.payload;
        },
    },
});

export default localSlice.reducer;

import { baseApi } from './baseApi'
import { Note, AddNote } from 'store/types'

export const notesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getDmeNotes: builder.query<Note[], { orderId: string }>({
      query: ({ orderId }) => ({ url: `/dme/orders/${orderId}/notes` }),
      providesTags: ['Notes'],
    }),
    getLabNotes: builder.query<Note[], { orderId: string }>({
      query: ({ orderId }) => ({ url: `/lab/orders/${orderId}/notes` }),
      providesTags: ['Notes'],
    }),
    sendDmeNote: builder.mutation<Note, AddNote>({
      query: (data) => ({
        method: `POST`,
        url: `/dme/orders/${data.orderId}/notes`,
        body: data,
      }),
      invalidatesTags: (result, error, { id }) => ['Notes'],
    }),
    sendLabNote: builder.mutation<Note, AddNote>({
      query: (data) => ({
        method: `POST`,
        url: `/lab/orders/${data.orderId}/notes`,
        body: data,
      }),
      invalidatesTags: (result, error, { id }) => ['Notes'],
    }),
    pinNote: builder.mutation<Note, { uuid: string; pinned: boolean }>({
      query: (data) => ({
        method: `PATCH`,
        url: `/notes/${data.uuid}`,
        body: data,
      }),
      invalidatesTags: (result, error, { id }) => ['Notes', 'Order'],
    }),
    deleteNote: builder.mutation<Note, { uuid: string }>({
      query: (data) => ({
        method: `DELETE`,
        url: `/lab/notes/${data.uuid}`,
      }),
      invalidatesTags: (result, error, { id }) => ['Notes'],
    }),
  }),
})

export const {
  useGetDmeNotesQuery,
  useGetLabNotesQuery,
  useSendDmeNoteMutation,
  useSendLabNoteMutation,
  usePinNoteMutation,
  useDeleteNoteMutation,
} = notesApi
